<template>
    <button
        class="hamburger hamburger--stand"
        :class="{ 'is-active': isActive }"
        type="button"
        aria-label="Menu"
        aria-controls="navigation"
        @click="$emit('click')"
    >
        <span class="hamburger-box">
            <span class="hamburger-inner" />
        </span>
    </button>
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            required: true,
        },
    },
}
</script>

<style lang="scss">
// Settings
$hamburger-padding-x: 30px;
$hamburger-padding-y: 29px;
$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $color-silver-chalice;
$hamburger-layer-border-radius: 4px;
$hamburger-hover-opacity: 0.7;
$hamburger-active-layer-color: $color-white;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

.hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: inline-block;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
        } @else {
            opacity: $hamburger-hover-opacity;
        }
    }

    &.is-active {
        background: #c64c48;

        &:hover {
            @if $hamburger-hover-use-filter == true {
                filter: $hamburger-active-hover-filter;
            } @else {
                opacity: $hamburger-active-hover-opacity;
            }
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $hamburger-active-layer-color;
        }
    }
}

.hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: '';
        display: block;
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
}

.hamburger--stand {
    .hamburger-inner {
        transition: transform 0.075s 0.15s
                cubic-bezier(0.55, 0.055, 0.675, 0.19),
            background-color 0s 0.075s linear;

        &::before {
            transition: top 0.075s 0.075s ease-in,
                transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        &::after {
            transition: bottom 0.075s 0.075s ease-in,
                transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    &.is-active {
        .hamburger-inner {
            transform: rotate(90deg);
            background-color: transparent !important;

            transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1),
                background-color 0s 0.15s linear;

            &::before {
                top: 0;
                transform: rotate(-45deg);
                transition: top 0.075s 0.1s ease-out,
                    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            &::after {
                bottom: 0;
                transform: rotate(45deg);
                transition: bottom 0.075s 0.1s ease-out,
                    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}
@media (max-width: $screen-mobile-large) {
    .hamburger {
        padding: 20px 17px;
    }
}
</style>
