import { mapMutations, mapState, mapActions } from 'vuex'

const ChangePageMixin = {
    computed: {
        ...mapState({
            previous: (state) => state.page.previous,
            current: (state) => state.page.currentPage,
            next: (state) => state.page.next,
            isLocked: (state) => state.page.nextPageLocked,
            currentSubpage: (state) => state.login.currentSubpage,
        }),
    },
    methods: {
        ...mapMutations([
            'openModal',
            'setModalComponentId',
            'setCurrentSubOption',
            'changeSubpage',
        ]),
        ...mapActions({
            changePage: 'changePage',
        }),
        changePageAction(direction) {
            this.openModal(false)
            this.setModalComponentId('')
            this.setCurrentSubOption(null)
            if (direction === 'next') {
                this.changePage(this.next.id)
            } else {
                this.changePage(this.previous.id)
            }
        },
        goToPreviousPage() {
            // change subpage when on login form page view
            if (
                this.current.id === 'formPage' &&
                this.currentSubpage !== 'Login'
            ) {
                this.changeSubpage({ page: 'Login' })
            } else {
                this.changePageAction('previous')
            }
        },
    },
}

export default ChangePageMixin
