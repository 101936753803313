<template>
    <div v-if="open" class="input-checkbox-children-items">
        <div
            v-if="type === 'colors'"
            class="input-checkbox-children-items-colors input-checkbox-children-items-colors-tablet"
        >
            <div class="input-checkbox-children-items-colors-title">
                {{ title }}
                <span @click="$emit('toggle')">&times;</span>
            </div>
            <div
                v-for="(item, index) in notDisabledItems"
                :key="index"
                :title="getTitle(item)"
                :style="item.value ? `background-color: ${item.value}` : null"
                class="input-checkbox-children-items-colors-item"
                @click="$emit('select-item', item)"
            >
                <img
                    v-if="item.image"
                    :src="item.image"
                    :alt="item.title[locale]"
                />
            </div>
        </div>
        <div v-else>
            <div
                v-if="screenWidth <= 900"
                class="input-checkbox-children-tablet"
            >
                <div class="input-checkbox-children-title">
                    {{
                        currentSettings.length && currentSettings[name]
                            ? currentSettings[name]
                            : title
                    }}
                    <span @click="$emit('toggle')">&times;</span>
                </div>
            </div>
            <div
                v-for="item in notDisabledItems"
                :key="item.id"
                class="input-checkbox-children-items-item"
                @click="$emit('select-item', item)"
            >
                {{ item.title[locale] }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => {},
        },
        currentSettings: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapState({
            screenWidth: (state) => state.page.screenWidth,
            locale: (state) => state.i18n.locale,
            currentPage: (state) => state.page.currentPage,
        }),
        notDisabledItems() {
            return this.items.filter((item) => item.disabled !== true)
        },
    },
    methods: {
        getTitle(item) {
            let title = item.title[this.locale]
            if (this.currentPage.id !== 'edgeColoration') {
                title +=
                    item.id !== undefined && item.id !== null ? item.id : ''
            }
            return title
        },
    },
}
</script>

<style lang="scss">
.input-checkbox-children {
    &-items {
        position: absolute;
        top: 51px;
        z-index: 5;
        width: 100%;
        background: $color-white;
        border: $color-border-input solid 1px;
        border-top: none;
        margin-bottom: 30px;

        &-item {
            padding: 16px 28px 15px;
            border-bottom: $color-border-input solid 1px;
            cursor: pointer;
            background-color: $color-white;
            transition: background-color 0.3s;

            &:hover {
                background: $color-bg-lighter;
            }

            &:last-child {
                border: none;
            }
        }

        &-colors {
            display: flex;
            flex-wrap: wrap;
            padding: 14px;
            box-shadow: $color-silver 0 0 70px;
            background-color: $color-white;

            &-title {
                display: none;
                width: 100%;
                padding: 16px 13px 15px;
                cursor: pointer;

                &:after {
                    content: '';
                    display: block;
                    border-bottom: $color-tundra solid 2px;
                    border-right: $color-tundra solid 2px;
                    position: absolute;
                    right: 28px;
                    top: 16px;
                    transform-origin: 70% 70%;
                    transform: rotate(-135deg);
                    @include size(11px);
                }
            }

            &-item {
                margin: 12px 12px 10px;
                border-radius: 20px;
                cursor: pointer;
                border: $color-border-input solid 1px;
                @include size(34px);
            }
        }
    }

    @media (max-width: $screen-tablet-medium) {
        &-items {
            max-width: 360px;
            min-width: 360px;
            position: fixed;
            bottom: 30%;
            left: calc(50% - 180px);
            top: 25%;
            z-index: 11;
            height: 0;

            &-item:first-child {
                border-top: $color-border-input solid 1px;
            }

            &-colors {
                padding: 0 14px 14px;

                &-tablet & {
                    &-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span {
                            font-size: 4rem;
                            line-height: 0;
                            color: $color-default-text;
                        }

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        &-tablet & {
            &-title {
                display: flex;
                justify-content: space-between;

                span {
                    font-size: 4rem;
                    line-height: 0;
                    color: $color-default-text;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    @media (max-width: $screen-mobile-landscape) {
        &-items {
            bottom: unset;
            top: 20%;
        }
    }
    @media (max-width: $screen-mobile-medium) {
        &-items {
            max-width: 320px;
            min-width: 320px;
            left: calc(50% - 160px);
            top: 10%;
        }
    }
}
</style>
