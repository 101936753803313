<template>
    <g>
        <!-- hide pen holder when streamer is seleted 
        and hide streamer when any other option is selected 
        BUT show streamer on package page if is selected
        -->
        <Elastic :format="format" />
        <PenHolder v-if="showPenHolder" :format="format" />
        <Ribbon :format="format" />
        <Streamer v-if="showStreamer" :format="format" />
    </g>
</template>

<script>
import Elastic from './Elastic'
import PenHolder from './PenHolder'
import Ribbon from './Ribbon'
import Streamer from './Streamer'

import { mapState } from 'vuex'

export default {
    components: {
        Elastic,
        PenHolder,
        Ribbon,
        Streamer,
    },
    props: {
        format: {
            type: String,
            default: '',
        },
        lastSelectedAccessory: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState({
            isPagePackage: (state) =>
                state.page?.currentPage?.id === 'packagePage',
            isStreamer: (state) => state.notes.configuration?.streamer?.general,
        }),
        showStreamer() {
            if (this.isPagePackage) {
                return this.isStreamer
            } else {
                if (this.isStreamer) {
                    return this.lastSelectedAccessory === 'streamer'
                } else {
                    return false
                }
            }
        },
        showPenHolder() {
            return !this.showStreamer
        },
    },
}
</script>
