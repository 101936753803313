import { mapState, mapActions } from 'vuex'
import Api from '@/api/ApiLauncher'

export default {
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
            code: (state) => state.notes.configurationCode,
            userData: (state) => state.user.userData,
        }),
        productNumber() {
            return this.configuration.material.name === 'fsc'
                ? 'WEB.MN.FSC'
                : 'WEB.MN'
        },
        parameters() {
            let pagesCount = this.configuration.additionalPages?.general?.title?.pl?.slice(
                0,
                2
            )

            pagesCount = pagesCount ? String(pagesCount?.trim()) : ''

            return [
                {
                    code: 'OKLADKA_RODZAJ_OKLADKI',
                    value: this.configuration.coverType?.apiId || '',
                },
                {
                    code: 'OKLADKA_NAROZNIKI_OKLADKI',
                    value: this.configuration.coverCorners?.apiId || '',
                },
                {
                    code: 'OKLADKA_RODZAJ_GRZBIETU',
                    value: this.configuration.coverRidge?.apiId || '',
                },
                {
                    code: 'FORMAT',
                    value: this.configuration.format?.apiId || '',
                },
                {
                    code: 'OKLEINA',
                    value: this.configuration.coverColorPattern?.apiId || '',
                },
                {
                    code: 'OKLADKA_ZNAKOWANIE_DRUK',
                    value:
                        this.configuration.markingPrint?.singleMarkingPrint
                            ?.apiId ||
                        this.configuration.markingPrint?.doubleMarkingPrint
                            ?.apiId ||
                        'B',
                },
                {
                    code: 'OKLADKA_ZNAKOWANIE_TLOCZENIE',
                    value:
                        this.configuration.markingExpression?.apiId ||
                        this.configuration.markingExpression?.color?.apiId ||
                        '',
                },
                { code: 'OKLADKA_ZNAKOWANIE_SITODRUK', value: '' },
                {
                    code: 'OKLADKA_ZNAKOWANIE_WYKONCZENIE',
                    value: this.configuration.markingCoverFinish?.apiId || '',
                },
                {
                    code: 'BLOK_RODZAJ_PAPIERU',
                    value: this.configuration.paperType?.apiId || '',
                },
                {
                    code: 'BLOK_ILOSC_KARTEK',
                    value: this.configuration.numberOfPages?.apiId || '',
                },
                {
                    code: 'BLOK_RODZAJ_ZADRUKU',
                    value:
                        this.configuration.overprintType?.apiId ||
                        this.configuration.overprintType?.style?.apiId ||
                        this.configuration.overprintType?.druk?.apiId ||
                        'B',
                },
                {
                    code: 'BLOK_PERFORACJA',
                    // value: this.configuration.perforation?.apiId || '',
                    value: this.configuration.perforation?.apiId ? 'T' : 'N',
                },
                {
                    code: 'BLOK_OTWORY',
                    value: this.configuration.binderHoles?.apiId || '',
                },
                {
                    code: 'WYKLEJKA_RODZAJ_PAPIERU',
                    value: this.configuration.flyleafPaper?.apiId || '',
                },
                {
                    code: 'WYKLEJKA_RODZAJ_ZADRUKU',
                    value:
                        this.configuration.flyleaf?.singleFlyleafPrint?.apiId ||
                        this.configuration.flyleaf?.doubleFlyleafPrint?.apiId ||
                        '',
                },
                {
                    code: 'DODATKOWE_STRONY_ILOSC',
                    value: pagesCount || '',
                },
                {
                    code: 'DODATKOWE_STRONY_MIEJSCE',
                    value:
                        this.configuration.additionalPages?.pagesPlace?.apiId ||
                        '',
                },
                {
                    code: 'DODATKOWE_STRONY_RODZAJ_DRUKU',
                    value:
                        this.configuration.additionalPages?.personalizedPrint
                            ?.apiId || '',
                },
                {
                    code: 'DODATKOWE_STRONY_RODZAJ_PAPIERU',
                    value:
                        this.configuration.additionalPages?.paperType?.apiId ||
                        '',
                },
                {
                    code: 'KALENDARZ',
                    value: this.configuration.calendar?.apiId || '',
                },
                {
                    code: 'GUMKA_ZAMYKAJACA',
                    value:
                        this.configuration.elastic?.elasticColor?.apiId || '',
                },
                {
                    code: 'UCHWYT_NA_DLUGOPIS',
                    value:
                        this.configuration.penHolder?.penHolderColor?.apiId ||
                        '',
                },
                {
                    code: 'TASIEMKA',
                    value: this.configuration.ribbon?.ribbonColor?.apiId || '',
                },
                {
                    code: 'BANDEROLA',
                    value:
                        this.configuration.streamer?.wrapperPrint?.apiId || '',
                },
                {
                    code: 'KIESZEN_KOLORY',
                    value:
                        this.configuration.pocket?.apiId ||
                        this.configuration.pocket?.singlePocketPrint?.apiId ||
                        this.configuration.pocket?.doublePocketPrint?.apiId ||
                        '',
                },
                {
                    code: 'KRAWEDZIE_TYP',
                    value:
                        this.configuration.edgeColoration?.apiId ||
                        this.configuration.edgeColoration?.general?.apiId ||
                        '',
                },
                {
                    code: 'KRAWEDZIE_FOLIA',
                    value:
                        this.configuration.edgeColoration?.metallicFoilColor
                            ?.apiId || '',
                },
                {
                    code: 'OPAKOWANIE',
                    value: this.configuration.wrapping?.apiId || '',
                },
            ]
        },
        payloadParams() {
            return {
                referenceNumber: this.code,
                // customerEmail: this.userData.email || '',
                shippingCountry: this.$store.state.valuation.country || null,
                shippingZipCode: this.$store.state.valuation.zip || null,
                currency: this.$store.state.valuation.currentCurrency?.id || '',
                quantities: this.$store.state.valuation.volumes.length
                    ? this.$store.state.valuation.volumes.map((v) => v.quantity)
                    : [1000],
            }
        },
    },
    mounted() {
        this.$root.$on('get-config-api-code', this.getConfigNumber)
    },
    methods: {
        ...mapActions(['setConfigurationApiCode', 'sendNotesConfig']),
        getConfigNumber(withUserData = false) {
            const payload = {
                ...this.payloadParams,
                parameters: [...this.parameters],
                productNumber: this.productNumber,
            }

            if (!withUserData || payload.shippingCountry === 'inny') {
                payload.shippingCountry = null
                payload.shippingZipCode = null
                // payload.currency = ''
                // payload.customerEmail = ''
            }

            const jsonPayload = JSON.stringify(payload)

            this.setConfigurationApiCode(null)

            return Api.getService('quote')
                .requestPut(
                    'send',
                    {},
                    jsonPayload,
                    {},
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                )
                .then(({ data }) => {
                    this.setConfigurationApiCode(data)
                })
                .catch((e) => {
                    console.error(e)
                })
        },
    },
}
