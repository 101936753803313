<template>
<div class="svg-spinner">
        <svg class="svg-spinner-svg" viewBox="25 25 50 50">
            <circle class="svg-spinner-bg" cx="50" cy="50" r="20" fill="none" :stroke="bgColor" stroke-width="2" />
            <circle class="svg-spinner-path" cx="50" cy="50" r="20" fill="none" :stroke="color" stroke-width="2" />
        </svg>
        <div v-if="$slots.default" class="svg-spinner-img">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "SvgSpinner",
    props: {
        color: {
            type: String,
            default: '#ED544E'
        },
        bgColor: {
            type: String,
            default: '#E8E8E8'
        }
    }
}
</script>

<style lang="scss">
.svg-spinner {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;

    &-svg {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        transform: translate3d(0, 0, 0);
    }

    &-path {
        stroke-dasharray: 150,200;
        stroke-dashoffset: -10;
        animation: dash 1.5s ease-in-out infinite;
        // stroke-linecap: round;
    }
    &-img {
        @include positionAbsoluteFit();
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}

</style>
