<template>
    <div class="material">
        <div class="material-wrapper">
            <MaterialHeader />
            <h1 class="material-title">
                {{ $t('material.title') }}
            </h1>
            <MaterialBoxesDesktop @redirect="redirect" />
            <MaterialConfig />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import MaterialBoxesDesktop from '@/components/material/MaterialBoxesDesktop'
import MaterialHeader from '@/components/material/MaterialHeader'
import MaterialConfig from '@/components/material/MaterialConfig'

export default {
    components: {
        MaterialHeader,
        MaterialBoxesDesktop,
        MaterialConfig,
    },
    methods: {
        ...mapActions(['changePage', 'setNotesConfig', 'getDependencies']),
        redirect(type) {
            const callback = () => {
                this.setNotesConfig({ param: 'material', value: type })
                this.changePage('cover')
            }
            this.getDependencies({ type: type.name, callback })
        },
    },
}
</script>

<style lang="scss">
.material {
    @include size(100%);
    position: relative;
    max-height: 100vh;
    max-width: 1290px;
    margin-left: auto;
    overflow-y: scroll;
    display: flex;

    &-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }

    &-title {
        font-size: 4.8rem;
        text-align: center;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    @media (max-width: $screen-large) {
        &-title {
            font-size: 3.4rem;
            margin-bottom: 30px;
            margin-top: 20px;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        padding-top: 120px;
        height: 100vh;

        &-wrapper {
            justify-content: flex-start;
        }

        &-title {
            font-size: 3.4rem;
            margin-bottom: 38px;
        }
    }
    @media (max-width: $screen-mobile-landscape) {
        padding-top: 100px;

        &-title {
            font-size: 3.2rem;
            margin-bottom: 14px;
            max-width: 300px;
        }
    }
}
</style>
