<template>
    <!-- eslint-disable -->
    <svg
        :class="[
            'notes-open',
            {
                'notes-open--horizontal': formatGroup === 'horizontal',
                'notes-open--square': formatGroup === 'square',
                'notes-open--virtual': isVirtual,
            },
        ]"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="svg[formatGroup].width"
        :height="svg[formatGroup].height"
        :viewBox="svg[formatGroup].viewBox"
    >
        <defs>
            <pattern
                id="pattern"
                width="1"
                height="1"
                patternTransform="matrix(-1, 0, 0, 1, 1299.254, 0)"
                viewBox="0 0 649.627 754.184"
            >
                <use preserveAspectRatio="none" xlink:href="#image" />
            </pattern>
            <filter
                v-if="formatGroup === 'horizontal'"
                id="shadow_vector"
                x="54"
                y="16"
                width="696"
                height="250"
                filterUnits="userSpaceOnUse"
            >
                <feOffset dx="20" dy="20" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="9" result="blur" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <filter
                v-else-if="formatGroup === 'square'"
                id="shadow_vector"
                x="102"
                y="35"
                width="597"
                height="332"
                filterUnits="userSpaceOnUse"
            >
                <feOffset dx="20" dy="20" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="9" result="blur" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <filter
                v-else
                id="shadow_vector"
                x="153"
                y="95.333"
                width="711"
                height="520"
                filterUnits="userSpaceOnUse"
            >
                <feOffset dx="20" dy="20" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="9" result="blur" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <linearGradient
                id="notes-open-linear-gradient pocket-flyleaf-linear-gradient"
                x2="0.926"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.341" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="notes-open-linear-gradient-2 pocket-flyleaf-linear-gradient-2"
                x1="0.017"
                x2="1.371"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.341" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="after-horiz-linear-gradient-2"
                x1="0.017"
                x2="1.371"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.341" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="notes-open-linear-gradient"
                x2="0.926"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.341" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="notes-open-linear-gradient-2"
                x1="0.017"
                x2="1.371"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.341" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <image
                id="image"
                preserveAspectRatio="none"
                width="649.627"
                height="754.184"
                xlink:href="/img/notes/Notes-image.png"
            />

            <PaperPattern :background="background" />
            <PaperPattern id="paperType" :background="paperTypeBackground" />
            <PaperPattern id="flyleafPaper" :background="flyleafPaperBackground" />
            <PaperPattern id="extraPageType" :background="extraPaperTypeBackground" />
        </defs>
        <g class="notes-open-zoom">
            <g id="Notes2" :transform="svg[formatGroup].notes2Transform">
                <use
                    v-if="formatGroup === 'vertical'"
                    id="shadow"
                    class="notes-open-1"
                    transform="translate(600 140.333)"
                    xlink:href="#image"
                />
                <rect
                    v-if="formatGroup === 'vertical'"
                    id="shadow-2"
                    data-name="shadow"
                    class="notes-open-2"
                    width="649.627"
                    height="754.184"
                    transform="translate(228 140)"
                />

                <!-- hide when cover is soft -->
                <g
                    v-if="!isCoverSoft"
                    id="notes"
                    :transform="svg[formatGroup].notesTransform"
                >
                    <g
                        class="notes-open-15 notes-pocket-flyleaf-14"
                        :transform="svg[formatGroup].notesShadowGroup"
                    >
                        <rect
                            v-if="formatGroup === 'vertical'"
                            id="shadow_vector-2"
                            data-name="shadow_vector"
                            class="notes-open-3"
                            :fill="
                                background ? `url(#cover)` : defaultBackground
                            "
                            width="657"
                            height="466"
                            rx="12"
                            transform="translate(160 102.33)"
                        />
                        <rect
                            v-if="formatGroup === 'horizontal'"
                            id="shadow_vector-2"
                            data-name="shadow_vector"
                            class="notes-open-3"
                            :fill="
                                background ? `url(#cover)` : defaultBackground
                            "
                            width="642"
                            height="196"
                            rx="12"
                            transform="translate(73 17)"
                        />
                        <rect
                            v-if="formatGroup === 'square'"
                            id="Rectangle_13912"
                            data-name="Rectangle 13912"
                            class="notes-open-3 notes-shadow"
                            :fill="
                                background ? `url(#cover)` : defaultBackground
                            "
                            width="526"
                            height="263"
                            rx="12"
                            transform="translate(0.5 5.095)"
                        />
                    </g>

                    <!-- round/straight corners -->
                    <g
                        id="Rectangle_1512"
                        data-name="Rectangle 1512"
                        class="notes-open-4"
                        :fill="background ? `url(#cover)` : defaultBackground"
                        transform="translate(0.126 -0.121)"
                    >
                        <rect
                            class="notes-open-13"
                            :width="svg[formatGroup].notesBorder1W"
                            :height="svg[formatGroup].notesBorder1H"
                            :rx="roundCorners ? 12 : 2"
                        />
                        <rect
                            class="notes-open-14"
                            x="0.5"
                            y="0.5"
                            :width="svg[formatGroup].notesBorder2W"
                            :height="svg[formatGroup].notesBorder2H"
                            :rx="roundCorners ? 11.5 : 2"
                        />
                    </g>
                </g>
                <g
                    v-else
                    id="notes"
                    :transform="svg[formatGroup].notesTransform"
                >
                    <g
                        class="notes-open-15 notes-pocket-flyleaf-14"
                        :transform="svg[formatGroup].notesShadowGroup"
                    >
                        <rect
                            v-if="formatGroup === 'vertical'"
                            id="shadow_vector-2"
                            class="notes-open-3"
                            width="627"
                            height="426"
                            rx="12"
                            transform="translate(175 117)"
                        />
                        <rect
                            v-if="formatGroup === 'horizontal'"
                            id="shadow_vector-2"
                            class="notes-open-3"
                            width="612"
                            height="176"
                            rx="12"
                            transform="translate(88 27)"
                        />
                    </g>
                    <g
                        class="notes-open-15 notes-pocket-flyleaf-14"
                        transform="translate(-150 -80)"
                    >
                        <rect
                            v-if="formatGroup === 'square'"
                            id="Rectangle_13912"
                            class="notes-open-3"
                            width="496"
                            height="243"
                            rx="12"
                            transform="translate(165 90)"
                        />
                    </g>
                </g>
                <g
                    id="Group_752"
                    data-name="Group 752"
                    :transform="svg[formatGroup].Group_752Transform"
                >
                    <Paper
                        :format="formatGroup"
                        :format-specific="formatSpecific"
                        :round-corners="roundCorners"
                    />
                    <!-- show flyleaf only on flyleaf/accessories page and on calendar section-->
                    <!-- and handle hideFlyleaf nad forceFlyleaf props (for WorkspaceVirtual) -->
                    <Flyleaf
                        v-if="
                            !hideFlyleaf &&
                            (forceFlyleaf ||
                                currentPage === 'flyleaf' ||
                                currentPage === 'accessories' ||
                                isCalendar)
                        "
                        :current-page="currentPage"
                        :format="formatGroup"
                        :is-pocket="!!pocketType"
                        :is-calendar="isCalendar"
                    />
                    <!-- showing notes block options and additional pages-->
                    <g
                        v-if="
                            !forceFlyleaf &&
                            (currentPage !== 'flyleaf' && !isCalendar)
                        "
                    >
                        <Perforation
                            :format="formatGroup"
                            :perforation-type="perforation"
                        />
                        <Holes
                            v-if="binderHoles"
                            :holes="binderHoles"
                            :format="formatGroup"
                        />

                        <g v-if="!isCalendar">
                            <PersonalizedFlyleaf
                                :format="formatGroup"
                                :page-position="pagePosition"
                            />
                            <PagesBefore
                                v-if="pagePosition === 'before'"
                                :format="formatGroup"
                            />
                            <Pages
                                v-else
                                :format="formatGroup"
                                :is-after="pagePosition === 'after'"
                            />
                        </g>
                    </g>
                    <Calendar
                        :calendarType="calendarType"
                        :format="formatGroup"
                        transform="translate(-397 -240)"
                    />
                    <NotesOpenEdge
                        :format="formatGroup"
                        :page-position="pagePosition"
                        :round-corners="roundCorners"
                        :is-cover-soft="isCoverSoft"
                        :is-flyleaf-virtual="isVirtual && forceFlyleaf"
                    />
                </g>
                <!-- show pocket only on accessories page -->
                <g v-if="currentPage === 'accessories'">
                    <PocketVertical
                        v-if="formatGroup === 'vertical'"
                        :pocket-type="pocketType"
                        :pocket-print="pocketPrint"
                        :round-corners="roundCorners"
                    />
                    <PocketHorizontal
                        v-if="formatGroup === 'horizontal'"
                        :pocket-type="pocketType"
                        :pocket-print="pocketPrint"
                        :round-corners="roundCorners"
                    />
                    <PocketSquare
                        v-if="formatGroup === 'square'"
                        :pocket-type="pocketType"
                        :pocket-print="pocketPrint"
                        :round-corners="roundCorners"
                    />
                </g>
                <NotesOpenAccessories :format="formatGroup" />
            </g>
        </g>
    </svg>
</template>

<script>
import NotesOpenEdge from './NotesOpenEdge'
import Paper from './inside/Paper'
import Perforation from './inside/Perforation'
import Holes from './inside/Holes'
import Pages from './personalized/Pages'
import PagesBefore from './personalized/PagesBefore'
import Calendar from './personalized/Calendar'
import Flyleaf from './flyleaf/Flyleaf'
import PocketVertical from './flyleaf/PocketVertical'
import PocketHorizontal from './flyleaf/PocketHorizontal'
import PocketSquare from './flyleaf/PocketSquare'
import PersonalizedFlyleaf from './personalized/PersonalizedFlyleaf'
import NotesOpenAccessories from './NotesOpenAccessories'

import PaperPattern from './paper/PaperPattern'
import NotesFormatMixin from '@/mixins/NotesFormatMixin'
import { mapState } from 'vuex'

export default {
    components: {
        NotesOpenAccessories,
        Paper,
        Perforation,
        Holes,
        Pages,
        PagesBefore,
        Calendar,
        NotesOpenEdge,
        Flyleaf,
        PocketVertical,
        PocketHorizontal,
        PocketSquare,
        PaperPattern,
        PersonalizedFlyleaf,
    },
    mixins: [NotesFormatMixin],
    props: {
        forceFlyleaf: {
            type: Boolean,
            default: false,
        },
        hideFlyleaf: {
            type: Boolean,
            default: false,
        },
        isVirtual: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            defaultBackground: '#7e7e7e',
            svg: {
                vertical: {
                    width: '1021',
                    height: '754.333',
                    viewBox: '0 0 1021 754.333',
                    notes2Transform: 'translate(-228 -140)',
                    notesTransform: 'translate(387.874 242.454)',
                    notesShadowGroup: 'matrix(1, 0, 0, 1, -159.87, -102.45)',
                    Group_752Transform: 'translate(398.006 244.237)',
                    notesBorder1W: '657',
                    notesBorder1H: '466',
                    notesBorder2W: '656',
                    notesBorder2H: '465',
                },
                horizontal: {
                    width: '881.5',
                    height: '304',
                    viewBox: '0 0 881.5 304',
                    notes2Transform: 'translate(-2087 -2866)',
                    notesTransform: 'translate(2160 2883)',
                    notesShadowGroup: 'matrix(1, 0, 0, 1, -73, -17)',
                    Group_752Transform: 'translate(2170 2884.673)',
                    notesBorder1W: '642',
                    notesBorder1H: '196',
                    notesBorder2W: '641',
                    notesBorder2H: '195',
                },
                square: {
                    width: '597',
                    height: '332',
                    viewBox: '0 0 597 332',
                    notes2Transform: 'translate(6.5 7)',
                    notesTransform: 'translate(0 0)',
                    notesShadowGroup: 'translate(0.5)',
                    Group_752Transform: 'translate(10 1.905)',
                    notesBorder1W: '543',
                    notesBorder1H: '278',
                    notesBorder2W: '542',
                    notesBorder2H: '277',
                },
            },
        }
    },
    computed: {
        ...mapState({
            marking: (state) => {
                if (
                    state.notes.configuration.markingPrint &&
                    state.notes.configuration.markingPrint.title.pl === 'CMYK'
                ) {
                    return true
                }
                return false
            },
            background: (state) =>
                state.notes.configuration.coverColorPattern
                    ? state.notes.configuration.coverColorPattern.image
                    : null,
            paperTypeBackground: (state) =>
                state.notes.configuration.paperType
                ? state.notes.configuration.paperType.image
                    : null
            ,
            extraPaperTypeBackground: (state) =>
                state.notes.configuration.additionalPages?.paperType
                    ? state.notes.configuration.additionalPages.paperType.image
                    : null
            ,
            flyleafPaperBackground: (state) =>
                state.notes.configuration.flyleafPaper
                    ? state.notes.configuration.flyleafPaper.image
                    : null
            ,
            currentPage: (state) => state.page.currentPage.id,
            isCalendar: (state) => {
                if (state.page.currentPage.id === 'personalized') {
                    return !!(
                        state.notes.configuration.calendar &&
                        state.notes.configuration.calendar.title
                    )
                }
                return false
            },
            perforation: (state) => {
                const option = state.notes.configuration.perforation

                return option?.title?.pl || null
            },
            binderHoles: (state) => {
                const option = state.notes.configuration.binderHoles

                return option?.title?.pl || null
            },
            roundCorners: (state) => {
                const option = state.notes.configuration.coverCorners

                return option?.title?.pl === `Zaokrąglone`
            },
            calendarType: (state) => {
                return state.notes.configuration.calendar?.title?.pl || null
            },
            isFolded: (state) => {
                const additionalPages =
                    state.notes.configuration.additionalPages
                if (additionalPages) {
                    const print =
                        additionalPages.personalizedPrint &&
                        additionalPages.personalizedPrint.title.pl
                    if (print) {
                        const printTypesArray = print.split('+')
                        const trimedTypes = printTypesArray.map((el) => {
                            return el.trim()
                        })
                        if (trimedTypes[0] === trimedTypes[1]) {
                            return true
                        }
                    }
                }
                return false
            },
            pagePosition: (state) => {
                const additionalPages =
                    state.notes.configuration.additionalPages
                const isPersonalizedPage =
                    state.page.currentPage.id === 'personalized'

                let position = 'center'
                if (isPersonalizedPage) {
                    if (additionalPages && additionalPages.pagesPlace) {
                        const place = additionalPages.pagesPlace.title.pl
                        if (place.startsWith('Przed')) {
                            position = 'before'
                        }
                        if (place.startsWith('Po')) {
                            position = 'after'
                        }
                    }
                }

                return position
            },
            pocketType: (state) => {
                const option = state.notes.configuration.pocket

                return option?.title?.pl || option?.general?.title?.pl || null
            },
            pocketPrint: (state) => {
                const option = state.notes.configuration.pocket

                return (
                    option?.singlePocketPrint?.title?.pl ||
                    option?.doublePocketPrint?.title?.pl ||
                    null
                )
            },
            isCoverSoft: (state) => {
                return ['1', '2'].includes(
                    state.notes.configuration.coverType?.apiId
                )
            },
        }),
    },
}
</script>

<style lang="scss">
.notes-open {
    margin: 0 -70px;

    &-zoom {
        transform: scale(1.3);
        transform-origin: 63% 50%;
    }

    &--horizontal & {
        &-zoom {
            transform: scale(1);
        }
    }
    &--square & {
        &-zoom {
            transform: scale(1.05);
            transform-origin: 20% 50%;
        }
    }

    &--horizontal {
        zoom: 0.9;
    }
    &--square {
        zoom: 0.82;

        .notes-shadow {
            display: none;
        }
    }

    &--virtual {
        zoom: 1;
    }

    &-1,
    &-2 {
        opacity: 0.6;
        display: none;
    }

    &-2 {
        mix-blend-mode: multiply;
        isolation: isolate;
        fill: url(#pattern);
    }

    &-4 {
        stroke: rgba(0, 0, 0, 0.27);
    }

    &-6 {
        opacity: 0.24;
    }

    &-7 {
        fill: url(#notes-open-linear-gradient);
    }

    &-10,
    &-11,
    &-8 {
        fill: url(#notes-open-linear-gradient-2);
    }

    &-12,
    &-14,
    &-9 {
        fill: none;
    }

    &-9 {
        stroke: #707070;
        stroke-width: 4px;
        opacity: 0.18;
    }

    &-10 {
        opacity: 0.31;
    }

    &-11 {
        opacity: 0.26;
    }

    &-12 {
        stroke: #d1d1d1;
    }

    &-13 {
        stroke: none;
    }

    &-15 {
        filter: url(#shadow_vector);
    }
    @media (max-width: $screen-medium) {
        &-zoom {
            transform: scale(1.1);
            transform-origin: 46% 130%;
        }

        &--horizontal {
            zoom: 0.75;
        }
    }
    @media (max-width: $screen-desktop) {
        &--square {
            zoom: 0.68;
        }
        &--horizontal {
            zoom: 0.65;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        margin: -70px auto;

        &--horizontal,
        &--square {
            zoom: 1;
            margin: 0 auto;
        }
    }
}
.notes-pocket-flyleaf {
    margin: 0 -70px;

    &-1,
    &-2 {
        opacity: 0.6;
    }

    &-2 {
        mix-blend-mode: multiply;
        isolation: isolate;
        fill: url(#pattern);
    }

    &-4 {
        stroke: rgba(0, 0, 0, 0.27);
    }

    &-5 {
        fill: #fff;
    }

    &-7 {
        opacity: 0.24;
    }

    &-8 {
        fill: url(#pocket-flyleaf-linear-gradient);
    }

    &-11,
    &-9 {
        fill: url(#pocket-flyleaf-linear-gradient-2);
    }

    &-10,
    &-13 {
        fill: none;
    }

    &-10 {
        stroke: #707070;
        stroke-width: 4px;
        opacity: 0.18;
    }

    &-11 {
        opacity: 0.31;
    }

    &-12 {
        stroke: none;
    }

    &-14 {
        filter: url(#shadow_vector);
    }
    @media (max-width: $screen-tablet-medium) {
        margin: -70px auto;
        &.notes-open--square,
        &.notes-open--horizontal {
            margin: 0 auto;
        }
    }
}
</style>
