<template>
    <component :is="currentLayout" />
</template>

<script>
//import global styles
import './assets/scss/global.scss'

import Default from '@/layouts/default'
import Tablet from '@/layouts/tablet'
import { mapState, mapMutations, mapActions } from 'vuex'
import { WindowHelper } from '@/helper/WindowHelper'

export default {
    name: 'App',
    components: {
        Default,
    },
    data() {
        return {
            layouts: {
                default: Default,
                tablet: Tablet,
            },
            currentLayout: Default,
        }
    },
    computed: {
        ...mapState({
            screenWidth: (state) => state.page.screenWidth,
            defaultLocale: (state) => state.i18n.defaultLocale,
            locales: (state) => state.i18n.locales,
            currentPage: (state) => state.page.currentPage,
        }),
    },
    watch: {
        $route(to) {
            this.detectLanguage(to.path)
            this.detectConfiguration(to.path)
        },
    },
    created() {
        this.detectLanguage(this.$route.path)
        this.detectConfiguration(this.$route.path)
    },
    mounted() {
        window.addEventListener('resize', this.handleLayoutChange)
        this.$nextTick(this.handleLayoutChange)
    },
    destroyed() {
        window.removeEventListener('resize', this.checkScreenWidth)
    },
    methods: {
        ...mapMutations(['setScreenWidth', 'setLocale']),
        ...mapActions(['getConfig']),
        checkScreenWidth() {
            this.setScreenWidth(WindowHelper.getWidth())
        },
        detectConfiguration(path) {
            const reg = /^\/([\w]{2}\/)?konfigurator\/[\w]+/
            if (reg.test(path)) {
                const code = path?.split('/konfigurator/')
                if (code[1]) {
                    this.getConfig({ data: code[1], context: this })
                }
            }
        },
        setLayout() {
            if (this.screenWidth <= 900) {
                this.currentLayout = this.layouts.tablet
            } else {
                this.currentLayout = this.layouts.default
            }
        },
        handleLayoutChange() {
            this.checkScreenWidth()
            this.setLayout()
        },
        detectLanguage(path) {
            let lang = this.defaultLocale

            if (path) {
                const pathLocale = path.split('/')[1]
                if (pathLocale && this.locales.includes(pathLocale)) {
                    lang = pathLocale
                }
            }
            this.setLocale(lang)
        },
    },
    metaInfo() {
        return {
            htmlAttrs: { lang: this.currentLocale },
            title: this.$t('meta.title'),
            meta: [
                { charset: 'utf-8' },
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.$t('meta.description'),
                },
            ],
        }
    },
}
</script>
