import AccessoriesData from './AccessoriesData'
import CoverMarkingData from './CoverMarkingData.vue'
import CoverData from './CoverData.vue'
import EdgeColorationData from './EdgeColorationData.vue'
import FlyleafData from './FlyleafData.vue'
import FormatData from './FormatData.vue'
import InsideData from './InsideData.vue'
import PackageData from './PackageData.vue'
import PaperData from './PaperData.vue'
import PersonalizedData from './PersonalizedData.vue'

const notesData = [
    ...AccessoriesData.computed.options(),
    ...CoverData.computed.options(),
    ...CoverMarkingData.computed.options(),
    ...EdgeColorationData.computed.options(),
    ...FlyleafData.computed.options(),
    ...FormatData.computed.options(),
    ...InsideData.computed.options(),
    ...PackageData.computed.options(),
    PaperData.computed.patterns(),
    ...PersonalizedData.computed.options(),
]

export { notesData }
