<template>
    <g
        id="Zmiana_koloru_lub_maska"
        class="notes-border"
        transform="translate(691 259)"
    >
        <path
            :fill="background ? `url(#cover)` : defaultBackground"
            class="notes-border-1"
            :d="getPath1"
        />
        <path class="notes-border-2" :d="getPath2" />
    </g>
</template>

<script>
export default {
    props: {
        isRound: {
            type: Boolean,
            default: false,
        },
        background: {
            type: String,
            default: null,
        },
        defaultBackground: {
            type: String,
            default: null,
        },
        format: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            path1: {
                round: {
                    vertical:
                        'M0,0H372.72a12,12,0,0,1,12,12V532.11a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                    horizontal:
                        'M0,0H362.727a12,12,0,0,1,12,12V216.7a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                    square:
                        'M0,0H253.08a12,12,0,0,1,12,12V251.273a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
                straight: {
                    vertical:
                        'M0,0H384.72a0,0,0,0,0,0,0V544.11a0,0,0,0,0,0,0H0a0,0,0,0,0,0,0V0A0,0,0,0,0,0,0Z',
                    horizontal:
                        'M0,0H374.72a0,0,0,0,0,0,0V228.11a0,0,0,0,0,0,0H0a0,0,0,0,0,0,0V0A0,0,0,0,0,0,0Z',
                    square:
                        'M0,0H265.08a0,0,0,0,0,0,0V263.273a0,0,0,0,0,0,0H0a0,0,0,0,0,0,0V0A0,0,0,0,0,0,0Z',
                },
            },
            path2: {
                round: {
                    vertical:
                        'M1,.5H372.72A11.5,11.5,0,0,1,384.22,12V532.11a11.5,11.5,0,0,1-11.5,11.5H1a.5.5,0,0,1-.5-.5V1A.5.5,0,0,1,1,.5Z',
                    horizontal:
                        'M1,.5H362.727a11.5,11.5,0,0,1,11.5,11.5V216.7a11.5,11.5,0,0,1-11.5,11.5H1a.5.5,0,0,1-.5-.5V1A.5.5,0,0,1,1,.5Z',
                    square:
                        'M1,.5H253.08A11.5,11.5,0,0,1,264.58,12V251.273a11.5,11.5,0,0,1-11.5,11.5H1a.5.5,0,0,1-.5-.5V1A.5.5,0,0,1,1,.5Z',
                },
                straight: {
                    vertical:
                        'M1,.5H384.72A0,0,0,0,0,0,0V544.11a0,0,0,0,0,0,0H1a0,0,0,0,0,0,0V1A0,0,0,0,0,0,0Z',
                    horizontal:
                        'M1,.0H374.72A0,0,0,0,0,0,0V228.11a0,0,0,0,0,0,0H1a0,0,0,0,0,0,0V1A0,0,0,0,0,0,0Z',
                    square:
                        'M1,.5H265.08A0,0,0,0,0,0,0V263.273a0,0,0,0,0,0,0H1a0,0,0,0,0,0,0V1A0,0,0,0,0,0,0Z',
                },
            },
        }
    },
    computed: {
        getPath1() {
            if (this.isRound) {
                return this.path1.round[this.format]
            }
            return this.path1.straight[this.format]
        },
        getPath2() {
            if (this.isRound) {
                return this.path2.round[this.format]
            }
            return this.path2.straight[this.format]
        },
    },
}
</script>

<style lang="scss" scoped>
.notes-border {
    fill: #7e7e7e;
    stroke: rgba(0, 0, 0, 0.27);

    &-1 {
        stroke: none;
    }
    &-2 {
        fill: none;
    }
}
</style>
