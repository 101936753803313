<script>
import dependencies from '@/store/modules/dependencies'

export default {
    data() {
        return {
            pageTitle: {
                pl: 'Okładka',
                en: 'Cover',
                de: 'Einbandmaterial',
                fr: 'Couverture',
            },
        }
    },
    computed: {
        list() {
            return dependencies.state.list
        },
        options() {
            let OKLADKA_RODZAJ_OKLADKI = null
            let OKLADKA_NAROZNIKI_OKLADKI = null
            let OKLADKA_RODZAJ_GRZBIETU = null

            if (this.list?.length) {
                this.list.forEach((dependency) => {
                    OKLADKA_RODZAJ_OKLADKI = mergeMultipleParams(
                        OKLADKA_RODZAJ_OKLADKI,
                        dependency.actions.OKLADKA_RODZAJ_OKLADKI
                    )

                    OKLADKA_NAROZNIKI_OKLADKI = mergeMultipleParams(
                        OKLADKA_NAROZNIKI_OKLADKI,
                        dependency.actions.OKLADKA_NAROZNIKI_OKLADKI
                    )

                    OKLADKA_RODZAJ_GRZBIETU = mergeMultipleParams(
                        OKLADKA_RODZAJ_GRZBIETU,
                        dependency.actions.OKLADKA_RODZAJ_GRZBIETU
                    )
                })
            }

            function mergeMultipleParams(param, dependencyAction) {
                param = param || dependencyAction

                if (param && dependencyAction) {
                    param = param.filter((dep) => {
                        return dependencyAction.includes(dep)
                    })
                }
                return param
            }

            function filterOptionsFn({ apiId }, allowed) {
                return allowed ? allowed.includes(apiId.toUpperCase()) : true
            }

            return [
                {
                    id: 'coverType',
                    isModal: true,
                    apiId: 'OKLADKA_RODZAJ_OKLADKI',
                    required: true,
                    title: {
                        pl: 'Rodzaj okładki',
                        en: 'Type of cover',
                        de: 'Art des Einbandmaterials',
                        fr: 'Type de couverture',
                    },
                    choices: [
                        {
                            title: {
                                pl: 'Miękka papierowa',
                                en: 'Paper softcover',
                                de: 'Softcover aus Papier',
                                fr: 'Couverture souple en papier',
                            },
                            apiId: '1',
                        },
                        {
                            title: {
                                pl: 'Miękka w okleinie introligatorskiej ',
                                en: 'Material softcover',
                                de: 'Softcover mit Einbandmaterial',
                                fr: 'Couverture souple en matière',
                            },
                            apiId: '2',
                        },
                        {
                            title: {
                                pl:
                                    'Twarda w okleinie papierowej lub introligatorskiej',
                                en: 'Paper or material hardcover',
                                de:
                                    'Hardcover mit  Papier- oder Einbandmaterial',
                                fr: 'Couverture rigide en papier ou en matière',
                            },
                            apiId: '3',
                        },
                        {
                            title: {
                                pl:
                                    'Twarda elastyczna w okleinie introligatorskiej',
                                en: 'Material flexible hardcover',
                                de: 'Elastischer Hardcover mit Einbandmaterial',
                                fr: 'Couverture rigide flexible en matère',
                            },
                            apiId: '4',
                        },
                    ].filter((option) =>
                        filterOptionsFn(option, OKLADKA_RODZAJ_OKLADKI)
                    ),
                },
                {
                    id: 'coverCorners',
                    apiId: 'OKLADKA_NAROZNIKI_OKLADKI',
                    isModal: true,
                    required: true,
                    title: {
                        pl: 'Narożniki okładki',
                        en: 'Cover corners',
                        de: 'Umschlagecken',
                        fr: 'Les coins de la couverture',
                    },
                    choices: [
                        {
                            title: {
                                pl: 'Proste',
                                en: 'Straight',
                                de: 'Spitze Ecken',
                                fr: 'Droits',
                            },
                            apiId: 'P',
                        },
                        {
                            title: {
                                pl: 'Zaokrąglone',
                                en: 'Rounded',
                                de: 'Runde Ecken',
                                fr: 'Arrondis',
                            },
                            apiId: 'Z',
                        },
                    ].filter((option) =>
                        filterOptionsFn(option, OKLADKA_NAROZNIKI_OKLADKI)
                    ),
                },
                {
                    id: 'coverRidge',
                    apiId: 'OKLADKA_RODZAJ_GRZBIETU',
                    isModal: true,
                    required: true,
                    title: {
                        pl: 'Rodzaj grzbietu',
                        en: 'Spine type',
                        de: 'Buchrückenart',
                        fr: 'Type de dos',
                    },
                    choices: [
                        {
                            title: {
                                pl: 'Miękki',
                                en: 'Soft',
                                de: 'Weich',
                                fr: 'Souple',
                            },
                            apiId: 'M',
                        },
                        {
                            title: {
                                pl: 'Twardy',
                                en: 'Hard',
                                de: 'Gerade',
                                fr: 'Rigide',
                            },
                            apiId: 'T',
                        },
                    ].filter((option) =>
                        filterOptionsFn(option, OKLADKA_RODZAJ_GRZBIETU)
                    ),
                },
            ]
        },
    },
}
</script>
