import { WindowHelper } from '@/helper/WindowHelper'

const changePage = (store) => {
    store.subscribeAction((action, state) => {
        if (action.type === 'changePage') {
            window.onresize = forceNarrowMenuOnBreakpoint
            forceNarrowMenuOnBreakpoint()

            clearCurrentOption()
        }

        function clearCurrentOption() {
            store.commit('setCurrentOption', null)
        }

        function forceNarrowMenuOnBreakpoint() {
            const resizeBreakpoint = state.menu.resizeMenuBreakpoint
            const isSmallScreen = WindowHelper.getWidth() <= resizeBreakpoint
            const isFirstPage = state.page.currentPage
                ? state.page.currentPage.id === 'material'
                : false

            if (!isFirstPage && isSmallScreen) {
                store.commit('resize', true)
            } else if (isFirstPage && isSmallScreen) {
                store.commit('resize', false)
            }
        }
    })
}

export default changePage
