import { compile } from 'path-to-regexp'
import ApiClient from '@/api/ApiClient'

class ApiService {
    constructor(serviceContract, envsAvailable, defaultEnvName) {
        this.defaultEnvName = defaultEnvName
        this.endpoints = serviceContract.endpoints
        this.envsAvailable = envsAvailable
        this.name = serviceContract.name
    }

    getName() {
        return this.name
    }

    getPathForAction(action, pathParams) {
        const endpointConfig = this.endpoints[action]

        const toPath = compile(endpointConfig.path)

        const pathCompiled = toPath(pathParams)
        const envName = endpointConfig.forceEnv
            ? endpointConfig.forceEnv
            : this.defaultEnvName
        if (!this.envsAvailable.hasOwnProperty(envName)) {
            throw new Error(
                `[DMSException] Undefined env "${envName}" called in service "${this.name}".`
            )
        }
        const envConfig = this.envsAvailable[envName]

        return envConfig.path + pathCompiled + (envConfig.endpointSuffix || '')
    }

    guardActionMethod(action, method) {
        if (!this.endpoints.hasOwnProperty(action)) {
            throw new Error(
                `[DMSException] Action '${action}' is not defined in api service '${this.name}'.`
            )
        }

        if (!this.endpoints[action].methods.includes(method)) {
            throw new Error(
                `[DMSException] Method ${method} not allowed as it is set in api service '${this.name}' configuration.`
            )
        }
    }

    requestDelete(action = 'index', pathParams = {}, params = {}, conf = {}) {
        this.guardActionMethod(action, 'delete')

        const path = this.getPathForAction(action, pathParams)

        return ApiClient.delete(path, params, conf)
    }

    requestGet(
        action = 'index',
        pathParams = {},
        params = {},
        conf = { headers: { Accept: `application/json` } }
    ) {
        this.guardActionMethod(action, 'get')

        const path = this.getPathForAction(action, pathParams)

        return ApiClient.get(path, params, conf)
    }

    requestHead(action = 'index', pathParams = {}, params = {}, conf = {}) {
        this.guardActionMethod(action, 'head')

        const path = this.getPathForAction(action, pathParams)

        return ApiClient.head(path, params, conf)
    }

    requestOptions(action = 'index', pathParams = {}, params = {}, conf = {}) {
        this.guardActionMethod(action, 'options')

        const path = this.getPathForAction(action, pathParams)

        return ApiClient.options(path, params, conf)
    }

    requestPatch(
        action = 'index',
        pathParams = {},
        payload = {},
        params = {},
        conf = {}
    ) {
        this.guardActionMethod(action, 'patch')

        const path = this.getPathForAction(action, pathParams)

        return ApiClient.patch(path, payload, params, conf)
    }

    requestPost(
        action = 'index',
        pathParams = {},
        payload = {},
        params = {},
        conf = {}
    ) {
        this.guardActionMethod(action, 'post')

        const path = this.getPathForAction(action, pathParams)

        return ApiClient.post(path, payload, params, conf)
    }

    requestPut(
        action = 'index',
        pathParams = {},
        payload = {},
        params = {},
        conf = {}
    ) {
        this.guardActionMethod(action, 'put')

        const path = this.getPathForAction(action, pathParams)

        return ApiClient.put(path, payload, params, conf)
    }
}

export default ApiService
