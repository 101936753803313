const state = {
    errorMessage: '',
    infoMessage: '',
    show: false,
    isLoading: false,
}
const getters = {
    hasInfoMessage(state) {
        return !!state.infoMessage
    },
    getInfoMessage(state) {
        return state.infoMessage
    },
    hasErrorMessage(state) {
        return !!state.errorMessage
    },
    getErrorMessage(state) {
        return state.errorMessage
    },
    isShown(state) {
        return !!state.show
    },
    isLoaderLoading(state) {
        return !!state.isLoading
    },
}
const mutations = {
    setErrorMessage(state, message) {
        state.errorMessage = message
    },
    clearErrorMessage(state) {
        state.errorMessage = ''
    },
    setInfoMessage(state, message) {
        state.infoMessage = message
    },
    setShow(state, value) {
        state.show = !!value
    },
    setLoaderLoading(state, value) {
        state.isLoading = !!value
    },
}

const actions = {
    showLoader({ commit }, { message, error, loading }) {
        commit('setInfoMessage', message || '')
        commit('setErrorMessage', error || '')
        commit('setLoaderLoading', !!loading)
        commit('setShow', true)
    },
    showLoaderError({ commit }, message) {
        commit('setInfoMessage', '')
        commit('setErrorMessage', message || 'loader.error.general')
        commit('setLoaderLoading', false)
        commit('setShow', true)
    },
    hideLoader({ commit }) {
        commit('setShow', false)
    },
}

export default {
    state,
    mutations,
    getters,
    actions,
}
