<template>
    <!-- eslint-disable -->
    <g
        id="Zmiana_koloru_lub_maska"
        class="notes-edge-2"
        :style="`fill: ${
            edgeColor && edgeColor.value ? edgeColor.value : defaultColor
        };`"
        :transform="options[format].gTransform"
    >
        <rect
            class="notes-edge-10"
            :width="options[format].width"
            :height="options[format].height"
        />
        <rect
            class="notes-edge-11"
            x="0.5"
            y="0.5"
            :width="options[format].width - 1"
            :height="options[format].height - 1"
        />
    </g>
</template>

<script>
export default {
    props: {
        edgeColor: {
            type: Object,
            default: () => {},
        },
        defaultColor: {
            type: String,
            default: '',
        },
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            options: {
                vertical: {
                    gTransform: 'translate(675 276)',
                    height: '532.11',
                    width: '44.72',
                },
                horizontal: {
                    gTransform: 'translate(4.029 4.7)',
                    height: '187.943',
                    width: '30.027',
                },
                square: {
                    gTransform: 'translate(4.86 7)',
                    height: '251',
                    width: '36.222',
                },
            },
        }
    },
}
</script>
