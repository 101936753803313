<template>
    <!-- eslint-disable -->
    <g v-if="pocketType">
        <defs>
            <linearGradient
                id="pocket-square-linear-gradient-5"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0" />
                <stop offset="1" stop-opacity="0.11" />
            </linearGradient>
            <linearGradient
                id="pocket-square-linear-gradient-6"
                x1="0.185"
                y1="-0.083"
                x2="0.864"
                y2="0.775"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0" />
                <stop offset="1" stop-opacity="0.31" />
            </linearGradient>
            <linearGradient
                id="pocket-square-linear-gradient-7"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.322" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="pocket-square-linear-gradient-8"
                x1="1.463"
                y1="0.341"
                x2="0.204"
                y2="0.341"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.231" />
                <stop offset="1" stop-opacity="0.039" />
            </linearGradient>
            <linearGradient
                id="pocket-square-linear-gradient-9"
                y1="0.511"
                x2="1.018"
                y2="0.511"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" stop-opacity="0.122" />
                <stop offset="1" stop-opacity="0.161" />
            </linearGradient>
            <linearGradient
                id="pocket-square-linear-gradient-10"
                y1="0.36"
                x2="1"
                y2="0.364"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" stop-opacity="0.302" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="pocket-square-linear-gradient-11"
                x1="1"
                y1="-0.092"
                x2="0.198"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.38" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <pattern
                id="pocket-pattern-2"
                width="1"
                height="1"
                viewBox="486.845 77.045 149.023 357.591"
            >
                <image
                    preserveAspectRatio="xMidYMid slice"
                    width="750.502"
                    height="491.875"
                    xlink:href="/img/notes/pocket-img.jpg"
                />
            </pattern>
        </defs>
        <rect
            id="Rectangle_1617"
            data-name="Rectangle 1617"
            class="square-pocket-9"
            width="104"
            height="262"
            transform="translate(490 343.666)"
        />
        <g
            v-if="roundCorners"
            id="Group_1174"
            data-name="Group 1174"
            transform="translate(-98 -338)"
        >
            <g id="TU_INNY_KOLOR" transform="translate(465 330.069)">
                <path
                    id="Path_390"
                    data-name="Path 390 s"
                    d="M0,0H154.561V263.353H0Z"
                    transform="translate(-58.843 15.557)"
                    class="square-pocket-10"
                    :class="colorClass"
                    :style="colorInsideStyle"
                />
                <path
                    id="Path_385"
                    data-name="Path 385"
                    d="M0,0H15.767L7.884,12.028Z"
                    transform="translate(-60 15.715)"
                    class="square-pocket-11"
                    :class="colorClass"
                    :style="colorStyle"
                />
                <path
                    id="Path_386"
                    data-name="Path 386"
                    d="M5.524-30.77,5.816-2.952H-3Z"
                    transform="translate(-49.025 30.864)"
                    class="square-pocket-10"
                    :class="colorClass"
                    :style="colorInsideStyle"
                />
                <path
                    id="Path_387"
                    data-name="Path 387"
                    d="M0,12.028H15.767L7.884,0Z"
                    transform="translate(-59.416 266.972)"
                    class="square-pocket-11"
                    :class="colorClass"
                    :style="colorStyle"
                />
                <path
                    id="Path_388"
                    data-name="Path 388"
                    d="M6.258-19.556,7.047-45h-9.2Z"
                    transform="translate(-49.961 310.05)"
                    class="square-pocket-10"
                    :class="colorClass"
                    :style="colorInsideStyle"
                />
                <path
                    id="Path_6300"
                    data-name="Path 6300"
                    d="M705.422,160.475a10.8,10.8,0,0,0-10.8-10.8h-.11L491.733,134.146V426.722L695.2,413.051l0-.016a10.8,10.8,0,0,0,10.216-10.788v-.036l-.07-240.5A10.869,10.869,0,0,0,705.422,160.475Z"
                    transform="translate(-535.397 -134.146)"
                    class="square-pocket-11"
                    :class="colorClass"
                    :style="colorStyle"
                />
                <g
                    v-if="
                        colorClass &&
                        colorClass !== 'notes-pocket-color-flyleaf'
                    "
                    id="Group_1210"
                    data-name="Group 1210"
                    transform="translate(-285.012 -222.752)"
                >
                    <g id="Group_1177" data-name="Group 1177">
                        <path
                            id="Path_6240"
                            data-name="Path 6240"
                            d="M307.082,326.675v.763c6.488.449,3.152,8.771,2.363,10.5l-3.336,7.351-6.674-12.626c-3.152-6.127-.1-5.811,1.066-5.731v-.718l-13.348-.94v.732c1.348.145,2.873.307,4.909,4.389,0,0,2.086,3.755,8.9,17.486v8.454c0,3.845-1.762,5.141-3.708,5.1v.824l12.93.262v-.81c-1.946-.041-3.708-1.4-3.708-5.218V346.118l3.291-7.353c5.144-10.785,8.62-10.544,10.242-10.431v-.749Z"
                            class="square-pocket-3"
                        />
                        <path
                            id="Path_6241"
                            data-name="Path 6241"
                            d="M334.657,328.177c-10.2-.724-15.526,7.294-15.526,17.187,0,9.945,5.33,17.895,15.526,18.094s15.526-7.194,15.526-16.7C350.183,337.3,344.853,328.9,334.657,328.177Zm0,34.549c-5.515-.114-8.9-3.452-8.9-17.064,0-13.563,3.383-17.139,8.9-16.753,5.469.384,8.806,4.331,8.806,17.549C343.463,359.723,340.127,362.839,334.657,362.726Z"
                            class="square-pocket-3"
                        />
                        <path
                            id="Path_6242"
                            data-name="Path 6242"
                            d="M367.606,364.1c4.91.1,11.4-2.062,11.359-11.068V340.055c0-2.194-.1-7.685,6.442-7.186v-.679l-13.025-.916v.692c5.885.362,5.885,5.853,5.885,8.049V353.01c0,5.811-3.85,8.833-7.971,8.737-4.727-.11-8.157-3.52-8.157-9.2v-17.17c0-3.518,2.039-3.987,4.032-3.848v-.7l-13.161-.926v.713c2.039.141,3.476,1.329,3.476,4.357v16.986c0,7.712,5.005,12.021,11.122,12.141Z"
                            class="square-pocket-3"
                        />
                        <path
                            id="Path_6243"
                            data-name="Path 6243"
                            d="M412.611,359.917c-2.318-8.759-5.747-10-8.806-10.045,5.236-.205,8.856-1.907,8.856-7.493,0-7.232-5.84-8.686-15.062-9.336l-10.154-.715v.677c2.039.141,3.1,1.157,3.1,3.944v21.859c0,3.411-1.111,4.644-3.1,4.6v.677l11.957.241v-.663c-1.947-.042-3.244-1.363-3.244-4.428v-9.3l2.178.094a6.82,6.82,0,0,1,6.488,4.842c2.966,9.579,1.993,9.56,9.13,9.751l3.893.079v-.648A5.822,5.822,0,0,1,412.611,359.917Zm-15.02-10.582-1.433-.063V333.613l1.436.1c4.727.328,8.713.517,8.713,8.306h0C406.3,349.721,402.5,349.553,397.591,349.335Z"
                            class="square-pocket-3"
                        />
                        <path
                            id="Path_6244"
                            data-name="Path 6244"
                            d="M311.855,397.043c-1.206,10.251-11.263,10.682-13.766,10.789l-1.668.071V377.722c0-3.714,1.854-4.582,3.85-4.571v-.77l-12.84-.08v.788c1.993.01,3.29,1.369,3.29,4.541v24.852c0,3.536-.927,5.707-2.919,5.793v.783l24.7-1.085V397.025Z"
                            class="square-pocket-3"
                        />
                        <path
                            id="Path_6245"
                            data-name="Path 6245"
                            d="M348.328,389.779c0-9.483-5.33-17.565-15.526-17.635s-15.526,8.31-15.526,18.231c0,9.971,5.33,17.6,15.526,17.146s15.527-8.211,15.527-17.742ZM332.8,406.786c-5.515.24-8.9-2.889-8.9-16.538,0-13.6,3.383-17.4,8.9-17.37,5.47.033,8.806,3.776,8.806,17.03C341.608,403.21,338.272,406.548,332.8,406.786Z"
                            class="square-pocket-3"
                        />
                        <path
                            id="Path_6246"
                            data-name="Path 6246"
                            d="M371.037,392.444v.694c2.874-.072,3.851.088,3.851,6.665,0,4.185-1.484,5.216-6.906,5.452-5.191.225-8.574-2.971-8.574-15.924s3.288-16.278,8.576-16.247c5.468.032,9.083,2.116,10.107,9.487l1.3-.011.324-7.582c-2.873-1.888-6.534-2.556-11.725-2.591-10.012-.069-15.8,7.605-15.8,17.08s5.79,16.93,15.8,16.484a35.008,35.008,0,0,0,11.906-2.446c0-9.954.463-10.6,3.152-10.668v-.681Z"
                            class="square-pocket-3"
                        />
                        <path
                            id="Path_6247"
                            data-name="Path 6247"
                            d="M401.629,372.618c-10.2-.07-15.529,7.486-15.529,16.436,0,9,5.33,15.854,15.526,15.4s15.526-7.441,15.526-15.995h0C417.155,379.947,411.825,372.689,401.629,372.618Zm0,31.174c-5.515.24-8.9-2.565-8.9-14.865,0-12.255,3.383-15.68,8.9-15.648,5.466.033,8.806,3.4,8.806,15.308C410.435,400.54,407.1,403.554,401.629,403.792Z"
                            class="square-pocket-3"
                        />
                    </g>
                </g>
            </g>
            <g
                id="Group_767"
                data-name="Group 767"
                transform="translate(465.584 330.069)"
            >
                <line
                    id="Line_180"
                    data-name="Line 180"
                    y2="263.405"
                    transform="translate(-59.708 15.791)"
                    class="square-pocket-12"
                    stroke="rgba(45,46,47,0.15)"
                    stroke-width="1.5"
                />
                <path
                    id="Path_527"
                    data-name="Path 527"
                    d="M5.8-20.342,6.549-45h-8.7Z"
                    transform="translate(-49.961 311.219)"
                    class="square-pocket-13"
                />
                <path
                    id="Path_529"
                    data-name="Path 529"
                    d="M0,0H11.243l-3.8,12.146Z"
                    transform="translate(-60 15.715)"
                    class="square-pocket-14"
                />
                <path
                    id="Path_526"
                    data-name="Path 526"
                    d="M5.524-30.77,5.33-2.952H-3Z"
                    transform="translate(-49.609 30.864)"
                    class="square-pocket-15"
                />
                <path
                    id="Path_392"
                    data-name="Path 392"
                    d="M.049.213,7.5,12.489h8.274V251.269H7.982L0,263.693Z"
                    transform="translate(-60 15.502)"
                    class="square-pocket-16"
                />
                <path
                    id="Path_6302"
                    data-name="Path 6302"
                    d="M705.422,160.475a10.8,10.8,0,0,0-10.8-10.8h-.11L491.733,134.146V426.722L695.2,413.051l0-.016a10.8,10.8,0,0,0,10.216-10.788v-.036l-.07-240.5A10.869,10.869,0,0,0,705.422,160.475Z"
                    transform="translate(-535.981 -134.146)"
                    class="square-pocket-16"
                />
                <path
                    id="Path_528"
                    data-name="Path 528"
                    d="M0,12.028H11.778L7.884,0Z"
                    transform="translate(-60 266.972)"
                    class="square-pocket-19"
                />
                <line
                    id="Line_179"
                    data-name="Line 179"
                    y2="292.607"
                    transform="translate(-43.939 0.021)"
                    class="square-pocket-20"
                />
            </g>
        </g>
        <g
            v-else
            id="Group_1331"
            data-name="Group 1331"
            transform="translate(-224.5 -338.666)"
        >
            <g id="TU_INNY_KOLOR" transform="translate(521.777 334)">
                <path
                    id="Path_390"
                    data-name="Path 390 rs"
                    class="square-pocket-10"
                    :class="colorClass"
                    :style="colorInsideStyle"
                    d="M0,0H221.89V263.116H0Z"
                    transform="translate(0 11.482)"
                />
                <path
                    id="Path_385"
                    data-name="Path 385"
                    class="square-pocket-11"
                    :class="colorClass"
                    :style="colorStyle"
                    d="M0,0H25.82L12.91,11.819Z"
                    transform="translate(0 11.482)"
                />
                <path
                    id="Path_386"
                    data-name="Path 386"
                    class="square-pocket-10"
                    :class="colorClass"
                    :style="colorInsideStyle"
                    d="M10.959-30.77l.478,27.335H-3Z"
                    transform="translate(15.103 26.902)"
                />
                <path
                    id="Path_387"
                    data-name="Path 387"
                    class="square-pocket-11"
                    :class="colorClass"
                    :style="colorStyle"
                    d="M0,11.819H25.82L12.91,0Z"
                    transform="translate(0 263.361)"
                />
                <path
                    id="Path_388"
                    data-name="Path 388"
                    class="square-pocket-10"
                    :class="colorClass"
                    :style="colorInsideStyle"
                    d="M10.75-20.177,12.1-45H-2.155Z"
                    transform="translate(15.066 308.195)"
                />
                <path
                    id="Path_389"
                    data-name="Path 389"
                    class="square-pocket-11"
                    :class="colorClass"
                    :style="colorStyle"
                    d="M0-30,213.723-14.535V248.376L0,261.779Z"
                    transform="translate(25.82 26.258)"
                />
            </g>
            <g
                id="Group_767"
                data-name="Group 767"
                transform="translate(521.299 334)"
            >
                <line
                    id="Line_180"
                    data-name="Line 180"
                    class="square-pocket-12"
                    y2="264"
                    transform="translate(0.201 12.166)"
                />
                <g
                    v-if="
                        colorClass &&
                        colorClass !== 'notes-pocket-color-flyleaf'
                    "
                    id="Group_1334"
                    data-name="Group 1334"
                    transform="translate(-212.811 -222.607)"
                >
                    <path
                        id="Path_6240"
                        data-name="Path 6240"
                        class="square-pocket-3"
                        d="M304.173,326.47v.652c5.541.383,2.692,7.491,2.018,8.967l-2.849,6.278-5.7-10.783c-2.692-5.233-.082-4.963.91-4.895v-.613l-11.4-.8v.625c1.151.124,2.454.262,4.193,3.748,0,0,1.782,3.207,7.6,14.934v7.22c0,3.284-1.5,4.391-3.167,4.355v.7l11.043.224v-.692c-1.662-.035-3.167-1.2-3.167-4.456v-8.859l2.811-6.28c4.393-9.211,7.362-9.005,8.747-8.909v-.64Z"
                    />
                    <path
                        id="Path_6241"
                        data-name="Path 6241"
                        class="square-pocket-3"
                        d="M332.391,328.17c-8.708-.618-13.26,6.229-13.26,14.678,0,8.493,4.552,15.283,13.26,15.453s13.26-6.144,13.26-14.261C345.651,335.964,341.1,328.79,332.391,328.17Zm0,29.506c-4.71-.1-7.6-2.948-7.6-14.573,0-11.583,2.889-14.637,7.6-14.308,4.671.328,7.521,3.7,7.521,14.988C339.912,355.112,337.063,357.773,332.391,357.677Z"
                        transform="translate(-4.667 -0.417)"
                    />
                    <path
                        id="Path_6242"
                        data-name="Path 6242"
                        class="square-pocket-3"
                        d="M365.476,359.113c4.193.082,9.738-1.761,9.7-9.453V338.574c0-1.874-.082-6.563,5.5-6.137v-.58l-11.124-.782v.591c5.026.309,5.026,5,5.026,6.874v11.1c0,4.963-3.288,7.544-6.808,7.462-4.037-.094-6.966-3.006-6.966-7.854V334.582c0-3,1.741-3.405,3.443-3.286v-.6l-11.24-.791v.609c1.741.12,2.969,1.135,2.969,3.721v14.507c0,6.586,4.274,10.266,9.5,10.369Z"
                        transform="translate(-9.612 -0.676)"
                    />
                    <path
                        id="Path_6243"
                        data-name="Path 6243"
                        class="square-pocket-3"
                        d="M408.938,355.89c-1.98-7.481-4.908-8.538-7.521-8.579,4.472-.175,7.563-1.629,7.563-6.4,0-6.176-4.988-7.418-12.864-7.973l-8.672-.611v.578c1.741.12,2.652.988,2.652,3.368v18.669c0,2.913-.949,3.966-2.652,3.929v.578l10.212.206v-.566c-1.663-.036-2.771-1.164-2.771-3.782v-7.94l1.86.08a5.825,5.825,0,0,1,5.541,4.135c2.533,8.181,1.7,8.165,7.8,8.328l3.325.067v-.553A4.972,4.972,0,0,1,408.938,355.89Zm-12.828-9.037-1.224-.054V333.425l1.226.085c4.037.28,7.441.442,7.441,7.094h0C403.551,347.182,400.305,347.039,396.11,346.853Z"
                        transform="translate(-14.638 -1.03)"
                    />
                    <path
                        id="Path_6244"
                        data-name="Path 6244"
                        class="square-pocket-3"
                        d="M308.29,393.432c-1.03,8.755-9.619,9.123-11.757,9.214l-1.425.061V376.931c0-3.172,1.583-3.913,3.288-3.9v-.658l-10.966-.068v.673c1.7.009,2.81,1.169,2.81,3.878v21.225c0,3.02-.792,4.874-2.493,4.947v.669l21.1-.927v-9.35Z"
                        transform="translate(-0.041 -6.864)"
                    />
                    <path
                        id="Path_6245"
                        data-name="Path 6245"
                        class="square-pocket-3"
                        d="M343.8,387.205c0-8.1-4.552-15-13.26-15.061s-13.26,7.1-13.26,15.57c0,8.516,4.552,15.031,13.26,14.643s13.261-7.013,13.261-15.152Zm-13.26,14.525c-4.71.2-7.6-2.467-7.6-14.124,0-11.615,2.889-14.862,7.6-14.835,4.672.028,7.521,3.225,7.521,14.544C338.057,398.676,335.208,401.526,330.536,401.73Z"
                        transform="translate(-4.397 -6.841)"
                    />
                    <path
                        id="Path_6246"
                        data-name="Path 6246"
                        class="square-pocket-3"
                        d="M368.285,389.517v.593c2.455-.061,3.289.075,3.289,5.692,0,3.574-1.267,4.455-5.9,4.656-4.433.192-7.323-2.537-7.323-13.6s2.808-13.9,7.324-13.876c4.67.027,7.757,1.807,8.632,8.1l1.109-.009.277-6.475c-2.454-1.612-5.58-2.183-10.014-2.213-8.551-.059-13.5,6.5-13.5,14.587s4.945,14.459,13.495,14.078a29.9,29.9,0,0,0,10.168-2.089c0-8.5.4-9.054,2.692-9.111v-.582Z"
                        transform="translate(-9.492 -6.877)"
                    />
                    <path
                        id="Path_6247"
                        data-name="Path 6247"
                        class="square-pocket-3"
                        d="M399.362,372.618c-8.708-.06-13.262,6.393-13.262,14.037,0,7.682,4.552,13.54,13.26,13.151s13.26-6.355,13.26-13.66h0C412.622,378.877,408.07,372.679,399.362,372.618Zm0,26.624c-4.71.205-7.6-2.191-7.6-12.7,0-10.466,2.889-13.391,7.6-13.364,4.668.028,7.521,2.9,7.521,13.074C406.883,396.465,404.034,399.039,399.362,399.242Z"
                        transform="translate(-14.442 -6.91)"
                    />
                </g>
                <path
                    id="Path_527"
                    data-name="Path 527"
                    class="square-pocket-13"
                    d="M10.716-20.108V-44.824L-2.155-45Z"
                    transform="translate(15.544 308.195)"
                />
                <path
                    id="Path_529"
                    data-name="Path 529"
                    class="square-pocket-14"
                    d="M0,0H18.221L12.382,11.268Z"
                    transform="translate(0.478 11.482)"
                />
                <path
                    id="Path_526"
                    data-name="Path 526"
                    class="square-pocket-15"
                    d="M10.959-30.77,10.641-3.434H-3Z"
                    transform="translate(15.581 26.902)"
                />
                <path
                    id="Path_392"
                    data-name="Path 392"
                    class="square-pocket-16"
                    d="M.024-3.922,12.382,7.359H25.816l.063,240.676H13.058L0,260.79Z"
                    transform="translate(0.478 15.391)"
                />
                <path
                    id="Path_525"
                    data-name="Path 525"
                    class="square-pocket-17"
                    d="M0-30,213.211-14.538V248.314L0,261.714Z"
                    transform="translate(26.298 26.367)"
                />
                <path
                    id="Path_391"
                    data-name="Path 391"
                    class="square-pocket-18"
                    d="M0-30,213.211-14.46V249.719L0,263.186Z"
                    transform="translate(26.298 24.895)"
                />
                <path
                    id="Path_528"
                    data-name="Path 528"
                    class="square-pocket-19"
                    d="M0,11.819H19.288L12.91,0Z"
                    transform="translate(0.478 263.362)"
                />
                <line
                    id="Line_179"
                    data-name="Line 179"
                    class="square-pocket-20"
                    y2="291"
                    transform="translate(26.201 -3.834)"
                />
            </g>
        </g>
    </g>
</template>

<script>
import FlyleafPageMixin from '@/mixins/FlyleafPageMixin'

export default {
    mixins: [FlyleafPageMixin],
    props: {
        pocketType: {
            type: String,
            default: '',
        },
        pocketPrint: {
            type: String,
            default: '',
        },
        roundCorners: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
.square-pocket-3 {
    fill: #fff;
}
.square-pocket-12,
.square-pocket-20 {
    fill: none;
}

.square-pocket-9 {
    fill: url(#pocket-square-linear-gradient-4);
}

.square-pocket-10 {
    fill: #efefef;

    &.one {
        fill: #efefef;
    }
    &.cmyk {
        fill: #efefef;
    }
    &.oneone {
        fill: #7eb9bf;
    }
    &.cmykcmyk {
        fill: #3e5873;
    }
}

.square-pocket-11 {
    fill: #fff;

    &.one {
        fill: #7eb9bf;
    }
    &.cmyk {
        fill: url(#pocket-pattern-2);
    }
    &.oneone {
        fill: #7eb9bf;
    }
    &.cmykcmyk {
        fill: url(#pocket-pattern-2);
    }
}

.square-pocket-12 {
    stroke: rgba(45, 46, 47, 0.15);
    stroke-width: 1.5px;

    fill: #fff;
    stroke: rgba(45, 46, 47, 0.15);
    stroke-width: 1.5px;

    &.one {
        fill: #d8d8d8;
    }
    &.oneone {
        fill: #7eb9bf;
    }
    &.cmyk {
        fill: #d8d8d8;
    }
    &.cmykcmyk {
        fill: #3e5873;
    }
}

.square-pocket-13 {
    fill: url(#pocket-square-linear-gradient-5);
}

.square-pocket-14 {
    fill: url(#pocket-square-linear-gradient-6);
}

.square-pocket-15 {
    fill: url(#pocket-square-linear-gradient-7);
}

.square-pocket-16 {
    fill: url(#pocket-square-linear-gradient-8);
}

.square-pocket-17 {
    fill: url(#pocket-square-linear-gradient-9);
}

.square-pocket-18 {
    opacity: 0.23;
    fill: url(#pocket-square-linear-gradient-10);
}

.square-pocket-19 {
    fill: url(#pocket-square-linear-gradient-11);
}

.square-pocket-20 {
    stroke: rgba(255, 255, 255, 0.35);
}
.notes-pocket-color-flyleaf {
    fill: url('#flyleafPaper');
}
</style>
