<template>
    <div class="offer-currency">
        <div class="offer-currency-label">
            {{ $t('offer.currency') }}
        </div>
        <FormDropdown
            v-model="selectedCurrency"
            :options="currencyOptions"
            disabled
            @input="selectCurrency"
        />
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { currencies } from '@/assets/data/currencies'
import FormDropdown from '@/components/form/FormDropdown'

export default {
    components: {
        FormDropdown,
    },
    data() {
        return {
            allCurencies: currencies,
            selectedCurrency: ''
        }
    },
    computed: {
        ...mapState({
            defaultCurrency: (state) => state.valuation.defaultCurrency,
            currentCurrency: (state) => state.valuation.currentCurrency,
            isUserCountryPoland: (state) => state.user.userData.countryName === 'Polska'
        }),
        currencyOptions() {
            return currencies.map(c => { return {label: c.title, value: c.id }})
        }
    },
    created() {
        // this.selectedCurrency = this.isUserCountryPoland ?
        // this.selectedCurrency = this.currentCurrency?.id || this.defaultCurrency?.id
    },
    mounted() {
        // this.setCurrency(this.defaultCurrency)
        // this.setCurrency(this.isUserCountryPoland ? 'PLN' : 'EUR')
        this.selectCurrency(this.isUserCountryPoland ? 'PLN' : 'EUR')
    },
    methods: {
        ...mapMutations(['setCurrency']),
        selectCurrency(value) {
            const currency = currencies.find(c => c.id === value)
            this.setCurrency(currency)
            this.selectedCurrency = currency.id
        }
    },
}
</script>

<style lang="scss">
.offer-currency {
    display: flex;
    margin-top: 33px;
    width: 100%;

    @media (min-width: $screen-tablet-medium) and (max-width: $screen-large) {
        justify-content: flex-end;
    }

    @media (max-width: $screen-tablet-small) {
        justify-content: flex-end;
    }

    &-label {
        display: flex;
        align-items: center;
        width: 160px;
    }
}
</style>
