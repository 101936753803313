<template>
    <div class="summary-links" :class="theme">
        <div v-if="code">
            <hr />
            <header class="summary-links-header">
                <h2
                    class="summary-links-link-title"
                    v-text="$t('config.linkTitle')"
                />
                <transition name="fade">
                    <span v-if="textCopied" class="summary-links-code">
                        {{ successMessage }}
                    </span>
                </transition>
            </header>
            <p class="summary-links-text-small">
                {{ $t('config.linkDesc') }}
            </p>
            <div class="summary-links-buttons">
                <div
                    class="summary-links-link-box"
                    :title="$t('link.configurationUrl') + code"
                >
                    {{ $t('link.configurationUrl') }}
                    <span class="red">{{ code }}</span>
                </div>
                <button
                    class="summary-links-button summary-links-buttons-code"
                    @click="
                        addToClipboard(
                            $t('link.configurationUrl') + code,
                            $t('config.copyLink')
                        )
                    "
                >
                    {{ $t('config.button3') }}
                </button>
            </div>
            <div class="summary-links-buttons">
                <div
                    class="summary-links-link-box"
                    :title="$t('link.configurationUrl') + code"
                >
                    <span class="red">{{ code }}</span>
                </div>
                <button
                    class="summary-links-button summary-links-buttons-code"
                    @click="addToClipboard(code, $t('config.copyCode'))"
                >
                    {{ $t('config.button2') }}
                </button>
            </div>
        </div>
        <hr />
<!--        <p class="summary-links-text-small summary-links-text-margin">-->
<!--            {{ $t('config.desc2') }}-->
<!--        </p>-->
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        theme: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            textCopied: false,
            successMessage: '',
        }
    },
    computed: {
        ...mapState({
            code: (state) => state.notes.configurationCode || null,
        }),
    },
    methods: {
        addToClipboard(text, successMessage) {
            this.textCopied = false
            const textArea = document.createElement('textarea')

            textArea.style.position = 'fixed'
            textArea.style.top = 0
            textArea.style.left = 0

            // Ensure it has a small width and height. Setting to 1px / 1em
            // doesn't work as this gives a negative w/h on some browsers.
            textArea.style.width = '2em'
            textArea.style.height = '2em'

            // We don't need padding, reducing the size if it does flash render.
            textArea.style.padding = 0

            // Clean up any borders.
            textArea.style.border = 'none'
            textArea.style.outline = 'none'
            textArea.style.boxShadow = 'none'

            // Avoid flash of white box if rendered for any reason.
            textArea.style.background = 'transparent'

            textArea.value = text

            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()

            try {
                document.execCommand('copy')
                this.textCopied = true
                this.successMessage = successMessage
                setTimeout(() => {
                    this.textCopied = false
                    this.successMessage = ''
                }, 3000)
            } catch (err) {
                console.log('Oops, unable to copy')
            }

            document.body.removeChild(textArea)
        },
    },
}
</script>

<style lang="scss">
.summary-links {
    &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;

        &:last-child {
            margin-bottom: 20px;
        }

        &-code {
            white-space: nowrap;
            background: transparent;
            border-color: $color-silver;
            color: $color-border-form;
            font-size: 1.8rem;
            padding: 14px 42px;
            transition: 0.2s;

            &:hover {
                background: $color-burnt-sienna;
                color: $color-white;
            }
        }
    }

    &-button {
        border: 1px solid $color-silver;
        padding: 10px 20px;
        font-size: 2rem;
        cursor: pointer;
        margin-left: 20px;
    }

    &-text {
        &-small {
            font-size: 1.6rem;
            margin-bottom: 10px;
            margin-top: 15px;
        }
        &-margin {
            margin-top: 30px;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }

    &-code {
        color: $color-burnt-sienna;
        font-size: 1.4rem;
    }

    &-link {
        &-title {
            font-size: 2.5rem;
            font-weight: normal;
        }
        &-box {
            border: 1px solid $color-dark-gray;
            background: $color-bg-lighter;
            padding: 27px 22px;
            font-size: 1.7rem;
            margin: 20px 0;
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;

            .red {
                color: $color-burnt-sienna;
            }
        }
    }

    hr {
        border-color: transparent;
        border-bottom-color: $color-silver;
    }

    &.summary & {
        &-link {
            &-title {
                font-size: 1.6rem;
                color: $color-oslo-gray;
            }
        }
        &-text {
            &-small {
                font-size: 1.3rem;
            }
            &-margin {
                display: none;
            }
        }
    }
    @media (max-width: $screen-mobile-large) {
        &-link {
            &-box {
                padding: 17px 12px;
                font-size: 1.4rem;
            }
        }
    }
}
</style>
