<template>
    <div
        :class="[
            'form-field form-field-default',
            { 'is-required': required },
            { 'is-error': error },
            themeClass,
        ]"
    >
        <div class="form-field-label">
            <label v-text="label" />
        </div>
        <div class="form-field-wrapper">
            <slot name="prepend" />
            <slot>
                <div class="form-field-input">
                    <div class="form-field-input-error-message" v-text="error" />
                    <input
                        v-if="mask"
                        v-model="inputValue"
                        v-mask="mask"
                        :name="name"
                        :type="getType"
                        autocomplete="disabled"
                        @focus="isFocused = true"
                        @focusout="onFocusout()"
                        @input="onInput"
                    />
                    <input
                        v-else
                        v-model="inputValue"
                        :name="name"
                        :type="getType"
                        autocomplete="disabled"
                        @focus="isFocused = true"
                        @focusout="onFocusout()"
                        @input="onInput"
                    />
                    <FormTips
                        v-if="isTipsList"
                        :tips="tips"
                        :value="inputValue"
                        @select="onSelect"
                    />
                </div>
            </slot>
            <slot name="append" />
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mask } from 'vue-the-mask'
import FormTips from '@/components/form/input/FormTips'
import ThemeClassMixin from '@/mixins/ThemeClassMixin'

export default {
    components: {
        FormTips,
    },
    directives: {
        mask,
    },
    mixins: [ThemeClassMixin],
    props: {
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        mask: {
            type: String,
            default: null,
        },
        tips: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            inputValue: null,
            error: null,
            isFocused: false,
            themeClassPrefix: 'form-field--',
        }
    },
    computed: {
        isTipsList() {
            return this.inputValue && this.tips.length > 0 && this.isFocused
        },
        getType() {
            return this.type !== 'password' ? 'text' : 'password'
        },
    },
    created() {
        this.inputValue = this.value
    },
    methods: {
        onInput: debounce(function () {
            this.$emit('input', this.inputValue.trim())
        }, 150),
        onSelect(value) {
            this.inputValue = value
            this.$emit('input', this.inputValue.trim())
        },
        onFocusout() {
            setTimeout(() => {
                this.isFocused = false
            }, 200)
        },
        validate() {
            this.error = null
            this.inputValue ? (this.inputValue = this.inputValue.trim()) : false
            return (
                this.validNotEmpty() &&
                this.validMinLength() &&
                this.validEmail() &&
                this.validPostalCode()
            )
        },
        validNotEmpty() {
            let valid = !(this.required && !this.inputValue)
            if (!valid) {
                this.error = 'To pole nie może być puste'
            }
            return valid
        },
        validMinLength() {
            let valid = true
            if (this.inputValue) {
                valid = this.inputValue.length >= 1
            }
            if (!valid) {
                this.error = 'Minimalna ilość znaków - 1'
            }
            return valid
        },
        validEmail() {
            let valid = true
            if (this.inputValue && this.type === 'email') {
                valid = /^(\w+[-.])*\w+@(\w+[-.])+[A-Za-z]+$/.test(
                    this.inputValue
                )
            }
            if (!valid) {
                this.error = 'Podaj poprawny adres email'
            }
            return valid
        },
        validPostalCode() {
            let valid = true
            if (this.inputValue && this.type === 'zip') {
                valid = /^[0-9]{2}-?[0-9]{3}$/.test(this.inputValue)
            }
            if (!valid) {
                this.error = 'Nieprawidłowy kod pocztowy'
            }
            return valid
        },
    },
}
</script>

<style lang="scss">
.form-field {
    position: relative;

    label {
        white-space: nowrap;
    }

    &-wrapper {
        display: flex;
        width: 100%;
        max-width: 340px;
    }

    &-default {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }
    &--wide {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
            display: block;
            margin-bottom: 10px;
        }
    }
    &--wide & {
        &-input {
            width: 100%;
        }
    }

    &--half {
        width: calc(50% - 15px);
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;

        label {
            display: block;
            margin-bottom: 10px;
        }
    }

    &--half & {
        &-input {
            width: 100%;
        }
    }

    &--success {
        input {
            border-color: $color-success2;
            background-color: transparent !important;
        }
    }
    &--success & {
        &-input {
            &::before {
                position: absolute;
                top: 10px;
                right: 10px;
                content: '';
                display: block;
                width: 30px;
                height: 30px;
                background: no-repeat
                    url('~@/assets/images/system-icons/success.png');
            }
        }
    }
    &--error {
        border-color: $color-burnt-sienna;
    }
    &--wrap label {
        white-space: normal;
    }

    @media (max-width: $screen-mobile-large) {
        &--half {
            width: 100%;
        }
    }
}
</style>
