<template>
    <!-- eslint-disable -->
    <g>
        <g v-if="format === 'vertical' && patternTheme === 'dark'">
            <defs>
                <linearGradient
                    id="ridge-soft-dark-vertical-linear-gradient"
                    x1="-0.127"
                    y1="0.565"
                    x2="0.164"
                    y2="0.565"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#646464" />
                    <stop offset="1" stop-color="#2e2e2e" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="ridge-soft-dark-vertical-linear-gradient-2"
                    x1="0.022"
                    y1="0.465"
                    x2="1.172"
                    y2="0.467"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#9b9b9b" stop-opacity="0.2" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
            </defs>
            <path
                id="Path_7286"
                data-name="Path 7286"
                class="ridge-soft-dark-vertical-3"
                d="M0,0H75.908V544.11H0Z"
                transform="translate(690.604 259)"
            />
            <rect
                id="Rectangle_1493"
                data-name="Rectangle 1493"
                class="ridge-soft-dark-vertical-4"
                width="395"
                height="1"
                transform="translate(691 258)"
            />
            <path
                id="Path_7301"
                data-name="Path 7301"
                class="ridge-soft-dark-vertical-5"
                d="M0,0H385.115V544.11H0Z"
                transform="translate(690.605 259)"
            />
        </g>
        <g v-if="format === 'vertical' && patternTheme === 'light'">
            <defs>
                <linearGradient
                    id="ridge-soft-light-vertical-linear-gradient"
                    x1="-0.127"
                    y1="0.565"
                    x2="0.164"
                    y2="0.565"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#7e7e7e" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="ridge-soft-light-vertical-linear-gradient-2"
                    x1="0.022"
                    y1="0.465"
                    x2="1.172"
                    y2="0.467"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#9b9b9b" stop-opacity="0.2" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
            </defs>

            <path
                id="Path_7286"
                data-name="Path 7286"
                class="ridge-soft-light-vertical-3"
                d="M0,0H75.908V544.11H0Z"
                transform="translate(690.604 259)"
            />
            <rect
                id="Rectangle_1493"
                data-name="Rectangle 1493"
                class="ridge-soft-light-vertical-4"
                width="395"
                height="1"
                transform="translate(691 258)"
            />
            <path
                id="Path_7301"
                data-name="Path 7301"
                class="ridge-soft-light-vertical-5"
                d="M0,0H385.115V544.11H0Z"
                transform="translate(690.605 259)"
            />
        </g>
        <!-- horizontal - soft  -->
        <g
            v-if="format === 'horizontal' && patternTheme === 'dark'"
            transform="translate(691 259)"
        >
            <defs>
                <linearGradient
                    id="soft-dark-horiz-gradient"
                    x1="-0.127"
                    y1="0.565"
                    x2="0.164"
                    y2="0.565"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#646464" />
                    <stop offset="1" stop-color="#2e2e2e" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="soft-dark-horiz-gradient-2"
                    x1="0.022"
                    y1="0.465"
                    x2="1.172"
                    y2="0.467"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#9b9b9b" stop-opacity="0.2" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
            </defs>
            <path
                id="Path_7286"
                data-name="Path 7286"
                d="M0,0H116.717V226.665H0Z"
                transform="translate(0 0.583)"
                fill="url(#soft-dark-horiz-gradient)"
            />
            <rect
                id="Rectangle_1493"
                data-name="Rectangle 1493"
                width="374"
                transform="translate(0.792 0)"
                fill="rgba(255,255,255,0.38)"
            />
            <path
                id="Path_7301"
                data-name="Path 7301"
                d="M0,0H372.734V226.665H0Z"
                transform="translate(0 0.583)"
                fill="url(#soft-dark-horiz-gradient-2)"
            />
        </g>
        <g
            v-if="format === 'horizontal' && patternTheme === 'light'"
            transform="translate(691 259)"
        >
            <defs>
                <linearGradient
                    id="soft-light-horiz-gradient"
                    x1="-0.127"
                    y1="0.565"
                    x2="0.164"
                    y2="0.565"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#7e7e7e" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="soft-light-horiz-gradient-2"
                    x1="0.022"
                    y1="0.465"
                    x2="1.172"
                    y2="0.467"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#9b9b9b" stop-opacity="0.2" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
            </defs>
            <path
                id="Path_7286"
                data-name="Path 7286"
                d="M0,0H231.75V227.051H0Z"
                transform="translate(0 0)"
                fill="url(#soft-light-horiz-gradient)"
            />
            <rect
                id="Rectangle_1493"
                data-name="Rectangle 1493"
                width="379"
                height="1"
                transform="translate(2.762 0.257)"
                fill="rgba(255,255,255,0.38)"
            />
            <path
                id="Path_7301"
                data-name="Path 7301"
                d="M0,0H372.565V227.051H0Z"
                transform="translate(0 0)"
                fill="url(#soft-light-horiz-gradient-2)"
            />
        </g>

        <!-- square - soft  -->
        <g
            v-if="format === 'square' && patternTheme === 'dark'"
            transform="translate(691 259)"
        >
            <defs>
                <linearGradient
                    id="soft-dark-square-gradient"
                    x1="-0.127"
                    y1="0.565"
                    x2="0.164"
                    y2="0.565"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#646464" />
                    <stop offset="1" stop-color="#2e2e2e" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="soft-dark-square-gradient-2"
                    x1="0.022"
                    y1="0.465"
                    x2="1.172"
                    y2="0.467"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#9b9b9b" stop-opacity="0.2" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
            </defs>
            <path
                id="Path_7286"
                data-name="Path 7286"
                d="M0,0H52.318V261.29H0Z"
                transform="translate(0.542 0.935)"
                fill="url(#soft-dark-square-gradient)"
            />
            <rect
                id="Rectangle_1493"
                data-name="Rectangle 1493"
                width="266"
                transform="translate(1.001 0.143)"
                fill="rgba(255,255,255,0.38)"
            />
            <path
                id="Path_7301"
                data-name="Path 7301"
                d="M0,0H264.43V261.29H0Z"
                transform="translate(0.541 0.935)"
                fill="url(#soft-dark-square-gradient-2)"
            />
        </g>
        <g
            v-if="format === 'square' && patternTheme === 'light'"
            transform="translate(691 259)"
        >
            <defs>
                <linearGradient
                    id="soft-light-square-gradient"
                    x1="-0.127"
                    y1="0.565"
                    x2="0.164"
                    y2="0.565"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#7e7e7e" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="soft-light-square-gradient-2"
                    x1="0.022"
                    y1="0.465"
                    x2="1.172"
                    y2="0.467"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#9b9b9b" stop-opacity="0.2" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
            </defs>
            <path
                id="Path_7286"
                data-name="Path 7286"
                d="M0,0H52.034V260.694H0Z"
                transform="translate(0 0)"
                fill="url(#soft-light-square-gradient)"
            />
            <rect
                id="Rectangle_1493"
                data-name="Rectangle 1493"
                width="269"
                height="1"
                transform="translate(0.957 0.242)"
                fill="rgba(255,255,255,0.38)"
            />
            <path
                id="Path_7301"
                data-name="Path 7301"
                d="M0,0H263.99V260.694H0Z"
                transform="translate(0 0)"
                fill="url(#soft-light-square-gradient-2)"
            />
        </g>
    </g>
</template>

<script>
export default {
    props: {
        format: {
            type: String,
            default: '',
        },
        patternTheme: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss">
.ridge-soft-dark-vertical-3 {
    fill: url(#ridge-soft-dark-vertical-linear-gradient);
}
.ridge-soft-dark-vertical-4 {
    fill: rgba(255, 255, 255, 0.38);
}
.ridge-soft-dark-vertical-5 {
    fill: url(#ridge-soft-dark-vertical-linear-gradient-2);
}

.ridge-soft-light-vertical-3 {
    fill: url(#ridge-soft-light-vertical-linear-gradient);
}
.ridge-soft-light-vertical-4 {
    fill: rgba(255, 255, 255, 0.38);
}
.ridge-soft-light-vertical-5 {
    fill: url(#ridge-soft-light-vertical-linear-gradient-2);
}
</style>
