<template>
    <g v-if="isSoft">
        <RidgeSoft
            v-if="!softCover"
            :format="format"
            :pattern-theme="patternTheme"
        />
        <RidgeSoftSoft v-else :format="format" :pattern-theme="patternTheme" />
    </g>
    <g v-else>
        <image
            v-if="format === 'vertical' && patternTheme === 'dark'"
            :href="base64[dark.vertical]"
            transform="translate(691 259)"
        />
        <image
            v-if="format === 'vertical' && patternTheme === 'light'"
            :href="base64[light.vertical]"
            transform="translate(691 259)"
        />
        <image
            v-if="format === 'horizontal' && patternTheme === 'dark'"
            :href="base64[dark.horizontal]"
            transform="translate(691 259)"
        />
        <image
            v-if="format === 'horizontal' && patternTheme === 'light'"
            :href="base64[light.horizontal]"
            transform="translate(691 259)"
        />
        <image
            v-if="format === 'square' && patternTheme === 'dark'"
            :href="base64[dark.square]"
            transform="translate(691 259)"
        />
        <image
            v-if="format === 'square' && patternTheme === 'light'"
            :href="base64[light.square]"
            transform="translate(691 259)"
        />
    </g>
</template>

<script>
import RidgeSoft from './RidgeSoft'
import RidgeSoftSoft from './RidgeSoftSoft'
import { mapState } from 'vuex'
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    components: {
        RidgeSoft,
        RidgeSoftSoft,
    },
    mixins: [ConvertToBase64],
    props: {
        format: {
            type: String,
            default: '',
        },
        patternTheme: {
            type: String,
            default: 'dark',
        },
        softCover: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            light: {
                vertical: '/img/notes/cover/hard-light.png',
                horizontal: '/img/notes/cover/205-hard-light.png',
                square: '/img/notes/cover/144-hard-light.png',
            },
            dark: {
                vertical: '/img/notes/cover/hard-dark.png',
                horizontal: '/img/notes/cover/205-hard-dark.png',
                square: '/img/notes/cover/144-hard-dark.png',
            },
        }
    },
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
        }),
        isSoft() {
            const soft =
                this.configuration.coverRidge &&
                this.configuration.coverRidge.title.pl === `Miękki`
            return typeof soft === 'boolean' ? soft : true
        },
    },
    created() {
        Object.keys(this.light).forEach((format) => {
            this.toDataUrl(this.light[format])
        })
        Object.keys(this.dark).forEach((format) => {
            this.toDataUrl(this.dark[format])
        })
    },
}
</script>
