var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'input-default',
        {
            'is-set': _vm.storedValue,
            'is-focused': _vm.focus,
            'is-filled': _vm.value && _vm.value.title.pl,
        },
    ]},[_c('InputDefaultText',{attrs:{"id":_vm.id,"stored-value":_vm.storedValue,"placeholder":_vm.placeholder,"type":_vm.type,"value":_vm.value},on:{"changeVal":_vm.onChangeValue,"edit":_vm.edit,"input":_vm.add}}),(_vm.childrenGroups && _vm.storedValue)?_c('div',_vm._l((_vm.childrenGroups),function(childrenGroup,index){return _c('InputCheckboxChildren',{key:childrenGroup.name,attrs:{"id":childrenGroup.id,"title":childrenGroup.title[_vm.locale],"type":childrenGroup.type,"items":childrenGroup.items,"required":childrenGroup.required,"current-settings":_vm.childrenSettings,"disabled":index > _vm.currentEnabled},on:{"edit":_vm.onSelect,"select":_vm.onSelect}})}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }