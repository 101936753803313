/* 
    Lista opcji i stron. Jeśli te wartości (params) zostaną wykluczone w zależnościach
    to ta strona (pageId) zostanie ukryta w menu
*/
export default [
    {
        params: ['WYKLEJKA_RODZAJ_PAPIERU', 'WYKLEJKA_RODZAJ_ZADRUKU'],
        pageId: 'flyleaf',
    },
    {
        params: [
            'OKLADKA_ZNAKOWANIE_DRUK',
            'OKLADKA_ZNAKOWANIE_TLOCZENIE',
            'OKLADKA_ZNAKOWANIE_WYKONCZENIE',
        ],
        pageId: 'coverMarking',
    },
    {
        params: ['KRAWEDZIE_TYP'],
        pageId: 'edgeColoration',
    },
    {
        params: ['WYKLEJKA_RODZAJ_ZADRUKU', 'WYKLEJKA_RODZAJ_PAPIERU'],
        pageId: 'flyleaf',
    },
    {
        params: ['OPAKOWANIE'],
        pageId: 'packagePage',
    },
    {
        params: ['FORMAT'],
        pageId: 'format',
    },
    {
        params: [
            'DODATKOWE_STRONY_MIEJSCE',
            'DODATKOWE_STRONY_RODZAJ_DRUKU',
            'DODATKOWE_STRONY_RODZAJ_PAPIERU',
            'KALENDARZ',
        ],
        pageId: 'personalized',
    },
    {
        params: [
            'BLOK_RODZAJ_PAPIERU',
            'BLOK_OTWORY',
            'BLOK_PERFORACJA',
            'BLOK_ILOSC_KARTEK',
            'BLOK_RODZAJ_ZADRUKU',
        ],
        pageId: 'inside',
    },
]
