<template>
    <!-- eslint-disable -->
    <g v-if="pocketType">
        <defs>
            <linearGradient
                id="vertical-pocket-gradient-5"
                x1="0.805"
                y1="0.794"
                x2="0.129"
                y2="0.793"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.361" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="vertical-pocket-gradient-6"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0" />
                <stop offset="1" stop-opacity="0.11" />
            </linearGradient>
            <linearGradient
                id="vertical-pocket-gradient-7"
                x1="0.185"
                y1="-0.083"
                x2="0.864"
                y2="0.775"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0" />
                <stop offset="1" stop-opacity="0.31" />
            </linearGradient>
            <linearGradient
                id="vertical-pocket-gradient-8"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.322" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="vertical-pocket-gradient-9"
                x1="1.463"
                y1="0.341"
                x2="0.204"
                y2="0.341"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.231" />
                <stop offset="1" stop-opacity="0.039" />
            </linearGradient>
            <linearGradient
                id="vertical-pocket-gradient-10"
                y1="0.511"
                x2="1.018"
                y2="0.511"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" stop-opacity="0.122" />
                <stop offset="1" stop-opacity="0.161" />
            </linearGradient>
            <linearGradient
                id="vertical-pocket-gradient-11"
                y1="0.36"
                x2="1"
                y2="0.364"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" stop-opacity="0.302" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="vertical-pocket-gradient-12"
                x1="1"
                y1="-0.092"
                x2="0.198"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.38" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <pattern
                id="pocket-pattern-2"
                width="1"
                height="1"
                viewBox="486.845 77.045 149.023 357.591"
            >
                <image
                    preserveAspectRatio="xMidYMid slice"
                    width="793.502"
                    height="491.875"
                    xlink:href="/img/notes/pocket-img.jpg"
                />
            </pattern>
        </defs>
        <g
            v-if="roundCorners"
            id="Group_1174"
            data-name="Group 1174"
            transform="translate(2211 2547)"
        >
            <g id="TU_INNY_KOLOR" transform="translate(465 330.069)">
                <path
                    id="Path_390"
                    data-name="Path 390 h"
                    d="M0,0H108.5V184.876H0Z"
                    transform="translate(-149.188 10.921)"
                    class="pocket-horizontal-13"
                    :class="colorClass"
                    :style="colorInsideStyle"
                />
                <path
                    id="Path_385"
                    data-name="Path 385"
                    d="M0,0H11.069L5.534,8.444Z"
                    transform="translate(-150 11.032)"
                    class="pocket-horizontal-14"
                    :class="colorClass"
                    :style="colorStyle"
                />
                <path
                    id="Path_386"
                    data-name="Path 386"
                    d="M2.984-30.77l.205,19.528H-3Z"
                    transform="translate(-141.402 30.836)"
                    class="pocket-horizontal-13"
                    :class="colorClass"
                    :style="colorInsideStyle"
                />
                <path
                    id="Path_387"
                    data-name="Path 387"
                    d="M0,8.444H11.069L5.534,0Z"
                    transform="translate(-149.59 187.416)"
                    class="pocket-horizontal-14"
                    :class="colorClass"
                    :style="colorStyle"
                />
                <path
                    id="Path_388"
                    data-name="Path 388"
                    d="M3.751-27.138,4.3-45H-2.155Z"
                    transform="translate(-142.31 231.067)"
                    class="pocket-horizontal-13"
                    :class="colorClass"
                    :style="colorInsideStyle"
                />
                <path
                    id="Path_6300"
                    data-name="Path 6300"
                    d="M659.624,152.629a7.584,7.584,0,0,0-7.584-7.584h-.078l-250.229-10.9V339.537l250.716-9.6,0-.011a7.584,7.584,0,0,0,7.172-7.573v-.025l-.049-168.834A7.655,7.655,0,0,0,659.624,152.629Z"
                    transform="translate(-540.265 -134.146)"
                    class="pocket-horizontal-14"
                    :class="colorClass"
                    :style="colorStyle"
                />
                <g
                    v-if="
                        colorClass &&
                        colorClass !== 'notes-pocket-color-flyleaf'
                    "
                    id="Group_1210"
                    data-name="Group 1210"
                    transform="translate(-354.868 -267.658)"
                >
                    <g id="Group_1177" data-name="Group 1177">
                        <path
                            id="Path_6240"
                            data-name="Path 6240"
                            d="M307.082,326.675v.763c6.488.449,3.152,8.771,2.363,10.5l-3.336,7.351-6.674-12.626c-3.152-6.127-.1-5.811,1.066-5.731v-.718l-13.348-.94v.732c1.348.145,2.873.307,4.909,4.389,0,0,2.086,3.755,8.9,17.486v8.454c0,3.845-1.762,5.141-3.708,5.1v.824l12.93.262v-.81c-1.946-.041-3.708-1.4-3.708-5.218V346.118l3.291-7.353c5.144-10.785,8.62-10.544,10.242-10.431v-.749Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_6241"
                            data-name="Path 6241"
                            d="M334.657,328.177c-10.2-.724-15.526,7.294-15.526,17.187,0,9.945,5.33,17.895,15.526,18.094s15.526-7.194,15.526-16.7C350.183,337.3,344.853,328.9,334.657,328.177Zm0,34.549c-5.515-.114-8.9-3.452-8.9-17.064,0-13.563,3.383-17.139,8.9-16.753,5.469.384,8.806,4.331,8.806,17.549C343.463,359.723,340.127,362.839,334.657,362.726Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_6242"
                            data-name="Path 6242"
                            d="M367.606,364.1c4.91.1,11.4-2.062,11.359-11.068V340.055c0-2.194-.1-7.685,6.442-7.186v-.679l-13.025-.916v.692c5.885.362,5.885,5.853,5.885,8.049V353.01c0,5.811-3.85,8.833-7.971,8.737-4.727-.11-8.157-3.52-8.157-9.2v-17.17c0-3.518,2.039-3.987,4.032-3.848v-.7l-13.161-.926v.713c2.039.141,3.476,1.329,3.476,4.357v16.986c0,7.712,5.005,12.021,11.122,12.141Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_6243"
                            data-name="Path 6243"
                            d="M412.611,359.917c-2.318-8.759-5.747-10-8.806-10.045,5.236-.205,8.856-1.907,8.856-7.493,0-7.232-5.84-8.686-15.062-9.336l-10.154-.715v.677c2.039.141,3.1,1.157,3.1,3.944v21.859c0,3.411-1.111,4.644-3.1,4.6v.677l11.957.241v-.663c-1.947-.042-3.244-1.363-3.244-4.428v-9.3l2.178.094a6.82,6.82,0,0,1,6.488,4.842c2.966,9.579,1.993,9.56,9.13,9.751l3.893.079v-.648A5.822,5.822,0,0,1,412.611,359.917Zm-15.02-10.582-1.433-.063V333.613l1.436.1c4.727.328,8.713.517,8.713,8.306h0C406.3,349.721,402.5,349.553,397.591,349.335Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_6244"
                            data-name="Path 6244"
                            d="M311.855,397.043c-1.206,10.251-11.263,10.682-13.766,10.789l-1.668.071V377.722c0-3.714,1.854-4.582,3.85-4.571v-.77l-12.84-.08v.788c1.993.01,3.29,1.369,3.29,4.541v24.852c0,3.536-.927,5.707-2.919,5.793v.783l24.7-1.085V397.025Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_6245"
                            data-name="Path 6245"
                            d="M348.328,389.779c0-9.483-5.33-17.565-15.526-17.635s-15.526,8.31-15.526,18.231c0,9.971,5.33,17.6,15.526,17.146s15.527-8.211,15.527-17.742ZM332.8,406.786c-5.515.24-8.9-2.889-8.9-16.538,0-13.6,3.383-17.4,8.9-17.37,5.47.033,8.806,3.776,8.806,17.03C341.608,403.21,338.272,406.548,332.8,406.786Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_6246"
                            data-name="Path 6246"
                            d="M371.037,392.444v.694c2.874-.072,3.851.088,3.851,6.665,0,4.185-1.484,5.216-6.906,5.452-5.191.225-8.574-2.971-8.574-15.924s3.288-16.278,8.576-16.247c5.468.032,9.083,2.116,10.107,9.487l1.3-.011.324-7.582c-2.873-1.888-6.534-2.556-11.725-2.591-10.012-.069-15.8,7.605-15.8,17.08s5.79,16.93,15.8,16.484a35.008,35.008,0,0,0,11.906-2.446c0-9.954.463-10.6,3.152-10.668v-.681Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_6247"
                            data-name="Path 6247"
                            d="M401.629,372.618c-10.2-.07-15.529,7.486-15.529,16.436,0,9,5.33,15.854,15.526,15.4s15.526-7.441,15.526-15.995h0C417.155,379.947,411.825,372.689,401.629,372.618Zm0,31.174c-5.515.24-8.9-2.565-8.9-14.865,0-12.255,3.383-15.68,8.9-15.648,5.466.033,8.806,3.4,8.806,15.308C410.435,400.54,407.1,403.554,401.629,403.792Z"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
            <g
                id="Group_767"
                data-name="Group 767"
                transform="translate(465.41 330.069)"
            >
                <line
                    id="Line_180"
                    data-name="Line 180"
                    y2="184.912"
                    transform="translate(-149.795 11.085)"
                    stroke="rgba(45,46,47,0.15)"
                    stroke-width="1.5"
                    class="pocket-horizontal-15"
                />
                <path
                    id="Path_527"
                    data-name="Path 527"
                    d="M3.432-27.69,3.955-45h-6.11Z"
                    transform="translate(-142.311 231.887)"
                    class="pocket-horizontal-16"
                />
                <path
                    id="Path_529"
                    data-name="Path 529"
                    d="M0,0H7.893L5.228,8.527Z"
                    transform="translate(-150 11.032)"
                    class="pocket-horizontal-17"
                />
                <path
                    id="Path_526"
                    data-name="Path 526"
                    d="M2.984-30.77,2.848-11.241H-3Z"
                    transform="translate(-141.812 30.836)"
                    class="pocket-horizontal-18"
                />
                <path
                    id="Path_392"
                    data-name="Path 392"
                    d="M.034.213,5.262,8.83H11.07V176.456H5.6L0,185.177Z"
                    transform="translate(-150 10.819)"
                    class="pocket-horizontal-19"
                />
                <path
                    id="Path_528"
                    data-name="Path 528"
                    d="M0,8.444H8.268L5.534,0Z"
                    transform="translate(-150 187.416)"
                    class="pocket-horizontal-22"
                />
                <path
                    id="Path_6302"
                    data-name="Path 6302"
                    d="M659.624,152.629a7.584,7.584,0,0,0-7.584-7.584h-.078l-250.229-10.9V339.537l250.716-9.6,0-.011a7.584,7.584,0,0,0,7.172-7.573v-.025l-.049-168.834A7.655,7.655,0,0,0,659.624,152.629Z"
                    transform="translate(-540.675 -134.146)"
                    class="pocket-horizontal-19"
                />
                <line
                    id="Line_179"
                    data-name="Line 179"
                    y2="205.412"
                    transform="translate(-138.827 0.015)"
                    fill="none"
                    stroke="rgba(255,255,255,0.35)"
                    stroke-width="1"
                    class="pocket-horizontal-23"
                />
            </g>
        </g>
        <g
            v-else
            id="Group_1335-2"
            data-name="Group 1335"
            transform="translate(2016.5 2547)"
        >
            <g id="TU_INNY_KOLOR" transform="translate(490.656 330.138)">
                <path
                    id="Path_390"
                    data-name="Path 390 rh"
                    class="pocket-horizontal-13"
                    :class="colorClass"
                    :style="colorInsideStyle"
                    d="M0,0H274.453V177.155H0Z"
                    transform="translate(1.179 15.977)"
                />
                <path
                    id="Path_385"
                    data-name="Path 385"
                    class="pocket-horizontal-14"
                    :class="colorClass"
                    :style="colorStyle"
                    d="M0,0H20.761L10.38,12.512Z"
                    transform="translate(0 10.977)"
                />
                <path
                    id="Path_386"
                    data-name="Path 386"
                    class="pocket-horizontal-13"
                    :class="colorClass"
                    :style="colorInsideStyle"
                    d="M9.219-30.77,9.638-1.6H-3Z"
                    transform="translate(11.123 30.77)"
                />
                <path
                    id="Path_387"
                    data-name="Path 387"
                    class="pocket-horizontal-14"
                    :class="colorClass"
                    :style="colorStyle"
                    d="M0,13.941H19.962L9.981,0Z"
                    transform="translate(0.045 181.8)"
                />
                <path
                    id="Path_388"
                    data-name="Path 388"
                    class="pocket-horizontal-13"
                    :class="colorClass"
                    :style="colorInsideStyle"
                    d="M7.922-19.78,8.866-45H-2.155Z"
                    transform="translate(11.915 225.521)"
                />
                <path
                    id="Path_389"
                    data-name="Path 389"
                    class="pocket-horizontal-14"
                    :class="colorClass"
                    :style="colorStyle"
                    d="M0-30,267.965-19.122v184.93L0,175.236Z"
                    transform="translate(19.878 30.077)"
                />
            </g>
            <g
                id="Group_767"
                data-name="Group 767"
                transform="translate(490.645 330.063)"
            >
                <line
                    id="Line_180"
                    data-name="Line 180"
                    class="pocket-horizontal-15"
                    y2="168.142"
                    transform="translate(0 16.192)"
                />
                <path
                    id="Path_527"
                    data-name="Path 527"
                    class="pocket-horizontal-16"
                    d="M7.922-19.78,8.866-45H-2.155Z"
                    transform="translate(11.927 225.596)"
                />
                <path
                    id="Path_529"
                    data-name="Path 529"
                    class="pocket-horizontal-17"
                    d="M0,0H15.607L10.441,12.549Z"
                    transform="translate(0.031 11.052)"
                />
                <path
                    id="Path_526"
                    data-name="Path 526"
                    class="pocket-horizontal-18"
                    d="M6.653-30.77,6.433-6.684H-3Z"
                    transform="translate(13.507 30.845)"
                />
                <path
                    id="Path_392"
                    data-name="Path 392"
                    class="pocket-horizontal-19"
                    d="M-.205-4.271,10.16,8.238h9.48l.063,158.8H9.842L-.246,180.49Z"
                    transform="translate(0.246 15.326)"
                />
                <path
                    id="Path_525"
                    data-name="Path 525"
                    class="pocket-horizontal-20"
                    d="M0-30,268.2-19.1V166.149L0,175.594Z"
                    transform="translate(19.694 30.152)"
                />
                <path
                    id="Path_391"
                    data-name="Path 391"
                    class="pocket-horizontal-21"
                    d="M0-30,233.138-19.1V166.149L0,175.594Z"
                    transform="translate(19.693 30.152)"
                />
                <path
                    id="Path_528"
                    data-name="Path 528"
                    class="pocket-horizontal-22"
                    d="M-.025,14.09H15.727L10-.082Z"
                    transform="translate(0.193 181.881)"
                />
                <line
                    id="Line_798"
                    data-name="Line 798"
                    class="pocket-horizontal-23"
                    x1="0.381"
                    y2="205.438"
                    transform="translate(20.355)"
                />
            </g>
            <g
                v-if="colorClass && colorClass !== 'notes-pocket-color-flyleaf'"
                id="Group_1338"
                data-name="Group 1338"
                transform="translate(404.63 75.88)"
            >
                <path
                    id="Path_7192"
                    data-name="Path 7192"
                    class="pocket-horizontal-5"
                    d="M218.722,339.606v7.814c0,2.874,1.663,3.877,3.5,3.887v.611l-12.37-.06v-.621c1.888.011,3.585-.981,3.585-3.876v-6.37c-6.626-10.265-8.68-13.067-8.68-13.067-2.018-3.054-3.542-3.158-4.884-3.254v-.55l13.12.56v.54c-1.116-.047-4.094-.251-1.025,4.333l6.4,9.431,3.157-5.585c.743-1.3,3.865-7.621-2.228-7.876v-.575l12.051.515v.564c-1.494-.062-4.708-.2-9.512,7.993Z"
                />
                <path
                    id="Path_7193"
                    data-name="Path 7193"
                    class="pocket-horizontal-5"
                    d="M244.525,325.7c-9.124-.393-13.982,5.721-13.982,13.184,0,7.5,4.858,13.444,13.982,13.484,8.877.038,13.435-5.632,13.435-12.84C257.96,332.352,253.4,326.079,244.525,325.7Zm0,26.114c-4.9-.025-7.951-2.511-7.951-12.788,0-10.24,3.05-12.979,7.951-12.772,4.79.2,7.679,3.129,7.679,13.139C252.2,349.435,249.315,351.834,244.525,351.81Z"
                />
                <path
                    id="Path_7194"
                    data-name="Path 7194"
                    class="pocket-horizontal-5"
                    d="M263.265,343.388v-12.9c0-2.3-1.2-3.177-2.915-3.248V326.7l10.906.465v.532c-1.628-.068-3.3.326-3.3,3v13.058c0,4.317,2.812,6.871,6.648,6.9,3.312.024,6.371-2.333,6.371-6.775v-9.935c0-1.679,0-5.876-4.692-6.037v-.529l10.291.439v.52c-5.123-.249-5.05,3.948-5.05,5.625v9.926c.037,6.886-5.129,8.61-9.1,8.593C267.418,352.46,263.265,349.245,263.265,343.388Z"
                />
                <path
                    id="Path_7195"
                    data-name="Path 7195"
                    class="pocket-horizontal-5"
                    d="M310.86,352.349l-2.818-.014c-5.231-.059-4.512-.056-6.707-7.419a4.989,4.989,0,0,0-4.856-3.631l-1.647-.037V348.4c0,2.362.982,3.361,2.45,3.369v.511l-9.124-.044v-.518c1.539.008,2.4-.952,2.4-3.571V331.365c0-2.136-.82-2.894-2.4-2.96v-.519l7.761.331c6.892.294,11.179,1.283,11.179,6.889,0,4.332-2.646,5.714-6.521,5.964,2.268-.016,4.793.883,6.487,7.634a4.2,4.2,0,0,0,3.8,3.144Zm-14.941-23.62-1.087-.045v12.05l1.087.025c3.69.085,6.515.15,6.515-5.8C302.434,328.935,299.471,328.878,295.919,328.729Z"
                />
                <path
                    id="Path_7196"
                    data-name="Path 7196"
                    class="pocket-horizontal-5"
                    d="M225.346,377.244v8.239L201.537,386.5v-.588c1.981-.083,2.9-1.725,2.9-4.383V362.841c0-2.384-1.285-3.394-3.267-3.382v-.589l12.583-.061v.579c-1.925.011-3.725.68-3.725,3.473v22.694l1.62-.068c2.417-.1,11.967-.5,13.094-8.225Z"
                />
                <path
                    id="Path_7197"
                    data-name="Path 7197"
                    class="pocket-horizontal-5"
                    d="M243.785,385.029c-9.137.394-14.011-5.319-14.011-12.827,0-7.471,4.874-13.831,14.011-13.87,8.9-.039,13.461,6.032,13.461,13.21C257.246,378.757,252.685,384.645,243.785,385.029Zm0-26.143c-4.913.025-7.961,2.921-7.961,13.17,0,10.287,3.048,12.627,7.961,12.419,4.8-.2,7.7-2.739,7.7-12.8C251.484,361.662,248.587,358.861,243.785,358.886Z"
                />
                <path
                    id="Path_7198"
                    data-name="Path 7198"
                    class="pocket-horizontal-5"
                    d="M285.524,373.19v.521c-2.1.06-2.469.559-2.469,8.16a28.777,28.777,0,0,1-9.5,1.874c-8.2.354-13.046-5.282-13.046-12.462s4.842-13.045,13.046-13.081c4.176-.018,7.089.46,9.357,1.878l-.255,5.793-1.023.017c-.807-5.62-3.681-7.18-8.079-7.157-4.3.022-7.014,2.575-7.014,12.407s2.786,12.254,7.014,12.075c4.361-.185,5.543-.972,5.543-4.164,0-5.017-.775-5.135-3.076-5.069v-.529Z"
                />
                <path
                    id="Path_7199"
                    data-name="Path 7199"
                    class="pocket-horizontal-5"
                    d="M299.712,382.617c-7.7.332-11.8-4.924-11.8-11.811,0-6.852,4.1-12.684,11.8-12.717,7.521-.033,11.381,5.555,11.381,12.16C311.093,376.888,307.233,382.292,299.712,382.617Zm0-24.019c-4.143.022-6.712,2.679-6.712,12.086,0,9.441,2.569,11.6,6.712,11.423,4.057-.172,6.506-2.494,6.506-11.74C306.218,361.154,303.769,358.577,299.712,358.6Z"
                />
            </g>
        </g>
    </g>
</template>

<script>
import FlyleafPageMixin from '@/mixins/FlyleafPageMixin'

export default {
    mixins: [FlyleafPageMixin],
    props: {
        pocketType: {
            type: String,
            default: '',
        },
        pocketPrint: {
            type: String,
            default: '',
        },
        roundCorners: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
.pocket-horizontal-5 {
    fill: #fff;
}

.pocket-horizontal-15,
.pocket-horizontal-23,
.pocket-horizontal-25,
.pocket-horizontal-9 {
    fill: none;
}

.pocket-horizontal-9 {
    stroke: #707070;
    stroke-width: 4px;
    opacity: 0.18;
}

.pocket-horizontal-10 {
    opacity: 0.31;
}

.pocket-horizontal-11 {
    opacity: 0.26;
}

.pocket-horizontal-12 {
    fill: url(#vertical-pocket-gradient-5);
}

.pocket-horizontal-13 {
    fill: #efefef;

    &.one {
        fill: #efefef;
    }
    &.cmyk {
        fill: #efefef;
    }
    &.oneone {
        fill: #7eb9bf;
    }
    &.cmykcmyk {
        fill: #3e5873;
    }
}

.pocket-horizontal-14 {
    fill: #fff;

    &.one {
        fill: #7eb9bf;
    }
    &.cmyk {
        fill: url(#pocket-pattern-2);
    }
    &.oneone {
        fill: #7eb9bf;
    }
    &.cmykcmyk {
        fill: url(#pocket-pattern-2);
    }
}
.pocket-horizontal-99 {
    fill: #fff;

    &.one {
        fill: #7eb9bf;
    }
    &.cmyk {
        fill: #3e5873;
    }
    &.oneone {
        fill: #7eb9bf;
    }
    &.cmykcmyk {
        fill: #3e5873;
    }
}

.pocket-horizontal-15 {
    fill: #fff;
    stroke: rgba(45, 46, 47, 0.15);
    stroke-width: 1.5px;

    &.one {
        fill: #d8d8d8;
    }
    &.oneone {
        fill: #7eb9bf;
    }
    &.cmyk {
        fill: #d8d8d8;
    }
    &.cmykcmyk {
        fill: #3e5873;
    }
    &-flyleaf {
        fill: url('#flyleafPaper');
    }
}

.pocket-horizontal-16 {
    fill: url(#vertical-pocket-gradient-6);
}

.pocket-horizontal-17 {
    fill: url(#vertical-pocket-gradient-7);
}

.pocket-horizontal-18 {
    fill: url(#vertical-pocket-gradient-8);
}

.pocket-horizontal-19 {
    fill: url(#vertical-pocket-gradient-9);
}

.pocket-horizontal-20 {
    fill: url(#vertical-pocket-gradient-10);
}

.pocket-horizontal-21 {
    opacity: 0.23;
    fill: url(#vertical-pocket-gradient-11);
}

.pocket-horizontal-22 {
    fill: url(#vertical-pocket-gradient-12);
}

.pocket-horizontal-23 {
    stroke: rgba(255, 255, 255, 0.35);
}
</style>
