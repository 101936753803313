<template>
    <div class="workspace" :class="`workspace--${currentPage.id}`">
        <img
            v-if="material && material.name === 'fsc'"
            src="/img/logo/fsc.svg"
            alt="eko logo"
            class="workspace-fsc"
        />

        <div class="workspace-back">
            <a class="workspace-back-button workspace-back-button-active" :href="$t('link.mainWebsite')">
                <span>
                    {{ $t('link.label') }}
                </span>
            </a>
            <LangChange />
            <UserInfo />
        </div>
        <div class="workspace-content">
            <NotesCover
                v-if="!currentPage.hideNotes"
                class="workspace-content-notes"
            />
            <div class="workspace-content-config">
                <transition name="fade" mode="out-in">
                    <MapperConfigPages />
                </transition>
            </div>
        </div>
        <Modal v-if="isModalOpen" :modal-component-name="modalComponentId" />
        <WorkspaceArrows />
        <Debugger v-if="debuggerOn" />
    </div>
</template>

<script>
import { mapState } from 'vuex'

import Modal from '@/components/modal/Modal'
import WorkspaceArrows from '@/components/workspace/WorkspaceArrows'
import Debugger from '@/components/debugger/Debugger'
import NotesCover from '@/components/notes/NotesCover'
import MapperConfigPages from '@/components/mapper/MapperConfigPages'
import LangChange from '@/components/lang/LangChange'
import UserInfo from '@/components/user/UserInfo'

import ConfigurationNumber from '@/mixins/ConfigurationNumber'
import GetCodes from '@/mixins/GetCodes'

export default {
    components: {
        Modal,
        WorkspaceArrows,
        Debugger,
        NotesCover,
        MapperConfigPages,
        LangChange,
        UserInfo,
    },
    mixins: [ConfigurationNumber, GetCodes],
    computed: {
        ...mapState({
            currentPage: (state) => state.page.currentPage,
            isModalOpen: (state) => state.modal.isOpen,
            modalComponentId: (state) => state.modal.componentId,
            configurationApiCode: (state) => state.notes.configurationApiCode,
        }),
        debuggerOn() {
            return process.env.NODE_ENV === 'development'
        },
        material() {
            return this.configuration.material
        },
    },
    watch: {
        currentPage(page) {
            if (
                page.id === 'yourConfig' &&
                !this.configurationApiCode?.calculationId
            ) {
                this.getCodes()
            }
        },
    },
}
</script>

<style lang="scss">
.workspace {
    position: relative;
    overflow: auto;
    height: 100%;
    width: 100%;

    &-fsc {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 100px;
        height: auto;
    }

    &-content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 1290px;
        margin: 0 auto;
        overflow: hidden;
        &-config {
            padding: 80px 0;
        }

        &-config,
        &-notes {
            transition: 0.2s;
            width: 50%;
            margin-right: -70px;
            height: 100%;
        }
    }

    &--paper & {
        &-content {
            overflow: visible;

            &-notes {
                transform: scale(0.9);
                width: 36%;
                height: 800px;
                margin-right: 0;
            }

            &-config {
                width: 64%;
                margin-right: 0;
            }
        }
    }

    &--material & {
        &-content {
            margin: 0 auto;

            &-config {
                padding: 0;
                width: 100%;
                transition: none;
            }
        }
    }

    &--sent & {
        &-content {
            &-config {
                width: 100%;
            }
        }
    }

    &--formPage & {
        &-content {
            &-config {
                width: 70%;
                height: 100%;
            }
        }
    }
    &--offer & {
        &-content {
            &-config {
                height: 100%;
            }
        }
    }
    &--formFinal & {
        &-content {
            &-config {
                height: 100%;
            }
        }
    }

    &--sent & {
        &-content {
            max-width: unset;
            margin: 0;
        }
    }

    &-back {
        position: absolute;
        top: 40px;
        right: 40px;
        z-index: 19;
        display: flex;

        &-button {
            @include size(48px);
            overflow: hidden;
            padding: 0 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid $color-silver;
            cursor: pointer;
            transition: background-color 0.3s, width 0.3s, border-color 0.3s;
            background-color: $color-white;
            position: relative;
            right: 20px;
            position: relative;
            color: $color-white;

            span {
                position: absolute;
                white-space: nowrap;
                transform: translate(-100px);
                opacity: 0;
                margin-left: 10px;
            }

            &::before {
                @include size(10px);
                content: '';
                display: block;
                border: solid $color-default-text;
                border-width: 0 0 2px 2px;
                transform: rotate(45deg);
                margin-left: 2px;
            }
            &-active,
            &:hover {
                border-color: $color-burnt-sienna;
                background-color: $color-burnt-sienna;
                width: 200px;

                span {
                    position: relative;
                    transform: translate(0);
                    opacity: 1;
                }

                &::before {
                    border-color: $color-white;
                }
            }

            &-active {
                &:hover {
                    background-color: $color-bg-dark;
                    border-color: $color-bg-dark;
                }
            }
        }
    }

    @media (max-width: $screen-page) {
        &--paper & {
            &-content {
                &-notes {
                    width: 50%;
                }
            }
        }
    }

    @media (max-width: $screen-large) {
        &-fsc {
            width: 70px;
        }
        &-content {
            margin: 0 80px;
            max-width: calc(100% - 160px);
        }
        &--material & {
            &-content {
                max-width: 100%;
            }
        }
        &--sent & {
            &-content {
                max-width: unset;
                margin: 0;
            }
        }
    }

    @media (max-width: $screen-medium) {
        &-content {
            &-notes,
            &-config {
                margin-right: 0;
            }
        }

        &--formPage & {
            &-content {
                &-config {
                    width: 90%;
                }
            }
        }
        &--yourConfig & {
            &-content {
                &-config {
                    width: 60%;
                }
            }
        }
    }

    @media (max-width: $screen-desktop) {
        &--paper & {
            &-content {
                &-config {
                    width: 50%;
                }
            }
        }
    }

    @media (max-width: $screen-tablet-large) {
        &-content {
            margin: 0 25px;
            max-width: calc(100% - 50px);
        }
    }

    @media (max-width: $screen-tablet-medium) {
        &-content {
            margin: 0 10px;
            max-width: calc(100% - 20px);

            &-config,
            &-notes {
                width: 45%;
            }
        }
        &--formPage & {
            &-content {
                &-config {
                    width: 100%;
                }
            }
        }
        &--material & {
            &-content {
                &-config {
                    padding: 80px 0;
                }
            }
        }
    }
}
</style>
