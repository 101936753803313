<template>
    <g>
        <g :transform="svg[format].penHolder">
            <PenHolder :format="format" />
        </g>
        <g :transform="svg[format].ribbon">
            <Ribbon :format="format" />
        </g>
    </g>
</template>

<script>
import PenHolder from '@/components/notes/accessories/PenHolder'
import Ribbon from '@/components/notes/accessories/Ribbon'

export default {
    components: {
        PenHolder,
        Ribbon,
    },
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            defaultBackground: '#7e7e7e',
            svg: {
                vertical: {
                    elastic: 'translate(-35 22) scale(1 0.855)',
                    penHolder: 'translate(-37 -70)',
                    ribbon: 'translate(-30 -100)',
                },
                horizontal: {
                    elastic: 'translate(1735 2665) scale(1 0.85)',
                    penHolder: 'translate(1730 2606)',
                    ribbon: 'translate(1730 2587)',
                },
                square: {
                    elastic: 'translate(-525 -269) scale(1 1.04)',
                    penHolder: 'translate(-417 -247)',
                    ribbon: 'translate(-470 -251)',
                },
            },
        }
    },
}
</script>
