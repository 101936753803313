<template>
    <!-- eslint-disable -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="svg[formatGroup].width"
        :height="svg[formatGroup].height"
        :viewBox="svg[formatGroup].viewBox"
        class="notes-cover-image"
        :class="[
            `notes-cover-image--${formatGroup} notes-cover-image--${formatSpecific}`,
            currentPage.id === 'format'
                ? `notes-cover-image--zoom-${formatSpecific}`
                : '',
        ]"
    >
        <NotesCoverFrontDefs />
        <PaperPattern :background="background" />
        <defs>
            <linearGradient
                id="cover-marking-gradient"
                x1="0.069"
                y1="0.046"
                x2="0.659"
                y2="0.734"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" />
                <stop offset="0.319" stop-color="#fcfcfc" />
                <stop offset="1" stop-color="gray" />
            </linearGradient>
            <filter
                id="shadow_vector"
                x="128"
                y="126"
                width="418"
                height="578"
                filterUnits="userSpaceOnUse"
            >
                <feOffset dx="20" dy="20" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="9" result="blur" />
                <feFlood flood-opacity="0.102" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>

            <filter
                id="Path_9592"
                x="376.972"
                y="550.782"
                width="136.425"
                height="139.461"
                filterUnits="userSpaceOnUse"
            >
                <feOffset dx="1" dy="-1" input="SourceAlpha" />
                <!-- <feGaussianBlur stdDeviation="1.5" result="blur-2" /> -->
                <!-- <feFlood flood-color="#5d5d5d" /> -->
                <feComposite operator="in" in2="blur-2" />
                <feComposite in="SourceGraphic" />
            </filter>
            <clipPath id="cover-marking-clip-path">
                <path
                    id="Zmiana_koloru_lub_maska"
                    d="M0,0H372.72a12,12,0,0,1,12,12l1.569,452.245c-17.9,12.671-89.984,66.727-110.2,80.571L0,544.11Z"
                    transform="translate(691 259)"
                    fill="#7e7e7e"
                    stroke="rgba(0,0,0,0.27)"
                    stroke-width="1"
                />
            </clipPath>
            <linearGradient
                id="notes-cmyk-gradient"
                x1="1.082"
                y1="0.819"
                x2="0.269"
                y2="0.16"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#516e8a" />
                <stop offset="0.171" stop-color="#395168" />
                <stop offset="0.385" stop-color="#27374a" />
                <stop offset="0.59" stop-color="#1f2c39" />
                <stop offset="0.837" />
                <stop offset="1" stop-color="#2e4358" />
            </linearGradient>
        </defs>
        <g
            id="Group_2035"
            data-name="Group 2035"
            transform="translate(-2481 -5673)"
        >
            <g id="Notes" transform="translate(1913 5533)">
                <g id="notes-2" data-name="notes">
                    <g
                        id="Zmiana_koloru_lub_maska"
                        class="notes-border"
                        transform="translate(691 259)"
                    >
                        <path
                            :fill="cornerFill"
                            class="notes-border-1"
                            d="M0,0H384.72a0,0,0,0,0,0,0V544.11a0,0,0,0,0,0,0H0a0,0,0,0,0,0,0V0A0,0,0,0,0,0,0Z"
                        />
                        <path
                            class="notes-border-2"
                            d="M1,.5H384.72A0,0,0,0,0,0,0V544.11a0,0,0,0,0,0,0H1a0,0,0,0,0,0,0V1A0,0,0,0,0,0,0Z"
                        />
                    </g>
                    <!-- biała kartka -->
                    <g
                        transform="matrix(1, 0, 0, 1, 568, 140)"
                        filter="url(#shadow_vector)"
                    >
                        <rect
                            id="shadow_vector-2"
                            data-name="shadow_vector"
                            width="364"
                            height="524"
                            transform="translate(144 140)"
                            fill="#fff"
                        />

                        <image
                            href="/img/notes/marking/shadow2.png"
                            height="120"
                            width="139"
                            transform="translate(375 546) scale(1.1)"
                            opacity="0.8"
                            style="
                                mix-blend-mode: multiply;
                                isolation: isolate;
                            "
                        />
                    </g>
                    <g
                        id="Zmiana_koloru_lub_maska"
                        transform="translate(691 259)"
                        fill="url(#cover)"
                    >
                        <path
                            d="M0,0H372.72a12,12,0,0,1,12,12l.686,452.2c-37.088,21.257-66.035,47.038-110.811,81.411L0,544.11Z"
                            stroke="none"
                        />
                        <path
                            d="M 1 1 L 1 543.1154174804688 L 274.258544921875 544.6079711914063 C 285.9457397460938 535.6321411132813 296.4054565429688 527.3607177734375 306.523193359375 519.3598022460938 C 334.8467102050781 496.9619140625 357.2571411132813 479.240478515625 384.4053649902344 463.6203308105469 L 383.7200012207031 12 C 383.7200012207031 9.06182861328125 382.5758056640625 6.29937744140625 380.4981994628906 4.2218017578125 C 378.4205322265625 2.14422607421875 375.658203125 1 372.7200012207031 1 L 1 1 M 0 0 L 372.7200012207031 0 C 379.3473815917969 0 384.7200012207031 5.37255859375 384.7200012207031 12 L 385.40625 464.19873046875 C 348.3183288574219 485.4560546875 319.3710632324219 511.236328125 274.5957336425781 545.60986328125 L 0 544.1099853515625 L 0 0 Z"
                            stroke="none"
                            fill="rgba(0,0,0,0.08)"
                        />
                    </g>

                    <Marking
                        transform="translate(0 0)"
                        :format="formatGroup"
                        clip-path="url(#cover-marking-clip-path)"
                    />
                </g>
            </g>

            <path
                id="Path_10608"
                data-name="Path 10608"
                d="M0,0,386.406.155V463.294l-53.55,40.219L279.215,543.8,0,544Z"
                transform="translate(2603 5792)"
                opacity="0.17"
                fill="url(#cover-marking-gradient)"
            />
            <g
                id="Group_1512"
                data-name="Group 1512"
                transform="matrix(1, -0.017, 0.017, 1, -3218.184, 2183.907)"
            >
                <g
                    transform="matrix(1, 0.02, -0.02, 1, 5637.49, 3584.03)"
                    filter="url(#Path_9592)"
                >
                    <image
                        href="/img/notes/marking/shadow.png"
                        height="120"
                        width="139"
                        transform="translate(369 545)"
                        style="mix-blend-mode: multiply; isolation: isolate;"
                    />
                    <!-- tu jest ten róg -->
                    <!-- zmień opacity rogu jak jest dodana folia, nie wiem czy tak jest prawidłowo ale zobaczymy -->
                    <path
                        id="Path_9592-2"
                        data-name="Path 9592"
                        d="M76.485-12.627s4.223,7.685,12.337,37.484,5.287,51.78,5.287,51.78-19.165-3.127-45.875-.311S-8.55,94.8-8.55,94.8L76.485-12.627Z"
                        transform="translate(475.1 672.33) rotate(-165)"
                        :fill="cornerFill"
                        :opacity="haveCoverFinish ? '0.3' : '1'"
                    />
                </g>
                <path
                    id="Path_9594"
                    data-name="Path 9594"
                    d="M10.683,12.227c-15.62,43.343-7.609,91.566-7.609,91.566l12.135-9.745C.335,88.916,10.683,12.227,10.683,12.227Z"
                    transform="translate(6020.898 4152.024)"
                    fill="#fff"
                    opacity="0.04"
                />
                <!-- cień -->
                <linearGradient
                    id="shadow-gradient-corner-2131"
                    x1="1.05"
                    y1="0.843"
                    x2="0.455"
                    y2="0.332"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#b1b1b1" stop-opacity="0" />
                    <stop
                        offset="0.688"
                        stop-color="#6d6d6d"
                        stop-opacity="0"
                    />
                    <stop
                        offset="0.9"
                        stop-color="#040404"
                        stop-opacity="0.588"
                    />
                    <stop offset="1" />
                </linearGradient>
                <filter
                    id="Path_10614"
                    x="376.993"
                    y="550.738"
                    width="136.812"
                    height="139.572"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dx="1" dy="-1" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1.5" result="blur-3" />
                    <feFlood flood-color="#5d5d5d" />
                    <feComposite operator="in" in2="blur-3" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <g
                    transform="matrix(1, 0.02, -0.02, 1, 5637.49, 3584.03)"
                    filter="url(#Path_10614)"
                >
                    <path
                        id="Path_10614-2"
                        data-name="Path 10614"
                        d="M85.362,0S89.6,7.685,97.746,37.487s5.307,51.784,5.307,51.784S83.815,86.144,57,88.96,0,107.437,0,107.437L85.362,0Z"
                        transform="translate(480.5 686.81) rotate(-165)"
                        opacity="0.39"
                        fill="url(#shadow-gradient-corner-2131)"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import PaperPattern from '@/components/notes/paper/PaperPattern'
import Marking from '@/components/notes/marking'
import NotesCoverFrontDefs from '@/components/notes/NotesCoverFrontDefs'
import NotesFormatMixin from '@/mixins/NotesFormatMixin'
import { mapState } from 'vuex'

export default {
    components: {
        PaperPattern,
        Marking,
        NotesCoverFrontDefs,
    },
    mixins: [NotesFormatMixin],
    data() {
        return {
            defaultBackground: '#7e7e7e',
            svg: {
                vertical: {
                    viewBox: '0 0 758 880',
                    width: '758',
                    height: '880',
                    notesTransform: 'translate(-568 -140)',
                    shadowTransform: 'matrix(1, 0, 0, 1, 568, 140)',
                    shadowPathTransform: 'translate(123 126)',
                    shadowPathD:
                        'M0,0H367a12,12,0,0,1,12,12V520a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
                horizontal: {
                    viewBox: '0 0 423 278',
                    width: '423',
                    height: '278',
                    notesTransform: 'translate(-684 -254.588)',
                    shadowTransform: 'matrix(1, 0, 0, 1, 684, 254.59)',
                    shadowPathTransform: 'translate(7 7)',
                    shadowPathD:
                        'M0,0H357a12,12,0,0,1,12,12V212a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
                square: {
                    viewBox: '0 0 532 428',
                    width: '532',
                    height: '428',
                    notesTransform: 'translate(-583 -198.273)',
                    shadowTransform: 'translate(583 198.273)',
                    shadowPathTransform: 'translate(108 64)',
                    shadowPathD:
                        'M0,0H249a12,12,0,0,1,12,12V246a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
            },
        }
    },
    computed: {
        ...mapState({
            cornerFillColor: (state) => state.notes.cornerFillColor,
            configuration: (state) => state.notes.configuration,
            background: (state) =>
                state.notes.configuration.coverColorPattern?.image || null,
            currentPage: (state) => state.page.currentPage,
            patternTheme: (state) =>
                state.notes.configuration.coverColorPattern?.theme,
            doubleMarkingPrint: (state) =>
                state.notes.configuration.markingPrint?.doubleMarkingPrint,
            haveCoverFinish: (state) =>
                state.notes.configuration.markingCoverFinish?.title?.pl ===
                'Folia błyszcząca',
        }),
        cornerFill() {
            // if (this.doubleMarkingPrint?.title?.pl.startsWith('CMYK')) {
            //     return 'url(#cover)'
            // } else {
            //     return this.background ? 'url(#cover)' : this.defaultBackground
            // }
            return this.cornerFillColor
        },
    },
}
</script>

<style lang="scss" scoped>
.notes-cover-image {
    margin: 0 -50px 0 0;
    pointer-events: none;

    &--zoom {
        &-b5 {
            transform: scale(0.88, 0.825);
        }
        &-a5 {
            transform: scale(0.705, 0.71);
        }
        &-125 {
            transform: scale(0.6, 0.69);
        }
        &-a6 {
            transform: scale(0.5, 0.5);
        }
        &-84 {
            transform: scale(0.401, 0.455);
        }
    }

    &--vertical {
        .notes-shadow {
            filter: url(#vertical-shadow_vector);
        }
    }
    &--horizontal {
        .notes-shadow {
            filter: url(#horizontal-shadow_vector);
        }
    }

    &--square {
        zoom: 1.3;

        .notes-shadow {
            filter: url(#square-shadow_vector);
        }
    }

    @media (max-width: $screen-medium) {
        height: 600px;
        margin: 0 -70px 0 -30px;

        &--vertical {
            width: auto;
        }
    }
    @media (max-width: $screen-desktop) {
        &--horizontal {
            zoom: 0.8;
        }
    }
    @media (max-width: $screen-tablet-medium) {
        margin: -80px auto;

        &--horizontal {
            zoom: 1;
            margin: -120px auto -100px;
        }
    }
}
.notes-shadow-image {
    opacity: 0.8;
}
</style>
