<template>
    <component :is="pages[currentPage.id]" />
</template>

<script>
import { mapState } from 'vuex'
import pages from '@/pages/index.js'

export default {
    components: {
        ...pages,
    },
    data() {
        return {
            pages: { ...pages },
        }
    },
    computed: {
        ...mapState({
            currentPage: (state) => state.page.currentPage,
        }),
    },
}
</script>
