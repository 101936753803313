var render = function render(){var _vm=this,_c=_vm._self._c;return (
        _vm.searchPhrase &&
        _vm.searchPhrase.length >= 3 &&
        _vm.isModalOpen &&
        _vm.searchTipsPatterns &&
        _vm.searchTipsPatterns.length
    )?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideTips),expression:"hideTips"}],staticClass:"input-search-tips"},_vm._l((_vm.searchTipsPatterns),function(tip,index){return _c('div',{key:index,staticClass:"input-search-tips-tip",domProps:{"innerHTML":_vm._s(tip.searchTitle)},on:{"click":function($event){return _vm.searchFor(tip.title[_vm.locale])}}})}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }