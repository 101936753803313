<template>
    <!-- eslint-disable -->
    <!-- hide pen holder when steamer is picked -->
    <g
        v-if="isPenHolder"
        :transform="position[format]"
        id="Group_932"
        data-name="Group 932"
        style="clip-path: inset(0px 0px 0px 2px);"
    >
        <defs>
            <linearGradient
                id="pen-holder-linear-gradient"
                y1="0.548"
                x2="1"
                y2="0.544"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.122" />
                <stop offset="0.314" stop-opacity="0" />
                <stop offset="0.686" stop-opacity="0" />
                <stop offset="1" stop-opacity="0.239" />
            </linearGradient>
        </defs>
        <g
            id="Rectangle_1451"
            data-name="Rectangle 1451"
            class="accessories-pen-2"
            :style="`fill: ${color}`"
            transform="translate(18922 10939)"
        >
            <path
                class="accessories-pen-14"
                d="M0,0H24a4,4,0,0,1,4,4V41a4,4,0,0,1-4,4H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
            />
            <path
                class="accessories-pen-15"
                d="M1,.5H24A3.5,3.5,0,0,1,27.5,4V41A3.5,3.5,0,0,1,24,44.5H1A.5.5,0,0,1,.5,44V1A.5.5,0,0,1,1,.5Z"
            />
        </g>
        <g
            id="Rectangle_1397"
            data-name="Rectangle 1397"
            class="accessories-pen-3"
            transform="translate(18922 10939)"
        >
            <path
                class="accessories-pen-14"
                d="M0,0H24a4,4,0,0,1,4,4V41a4,4,0,0,1-4,4H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
            />
            <path
                class="accessories-pen-15"
                d="M1,.5H24A3.5,3.5,0,0,1,27.5,4V41A3.5,3.5,0,0,1,24,44.5H1A.5.5,0,0,1,.5,44V1A.5.5,0,0,1,1,.5Z"
            />
        </g>
        <g
            id="Group_1366"
            data-name="Group 1366"
            transform="translate(17848 10422)"
        >
            <g
                id="Group_1356"
                data-name="Group 1356"
                class="accessories-pen-4"
                transform="translate(1436.453 86.016) rotate(90)"
            >
                <line
                    id="Line_137"
                    data-name="Line 137"
                    class="accessories-pen-5"
                    y1="25.294"
                    transform="translate(435.5 335.5)"
                />
                <path
                    id="Path_7302"
                    data-name="Path 7302"
                    class="accessories-pen-5"
                    d="M0,0"
                    transform="translate(439.834 335.5)"
                />
            </g>
            <line
                id="Line_629"
                data-name="Line 629"
                class="accessories-pen-6"
                x2="25"
                transform="translate(1075.5 523.5)"
            />
        </g>
        <g
            id="Group_1367"
            data-name="Group 1367"
            transform="translate(17848 10427)"
        >
            <g
                id="Group_1356-2"
                data-name="Group 1356"
                class="accessories-pen-4"
                transform="translate(1436.453 86.016) rotate(90)"
            >
                <line
                    id="Line_137-2"
                    data-name="Line 137"
                    class="accessories-pen-5"
                    y1="25.294"
                    transform="translate(435.5 335.5)"
                />
                <path
                    id="Path_7302-2"
                    data-name="Path 7302"
                    class="accessories-pen-5"
                    d="M0,0"
                    transform="translate(439.834 335.5)"
                />
            </g>
            <line
                id="Line_629-2"
                data-name="Line 629"
                class="accessories-pen-6"
                x2="25"
                transform="translate(1075.5 523.5)"
            />
        </g>
        <g
            id="Group_1368"
            data-name="Group 1368"
            transform="translate(17848 10432)"
        >
            <g
                id="Group_1356-3"
                data-name="Group 1356"
                class="accessories-pen-4"
                transform="translate(1436.453 86.016) rotate(90)"
            >
                <line
                    id="Line_137-3"
                    data-name="Line 137"
                    class="accessories-pen-5"
                    y1="25.294"
                    transform="translate(435.5 335.5)"
                />
                <path
                    id="Path_7302-3"
                    data-name="Path 7302"
                    class="accessories-pen-5"
                    d="M0,0"
                    transform="translate(439.834 335.5)"
                />
            </g>
            <line
                id="Line_629-3"
                data-name="Line 629"
                class="accessories-pen-6"
                x2="25"
                transform="translate(1075.5 523.5)"
            />
        </g>
        <g
            id="Group_1369"
            data-name="Group 1369"
            transform="translate(17848 10437)"
        >
            <g
                id="Group_1356-4"
                data-name="Group 1356"
                class="accessories-pen-4"
                transform="translate(1436.453 86.016) rotate(90)"
            >
                <line
                    id="Line_137-4"
                    data-name="Line 137"
                    class="accessories-pen-5"
                    y1="25.294"
                    transform="translate(435.5 335.5)"
                />
                <path
                    id="Path_7302-4"
                    data-name="Path 7302"
                    class="accessories-pen-5"
                    d="M0,0"
                    transform="translate(439.834 335.5)"
                />
            </g>
            <line
                id="Line_629-4"
                data-name="Line 629"
                class="accessories-pen-6"
                x2="25"
                transform="translate(1075.5 523.5)"
            />
        </g>
        <g
            id="Group_1370"
            data-name="Group 1370"
            transform="translate(17848 10442)"
        >
            <g
                id="Group_1356-5"
                data-name="Group 1356"
                class="accessories-pen-4"
                transform="translate(1436.453 86.016) rotate(90)"
            >
                <line
                    id="Line_137-5"
                    data-name="Line 137"
                    class="accessories-pen-5"
                    y1="25.294"
                    transform="translate(435.5 335.5)"
                />
                <path
                    id="Path_7302-5"
                    data-name="Path 7302"
                    class="accessories-pen-5"
                    d="M0,0"
                    transform="translate(439.834 335.5)"
                />
            </g>
            <line
                id="Line_629-5"
                data-name="Line 629"
                class="accessories-pen-6"
                x2="25"
                transform="translate(1075.5 523.5)"
            />
        </g>
        <g
            id="Group_1371"
            data-name="Group 1371"
            transform="translate(17848 10447)"
        >
            <g
                id="Group_1356-6"
                data-name="Group 1356"
                class="accessories-pen-4"
                transform="translate(1436.453 86.016) rotate(90)"
            >
                <line
                    id="Line_137-6"
                    data-name="Line 137"
                    class="accessories-pen-5"
                    y1="25.294"
                    transform="translate(435.5 335.5)"
                />
                <path
                    id="Path_7302-6"
                    data-name="Path 7302"
                    class="accessories-pen-5"
                    d="M0,0"
                    transform="translate(439.834 335.5)"
                />
            </g>
            <line
                id="Line_629-6"
                data-name="Line 629"
                class="accessories-pen-6"
                x2="25"
                transform="translate(1075.5 523.5)"
            />
        </g>
        <g
            id="Group_1372"
            data-name="Group 1372"
            transform="translate(17848 10452)"
        >
            <g
                id="Group_1356-7"
                data-name="Group 1356"
                class="accessories-pen-4"
                transform="translate(1436.453 86.016) rotate(90)"
            >
                <line
                    id="Line_137-7"
                    data-name="Line 137"
                    class="accessories-pen-5"
                    y1="25.294"
                    transform="translate(435.5 335.5)"
                />
                <path
                    id="Path_7302-7"
                    data-name="Path 7302"
                    class="accessories-pen-5"
                    d="M0,0"
                    transform="translate(439.834 335.5)"
                />
            </g>
            <line
                id="Line_629-7"
                data-name="Line 629"
                class="accessories-pen-6"
                x2="25"
                transform="translate(1075.5 523.5)"
            />
        </g>
        <g
            id="Group_1373"
            data-name="Group 1373"
            transform="translate(17848 10457)"
        >
            <g
                id="Group_1356-8"
                data-name="Group 1356"
                class="accessories-pen-4"
                transform="translate(1436.453 86.016) rotate(90)"
            >
                <line
                    id="Line_137-8"
                    data-name="Line 137"
                    class="accessories-pen-5"
                    y1="25.294"
                    transform="translate(435.5 335.5)"
                />
                <path
                    id="Path_7302-8"
                    data-name="Path 7302"
                    class="accessories-pen-5"
                    d="M0,0"
                    transform="translate(439.834 335.5)"
                />
            </g>
            <line
                id="Line_629-8"
                data-name="Line 629"
                class="accessories-pen-6"
                x2="25"
                transform="translate(1075.5 523.5)"
            />
        </g>
    </g>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            position: {
                vertical: 'translate(-17848 -10422)',
                horizontal: 'translate(-17858 -10588.412)',
                square: 'translate(-17969 -10578.412)',
            },
        }
    },
    computed: {
        ...mapState({
            isPenHolder: (state) => !!state.notes.configuration.penHolder,
            isStreamer: (state) => !!state.notes.configuration.streamer,
            color: (state) => {
                if (
                    state.notes.configuration.penHolder &&
                    state.notes.configuration.penHolder.penHolderColor
                ) {
                    return state.notes.configuration.penHolder.penHolderColor
                        .value
                }
            },
        }),
    },
}
</script>

<style lang="scss">
.accessories-pen {
    &-2 {
        fill: #ed544e;
    }
    &-2,
    &-3 {
        stroke: rgba(45, 46, 47, 0.46);
    }
    &-3 {
        fill: url(#pen-holder-linear-gradient);
    }
    &-4 {
        opacity: 0.28;
    }
    &-15,
    &-5,
    &-6 {
        fill: none;
    }
    &-5 {
        stroke: #fff0ef;
    }
    &-6 {
        stroke: #707070;
        opacity: 0.35;
    }
    &-14 {
        stroke: none;
    }
}
</style>
