<template>
    <!-- notes for streamer and basic front option -->
    <div
        v-if="
            lastSelectedAccessory === 'basic' ||
            lastSelectedAccessory === 'streamer' ||
            forceCover
        "
        class="notes-cover-accessories"
    >
        <NotesCoverFront
            v-if="!showEdge"
            :last-selected-accessory="lastSelectedAccessory"
            :custom-view-box="customViewBox"
        />
        <NotesEdge v-else transform="translate(-23 0)" />
        <button
            v-if="streamer"
            class="notes-cover-accessories-button"
            :class="{ 'notes-cover-accessories-button--rotated': showEdge }"
            @click="changeNotesView"
        >
            <Arrow />
            {{ $t('general.buttonView') }}
        </button>
    </div>
    <!-- show pocket, when pocket is selected last -->
    <NotesOpen
        v-else-if="lastSelectedAccessory === 'pocket'"
        class="notes-cover-open"
    />
</template>

<script>
import NotesCoverFront from '@/components/notes/NotesCoverFront'
import NotesEdge from '@/components/notes/NotesEdge'
import NotesOpen from '@/components/notes/NotesOpen'
import { mapState } from 'vuex'

import Arrow from '@/assets/images/arrow.svg'

export default {
    components: {
        NotesCoverFront,
        NotesEdge,
        NotesOpen,
        Arrow,
    },
    props: {
        customViewBox: {
            type: String,
            default: null,
        },
        forceCover: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showEdge: false,
            lastSelectedAccessory: 'basic',
        }
    },
    computed: {
        ...mapState({
            streamer: (state) => {
                return state.notes.configuration.streamer
            },
            pocket: (state) => {
                return state.notes.configuration.pocket
            },
            elastic: (state) => {
                return state.notes.configuration.elastic
            },
            penHolder: (state) => {
                return state.notes.configuration.penHolder
            },
            ribbon: (state) => {
                return state.notes.configuration.ribbon
            },
        }),
    },
    watch: {
        // watch selected options and change view according to last changed option
        // then visualise option that changed (this part is in template section)
        streamer(val) {
            this.lastSelectedAccessory = 'streamer'
            if (!val) {
                this.showEdge = false
            }
        },
        pocket(val) {
            this.lastSelectedAccessory = 'pocket'
        },
        elastic(val) {
            this.lastSelectedAccessory = 'basic'
        },
        penHolder(val) {
            this.lastSelectedAccessory = 'basic'
        },
        ribbon(val) {
            this.lastSelectedAccessory = 'basic'
        },
    },
    methods: {
        changeNotesView() {
            this.showEdge = !this.showEdge
        },
    },
}
</script>

<style lang="scss">
.notes-cover-accessories {
    position: relative;
    &-button {
        position: absolute;
        bottom: 4%;
        left: 50%;
        transform: translateX(calc(-50% + -60px));
        font-size: 1.6rem;
        background-color: $color-burnt-sienna;
        padding: 14px 28px;
        border: 1px solid $color-burnt-sienna;
        color: $color-white;
        cursor: pointer;
        transition: 0.2s;
        z-index: 9;

        svg {
            transition: inherit;
            margin-right: 8px;
            path,
            line {
                stroke: $color-white;
            }
        }
        &--rotated {
            left: 19.5%;
            transform: none;
            svg {
                transform: rotate(-180deg);
            }
        }

        &:hover {
            background: $color-shark;
            border-color: $color-shark;
            color: $color-white;

            svg {
                path,
                line {
                    stroke: $color-white;
                }
            }
        }
    }
    @media (max-width: $screen-medium) {
        &-button {
            transform: translateX(calc(-50% + -30px));
            &--rotated {
                left: 15%;
                transform: unset;
            }
        }
    }
    @media (max-width: $screen-tablet-medium) {
        &-button {
            display: none;
        }
    }
}
</style>
