<template>
    <div class="form-tips">
        <ul class="form-tips-list">
            <li
                v-for="item in filteredTips"
                :key="item"
                @click="selectItem(item)"
                v-text="item"
            />
        </ul>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
    props: {
        tips: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            query: '',
        }
    },
    computed: {
        filteredTips() {
            let reg = new RegExp('^' + this.query, 'i')
            let filteredTips = []
            this.tips.forEach((tip) => {
                if (tip.match(reg)) {
                    filteredTips.push(tip)
                }
            })
            return filteredTips
        },
    },
    watch: {
        value() {
            this.updateQuery()
        },
    },
    mounted() {
        this.query = this.value
    },
    methods: {
        updateQuery: debounce(function () {
            this.query = this.value
        }, 150),
        selectItem(item) {
            this.$emit('select', item)
        },
    },
}
</script>

<style lang="scss">
.form-tips {
    position: absolute;
    top: 48px;
    z-index: 10;
    background: $color-white;
    width: 100%;
    box-shadow: $color-border-input-darker 0 0 10px;
    max-height: 300px;
    overflow: auto;

    &-list {
        li {
            list-style-type: none;
            font-size: 16px;
            padding: 14px 23px;
            border: $color-border-input-darker solid 1px;
            border-top: none;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
                background: $color-bg-light;
            }
        }
    }
}
</style>
