export default {
    general: {
        loading: 'Loading',
        back: 'Back to website',
        configurator: 'Notebook set up',
        page: ' sheet',
        page2: ' sheets',
        pages: ' sheets',
        zoom: 'Zoom in outer cover',
        zoom2: 'Zoom in print type',
        buttonView: 'Change view',
        notfound: 'country not found',
    },
    material: {
        title: 'Create your Mindnotes®',
        fsc: 'Mindnotes from FSC<sup>®</sup> certified materials',
        standard: 'Mindnotes from standard and recycled materials',
        label: 'Start setup',
        load: 'Upload setup:',
        description:
            'Enter the setup code found in the summary to upload the set up again.',
        loadMobile: 'or add a set up number',
    },
    paper: {
        title: 'Cover - material',
        filter: 'Filter materials by:',
        color: 'Select colour',
        category: 'Select a category',
        search: 'Type in name or number of cover material',
        noResults: 'No search results.',
        search2: 'search material',
        papier: 'Paper',
        okleina: 'Cover material',
        placeholder: 'Type in name or number of cover material',
        fallback: 'No cover materials available for the selected setup',
    },
    config: {
        title: 'Your setup',
        subtitle:
            'In this step you can check and download your full configuration without selling prices.',
        details: 'Show details of your configuration',
        button1: 'Download PDF',
        linkTitle: 'Link to your setup',
        linkDesc:
            'Copy the code or the link to reuse the same setup for future orders.',
        button2: 'Copy code',
        button3: 'Copy link',
        desc2:
            'If you want to receive an offer with market prices go to login.',
        logowanko: 'Next',
        copyCode: 'Code copied',
        copyLink: 'Link copied',
    },
    summary: {
        title: 'Summary',
        save: 'Save to PDF',
        edit: 'Edit',
        goToForm: 'Go to form',
        sendForm: 'Confirm and send',
        info: {
            send: 'Sending form...',
            pdf: `To save your configuration as a PDF, click the button below`,
            individual: `PromoNotes fulfills all its orders to end customers exclusively through its distributors, which are advertising agencies`,
            form: `To receive a binding offer from a distributor, please contact PromoNotes by filling out the form`,
            agency: `To submit an official request for quotation and receive a binding commercial offer, click the 'Go to offer' button.`,
            erp: `After clicking it, you will leave the Mindnotes website and be redirected to the PromoNotes customer portal. There you will find a binding commercial offer for your configuration as well as the history of previous quote requests.`,
        },
    },
    pdf: {
        summary: 'Setup summary',
        configurationNo: 'Calculation number: ',
        code: 'Setup code: ',
        link: 'setup link',
        file1: 'Thank you for the successful ',
        file2: 'setup of the Mindnotes® notebook.',
        file3:
            'All distributors of advertising products are welcome to contact the PromoNotes sales team. All you need',
        file4:
            'to do is send your setup number to your sales manager at PromoNotes or to biuro@promonotes.pl,',
        file5: 'biuro@promonotes.pl',
        file6: 'and we will contact you.',
        file7:
            'If you are not a distributor of promotional products please contact your promotional gadget supplier.',
        file71: '',
        file72:
            'If you have not worked with a supplier who offers Mindnotes® products, please email us and we will be pleased ',
        file73: 'to put you in touch with our distributor.',
        file73Html: ' to put you in touch with our distributor.',
        file8:
            'Dimensions and images shown are indicative and cannot be used as grounds for complaint. The manufacturer reserves ',
        file9:
            'the  right to technical modifications to individual products and is not responsible for possible errors in the setup.',
        file10:
            'Description of the summary setup and the image generated in the setup section does not constitute an offer within ',
        file11: 'the meaning of the Civil Code.',
        footer: 'Promonotes Sp. z o.o. All rights reserved',
        page: 'Page ',
        data: 'Date: ',
    },
    form: {
        form: 'Form',
        title: 'Log in',
        fill: 'To receive a quotation, please fill in the form fields.',
        company: 'Company name',
        phone: 'Phone number',
        email: 'Email',
        website: 'Website address',
        street: 'Street',
        houseNumber: 'Street/flat number',
        city: 'City',
        nip: 'VAT-Id',
        zipcode: 'Zip code',
        country: 'Country',
        name: 'First name',
        surname: 'Last name',
        position: 'Position',
        agencyName: 'Operating agency',
        agencyEmail: 'Agency email address',
        agencyAddress: 'Agency contact details',
        agencyInfo: '',
        message: 'Your order message',
        emailcopy:
            'Send a copy of my notebook setup to the email addresses provided',
        consent1:
            '<span class="red">*</span> I consent to the processing of my personal data contained in the form  by PromoNotes sp. z o.o. in order to respond to the inquiry made via the contact form. Details of data processing are specified in the GDPR tab.',
        consent2:
            'I agree to receiving commercial information on products and services  from PromoNotes Sp. z o.o. via electronic means of communication to the email address provided by me in the Form,  in accordance with the Act on electronic services of 18 July 2002. (Journal of Laws 2002 No. 144, item 1204, consolidated text in Journal of Laws 2019, item 123). This consent is voluntary in nature. Details of data processing are set out in the GDPR tab.',
        consent3:
            ' I consent to the use of telecommunications equipment and automatic calling systems within the meaning of the Telecommunications Law of 16 July 2004  (Journal of Laws 2004 no. 171, item 1800, consolidated text in Journal of Laws 2018, item 1954) with reference to the email address and telephone number provided by me for the purposes of direct marketing, including direct contact and presentation of the offer by PromoNotes spółka z ograniczoną odpowiedzialnością. This consent is voluntary. Details of data processing are specified in the GDPR tab.',
        send: 'Send',
        description:
            'If you are already a Promonotes customer, please log in using the same details.',
        password: 'Password',
        password2: 'Repeat your password',
        login: 'Sign in',
        required: 'mandatory field',
        forgotPass: 'Forgot your password?',
        customerType: 'Customer type',
        individual: 'End customer',
        adAgency: 'Advertising agency',
        add: 'Add',
        goToOffer: 'Go to offer',
        signInAndGoToOffer: 'Create an account and go to offer',
        register: {
            title: 'Registration',
            secondTitle: 'Your data',
            send: 'Register',
        },
        passwordPage: {
            title: 'Forgot your password',
            description:
                'Please enter the email address you used when creating your account to have a password reset link sent to you.',
            label: 'Email',
            button: 'Send',
            hello: 'Hello',
            company: 'from company',
            tip:
                'In this step you will create your account password. Remember that an effective password should contain between 8-16 characters, uppercase letters, numbers and special characters.',
            passWeak: 'Your password is weak',
            passGood: 'Your password is good',
            passStrong: 'Your password is strong',
            passResetTitle: 'Your password has been reset',
            passResetDesc:
                'In order to log into your account, create a new password.',
            passResetSave: 'Save',
        },
        agency: {
            noAcc: 'You do not have an account yet?',
            register:
                'Registration is necessary in order to provide data for order processing. If you have already been a customer of Promonotes, your details are stored in our database and you will only need to confirm them.',
            createAcc: 'Create an account',
        },
        male: 'Mr',
        female: 'Mrs',
    },
    offer: {
        title: 'Offer',
        description:
            'Fill in the required fields and we will give you our recommended market selling price.',
        prints: 'Volume',
        price2: 'Purchase price',
        price1: 'Recommended market selling price',
        transportCost: 'Transport costs',
        legalInfo:
            'Prices are for information purposes only and do not constitute an offer within the meaning of Article 66 of the Civil Code. We reserve the right to change the quoted prices. The prices are net and do not include any taxes or customs duties. In order to receive a binding offer please go to SUMMARY.',
        country: 'Country',
        countryOther:
            'If your country is not on the list, please select OTHER.',
        calculate: 'Calculate',
        orientation:
            'Approximate lead time is 14-21 days from the date of artwork approval.',
        currency: 'Currency',
        szt: 'pcs.',
        other: 'OTHER',
        warning: 'Terms of delivery',
        addPrints: 'Add quantity',
        error: {
            form: `Form validation error, please fill in the fields correctly`,
        },
        info: {
            form: 'Form verification...',
            valuation: 'Sending calculation...',
        },
    },
    sent: {
        title: 'Thank you for your request!',
        desc: 'We will contact you as soon as possible',
        button: 'New setup',
    },
    modal: {
        coverSoft: 'Paper softcover',
        coverSoftDescr:
            'The simplest, most attractive and at the same time most economical cover material in the Mindnotes® collection',
        coverHard: 'Paper or material hardcover',
        coverHardDescr:
            'The classic hardcover available in a wide range of paper and textile materials.',
        coverSoft2: 'Material softcover',
        coverSoft2Descr:
            'The biggest star in the Mindnotes® collection. The classic outer cover with an extremely flexible and exclusively textile cover.',
        coverHard2: 'Material flexible hardcover',
        coverHard2Descr:
            'Classically hard but at the same time pleasantly flexible book binding, offered in the Mindnotes® collection exclusively as a textile option.',
        cornerRound: 'Rounded corners',
        cornerStraight: 'Straight corners',
        ridgeSoft: 'Soft spine',
        ridgeHard: 'Hard spine',
        flyleaf:
            'A notebook element connecting the cover with the writing pad, usually made of thicker paper than the notepad.',
        pocket:
            'A practical  paper pocket mounted on the inside of the back cover.',
        package1:
            'Bulk delivery - The notebooks are packed directly into cardboard boxes. On request, paper dividers can be used between the notebook layers to protect them. ',
        package2:
            'Standard - individual PP foil bag, with self-adhesive closure or with perforation. ',
        package3:
            'Ecological - the product is wrapped in compostable film made from corn starch. Bag with perforation and imprint (100% compostable logo). ',
        marking1:
            '<strong>Debossing with foil </strong>- up to 50 cm<sup>2</sup> in one place',
        marking2:
            '<strong>Debossing without foil - small</strong> - up to 50 cm<sup>2</sup> in one place',
        marking3:
            '<strong>Debossing without foil - large </strong> - up to 150 cm<sup>2</sup> in one place',
        paperTypeSoft1: 'The most popular cover material for standard notebooks is:',
        paperTypeSoft2: 'double-coated cardboard 250g/m2',
        paperTypeSoft3: 'The most popular cover materials for eco-friendly notebooks are:',
        paperTypeSoft4: 'uncoated recycled kraft cardboard 300g/m2',
        paperTypeSoft5: 'recycled offset paper 300g/m2',
        paperTypeTorino: 'The most popular material for notebook covers is Torino cover material.',
        paperTypeHardVeneer1: 'The most popular cover material for standard notebooks is:',
        paperTypeHardVeneer2: 'coated paper 150g/m2',
        paperTypeHardVeneer3: 'The most popular cover materials for eco-friendly notebooks are:',
        paperTypeHardVeneer4: 'recycled offset paper 160g/m2',
        paperTypeHardVeneer5: 'uncoated recycled kraft paper 120g/m2',
    },
    errors: {
        required: 'Select obligatory options',
        cover: `Select at least one option from each category`,
        format: 'Select a format',
        paper: 'Select a cover material',
        coverMarking: `Select at least one option from each category`,
        packagePage: 'Select a packaging type',
    },
    link: {
        mainWebsite: process.env.VUE_APP_WEBSITE_URL,
        configurationUrl: `${process.env.VUE_APP_CONFIGURATOR_URL}/konfigurator/`,
        label: 'mindnotes.eu',
    },
    format: {
        title: 'Formats',
        netto: 'Writing pad net size',
    },
    meta: {
        title:
            'Bespoke advertising and company notebooks with a logo - online configurator',
        description:
            'Create your perfect notebook and choose from over a hundred customization options: format, type of cover, corners, spine, paper, customization method, accessories. There are as many possibilities as many ideas you have. Check.',
    },
    loader: {
        close: 'Close',
        info: {
            config: 'Fetching configuration...',
            download: 'Generating PDF file...',
            deps: 'Downloading dependencies...',
            summary: 'Generating calculatiion...',
        },
        error: {
            general: `<strong>There was an error in the application</strong> <br/>please try again soon.`,
            config: `<strong>There was an error</strong> <br/>the configuration does not exist or is no longer valid.`,
            pdf: `<strong>There was an error generating the file</strong> <br/>please try again.`,
            deps: `<strong>There was an error downloading dependencies</strong> <br/>please try again.`,
            summary: `<strong>There was an error generating the calculation</strong> <br/>please try again.`,
        },
    },
}
