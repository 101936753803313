<template>
    <!-- eslint-disable -->
    <g
        v-if="isRibbon"
        id="Group_931"
        data-name="Group 931"
        :transform="options[format]"
    >
        <defs>
            <linearGradient
                id="ribbon-linear-gradient"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.302" />
                <stop offset="0.247" stop-color="#764f4c" stop-opacity="0" />
                <stop offset="1" stop-color="#7e5551" stop-opacity="0" />
            </linearGradient>
        </defs>
        <g
            id="Rectangle_1398"
            data-name="Rectangle 1398"
            class="accessory-ribbon-2"
            transform="translate(18592 11226)"
            :style="`fill: ${color};`"
        >
            <rect class="accessory-ribbon-11" width="10" height="99" />
            <rect
                class="accessory-ribbon-12"
                x="0.5"
                y="0.5"
                width="9"
                height="98"
            />
        </g>
        <rect
            id="Rectangle_1452"
            data-name="Rectangle 1452"
            class="accessory-ribbon-3"
            width="10"
            height="99"
            transform="translate(18592 11226)"
        />
    </g>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            options: {
                vertical: 'translate(-17879 -10424)',
                horizontal: 'translate(-17879 -10739.412)',
                square: 'translate(-17879 -10704.412)',
            },
        }
    },
    computed: {
        ...mapState({
            color: (state) => {
                const option = state.notes.configuration.ribbon
                if (option && option.ribbonColor) {
                    return option.ribbonColor.value
                }
                return null
            },
            isRibbon: (state) => {
                const option = state.notes.configuration.ribbon
                if (option && option.title) {
                    return true
                }
                if (option && option.general.title) {
                    return true
                }
                return null
            },
        }),
    },
}
</script>

<style lang="scss">
.accessory-ribbon-2 {
    fill: #ed544e;
    stroke: rgba(0, 0, 0, 0.36);
}
.accessory-ribbon-3 {
    fill: url(#ribbon-linear-gradient);
}
.accessory-ribbon-11 {
    stroke: none;
}
.accessory-ribbon-12 {
    fill: none;
}
</style>
