<template>
    <div
        class="layout-tablet"
        :class="{ 'layout-tablet--active-menu': isMobileMenu }"
    >
        <BarTop v-if="isStandardLayout" />
        <WorkspaceTablet />
        <BarBottom v-if="isStandardLayout" />
        <transition name="fade">
            <div
                v-if="isOverlay"
                class="layout-tablet-overlay"
                @click="hideOverlay"
            />
        </transition>
        <LoaderModal />
        <WorkspaceVirtual />
    </div>
</template>

<script>
import BarBottom from '@/components/bar/BarBottom'
import BarTop from '@/components/bar/BarTop'
import WorkspaceTablet from '@/components/workspace/WorkspaceTablet'
import { mapState, mapMutations } from 'vuex'
import WorkspaceVirtual from '@/components/workspace/WorkspaceVirtual'
import LoaderModal from '@/components/loader/LoaderModal.vue'

export default {
    components: {
        LoaderModal,
        BarBottom,
        BarTop,
        WorkspaceTablet,
        WorkspaceVirtual,
    },
    computed: {
        ...mapState({
            currentPage: (state) => state.page.currentPage,
            isOverlay: (state) => state.page.isOverlayActive,
            isMobileMenu: (state) => state.page.isMobileMenuActive,
            hideMobileMenuBars: (state) => state.page.hideMobileMenuBars,
        }),
        isStandardLayout() {
            if (this.hideMobileMenuBars) {
                return !this.hideMobileMenuBars.includes(this.currentPage.id)
            }
            return true
        },
    },
    methods: {
        ...mapMutations([
            'showMobileMenu',
            'setCurrentSubOption',
            'setOverlay',
        ]),
        hideOverlay() {
            if (this.isMobileMenu) {
                this.showMobileMenu(false)
            }
            this.setCurrentSubOption(null)
            this.setOverlay(false)
        },
    },
}
</script>

<style lang="scss">
.layout-tablet {
    position: relative;
    min-height: 100vh;
    overflow: hidden;

    &--active-menu {
        overflow: hidden;
    }

    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        @include size(100%, 100%);
        background: $color-bg-dark;
        opacity: 0.7;
        z-index: 6;
    }

    &--active-menu & {
        &-overlay {
            background: rgba(0, 0, 0, 0.7);
            opacity: 1;
        }
    }

    @media (max-width: $screen-mobile-large) {
        min-height: calc(100vh - 50px);
        &--active-menu {
            overflow: visible;
        }
    }
}
</style>
