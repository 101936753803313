<template>
    <div class="workspace-virtual">
        <div class="notes-cover">
            <div id="notes-open-flyleaf">
                <NotesOpen class="notes-cover-open" force-flyleaf is-virtual />
            </div>
            <div id="notes-open">
                <NotesOpen class="notes-cover-open" hide-flyleaf is-virtual />
            </div>
            <div id="notes-edge">
                <NotesEdge />
            </div>
            <div
                id="notes-accessories"
                :class="`notes-accessories--${formatGroup}`"
            >
                <NotesCoverAccessories
                    :custom-view-box="
                        formatGroup === 'horizontal' ? '-50 0 600 300' : null
                    "
                    force-cover
                />
            </div>
        </div>
    </div>
</template>

<script>
import NotesOpen from '@/components/notes/NotesOpen'
import NotesEdge from '@/components/notes/NotesEdge'
import NotesCoverAccessories from '@/components/notes/NotesCoverAccessories'
import FormatGroupMixin from '@/mixins/NotesFormatMixin'
import { mapState } from 'vuex'

export default {
    components: {
        NotesOpen,
        NotesEdge,
        NotesCoverAccessories,
    },
    mixins: [FormatGroupMixin],
    data() {
        return {
            notesOpenPages: ['inside', 'personalized', 'flyleaf'],
        }
    },
    computed: {
        ...mapState({
            currentPage: (state) => state.page.currentPage.id,
        }),
        cornerType() {
            return this.configuration?.coverCorners
        },
    },
}
</script>

<style lang="scss">
#notes-open,
#notes-open-flyleaf,
#notes-edge {
    width: 1021px !important;
    height: 760px !important;

    .notes-open,
    .notes-edge {
        margin: 0 !important;
        padding: 0 !important;
    }
}
#notes-edge {
    .notes-edge {
        transform: scale(1);
        transform-origin: -100% 0;
    }
}

#notes-accessories {
    width: 1021px !important;
    height: 100%;
    min-height: 800px;

    .notes-cover-accessories {
        transform: translateY(-50px);
    }
    .notes-cover-image {
        zoom: 1 !important;
        height: 100% !important;
        margin: 0 !important;
    }

    &.notes-accessories--horizontal {
        padding-top: 100px;
        padding-left: 100px;

        .notes-cover-accessories {
            height: 720px;
            margin: 0;
        }
    }
    .notes-cover-image--horizontal {
        transform: none;
    }
    .notes-open {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.workspace-virtual {
    // position: relative;
    // left: 0%;
    // top: 0;
    width: 1000px;
    height: 10000px;
    z-index: -1;

    position: absolute;
    left: 0%;
    top: 10000px;

    .page-paper {
        &-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
        }
    }

    .notes-cover {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        overflow: visible;

        > svg {
            height: inherit;
        }

        .marking-print.marking-print--horizontal,
        .expression-size.expression-size--horizontal {
            transform-origin: 144% 120%;
        }

        @media (max-width: $screen-tablet-medium) {
            margin: 0 auto;
            max-width: 90%;

            &-button {
                display: none;
            }
        }
    }
}
</style>
