<template>
    <!-- eslint-disable -->
    <g>
        <rect
            id="calendar-quarter-bg"
            :width="calendar[format].width"
            :height="calendar[format].height"
            :transform="calendar[format].paperTransform"
            :fill="paperColor"
        />
        <image
            :href="calendar[format].url"
            :transform="calendar[format].transform"
            alt="calendar"
        />
    </g>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            calendar: {
                vertical: {
                    url:
                        '/img/notes/personalized/calendar-quarter-vertical.png',
                    transform: 'translate(420 257)',
                    width:"622",
                    height:"451",
                    paperTransform:"translate(397 245)",
                },
                horizontal: {
                    url: '/img/notes/personalized/calendar-quarter-horizontal.png',
                    transform: 'translate(405 245)',
                    width:"602",
                    height:"184",
                    paperTransform:"translate(406 244)",
                },
                square: {
                    url: '/img/notes/personalized/calendar-quarter-square.png',
                    transform: 'translate(400 250)',
                    width:"502",
                    height:"264",
                    paperTransform:"translate(406 244)",
                },
            },
        }
    },
    computed: {
        ...mapGetters(['paperColor']),
    },
}
</script>
