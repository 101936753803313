<script>
import dependencies from '@/store/modules/dependencies'

export default {
    data() {
        return {
            pageTitle: {
                pl: 'Personalizowane kartki',
                en: 'Personalised sheets',
                de: 'Personalisierte Blätter',
                fr: 'Pages personnalisées',
            },
        }
    },
    computed: {
        list() {
            return dependencies.state.list
        },
        options() {
            let DODATKOWE_STRONY_MIEJSCE = null
            let DODATKOWE_STRONY_RODZAJ_DRUKU = null
            let DODATKOWE_STRONY_RODZAJ_PAPIERU = null
            let KALENDARZ = null

            if (this.list?.length) {
                this.list.forEach((dependency) => {
                    DODATKOWE_STRONY_MIEJSCE = mergeMultipleParams(
                        DODATKOWE_STRONY_MIEJSCE,
                        dependency.actions.DODATKOWE_STRONY_MIEJSCE
                    )

                    DODATKOWE_STRONY_RODZAJ_DRUKU = mergeMultipleParams(
                        DODATKOWE_STRONY_RODZAJ_DRUKU,
                        dependency.actions.DODATKOWE_STRONY_RODZAJ_DRUKU
                    )

                    DODATKOWE_STRONY_RODZAJ_PAPIERU = mergeMultipleParams(
                        DODATKOWE_STRONY_RODZAJ_PAPIERU,
                        dependency.actions.DODATKOWE_STRONY_RODZAJ_PAPIERU
                    )

                    KALENDARZ = mergeMultipleParams(
                        KALENDARZ,
                        dependency.actions.KALENDARZ
                    )
                })
            }

            function mergeMultipleParams(param, dependencyAction) {
                param = param || dependencyAction

                if (param && dependencyAction) {
                    param = param.filter((dep) => {
                        return dependencyAction.includes(dep)
                    })
                }
                return param
            }

            function filterOptionsFn({ apiId }, allowed) {
                return allowed ? allowed.includes(apiId.toUpperCase()) : true
            }

            return [
                {
                    id: 'additionalPages',
                    apiId: '',
                    title: {
                        pl: 'Dodatkowe kartki',
                        en: 'Extra pages',
                        de: 'Werbeblätter',
                        fr: 'Pages supplémentaires',
                    },
                    inputs: [
                        {
                            id: 'pageNumber',
                            apiId: 'DODATKOWE_STRONY_ILOSC',
                            title: {
                                pl: 'Ilość kartek',
                                en: 'Number of sheets',
                                de: 'Blattanzahl',
                                fr: 'Nombre de feuilles',
                            },
                            type: 'number',
                            placeholder: {
                                pl: 'Wpisz ilość (od 1 do 10)',
                                en: 'Enter the number (from 1 to 10)',
                                de: 'Anzahl eingeben',
                                fr: 'Préciser la quantité (1 à 10)',
                            },
                            childrenGroups: [
                                {
                                    id: 'pagesPlace',
                                    apiId: 'DODATKOWE_STRONY_MIEJSCE',
                                    required: true,
                                    title: {
                                        pl: 'Miejsce kartek',
                                        en: 'Sheets placement',
                                        de: 'Position der Blätter im Buch',
                                        fr: 'Emplacement des feuilles',
                                    },
                                    items: [
                                        {
                                            apiId: 'F',
                                            title: {
                                                pl: 'Przed blokiem notesu',
                                                en:
                                                    'In front of the writing pad',
                                                de: 'Vor dem Notizblock',
                                                fr: 'Devant le bloc-notes',
                                            },
                                        },
                                        {
                                            apiId: 'I',
                                            title: {
                                                pl: 'W środku notesu',
                                                en:
                                                    'In the middle of the writing pad',
                                                de: 'Im Notizblock verteilt',
                                                fr: 'Au milieu du bloc-notes',
                                            },
                                        },
                                        {
                                            apiId: 'B',
                                            title: {
                                                pl: 'Po bloku notesu',
                                                en: 'After the writing pad',
                                                de: 'Nach dem Notizblock',
                                                fr: 'Derrière le bloc-notes',
                                            },
                                        },
                                    ].filter((item) =>
                                        filterOptionsFn(
                                            item,
                                            DODATKOWE_STRONY_MIEJSCE
                                        )
                                    ),
                                },
                                {
                                    id: 'personalizedPrint',
                                    apiId: 'DODATKOWE_STRONY_RODZAJ_DRUKU',
                                    required: true,
                                    title: {
                                        pl: 'Rodzaj druku',
                                        en: 'Printing type',
                                        de: 'Druckart',
                                        fr: "Type d'impression",
                                    },
                                    items: [
                                        {
                                            apiId: '10',
                                            title: {
                                                pl: '1 + 0',
                                                en: '1 + 0',
                                                de: '1 + 0',
                                                fr: '1 + 0',
                                            },
                                        },
                                        {
                                            apiId: '11',
                                            title: {
                                                pl: '1 + 1',
                                                en: '1 + 1',
                                                de: '1 + 1',
                                                fr: '1 + 1',
                                            },
                                        },
                                        {
                                            apiId: '40',
                                            title: {
                                                pl: 'CMYK + 0',
                                                en: 'CMYK + 0',
                                                de: 'CMYK + 0',
                                                fr: 'CMYK + 0',
                                            },
                                        },
                                        {
                                            apiId: '44',
                                            title: {
                                                pl: 'CMYK + CMYK',
                                                en: 'CMYK + CMYK',
                                                de: 'CMYK + CMYK',
                                                fr: 'CMYK + CMYK',
                                            },
                                        },
                                    ].filter((item) =>
                                        filterOptionsFn(
                                            item,
                                            DODATKOWE_STRONY_RODZAJ_DRUKU
                                        )
                                    ),
                                },
                                {
                                    id: 'paperType',
                                    apiId: 'DODATKOWE_STRONY_RODZAJ_PAPIERU',
                                    required: true,
                                    title: {
                                        pl: 'Rodzaj papieru',
                                        en: 'Paper type',
                                        de: 'Papierart',
                                        fr: 'Type de papier',
                                    },
                                    items: [
                                        {
                                            apiId: 'S',
                                            title: {
                                                pl: 'Standardowy biały',
                                                en: 'White',
                                                de: 'Weiß',
                                                fr: 'Blanc',
                                            },
                                            hex: '#f7f7f7',
                                        },
                                        {
                                            apiId: 'K',
                                            title: {
                                                pl: 'Kredowy biały',
                                                en: 'Coated white',
                                                de: 'Beschichtet weiß',
                                                fr: 'Couché blanc',
                                            },
                                            hex: '#f7f7f7',
                                        },
                                        {
                                            apiId: 'I',
                                            title: {
                                                pl: 'Ivory',
                                                en: 'Ivory',
                                                de: 'Elfenbein',
                                                fr: 'Ivoire',
                                            },
                                            hex: '#f8f6f1',
                                        },
                                        {
                                            apiId: 'E',
                                            title: {
                                                pl: 'Biały z recyklingu',
                                                en: 'White recycled',
                                                de: 'Recycling weiß',
                                                fr: 'Recyclé blanc',
                                            },
                                            hex: '#eeebe3',
                                        },
                                        {
                                            apiId: 'G',
                                            title: {
                                                pl: 'Ekologiczny z trawy',
                                                en: 'Eco – grass',
                                                de: 'ECO mit Grasfasern',
                                                fr: "Eco d'herbe",
                                            },
                                            hex: 'url(#extraPageType)', //'#dfd0bd',
                                            image: '/img/_updates/MNT-212/80PG-320.jpg'
                                        },

                                        {
                                            apiId: 'A',
                                            title: {
                                                pl: 'Ekologiczny z jabłek',
                                                en: 'Eco – apple',
                                                de: 'ECO mit Apfel',
                                                fr: 'Eco de pommes',
                                            },
                                            hex: 'url(#extraPageType)',
                                            image: '/img/_updates/MNT-212/80PUA-112.jpg'
                                        },
                                    ].filter((item) =>
                                        filterOptionsFn(
                                            item,
                                            DODATKOWE_STRONY_RODZAJ_PAPIERU
                                        )
                                    ),
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'calendar',
                    apiId: 'KALENDARZ',
                    title: {
                        pl: 'Kalendarz',
                        en: 'Calendar',
                        de: 'Kalender',
                        fr: 'Agenda – calendrier',
                    },
                    choices: [
                        {
                            apiId: 'R',
                            title: {
                                pl: 'Kalendarz roczny (bieżący i kolejny rok)',
                                en: 'Annual calendar (current and next year)',
                                de:
                                    'Jahreskalender (aktuelles und nächstes Jahr)',
                                fr:
                                    'Calendrier annuel (année en cours et suivante) ',
                            },
                        },
                        {
                            apiId: 'K',
                            title: {
                                pl: 'Kalendarz kwartalny (bieżący rok)',
                                en: 'Quarterly calendar (current year)',
                                de: 'Vierteljahreskalender (aktuelles Jahr)',
                                fr: 'Calendrier trimestriel (année en cours) ',
                            },
                        },
                    ].filter((item) => filterOptionsFn(item, KALENDARZ)),
                },
            ]
        },
    },
}
</script>
