const ThemeClassMixin = {
    props: {
        theme: {
            default: '',
        },
    },
    data() {
        return {
            themeClassPrefix: '',
        }
    },
    computed: {
        themeClass() {
            if (!this.theme || this.theme.length === 0) {
                return ''
            }

            let themeArray = this.theme

            if (!Array.isArray(themeArray)) {
                themeArray = [themeArray]
            }

            let out = themeArray
                .map((v) => {
                    return this.themeClassPrefix + v
                })
                .join(' ')

            return out
        },
    },
}

export default ThemeClassMixin
