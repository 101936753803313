<template>
    <!-- eslint-disable -->
    <g>
        <filter
            id="vertical-shadow_vector"
            x="116"
            y="119"
            width="433"
            height="586"
            filterUnits="userSpaceOnUse"
        >
            <feOffset dx="20" dy="20" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="9" result="blur" />
            <feFlood flood-opacity="0.102" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
        </filter>

        <filter
            id="horizontal-shadow_vector"
            x="0"
            y="0"
            width="423"
            height="278"
            filterUnits="userSpaceOnUse"
        >
            <feOffset dx="20" dy="20" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="9" result="blur" />
            <feFlood flood-opacity="0.102" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
        </filter>
        <filter
            id="square-shadow_vector"
            x="101"
            y="57"
            width="315"
            height="312"
            filterUnits="userSpaceOnUse"
        >
            <feOffset dx="20" dy="20" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="9" result="blur" />
            <feFlood flood-opacity="0.102" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
        </filter>

        <linearGradient
            id="cover-soft-linear-gradient-3"
            x1="-0.035"
            y1="-0.05"
            x2="0.568"
            y2="0.644"
            xlink:href="#cover-soft-linear-gradient"
        />

        <!-- access-streamer defs -->
        <clipPath id="access-streamer--clip-path-vertical">
            <path
                id="Path_409"
                data-name="Path 409"
                class="access-streamer-1"
                d="M0,0H389V127.213H0Z"
            />
        </clipPath>
        <clipPath id="access-streamer--clip-path-horizontal">
            <path
                id="Path_409"
                data-name="Path 409"
                class="cls-1"
                d="M0,0H379.208V124.01H0Z"
            />
        </clipPath>
        <pattern
            id="access-streamer--pattern"
            preserveAspectRatio="xMidYMid slice"
            width="100%"
            height="100%"
            viewBox="0 0 1242 814"
        >
            <image width="1242" height="814" :xlink:href="base64[banderola]" />
        </pattern>
        <linearGradient
            id="access-streamer--linear-gradient-8"
            y1="0.335"
            x2="1"
            y2="0.335"
            gradientUnits="objectBoundingBox"
        >
            <stop offset="0" stop-opacity="0.122" />
            <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
            id="access-streamer--linear-gradient-9"
            x1="1"
            y1="0.335"
            x2="0"
            y2="0.335"
            xlink:href="#access-streamer--linear-gradient-8"
        />

        <clipPath id="access-streamer--clip-path-square">
            <path
                id="Path_409"
                data-name="Path 409"
                class="cls-1"
                d="M0,0H271.671V111.765H0Z"
            />
        </clipPath>

        <clipPath id="obwoluta--clip-path-vertical">
            <path
                id="Path_409"
                data-name="Path 409"
                class="cls-1"
                d="M0,0H389V537.546H0Z"
                transform="translate(18557 10881)"
            />
        </clipPath>
        <clipPath id="obwoluta--clip-path-horizontal">
            <path
                id="Path_409"
                data-name="Path 409"
                class="cls-1"
                d="M0,0H389V221.791H0Z"
            />
        </clipPath>
        <clipPath id="obwoluta--clip-path-square">
            <path
                id="Path_409"
                data-name="Path 409"
                class="cls-1"
                d="M0,0H269.5V248.022H0Z"
            />
        </clipPath>
        <pattern
            id="obwoluta--pattern"
            width="1"
            height="1"
            viewBox="116.592 67.171 350.647 261.824"
        >
            <image
                preserveAspectRatio="xMidYMid slice"
                width="688.135"
                height="451"
                :xlink:href="base64[banderola]"
            />
        </pattern>
        <pattern
            id="obwoluta--pattern-horizontal"
            width="1"
            height="1"
            viewBox="292.726 66.59 144.677 261.824"
        >
            <image
                preserveAspectRatio="xMidYMid slice"
                width="688.135"
                height="451"
                :xlink:href="base64[banderola]"
            />
        </pattern>
        <linearGradient
            id="obwoluta--linear-gradient-8"
            y1="0.335"
            x2="1"
            y2="0.335"
            gradientUnits="objectBoundingBox"
        >
            <stop offset="0" stop-opacity="0.122" />
            <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
            id="obwoluta--linear-gradient-9"
            x1="1"
            y1="0.335"
            x2="0"
            y2="0.335"
            xlink:href="#obwoluta--linear-gradient-8"
        />
    </g>
</template>

<script>
import { mapState } from 'vuex'
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            banderola: '/img/notes/banderola.jpg',
        }
    },
    computed: {
        ...mapState({
            verticalFormats: (state) => state.notes.verticalFormats,
        }),
    },
    created() {
        this.toDataUrl(this.banderola)
    },
}
</script>
