import material from '@/pages/material'
import paper from '@/pages/paper'
import cover from '@/pages/cover'
import format from '@/pages/format'
import coverMarking from '@/pages/coverMarking'
import inside from '@/pages/inside'
import flyleaf from '@/pages/flyleaf'
import personalized from '@/pages/personalized'
import accessories from '@/pages/accessories'
import edgeColoration from '@/pages/edgeColoration'
import summaryPage from '@/pages/summaryPage'
import packagePage from '@/pages/packagePage'
import formPage from '@/pages/formPage'
import yourConfig from '@/pages/yourConfig'
import offer from '@/pages/offer'
import formFinal from '@/pages/formFinal'
import sent from '@/pages/sent'

export default {
    paper,
    cover,
    format,
    material,
    coverMarking,
    inside,
    flyleaf,
    packagePage,
    personalized,
    accessories,
    edgeColoration,
    summaryPage,
    formPage,
    yourConfig,
    offer,
    formFinal,
    sent,
}
