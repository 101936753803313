<template>
    <!-- eslint-disable -->
    <div class="modal-format-patterns">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 325.455 459.165">
            <g transform="translate(-124.787 -110.914)">
                <rect
                    id="A4 (210 x 297)"
                    :class="[
                        'a',
                        { active: (format ? format.id : null) === 'a4' },
                    ]"
                    width="323.76"
                    height="457.889"
                    transform="translate(125.787 111.414)"
                />
                <rect
                    id="B5 (185 x 245)"
                    :class="[
                        'a',
                        { active: (format ? format.id : null) === 'b5' },
                    ]"
                    width="285.217"
                    height="377.72"
                    transform="translate(125.787 191.583)"
                />
                <rect
                    id="A5 (148 x 210)"
                    :class="[
                        'a',
                        { active: (format ? format.id : null) === 'a5' },
                    ]"
                    width="228.174"
                    height="323.76"
                    transform="translate(125.787 245.543)"
                />
                <rect
                    id="205 x 125"
                    :class="[
                        'a',
                        { active: (format ? format.id : null) === '205' },
                    ]"
                    width="316.051"
                    height="192.714"
                    transform="translate(125.787 376.588)"
                />
                <rect
                    id="A6 (105 x 148)"
                    :class="[
                        'a',
                        { active: (format ? format.id : null) === 'a6' },
                    ]"
                    width="161.88"
                    height="228.174"
                    transform="translate(125.787 341.129)"
                />
                <rect
                    id="84 x 135"
                    :class="[
                        'a',
                        { active: (format ? format.id : null) === '84' },
                    ]"
                    width="129.504"
                    height="208.131"
                    transform="translate(125.787 361.171)"
                />
                <rect
                    id="125 x 205"
                    :class="[
                        'a',
                        { active: (format ? format.id : null) === '125' },
                    ]"
                    width="192.714"
                    height="316.051"
                    transform="translate(125.787 253.251)"
                />
                <rect
                    id="175 x 175"
                    :class="[
                        'a',
                        { active: (format ? format.id : null) === '175' },
                    ]"
                    width="222.007"
                    height="222.007"
                    transform="translate(125.787 347.296)"
                />
                <text class="c" transform="translate(150.693 470.353)">
                    <tspan
                        v-if="size.text"
                        x="0"
                        y="0"
                        :class="['size', size.font]"
                    >
                        {{ size.text }} mm
                    </tspan>
                </text>
                <use id="use" :xlink:href="`#${format}`" />
            </g>
        </svg>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
    props: {
        choices: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapState({
            format: (state) => state.notes.configuration.format,
            locale: (state) => state.i18n.locale,
        }),
        size() {
            let text = '',
                font = 'default'
            const format = this.format ? this.format.id : null
            switch (format) {
                case 'a4':
                    text = '210 x 297'
                    font = 'default'
                    break
                case 'b5':
                    text = '185 x 245'
                    font = 'default'
                    break
                case 'a5':
                    text = '148 x 210'
                    font = 'medium'
                    break
                case '175':
                    text = '175 x 175'
                    font = 'default'
                    break
                case '125':
                    text = '125 x 205'
                    font = 'default'
                    break
                case '205':
                    text = '205 x 125'
                    font = 'default'
                    break
                case 'a6':
                    text = 'A6 (105 x 148)'
                    font = 'small'
                    break
                case '84':
                    text = '84 x 135'
                    font = 'small'
                    break
                default:
                    text = ''
                    font = 'default'
            }
            return { text, font }
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke-miterlimit: 10;
}

.a {
    stroke: #ccc;
}

.active {
    stroke: #e43c3c;
    stroke-width: 2px;
    transition: stroke 0.7s;
}

.c {
    fill: #e33d3d;
    font-size: 27px;
    font-family: SourceSansPro-Regular, Source Sans Pro;
}

.size {
    font-size: 27px;
    transition: font-size 0.3s;

    &.medium {
        font-size: 24px;
    }

    &.small {
        font-size: 17px;
    }
}
</style>
