var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',{attrs:{"transform":_vm.options[_vm.format].transform}},[_c('g',{attrs:{"id":"Group_1003","data-name":"Group 1003","transform":"translate(307 5)"}},[_c('line',{class:{
                'paper-perforation-standard':
                    _vm.perforationType === 'Standardowa',
                'paper-perforation-micro':
                    _vm.perforationType === 'Mikroperforacja',
            },attrs:{"id":"Line_206","data-name":"Line 206","y2":_vm.options[_vm.format].height}})]),_c('g',{attrs:{"id":"Group_1003","data-name":"Group 1003","transform":"translate(333 5)"}},[_c('line',{class:{
                'paper-perforation-standard':
                    _vm.perforationType === 'Standardowa',
                'paper-perforation-micro':
                    _vm.perforationType === 'Mikroperforacja',
            },attrs:{"id":"Line_206","data-name":"Line 206","y2":_vm.options[_vm.format].height}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }