<template>
    <div id="page-form" class="page-form">
        <Component :is="pages[currentSubPage]" v-bind="props" />
    </div>
</template>

<script>
import loginPages from './login'
import { mapState } from 'vuex'

export default {
    components: {
        ...loginPages,
    },
    data() {
        return {
            pages: { ...loginPages },
        }
    },
    computed: {
        ...mapState({
            currentSubPage: (state) => state.login.currentSubpage,
            props: (state) => state.login.subpageProps || {},
        }),
    },
}
</script>

<style lang="scss">
.page-form {
    height: 100%;
    overflow: auto;
    @include hide-scrollbar();

    @media (max-width: $screen-tablet-medium) {
        margin: 0;
        padding: 0 0 65px;
    }

    @media (max-width: $screen-mobile-large) {
        padding-bottom: 40px;
    }
}
</style>
