import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            formatGroup: (state) => {
                const format = state.notes.configuration.format
                const formatId = format ? format.id : null

                if (formatId === '205') {
                    return 'horizontal'
                }
                if (formatId === '175') {
                    return 'square'
                }
                return 'vertical'
            },
            formatSpecific: (state) => {
                const defaultFormat =
                    state.notes.configurationDefault.format &&
                    state.notes.configurationDefault.format.id
                const format =
                    state.notes.configuration.format &&
                    state.notes.configuration.format.id
                return format || defaultFormat
            },
        }),
    },
}
