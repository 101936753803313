import { individualFormDefaults } from '@/assets/data/form'
import Api from '@/api/ApiLauncher'

const state = {
    type: 'individual',
    logged: false,
    userData: individualFormDefaults,
}

const mutations = {
    setUserType(state, value) {
        state.type = value
    },
    loginUser(state, value) {
        state.logged = value
    },
    setUserData(state, value) {
        state.userData = value
    },
}

const actions = {
    sendLead({ state, rootState }, extended = false) {
        const payloadData = {
            quoteRequest: {
                referenceNumber: rootState.notes.configurationCode,
                calculationNr: String(
                    rootState.notes.configurationApiCode?.calculationId
                ),
            },
            customer: {
                salutation: state.userData?.salutation,
                firstName: state.userData?.firstName,
                lastName: state.userData?.lastName,
                companyName: state.userData?.companyName,
                email: state.userData?.email,
                // phone: state.userData?.phone,
                countryName: state.userData?.countryName,
                vatNr: state.userData?.vatNr,
            },
            gdprConsents: [],
        }
        if (state.userData?.PERMIT_ANSWER) {
            payloadData.gdprConsents.push('PERMIT_ANSWER')
        }
        if (state.userData?.PERMIT_MAILING) {
            payloadData.gdprConsents.push('PERMIT_MAILING')
        }

        if (extended) {
            payloadData.customer.phoneNumber = state.userData?.phone
            payloadData.customer.city = rootState.valuation.zip
            payloadData.customer.dealerName = state.userData?.dealerName
            payloadData.customer.dealerEmail = state.userData?.dealerEmail
            payloadData.customer.dealerContactDetails =
                state.userData?.dealerContactDetails
        }

        return Api.getService('lead').requestPut(
            extended ? 'extended' : 'base',
            {},
            payloadData
        )
    },
}

export default {
    state,
    mutations,
    actions,
}
