const state = {
    currentPage: {
        id: '',
    },
    next: '',
    previous: '',
    isPageLoading: false,
    allPages: [],
    nextPageLocked: false,
    screenWidth: null,
    isMobileMenuActive: false,
    isOverlayActive: false,
    hideMobileMenuBars: ['material', 'sent'],
}

const mutations = {
    setNextPageLocked(state, value) {
        state.nextPageLocked = value
    },
    setAllPages(state, value) {
        state.allPages = value
    },
    setScreenWidth(state, value) {
        state.screenWidth = value
    },
    setOverlay(state, value) {
        state.isOverlayActive = value
    },
    showMobileMenu(state, value) {
        state.isMobileMenuActive = value
        state.isOverlayActive = value
    },
    pageIsLoading(state) {
        state.isPageLoading = true
    },
    pageLoaded(state) {
        state.isPageLoading = false
    },
}

const actions = {
    changePage({ state, dispatch, rootState }, value) {
        dispatch('enableInMenu', value)

        let currentIndex = null
        let currentPage = null

        state.allPages.find((el, index) => {
            if (el.id === value) {
                currentIndex = index
                currentPage = el
            }
        })

        // allow hiding pages when there are dependencies
        let prev = 1
        const prevPage = state.allPages[currentIndex - 1]
        if (prevPage?.disabledByDependency) {
            prev = 2
        }

        state.previous = state.allPages[currentIndex - prev]

        let next = 1
        const nextPage = state.allPages[currentIndex + 1]
        if (nextPage?.disabledByDependency) {
            next = 2
        }
        state.next = state.allPages[currentIndex + next]

        state.currentPage = currentPage
    },

    enableInMenu({ state }, value) {
        let shouldStop = false
        state.allPages.forEach((el) => {
            if (!shouldStop) {
                el.disabled = false
            }
            if (el.id === value) {
                shouldStop = true
            }
        })
    },
    disableInMenu({ state }, value) {
        let shouldStop = false
        state.allPages.forEach((el) => {
            if (!shouldStop) {
                el.disabled = false
            } else {
                el.disabled = true
            }
            if (el.id === value) {
                shouldStop = true
            }
        })
    },
    disablePageByDependency({ state, commit, dispatch }, value) {
        const index = state.allPages.findIndex((el) => el.id === value)

        if (index > -1) {
            const pages = [...state.allPages] // copy
            pages[index].disabledByDependency = true // update
            commit('setAllPages', pages) // save
            dispatch('changePage', state.currentPage.id) //update next and previous page details
        }
    },
    clearPageDependency({ state, commit, dispatch }, value) {
        const index = state.allPages.findIndex((el) => el.id === value)

        if (index > -1) {
            const pages = [...state.allPages] // copy
            pages[index].disabledByDependency = false // update
            commit('setAllPages', pages) // save
            dispatch('changePage', state.currentPage.id) //update next and previous page details
        }
    },
}

export default {
    state,
    mutations,
    actions,
}
