<script>
import BasePageMixin from '../mixins/BasePageMixin'
import NotesImage from '../components/notes/NotesCover'
import EdgeColorationData from '@/mixins/pages/EdgeColorationData'
import { mapState } from 'vuex'

export default {
    components: {
        NotesImage,
    },
    extends: BasePageMixin,
    mixins: [EdgeColorationData],
    computed: {
        ...mapState({
            edgeColoration: (state) => state.notes.configuration.edgeColoration,
        }),
    },
    methods: {
        validate() {
            return this.edgeColoration
                ? this.edgeColoration.general
                    ? !!this.edgeColoration.metallicFoilColor
                    : true
                : true
        },
    },
}
</script>
