<template>
    <image
        :href="base64[image[format].url]"
        :transform="image[format].transform"
    />
</template>

<script>
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            image: {
                a4: {
                    url: '/img/notes/inside/standard/lines-vertical.png',
                    transform: 'translate(34 18)',
                },
                '205': {
                    url: '/img/notes/inside/standard/lines-horizontal.png',
                    transform: 'translate(12 3)',
                },
                '175': {
                    url: '/img/notes/inside/standard/lines-square.png',
                    transform: 'translate(18 5)',
                },
                b5: {
                    url: '/img/notes/inside/standard/b5/lines-b5.png',
                    transform: 'translate(35 30)',
                },
                a5: {
                    url: '/img/notes/inside/standard/a5/lines-a5.png',
                    transform: 'translate(35 45)',
                },
                a6: {
                    url: '/img/notes/inside/standard/a6/lines-a6.png',
                    transform: 'translate(33 45)',
                },
                '125': {
                    url: '/img/notes/inside/standard/125/lines-125.png',
                    transform: 'translate(42 33)',
                },
                '84': {
                    url: '/img/notes/inside/standard/84/lines-84.png',
                    transform: 'translate(42 39)',
                },
            },
        }
    },

    created() {
        Object.keys(this.image).forEach((format) => {
            this.toDataUrl(this.image[format].url)
        })
    },
}
</script>
