<template>
    <!-- eslint-disable -->
    <g v-if="calendarType && currentPage.id === 'personalized'">
        <CalendarQuarter
            v-if="calendarType.startsWith('Kalendarz kwartalny')"
            :format="format"
        />
        <CalendarYear
            v-if="calendarType.startsWith('Kalendarz roczny')"
            :format="format"
        />
    </g>
</template>

<script>
import CalendarYear from './CalendarYear'
import CalendarQuarter from './CalendarQuarter'
import { mapState } from 'vuex'

export default {
    components: {
        CalendarYear,
        CalendarQuarter,
    },
    props: {
        format: {
            type: String,
            default: '',
        },
        calendarType: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState({
            currentPage: (state) => state.page.currentPage,
        }),
    },
}
</script>
