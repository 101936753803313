import { currencies } from '@/assets/data/currencies'

const state = {
    isGettingValuation: false,
    currentCurrency: null,
    defaultCurrency: currencies[0],
    volumes: [],
    validPrices: {
        calculation: '',
        currency: '',
        zip: '',
        country: '',
        prices: [],
    },
    country: '',
    zip: '',
}

const getters = {
    validValuationConfig(state) {
        return (
            state.country.toLowerCase() === 'inny' ||
            (state.country && state.zip && state.zip.length > 3)
        )
    },
    isCalcualtionValid(state, getterrs, rootState) {
        return (
            state.validPrices.calculation ===
                rootState.notes.configurationApiCode?.calculationId &&
            state.validPrices.country === state.country &&
            state.validPrices.zip === state.zip &&
            state.validPrices.currency === state.currentCurrency?.id
        )
    },
    validValuationPrices(state) {
        return state.validPrices.prices || []
    },
}

const mutations = {
    setCurrency(state, value) {
        state.currentCurrency = value
    },
    setValuationCountry(state, value) {
        state.country = value
    },
    setValuationZip(state, value) {
        state.zip = value
    },
    addVolume(state, id) {
        const last = state.volumes[state.volumes.length - 1]?.quantity
        state.volumes.push({
            id,
            quantity: last ? last : 100,
        })
    },
    removeVolume(state, id) {
        if (state.volumes.length > 1) {
            state.volumes = state.volumes.filter((el) => {
                return el.id !== id
            })
        }
    },
    setValidPrices(state, { calculation, prices }) {
        state.validPrices = {
            calculation,
            country: state.country,
            zip: state.zip,
            currency: state.currentCurrency.id,
            prices: prices.filter(
                (p) => p.currency === state.currentCurrency.id
            ),
        }
    },
}
export default {
    state,
    getters,
    mutations,
}
