<template>
    <div v-if="showCheckbox">
        <div
            :class="['input-checkbox', { 'is-checked': checked }]"
            @click="onClick"
        >
            <div class="input-mark" />
            <div class="input-checkbox-label" v-text="title" />
        </div>
        <div v-if="childrenGroups && checked && showChildrenGroups">
            <InputCheckboxChildren
                v-for="(childrenGroup, index) in childrenGroups"
                :id="childrenGroup.id"
                :key="childrenGroup.id"
                :title="childrenGroup.title[locale]"
                :type="childrenGroup.type"
                :items="childrenGroup.items"
                :required="childrenGroup.required"
                :current-settings="childrenSettings"
                :disabled="index > currentEnabled"
                @edit="onSelect"
                @select="onSelect"
            />
        </div>
    </div>
</template>

<script>
import InputCheckboxChildren from './InputCheckboxChildren'
import { mapState, mapMutations } from 'vuex'

export default {
    components: {
        InputCheckboxChildren,
    },
    props: {
        option: {
            type: Object,
            default: () => {},
        },
        choice: {
            type: Object,
            default: () => {},
        },
        title: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        childrenGroups: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            childrenSettings: null,
            currentEnabled: 0,
            listOfChildrenGroups: [],
        }
    },
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
            locale: (state) => state.i18n.locale,
        }),
        showChildrenGroups() {
            let show = false
            this.childrenGroups?.forEach((group) => {
                if (group?.items?.length) {
                    show = true
                }
            })
            return show
        },
        showCheckbox() {
            if (this.childrenGroups?.length) {
                return this.showChildrenGroups
            } else {
                return true
            }
        },
    },
    watch: {
        checked() {
            if (!this.checked) {
                this.cleanChildrenSettings()
            }
        },
    },
    created() {
        if (this.childrenGroups && this.childrenGroups.length > 0) {
            this.defaultChildrenSettings()
            this.getListOfChildrenGroups()
            this.checkEnabled()
        }
    },
    methods: {
        ...mapMutations(['setCurrentOption', 'setCurrentSubOption']),
        onClick() {
            let value = this.choice
            if (this.checked) {
                value = null
                this.setCurrentOption(this.option)
                this.setCurrentSubOption(null)
                this.cleanChildrenSettings()
            }
            this.$emit('input', {
                id: this.option.id,
                value: value,
                lockOption: !!this.childrenGroups,
            })
        },
        onSelect(data) {
            this.setCurrentOption(this.option)
            this.$emit('input', {
                id: this.option.id,
                value: null,
            })
            let currentIndex
            if (this.listOfChildrenGroups.includes(data.id)) {
                currentIndex = this.listOfChildrenGroups.indexOf(data.id)
            }

            let lockOption = true

            if (data.value) {
                this.childrenSettings[data.id] = data.value
                this.currentEnabled = currentIndex + 1
                lockOption = currentIndex < this.listOfChildrenGroups.length - 1
            } else {
                this.currentEnabled = currentIndex
                for (
                    let i = currentIndex;
                    i < this.listOfChildrenGroups.length;
                    i++
                ) {
                    delete this.childrenSettings[this.listOfChildrenGroups[i]]
                }
            }

            this.$emit('input', {
                id: this.option.id,
                value: this.childrenSettings,
                lockOption: lockOption,
            })
        },
        defaultChildrenSettings() {
            if (
                typeof this.configuration[this.option.id] === 'object' &&
                this.configuration[this.option.id] != null
            ) {
                if (
                    this.configuration[this.option.id]['general'] &&
                    this.configuration[this.option.id]['general'].title[
                        'locale'
                    ] === this.id
                ) {
                    this.childrenSettings = this.configuration[this.option.id]
                } else {
                    this.cleanChildrenSettings()
                }
            } else {
                this.cleanChildrenSettings()
            }
        },
        cleanChildrenSettings() {
            this.childrenSettings = {}
            this.childrenSettings['general'] = this.choice
        },
        getListOfChildrenGroups() {
            this.listOfChildrenGroups = this.childrenGroups.map((group) => {
                return group.id
            })
        },
        checkEnabled() {
            this.listOfChildrenGroups.forEach((item) => {
                if (this.childrenSettings[item]) {
                    this.currentEnabled++
                } else {
                    return
                }
            })
        },
    },
}
</script>

<style lang="scss">
.input-checkbox {
    padding: 14px 27px 12px;
    border-bottom: $color-border-input solid 2px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:last-child {
        border-bottom: none;
    }

    &-label {
        display: inline-block;
        font-size: 1.6rem;
        max-width: calc(100% - 40px);
        padding-left: 15px;
    }

    &.is-checked {
        .input-mark {
            &:after {
                opacity: 1;
            }
        }
    }
}
</style>
