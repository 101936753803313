<template>
    <div
        :class="[
            'form-field form-field-checkbox',
            {
                'is-checked': checked,
                'is-required': required,
                'is-error': error,
            },
        ]"
        @click="toggle"
    >
        <div class="form-field-checkbox-control">
            <div class="form-field-checkbox-control-input" />
        </div>
        <div
            v-bastards
            class="form-field-checkbox-label"
            :class="{ 'form-field-checkbox-label--expanded': labelExpanded }"
            v-html="label"
        />
        <ArrowIcon
            class="form-field-checkbox-arrow"
            :class="{ 'form-field-checkbox-arrow--rotated': labelExpanded }"
            @click.stop="expandLabel"
        />
    </div>
</template>

<script>
import ArrowIcon from '@/assets/images/system-icons/arrow-down.svg'

export default {
    components: {
        ArrowIcon,
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            checked: false,
            error: null,
            labelExpanded: false,
        }
    },
    created() {
        this.checked = !!this.value
    },
    methods: {
        expandLabel() {
            this.labelExpanded = !this.labelExpanded
        },
        toggle() {
            this.checked = !this.checked
            setTimeout(() => {
                this.$emit('input', this.checked)
            }, 150)
        },
        validate() {
            this.error = null
            return this.validNotEmpty()
        },
        validNotEmpty() {
            let valid = !(this.required && !this.checked)
            if (!valid) {
                this.error = 'To pole nie może być puste'
            }
            return valid
        },
    },
}
</script>

<style lang="scss">
.form-field-checkbox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 28px;
    position: relative;
    padding-right: 40px;
    justify-content: flex-start;

    &-control {
        width: 8%;
        margin-top: 10px;

        &-input {
            border: $color-border-input-darker solid 1px;
            transition: all 0.2s;
            cursor: pointer;
            @include size(24px);

            &:after {
                content: '';
                display: block;
                background: url('/img/check-red.svg') 50% 50% no-repeat;
                background-size: 63%;
                opacity: 0;
                transition: opacity 0.2s;
                @include size(100%);
            }
        }
    }

    &-label {
        cursor: pointer;
        width: 92%;
        font-size: 16px;
        line-height: 22px;

        overflow: hidden;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        .red {
            color: $color-burnt-sienna;
        }

        &--expanded {
            overflow: visible;
            display: block;
        }
    }

    &-arrow {
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
        transition: 0.2s;

        &--rotated {
            transform: rotate(180deg);
        }
    }

    &.is-checked & {
        &-control {
            &-input {
                border-color: $color-burnt-sienna;

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &.is-error & {
        &-control {
            &-input {
                border-color: $color-burnt-sienna;
            }
        }
    }

    @media (max-width: $screen-tablet-medium) {
        &-label {
            font-size: 1.4rem;
            margin-left: 20px;
        }

        &-control {
            width: 40px;
            margin-top: 3px;

            &-input {
                @include size(40px);
                padding: 8px;
                margin-right: 25px;
            }
        }
    }

    @media (max-width: $screen-mobile-landscape) {
        align-items: flex-start;

        &-control {
            margin-top: 0;

            &-input {
                margin-top: 6px;
            }
        }

        &-label {
            margin-left: 25px;
        }
    }
}
</style>
