const apiParams = {
    material: 'MATERIAL',
    coverType: 'OKLADKA_RODZAJ_OKLADKI',
    coverCorners: 'KRAWEDZIE_TYP',
    coverRidge: 'OKLADKA_RODZAJ_GRZBIETU',
    format: 'FORMAT',
    coverColorPattern: 'OKLEINA',
    coverColorType: 'OKLEINA_TYP',
    markingPrint: 'OKLADKA_ZNAKOWANIE_DRUK',
    markingExpression: 'OKLADKA_ZNAKOWANIE_TLOCZENIE',
    markingCoverFinish: 'OKLADKA_ZNAKOWANIE_WYKONCZENIE',
    paperType: 'BLOK_RODZAJ_PAPIERU',
    numberOfPages: 'BLOK_ILOSC_KARTEK',
    perforation: 'BLOK_PERFORACJA',
    binderHoles: 'BLOK_OTWORY',
    flyleaf: 'WYKLEJKA_RODZAJ_ZADRUKU',
    flyleafPaper: 'WYKLEJKA_RODZAJ_PAPIERU',
    pocket: 'KIESZEN_KOLORY',
    additionalPages: 'DODATKOWE_STRONY_RODZAJ_PAPIERU', //DODATKOWE_STRONY_MIEJSCE;DODATKOWE_STRONY_ILOSC;DODATKOWE_STRONY_RODZAJ_DRUKU
    calendar: 'KALENDARZ',
    elastic: 'GUMKA_ZAMYKAJACA',
    penHolder: 'UCHWYT_NA_DLUGOPIS',
    ribbon: 'TASIEMKA',
    streamer: 'BANDEROLA',
    edgeColoration: 'KRAWEDZIE_FOLIA',
    wrapping: 'OPAKOWANIE',
}

// TODO wsparcie dla tablicy z opcjami jak dla "additional pages"

function paramMapper(param) {
    return apiParams[param]
}

function paramMapperReverse(param) {
    let output = null
    Object.keys(apiParams).forEach((apiParam) => {
        if (apiParams[apiParam] === param) {
            output = apiParam
        }
    })
    return output
}
export { paramMapper, paramMapperReverse }
