const env = {
    env: {
        mock: {
            path: process.env.VUE_APP_MOCK_URL,
            endpointSuffix: '.json',
        },
        api: {
            path: process.env.VUE_APP_API_URL,
        },
    },
}

const config = {
    config: {
        env: process.env.BUILD_ENV || 'mock',
    },
}

export { config, env }
