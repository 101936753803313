export default {
    general: {
        loading: 'Loading',
        back: 'Homepage',
        configurator: 'Notizbuch-Konfigurator',
        page: ' Seite',
        page2: ' Seiten',
        pages: ' Seiten',
        zoom: 'Einband vergrößern',
        zoom2: 'Druckart vergrößern',
        buttonView: 'Ansicht ändern',
        notfound: 'Land nicht gefunden',
    },
    material: {
        title: 'Kreieren Sie Ihre Mindnotes®',
        fsc: 'Mindnotes aus FSC<sup>®</sup>-zertifizierten Materialien',
        standard: 'Mindnotes aus Standard- und umweltfreundlichen Materialien',
        label: 'Konfiguration starten',
        load: 'Konfiguration aufrufen:',
        description:
            'Geben Sie den Konfigurationscode aus einer bereits erstellten Konfiguration ein, um diese neu aufzurufen.',
        loadMobile: 'Bereits vorhandene Konfiguration aufrufen',
    },
    paper: {
        title: 'Einband Material',
        filter: 'Einbandmaterialien filtern nach:',
        color: 'Farbe wählen',
        category: 'Kategorie wählen',
        search: 'Namen des Einbandes oder Einbandcode eingeben',
        noResults: 'Keine Suchergebnisse',
        search2: 'Material suchen',
        papier: 'Papier',
        okleina: 'Einband',
        placeholder: 'Namen des Einbandes oder Einbandcode eingeben',
        fallback: 'Keine Einbände für die ausgewählte Konfiguration vorhanden.',
    },
    config: {
        title: 'Ihre Konfiguration',
        subtitle:
            'In diesem Schritt können Sie Ihre komplette Konfiguration überprüfen und herunterladen. (ohne Verkaufspreise)',
        details: 'Details zu Ihrer Konfiguration anzeigen',
        button1: 'PDF herunterladen',
        linkTitle: 'Link zu Ihrer Konfiguration',
        linkDesc:
            'Kopieren Sie den Code oder Link, um bei späteren Bestellungen dieselbe Konfiguration wieder verwenden zu können.',
        button2: 'Code kopieren',
        button3: 'Link kopieren',
        desc2:
            'Wenn Sie ein Angebot mit Marktpreisen erhalten möchten, loggen Sie sich bitte ein.',
        logowanko: 'Nächste',
        copyCode: 'Code kopiert',
        copyLink: 'Link kopiert',
    },
    summary: {
        title: 'Zusammenfassung',
        save: 'Als PDF speichern',
        edit: 'Bearbeiten',
        goToForm: 'Zum Formular gehen',
        sendForm: 'Bestätigen und senden',
        info: {
            send: 'Formular wird gesendet...',
            pdf: `Um Ihre Konfiguration als PDF zu speichern, klicken Sie auf die Schaltfläche unten`,
            individual: `"PromoNotes erfüllt alle Aufträge ausschließlich über seine Distributoren, die Werbeagenturen sind"`,
            form: `Um ein verbindliches Angebot von einem Distributor zu erhalten, kontaktieren Sie PromoNotes bitte, indem Sie das Formular ausfüllen`,
            agency: `Um eine offizielle Angebotsanfrage zu stellen und ein verbindliches kommerzielles Angebot zu erhalten, klicken Sie auf die Schaltfläche 'Zum Angebot gehen'`,
            erp: `Nach dem Klicken verlassen Sie die Mindnotes-Website und werden zum PromoNotes-Kundenportal weitergeleitet. Dort finden Sie ein verbindliches kommerzielles Angebot für Ihre Konfiguration sowie die Historie früherer Angebotsanfragen.`,
        },
    },
    pdf: {
        summary: 'Konfigurationszusammenfassung',
        configurationNo: 'Kalkulationsnummer: ',
        code: 'Konfigurationscode: ',
        link: 'Link zur Konfiguration',
        file1: 'Vielen Dank für die erfolgreiche',
        file2: 'Konfiguration Ihres Mindnotes® - Notizbuchs',
        file3:
            'Alle Händler von Werbeprodukten bitten wir um Kontaktaufnahme mit unserem Verkaufsteam der PromoNotes GmbH. Alles,',
        file4:
            'was Sie tun müssen, ist, Ihre Konfigurationsnummer an Ihren Vertriebsmitarbeiter bei PromoNotes oder an die Adresse ',
        file5: 'info@promonotes.de',
        file6:
            'info@promonotes.de zu senden, und wir werden uns mit Ihnen in Verbindung setzen.',
        file7:
            'Sollten Sie kein Wiederverkäufer von Werbeartikeln sein, wenden Sie sich bitte an Ihren Werbeartikelhändler oder',
        file71: 'Ihre Werbemittelagentur.',

        file72:
            'Wenn Sie nicht mit einem Lieferanten zusammengearbeitet haben, der Mindnotes®-Produkte anbietet, schreiben Sie uns an ',
        file73: 'und wir werden Sie mit unserem Händler kontaktieren.',
        file73Html: ' und wir werden Sie mit unserem Händler kontaktieren.',
        file8:
            'Die bereitgestellten Maße und Grafiken sind Richtwerte und können keine Grundlage für eine Reklamation darstellen.',
        file9:
            'Der Hersteller behält sich  technische Änderungen an einzelnen Produkten vor und haftet nicht für eventuelle Fehler ',
        file10:
            'im Konfigurator. Beschreibung,  Konfigurationszusammenfassungen und im Konfigurator generierte Grafiken stellen kein ',
        file11:
            'Angebot im Sinne der Bestimmungen des Bürgerlichen Gesetzbuches dar.',
        footer: 'Promonotes Sp. z o.o. Alle Rechte vorbehalten',
        page: 'Seite ',
        data: 'Datum: ',
    },
    form: {
        form: 'Formular',
        title: 'Einloggen',
        fill:
            'Um ein Preisangebot zu erhalten, füllen Sie bitte die Formularfelder aus.',
        company: 'Firmenname',
        phone: 'Telefonnummer',
        email: 'E-mail',
        website: 'Homepage',
        street: 'Straße',
        houseNumber: 'Haus-/Wohnungsnummer',
        city: 'Ort',
        nip: 'USt-Id',
        zipcode: 'plz',
        country: 'Land',
        name: 'Vorname',
        surname: 'Familienname',
        position: 'Position',
        agencyName: 'Werbemittelagentur',
        agencyEmail: 'E-Mail-Adresse der Agentur',
        agencyAddress: 'Kontaktperson bei der Agentur',
        agencyInfo: '',
        message: 'Ihre Bestellnachricht',
        emailcopy:
            'Kopie meiner Notizbuch-Konfiguration an die angegebenen E-Mail-Adressen senden',
        consent1:
            '<span class="red">*</span> Ich erkläre mich damit einverstanden, dass die PromoNotes sp. z o.o. meine im Formular angegebenen personenbezogenen Daten zwecks Beantwortung der über das Kontaktformular gestellten Anfrage verarbeitet. Hinweise zur Verarbeitung personenbezogener Daten sind unter dem Lesezeichen DSGV zu finden.',
        consent2:
            'Ich erkläre mich damit einverstanden, von der PromoNotes sp. z o.o. per E-Mail an die von mir im Formular angegebene E-Mail-Adresse Handelsinformationen über ihre Produkte und Dienstleistungen im Sinne des polnischen Gesetzes über elektronische Dienstleistungen vom 18. Juli 2002 (pln. GBl. 2019, Pos.123) zu empfangen. Die Zustimmung ist freiwillig. Hinweise zur Verarbeitung personenbezogener Daten sind unter dem Lesezeichen DSGV',
        consent3:
            'Ich erkläre mich damit einverstanden, dass Telekommunikationsendgeräte und automatisierte Anrufsysteme im Sinne des polnischen Gesetzes vom 16. Juli 2004 Telekommunikationsrecht (pln. GBl. 2004 Nr. 171, Pos. 1800, konsolidierte Fassung pln. GBl. 2018, Pos. 1954) eingesetzt werden, um mich unter meiner E-Mail-Adresse und Telefonnummer zwecks des Direktmarketing, darunter Kontaktaufnahme und Vorstellung von Angeboten durch die Gesellschaft PromoNotes zu erreichen. Die Zustimmung ist freiwillig. Hinweise zur Verarbeitung personenbezogener Daten sind unter dem Lesezeichen DSGV zu finden.',
        send: 'senden',
        description:
            'Sollten Sie bereits Kunde von PromoNotes sein, loggen Sie sich bitte mit Ihren Angaben ein.',
        password: 'Passwort',
        password2: 'Passwort wiederholen',
        login: 'Einloggen',
        required: 'Pflichteingabefeld',
        forgotPass: 'Passwort vergessen?',
        customerType: 'Kundenart',
        individual: 'Endkunde',
        adAgency: 'Werbeagentur',
        add: 'Hinzufügen',
        goToOffer: 'Zum Angebot gehen',
        signInAndGoToOffer: 'Konto erstellen und zum Angebot gehen',
        register: {
            title: 'Anmeldung',
            secondTitle: 'Ihre Angaben',
            send: 'Anmelden',
        },
        passwordPage: {
            title: 'Passwort vergessen?',
            description:
                'Geben Sie die E-Mail-Adresse ein, die Sie bei der Erstellung Ihres Kontos benutzt haben, damit wir Ihnen einen Link zum Zurücksetzen des Passwortes senden können.',
            label: 'E-mail',
            button: 'senden',
            hello: 'Guten Tag,',
            company: 'von der Firma',
            tip:
                'In diesem Schritt erstellen Sie Ihr Kontopasswort. Damit es sicher ist, muss es aus 8 bis 16 Zeichen bestehen, darunter müssen Großbuchstaben, Zahlen und Sonderzeichen enthalten sein.',
            passWeak: 'Ihr Passwort ist schwach',
            passGood: 'Ihr Passwort ist gut',
            passStrong: 'Ihr Passwort ist sicher',
            passResetTitle: 'Ihr Passwort wurde zurückgesetzt',
            passResetDesc:
                'Um sich bei Ihrem Konto einzuloggen, müssen Sie ein neues Passwort erstellen.',
            passResetSave: 'Abonnieren',
        },
        agency: {
            noAcc: 'Sie haben noch kein Konto?',
            register:
                'Die Anmeldung ist erforderlich, um Daten für die Auftragsabwicklung bereitzustellen. Sollten Sie bereits Kunde von Promonotes sein, sind Ihre Daten in unserer Datenbank hinterlegt und Sie müssen diese nur noch bestätigen.',
            createAcc: 'Konto erstellen',
        },
        male: 'Herr',
        female: 'Frau',
    },
    offer: {
        title: 'Angebot',
        description:
            'Füllen Sie die Pflichtfelder aus, damit wir Ihnen unseren empfohlenen Marktverkaufspreis nennen können.',
        prints: 'Auflage',
        price2: 'Kaufpreis',
        price1: 'Empfohlener Marktverkaufspreis',
        transportCost: 'Transportkosten',
        legalInfo:
            'Die angegebenen Preise haben lediglich einen Informationscharakter und stellen kein Angebot im Sinne von Art. 66 § 1 des polnischen Zivilgesetzbuches dar. Wir behalten uns das Recht vor, die angegebenen Preise zu ändern. Es sind Nettopreise und enthalten keine Steuern bzw. Zollgebühren. Um ein verbindliches Angebot zu erhalten, gehen Sie zur ZUSAMMENFASSUNG.',
        country: 'Land',
        countryOther:
            'Sollte Ihr Land auf der Liste nicht aufgeführt sein, wählen Sie bitte die Option ANDERES.',
        calculate: 'Berechnen',
        orientation:
            'Die Produktionszeit beträgt ca. 14-21 Tage ab dem Datum der Druckfreigabe.',
        currency: 'Währung',
        szt: 'st.',
        other: 'ANDERES',
        warning: 'Lieferbedingungen',
        addPrints: 'Menge hinzufügen',
        error: {
            form: `Validierungsfehler des Formulars, bitte füllen Sie die Felder korrekt aus`,
        },
        info: {
            form: 'Formularüberprüfung...',
            valuation: 'Senden der Berechnung...',
        },
    },
    sent: {
        title: 'Vielen Dank für Ihre Bestellung.',
        desc: 'Wir werden schnellstmöglich mit Ihnen in Kontakt treten.',
        button: 'Neue Konfiguration',
    },
    modal: {
        coverSoft: 'Softcover aus Papier',
        coverSoftDescr:
            'Der einfachste, ansprechendste und gleichzeitig preiswerteste Einband in der Mindnotes® Kollektion',
        coverHard: 'Hardcover mit Papier oder einem Bezugsmaterial kaschiert',
        coverHardDescr:
            'Klassischer Bucheinband, der in einer breiten Palette von Papier- und Textileinbänden erhältlich ist.',
        coverSoft2: 'Softcover mit Einbandmaterial.',
        coverSoft2Descr:
            'Der heimliche Star der Mindnotes® Kollektion. Klassisches Einbandmaterial kombiniert in einem elastischen und exklusiven Softumschlag',
        coverHard2: 'Elastisches Hardcover mit Einbandmaterial',
        coverHard2Descr:
            'Klassisch fester und zugleich angenehm elastischer Bucheinband, der in der Mindnotes® Kollektion ausschließlich mit unseren Materialien-Einbänden kombiniert wird.',
        cornerRound: 'Runde Ecken',
        cornerStraight: 'Spitze Ecken',
        ridgeSoft: 'Weicher Rücken',
        ridgeHard: 'Gerader Rücken',
        flyleaf:
            'Ein Notizbuchbestandteil, der den Einband mit dem Schreibblock verbindet und in der Regel aus dickerem Papier als der Schreibblock hergestellt wird.',
        pocket:
            'Praktische Dokumententasche, diese ist im Nachsatz angebracht.',
        package1:
            'Lose im Karton – Notizbücher werden direkt in Großkartons verpackt. Auf Wunsch können zwischen den einzelnen Schichten der Notizbücher, Trennblätter aus Papier zum Schutz eingefügt werden.',
        package2:
            'Standardverpackung – einzeln im PP Folienbeutel mit Selbstklebeverschluss oder mit Perforation.',
        package3:
            'Umweltfreundliche Verpackung – das Produkt wird in eine kompostierbare Folie aus Maisstärke verpackt. Beutel mit Perforation und Aufdruck (Logo 100% kompostierbar).',
        marking1:
            '<strong>Folienprägung </strong>- bis 50 cm<sup>2</sup> an einer Position',
        marking2:
            '<strong>Blindprägung klein</strong> - bis 50 cm<sup>2</sup> – an einer Position',
        marking3:
            '<strong>Blindprägung groß </strong>  - bis 150 cm<sup>2</sup> – an einer Position',
        paperTypeSoft1: 'Das beliebteste Einbandmaterial für Standard-Notizbücher ist:',
        paperTypeSoft2: 'beidseitig gestrichener Karton 250g/m2',
        paperTypeSoft3: 'Die beliebtesten Einbandmaterialien für umweltfreundliche Notizbücher sind:',
        paperTypeSoft4: 'recycelter ungestrichener Kraftkarton 300g/m2',
        paperTypeSoft5: 'recyceltes Offset Papier 300g/m2',
        paperTypeTorino: 'Das beliebteste Material für Notebook-Einbände in der Buchbinderei ist Torino-Furnier.',
        paperTypeHardVeneer1: 'Das beliebteste Einbandmaterial für Standard-Notizbücher ist:',
        paperTypeHardVeneer2: 'gestrichenes Papier 150 g/m2',
        paperTypeHardVeneer3: 'Die beliebtesten Einbandmaterialien für umweltfreundliche Notizbücher sind:',
        paperTypeHardVeneer4: 'recyceltes Offset Papier 160g/m2',
        paperTypeHardVeneer5: 'recyceltes unbeschichtetes Kraftpapier 120g/m2',
    },
    errors: {
        required: 'Pflichtoptionen wählen',
        cover: 'Aus jeder Kategorie muss mindestens eine Option gewählt werden',
        format: 'Format wählen',
        paper: 'Einband wählen',
        coverMarking:
            'Aus jeder Kategorie muss mindestens eine Option gewählt werden',
        packagePage: 'Verpackungsart wählen',
    },
    link: {
        mainWebsite: `${process.env.VUE_APP_WEBSITE_URL}/de`,
        configurationUrl: `${process.env.VUE_APP_CONFIGURATOR_URL}/de/konfigurator/`,
        label: 'mindnotes.eu',
    },
    format: {
        title: 'Formate',
        netto: 'Nettomaß des Notizbuchblocks',
    },
    meta: {
        title:
            'Personalisierte Notizbücher mit Werbedruck von Ihrem Firmenlogo : Online - Konfigurator',
        description:
            'Kreieren Sie Ihr perfektes Notizbuch und wählen Sie aus über hundert Wahloptionen: Format, Ecken, Rücken, Papier und Druck, wählen Sie die Accessoires und der Einbandveredelung. Es gibt so viele Möglichkeiten, wieviel Ideen Sie haben. Bitte überprüfen.',
    },

    loader: {
        close: 'schließen',
        info: {
            config: 'Konfiguration abrufen...',
            download: 'Generierung von PDF-Dateien...',
            deps: 'Abhängigkeiten werden heruntergeladen...',
            summary: 'Berechnungsgenerierung...',
        },
        error: {
            general: `<strong>Bei der Anwendung ist ein Fehler aufgetreten</strong> <br/>Bitte versuchen Sie es bald erneut.`,
            config:
                '<strong>Beim Herunterladen der Konfiguration ist ein Fehler aufgetreten</strong> <br/>die Konfiguration existiert nicht oder ist nicht mehr gültig.',
            pdf: `<strong>Beim Generieren der Datei ist ein Fehler aufgetreten</strong> <br/>Bitte versuchen Sie es erneut.`,
            deps: `<strong>Beim Herunterladen der Abhängigkeiten ist ein Fehler aufgetreten</strong> <br/>Bitte versuchen Sie es erneut.`,
            summary: `<strong>Beim Generieren der Berechnung ist ein Fehler aufgetreten</strong> <br/>Bitte versuchen Sie es erneut.`,
        },
    },
}
