import Vue from 'vue'
import pl from '@/assets/data/dictionary/pl'
import en from '@/assets/data/dictionary/en'
import de from '@/assets/data/dictionary/de'
import fr from '@/assets/data/dictionary/fr'
import { mapState } from 'vuex'

Vue.mixin({
    computed: {
        ...mapState({
            currentLocale: (state) => state.i18n.locale,
            fallbackLocale: (state) => state.i18n.defaultLocale,
        }),
    },
    methods: {
        localePath(path, locale) {
            let redirectTo = ''

            if (!path) {
                return '/'
            }

            if (path && path.indexOf('/') !== 0) {
                path = '/' + path
            }

            if (locale || this.currentLocale !== this.fallbackLocale) {
                redirectTo = '/' + (locale || this.currentLocale) + path
            } else {
                redirectTo = path
            }

            return redirectTo
        },

        $t(location) {
            const currentLocale = this.currentLocale
            const currentDictionary = getDictionary.call(this, currentLocale)
            let translation
            translation = translate(currentDictionary, location)

            if (!translation) {
                const fallbackDictionary = getDictionary.call(
                    this,
                    this.fallbackLocale
                )
                translation = translate(fallbackDictionary, location)
            }

            return translation

            function translate(dictionary, translationLocation) {
                let translation = dictionary
                const locationElements = translationLocation.split('.')
                try {
                    locationElements.forEach((location) => {
                        translation = translation[location]
                    })
                    if (!translation) {
                        throw Error
                    }
                } catch (error) {
                    console.warn(
                        `[i18n] Dictionary error! There is no translation for "${translationLocation}" path!`
                    )
                }
                return translation
            }
            function getDictionary(locale, preventLoop) {
                switch (locale) {
                    case 'pl':
                        return pl
                    case 'en':
                        return en
                    case 'de':
                        return de
                    case 'fr':
                        return fr
                    default:
                        if (!preventLoop) {
                            const fallback = this.fallbackLocale
                            return getDictionary.call(this, fallback, true)
                        }
                        return pl
                }
            }
        },
    },
})
