<template>
    <div v-if="patterns && patterns.length">
        <div class="list-patterns">
            <div
                v-for="pattern in filteredPatterns"
                :key="pattern.id"
                class="list-patterns-element"
                @click="select(pattern)"
            >
                <div
                    v-if="selectedPattern && selectedPattern.id === pattern.id"
                    class="list-patterns-element-selected"
                />
                <ImageLazySimple
                    :src="pattern.image"
                    :height="screenWidth > 900 ? 112 : 164"
                    :alt="pattern.symbol"
                    class="list-patterns-element-image"
                />
                <div
                    v-if="pattern.group && coverType === 'okleina'"
                    class="list-patterns-element-info"
                >
                    <div v-text="groups[pattern.group].title[locale]" />
                    <div v-text="pattern.id" />
                </div>
                <div
                    v-else-if="pattern.group && coverType === 'papier'"
                    class="list-patterns-element-info"
                >
                    <div v-text="pattern.title[locale]" />
                </div>
            </div>
            <div
                v-if="!filteredPatterns.length"
                class="list-patterns-noresults"
            >
                {{ $t('paper.noResults') }}
            </div>
        </div>
    </div>
    <div v-else>
        {{ $t('paper.fallback') }}
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        patterns: {
            type: Array,
            default: () => {},
        },
        groups: {
            type: Object,
            default: () => {},
        },
        colors: {
            type: Object,
            default: () => {},
        },
        coverType: {
            type: String,
            default: 'okleina',
        },
    },
    computed: {
        ...mapState({
            filters: (state) => state.filters,
            selectedPattern: (state) =>
                state.notes.configuration.coverColorPattern,
            screenWidth: (state) => state.page.screenWidth,
            locale: (state) => state.i18n.locale,
        }),
        filteredPatterns() {
            let filtered
            filtered = this.filterByColor(this.patterns)
            filtered = this.filterByCategory(filtered)
            filtered = this.filterBySearch(filtered)
            return filtered
        },
    },
    methods: {
        ...mapActions(['setNotesConfig']),
        select(pattern) {
            if (this.selectedPattern === pattern) {
                this.setNotesConfig({ param: 'coverColorPattern', value: null })
            } else {
                this.setNotesConfig({
                    param: 'coverColorPattern',
                    value: pattern,
                })
            }
            this.$emit('select')
        },
        filterByColor(patterns) {
            let filterColor = this.filters.color ? this.filters.color.id : null

            return patterns.filter((pattern) => {
                if (!filterColor || filterColor === pattern.color) {
                    return true
                }
                return false
            })
        },
        filterByCategory(patterns) {
            let filterCategory = this.filters.category
                ? this.filters.category.id
                : null

            return patterns.filter((pattern) => {
                if (
                    !filterCategory ||
                    pattern.category.indexOf(filterCategory) !== -1
                ) {
                    return true
                }
                return false
            })
        },
        filterBySearch(patterns) {
            let filterSearch = this.filters.search
                ? this.filters.search.toLowerCase()
                : null

            return patterns.filter((pattern) => {
                const fieldsToCheck = [pattern.title[this.locale], pattern.id]
                let isMatch = false

                for (let index in fieldsToCheck) {
                    if (!isMatch && typeof fieldsToCheck[index] === 'string') {
                        isMatch =
                            fieldsToCheck[index]
                                .toLowerCase()
                                .indexOf(filterSearch) !== -1
                    }
                }
                if (!filterSearch || isMatch) {
                    return true
                }
                return false
            })
        },
    },
}
</script>

<style lang="scss">
.list-patterns {
    display: flex;
    flex-flow: row wrap;
    height: 430px;
    overflow-y: auto;
    padding-bottom: 70px;
    width: 100%;

    &-element {
        margin: 8px;
        position: relative;
        height: 112px;
        cursor: pointer;

        &-selected {
            position: absolute;
            left: calc(50% - 10px);
            top: calc(50% - 12px);
            border: solid $color-white;
            border-width: 4px 4px 0 0;
            @include size(25px, 12px);
            z-index: 4;
            transform: rotate(-224deg);
        }

        &-image {
            position: relative;
            z-index: 2;
            img {
                max-width: 112px;
            }
        }

        &-info {
            opacity: 0;
            position: relative;
            z-index: 99;
            background: $color-white;
            width: 100%;
            bottom: 4px;
            text-align: center;
            padding: 10px;
            z-index: 3;
            box-shadow: inherit;
            transition: opacity 0s;
            max-width: 112px;
            pointer-events: none;
        }

        &:hover & {
            &-info {
                opacity: 1;
                transition: opacity 0.2s;
                pointer-events: all;
            }
        }

        &:hover {
            box-shadow: 0 0 10px 2px $color-shadow;
        }
    }

    &-noresults {
        font-size: 2rem;
        margin-top: 30px;
    }

    @media (max-width: $screen-page) {
        height: 300px;
        padding-bottom: 60px;
    }

    @media (max-width: $screen-tablet-medium) {
        height: 100%;
        justify-content: flex-start;
        max-width: 540px;
        margin: 0 auto;

        &-element {
            @include size(112px);

            img {
                width: 100%;
            }

            &-info {
                max-width: 164px;
            }

            &:hover & {
                &-image {
                    z-index: 2;
                }

                &-info {
                    z-index: 3;
                    opacity: 1;
                    transition: opacity 0.2s;
                }
            }
        }
    }

    @media (max-width: $screen-mobile-large) {
        margin: 0 10px 0;
        width: calc(100% - 20px);
        padding-top: 73px;

        &-element {
            width: 112px;
            height: auto;
            margin: 10px;

            &-info {
                position: absolute;
                bottom: unset;
                max-width: 100%;
                top: 100px;
            }
        }
    }
}
</style>
