import { mapActions, mapGetters, mapState } from 'vuex'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { font } from '@/components/fonts/sourceSans'
import { fontLight } from '@/components/fonts/sourceSansLight'
import formatMixin from '@/mixins/NotesFormatMixin'

const saveToPdf = {
    data() {
        return {
            pdf: {
                logo: '',
                fsc: '',
                pageCount: 0,
            },
        }
    },
    mixins: [formatMixin],
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
            configurationApiCode: (state) => state.notes.configurationApiCode,
            configurationCode: (state) => state.notes.configurationCode,
            locale: (state) => state.i18n.locale,
            allPages: (state) => state.page.allPages,
            viewClosed: (state) => state.canvas.viewClosed,
            viewOpen: (state) => state.canvas.viewOpen,
            viewFlyleaf: (state) => state.canvas.viewFlyleaf,
            viewEdge: (state) => state.canvas.viewEdge,
            isFsc: (state) =>
                state.notes.configuration.material?.name === 'fsc',
            currentCurrency: (state) => state.valuation.currentCurrency,
        }),
        ...mapGetters([
            'validValuationConfig',
            'validValuationPrices',
            'isCalcualtionValid',
        ]),
        configurationNo() {
            return String(this.configurationApiCode.calculationId)
        },
        summaryPages() {
            // displays only page sections with selected options
            const arrayOfPages = this.allPages.filter((page) => {
                if (page.toSummary) {
                    // check if options are selected (not null)
                    const pages = page.possibleOptions
                        .map((option) => {
                            if (this.configuration[option.name]) {
                                return option.name
                            }
                        })
                        .filter(Boolean)

                    return pages?.length ? pages : null
                }
            })
            const objectOfPages = {}

            arrayOfPages.map((page) => (objectOfPages[page.id] = page))

            return objectOfPages
        },
    },
    mounted() {
        // extend api with my variables and methods
        jsPDF.API.offsetTop = 20
        jsPDF.API.marginLeft = 30
        jsPDF.API.lineHeight = 42
        jsPDF.API.currentPage = 1

        jsPDF.API.addSpace = function (space) {
            this.offsetTop += space || this.lineHeight / 2
        }
        jsPDF.API.addText = function (text, lineHeight = 42) {
            this.offsetTop += lineHeight
            this.text(text, this.marginLeft, this.offsetTop)
        }

        jsPDF.API.newPage = function () {
            this.addPage()
            this.offsetTop = 20

            this.currentPage++
        }

        jsPDF.API.addFooter = function (text, margin = 0) {
            this.text(text, this.marginLeft + margin, 600)
        }
        jsPDF.API.addTextSameRow = function (text = '', margin = 270) {
            text = String(text)
            const lines = text ? this.splitTextToSize(text, 250) : ''
            this.text(lines, this.marginLeft + margin, this.offsetTop)
            // add space if text has multiple lines
            for (let index in lines) {
                if (index > 0) {
                    this.addSpace()
                }
            }
        }
        jsPDF.API.hr = function (color = [0, 0, 0], lineHeight = 12) {
            const top = this.offsetTop + lineHeight
            this.setDrawColor(...color)
            this.line(this.marginLeft, top, 415, top, 'F')
        }
    },
    methods: {
        ...mapActions(['showLoader', 'hideLoader', 'showLoaderError']),
        configItems(possibleOptions) {
            if (possibleOptions?.length) {
                return possibleOptions.filter((item) => {
                    item.value = this.parseConfiguration(
                        this.configuration[item.name]
                    )
                    return this.configuration[item.name]
                })
            } else {
                return []
            }
        },
        parseConfiguration(configuration) {
            if (configuration && typeof configuration === 'object') {
                if (configuration.title) {
                    return configuration.title[this.locale]
                } else {
                    let title = '',
                        configKeys = Object.keys(configuration)
                    for (let index in configKeys) {
                        title +=
                            configuration[configKeys[index]].title[this.locale]
                        if (index < configKeys.length - 1) {
                            title += ', '
                        }
                    }
                    return title
                }
            } else {
                return configuration
            }
        },
        getImgFromUrl(logo_url, callback) {
            const img = new Image()
            img.src = logo_url
            img.onload = function () {
                callback(img)
            }
        },
        saveToPdf() {
            const logo_url = require('@/assets/images/logo_mindnotes.png')
            const fsc = require('@/assets/images/fsc.png')
            this.getImgFromUrl(logo_url, (logo) => {
                this.getImgFromUrl(fsc, (logoFsc) => {
                    this.generatePDF(logo, logoFsc)
                })
            })
        },
        getTodaysDate() {
            let today = new Date()
            const dd = String(today.getDate()).padStart(2, '0')
            const mm = String(today.getMonth() + 1).padStart(2, '0')
            const yyyy = today.getFullYear()

            today = `${dd}.${mm}.${yyyy}`
            return today
        },
        getCurrentYear() {
            // let today = new Date()
            // const yyyy = today.getFullYear()

            // return yyyy
            // nie wiem czy klient chce aktualny rok czy 2022, w mailu było info że ma być 2022, jak coś to wystarczy odkomentować górę
            return 2022
        },
        printHeader(doc) {
            const lineHeight = 9

            doc.setFontSize(14)
            doc.setFont('SourceSans', 'normal')
            doc.addText(this.$t('pdf.summary'), lineHeight + 4)

            doc.setFontSize(12)

            // FSC

            if (this.isFsc) {
                doc.addImage(
                    this.pdf.fsc,
                    'png',
                    30,
                    39,
                    29,
                    43,
                    undefined,
                    'FAST'
                )

                doc.addSpace(40)
            }

            // Kod konfiguracji z cmsa
            doc.addText(
                `${this.$t('pdf.code')}${this.configurationCode} - `,
                lineHeight + 10
            )

            let linkX = 121
            let linkY = 52

            if (this.locale === 'en') {
                linkX = 103
            } else if (this.locale === 'de') {
                linkX = 133
            } else if (this.locale === 'fr') {
                linkX = 143
            }

            if (this.isFsc) {
                linkY = 92
            }
            doc.textWithLink(this.$t('pdf.link'), linkX, linkY, {
                url: this.$t('link.configurationUrl') + this.configurationCode,
            })

            // Podkreślenie linku
            const linkWidth = doc.getTextWidth(this.$t('pdf.link'))

            doc.line(linkX, linkY + 1, linkX + linkWidth, linkY + 1)

            doc.setFontSize(9)
            doc.setFont('SourceSans', 'light')
            doc.addText(
                `${this.$t('pdf.data')} ${this.getTodaysDate()}`,
                lineHeight + 3
            )
            doc.setFont('SourceSans', 'normal')

            // Numer kalkulacji (z api mindnotes)
            doc.setFont('SourceSans', 'light')
            doc.setTextColor('#9E9E9E')

            doc.addText(
                `${this.$t('pdf.configurationNo')} ${this.configurationNo}`,
                lineHeight
            )

            // reset font color and weight
            doc.setFont('SourceSans', 'normal')
            doc.setTextColor('#000000')

            doc.addImage(
                this.pdf.logo,
                'png',
                302,
                28,
                114,
                19,
                undefined,
                'FAST'
            )
            if (this.isFsc) {
                doc.addSpace(5)
            } else {
                doc.addSpace()
            }
        },
        printFooter(doc) {
            doc.setFontSize(9)
            doc.setFont('SourceSans', 'light')

            doc.addFooter(this.$t('pdf.page'))
            doc.setFont('SourceSans', 'normal')

            doc.addFooter(String(doc.currentPage), 20)
            doc.setFont('SourceSans', 'light')

            doc.addFooter(`/${this.pdf.pageCount}`, 23)

            let leftOffset = 205
            if (this.locale === 'en') {
                leftOffset = 235
            } else if (this.locale === 'fr' || this.locale === 'de') {
                leftOffset = 230
            } else {
                leftOffset = 205
            }

            doc.addFooter(
                `© ${this.getCurrentYear()}, ${this.$t('pdf.footer')} `,
                leftOffset
            )
        },
        addNewPage(doc, cb) {
            doc.newPage()

            if (cb) {
                cb()
            }
            this.printHeader(doc)
            this.printFooter(doc)
        },

        printSection(doc, page) {
            const currentPageConfig = this.configItems(page.possibleOptions)
            const title = page.summaryTitle
                ? page.summaryTitle[this.locale]
                : page.title[this.locale]
            doc.setFontSize(16)

            doc.setTextColor('#000000')
            doc.addText(title, 21)
            doc.hr([0, 0, 0], 7)

            for (let index in currentPageConfig) {
                doc.setFontSize(10)
                doc.setTextColor('#333333')
                if (index > 0) {
                    doc.addSpace(7)
                }
                doc.addText(currentPageConfig[index].title[this.locale], 20)
                doc.addTextSameRow(currentPageConfig[index].value, 145)

                if (index < currentPageConfig.length - 1) {
                    doc.hr([0, 0, 0], 12)
                }
            }

            if (this.isFsc) {
                doc.addSpace(1)
            } else {
                doc.addSpace(14)
            }
        },
        generatePDF(logo, fsc) {
            this.pdf.logo = logo
            this.pdf.fsc = fsc
            window.html2canvas = html2canvas
            const doc = new jsPDF('p', 'px', 'a4', true)
            // set Font
            doc.addFileToVFS('sourcesans1.ttf', font)
            doc.addFont('sourcesans1.ttf', 'SourceSans', 'normal', 'Identity-H')
            doc.addFileToVFS('sourcesans2.ttf', fontLight)
            doc.addFont('sourcesans2.ttf', 'SourceSans', 'light')
            doc.setFont('SourceSans', 'normal')

            if (this.summaryPages.flyleaf) {
                this.pdf.pageCount = 5
            } else {
                this.pdf.pageCount = 4
            }

            // FIRST PAGE (cover)

            // ~~~~ print options ~~~~
            let [
                viewClosedX,
                viewClosedY,
                viewClosedWidth,
                viewClosedHeight,
            ] = [150, 386, 250, 218]
            if (this.formatGroup === 'square') {
                viewClosedWidth = 282
            }

            if (this.formatGroup === 'horizontal') {
                viewClosedWidth = 340
                viewClosedHeight = 268
                viewClosedX = 137
            }

            if (this.viewClosed.image) {
                doc.addImage(
                    this.viewClosed.image,
                    'png',
                    viewClosedX,
                    viewClosedY,
                    viewClosedWidth,
                    viewClosedHeight,
                    undefined,
                    'FAST'
                )
            }
            this.printHeader(doc)
            this.printFooter(doc)

            doc.setFontSize(14)

            if (this.summaryPages.cover) {
                this.printSection(doc, this.summaryPages.cover)
            }
            if (this.summaryPages.format) {
                this.printSection(doc, this.summaryPages.format)
            }
            if (this.summaryPages.paper) {
                this.printSection(doc, this.summaryPages.paper)
            }
            if (this.summaryPages.coverMarking) {
                this.printSection(doc, this.summaryPages.coverMarking)
            }

            // SECOND PAGE (inside)
            let [viewOpenX, viewOpenY, viewOpenWidth, viewOpenHeight] = [
                93,
                280,
                300,
                225,
            ]

            if (this.formatGroup === 'square') {
                viewOpenX = 123
                viewOpenY = 290
            }

            this.addNewPage(doc, () => {
                if (this.viewOpen.image) {
                    doc.addImage(
                        this.viewOpen.image,
                        'png',
                        viewOpenX,
                        viewOpenY + 80,
                        viewOpenWidth,
                        viewOpenHeight,
                        undefined,
                        'FAST'
                    )
                }
            })

            if (this.summaryPages.inside) {
                this.printSection(doc, this.summaryPages.inside)
            }
            if (this.summaryPages.personalized) {
                this.printSection(doc, this.summaryPages.personalized)
            }

            // THIRD PAGE (flyleaf - optional page)
            if (this.summaryPages.flyleaf) {
                this.addNewPage(doc, () => {
                    if (this.viewFlyleaf.image) {
                        doc.addImage(
                            this.viewFlyleaf.image,
                            'png',
                            viewOpenX,
                            viewOpenY,
                            viewOpenWidth,
                            viewOpenHeight,
                            undefined,
                            'FAST'
                        )
                    }
                })

                this.printSection(doc, this.summaryPages.flyleaf)
            }

            // FOURTH PAGE (accesories/edge/packaging)
            let [viewEdgeX, viewEdgeY, viewEdgeWidth, viewEdgeHeight] = [
                280,
                347,
                400,
                248,
            ]

            if (this.formatGroup === 'square') {
                viewEdgeHeight = 297
                viewEdgeY = 343
            }

            if (this.formatGroup === 'horizontal') {
                viewEdgeHeight = 262
                viewEdgeY = 444
            }

            this.addNewPage(doc, () => {
                if (this.viewClosed.image) {
                    doc.addImage(
                        this.viewClosed.image,
                        'png',
                        viewClosedX - 45,
                        viewClosedY - 30,
                        viewClosedWidth,
                        viewClosedHeight,
                        undefined,
                        'FAST'
                    )
                }

                if (this.viewEdge.image) {
                    doc.addImage(
                        this.viewEdge.image,
                        'png',
                        viewEdgeX,
                        viewEdgeY,
                        viewEdgeWidth,
                        viewEdgeHeight,
                        undefined,
                        'FAST'
                    )
                }
            })

            if (this.summaryPages.accessories) {
                this.printSection(doc, this.summaryPages.accessories)
            }

            if (this.summaryPages.packagePage) {
                this.printSection(doc, this.summaryPages.packagePage)
            }

            if (this.summaryPages.edgeColoration) {
                this.printSection(doc, this.summaryPages.edgeColoration)
            }

            // FIFTH PAGE (summary)

            this.addNewPage(doc)
            doc.setFont('SourceSans', 'normal')

            if (this.isCalcualtionValid) {
                doc.setFontSize(16)

                doc.setTextColor('#000000')
                doc.addText(this.$t('offer.title'), 21)
                doc.hr([0, 0, 0], 7)
                doc.setFontSize(10)
                doc.setTextColor('#000000')
                doc.addText(`${this.$t('offer.prints')}`, 20)
                doc.addTextSameRow(this.$t('offer.price1'), 145)
                doc.addTextSameRow(this.$t('offer.transportCost'), 290)
                const properPrice = (price) => {
                    if (isNaN(price) || price < 0) {
                        return '-'
                    }
                    let priceString = ''
                    if (this.currentCurrency.isOnLeftSide) {
                        priceString += this.currentCurrency.symbol
                    }
                    priceString += price
                    if (!this.currentCurrency.isOnLeftSide) {
                        priceString += this.currentCurrency.symbol
                    }
                    return priceString
                }
                doc.setTextColor('#333333')
                this.validValuationPrices
                    .sort((a, b) => (a.quantity > b.quantity ? 1 : -1))
                    .forEach((volume) => {
                        doc.hr([200, 200, 200], 7)
                        doc.addText(
                            `${volume.quantity} ${this.$t('offer.szt')}`,
                            20
                        )
                        doc.addTextSameRow(properPrice(volume.price), 145)
                        doc.addTextSameRow(
                            properPrice(volume.shippingPrice),
                            290
                        )
                    })

                doc.addSpace(7)
            }

            doc.setFontSize(15)
            doc.setTextColor('#000000')

            doc.addSpace(41)
            doc.addText(this.$t('pdf.file1'), 13)
            doc.addText(this.$t('pdf.file2'), 13)
            doc.setFontSize(10)

            doc.addSpace(15)

            if (this.isCalcualtionValid && this.locale === 'pl') {
                doc.addText(this.$t('pdf.pdfDisclaimer1'), 8)
                doc.setFont('SourceSans', 'light')
                doc.setFontSize(10)
                doc.addText(this.$t('pdf.pdfDisclaimer2'), 18)
            } else {
                doc.addText(this.$t('pdf.file3'), 8)
                doc.addText(this.$t('pdf.file4'), 8)
                // const textWidth = doc.getTextWidth(this.$t('pdf.file5'))
                // let x = 349
                // let y = 193
                //
                // if (this.isFsc) {
                //     y = 193 + 24
                // }
                //
                // if (this.locale === 'fr') {
                //     x = 333
                // } else if (this.locale === 'en') {
                //     x = 268
                // } else if (this.locale === 'de') {
                //     y = 201
                //     if (this.isFsc) {
                //         y = 201 + 24
                //     }
                //     x = 30
                // } else {
                //     x = 30
                //     y = 201
                // }

                // doc.line(x, y, x + textWidth, y)

                doc.addText(this.$t('pdf.file6'), 8)
                doc.setFont('SourceSans', 'light')
                doc.setFontSize(10)
                if (this.locale === 'de') {
                    doc.addText(this.$t('pdf.file7'), 18)
                    doc.addText(this.$t('pdf.file71'), 8)
                } else {
                    doc.addText(this.$t('pdf.file7'), 18)
                }
                doc.addText(this.$t('pdf.file72'), 18)
                doc.addText(this.$t('pdf.file73'), 8)
                doc.addText(this.$t('pdf.file8'), 18)
                doc.addText(this.$t('pdf.file9'), 8)
                doc.addText(this.$t('pdf.file10'), 8)
                if (this.locale !== 'pl') {
                    doc.addText(this.$t('pdf.file11'), 8)
                }
            }

            doc.save(
                `configuration_Mindnotes_number:${this.configurationNo}.pdf`
            )
        },
        async downloadPDF() {
            this.showLoader({
                message: 'loader.info.download',
                loading: true,
            })

            setTimeout(() => {
                try {
                    this.notesToCanvas(() => {
                        try {
                            this.saveToPdf()

                            setTimeout(() => {
                                this.hideLoader()
                            }, 500)
                        } catch (e) {
                            console.error(e)
                            this.showLoaderError('loader.error.pdf')
                        }
                    })
                } catch (e) {
                    console.error(e)
                    this.showLoaderError('loader.error.pdf')
                }
            }, 1000) // artifical delay for smooth animation
        },
    },
}

export default saveToPdf
