<template>
    <!-- eslint-disable -->
    <g>
        <defs>
            <linearGradient
                id="edge-streamer-vertical-gradient"
                x1="1.941"
                y1="0.5"
                x2="-0.321"
                y2="0.5"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#bfbfbf" />
                <stop offset="0.243" stop-color="#d2d2d2" />
                <stop offset="0.637" stop-color="#f5f5f5" />
                <stop offset="0.905" stop-color="#d1d1d1" />
                <stop offset="1" stop-color="#bebdbd" />
                <stop offset="1" stop-color="#bdbdbd" />
            </linearGradient>
<!--
            <pattern
                id="edge-vertical-pattern"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="base64[images.edgePattern]"
                />
            </pattern>-->
        </defs>
        <g v-if="type === 'Banderola wąska'">
            <clipPath id="edge-streamer-clip-path">
                <rect
                    id="Rectangle_1676"
                    data-name="Rectangle 1676"
                    :width="options[format].gluedOne.rectWidth"
                    :height="options[format].gluedOne.rectHeight"
                    transform="translate(57 10)"
                    fill="url(#edge-streamer-vertical-gradient)"
                />
            </clipPath>
            <!-- shadow -->
            <image
                :href="base64[images.shadow]"
                :transform="options[format].shadowTransform"
            />
            <g
                v-if="print === '1 + 0'"
                id="Group_1468"
                data-name="Group 1468"
                :transform="options[format].gluedOne.transform"
            >
                <rect
                    id="Rectangle_1672"
                    data-name="Rectangle 1672"
                    :width="options[format].gluedOne.rectWidth"
                    :height="options[format].gluedOne.rectHeight"
                    transform="translate(58 13)"
                    fill="url(#edge-streamer-vertical-gradient)"
                />
            </g>
            <g
                v-if="print === 'CMYK + 0'"
                id="Group_1468"
                data-name="Group 1468"
                :transform="options[format].gluedCmyk.transform"
                clip-path="url(#edge-streamer-clip-path)"
            >
<!--                <rect
                    id="Rectangle_1672"
                    data-name="Rectangle 1672"
                    width="488.255"
                    height="517"
                    transform="translate(58 13)"
                    fill="url(#edge-vertical-pattern)"
                />-->
                <image
                    width="80"
                    height="814"
                    transform="translate(58 13)"
                    :xlink:href="base64[images.edgePattern]"
                />
                <image
                    :href="base64[images.shadowSmall]"
                    transform="translate(58 13)"
                    preserveAspectRatio="none"
                    :width="options[format].gluedOne.rectWidth"
                    :height="options[format].gluedOne.rectHeight"
                />
            </g>
        </g>
        <g v-if="type === 'Obwoluta wąska'">
            <!-- 1 + 0 -->
            <g
                v-if="print === '1 + 0'"
                id="Group_1478"
                data-name="Group 1478"
                :transform="options[format].rolledOne.transform"
            >
                <rect
                    id="Rectangle_1672"
                    data-name="Rectangle 1672"
                    :width="options[format].rolledOne.width"
                    :height="options[format].rolledOne.height"
                    transform="translate(9 0)"
                    fill="url(#edge-streamer-vertical-gradient)"
                />
                <rect
                    id="Rectangle_1672-2"
                    data-name="Rectangle 1672"
                    :width="options[format].rolledOne.width"
                    :height="options[format].rolledOne.height"
                    :transform="options[format].rolledOne.rightTransform"
                    fill="url(#edge-streamer-vertical-gradient)"
                />
            </g>
            <g v-if="print === 'CMYK + 0'">
                <defs>
                    <clipPath id="clip-path">
                        <rect
                            id="Rectangle_1679"
                            data-name="Rectangle 1679"
                            :width="options[format].rolledOne.width"
                            :height="options[format].rolledOne.height"
                            transform="translate(0 0)"
                            fill="url(#edge-streamer-vertical-gradient)"
                        />
                    </clipPath>
                </defs>
                <g :transform="options[format].rolledCmyk.transform">
                    <g
                        id="Group_2016"
                        data-name="Group 2016"
                        transform="translate(0 0)"
                    >
                        <g
                            id="Mask_Group_46"
                            data-name="Mask Group 46"
                            clip-path="url(#clip-path)"
                            style="isolation: isolate;"
                        >
<!--                            <rect
                                id="Untitled-3-2"
                                data-name="Untitled-3"
                                width="488.255"
                                height="517"
                                transform="translate(186.5 -64.979) rotate(90)"
                                fill="url(#edge-vertical-pattern)"
                            />-->
                            <image
                                width="80"
                                height="814"
                                :xlink:href="base64[images.edgePattern]"
                            />
                        </g>
                        <rect
                            id="Rectangle_1680"
                            data-name="Rectangle 1680"
                            :width="options[format].rolledOne.width"
                            :height="options[format].rolledOne.height"
                            transform="translate(0 0)"
                            opacity="0.6"
                            fill="url(#edge-streamer-vertical-gradient)"
                            style="
                                mix-blend-mode: multiply;
                                isolation: isolate;
                            "
                        />
                    </g>
                    <g
                        id="Group_2017"
                        data-name="Group 2017"
                        :transform="options[format].rolledCmyk.transformRight"
                    >
                        <g
                            id="Mask_Group_47"
                            data-name="Mask Group 47"
                            clip-path="url(#clip-path)"
                            style="isolation: isolate;"
                        >
<!--                            <rect
                                id="Untitled-3"
                                width="488.255"
                                height="517"
                                transform="translate(135.5 -64.979) rotate(90)"
                                fill="url(#edge-vertical-pattern)"
                            />-->
                            <image
                                width="80"
                                height="814"
                                transform="translate(-10 0)"
                                :xlink:href="base64[images.edgePattern]"
                            />
                        </g>
                        <rect
                            id="Rectangle_1681"
                            data-name="Rectangle 1681"
                            :width="options[format].rolledOne.width"
                            :height="options[format].rolledOne.height"
                            transform="translate(0 0)"
                            opacity="0.6"
                            fill="url(#edge-streamer-vertical-gradient)"
                            style="
                                mix-blend-mode: multiply;
                                isolation: isolate;
                            "
                        />
                    </g>
                </g>
            </g>
        </g>
    </g>
</template>

<script>
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        type: {
            type: String,
            default: '',
        },
        print: {
            type: String,
            default: '',
        },
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            options: {
                vertical: {
                    shadowTransform: 'translate(2 208)',
                    gluedOne: {
                        rectWidth: 61,
                        rectHeight: 131,
                        transform: 'translate(-55 210)',
                    },
                    gluedCmyk: {
                        transform: 'translate(-55 210)',
                    },
                    rolledOne: {
                        transform: 'translate(-6 230)',
                        height: 131,
                        width: 10,
                        rightTransform: 'translate(59 0)',
                    },
                    rolledCmyk: {
                        transform: 'translate(3 230)',
                        transformRight: ' translate(50 0)',
                    },
                },
                horizontal: {
                    shadowTransform: 'translate(2 71) scale(0.8)',
                    gluedOne: {
                        rectWidth: 43,
                        rectHeight: 101,
                        transform: 'translate(-53 70)',
                    },
                    gluedCmyk: {
                        transform: 'translate(-53 73)',
                    },
                    rolledOne: {
                        transform: 'translate(-3 80)',
                        height: 111,
                        width: 7,
                        rightTransform: 'translate(43 0)',
                    },
                    rolledCmyk: {
                        transform: 'translate(6 80)',
                        transformRight: ' translate(34 0)',
                    },
                },
                square: {
                    shadowTransform: 'translate(2 87) scale(0.9)',
                    gluedOne: {
                        rectWidth: 50,
                        rectHeight: 121,
                        transform: 'translate(-53 85)',
                    },
                    gluedCmyk: {
                        transform: 'translate(-53 85)',
                    },
                    rolledOne: {
                        transform: 'translate(-3 100)',
                        height: 121,
                        width: 7,
                        rightTransform: 'translate(49 0)',
                    },
                    rolledCmyk: {
                        transform: 'translate(6 100)',
                        transformRight: ' translate(40 0)',
                    },
                },
            },
            images: {
                edgePattern: '/img/notes/edge/edge-pattern-vertical.png',
                shadow: '/img/notes/edge/shadow.png',
                shadowSmall: '/img/notes/edge/shadow-small.png',
            },
        }
    },
    created() {
        Object.keys(this.images).forEach((option) => {
            this.toDataUrl(this.images[option])
        })
    },
}
</script>
