<template>
    <nav
        class="navigation-tablet"
        :class="{ 'navigation-tablet--active': isMenuOpen }"
    >
        <ul class="navigation-tablet-list">
            <li
                v-for="(element, index) in navigation"
                :key="index"
                class="navigation-tablet-element"
                :class="{
                    'navigation-tablet-element--current':
                        currentPage.id === element.id,
                    'navigation-tablet-element--disabled': element.disabled,
                }"
                @click="redirect(element)"
            >
                <div class="navigation-tablet-element-icon">
                    <component
                        :is="require(`@/assets/${element.icon}`).default"
                    />
                </div>
                <span class="navigation-tablet-element-title">
                    {{ element.title[locale] }}
                </span>
            </li>
        </ul>
        <div class="navigation-tablet-close" @click="closeMenu">
            &times;
        </div>
    </nav>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState({
            navigation: (state) =>
                state.page.allPages.filter((el) => !el.isHiddenInMenu),
            isMenuOpen: (state) => state.page.isMobileMenuActive,
            currentPage: (state) => state.page.currentPage,
            locale: (state) => state.i18n.locale,
        }),
    },
    methods: {
        ...mapMutations([
            'openModal',
            'setModalComponentId',
            'setCurrentSubOption',
            'showMobileMenu',
        ]),
        ...mapActions(['changePage']),
        redirect(element) {
            if (!element.disabled) {
                this.openModal(false)
                this.setModalComponentId('')
                this.setCurrentSubOption(null)
                this.showMobileMenu(false)
                this.changePage(element.id)
            }
        },
        closeMenu() {
            this.showMobileMenu(false)
        },
    },
}
</script>

<style lang="scss">
.navigation-tablet {
    height: calc(100vh - 80px);
    width: 60%;
    background: $color-bg-dark;
    position: absolute;
    top: 80px;
    left: 0;
    transition: 0.2s;
    transform: translateX(-101%);
    z-index: 20;
    overflow-y: auto;

    &--active {
        transform: translateX(0);
    }

    &-list {
        @include size(100%);
        background-color: $color-bg-dark;
    }

    &-close {
        display: none;
    }

    &-element {
        height: 60px;
        display: flex;
        align-items: center;
        padding-left: 60px;
        color: $color-burnt-sienna;

        &-icon {
            min-width: 35px;
            display: block;

            svg {
                fill: $color-burnt-sienna;
            }
        }

        &-title {
            padding-left: 16px;
            font-size: 1.8rem;
        }

        &--current {
            background: $color-burnt-sienna;
            color: $color-white;
        }

        &--current & {
            &-icon {
                svg {
                    fill: $color-white;
                }
            }
        }
        &--disabled {
            color: $color-dove-gray;
        }
        &--disabled & {
            &-icon {
                svg {
                    fill: $color-dove-gray;
                }
            }
        }
    }

    @media (max-width: $screen-mobile-landscape) {
        &-element {
            padding-left: 30px;
        }
    }

    @media (max-width: $screen-mobile-large) {
        top: 0;
        width: 90%;

        display: flex;
        flex-flow: row nowrap;
        background-color: transparent;
        height: calc(100vh - 50px);

        &-close {
            background: $color-mojo;
            @include size(50px);
            flex-shrink: 0;
            color: $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4rem;
            line-height: 0;
        }

        &-element {
            padding-left: 20px;
            background: $color-bg-dark;
            border-bottom: 1px solid $color-tundra;
            height: 50px;

            &:last-child,
            &--current {
                border-bottom: transparent;
            }

            &--current {
                background: $color-burnt-sienna;
                color: $color-white;
            }

            &--current & {
                &-icon {
                    svg {
                        fill: $color-white;
                    }
                }
            }
        }
    }
}
</style>
