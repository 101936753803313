<template>
    <div v-click-outside="closeModal" :class="['modal', { show: show }]">
        <header>
            <div
                v-if="currentOption && currentOption.title"
                class="modal-mobile-title"
                v-text="currentOption.title[locale]"
            />
            <div class="modal-close" @click="closeModal()">
                <span>&#10005;</span>
            </div>
        </header>
        <div class="modal-container">
            <div class="modal-container-content">
                <component :is="modals[modalComponentName]" />
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapState, mapMutations } from 'vuex'
import modal from '@/components/modal'

export default {
    directives: {
        ClickOutside,
    },
    components: {
        ...modal.components,
    },
    props: {
        modalComponentName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            modals: modal.data,
            show: false,
        }
    },
    computed: {
        ...mapState({
            currentOption: (state) => state.notes.currentOption,
            locale: (state) => state.i18n.locale,
        }),
    },
    mounted() {
        setTimeout(this.showModal, 100)
    },
    methods: {
        ...mapMutations(['openModal', 'setModalComponentId']),
        closeModal() {
            if (this.show) {
                this.show = false
                setTimeout(() => {
                    this.openModal(false)
                    this.setModalComponentId('')
                }, 300)
            }
        },
        showModal() {
            this.show = true
        },
    },
}
</script>

<style lang="scss">
.modal {
    opacity: 0;
    position: absolute;
    top: calc(50% - 300px);
    right: 7%;
    background: $color-white;
    width: 47%;
    max-width: 100%;
    box-shadow: $color-gray 0 0 70px;
    padding: 50px 55px;
    z-index: 20;
    transition: opacity 0.3s;

    &-mobile-title {
        display: none;
    }

    &-close {
        background: $color-burnt-sienna;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        @include size(48px);

        span {
            font-size: 23px;
            color: $color-white;
            display: block;
            text-align: center;
            position: relative;
            top: 11px;
        }
    }

    &.show {
        opacity: 1;
    }

    .flex {
        margin-bottom: 35px;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-left {
        width: 43%;
    }

    &-right {
        width: 57%;
        padding-left: 40px;
    }

    &-title {
        font-size: 31px;
        font-weight: 700;
        margin-bottom: 12px;
        color: $color-default-text;
    }

    &-text {
        font-weight: 300;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 20px;
    }

    &-inputs {
        .input-checkbox {
            border: none;
        }
    }
    @media (max-width: $screen-tablet-large) {
        padding: 50px 15px;

        &-right {
            padding-left: 15px;
        }
    }
    @media (max-width: $screen-tablet-medium) {
        width: 80%;
        left: 10%;
        right: unset;
        position: fixed;
    }
    @media (max-width: $screen-mobile-large) {
        position: absolute;
        top: -38px;
        left: 20px;
        width: calc(100% - 40px);
        padding: 0;
        margin-bottom: 40px;

        &-container {
            padding: 20px;
        }

        &-mobile-title {
            @include size(100%);
            display: block;
            font-size: 1.6rem;
            background-color: $color-bg-light;
            height: 48px;
            padding-left: 24px;
            display: flex;
            align-items: center;
        }

        &-close {
            background: transparent;

            span {
                color: $color-default-text;
            }
        }

        &-right {
            width: 100%;
        }

        &-left {
            width: 100%;
        }

        .flex {
            flex-direction: column;
        }
    }
}
</style>
