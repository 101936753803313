<template>
    <g id="marking">
        <!-- druk -->
        <Print
            :is-round="
                configuration.coverCorners &&
                configuration.coverCorners.title.pl === `Zaokrąglone`
            "
            :option="markingPrint"
            :format="format"
            :is-large="isLarge"
        />
        <!-- tłoczenie -->
        <Expression
            id="marking-expression"
            :option="configuration.markingExpression"
            :format="format"
            :is-large="isLarge"
        />
        <!-- wykończenie -->
        <CoverFinish
            id="marking-cover-finish"
            :option="
                configuration.markingCoverFinish &&
                configuration.markingCoverFinish.title.pl
            "
            :format="format"
        />
    </g>
</template>

<script>
import Print from './Print'
import Expression from './Expression'
import CoverFinish from './CoverFinish'

import { mapState } from 'vuex'

export default {
    components: {
        Print,
        Expression,
        CoverFinish,
    },
    props: {
        format: {
            type: String,
            default: 'vertical',
        },
    },
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
        }),
        isLarge() {
            // check is expression is selected as large
            if (this.configuration.markingExpression?.general) {
                return (
                    this.configuration.markingExpression.general.title.pl.indexOf(
                        'duże'
                    ) > -1
                )
            }
            if (this.configuration.markingExpression?.title) {
                return (
                    this.configuration.markingExpression.title.pl.indexOf(
                        'duże'
                    ) > -1
                )
            }
            return false
        },
        markingPrint() {
            return (
                (this.configuration.markingPrint &&
                    this.configuration.markingPrint.singleMarkingPrint &&
                    this.configuration.markingPrint.singleMarkingPrint.title
                        .pl) ||
                (this.configuration.markingPrint &&
                    this.configuration.markingPrint.doubleMarkingPrint &&
                    this.configuration.markingPrint.doubleMarkingPrint.title.pl)
            )
        },
    },
}
</script>
