const state = {
    isOpen: false,
    componentId: '',
}

const mutations = {
    openModal(state, value) {
        state.isOpen = value
    },
    setModalComponentId(state, value) {
        state.componentId = value
    },
}

export default {
    state,
    mutations,
}
