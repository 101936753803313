var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{staticClass:"notes-cover-image",class:[
        `notes-cover-image--${_vm.formatGroup} notes-cover-image--${_vm.formatSpecific}`,
        _vm.currentPage.id === 'format'
            ? `notes-cover-image--zoom-${_vm.formatSpecific}`
            : '',
    ],attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":_vm.svg[_vm.formatGroup].width,"height":_vm.svg[_vm.formatGroup].height,"viewBox":_vm.customViewBox || _vm.svg[_vm.formatGroup].viewBox}},[_c('defs',[_c('NotesCoverFrontDefs'),_c('PaperPattern',{attrs:{"background":_vm.background}})],1),_c('g',{attrs:{"id":"Notes","transform":_vm.svg[_vm.formatGroup].notesTransform}},[(
                _vm.currentPage &&
                _vm.currentPage.id !== 'paper' &&
                _vm.formatGroup === 'vertical'
            )?_c('image',{staticClass:"notes-shadow-image",attrs:{"id":"shadow","width":_vm.svg[_vm.formatGroup].width,"height":_vm.svg[_vm.formatGroup].height,"transform":"translate(568 140)","xlink:href":"/img/notes/Notes-image.png"}}):_vm._e(),_c('g',{staticClass:"notes-cover-zoom",attrs:{"id":"notes-2","data-name":"notes"}},[_c('g',{staticClass:"notes-shadow",attrs:{"transform":_vm.svg[_vm.formatGroup].shadowTransform}},[_c('path',{attrs:{"id":"shadow_vector-2","data-name":"shadow_vector","d":_vm.svg[_vm.formatGroup].shadowPathD,"transform":_vm.svg[_vm.formatGroup].shadowPathTransform}})]),_c('Corners',{attrs:{"is-round":_vm.configuration.coverCorners &&
                    _vm.configuration.coverCorners.title.pl === `Zaokrąglone`,"background":_vm.background,"default-background":_vm.defaultBackground,"format":_vm.formatGroup}}),_c('Marking',{attrs:{"format":_vm.formatGroup}}),_c('Ridge',{attrs:{"soft-cover":_vm.configuration.coverType &&
                    _vm.configuration.coverType.title.pl === 'Miękka papierowa',"format":_vm.formatGroup,"pattern-theme":_vm.patternTheme || 'dark'}}),_c('Accessories',{attrs:{"format":_vm.formatGroup,"last-selected-accessory":_vm.lastSelectedAccessory}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }