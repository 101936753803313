export default {
    services: [
        {
            name: 'offer',
            endpoints: {
                index: {
                    path: '/:locale/api/offer/',
                    methods: ['get'],
                },
            },
        },
        {
            name: 'configuration',
            endpoints: {
                // get config when id provided
                index: {
                    path: '/api/notebook_configuration/:token',
                    methods: ['get'],
                    forceEnv: 'api',
                },
                // send config and get version
                send: {
                    path: '/api/notebook_configuration',
                    methods: ['post'],
                    forceEnv: 'api',
                },
                // update with configurationApiCode
                update: {
                    path: '/api/notebook_configuration/:token',
                    methods: ['put'],
                    forceEnv: 'api',
                },
            },
        },
        {
            name: 'dependencies',
            endpoints: {
                standard: {
                    path: '/promonotes.api/WEB.MN',
                    methods: ['get'],
                    forceEnv: 'api',
                },
                fsc: {
                    path: '/promonotes.api/WEB.MN.FSC',
                    methods: ['get'],
                    forceEnv: 'api',
                },
            },
        },
        {
            name: 'quote',
            endpoints: {
                send: {
                    path: '/promonotes.quote',
                    methods: ['put'],
                    forceEnv: 'api',
                },
            },
        },
        {
            name: 'valuation',
            endpoints: {
                index: {
                    path: '/api/valuation',
                    methods: ['get'],
                },
            },
        },
        {
            name: 'lead',
            endpoints: {
                base: {
                    path: '/promonotes.lead/basic',
                    methods: ['put'],
                    forceEnv: 'api',
                },
                extended: {
                    path: '/promonotes.lead/extended',
                    methods: ['put'],
                    forceEnv: 'api',
                },
            },
        },
    ],
}
