<template>
    <g>
        <image
            v-if="druk === '1 + 1'"
            :href="base64[image['1']]"
            transform="translate(12 0)"
        />
        <image
            v-if="druk === '2 + 2'"
            :href="base64[image['2']]"
            transform="translate(12 0)"
        />
        <image
            v-if="druk === 'CMYK + CMYK'"
            :href="base64[image['cmyk']]"
            transform="translate(12 0)"
        />
    </g>
</template>

<script>
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        druk: {
            type: [String, Boolean],
            default: '',
        },
    },
    data() {
        return {
            image: {
                '1': '/img/notes/inside/individual-horizontal_1.png',
                '2': '/img/notes/inside/individual-horizontal_2.png',
                cmyk: '/img/notes/inside/individual-horizontal_cmyk.png',
            },
        }
    },

    created() {
        Object.keys(this.image).forEach((name) => {
            this.toDataUrl(this.image[name])
        })
    },
}
</script>
