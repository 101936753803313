<template>
    <!-- eslint-disable -->
    <g v-if="print">
        <defs>
            <linearGradient
                id="edge-streamer-vertical-gradient"
                x1="1.941"
                y1="0.5"
                x2="-0.321"
                y2="0.5"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#bfbfbf" />
                <stop offset="0.243" stop-color="#d2d2d2" />
                <stop offset="0.637" stop-color="#f5f5f5" />
                <stop offset="0.905" stop-color="#d1d1d1" />
                <stop offset="1" stop-color="#bebdbd" />
                <stop offset="1" stop-color="#bdbdbd" />
            </linearGradient>

            <pattern
                id="edge-vertical-pattern"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="base64[edgePattern]"
                />
            </pattern>
        </defs>
        <Streamer :print="print" :type="type" :format="formatGroup" />
        <Wrapper :print="print" :type="type" :format="formatGroup" />
    </g>
</template>

<script>
import { mapState } from 'vuex'
import Streamer from '@/components/notes/edge/edgeStreamer/Streamer'
import Wrapper from '@/components/notes/edge/edgeStreamer/Wrapper'
import NotesFormatMixin from '@/mixins/NotesFormatMixin'
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    components: {
        Streamer,
        Wrapper,
    },
    mixins: [NotesFormatMixin, ConvertToBase64],
    data() {
        return {
            edgePattern: '/img/notes/edge-pattern.jpg',
        }
    },
    computed: {
        ...mapState({
            type: (state) => {
                const option = state.notes.configuration.streamer
                if (option && option.title) {
                    return option.title.pl
                }
                if (option && option.general) {
                    return option.general.title.pl
                }
                return null
            },
            print: (state) => {
                const option = state.notes.configuration.streamer
                if (option && option.wrapperPrint) {
                    return option.wrapperPrint.title.pl
                }
                return null
            },
        }),
    },
    created() {
        this.toDataUrl(this.edgePattern)
    },
}
</script>
