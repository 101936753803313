<template>
    <div class="base-page">
        <OptionList :title="pageTitle[locale]">
            <template v-slot:list>
                <Option
                    v-for="option in filteredOptions(options)"
                    :id="option.id"
                    :key="option.id"
                    :title="option.title[locale]"
                    :option="option"
                    :is-modal="option.isModal ? option.isModal : false"
                    :selected="configuration[option.id] != null"
                    @edit="selectParameter"
                >
                    <template v-slot:content>
                        <InputDefault
                            v-for="input in option.inputs"
                            :id="input.id"
                            :key="input.id"
                            :option="option"
                            :type="input.type"
                            :placeholder="input.placeholder[locale]"
                            :children-groups="
                                input.childrenGroups
                                    ? input.childrenGroups
                                    : null
                            "
                            :stored-value="storedValue(option.id)"
                            @input="selectParameter"
                        />
                        <InputCheckbox
                            v-for="choice in option.choices"
                            :key="choice.id"
                            :option="option"
                            :choice="choice"
                            :title="choice.title[locale]"
                            :children-groups="
                                choice.childrenGroups
                                    ? choice.childrenGroups
                                    : null
                            "
                            :checked="
                                isChecked(option.id, choice.title[locale])
                            "
                            @input="selectParameter"
                        />
                    </template>
                </Option>
            </template>
        </OptionList>
    </div>
</template>

<script>
import InputCheckbox from '../components/input/InputCheckbox'
import InputDefault from '../components/input/InputDefault'
import Option from '../components/option/Option'
import OptionList from '../components/option/OptionList'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    components: {
        InputCheckbox,
        InputDefault,
        Option,
        OptionList,
    },
    data() {
        return {
            pageTitle: '',
        }
    },
    computed: mapState({
        configuration: (state) => state.notes.configuration,
        locale: (state) => state.i18n.locale,
    }),
    mounted() {
        this.setNextPageLocked(!this.validate())
    },
    methods: {
        ...mapActions(['setNotesConfig']),
        ...mapMutations(['setCurrentOption', 'setNextPageLocked']),
        isChecked(optionId, choiceTitle) {
            const currentOption = this.configuration[optionId]
            const currentOptTitle = currentOption
                ? currentOption.title
                    ? currentOption.title[this.locale]
                    : null
                : null
            let op1 = currentOptTitle === choiceTitle
            let op2 = currentOption
                ? currentOption.general
                    ? currentOption.general.title[this.locale] === choiceTitle
                    : false
                : false

            return op1 || op2
        },
        storedValue(id) {
            let value = null
            if (this.configuration[id]) {
                if (this.configuration[id]['general']) {
                    value = this.configuration[id]['general']
                } else {
                    value = this.configuration[id]
                }
            }

            return value
        },
        selectParameter({ id, value, lockOption }) {
            this.setNotesConfig({ param: id, value: value })
            if (value && !lockOption) {
                let index =
                    this.options.indexOf(
                        this.options.find((element) => element.id === id)
                    ) + 1
                if (index < this.options.length) {
                    let nextId = this.options[index].id
                    if (this.configuration[nextId] === null) {
                        this.setCurrentOption(this.options[index])
                    }
                }
            }
            this.setNextPageLocked(!this.validate())
        },
        filteredOptions(list) {
            if (list?.length) {
                return list.filter(
                    (option) => option.choices?.length || option.inputs?.length
                )
            }
        },
        validate() {
            return true
        },
    },
}
</script>

<style lang="scss">
.base-page {
    position: relative;
    height: 100%;
    max-width: 1290px;
    margin-left: auto;
    overflow-y: scroll;
    display: flex;
    @include hide-scrollbar();

    @media (max-width: $screen-medium) {
        padding: 20px 0;
        display: flex;
    }

    @media (max-width: $screen-tablet-medium) {
        margin-right: 0;
        overflow-y: auto;
        max-height: none;
        min-height: 360px;
        padding: 40px 0 0 0;
        padding-bottom: 65px;
    }

    @media (max-width: $screen-mobile-large) {
        padding: 0;
    }
}
</style>
