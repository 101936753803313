<template>
    <div
        class="bar-bottom"
        :class="{ 'bar-bottom--error': showError && isLocked }"
    >
        <button
            class="bar-bottom-nav bar-bottom-nav--left"
            @click="goToPrevPage"
        />
        <BarBottomOption :show-error="showError && isLocked" />
        <button
            v-if="next && !isLastPage"
            class="bar-bottom-nav bar-bottom-nav--right"
            :class="{ 'bar-bottom-nav--disabled': isLocked }"
            @click="goToNextPage"
        />
    </div>
</template>

<script>
import BarBottomOption from '@/components/bar/BarBottomOption'
import ChangePageMixin from '@/mixins/ChangePageMixin'

import { mapState, mapActions } from 'vuex'

export default {
    components: {
        BarBottomOption,
    },
    mixins: [ChangePageMixin],

    data() {
        return {
            showError: false,
        }
    },
    computed: {
        ...mapState({
            currentPage: (state) => state.page.currentPage,
        }),
        isLastPage() {
            return this.currentPage?.id === 'yourConfig'
        },
    },
    watch: {
        isLocked(newVal) {
            if (newVal) {
                this.disableInMenu(this.currentPage.id)
            }
        },
    },
    methods: {
        ...mapActions(['disableInMenu']),
        goToPrevPage() {
            this.showError = false
            this.goToPreviousPage()
        },
        goToNextPage() {
            if (!this.isLocked) {
                this.showError = false
                this.changePageAction('next')
            } else {
                this.showError = true
            }
        },
    },
}
</script>

<style lang="scss">
.bar-bottom {
    @include size(100%, 50px);
    position: fixed;
    bottom: 0;
    left: 0;
    background: $color-bg-dark;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    z-index: 6;

    &--error {
        background-color: $color-burnt-sienna;
        svg path {
            fill: $color-white;
        }
    }

    &--error & {
        &-title {
            color: $color-white;
        }
    }

    &-nav {
        @include size(48px, 100%);
        min-width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 0px;
        background-color: transparent;

        &::before {
            content: '';
            display: block;
            border: solid $color-silver-chalice;
            border-width: 0 0 2px 2px;
            @include size(10px);
        }

        &--left {
            border-right: 1px solid $color-black;
            background-color: $color-shark;
            &::before {
                margin-left: 5px;
                transform: rotate(45deg);
            }
        }

        &--right {
            background-color: $color-burnt-sienna;

            &::before {
                margin-right: 5px;
                transform: rotate(-135deg);
                border-color: $color-white;
            }
        }
        &--disabled {
            border-color: $color-silver;
            background-color: $color-silver;

            &::before {
                border-color: $color-white;
            }
        }
    }
}
</style>
