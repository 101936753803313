<template>
    <div
        class="workspace-tablet"
        :class="`workspace-tablet--${currentPage.id}`"
    >
        <img
            v-if="material && material.name === 'fsc'"
            src="/img/logo/fsc.svg"
            alt="eko logo"
            class="workspace-tablet-fsc"
        />
        <div class="workspace-tablet-content">
            <NotesCover
                v-if="showNotesImage && !currentPage.hideNotes"
                class="workspace-tablet-content-notes"
            />
            <div class="workspace-tablet-content-config">
                <transition name="fade" mode="out-in">
                    <MapperConfigPages />
                </transition>
            </div>
        </div>
        <Modal v-if="isModalOpen" :modal-component-name="modalComponentName" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '@/components/modal/Modal'
import NotesCover from '@/components/notes/NotesCover'
import MapperConfigPages from '@/components/mapper/MapperConfigPages'
import ConfigurationNumber from '@/mixins/ConfigurationNumber'
import GetCodes from '@/mixins/GetCodes'

export default {
    components: {
        Modal,
        NotesCover,
        MapperConfigPages,
    },
    mixins: [ConfigurationNumber, GetCodes],
    computed: {
        ...mapState({
            currentPage: (state) => state.page.currentPage,
            configuration: (state) => state.notes.configuration,
            isModalOpen: (state) => state.modal.isOpen,
            modalComponentName: (state) => state.modal.componentId,
            screenWidth: (state) => state.page.screenWidth,
            configurationApiCode: (state) => state.notes.configurationApiCode,
        }),
        showNotesImage() {
            return (
                this.screenWidth > 480 || this.currentPage.id === 'summaryPage'
            )
        },
        material() {
            return this.configuration.material
        },
    },
    watch: {
        currentPage(page) {
            if (
                page.id === 'yourConfig' &&
                !this.configurationApiCode?.calculationId
            ) {
                this.getCodes()
            }
        },
    },
}
</script>

<style lang="scss">
.workspace-tablet {
    height: 100%;
    width: 100%;
    margin: 80px 0 50px;
    position: relative;

    &-fsc {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 60px;
        height: auto;
    }

    &-content {
        padding: 65px 0 0;
    }

    &--material & {
        &-content {
            padding: 0;
        }
    }

    @media (max-width: $screen-mobile-landscape) {
        &-fsc {
            display: none;
        }
    }

    @media (max-width: $screen-mobile-large) {
        margin-top: 60px;

        &-content {
            padding: 0;

            &-notes {
                padding-top: 45px;
            }
        }
    }

    &--material,
    &--sent {
        margin: 0;
    }
    &--sent & {
        &-content {
            padding: 0;
            width: 100%;
        }
    }
}
</style>
