<script>
import { mapState } from 'vuex'
import { colors } from './utils/edgeColors'
import dependencies from '@/store/modules/dependencies'

export default {
    data() {
        return {
            pageTitle: {
                pl: 'Krawędzie',
                en: 'Edges',
                de: 'Buchrand',
                fr: 'Bords',
            },
        }
    },
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
        }),
        list() {
            return dependencies.state.list
        },
        options() {
            let KRAWEDZIE_TYP = null
            let KRAWEDZIE_FOLIA = null

            if (this.list?.length) {
                this.list.forEach((dependency) => {
                    KRAWEDZIE_TYP = mergeMultipleParams(
                        KRAWEDZIE_TYP,
                        dependency.actions.KRAWEDZIE_TYP
                    )
                    KRAWEDZIE_FOLIA = mergeMultipleParams(
                        KRAWEDZIE_FOLIA,
                        dependency.actions.KRAWEDZIE_FOLIA
                    )
                })
            }

            function mergeMultipleParams(param, dependencyAction) {
                param = param || dependencyAction

                if (param && dependencyAction) {
                    param = param.filter((dep) => {
                        return dependencyAction.includes(dep)
                    })
                }
                return param
            }

            function filterOptionsFn({ apiId }, allowed) {
                return allowed ? allowed.includes(apiId.toUpperCase()) : true
            }
            return [
                {
                    id: 'edgeColoration',
                    apiId: 'KRAWEDZIE_TYP',
                    title: {
                        pl: 'Znakowanie krawędzi',
                        en: 'Edges customization',
                        de: 'Farbschnitt',
                        fr: 'Marquage des bords',
                    },
                    choices: [
                        {
                            apiId: 'G',
                            title: {
                                pl: 'Druk',
                                en: 'Print',
                                de: 'Grafik',
                                fr: 'Marquage',
                            },
                        },
                        {
                            apiId: 'F',
                            title: {
                                pl: 'Folia metaliczna',
                                en: 'Metallic foil',
                                de: 'Metallic-Folie',
                                fr: 'Film métallique',
                            },
                            childrenGroups: [
                                {
                                    id: 'metallicFoilColor',
                                    apiId: 'KRAWEDZIE_FOLIA',
                                    required: true,
                                    title: {
                                        pl: 'Kolor',
                                        en: 'Colour',
                                        de: 'Farbe',
                                        fr: 'Couleur',
                                    },
                                    type: 'colors',
                                    items: colors.filter((item) => filterOptionsFn(item, KRAWEDZIE_FOLIA)),
                                },
                            ],
                        },
                    ].filter((item) => filterOptionsFn(item, KRAWEDZIE_TYP)),
                },
            ]
        },
    },
}
</script>
