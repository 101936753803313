<template>
    <div
        class="summary-prints"
        :class="{ 'summary-prints--agency': userType === 'agency' }"
    >
        <p class="summary-prints-category-header">
           {{ $t('offer.title') }}
            <span
                class="edit"
                @click="$emit('back')"
                v-text="$t('summary.edit')"
            />
        </p>
        <div class="offer-printing-list">
            <OfferPrintingRow
                v-for="(row, index) in volumes"
                :key="row.id"
                :index="index"
                :quantity="row.quantity"
                :actions="false"
            />
        </div>
    </div>
</template>

<script>
import { mapState} from 'vuex'
import OfferPrintingRow from "@/components/offer/OfferPrintingRow.vue"

export default {
    components: {OfferPrintingRow},
    computed: {
        ...mapState({
            userType: (state) => state.user.type,
            volumes: (state) => state.valuation.volumes,
        }),
    }
}
</script>

<style lang="scss">
@mixin change-to-column {
    &-rows {
        flex-direction: column;
        &-title {
            text-align: right;
            width: 100%;
            margin-bottom: 15px;
            margin-top: 15px;
        }
    }

    &-box {
        margin-right: 0;
    }

    &-section {
        display: flex;
        flex-flow: row nowrap;
        max-width: unset;
        margin: 0;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 15px;

        &-title {
            padding-right: 15px;
            width: 130px;
            text-align: right;
            display: block;
            margin: 0;
        }
    }
    &-buttons {
        width: 100%;
        justify-content: flex-end;
        margin-top: 0 !important;
    }
    &-button {
        margin-top: 0;
    }
}

.summary-prints {

    &-category {
        margin-bottom: 20px;
        &-header {
            color: $color-gray;
            padding: 12px 0;
            border-bottom: $color-silver2 solid 1px;
            margin-bottom: 29px;

            span {
                float: right;
                margin-left: 15px;
                color: $color-bg-selected-lighter;
                font-weight: 300;
                cursor: pointer;
                opacity: 1;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

}
</style>
