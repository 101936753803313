<script>
import BasePageMixin from '@/mixins/BasePageMixin'
import NotesImage from '@/components/notes/NotesCover'
import InsideData from '@/mixins/pages/InsideData'
import { mapState } from 'vuex'

export default {
    components: {
        NotesImage,
    },
    extends: BasePageMixin,
    mixins: [InsideData],
    computed: {
        ...mapState({
            numberOfPages: (state) => state.notes.configuration.numberOfPages,
            overprintType: (state) => state.notes.configuration.overprintType,
            paperType: (state) => state.notes.configuration.paperType,
        }),
    },
    methods: {
        validate() {
            const overprintType = this.overprintType
                ? this.overprintType?.general
                    ? this.overprintType.style || this.overprintType.druk
                    : true
                : false
            return this.numberOfPages && overprintType && this.paperType
        },
    },
}
</script>
