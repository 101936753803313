<template>
    <div class="modal-format">
        <div class="flex">
            <div class="modal-left">
                <ModalFormatPatterns :choices="options[0].choices" />
            </div>
            <div class="modal-right">
                <p class="modal-title">
                    {{ $t('format.title') }}
                </p>
                <div class="modal-inputs">
                    <InputCheckbox
                        v-for="choice in options[0].choices"
                        :key="choice.title[locale]"
                        :option="{ id: 'format' }"
                        :choice="choice"
                        :title="choice.title[locale]"
                        :checked="
                            configuration.format
                                ? configuration.format.title[locale] ===
                                  choice.title[locale]
                                : false
                        "
                        @input="selectParameter"
                    />
                </div>
                <p class="modal-subtitle">
                    *{{ $t('format.netto') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import ModalFormatPatterns from './ModalFormatPatterns'
import InputCheckbox from '@/components/input/InputCheckbox'

import { mapState, mapMutations, mapActions } from 'vuex'

import FormatData from '@/mixins/pages/FormatData'

export default {
    components: {
        ModalFormatPatterns,
        InputCheckbox,
    },
    mixins: [FormatData],
    computed: mapState({
        configuration: (state) => state.notes.configuration,
        locale: (state) => state.i18n.locale,
    }),
    methods: {
        ...mapMutations(['setNextPageLocked']),
        ...mapActions(['setNotesConfig']),
        selectParameter({ id, value }) {
            this.setNotesConfig({ param: id, value: value })
            this.setNextPageLocked(false)
        },
    },
}
</script>

<style lang="scss">
.modal-format {
    .modal-title {
        padding-left: 25px;
    }
    .modal-subtitle {
        padding-left: 25px;
    }
}
</style>
