import { notesData } from '@/mixins/pages/index'
import isEqual from 'lodash/isEqual'

const validator = {
    optionValidator: (state, property, apiValue) => {
        const storeConfiguration = state.configuration

        // check if configuration has property (in case property has been removed)
        if (!storeConfiguration.hasOwnProperty(property)) {
            return false
        }

        // check if value that comes from api exists in current configuration
        // check if property has value ~~ (in case property values has been changed/removed)

        if (apiValue) {
            // find current notes property
            const currentProperty = notesData.filter(
                (prop) => prop.id === property
            )
            // find value in current notes thata is equal in data from api

            // validate for specific notes configurations
            if (property === 'material') {
                return validator.validateStringOptions(apiValue, [
                    'standard',
                    'fsc',
                ])
            }
            if (property === 'coverColorType') {
                return validator.validateStringOptions(apiValue, [
                    'okleina',
                    'papier',
                ])
            }
            if (property === 'coverColorPattern') {
                let isValid = validator.validateProperty(
                    currentProperty[0].okleina,
                    apiValue
                )
                return !isValid
                    ? validator.validateProperty(
                          currentProperty[0].papier,
                          apiValue
                      )
                    : true
            }

            if (currentProperty.length) {
                if (currentProperty[0].inputs) {
                    // validate additionalPages
                    return validator.validateAdditionalPages(
                        currentProperty,
                        apiValue
                    )
                } else if (currentProperty[0].choices && !apiValue.general) {
                    // validate basic option
                    return validator.validateProperty(
                        currentProperty[0].choices,
                        apiValue
                    )
                } else if (apiValue.general && currentProperty[0].choices) {
                    // validate nested option
                    return validator.validateProperty(
                        currentProperty[0].choices,
                        apiValue.general
                    )
                }
            }
            return false
        }

        // if value is null ==> set current value to null to overide previous options
        return true
    },

    validateProperty: (choicesList, apiValue) => {
        const foundEqualProp = choicesList.filter((option) =>
            isEqual(option, apiValue)
        )
        if (!foundEqualProp.length) {
            state.configValidationError = true
        }
        return !!foundEqualProp.length
    },

    validateAdditionalPages: (currentProperty, apiValue) => {
        let isPersonalizedPagesValid = true
        const currentOptions = currentProperty[0].inputs[0]?.childrenGroups

        function getOptions(id) {
            return currentOptions.filter((option) => option.id === id)
        }
        const currentPagesPlace = getOptions('pagesPlace')
        const personalizedPrint = getOptions('personalizedPrint')
        const paperType = getOptions('paperType')

        !validator.validateProperty(
            currentPagesPlace[0]?.items,
            apiValue.pagesPlace
        )
            ? (isPersonalizedPagesValid = false)
            : ''
        !validator.validateProperty(
            personalizedPrint[0]?.items,
            apiValue.personalizedPrint
        )
            ? (isPersonalizedPagesValid = false)
            : ''
        !validator.validateProperty(paperType[0]?.items, apiValue.paperType)
            ? (isPersonalizedPagesValid = false)
            : ''

        if (!isPersonalizedPagesValid) {
            state.configValidationError = true
        }
        return isPersonalizedPagesValid
    },

    validateStringOptions: (option, posibleOptions) => {
        if (
            posibleOptions.find((el) => {
                return el === option
            })
        ) {
            return true
        }
        state.configValidationError = true
        return false
    },
}

export { validator }
