<template>
    <div
        :class="[
            'input-default',
            {
                'is-set': storedValue,
                'is-focused': focus,
                'is-filled': value && value.title.pl,
            },
        ]"
    >
        <InputDefaultText
            :id="id"
            :stored-value="storedValue"
            :placeholder="placeholder"
            :type="type"
            :value="value"
            @changeVal="onChangeValue"
            @edit="edit"
            @input="add"
        />
        <div v-if="childrenGroups && storedValue">
            <InputCheckboxChildren
                v-for="(childrenGroup, index) in childrenGroups"
                :id="childrenGroup.id"
                :key="childrenGroup.name"
                :title="childrenGroup.title[locale]"
                :type="childrenGroup.type"
                :items="childrenGroup.items"
                :required="childrenGroup.required"
                :current-settings="childrenSettings"
                :disabled="index > currentEnabled"
                @edit="onSelect"
                @select="onSelect"
            />
        </div>
    </div>
</template>

<script>
import InputCheckboxChildren from '@/components/input/InputCheckboxChildren'
import InputDefaultText from '@/components/input/InputDefaultText'

import debounce from 'lodash/debounce'
import { mapState, mapMutations } from 'vuex'

export default {
    components: {
        InputCheckboxChildren,
        InputDefaultText,
    },
    props: {
        option: {
            type: Object,
            default: () => {},
        },
        id: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        storedValue: {
            type: [String, Object],
            default: null,
        },
        childrenGroups: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            focus: false,
            value: '',
            childrenSettings: null,
            currentEnabled: 0,
            listOfChildrenGroups: [],
        }
    },
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
            locale: (state) => state.i18n.locale,
        }),
    },
    watch: {
        storedValue() {
            if (!this.storedValue) {
                this.cleanChildrenSettings()
            }
        },
    },
    created() {
        this.value = this.storedValue
        if (this.childrenGroups && this.childrenGroups.length > 0) {
            this.getListOfChildrenGroups()
            this.defaultChildrenSettings()
            this.checkEnabled()
        }
    },
    methods: {
        ...mapMutations(['setCurrentOption', 'setCurrentSubOption']),
        edit() {
            this.value = ''
            this.setCurrentOption(this.option)
            this.setCurrentSubOption(null)
            this.$emit('input', {
                id: this.option.id,
                value: null,
                lockOption: !!this.childrenGroups,
            })
        },
        onChangeValue(newValue) {
            this.value = newValue
            this.childrenSettings['general'] = this.value
        },
        add(data) {
            this.$emit('input', {
                id: this.option.id,
                value: data.value,
                lockOption: !!data.childrenGroups,
            })
        },

        onSelect(data) {
            this.$emit('input', {
                id: this.option.id,
                value: null,
            })
            let currentIndex
            if (this.listOfChildrenGroups.includes(data.id)) {
                currentIndex = this.listOfChildrenGroups.indexOf(data.id)
            }

            let lockOption = true
            if (data.value) {
                this.childrenSettings[data.id] = data.value
                this.currentEnabled = currentIndex + 1
                lockOption = currentIndex < this.listOfChildrenGroups.length - 1
            } else {
                this.currentEnabled = currentIndex
                for (
                    let i = currentIndex;
                    i < this.listOfChildrenGroups.length;
                    i++
                ) {
                    delete this.childrenSettings[this.listOfChildrenGroups[i]]
                }
            }
            this.$emit('input', {
                id: this.option.id,
                value: this.childrenSettings,
                lockOption: lockOption,
            })
        },
        defaultChildrenSettings() {
            if (
                typeof this.configuration[this.option.name] === 'object' &&
                this.configuration[this.option.id] != null
            ) {
                this.childrenSettings = this.configuration[this.option.id]
            } else {
                this.cleanChildrenSettings()
            }
        },
        cleanChildrenSettings() {
            this.childrenSettings = {}
            this.childrenSettings['general'] = this.value
        },
        getListOfChildrenGroups() {
            this.listOfChildrenGroups = this.childrenGroups.map((group) => {
                return group.name
            })
        },
        checkEnabled() {
            this.listOfChildrenGroups.forEach((item) => {
                if (this.childrenSettings[item]) {
                    this.currentEnabled++
                } else {
                    return
                }
            })
        },
    },
}
</script>
