<template>
    <g>
        <rect
            id="Rectangle_13912"
            data-name="Rectangle 13912"

            :fill="paperType ? (paperType.image ? 'url(#paperType)' : paperType.hex || '#f7f7f7') : '#f7f7f7'"
            :rx="roundCorners ? options[format].rx : 0"
            :width="options[format].width"
            :height="options[format].height"
            :transform="options[format].transform"
        />

        <!-- VERTICAL -->
        <g v-if="printType === 'Standardowy' || !printType">
            <PaperChequered
                v-if="printStyle === 'Kratka'"
                :format="formatSpecific"
            />
            <PaperHalfChequered
                v-if="printStyle === 'Pół kratka'"
                :format="formatSpecific"
            />
            <PaperLines
                v-if="printStyle === 'Linie'"
                :format="formatSpecific"
            />
            <PaperDaily
                v-if="printStyle === 'Planer dzienny'"
                :format="formatSpecific"
            />
            <PaperWeekly
                v-if="printStyle === 'Planer tygodniowy'"
                :format="formatSpecific"
            />
            <PaperDots
                v-if="printStyle === 'Kropki'"
                :format="formatSpecific"
            />
        </g>
        <g v-if="printType === 'Indywidualny projekt zadruku'">
            <VerticalIndividual
                v-if="format === 'vertical'"
                :druk="printDruk"
            />
            <HorizontalIndividual
                v-if="format === 'horizontal'"
                :druk="printDruk"
            />
            <SquareIndividual v-if="format === 'square'" :druk="printDruk" />
        </g>
    </g>
</template>

<script>
import PaperChequered from './standard/PaperChequered'
import PaperHalfChequered from './standard/PaperHalfChequered'
import PaperLines from './standard/PaperLines'
import PaperDaily from './standard/PaperDaily'
import PaperWeekly from './standard/PaperWeekly'
import PaperDots from './standard/PaperDots'

import VerticalIndividual from './individual/VerticalIndividual'
import HorizontalIndividual from './individual/HorizontalIndividual'
import SquareIndividual from './individual/SquareIndividual'
import { mapState } from 'vuex'

export default {
    components: {
        PaperChequered,
        PaperLines,
        PaperHalfChequered,
        PaperDaily,
        PaperWeekly,
        PaperDots,
        VerticalIndividual,
        HorizontalIndividual,
        SquareIndividual,
    },
    props: {
        format: {
            type: String,
            default: null,
        },
        formatSpecific: {
            type: String,
            default: '',
        },
        roundCorners: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: {
                vertical: {
                    width: '640',
                    height: '451',
                    transform: 'translate(-0.006 5.096)',
                    rx: 18,
                },
                horizontal: {
                    width: '625',
                    height: '185',
                    transform: 'translate(0 3.327)',
                    rx: 8,
                },
                square: {
                    width: '526',
                    height: '263',
                    transform: 'translate(0.5 5.095)',
                    rx: 11,
                },
            },
        }
    },
    computed: {
        ...mapState({
            paperType: (state) => state.notes.configuration.paperType,
            printType: (state) => {
                const option = state.notes.configuration.overprintType
                if (option && option.title) {
                    return option.title.pl
                }
                if (option && option.general) {
                    return option.general.title.pl
                }
                return null
            },
            printStyle: (state) => {
                const option = state.notes.configuration.overprintType
                if (option && option.style) {
                    return option.style.title.pl
                }
                return false
            },
            printDruk: (state) => {
                const option = state.notes.configuration.overprintType
                if (option && option.druk) {
                    return option.druk.title.pl
                }
                return false
            },
        }),
        paperToDisplay() {
            if (!this.paperType) {
                return 'Standardowy'
            } else {
                return this.paperType.title.pl
            }
        },
    },
}
</script>

<style lang="scss">
.notes-open-paper {
    &--standard {
        fill: #f7f7f7;
    }

    &--eco {
        fill: #eeebe3;
    }

    &--ivory {
        fill: #f8f6f1;
    }
}
</style>
