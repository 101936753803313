<template>
    <div class="change-password">
        <h1 class="change-password-title">
            {{ $t('form.passwordPage.title') }}
        </h1>
        <p class="change-password-description">
            {{ $t('form.passwordPage.description') }}
        </p>
        <form class="change-password-form" @submit.prevent="resetPassword">
            <label for="emailInput">
                {{ $t('form.passwordPage.label') }}
            </label>
            <input type="text" placeholder="nowak@poczta.pl" />
            <button type="submit" class="login-button">
                {{ $t('form.passwordPage.button') }}
            </button>
        </form>
        <LoaderForm v-if="isLoading" />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import LoaderForm from '@/components/loader/LoaderForm'

export default {
    components: {
        LoaderForm,
    },
    data() {
        return {
            isSuccess: true,
            isLoading: false,
        }
    },
    methods: {
        ...mapMutations(['changeSubpage']),
        resetPassword() {
            this.isLoading = true
            // reset password

            setTimeout(() => {
                // on success redirect

                //TODO add email validation
                if (this.isSuccess) {
                    this.successReset()
                } else {
                    // on error redirect
                    this.errorReset()
                }
                this.isLoading = false
            }, 1500)
        },
        successReset() {
            this.changeSubpage({
                page: 'MessagePage',
                props: {
                    title: 'Dziękujemy za zgłoszenie.',
                    text:
                        '<p>Na podany mail został wysłany link do aktywacji konta.</p>',
                    icon: 'mail',
                    callback: () => {
                        setTimeout(() => {
                            console.log('callback')
                        }, 2500)
                    },
                },
            })
        },
        errorReset() {
            this.changeSubpage({
                page: 'MessagePage',
                props: {
                    title: 'Coś poszło nie tak.',
                    text: '<p>Odśwież stronę i spróbuj ponownie.</p>',
                    icon: 'warning',
                    callback: () => {
                        setTimeout(() => {
                            console.log('callback')
                        }, 500)
                    },
                },
            })
        },
    },
}
</script>

<style lang="scss">
.change-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    height: 100%;
    max-width: 526px;

    &-title {
        font-size: 4.8rem;
        margin-top: 0;
        margin-bottom: 34px;
    }

    &-description {
        font-size: 2rem;
    }

    &-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;

        & > button {
            align-self: flex-end;
        }

        & > label {
            font-size: 1.8rem;
            margin-bottom: 11px;
        }

        & > input {
            width: 100%;
            height: 50px;
            border-radius: 0;
            border-width: 1px;
            border-color: $color-default-text;
            margin-bottom: 51px;
            padding-left: 25px;
        }
    }
    @media (max-width: $screen-tablet-small) {
        padding-left: 20px;
        padding-right: 20px;
        &-title {
            font-size: 3.6rem;
        }
    }
    @media (max-width: $screen-mobile-large) {
        padding-top: 50px;
    }
}
</style>
