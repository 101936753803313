<template>
    <g>
        <image
            v-if="verticalFormats.indexOf(format) > -1"
            :href="base64[image.vertical]"
            transform="translate(320 20)"
            width="300"
        />
        <image
            v-if="format === '205'"
            :href="base64[image['205']]"
            transform="translate(310 15)"
        />
        <image
            v-if="format === '175'"
            :href="base64[image['175']]"
            transform="translate(255 20)"
        />
    </g>
</template>

<script>
import { mapState } from 'vuex'
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            image: {
                vertical: '/img/notes/inside/standard/planer-vertical-week.png',
                '205': '/img/notes/inside/standard/planer-horizontal.png',
                '175': '/img/notes/inside/standard/planer-weekly-square.png',
            },
        }
    },
    computed: {
        ...mapState({
            verticalFormats: (state) => state.notes.verticalFormats,
        }),
    },

    created() {
        Object.keys(this.image).forEach((format) => {
            this.toDataUrl(this.image[format])
        })
    },
}
</script>
