<script>
import dependencies from '@/store/modules/dependencies'

export default {
    data() {
        return {
            pageTitle: {
                pl: 'Format',
                en: 'Format',
                de: 'Format',
                fr: 'Format',
            },
        }
    },
    computed: {
        list() {
            return dependencies.state.list
        },
        options() {
            let FORMAT = null

            if (this.list?.length) {
                this.list.forEach((dependency) => {
                    FORMAT = mergeMultipleParams(
                        FORMAT,
                        dependency.actions.FORMAT
                    )
                })
            }

            function mergeMultipleParams(param, dependencyAction) {
                param = param || dependencyAction

                if (param && dependencyAction) {
                    param = param.filter((dep) => {
                        return dependencyAction.includes(dep)
                    })
                }
                return param
            }

            function filterOptionsFn({ apiId }, allowed) {
                return allowed ? allowed.includes(apiId.toUpperCase()) : true
            }

            return [
                {
                    id: 'format',
                    apiId: 'FORMAT',
                    isModal: true,
                    required: true,
                    title: {
                        pl: 'Format',
                        en: 'Format',
                        de: 'Format',
                        fr: 'Format',
                    },
                    description: {
                        pl: '<p><strong>Format</strong> - opis html</p>',
                        en: '',
                        de: '',
                        fr: '',
                    },
                    choices: [
                        {
                            id: 'a4',
                            apiId: 'A4',
                            title: {
                                pl: 'A4 (210 x 297 mm)',
                                en: 'A4 (210 x 297 mm)',
                                de: 'A4 (210 x 297 mm)',
                                fr: 'A4 (210 x 297 mm)',
                            },
                        },
                        {
                            id: 'b5',
                            apiId: 'B5+',
                            title: {
                                pl: 'B5+ (185 x 245 mm)',
                                en: 'B5+ (185 x 245 mm)',
                                de: 'B5+ (185 x 245 mm)',
                                fr: 'B5+ (185 x 245 mm)',
                            },
                        },
                        {
                            id: 'a5',
                            apiId: 'A5',
                            title: {
                                pl: 'A5 (148 x 210 mm)',
                                en: 'A5 (148 x 210 mm)',
                                de: 'A5 (148 x 210 mm)',
                                fr: 'A5 (148 x 210 mm)',
                            },
                        },
                        {
                            id: '175',
                            apiId: 'Square',
                            title: {
                                pl: 'Square (175 x 175 mm)',
                                en: 'Square (175 x 175 mm)',
                                de: 'Square (175 x 175 mm)',
                                fr: 'Square (175 x 175 mm)',
                            },
                        },
                        {
                            id: '125',
                            apiId: 'Slim',
                            title: {
                                pl: 'Slim (125 x 205 mm)',
                                en: 'Slim (125 x 205 mm)',
                                de: 'Slim (125 x 205 mm)',
                                fr: 'Slim (125 x 205 mm)',
                            },
                        },
                        {
                            id: '205',
                            apiId: 'Reporter',
                            title: {
                                pl: 'Reporter (205 x 125 mm)',
                                en: 'Reporter (205 x 125 mm)',
                                de: 'Reporter (205 x 125 mm)',
                                fr: 'Reporter (205 x 125 mm)',
                            },
                        },
                        {
                            id: 'a6',
                            apiId: 'A6',
                            title: {
                                pl: 'A6 (105 x 148 mm)',
                                en: 'A6 (105 x 148 mm)',
                                de: 'A6 (105 x 148 mm)',
                                fr: 'A6 (105 x 148 mm)',
                            },
                        },
                        {
                            id: '84',
                            apiId: 'Mini',
                            title: {
                                pl: 'Mini (84 x 135 mm)',
                                en: 'Mini (84 x 135 mm)',
                                de: 'Mini (84 x 135 mm)',
                                fr: 'Mini (84 x 135 mm)',
                            },
                        },
                    ].filter((item) => filterOptionsFn(item, FORMAT)),
                },
            ]
        },
    },
}
</script>
