<template>
    <div v-if="showError" class="bar-bottom-title" @click="showPopoup">
        <span v-text="errorMessage" />
        <WarningIcon class="workspace-arrows-error-icon" />
    </div>
    <div v-else-if="currentPage.id === 'paper'" class="bar-bottom-search">
        <form @submit.prevent>
            <input
                type="text"
                :placeholder="$t('paper.placeholder')"
                @input="setSearchFn"
            />
            <div class="bar-bottom-search-icon" @click="setSearchFn">
                <SearchIcon />
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SearchIcon from '@/assets/images/system-icons/search.svg'
import WarningIcon from '@/assets/images/system-icons/warning-icon.svg'

export default {
    components: {
        SearchIcon,
        WarningIcon,
    },
    props: {
        showError: {
            type: Boolean,
            deafult: false,
        },
    },
    computed: {
        ...mapState({
            currentOption: (state) => state.notes.currentOption,
            currentPage: (state) => state.page.currentPage,
            locale: (state) => state.i18n.locale,
        }),
        hasModal() {
            return this.currentOption.isModal ? true : false
        },
        errorMessage() {
            return (
                this.$t(`errors.${this.currentPage.id}`) ||
                this.$t('errors.required')
            )
        },
    },
    methods: {
        ...mapMutations(['openModal', 'setModalComponentId', 'setSearch']),
        setSearchFn(event) {
            this.setSearch(event.target.value)
        },
        showPopoup() {
            if (this.hasModal) {
                this.setModalComponentId(this.currentOption.id)
                this.openModal(true)
            }
        },
    },
}
</script>

<style lang="scss">
.bar-bottom {
    &-search {
        display: none;
    }

    &-title {
        color: $color-boulder;
        display: flex;
        align-items: center;
        font-size: 1.4rem;

        svg {
            margin-right: 10px;
        }
    }
    @media (max-width: $screen-tablet-medium) {
        &-title {
            text-align: left;
            width: 100%;
            margin-left: 20px;
        }
    }

    @media (max-width: $screen-mobile-large) {
        &-search {
            display: block;
            width: 100%;
            padding: 0 20px;

            form {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                input {
                    color: $color-white;
                    background-color: transparent;
                    border: none;
                    width: 100%;
                    font-size: 1.4rem;

                    &::placeholder {
                        color: $color-white;
                        opacity: 1;
                    }
                    &:-ms-input-placeholder {
                        color: $color-white;
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media (max-width: $screen-mobile-iphone) {
        &-search {
            padding: 0 10px;
        }
    }
}
</style>
