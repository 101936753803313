<template>
    <div class="offer-region">
        <form class="offer-region-form" @submit.prevent>
            <div class="offer-region-form-country">
                <div class="offer-region-form-label">
                    {{ $t('offer.country') }}<span class="red">*</span>
                </div>
                <FormDropdown
                    v-model="selectedCountry"
                    :options="countries"
                    theme="wide"
                    @input="selectCountry"
                />
                <div v-if="warning" class="offer-region-form-warning">
                    <WarningIcon class="offer-region-form-warning-icon" />
                    <div class="offer-region-form-warning-label">
                        {{ $t('offer.warning') }} {{ warning }}
                    </div>
                </div>
            </div>

            <FormFieldDefault
                v-if="
                    selectedCountry && selectedCountry.toLowerCase() !== 'inny'
                "
                ref="zipCode"
                v-model="zipCode"
                name="zipCode"
                :label="$t('form.zipcode')"
                required
                theme="wrap"
            />
            <FormIsRequiredInfo class="offer-region-form-required" />

            <p>{{ $t('offer.countryOther') }}</p>
            <button
                class="login-button offer-region-form-button"
                :disabled="!$store.getters.validValuationConfig"
                @click.prevent="$emit('calculate')"
            >
                {{ $t('offer.calculate') }}
            </button>
        </form>
        <p class="offer-region-text">
            {{ $t('offer.orientation') }}
        </p>
    </div>
</template>

<script>
import FormDropdown from '@/components/form/FormDropdown'
import FormFieldDefault from '@/components/form/input/FormFieldDefault'
import { countries } from '@/assets/data/countries'
import WarningIcon from '@/assets/images/system-icons/warning-icon.svg'
import FormIsRequiredInfo from '@/components/form/FormIsRequiredInfo'

export default {
    components: {
        FormDropdown,
        FormFieldDefault,
        WarningIcon,
        FormIsRequiredInfo,
    },
    data() {
        return {
            warning: '',
            filter: '',
        }
    },
    computed: {
        locale() {
            return this.$store.state.i18n.locale
        },
        countries() {
            return [
                ...countries
                    .filter((c) => !!c.offer)
                    .map((c) => ({
                        label: c.i18n[this.locale] || c.code,
                        value: c.code,
                    })),
                { label: this.$t('offer.other'), value: 'inny' },
            ]
        },
        selectedCountry: {
            get: function () {
                return this.$store.state.valuation.country
            },
            set: function (value) {
                this.$store.commit('setValuationCountry', value)
            },
        },
        zipCode: {
            get: function () {
                return this.$store.state.valuation.zip
            },
            set: function (value) {
                this.$store.commit('setValuationZip', value)
            },
        },
    },
    created() {
        this.selectCountry(
            this.$store.state.user.userData.countryName || 'Polska'
        )
    },
    methods: {
        selectCountry(country) {
            this.selectedCountry = country
            if (country) {
                if (['inny'].includes(country.toLowerCase())) {
                    this.warning = 'FCA'
                } else if (['polska'].includes(country.toLowerCase())) {
                    this.warning = 'OWU'
                } else {
                    this.warning = 'CTP'
                }
            }
        },
    },
}
</script>

<style lang="scss">
.offer-region {
    margin: 50px 0 33px;

    &-form {
        width: 100%;
        position: relative;

        &-required {
            width: 100%;
            max-width: 340px;
            margin-left: auto;
        }

        &-country {
            display: flex;
            margin-bottom: 30px;
        }

        &-label {
            width: 28%;
            display: flex;
            align-items: center;
            .red {
                color: $color-burnt-sienna;
            }
        }

        &-button {
            margin: 30px 0 30px auto;
        }
        &-warning {
            position: absolute;
            top: 10px;
            right: 10px;

            &-icon {
                position: absolute;
                right: 62px;
                top: 5px;
                cursor: pointer;
            }
            &-icon:hover {
                ~ .offer-region-form-warning-label {
                    opacity: 1;
                }
            }
            &-label {
                background: $color-white;
                display: block;
                max-width: 160px;
                font-size: 1.3rem;
                padding: 15px;
                position: absolute;
                bottom: 20px;
                left: -160px;
                box-shadow: 0 5px 15px rgba($color-black, 0.1);
                opacity: 0;
                transition: opacity 0.2s;
                pointer-events: none;

                &::after {
                    @include size(16px);
                    content: '';
                    transform: rotate(45deg);
                    background: $color-white;
                    display: block;
                    position: absolute;
                    bottom: -8px;
                    left: 50%;
                }
            }
        }
    }
    &-text {
        font-size: 1.3rem;
    }
    @media (max-width: $screen-tablet-medium) {
        &-form {
            &-label {
                color: $color-nobel;
            }
        }
    }
    @media (max-width: $screen-mobile-large) {
        &-form {
            &-required {
                margin-left: 0;
            }
            &-country {
                flex-direction: column;
            }
            &-label {
                margin-bottom: 7px;
                width: 100%;
            }
        }
    }
}
</style>
