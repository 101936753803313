<template>
    <div class="input-search">
        <input
            v-model="value"
            type="text"
            class="input-search-input"
            :class="{ 'input-search-input--active': showInputOnTablet }"
            :placeholder="placeholder"
            @input="$emit('input', value)"
        />
        <div v-if="!value" class="input-search-icon" @click="submit">
            <SearchIcon />
        </div>
        <div v-else class="input-search-icon" @click="clearInput">
            <CrossIcon />
        </div>
    </div>
</template>

<script>
import SearchIcon from '@/assets/images/system-icons/search.svg'
import CrossIcon from '@/assets/images/system-icons/cross.svg'

export default {
    components: {
        SearchIcon,
        CrossIcon,
    },
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        theme: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            value: null,
            showInputOnTablet: false,
        }
    },
    methods: {
        submit() {
            if (this.theme === 'tablet') {
                this.toggleInput()
            } else {
                this.$emit('submit')
            }
        },
        toggleInput() {
            this.showInputOnTablet = !this.showInputOnTablet
        },
        clearInput() {
            this.value = null
            this.$emit('input', null)
        },
    },
}
</script>

<style lang="scss">
.input-search {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    &-input {
        border: 0;
        font-size: 1.6rem;
        line-height: 2rem;
        letter-spacing: 0.16px;
        color: $color-default-text;

        &::placeholder,
        &:-ms-input-placeholder {
            color: $color-default-text;
            opacity: 1;
        }
    }

    &-icon {
        cursor: pointer;
        transform: scale(1);
        transition: 0.2s;
        display: inline-flex;
        align-items: center;

        &:hover {
            transform: scale(1.1);
        }
    }

    @media (max-width: $screen-medium) {
        margin-top: 10px;
        display: flex;

        &-input {
            @include size(calc(100% - 49px), 50px);
            display: block;
            position: absolute;
            left: 0;
            background: $color-white;
            padding-left: 20px;
            border: 1px solid $color-alto2;
            border-right-width: 0;
            z-index: 99;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.2s;

            &--active {
                opacity: 1;
                pointer-events: all;
            }
        }

        &-icon {
            @include size(50px);
            min-width: 50px;
            border: 1px solid $color-alto2;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background: $color-burnt-sienna;
                transform: scale(1);
                > svg g,
                svg path {
                    stroke: $color-white;
                }
            }
        }
    }

    @media (max-width: $screen-tablet-medium) {
        &-input {
            width: 91%;
            z-index: 4;
        }

        &-icon {
            margin-right: 0;

            &:hover {
                background: transparent;
                > svg g,
                svg path {
                    stroke: $color-burnt-sienna;
                }
            }
        }
    }
}
</style>
