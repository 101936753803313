<template>
    <div class="lang-change">
        <div class="lang-change-current">
            {{ currentLocale }}
        </div>
        <div
            class="lang-change-locales"
            :class="{ 'lang-change-locales--hide': hide }"
        >
            <div
                v-for="(locale, index) in localesToDisplay"
                :key="index"
                class="lang-change-locales-locale"
                @click="changeLanguage(locale)"
            >
                {{ locale }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data() {
        return {
            hide: false,
        }
    },
    computed: {
        ...mapState({
            locales: (state) => state.i18n.locales,
            currentLocale: (state) => state.i18n.locale,
            defaultLocale: (state) => state.i18n.defaultLocale,
        }),
        localesToDisplay() {
            return this.locales.filter(
                (locale) => locale !== this.currentLocale
            )
        },
    },
    methods: {
        ...mapMutations(['setLocale']),
        changeLanguage(locale) {
            if (locale === this.defaultLocale) {
                this.$router.push('/')
            } else {
                this.$router.push({ path: locale })
            }
            this.setLocale(locale)
            this.hide = true
            setTimeout(() => {
                this.hide = false
            }, 100)
        },
    },
}
</script>

<style lang="scss">
.lang-change {
    opacity: 1;
    text-transform: uppercase;
    position: relative;

    &-locales {
        transition: 0.2s;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        pointer-events: none;
        color: $color-white;

        &--hide {
            display: none;
        }

        &-locale {
            @include size(48px);
            border-bottom: 1px solid $color-silver;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $color-bg-dark;

            &:hover {
                background-color: $color-burnt-sienna;
            }
        }
    }

    &-current {
        @include size(48px);
        background-color: $color-white;
        border: 2px solid $color-silver;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &:hover & {
        &-locales {
            pointer-events: all;
            opacity: 1;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        &-current {
            border-color: transparent;
            color: $color-gray;
            position: relative;
            background-color: $color-bg-dark;

            &::before {
                content: '';
                border: solid $color-gray;
                border-width: 1px 1px 0 0;
                transform: rotate(135deg);
                @include size(10px);
                position: absolute;
                right: -15px;
                top: 14px;
            }
        }
        &-locales {
            color: $color-gray;

            &-locale {
                width: 65px;

                &:hover {
                    color: $color-white;
                    background-color: $color-bg-dark;
                }
            }
        }
    }
}
</style>
