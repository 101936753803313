<template>
    <!-- eslint-disable -->
    <g v-if="pocketType">
        <defs>
            <linearGradient
                id="pocket-gradient"
                x1="0.805"
                y1="0.794"
                x2="0.129"
                y2="0.793"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.361" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="pocket-gradient-2"
                x2="0.926"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.341" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="pocket-gradient-3"
                x1="0.017"
                x2="1.371"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.341" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="pocket-gradient-6"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0" />
                <stop offset="1" stop-opacity="0.11" />
            </linearGradient>
            <linearGradient
                id="pocket-gradient-7"
                x1="0.185"
                y1="-0.083"
                x2="0.864"
                y2="0.775"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0" />
                <stop offset="1" stop-opacity="0.31" />
            </linearGradient>
            <linearGradient
                id="pocket-gradient-8"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.322" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="pocket-gradient-9"
                x1="1.463"
                y1="0.341"
                x2="0.204"
                y2="0.341"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.231" />
                <stop offset="1" stop-opacity="0.039" />
            </linearGradient>
            <linearGradient
                id="pocket-gradient-10"
                y1="0.511"
                x2="1.018"
                y2="0.511"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" stop-opacity="0.122" />
                <stop offset="1" stop-opacity="0.161" />
            </linearGradient>
            <linearGradient
                id="pocket-gradient-11"
                y1="0.36"
                x2="1"
                y2="0.364"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" stop-opacity="0.302" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="pocket-gradient-12"
                x1="1"
                y1="-0.092"
                x2="0.198"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.38" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
            <pattern
                id="pocket-pattern-2"
                width="1"
                height="1"
                viewBox="486.845 77.045 149.023 357.591"
            >
                <image
                    preserveAspectRatio="xMidYMid slice"
                    width="750.502"
                    height="491.875"
                    xlink:href="/img/notes/pocket-img.jpg"
                />
            </pattern>
        </defs>
        <g v-if="roundCorners">
            <g
                id="Group_1174"
                data-name="Group 1174"
                transform="translate(283 -108)"
            >
                <g
                    id="TU_INNY_KOLOR"
                    transform="translate(465 330.069)"
                    class="notes-pocket-color"
                >
                    <path
                        id="Path_390"
                        data-name="Path 390 v"
                        d="M0,0H264.663V450.955H0Z"
                        transform="translate(1.981 26.638)"
                        class="notes-pocket-color-13"
                        :class="colorClass"
                        :style="colorInsideStyle"
                    />
                    <path
                        id="Path_385"
                        data-name="Path 385"
                        d="M0,0H27L13.5,20.6Z"
                        transform="translate(0 26.909)"
                        class="notes-pocket-color-99"
                        :class="colorClass"
                        :style="colorStyle"
                    />
                    <path
                        id="Path_386"
                        data-name="Path 386"
                        d="M11.6-30.77l.5,47.634H-3Z"
                        transform="translate(16.656 30.931)"
                        class="notes-pocket-color-15"
                        :class="colorClass"
                        :style="colorInsideStyle"
                    />
                    <path
                        id="Path_387"
                        data-name="Path 387"
                        d="M0,20.6H27L13.5,0Z"
                        transform="translate(1 457.15)"
                        class="notes-pocket-color-99"
                        :class="colorClass"
                        :style="colorStyle"
                    />
                    <path
                        id="Path_388"
                        data-name="Path 388"
                        d="M12.251-1.43,13.6-45H-2.155Z"
                        transform="translate(15.656 498.86)"
                        class="notes-pocket-color-15"
                        :class="colorClass"
                        :style="colorInsideStyle"
                    />
                    <path
                        id="Path_6300"
                        data-name="Path 6300"
                        d="M814.9,179.23a18.5,18.5,0,0,0-18.5-18.5h-.189L551.733,134.146V635.141L797.4,611.73l.006-.027A18.5,18.5,0,0,0,814.9,593.23v-.062l-.12-411.826A18.635,18.635,0,0,0,814.9,179.23Z"
                        transform="translate(-523.76 -134.147)"
                        class="notes-pocket-color-14"
                        :class="colorClass"
                        :style="colorStyle"
                    />
                    <g
                        v-if="
                            colorClass &&
                            colorClass !== 'notes-pocket-color-flyleaf'
                        "
                        id="Group_1210"
                        data-name="Group 1210"
                        transform="translate(-186.868 -126.919)"
                    >
                        <g id="Group_1177" data-name="Group 1177">
                            <path
                                id="Path_6240"
                                data-name="Path 6240"
                                d="M307.082,326.675v.763c6.488.449,3.152,8.771,2.363,10.5l-3.336,7.351-6.674-12.626c-3.152-6.127-.1-5.811,1.066-5.731v-.718l-13.348-.94v.732c1.348.145,2.873.307,4.909,4.389,0,0,2.086,3.755,8.9,17.486v8.454c0,3.845-1.762,5.141-3.708,5.1v.824l12.93.262v-.81c-1.946-.041-3.708-1.4-3.708-5.218V346.118l3.291-7.353c5.144-10.785,8.62-10.544,10.242-10.431v-.749Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_6241"
                                data-name="Path 6241"
                                d="M334.657,328.177c-10.2-.724-15.526,7.294-15.526,17.187,0,9.945,5.33,17.895,15.526,18.094s15.526-7.194,15.526-16.7C350.183,337.3,344.853,328.9,334.657,328.177Zm0,34.549c-5.515-.114-8.9-3.452-8.9-17.064,0-13.563,3.383-17.139,8.9-16.753,5.469.384,8.806,4.331,8.806,17.549C343.463,359.723,340.127,362.839,334.657,362.726Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_6242"
                                data-name="Path 6242"
                                d="M367.606,364.1c4.91.1,11.4-2.062,11.359-11.068V340.055c0-2.194-.1-7.685,6.442-7.186v-.679l-13.025-.916v.692c5.885.362,5.885,5.853,5.885,8.049V353.01c0,5.811-3.85,8.833-7.971,8.737-4.727-.11-8.157-3.52-8.157-9.2v-17.17c0-3.518,2.039-3.987,4.032-3.848v-.7l-13.161-.926v.713c2.039.141,3.476,1.329,3.476,4.357v16.986c0,7.712,5.005,12.021,11.122,12.141Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_6243"
                                data-name="Path 6243"
                                d="M412.611,359.917c-2.318-8.759-5.747-10-8.806-10.045,5.236-.205,8.856-1.907,8.856-7.493,0-7.232-5.84-8.686-15.062-9.336l-10.154-.715v.677c2.039.141,3.1,1.157,3.1,3.944v21.859c0,3.411-1.111,4.644-3.1,4.6v.677l11.957.241v-.663c-1.947-.042-3.244-1.363-3.244-4.428v-9.3l2.178.094a6.82,6.82,0,0,1,6.488,4.842c2.966,9.579,1.993,9.56,9.13,9.751l3.893.079v-.648A5.822,5.822,0,0,1,412.611,359.917Zm-15.02-10.582-1.433-.063V333.613l1.436.1c4.727.328,8.713.517,8.713,8.306h0C406.3,349.721,402.5,349.553,397.591,349.335Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_6244"
                                data-name="Path 6244"
                                d="M311.855,397.043c-1.206,10.251-11.263,10.682-13.766,10.789l-1.668.071V377.722c0-3.714,1.854-4.582,3.85-4.571v-.77l-12.84-.08v.788c1.993.01,3.29,1.369,3.29,4.541v24.852c0,3.536-.927,5.707-2.919,5.793v.783l24.7-1.085V397.025Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_6245"
                                data-name="Path 6245"
                                d="M348.328,389.779c0-9.483-5.33-17.565-15.526-17.635s-15.526,8.31-15.526,18.231c0,9.971,5.33,17.6,15.526,17.146s15.527-8.211,15.527-17.742ZM332.8,406.786c-5.515.24-8.9-2.889-8.9-16.538,0-13.6,3.383-17.4,8.9-17.37,5.47.033,8.806,3.776,8.806,17.03C341.608,403.21,338.272,406.548,332.8,406.786Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_6246"
                                data-name="Path 6246"
                                d="M371.037,392.444v.694c2.874-.072,3.851.088,3.851,6.665,0,4.185-1.484,5.216-6.906,5.452-5.191.225-8.574-2.971-8.574-15.924s3.288-16.278,8.576-16.247c5.468.032,9.083,2.116,10.107,9.487l1.3-.011.324-7.582c-2.873-1.888-6.534-2.556-11.725-2.591-10.012-.069-15.8,7.605-15.8,17.08s5.79,16.93,15.8,16.484a35.008,35.008,0,0,0,11.906-2.446c0-9.954.463-10.6,3.152-10.668v-.681Z"
                                fill="#fff"
                            />
                            <path
                                id="Path_6247"
                                data-name="Path 6247"
                                d="M401.629,372.618c-10.2-.07-15.529,7.486-15.529,16.436,0,9,5.33,15.854,15.526,15.4s15.526-7.441,15.526-15.995h0C417.155,379.947,411.825,372.689,401.629,372.618Zm0,31.174c-5.515.24-8.9-2.565-8.9-14.865,0-12.255,3.383-15.68,8.9-15.648,5.466.033,8.806,3.4,8.806,15.308C410.435,400.54,407.1,403.554,401.629,403.792Z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
                <g
                    id="Group_767"
                    data-name="Group 767"
                    transform="translate(466 330.105)"
                >
                    <line
                        id="Line_180"
                        data-name="Line 180"
                        y2="451.043"
                        transform="translate(0.5 27.003)"
                        fill="none"
                        stroke="rgba(45,46,47,0.15)"
                        stroke-width="1.5"
                        class="notes-pocket-16"
                    />
                    <path
                        id="Path_527"
                        data-name="Path 527"
                        d="M11.472-2.777,12.749-45h-14.9Z"
                        transform="translate(15.656 500.824)"
                        class="notes-pocket-17"
                    />
                    <path
                        id="Path_529"
                        data-name="Path 529"
                        d="M0,0H19.252l-6.5,20.8Z"
                        transform="translate(0 26.873)"
                        class="notes-pocket-18"
                    />
                    <path
                        id="Path_526"
                        data-name="Path 526"
                        d="M11.6-30.77l-.333,47.634H-3Z"
                        transform="translate(15.655 30.895)"
                        class="notes-pocket-19"
                    />
                    <path
                        id="Path_392"
                        data-name="Path 392"
                        d="M.084.213,12.835,21.233H27V430.111H13.668L0,451.384Z"
                        transform="translate(0 26.66)"
                        class="notes-pocket-20"
                    />
                    <path
                        id="Path_6302"
                        data-name="Path 6302"
                        d="M814.9,179.23a18.5,18.5,0,0,0-18.5-18.5h-.189L551.733,134.146V635.141L797.4,611.73l.006-.027A18.5,18.5,0,0,0,814.9,593.23v-.062l-.12-411.826A18.635,18.635,0,0,0,814.9,179.23Z"
                        transform="translate(-524.76 -134.183)"
                        class="notes-pocket-21"
                    />
                    <path
                        id="Path_528"
                        data-name="Path 528"
                        d="M0,20.6H20.168L13.5,0Z"
                        transform="translate(0 457.114)"
                        class="notes-pocket-23"
                    />
                    <line
                        id="Line_179"
                        data-name="Line 179"
                        y2="501.047"
                        transform="translate(27.503)"
                        fill="none"
                        stroke="rgba(255,255,255,0.35)"
                        stroke-width="1"
                        class="notes-pocket-24"
                    />
                </g>
            </g>
        </g>
        <g
            v-else
            id="Group_1174"
            data-name="Group 1174"
            transform="translate(282 -107.273)"
        >
            <g id="TU_INNY_KOLOR" class="notes-pocket-color">
                <path
                    id="Path_390"
                    data-name="Path 390 rv"
                    d="M0,0H290.136V450.912H0Z"
                    transform="translate(465 356.705)"
                    class="notes-pocket-color-13"
                    :class="colorClass"
                    :style="colorInsideStyle"
                />
                <path
                    id="Path_385"
                    data-name="Path 385"
                    d="M0,0H27L13.5,20.594Z"
                    transform="translate(465 356.976)"
                    class="notes-pocket-color-99"
                    :class="colorClass"
                    :style="colorStyle"
                />
                <path
                    id="Path_386"
                    data-name="Path 386"
                    d="M11.6-30.77l.5,47.63H-3Z"
                    transform="translate(481.655 361)"
                    class="notes-pocket-color-15"
                    :class="colorClass"
                    :style="colorInsideStyle"
                />
                <path
                    id="Path_387"
                    data-name="Path 387"
                    d="M0,20.594H27L13.5,0Z"
                    transform="translate(466 787.177)"
                    class="notes-pocket-color-99"
                    :class="colorClass"
                    :style="colorStyle"
                />
                <path
                    id="Path_388"
                    data-name="Path 388"
                    d="M12.25-1.435,13.6-45H-2.155Z"
                    transform="translate(480.655 828.886)"
                    class="notes-pocket-color-15"
                    :class="colorClass"
                    :style="colorInsideStyle"
                />
                <path
                    id="Path_389"
                    data-name="Path 389"
                    d="M0-30,263.029-3.448V447.935L0,470.947Z"
                    transform="translate(492.971 360.069)"
                    class="notes-pocket-color-14"
                    :class="colorClass"
                    :style="colorStyle"
                />
            </g>
            <g
                v-if="colorClass && colorClass !== 'notes-pocket-color-flyleaf'"
                id="Group_1210"
                data-name="Group 1210"
                transform="translate(306.488 206.104)"
            >
                <g id="Group_1177" data-name="Group 1177">
                    <path
                        id="Path_6240"
                        data-name="Path 6240"
                        class="notes-pocket-5"
                        d="M307.082,326.675v.763c6.488.449,3.152,8.771,2.363,10.5l-3.336,7.351-6.674-12.626c-3.152-6.127-.1-5.811,1.066-5.731v-.718l-13.348-.94v.732c1.348.145,2.873.307,4.909,4.389,0,0,2.086,3.755,8.9,17.486v8.454c0,3.845-1.762,5.141-3.708,5.1v.824l12.93.262v-.81c-1.946-.041-3.708-1.4-3.708-5.218V346.118l3.291-7.353c5.144-10.785,8.62-10.544,10.242-10.431v-.749Z"
                    />
                    <path
                        id="Path_6241"
                        data-name="Path 6241"
                        class="notes-pocket-5"
                        d="M334.657,328.177c-10.2-.724-15.526,7.294-15.526,17.187,0,9.945,5.33,17.895,15.526,18.094s15.526-7.194,15.526-16.7C350.183,337.3,344.853,328.9,334.657,328.177Zm0,34.549c-5.515-.114-8.9-3.452-8.9-17.064,0-13.563,3.383-17.139,8.9-16.753,5.469.384,8.806,4.331,8.806,17.549C343.463,359.723,340.127,362.839,334.657,362.726Z"
                    />
                    <path
                        id="Path_6242"
                        data-name="Path 6242"
                        class="notes-pocket-5"
                        d="M367.606,364.1c4.91.1,11.4-2.062,11.359-11.068V340.055c0-2.194-.1-7.685,6.442-7.186v-.679l-13.025-.916v.692c5.885.362,5.885,5.853,5.885,8.049V353.01c0,5.811-3.85,8.833-7.971,8.737-4.727-.11-8.157-3.52-8.157-9.2v-17.17c0-3.518,2.039-3.987,4.032-3.848v-.7l-13.161-.926v.713c2.039.141,3.476,1.329,3.476,4.357v16.986c0,7.712,5.005,12.021,11.122,12.141Z"
                    />
                    <path
                        id="Path_6243"
                        data-name="Path 6243"
                        class="notes-pocket-5"
                        d="M412.611,359.917c-2.318-8.759-5.747-10-8.806-10.045,5.236-.205,8.856-1.907,8.856-7.493,0-7.232-5.84-8.686-15.062-9.336l-10.154-.715v.677c2.039.141,3.1,1.157,3.1,3.944v21.859c0,3.411-1.111,4.644-3.1,4.6v.677l11.957.241v-.663c-1.947-.042-3.244-1.363-3.244-4.428v-9.3l2.178.094a6.82,6.82,0,0,1,6.488,4.842c2.966,9.579,1.993,9.56,9.13,9.751l3.893.079v-.648A5.822,5.822,0,0,1,412.611,359.917Zm-15.02-10.582-1.433-.063V333.613l1.436.1c4.727.328,8.713.517,8.713,8.306h0C406.3,349.721,402.5,349.553,397.591,349.335Z"
                    />
                    <path
                        id="Path_6244"
                        data-name="Path 6244"
                        class="notes-pocket-5"
                        d="M311.855,397.043c-1.206,10.251-11.263,10.682-13.766,10.789l-1.668.071V377.722c0-3.714,1.854-4.582,3.85-4.571v-.77l-12.84-.08v.788c1.993.01,3.29,1.369,3.29,4.541v24.852c0,3.536-.927,5.707-2.919,5.793v.783l24.7-1.085V397.025Z"
                    />
                    <path
                        id="Path_6245"
                        data-name="Path 6245"
                        class="notes-pocket-5"
                        d="M348.328,389.779c0-9.483-5.33-17.565-15.526-17.635s-15.526,8.31-15.526,18.231c0,9.971,5.33,17.6,15.526,17.146s15.527-8.211,15.527-17.742ZM332.8,406.786c-5.515.24-8.9-2.889-8.9-16.538,0-13.6,3.383-17.4,8.9-17.37,5.47.033,8.806,3.776,8.806,17.03C341.608,403.21,338.272,406.548,332.8,406.786Z"
                    />
                    <path
                        id="Path_6246"
                        data-name="Path 6246"
                        class="notes-pocket-5"
                        d="M371.037,392.444v.694c2.874-.072,3.851.088,3.851,6.665,0,4.185-1.484,5.216-6.906,5.452-5.191.225-8.574-2.971-8.574-15.924s3.288-16.278,8.576-16.247c5.468.032,9.083,2.116,10.107,9.487l1.3-.011.324-7.582c-2.873-1.888-6.534-2.556-11.725-2.591-10.012-.069-15.8,7.605-15.8,17.08s5.79,16.93,15.8,16.484a35.008,35.008,0,0,0,11.906-2.446c0-9.954.463-10.6,3.152-10.668v-.681Z"
                    />
                    <path
                        id="Path_6247"
                        data-name="Path 6247"
                        class="notes-pocket-5"
                        d="M401.629,372.618c-10.2-.07-15.529,7.486-15.529,16.436,0,9,5.33,15.854,15.526,15.4s15.526-7.441,15.526-15.995h0C417.155,379.947,411.825,372.689,401.629,372.618Zm0,31.174c-5.515.24-8.9-2.565-8.9-14.865,0-12.255,3.383-15.68,8.9-15.648,5.466.033,8.806,3.4,8.806,15.308C410.435,400.54,407.1,403.554,401.629,403.792Z"
                    />
                </g>
            </g>
            <g id="Group_767" data-name="Group 767">
                <line
                    id="Line_180"
                    data-name="Line 180"
                    class="notes-pocket-16"
                    y2="451"
                    transform="translate(466.5 357.105)"
                />
                <path
                    id="Path_527"
                    data-name="Path 527"
                    class="notes-pocket-17"
                    d="M11.47-2.781,12.747-45h-14.9Z"
                    transform="translate(481.654 830.887)"
                />
                <path
                    id="Path_529"
                    data-name="Path 529"
                    class="notes-pocket-18"
                    d="M0,0H19.25l-6.5,20.8Z"
                    transform="translate(466 356.976)"
                />
                <path
                    id="Path_526"
                    data-name="Path 526"
                    class="notes-pocket-19"
                    d="M11.6-30.77l-.333,47.63H-3Z"
                    transform="translate(481.654 361)"
                />
                <path
                    id="Path_392"
                    data-name="Path 392"
                    class="notes-pocket-20"
                    d="M.083.213l12.75,21.019H27V430.071H13.667L0,451.342Z"
                    transform="translate(466 356.763)"
                />

                <path
                    id="Path_391"
                    data-name="Path 391"
                    class="notes-pocket-22"
                    d="M0-30,263-3.46V447.728l-263,23Z"
                    transform="translate(493 360.21)"
                />
                <path
                    id="Path_525"
                    data-name="Path 525"
                    class="notes-pocket-21"
                    d="M0-30,263-3.46V447.728l-263,23Z"
                    transform="translate(493 360.21)"
                />
                <path
                    id="Path_528"
                    data-name="Path 528"
                    class="notes-pocket-23"
                    d="M0,20.594H20.167L13.5,0Z"
                    transform="translate(466 787.177)"
                />
                <line
                    id="Line_179"
                    data-name="Line 179"
                    class="notes-pocket-24"
                    y2="501"
                    transform="translate(493.5 330.105)"
                />
            </g>
        </g>
    </g>
</template>

<script>
import FlyleafPageMixin from '@/mixins/FlyleafPageMixin'

export default {
    mixins: [FlyleafPageMixin],
    props: {
        pocketType: {
            type: String,
            default: '',
        },
        pocketPrint: {
            type: String,
            default: '',
        },
        roundCorners: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
.notes-pocket {
    &-5 {
        fill: #fff;
    }

    &-16,
    &-24,
    &-26 {
        fill: none;
    }
    &-color {
        &-13 {
            fill: #fff;
            &.one {
                fill: #efefef;
            }
            &.cmyk {
                fill: #efefef;
            }
            &.oneone {
                fill: #7eb9bf;
            }
            &.cmykcmyk {
                fill: #3e5873;
            }
        }

        &-14 {
            fill: #fff;
            &.one {
                fill: #7eb9bf;
            }
            &.cmyk {
                fill: url(#pocket-pattern-2);
            }
            &.oneone {
                fill: #7eb9bf;
            }
            &.cmykcmyk {
                fill: url(#pocket-pattern-2);
            }
        }
        &-99 {
            fill: #fff;
            &.one {
                fill: #7eb9bf;
            }
            &.cmyk {
                fill: #3e5873;
            }
            &.oneone {
                fill: #7eb9bf;
            }
            &.cmykcmyk {
                fill: #3e5873;
            }
        }

        &-15 {
            fill: #fff;
            &.one {
                fill: #d8d8d8;
            }
            &.oneone {
                fill: #7eb9bf;
            }
            &.cmyk {
                fill: #d8d8d8;
            }
            &.cmykcmyk {
                fill: #3e5873;
            }
        }

        &-flyleaf {
            fill: url('#flyleafPaper');
        }
    }

    &-16 {
        stroke: rgba(45, 46, 47, 0.15);
        stroke-width: 1.5px;
    }

    &-17 {
        fill: url(#pocket-gradient-6);
    }

    &-18 {
        fill: url(#pocket-gradient-7);
    }

    &-19 {
        fill: url(#pocket-gradient-8);
    }

    &-20 {
        fill: url(#pocket-gradient-9);
    }

    &-21 {
        fill: url(#pocket-gradient-10);
        &.flyleaf {
            fill: url(#flyleafPaper);
        }
    }

    &-22 {
        opacity: 0.23;
        fill: url(#pocket-gradient-11);
    }

    &-23 {
        fill: url(#pocket-gradient-12);
    }

    &-24 {
        stroke: rgba(255, 255, 255, 0.35);
    }
}
</style>
