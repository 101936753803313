<template>
    <div class="new-password">
        <div class="new-password-wrapper">
            <div class="new-password-header">
                <h1 class="new-password-title">
                    {{ $t('form.passwordPage.hello') }}
                    <span class="red">{{ userInfo.name }}</span>
                    {{ $t('form.passwordPage.company') }} {{ userInfo.company }}
                </h1>
                <p>{{ $t('form.passwordPage.tip') }}</p>
            </div>
            <form class="new-password-form" @submit.prevent="submitForm">
                <FormFieldDefault
                    ref="password"
                    v-model="payload.password"
                    name="password"
                    type="password"
                    :label="$t('form.password')"
                    required
                    @input="$refs.checker.checkPassStrength(payload.password)"
                />
                <FormPasswordStrength ref="checker" />
                <FormFieldDefault
                    ref="password2"
                    v-model="payload.password2"
                    name="password2"
                    type="password"
                    :label="$t('form.password2')"
                    required
                />
                <FormIsRequiredInfo class="new-password-form-required" />
                <button
                    class="form-submit send-button"
                    type="submit"
                    v-text="$t('form.signInAndGoToOffer')"
                />
            </form>
        </div>
    </div>
</template>

<script>
import FormPasswordStrength from '@/components/form/FormPasswordStrength'
import FormFieldDefault from '@/components/form/input/FormFieldDefault'
import FormMixin from '@/mixins/FormMixin'
import FormIsRequiredInfo from '@/components/form/FormIsRequiredInfo'

export default {
    components: {
        FormFieldDefault,
        FormPasswordStrength,
        FormIsRequiredInfo,
    },
    mixins: [FormMixin],
    data() {
        return {
            payload: {
                password: null,
                password2: null,
            },
            userInfo: {
                name: 'Jan',
                company: 'PromoNotes Sp. z o.o.',
            },
        }
    },
    methods: {
        submitForm() {
            console.log('submitForm')
        },
        setDefaultPayload() {
            this.payload.password = ''
            this.payload.password2 = ''
        },
    },
}
</script>

<style lang="scss">
.new-password {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &-title {
        font-size: 4.8rem;
        .red {
            color: $color-burnt-sienna;
        }
    }

    &-wrapper {
        max-width: 600px;
    }

    &-form {
        margin-top: 50px;

        &-required {
            margin-left: 28%;
            margin-top: 20px;
        }

        .form-field-default {
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    @media (max-width: $screen-mobile-large) {
        &-wrapper {
            padding: 0 30px;
        }
        &-form {
            &-required {
                margin-left: 0;
            }
        }
    }
}
</style>
