var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'form-field form-field-checkbox',
        {
            'is-checked': _vm.checked,
            'is-required': _vm.required,
            'is-error': _vm.error,
        },
    ],on:{"click":_vm.toggle}},[_vm._m(0),_c('div',{directives:[{name:"bastards",rawName:"v-bastards"}],staticClass:"form-field-checkbox-label",class:{ 'form-field-checkbox-label--expanded': _vm.labelExpanded },domProps:{"innerHTML":_vm._s(_vm.label)}}),_c('ArrowIcon',{staticClass:"form-field-checkbox-arrow",class:{ 'form-field-checkbox-arrow--rotated': _vm.labelExpanded },on:{"click":function($event){$event.stopPropagation();return _vm.expandLabel.apply(null, arguments)}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-field-checkbox-control"},[_c('div',{staticClass:"form-field-checkbox-control-input"})])
}]

export { render, staticRenderFns }