<template>
    <div class="bar-top">
        <NavigationTablet />
        <Hamburger :is-active="isMenuOpen" @click="toggleMenu" />
        <div class="bar-top-title">
            <component :is="iconModule" v-if="iconModule" />
            <div v-text="currentPage.title[locale]" />
        </div>
        <LangChange class="bar-top-lang-change" />
        <div class="bar-top-logo">
            <SvgLogoSmall />
        </div>
    </div>
</template>

<script>
import NavigationTablet from '@/components/navigation/NavigationTablet'
import Hamburger from '@/components/hamburger/Hamburger'
import SvgLogoSmall from '@/assets/images/logo_mindnotes_small.svg'
import LangChange from '@/components/lang/LangChange'
import { mapState, mapMutations } from 'vuex'

export default {
    components: {
        NavigationTablet,
        Hamburger,
        SvgLogoSmall,
        LangChange,
    },
    computed: {
        ...mapState({
            currentPage: (state) => state.page.currentPage,
            isMenuOpen: (state) => state.page.isMobileMenuActive,
            locale: (state) => state.i18n.locale,
        }),
        iconModule() {
            if (this.currentPage.icon) {
                return require(`@/assets/${this.currentPage.icon}`).default
            }
            return null
        },
    },
    methods: {
        ...mapMutations(['showMobileMenu', 'setCurrentSubOption']),
        toggleMenu() {
            this.showMobileMenu(!this.isMenuOpen)
            if (this.isMenuOpen) {
                this.setCurrentSubOption(null)
            }
        },
        closeMenu() {
            this.showMobileMenu(false)
        },
    },
}
</script>

<style lang="scss">
.bar-top {
    @include size(100%, 80px);
    background: $color-bg-dark;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;

    &-title {
        display: flex;
        align-items: center;
        color: $color-white;

        svg {
            fill: $color-burnt-sienna;
            margin-right: 10px;
        }
    }

    &-lang-change {
        position: absolute;
        right: 120px;
    }

    &-logo {
        margin-right: 30px;
        fill: $color-white;
        transform: scale(0.65);
    }

    @media (max-width: $screen-mobile-large) {
        height: 60px;
        &-title {
            width: 100%;
            padding-left: 10px;
            font-size: 1.6rem;
        }
        &-logo {
            margin-right: 15px;
        }
    }
    @media (max-width: 430px) {
        &-lang-change {
            display: none;
        }
    }
}
</style>
