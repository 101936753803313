const state = {
    viewClosed: {
        image: '',
    },
    viewOpen: {
        image: '',
    },
    viewFlyleaf: {
        image: '',
    },
    viewEdge: {
        image: '',
    },
    isLoading: false,
}

const mutations = {
    setLoading(state, loading) {
        state.isLoading = loading
    },
    setViewClosed(state, viewClosed) {
        state.viewClosed.image = viewClosed
    },
    setViewFlyleaf(state, viewFlyleaf) {
        state.viewFlyleaf.image = viewFlyleaf
    },
    setViewOpen(state, viewOpen) {
        state.viewOpen.image = viewOpen
    },
    setViewEdge(state, viewEdge) {
        state.viewEdge.image = viewEdge
    },
}

export default {
    state,
    mutations,
}
