<template>
    <div v-if="coverType === 'okleina'" class="filter-paper">
        <div class="filter-paper-filters">
            <div class="filter-paper-filters-title">
                {{ $t('paper.filter') }}
            </div>
            <InputDropdown
                :name="colorTitle"
                :data="patternColors"
                theme="colors"
                :title="$t('paper.color')"
                @selected="setColor"
            />
            <InputDropdown
                :name="categoryTitle"
                :data="patternCategories"
                :title="$t('paper.category')"
                @selected="setCategory"
            />
        </div>
        <div class="filter-paper-search filter-paper-search--tablet">
            <form
                :class="[
                    'filter-paper-search-form',
                    {
                        'filter-paper-search-form-smaller':
                            coverType === 'papier',
                    },
                ]"
                @submit.prevent
            >
                <InputSearch
                    ref="search"
                    :placeholder="$t('paper.search')"
                    theme="tablet"
                    @input="search"
                />
                <InputSearchTips
                    ref="tips"
                    :patterns="patterns"
                    :cover-type="coverType"
                    :search-input="$refs.search"
                />
            </form>
        </div>
    </div>
</template>

<script>
import InputDropdown from '@/components/input/InputDropdown'
import InputSearch from '@/components/input/InputSearch'
import InputSearchTips from '@/components/input/InputSearchTips'

import { mapMutations } from 'vuex'

export default {
    components: {
        InputDropdown,
        InputSearch,
        InputSearchTips,
    },
    props: {
        coverType: {
            type: String,
            default: 'okleina',
        },
        categoryTitle: {
            type: String,
            default: '',
        },
        colorTitle: {
            type: String,
            default: '',
        },
        patternColors: {
            type: Object,
            default: () => {},
        },
        patternCategories: {
            type: Object,
            default: () => {},
        },
        patterns: {
            type: Object,
            default: () => {},
        },
    },
    beforeDestroy() {
        this.setSearch('')
    },
    methods: {
        ...mapMutations(['setCategory', 'setColor', 'setSearch']),
        search(event) {
            this.setSearch(event)
            this.$refs.tips.isModalOpen = true
        },
    },
}
</script>

<style lang="scss">
.filter-paper {
    &-filters {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }

    &-search {
        margin-left: auto;
        &--tablet {
            display: none;
        }
    }

    @media (max-width: $screen-page) {
        &-filters {
            flex-flow: row wrap;
            .input-dropdown {
                &-selected {
                    margin-left: 0;
                }
            }

            &-title {
                width: 100%;
                margin-top: 10px;
            }
        }
    }

    @media (max-width: $screen-medium) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        position: relative;

        &-filters {
            flex-wrap: wrap;
            justify-content: flex-start;

            &-title {
                width: 100%;
                margin-top: 20px;
            }
        }
        &-search {
            &--tablet {
                display: block;
                margin-bottom: 30px;
            }
            &-form-smaller {
                .input-text-input--active {
                    width: 210px;
                }
            }
        }
    }

    @media (max-width: $screen-desktop) {
        flex-wrap: wrap;
        &-filters {
            width: calc(100% - 50px);
            .input-dropdown-selected {
                margin-right: 8px;
            }
        }
        &-search {
            margin-left: auto;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        max-width: 510px;
        margin: 0 auto;

        &-filters {
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;

            &-title {
                width: auto;
                margin: 0 10px 20px 0;
                font-size: 1.4rem;
            }
        }
        &-search {
            margin-left: 0;
        }
    }

    @media (max-width: $screen-mobile-landscape) {
        max-width: 100%;
        margin: 0 auto;
    }

    @media (max-width: $screen-mobile-large) {
        margin: 0;

        &-filters {
            min-width: 0;
            padding: 16px 20px 13px;
            box-shadow: 0 4px 12px -2px rgba(204, 204, 204, 0.258);
            background-color: $color-white;
            position: relative;
            top: 64px;
            width: 100%;
            // z-index: 9;

            &-title {
                display: none;
            }
        }

        &-search--tablet {
            display: none;
        }
    }
    @media (max-width: $screen-mobile-iphone) {
        &-filters {
            top: 78px;
        }
    }
}
</style>
