<template>
    <form class="offer-region-form" @submit.prevent="onSubmitForm">
        <p style="padding-bottom: 20px;">
            {{ $t('form.agencyInfo') }}
        </p>
        <FormFieldDefault
            ref="name"
            v-model="payload.dealerName"
            name="name"
            :label="$t('form.agencyName')"
        />
        <FormFieldDefault
            ref="email"
            v-model="payload.dealerEmail"
            name="email"
            type="email"
            :label="$t('form.agencyEmail')"
        />
        <FormFieldDefault
            ref="info"
            v-model="payload.dealerContactDetails"
            name="info"
            :label="$t('form.agencyAddress')"
        />
        <!--        <FormIsRequiredInfo class="offer-region-form-required" />-->
    </form>
</template>

<script>
import FormFieldDefault from '@/components/form/input/FormFieldDefault.vue'
// import FormIsRequiredInfo from '@/components/form/FormIsRequiredInfo.vue'
import FormMixin from '@/mixins/FormMixin'
import { individualFormDefaults } from '@/assets/data/form'

export default {
    name: 'FormSummaryDistributor',
    components: { FormFieldDefault },
    extends: FormMixin,
    computed: {
        formIsValid() {
            return this.validation()
        },
    },
    methods: {
        setDefaultPayload() {
            this.payload = {
                ...individualFormDefaults,
                ...this.$store.state.user.userData,
            }
        },
        onSubmitForm() {
            this.$store.commit('setUserData', this.payload)
            this.$emit('submit')
        },
    },
}
</script>
