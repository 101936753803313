<script>
import dependencies from '@/store/modules/dependencies'
import { bandsColors7, bandsColors10, bandsColors15 } from './utils/bandsColors'
import { ribbonsColors } from './utils/ribbonsColors'
import { pensColors } from './utils/pensColors'

export default {
    data() {
        return {
            pageTitle: {
                pl: 'Akcesoria',
                en: 'Accessories',
                de: 'Accessoires',
                fr: 'Accessoires',
            },
        }
    },
    computed: {
        list() {
            return dependencies.state.list
        },
        options(context) {
            let GUMKA_ZAMYKAJACA = null
            let UCHWYT_NA_DLUGOPIS = null
            let TASIEMKA = null
            let BANDEROLA = null
            let KIESZEN_KOLORY = null

            if (this.list?.length) {
                this.list.forEach((dependency) => {
                    GUMKA_ZAMYKAJACA = mergeMultipleParams(
                        GUMKA_ZAMYKAJACA,
                        dependency.actions.GUMKA_ZAMYKAJACA
                    )
                    UCHWYT_NA_DLUGOPIS = mergeMultipleParams(
                        UCHWYT_NA_DLUGOPIS,
                        dependency.actions.UCHWYT_NA_DLUGOPIS
                    )

                    TASIEMKA = mergeMultipleParams(
                        TASIEMKA,
                        dependency.actions.TASIEMKA
                    )
                    BANDEROLA = mergeMultipleParams(
                        BANDEROLA,
                        dependency.actions.BANDEROLA
                    )
                    KIESZEN_KOLORY = mergeMultipleParams(
                        KIESZEN_KOLORY,
                        dependency.actions.KIESZEN_KOLORY
                    )
                })
            }

            function mergeMultipleParams(param, dependencyAction) {
                param = param || dependencyAction

                if (param && dependencyAction) {
                    param = param.filter((dep) => {
                        return dependencyAction.includes(dep)
                    })
                }
                return param
            }

            function filterOptionsFn({ apiId }, allowed) {
                return allowed ? allowed.includes(apiId.toUpperCase()) : true
            }

            const coverTypeId =
                context?.$store?.state?.notes?.configuration?.coverType?.apiId

            // jeśli występuje tasiemka i twarde okładki wówczas tasiemka i jej kolor muszą być wybrane
            // Kieszonka jest obowiązkowa w sytuacji wyboru gumki zamykajacej dla miękkiej okładki
            const isPocketRequired =
                context?.$store?.state?.notes?.configuration?.elastic
                    ?.general && ['1', '2'].includes(coverTypeId)

            // Kieszonka jest obowiązkowa w sytuacji wyboru uchwytu na długopis dla miękkiej okładki
            const pocketIsRequired =
                (context?.$store?.state?.notes?.configuration?.penHolder
                    ?.general &&
                    ['1', '2'].includes(coverTypeId)) ||
                isPocketRequired

            return [
                GUMKA_ZAMYKAJACA?.length === 0
                    ? null
                    : {
                          id: 'elastic',
                          apiId: 'GUMKA_ZAMYKAJACA',
                          title: {
                              pl: 'Gumka zamykająca',
                              en: 'Elastic closure',
                              de: 'Verschlussgummiband',
                              fr: 'Fermeture élastique',
                          },
                          choices: [
                              {
                                  apiId: '7_',
                                  title: {
                                      pl: '7 mm',
                                      en: '7 mm',
                                      de: '7 mm',
                                      fr: '7 mm',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'elasticColor',
                                          apiId: '',
                                          required: true,
                                          title: {
                                              pl: 'Kolor',
                                              en: 'Colour',
                                              de: 'Farbe',
                                              fr: 'Couleur',
                                          },
                                          type: 'colors',
                                          items: bandsColors7.filter((option) =>
                                              filterOptionsFn(
                                                  option,
                                                  GUMKA_ZAMYKAJACA
                                              )
                                          ),
                                      },
                                  ],
                              },
                              {
                                  apiId: '10_',
                                  title: {
                                      pl: '10 mm',
                                      en: '10 mm',
                                      de: '10 mm',
                                      fr: '10 mm',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'elasticColor',

                                          apiId: '',
                                          apiId: '',
                                          required: true,
                                          title: {
                                              pl: 'Kolor',
                                              en: 'Colour',
                                              de: 'Farbe',
                                              fr: 'Couleur',
                                          },
                                          type: 'colors',
                                          items: bandsColors10.filter(
                                              (option) =>
                                                  filterOptionsFn(
                                                      option,
                                                      GUMKA_ZAMYKAJACA
                                                  )
                                          ),
                                      },
                                  ],
                              },
                              {
                                  apiId: '15_',
                                  title: {
                                      pl: '15 mm',
                                      en: '15 mm',
                                      de: '15 mm',
                                      fr: '15 mm',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'elasticColor',

                                          apiId: '',
                                          apiId: '',
                                          required: true,
                                          title: {
                                              pl: 'Kolor',
                                              en: 'Colour',
                                              de: 'Farbe',
                                              fr: 'Couleur',
                                          },
                                          type: 'colors',
                                          items: bandsColors15.filter(
                                              (option) =>
                                                  filterOptionsFn(
                                                      option,
                                                      GUMKA_ZAMYKAJACA
                                                  )
                                          ),
                                      },
                                  ],
                              },
                          ].filter((item) =>
                              filterOptionsFn(item, GUMKA_ZAMYKAJACA)
                          ),
                      },
                UCHWYT_NA_DLUGOPIS?.length === 0
                    ? null
                    : {
                          id: 'penHolder',
                          apiId: 'UCHWYT_NA_DLUGOPIS',
                          title: {
                              pl: 'Uchwyt na długopis',
                              en: 'Pen loop',
                              de: 'Stiftschlaufe',
                              fr: 'Passant stylo',
                          },
                          choices: [
                              {
                                  apiId: 'Y',
                                  title: {
                                      pl: 'Z uchwytem',
                                      en: 'With pen loop',
                                      de: 'Mit Stiftschlaufe',
                                      fr: 'avec boucle à stylo',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'penHolderColor',

                                          apiId: '',
                                          apiId: '',
                                          required: true,
                                          title: {
                                              pl: 'Kolor',
                                              en: 'Colour',
                                              de: 'Farbe',
                                              fr: 'Couleur',
                                          },
                                          type: 'colors',
                                          items: pensColors.filter((item) =>
                                              filterOptionsFn(
                                                  item,
                                                  UCHWYT_NA_DLUGOPIS
                                              )
                                          ),
                                      },
                                  ].filter((item) =>
                                      filterOptionsFn(item, UCHWYT_NA_DLUGOPIS)
                                  ),
                              },
                          ],
                      },
                TASIEMKA?.length === 0
                    ? null
                    : {
                          id: 'ribbon',
                          apiId: 'TASIEMKA',
                          required: true,
                          title: {
                              pl: 'Tasiemka',
                              en: 'Ribbon',
                              de: 'Lesezeichen',
                              fr: 'Ruban',
                          },
                          choices: [
                              {
                                  apiId: '',
                                  required: true,
                                  title: {
                                      pl: 'Z tasiemką',
                                      en: 'With ribbon',
                                      de: 'Mit Leseband',
                                      fr: 'avec ruban',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'ribbonColor',
                                          apiId: '',
                                          required: true,
                                          title: {
                                              pl: 'Kolor',
                                              en: 'Colour',
                                              de: 'Farbe',
                                              fr: 'Couleur',
                                          },
                                          type: 'colors',
                                          items: ribbonsColors.filter((item) =>
                                              filterOptionsFn(item, TASIEMKA)
                                          ),
                                      },
                                  ],
                              },
                          ],
                      },
                BANDEROLA?.length === 0
                    ? null
                    : {
                          id: 'streamer',
                          apiId: 'BANDEROLA',
                          title: {
                              pl: 'Banderola i obwoluta',
                              en: 'Paper belly band and wrapper',
                              de: 'Banderole und Hülle',
                              fr: 'Bandeau et jaquette',
                          },
                          choices: [
                              {
                                  apiId: 'BANDEROLA',
                                  title: {
                                      pl: 'Banderola wąska',
                                      en: 'Paper belly band narrow',
                                      de: 'Banderole - schmal',
                                      fr: 'Bandeau papier',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'wrapperPrint',
                                          apiId: 'BK',
                                          required: true,
                                          title: {
                                              pl: 'Rodzaj druku',
                                              en: 'Printing type',
                                              de: 'Druckart',
                                              fr: "Type d'impression",
                                          },
                                          items: [
                                              {
                                                  apiId: 'BK_10',
                                                  title: {
                                                      pl: '1 + 0',
                                                      en: '1 + 0',
                                                      de: '1 + 0',
                                                      fr: '1 + 0',
                                                  },
                                              },
                                              {
                                                  apiId: 'BK_40',
                                                  title: {
                                                      pl: 'CMYK + 0',
                                                      en: 'CMYK + 0',
                                                      de: 'CMYK + 0',
                                                      fr: 'CMYK + 0',
                                                  },
                                              },
                                          ].filter((item) =>
                                              filterOptionsFn(item, BANDEROLA)
                                          ),
                                      },
                                  ],
                              },
                              {
                                  apiId: 'BZ',
                                  title: {
                                      pl: 'Obwoluta wąska',
                                      en: 'Paper wrapper narrow',
                                      de: 'Banderole - breit',
                                      fr: 'Bandeau papier fourreau',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'wrapperPrint',
                                          apiId: '',
                                          title: {
                                              pl: 'Rodzaj druku',
                                              en: 'Printing type',
                                              de: 'Druckart',
                                              fr: "Type d'impression",
                                          },
                                          items: [
                                              {
                                                  apiId: 'BZ_10',
                                                  title: {
                                                      pl: '1 + 0',
                                                      en: '1 + 0',
                                                      de: '1 + 0',
                                                      fr: '1 + 0',
                                                  },
                                              },
                                              {
                                                  apiId: 'BZ_40',
                                                  title: {
                                                      pl: 'CMYK + 0',
                                                      en: 'CMYK + 0',
                                                      de: 'CMYK + 0',
                                                      fr: 'CMYK + 0',
                                                  },
                                              },
                                          ].filter((item) =>
                                              filterOptionsFn(item, BANDEROLA)
                                          ),
                                      },
                                  ],
                              },
                              {
                                  apiId: 'OK',
                                  title: {
                                      pl: 'Banderola szeroka',
                                      en: 'Paper belly band wide',
                                      de: 'Banderole - geschlossen',
                                      fr: 'Couvre-couverture',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'wrapperPrint',
                                          apiId: '',
                                          title: {
                                              pl: 'Rodzaj druku',
                                              en: 'Printing type',
                                              de: 'Druckart',
                                              fr: "Type d'impression",
                                          },
                                          items: [
                                              {
                                                  apiId: 'OK_10',
                                                  title: {
                                                      pl: '1 + 0',
                                                      en: '1 + 0',
                                                      de: '1 + 0',
                                                      fr: '1 + 0',
                                                  },
                                              },
                                              {
                                                  apiId: 'OK_40',
                                                  title: {
                                                      pl: 'CMYK + 0',
                                                      en: 'CMYK + 0',
                                                      de: 'CMYK + 0',
                                                      fr: 'CMYK + 0',
                                                  },
                                              },
                                          ].filter((item) =>
                                              filterOptionsFn(item, BANDEROLA)
                                          ),
                                      },
                                  ],
                              },
                              {
                                  apiId: 'OZ',
                                  title: {
                                      pl: 'Obwoluta szeroka',
                                      en: 'Paper wrapper wide',
                                      de: 'Papiereinschlag',
                                      fr: 'Etui papier fourreau',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'wrapperPrint',
                                          apiId: '',
                                          title: {
                                              pl: 'Rodzaj druku',
                                              en: 'Printing type',
                                              de: 'Druckart',
                                              fr: "Type d'impression",
                                          },
                                          items: [
                                              {
                                                  apiId: 'OZ_10',
                                                  title: {
                                                      pl: '1 + 0',
                                                      en: '1 + 0',
                                                      de: '1 + 0',
                                                      fr: '1 + 0',
                                                  },
                                              },
                                              {
                                                  apiId: 'OZ_40',
                                                  title: {
                                                      pl: 'CMYK + 0',
                                                      en: 'CMYK + 0',
                                                      de: 'CMYK + 0',
                                                      fr: 'CMYK + 0',
                                                  },
                                              },
                                          ].filter((item) =>
                                              filterOptionsFn(item, BANDEROLA)
                                          ),
                                      },
                                  ].filter((item) =>
                                      filterOptionsFn(item, BANDEROLA)
                                  ),
                              },
                          ].filter((item) => filterOptionsFn(item, BANDEROLA)),
                      },
                KIESZEN_KOLORY?.length === 0
                    ? null
                    : {
                          id: 'pocket',
                          apiId: 'KIESZEN_KOLORY',
                          isModal: true,
                          required: pocketIsRequired,
                          title: {
                              pl: 'Kieszonka na dokumenty',
                              en: 'Document pocket',
                              de: 'Tasche',
                              fr: 'Pochette documents',
                          },
                          choices: [
                              {
                                  apiId: 'B',
                                  title: {
                                      pl: 'Bez zadruku',
                                      en: 'Unprinted',
                                      de: 'Ohne Druck',
                                      fr: 'Sans impression',
                                  },
                              },
                              {
                                  apiId: 'J',
                                  title: {
                                      pl: 'Druk jednostronny',
                                      en: 'Single-sided printing',
                                      de: 'Einseitiger Druck',
                                      fr: 'Impression recto',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'singlePocketPrint',
                                          apiId: '',
                                          title: {
                                              pl: 'Druk',
                                              en: 'Printing',
                                              de: 'Druck',
                                              fr: 'Impression',
                                          },
                                          required: true,
                                          items: [
                                              {
                                                  apiId: '10',
                                                  title: {
                                                      pl: '1 + 0',
                                                      en: '1 + 0',
                                                      de: '1 + 0',
                                                      fr: '1 + 0',
                                                  },
                                              },
                                              {
                                                  apiId: '40',
                                                  title: {
                                                      pl: 'CMYK + 0',
                                                      en: 'CMYK + 0',
                                                      de: 'CMYK + 0',
                                                      fr: 'CMYK + 0',
                                                  },
                                              },
                                          ].filter((item) =>
                                              filterOptionsFn(
                                                  item,
                                                  KIESZEN_KOLORY
                                              )
                                          ),
                                      },
                                  ],
                              },
                              {
                                  apiId: 'D',
                                  title: {
                                      pl: 'Druk dwustronny',
                                      en: 'Two-sided printing',
                                      de: 'Beidseitiger Druck',
                                      fr: 'Impression recto verso',
                                  },
                                  childrenGroups: [
                                      {
                                          id: 'doublePocketPrint',
                                          apiId: '',
                                          title: {
                                              pl: 'Druk',
                                              en: 'Printing',
                                              de: 'Druck',
                                              fr: 'Impression',
                                          },
                                          required: true,
                                          items: [
                                              {
                                                  apiId: '11',
                                                  title: {
                                                      pl: '1 + 1',
                                                      en: '1 + 1',
                                                      de: '1 + 1',
                                                      fr: '1 + 1',
                                                  },
                                              },
                                              {
                                                  apiId: '44',
                                                  title: {
                                                      pl: 'CMYK + CMYK',
                                                      en: 'CMYK + CMYK',
                                                      de: 'CMYK + CMYK',
                                                      fr: 'CMYK + CMYK',
                                                  },
                                              },
                                          ].filter((item) =>
                                              filterOptionsFn(
                                                  item,
                                                  KIESZEN_KOLORY
                                              )
                                          ),
                                      },
                                  ],
                              },
                          ],
                      },
            ].filter((el) => el)
        },
    },
}
</script>
