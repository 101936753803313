<template>
    <div class="page-paper">
        <h1 class="page-title">
            {{ $t('paper.title') }}
        </h1>
        <div class="page-paper-row">
            <div class="page-paper-checkboxes">
                <InputSimpleCheckbox
                    v-if="!onlyPaper"
                    :is-checked="coverType === 'okleina'"
                    :label="$t('paper.okleina')"
                    @click="setCoverType('okleina')"
                />
                <InputSimpleCheckbox
                    v-if="!onlyOkleina"
                    :is-checked="coverType === 'papier'"
                    :label="$t('paper.papier')"
                    @click="setCoverType('papier')"
                />
            </div>
            <div class="page-paper-searchbox">
                <form
                    class="page-paper-search page-paper-search--desktop"
                    @submit.prevent
                >
                    <InputSearch
                        ref="search"
                        :placeholder="$t('paper.search2')"
                        @input="search"
                    />
                    <InputSearchTips
                        ref="tips"
                        :patterns="patterns"
                        :cover-type="coverType"
                        @select="
                            (val) => {
                                $refs.search.value = val
                            }
                        "
                    />
                </form>
                <div class="page-paper-more" @click="showMore()" />
            </div>
        </div>
        <FilterPaper
            ref="filters"
            :category-title="categoryTitle[locale]"
            :color-title="colorTitle[locale]"
            :cover-type="coverType"
            :pattern-colors="patternColors"
            :pattern-categories="patternCategories"
            :patterns="patterns"
        />
        <ListPatterns
            :patterns="patterns[coverType]"
            :groups="patternGroups"
            :colors="patternColors"
            :cover-type="coverType"
            @select="checkValidation"
        />
    </div>
</template>

<script>
import FilterPaper from '@/components/filter/FilterPaper'
import InputSimpleCheckbox from '@/components/input/InputSimpleCheckbox'
import InputSearch from '@/components/input/InputSearch'
import InputSearchTips from '@/components/input/InputSearchTips'
import ListPatterns from '@/components/list/ListPatterns'
import PaperData from '@/mixins/pages/PaperData'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    components: {
        FilterPaper,
        InputSearch,
        InputSearchTips,
        InputSimpleCheckbox,
        ListPatterns,
    },
    mixins: [PaperData],
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
            locale: (state) => state.i18n.locale,
            searchPhrase: (state) => state.filters.search,
            coverColorType: (state) => state.notes.configuration.coverColorType,
            coverColorPattern: (state) =>
                state.notes.configuration.coverColorPattern,
            list: (state) => state.dependencies.list,
        }),
        isPatternSelected() {
            return (
                this.configuration.coverColorPattern &&
                this.configuration.coverColorPattern.name
            )
        },

        onlyOkleina() {
            let onlyIntro = false
            this.list.forEach((element) => {
                if (element.actions.OKLEINA_TYP?.includes('I')) {
                    onlyIntro = true
                }
            })
            return onlyIntro
        },
        onlyPaper() {
            let onlyPaper = false
            this.list.forEach((element) => {
                if (element.actions.OKLEINA_TYP?.includes('P')) {
                    onlyPaper = true
                }
            })
            return onlyPaper
        },
    },
    mounted() {
        if (this.onlyPaper) {
            this.coverType = 'papier'
        } else if (this.onlyOkleina) {
            this.coverType = 'okleina'
        } else {
            this.coverColorType ? (this.coverType = this.coverColorType) : null
        }

        this.setNotesConfig({
            param: 'coverColorType',
            value: this.coverType,
        })
        this.setNextPageLocked(!this.validate())
    },
    beforeDestroy() {
        this.setSearch('')
    },
    methods: {
        ...mapMutations([
            'setNextPageLocked',
            'setSearch',
            'openModal',
            'setModalComponentId'
        ]),
        ...mapActions(['setNotesConfig']),
        search(event) {
            this.setSearch(event)
            this.$refs.tips.isModalOpen = true
        },
        clearColorPattern() {
            this.setNotesConfig({ param: 'coverColorPattern', value: '' })
        },
        setCoverType(type) {
            this.setSearch('')
            this.$refs.search.value = ''
            this.$refs.filters.setCategory(null)
            this.$refs.filters.setColor(null)
            this.setNotesConfig({ param: 'coverColorType', value: type })
            this.clearColorPattern()

            this.coverType = type
        },
        checkValidation() {
            this.setNextPageLocked(!this.validate())
        },
        validate() {
            return !!this.coverColorPattern
        },
        showMore() {
            let coverType = null;

            switch (this.configuration.coverType.apiId) {
                case "1":
                    coverType = 'paperTypeSoft';
                    break;
                case "2":
                    coverType = 'paperTypeSoftIntro';
                    break;
                case "3":
                    coverType = 'paperTypeHardVeneer';
                    break;
                case "4":
                    coverType = 'paperTypeHardElastic';
                    break;
            }

            if (coverType) {
                console.log(coverType)
                this.openModal(true)
                this.setModalComponentId(coverType)
            }
        }
    },
}
</script>

<style lang="scss">
.page-paper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid $color-silver;
    }

    &-searchbox{
        display: flex;
        gap: 10px;
        align-items: flex-start;
    }

    &-checkboxes {
        display: flex;
        flex-flow: row nowrap;
        padding-bottom: 30px;
    }

    &-search {
        position: relative;
        border: 1px solid $color-silver;
        padding: 15px 30px;
        margin-bottom: 27px;
        margin-right: 2px;
    }
    &-more {
        background: url('/img/info.svg');
        background-size: contain;
        opacity: 1;
        cursor: pointer;
        pointer-events: auto;
        transition: opacity 0.3s;
        margin-bottom: 27px;
        @include size(52px);
    }

    @media (max-width: $screen-large) {
        &-search {
            padding: 10px 15px;
        }
        &-more {
            @include size(42px);
        }
    }
    @media (max-width: $screen-medium) {
        &-search {
            &--desktop {
                display: none;
            }
            &-form-smaller {
                .input-text-input--active {
                    width: 210px;
                }
            }
        }
    }

    @media (max-width: $screen-tablet-medium) {
        margin-right: 0;

        &-more {
            display: none;
        }

        &-checkboxes {
            margin: 0 auto;
        }

        &-row {
            border-bottom: 0;
        }
    }

    @media (max-width: $screen-mobile-large) {
        &-row {
            padding: 20px;
            position: fixed;
            top: 60px;
            z-index: 6;
            background-color: $color-white;
            width: 100%;
        }

        &-checkboxes {
            margin: 0;
            padding: 0;
        }
    }
}
</style>
