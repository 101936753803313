<template>
    <div class="summary-group">
        <div
            v-for="item in configItems"
            :key="item.name"
            class="summary-group-item"
        >
            <div
                class="summary-group-item-column"
                v-text="item.title[locale]"
            />
            <div
                class="summary-group-item-column"
                v-text="parseConfiguration(configuration[item.name])"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'SummaryConfigGroup',
    props: {
        possibleOptions: {
            type: Array,
            default: () => [],
        },
        configuration: {
            type: Object,
            default: () => null,
        },
    },
    computed: {
        ...mapState({
            locale: (state) => state.i18n.locale,
        }),
        configItems() {
            return this.possibleOptions.filter(
                (item) => this.configuration[item.name]
            )
        },
    },
    methods: {
        parseConfiguration(configuration) {
            if (configuration && typeof configuration === 'object') {
                if (configuration.title) {
                    return configuration.title[this.locale]
                } else {
                    let title = '',
                        configKeys = Object.keys(configuration)
                    for (let index in configKeys) {
                        title +=
                            configuration[configKeys[index]].title[this.locale]
                        if (index < configKeys.length - 1) {
                            title += ', '
                        }
                    }
                    return title
                }
            } else {
                return configuration
            }
        },
    },
}
</script>

<style lang="scss">
.summary-group {
    &-item {
        padding: 12px 0;
        border-bottom: $color-silver2 solid 1px;
        font-size: 16px;
        display: flex;

        &-column {
            width: 50%;
            padding-right: 15px;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        &-item {
            padding: 13px 18px;
            color: $color-nobel;
            border-color: $color-silver;

            &-column {
                &:last-child {
                    color: $color-default-text;
                }
            }
        }
    }
}
</style>
