<template>
    <div
        v-if="storedValue"
        class="input-default-container"
        @click="$emit('edit')"
    >
        <div class="input-mark checked" />
        <div class="input-default-value" v-text="value.title[locale]" />
    </div>
    <form v-else class="input-default-container" @submit.prevent="add">
        <div
            v-if="placeholder"
            class="input-default-placeholder"
            v-text="placeholder"
        />
        <input
            ref="input"
            :type="type"
            :name="id"
            @focus="focus = true"
            @blur="focus = false"
            @input="onInput"
        />
        <button type="submit">
            {{ $t('form.add') }}
        </button>
    </form>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'

export default {
    props: {
        storedValue: {
            type: [String, Object],
            default: null,
        },
        value: {
            type: [String, Object],
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            childrenSettings: null,
        }
    },
    computed: {
        ...mapState({
            locale: (state) => state.i18n.locale,
        }),
    },
    methods: {
        onInput: debounce(function (event) {
            let number = event.target.value,
                label = ''

            if (number) {
                number = parseInt(number)
                if (number > 10) {
                    number = 10
                } else if (number < 1) {
                    number = 1
                }

                if (number === 1) {
                    label = this.$t('general.page')
                } else if (number > 4) {
                    label = this.$t('general.page2')
                } else {
                    label = this.$t('general.pages')
                }
            }

            this.$refs.input.value = number
            this.$emit('changeVal', {
                title: {
                    pl: number + label,
                    en: number + label,
                    fr: number + label,
                    de: number + label,
                },
            })
        }, 100),
        add() {
            this.$emit('input', {
                value: this.value,
                lockOption: !!this.childrenGroups,
            })
        },
    },
}
</script>

<style lang="scss">
.input-default {
    &-container {
        position: relative;
        padding: 17px 14px 14px;
        display: flex;

        input {
            border: $color-silver solid 1px;
            padding: 10px;
            width: 100%;
        }
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        button {
            position: relative;
            left: -1px;
            border: none;
            background: $color-burnt-sienna;
            color: $color-white;
            height: 50px;
            min-width: 88px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background: $color-burnt-sienna-light;
            }
        }
    }

    &-placeholder {
        position: absolute;
        top: 33px;
        left: 30px;
        pointer-events: none;
        transition: all 0.2s;
    }

    &-value {
        font-size: 16px;
        max-width: calc(100% - 40px);
        padding-left: 15px;
    }

    &.is-set & {
        &-container {
            padding: 17px 14px 14px 27px;
            align-items: center;
            cursor: pointer;
        }
    }

    &.is-focused &,
    &.is-filled & {
        &-placeholder {
            font-size: 1rem;
            transform: translate(-14px, -26px);
        }
    }

    @media (max-width: $screen-mobile-large) {
        &-container {
            padding: 11px 0;
        }

        &-placeholder {
            font-size: 1.6rem;
            top: 28px;
            left: 22px;
        }
        &.is-focused &,
        &.is-filled & {
            &-placeholder {
                transform: translate(-15px, -28px);
            }
        }
    }
}
</style>
