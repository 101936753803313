<template>
    <!-- eslint-disable -->
    <g :transform="options[format].transform">
        <g id="Group_1003" data-name="Group 1003" transform="translate(307 5)">
            <line
                id="Line_206"
                data-name="Line 206"
                :class="{
                    'paper-perforation-standard':
                        perforationType === 'Standardowa',
                    'paper-perforation-micro':
                        perforationType === 'Mikroperforacja',
                }"
                :y2="options[format].height"
            />
        </g>
        <g id="Group_1003" data-name="Group 1003" transform="translate(333 5)">
            <line
                id="Line_206"
                data-name="Line 206"
                :class="{
                    'paper-perforation-standard':
                        perforationType === 'Standardowa',
                    'paper-perforation-micro':
                        perforationType === 'Mikroperforacja',
                }"
                :y2="options[format].height"
            />
        </g>
    </g>
</template>

<script>
export default {
    props: {
        perforationType: {
            type: String,
            default: 'Standardowa',
        },
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            options: {
                vertical: {
                    height: '451.595',
                    transform: 'translate(0 0)',
                },
                horizontal: {
                    height: '182.922',
                    transform: 'translate(-7 0)',
                },
                square: {
                    height: '263',
                    transform: 'translate(-57 0)',
                },
            },
        }
    },
}
</script>

<style>
.paper-perforation-standard {
    fill: none;
    stroke: #a2a2a2;
    stroke-dasharray: 3 3;
}
.paper-perforation-micro {
    fill: none;
    stroke: #a2a2a2;
    stroke-dasharray: 1 1;
}
</style>
