export default {
    isIE() {
        let myNav = navigator.userAgent.toLowerCase()

        return myNav.indexOf('msie') != -1
            ? parseInt(myNav.split('msie')[1])
            : false
    },
    isIE11() {
        return !!window.MSInputMethodContext && !!document.documentMode
    },
}
