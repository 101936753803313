<template>
    <ImageLazy
        :key="image.imageUrl"
        :image-url="image.imageUrl"
        :height="image.height"
        :has-loader="!image.isLoaderHidden"
        :alt="alt"
        :is-background="isBackground"
        :keep-background-ratio="image.keepBackgroundRatio || false"
        :delay="delay"
        @ready="onReady"
    >
        <slot />
    </ImageLazy>
</template>

<script>
import debounce from 'lodash/debounce'
import { WindowHelper } from '@/helper/WindowHelper'

export default {
    props: {
        imageDefault: {
            type: Object,
            required: true,
            validator: (value) => {
                return (
                    value.hasOwnProperty('imageUrl') &&
                    value.hasOwnProperty('height')
                )
            },
        },
        images: {
            type: Array,
            required: false,
            default: () => [],
            validator: (value) => {
                return (
                    value.filter(
                        (item) =>
                            item.hasOwnProperty('imageUrl') &&
                            item.hasOwnProperty('height') &&
                            item.hasOwnProperty('displayTo')
                    ).length === value.length
                )
            },
        },
        alt: {
            type: String,
            required: false,
            default: '',
        },
        isBackground: {
            type: Boolean,
            required: false,
            default: false,
        },
        delay: {
            type: Number,
            required: false,
            default: 0,
        },
    },

    data() {
        return {
            image: this.imageDefault,
            debounceOnWindowResize: null,
        }
    },

    created() {
        // Debounce function has to be defined here, otherwise
        // it will attach only to one component even if more will be included
        this.debounceOnWindowResize = debounce(function () {
            this.selectImageByViewportSize(window.innerWidth)
        }, 100)
    },

    mounted() {
        this.$nextTick(() => {
            this.selectImageByViewportSize(WindowHelper.getWidth())
        })

        window.addEventListener('resize', this.onWindowResize)
    },

    destroyed() {
        window.removeEventListener('resize', this.onWindowResize)
    },

    methods: {
        onWindowResize() {
            return this.debounceOnWindowResize()
        },

        selectImageByViewportSize(viewportSize) {
            // idea: this.images.getOnesMatchingViewport().sortByViewport(imageMatches, 'ASC').getFirst()

            const previousImageUrl = this.image.imageUrl

            const imageMatches = this.getImagesMatchingViewport(
                this.images,
                viewportSize
            )

            this.image =
                imageMatches.length > 0
                    ? this.getImageWithSmallestViewport(imageMatches)
                    : this.imageDefault

            if (previousImageUrl != this.image.imageUrl) {
                this.$emit('changeSize', this.image)
            }
        },

        getImagesMatchingViewport(images, viewportSize) {
            return this.images.filter((v) => {
                v.displayTo = v.displayTo ? v.displayTo : 9999

                return v.displayTo >= viewportSize
            })
        },

        getImageWithSmallestViewport(images) {
            return images.reduce((previous, current) =>
                previous.displayTo < current.displayTo ? previous : current
            )
        },

        onReady(isSuccess, e) {
            this.$emit('ready', isSuccess, e)
        },
    },
}
</script>
