<script>
import BasePageMixin from '@/mixins/BasePageMixin'
import NotesImage from '@/components/notes/NotesCover'
import CoverMarkingData from '@/mixins/pages/CoverMarkingData'
import { mapState } from 'vuex'

export default {
    components: {
        NotesImage,
    },
    extends: BasePageMixin,
    mixins: [CoverMarkingData],
    computed: {
        ...mapState({
            markingCoverFinish: (state) =>
                state.notes.configuration.markingCoverFinish,
            markingExpression: (state) =>
                state.notes.configuration.markingExpression,
            markingPrint: (state) => state.notes.configuration.markingPrint,
            coverColorPattern: (state) =>
                state.notes.configuration.coverColorPattern?.apiId,
        }),
    },
    methods: {
        validate() {
            const markingExpression = this.markingExpression
                ? this.markingExpression?.general
                    ? this.markingExpression.color
                    : true
                : false
            const markingPrint = this.markingPrint
                ? this.markingPrint?.general
                    ? this.markingPrint.singleMarkingPrint ||
                      this.markingPrint.doubleMarkingPrint
                    : true
                : false

            const coverFinishRequired =
                this.coverColorPattern === '250PSBS' ||
                this.coverColorPattern === '150PCW'

            const depRequired = coverFinishRequired
                ? this.markingCoverFinish
                : true

            return (
                depRequired &&
                (this.markingCoverFinish || markingExpression || markingPrint)
            )
        },
    },
}
</script>
