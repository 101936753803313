<template>
    <!-- eslint-disable -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="svg[formatGroup].width"
        :height="svg[formatGroup].height"
        :viewBox="svg[formatGroup].viewBox"
        class="notes-edge notes-edge-zoom"
    >
        <defs>
            <filter
                id="shadow_vector"
                x="0"
                y="0"
                :width="svg[formatGroup].width"
                :height="svg[formatGroup].height"
                filterUnits="userSpaceOnUse"
            >
                <feOffset dx="5" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="13" result="blur" />
                <feFlood flood-opacity="0.388" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <linearGradient
                id="notes-edge-linear-gradient-2"
                x1="0.017"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" stop-opacity="0.341" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="notes-edge-linear-gradient-4"
                x1="0.017"
                x2="1.371"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-opacity="0.341" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="notes-edge-linear-gradient-5"
                x1="0.983"
                x2="-0.371"
                xlink:href="#notes-edge-linear-gradient-4"
            />
            <PaperPattern :background="background" />
        </defs>

        <g
            id="Group_1192"
            data-name="Group 1192"
            :transform="svg[formatGroup].Group_1192Translate"
        >
            <g
                v-if="formatGroup === 'vertical'"
                class="notes-edge-12"
                transform="matrix(1, 0, 0, 1, 852.5, 237.5)"
            >
                <path
                    id="shadow_vector-2"
                    data-name="shadow_vector"
                    d="M0,0H36A12,12,0,0,1,48,12V512a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                    transform="translate(17.5 43.5) scale(0.8 1)"
                />
            </g>
            <g
                v-if="formatGroup === 'horizontal'"
                class="notes-edge-12"
                transform="matrix(1, 0, 0, 1, 849.5, 233.5)"
            >
                <path
                    id="shadow_vector-2"
                    data-name="shadow_vector"
                    d="M0,0H20A12,12,0,0,1,32,12V171a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                    transform="translate(19 43.5) scale(0.75 1)"
                />
            </g>
            <g
                v-if="formatGroup === 'square'"
                class="notes-edge-12"
                transform="matrix(1, 0, 0, 1, -6.5, -31.5)"
            >
                <path
                    id="shadow_vector-2"
                    data-name="shadow_vector"
                    d="M0,0H27A12,12,0,0,1,39,12V231a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                    transform="translate(18 46) scale(0.7 1)"
                />
            </g>
            <g
                id="Group_815"
                data-name="Group 815"
                :transform="svg[formatGroup].Group_815Translate"
            >
                <g
                    v-if="formatGroup === 'vertical'"
                    id="Group_812"
                    data-name="Group 812"
                    transform="translate(555.32 -68.5)"
                >
                    <line
                        id="Line_147"
                        data-name="Line 147"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(158.5 345.5)"
                    />
                    <line
                        id="Line_139"
                        data-name="Line 139"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(122.5 345.5)"
                    />
                    <line
                        id="Line_140"
                        data-name="Line 140"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(125.5 345.5)"
                    />
                    <line
                        id="Line_141"
                        data-name="Line 141"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(128.5 345.5)"
                    />
                    <line
                        id="Line_142"
                        data-name="Line 142"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(131.5 345.5)"
                    />
                    <line
                        id="Line_143"
                        data-name="Line 143"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(134.5 345.5)"
                    />
                    <line
                        id="Line_144"
                        data-name="Line 144"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(137.5 345.5)"
                    />
                    <line
                        id="Line_145"
                        data-name="Line 145"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(146.5 345.5)"
                    />
                    <line
                        id="Line_146"
                        data-name="Line 146"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(149.5 345.5)"
                    />
                    <line
                        id="Line_142-2"
                        data-name="Line 142"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(143.5 345.5)"
                    />
                    <line
                        id="Line_141-2"
                        data-name="Line 141"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(140.5 345.5)"
                    />
                    <line
                        id="Line_148"
                        data-name="Line 148"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(161.5 345.5)"
                    />
                    <line
                        id="Line_149"
                        data-name="Line 149"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(152.5 345.5)"
                    />
                    <line
                        id="Line_150"
                        data-name="Line 150"
                        class="notes-edge-1"
                        y2="530.125"
                        transform="translate(155.5 345.5)"
                    />
                </g>
                <g
                    v-if="formatGroup === 'horizontal'"
                    id="Group_812"
                    data-name="Group 812"
                    transform="translate(5.922 5.371)"
                >
                    <line
                        id="Line_147"
                        data-name="Line 147"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(24.172)"
                    />
                    <line
                        id="Line_139"
                        data-name="Line 139"
                        class="notes-edge-1"
                        y2="186.61"
                    />
                    <line
                        id="Line_140"
                        data-name="Line 140"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(2.014)"
                    />
                    <line
                        id="Line_141"
                        data-name="Line 141"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(4.029)"
                    />
                    <line
                        id="Line_142"
                        data-name="Line 142"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(6.043)"
                    />
                    <line
                        id="Line_143"
                        data-name="Line 143"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(8.057)"
                    />
                    <line
                        id="Line_144"
                        data-name="Line 144"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(10.072)"
                    />
                    <line
                        id="Line_145"
                        data-name="Line 145"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(16.114)"
                    />
                    <line
                        id="Line_146"
                        data-name="Line 146"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(18.129)"
                    />
                    <line
                        id="Line_142-2"
                        data-name="Line 142"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(14.1)"
                    />
                    <line
                        id="Line_141-2"
                        data-name="Line 141"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(12.086)"
                    />
                    <line
                        id="Line_148"
                        data-name="Line 148"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(26.186)"
                    />
                    <line
                        id="Line_149"
                        data-name="Line 149"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(20.143)"
                    />
                    <line
                        id="Line_150"
                        data-name="Line 150"
                        class="notes-edge-1"
                        y2="186.61"
                        transform="translate(22.157)"
                    />
                </g>
                <g
                    v-if="formatGroup === 'square'"
                    id="Group_812"
                    data-name="Group 812"
                    transform="translate(7.144 8)"
                >
                    <line
                        id="Line_147"
                        data-name="Line 147"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(29.159)"
                    />
                    <line
                        id="Line_139"
                        data-name="Line 139"
                        class="notes-edge-1"
                        y2="249.015"
                    />
                    <line
                        id="Line_140"
                        data-name="Line 140"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(2.43)"
                    />
                    <line
                        id="Line_141"
                        data-name="Line 141"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(4.86)"
                    />
                    <line
                        id="Line_142"
                        data-name="Line 142"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(7.29)"
                    />
                    <line
                        id="Line_143"
                        data-name="Line 143"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(9.72)"
                    />
                    <line
                        id="Line_144"
                        data-name="Line 144"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(12.15)"
                    />
                    <line
                        id="Line_145"
                        data-name="Line 145"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(19.44)"
                    />
                    <line
                        id="Line_146"
                        data-name="Line 146"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(21.869)"
                    />
                    <line
                        id="Line_142-2"
                        data-name="Line 142"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(17.01)"
                    />
                    <line
                        id="Line_141-2"
                        data-name="Line 141"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(14.58)"
                    />
                    <line
                        id="Line_148"
                        data-name="Line 148"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(31.589)"
                    />
                    <line
                        id="Line_149"
                        data-name="Line 149"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(24.299)"
                    />
                    <line
                        id="Line_150"
                        data-name="Line 150"
                        class="notes-edge-1"
                        y2="249.015"
                        transform="translate(26.729)"
                    />
                </g>
                <g id="Group_814" data-name="Group 814">
                    <EdgeColor
                        :edge-color="edgeColor"
                        :default-color="paperColor"
                        :format="formatGroup"
                    />
                    <EdgeFoil
                        v-if="edgeType === 'Folia metaliczna' && edgeFoil"
                        :edge-color="edgeFoil"
                        :format="formatGroup"
                    />
                    <EdgeGraphics
                        v-else-if="edgeType === 'Druk'"
                        :format="formatGroup"
                    />
                    <!-- default color is inside EdgeColor
                    (client no logner wants colors in configurator, only foil and graphics) -->

                    <g v-if="formatGroup === 'vertical'">
                        <g
                            v-if="showEdge"
                            id="_1"
                            data-name="1"
                            class="notes-edge-3"
                            :transform="
                                coverSoft
                                    ? 'translate(672 276)'
                                    : 'translate(669 269)'
                            "
                            :fill="
                                background ? `url(#cover)` : defaultBackground
                            "
                        >
                            <rect
                                class="notes-edge-10"
                                :width="coverSoft ? 3 : 7.72"
                                :height="coverSoft ? 532 : 544.11"
                            />
                            <rect
                                class="notes-edge-11"
                                x="0.5"
                                y="0.5"
                                :width="coverSoft ? 2.5 : 6.72"
                                :height="coverSoft ? 531 : 543.11"
                            />
                        </g>
                        <g
                            v-if="showEdge"
                            id="_2"
                            data-name="2"
                            class="notes-edge-3"
                            :transform="
                                coverSoft
                                    ? 'translate(718 276)'
                                    : 'translate(718 269)'
                            "
                            :fill="
                                background ? `url(#cover)` : defaultBackground
                            "
                        >
                            <rect
                                class="notes-edge-10"
                                :width="coverSoft ? 3 : 7.72"
                                :height="coverSoft ? 532 : 544.11"
                            />
                            <rect
                                class="notes-edge-11"
                                x="0.5"
                                y="0.5"
                                :width="coverSoft ? 2.5 : 6.72"
                                :height="coverSoft ? 531 : 543.11"
                            />
                        </g>
                        <rect
                            id="Rectangle_1348"
                            data-name="Rectangle 1348"
                            class="notes-edge-4"
                            width="6"
                            height="543"
                            transform="translate(670.581 270)"
                        />
                        <rect
                            id="Rectangle_1349"
                            data-name="Rectangle 1349"
                            class="notes-edge-5"
                            width="2"
                            height="543"
                            transform="translate(671.581 270)"
                        />
                        <rect
                            id="Rectangle_1514"
                            data-name="Rectangle 1514"
                            class="notes-edge-4"
                            width="6"
                            height="543"
                            transform="translate(717.581 270)"
                        />
                        <rect
                            id="Rectangle_1515"
                            data-name="Rectangle 1515"
                            class="notes-edge-5"
                            width="2"
                            height="543"
                            transform="translate(718.581 270)"
                        />
                        <g
                            id="Group_813"
                            data-name="Group 813"
                            transform="translate(553.32 -68.5)"
                        >
                            <line
                                id="Line_142-3"
                                data-name="Line 142"
                                class="notes-edge-6"
                                y2="530.125"
                                transform="translate(131.5 345.5)"
                            />
                            <line
                                id="Line_143-2"
                                data-name="Line 143"
                                class="notes-edge-7"
                                y2="530.125"
                                transform="translate(134.5 345.5)"
                            />
                            <line
                                id="Line_144-2"
                                data-name="Line 144"
                                class="notes-edge-7"
                                y2="530.125"
                                transform="translate(137.5 345.5)"
                            />
                            <line
                                id="Line_145-2"
                                data-name="Line 145"
                                class="notes-edge-7"
                                y2="530.125"
                                transform="translate(146.5 345.5)"
                            />
                            <line
                                id="Line_146-2"
                                data-name="Line 146"
                                class="notes-edge-7"
                                y2="530.125"
                                transform="translate(149.5 345.5)"
                            />
                            <line
                                id="Line_142-4"
                                data-name="Line 142"
                                class="notes-edge-7"
                                y2="530.125"
                                transform="translate(143.5 345.5)"
                            />
                            <line
                                id="Line_141-3"
                                data-name="Line 141"
                                class="notes-edge-7"
                                y2="530.125"
                                transform="translate(140.5 345.5)"
                            />
                            <line
                                id="Line_149-2"
                                data-name="Line 149"
                                class="notes-edge-7"
                                y2="530.125"
                                transform="translate(152.5 345.5)"
                            />
                            <line
                                id="Line_150-2"
                                data-name="Line 150"
                                class="notes-edge-6"
                                y2="530.125"
                                transform="translate(155.5 345.5)"
                            />
                        </g>
                    </g>
                    <g v-if="formatGroup === 'horizontal'">
                        <g
                            v-if="showEdge"
                            id="_1"
                            data-name="1"
                            class="notes-edge-3"
                            :transform="coverSoft ? 'translate(3 5)' : ''"
                            :fill="
                                background ? `url(#cover)` : defaultBackground
                            "
                        >
                            <rect
                                class="notes-edge-10"
                                :width="coverSoft ? 2 : 5.183"
                                :height="coverSoft ? 187 : 196"
                            />
                            <rect
                                class="notes-edge-11"
                                x="0.5"
                                y="0.5"
                                :width="coverSoft ? 1 : 4.183"
                                :height="coverSoft ? 186 : 195"
                            />
                        </g>
                        <g
                            v-if="showEdge"
                            id="_2"
                            data-name="2"
                            class="notes-edge-3"
                            :transform="
                                coverSoft
                                    ? 'translate(32.9 5)'
                                    : 'translate(32.9)'
                            "
                            :fill="
                                background ? `url(#cover)` : defaultBackground
                            "
                        >
                            <rect
                                class="notes-edge-10"
                                :width="coverSoft ? 2 : 5.183"
                                :height="coverSoft ? 186 : 196"
                            />
                            <rect
                                class="notes-edge-11"
                                x="0.5"
                                y="0.5"
                                :width="coverSoft ? 1 : 4.183"
                                :height="coverSoft ? 186 : 195"
                            />
                        </g>
                        <rect
                            id="Rectangle_1348"
                            data-name="Rectangle 1348"
                            class="notes-edge-4"
                            width="4.029"
                            height="195.255"
                            transform="translate(1.061 0.671)"
                        />
                        <rect
                            id="Rectangle_1349"
                            data-name="Rectangle 1349"
                            class="notes-edge-5"
                            width="1.343"
                            height="195.255"
                            transform="translate(1.733 0.671)"
                        />
                        <rect
                            id="Rectangle_1514"
                            data-name="Rectangle 1514"
                            class="notes-edge-4"
                            width="4.029"
                            height="195.255"
                            transform="translate(32.619 0.671)"
                        />
                        <rect
                            id="Rectangle_1515"
                            data-name="Rectangle 1515"
                            class="notes-edge-5"
                            width="1.343"
                            height="195.255"
                            transform="translate(33.29 0.671)"
                        />
                        <g
                            id="Group_813"
                            data-name="Group 813"
                            transform="translate(10.622 5.371)"
                        >
                            <line
                                id="Line_142-3"
                                data-name="Line 142"
                                class="notes-edge-6"
                                y2="186.61"
                            />
                            <line
                                id="Line_143-2"
                                data-name="Line 143"
                                class="notes-edge-7"
                                y2="186.61"
                                transform="translate(2.014)"
                            />
                            <line
                                id="Line_144-2"
                                data-name="Line 144"
                                class="notes-edge-7"
                                y2="186.61"
                                transform="translate(4.029)"
                            />
                            <line
                                id="Line_145-2"
                                data-name="Line 145"
                                class="notes-edge-7"
                                y2="186.61"
                                transform="translate(10.072)"
                            />
                            <line
                                id="Line_146-2"
                                data-name="Line 146"
                                class="notes-edge-7"
                                y2="186.61"
                                transform="translate(12.086)"
                            />
                            <line
                                id="Line_142-4"
                                data-name="Line 142"
                                class="notes-edge-7"
                                y2="186.61"
                                transform="translate(8.057)"
                            />
                            <line
                                id="Line_141-3"
                                data-name="Line 141"
                                class="notes-edge-7"
                                y2="186.61"
                                transform="translate(6.043)"
                            />
                            <line
                                id="Line_149-2"
                                data-name="Line 149"
                                class="notes-edge-7"
                                y2="186.61"
                                transform="translate(14.1)"
                            />
                            <line
                                id="Line_150-2"
                                data-name="Line 150"
                                class="notes-edge-6"
                                y2="186.61"
                                transform="translate(16.114)"
                            />
                        </g>
                    </g>
                    <g v-if="formatGroup === 'square'">
                        <g
                            v-if="showEdge"
                            id="_1"
                            data-name="1"
                            class="notes-edge-3"
                            :transform="
                                coverSoft ? 'translate(5 7)' : 'translate(0)'
                            "
                            :fill="
                                background ? `url(#cover)` : defaultBackground
                            "
                        >
                            <rect
                                class="notes-edge-10"
                                :width="coverSoft ? 2 : 6.253"
                                :height="coverSoft ? 250 : 263"
                            />
                            <rect
                                class="notes-edge-11"
                                x="0.5"
                                y="0.5"
                                :width="coverSoft ? 1 : 5.253"
                                :height="coverSoft ? 249 : 262"
                            />
                        </g>
                        <g
                            v-if="showEdge"
                            id="_2"
                            data-name="2"
                            class="notes-edge-3"
                            :transform="
                                coverSoft
                                    ? 'translate(39.689 7)'
                                    : 'translate(39.689)'
                            "
                            :fill="
                                background ? `url(#cover)` : defaultBackground
                            "
                        >
                            <rect
                                class="notes-edge-10"
                                :width="coverSoft ? 2 : 6.253"
                                :height="coverSoft ? 249 : 263"
                            />
                            <rect
                                class="notes-edge-11"
                                x="0.5"
                                y="0.5"
                                :width="coverSoft ? 1 : 5.253"
                                :height="coverSoft ? 250 : 262"
                            />
                        </g>
                        <rect
                            id="Rectangle_1348"
                            data-name="Rectangle 1348"
                            class="notes-edge-4"
                            width="4.86"
                            height="261.89"
                            transform="translate(1.281 1)"
                        />
                        <rect
                            id="Rectangle_1349"
                            data-name="Rectangle 1349"
                            class="notes-edge-5"
                            width="1.62"
                            height="261.89"
                            transform="translate(2.091 1)"
                        />
                        <rect
                            id="Rectangle_1514"
                            data-name="Rectangle 1514"
                            class="notes-edge-4"
                            width="4.86"
                            height="261.89"
                            transform="translate(39.35 1)"
                        />
                        <rect
                            id="Rectangle_1515"
                            data-name="Rectangle 1515"
                            class="notes-edge-5"
                            width="1.62"
                            height="261.89"
                            transform="translate(40.16 1)"
                        />
                        <g
                            id="Group_813"
                            data-name="Group 813"
                            transform="translate(12.814 8)"
                        >
                            <line
                                id="Line_142-3"
                                data-name="Line 142"
                                class="notes-edge-6"
                                y2="249.015"
                            />
                            <line
                                id="Line_143-2"
                                data-name="Line 143"
                                class="notes-edge-7"
                                y2="249.015"
                                transform="translate(2.43)"
                            />
                            <line
                                id="Line_144-2"
                                data-name="Line 144"
                                class="notes-edge-7"
                                y2="249.015"
                                transform="translate(4.86)"
                            />
                            <line
                                id="Line_145-2"
                                data-name="Line 145"
                                class="notes-edge-7"
                                y2="249.015"
                                transform="translate(12.15)"
                            />
                            <line
                                id="Line_146-2"
                                data-name="Line 146"
                                class="notes-edge-7"
                                y2="249.015"
                                transform="translate(14.58)"
                            />
                            <line
                                id="Line_142-4"
                                data-name="Line 142"
                                class="notes-edge-7"
                                y2="249.015"
                                transform="translate(9.72)"
                            />
                            <line
                                id="Line_141-3"
                                data-name="Line 141"
                                class="notes-edge-7"
                                y2="249.015"
                                transform="translate(7.29)"
                            />
                            <line
                                id="Line_149-2"
                                data-name="Line 149"
                                class="notes-edge-7"
                                y2="249.015"
                                transform="translate(17.01)"
                            />
                            <line
                                id="Line_150-2"
                                data-name="Line 150"
                                class="notes-edge-6"
                                y2="249.015"
                                transform="translate(19.44)"
                            />
                        </g>
                    </g>
                </g>

                <g v-if="formatGroup === 'vertical'">
                    <rect
                        id="Rectangle_1516"
                        data-name="Rectangle 1516"
                        class="notes-edge-8"
                        width="14.518"
                        height="530.507"
                        transform="translate(676.803 277)"
                    />
                    <path
                        id="Path_546"
                        data-name="Path 546"
                        class="notes-edge-9"
                        d="M0,0H9.518V530.507H0Z"
                        transform="translate(708.32 277)"
                    />
                </g>
                <g v-if="formatGroup === 'horizontal'">
                    <rect
                        id="Rectangle_1516"
                        data-name="Rectangle 1516"
                        class="notes-edge-8"
                        width="9.748"
                        height="186.867"
                        transform="translate(5.239 5.371)"
                    />
                    <path
                        id="Path_546"
                        data-name="Path 546"
                        class="notes-edge-9"
                        d="M0,0H6.391V186.867H0Z"
                        transform="translate(26.401 5.371)"
                    />
                </g>
                <g v-if="formatGroup === 'square'">
                    <rect
                        id="Rectangle_1516"
                        data-name="Rectangle 1516"
                        class="notes-edge-8"
                        width="11.759"
                        height="249.397"
                        transform="translate(6.32 8)"
                    />
                    <path
                        id="Path_546"
                        data-name="Path 546"
                        class="notes-edge-9"
                        d="M0,0H7.709V249.4H0Z"
                        transform="translate(31.849 8)"
                    />
                </g>
            </g>
        </g>
        <EdgeStreamer v-if="currentPage === 'accessories'" />
    </svg>
</template>

<script>
import PaperPattern from '@/components/notes/paper/PaperPattern'
import EdgeFoil from '@/components/notes/edge/EdgeFoil'
import EdgeGraphics from '@/components/notes/edge/EdgeGraphics'
import EdgeColor from '@/components/notes/edge/EdgeColor'
import EdgeStreamer from '@/components/notes/edge/EdgeStreamer'
import NotesFormatMixin from '@/mixins/NotesFormatMixin'

import { mapState, mapGetters } from 'vuex'

export default {
    components: {
        PaperPattern,
        EdgeFoil,
        EdgeGraphics,
        EdgeColor,
        EdgeStreamer,
    },
    mixins: [NotesFormatMixin],
    data() {
        return {
            defaultBackground: '#7e7e7e',
            svg: {
                vertical: {
                    width: '135',
                    height: '611',
                    viewBox: '0 0 135 611',
                    Group_1192Translate: 'translate(-852.5 -237.5)',
                    Group_815Translate: 'translate(188)',
                },
                horizontal: {
                    width: '109',
                    height: '260',
                    viewBox: '0 0 119 270',
                    Group_1192Translate: 'translate(-849.5 -233.5)',
                    Group_815Translate: 'translate(857 269)',
                },
                square: {
                    width: '111',
                    height: '319',
                    viewBox: '0 0 126 330',
                    Group_1192Translate: 'translate(6.5 31.5)',
                    Group_815Translate: 'translate(0 0)',
                },
            },
        }
    },
    computed: {
        ...mapGetters(['paperColor']),
        ...mapState({
            currentPage: (state) => state.page.currentPage.id,
            background: (state) =>
                state.notes.configuration.coverColorPattern?.image || null,
            edgeType: (state) => {
                const option = state.notes.configuration.edgeColoration
                return option?.title?.pl || option?.general?.title?.pl
            },
            edgeColor: (state) => {
                const option = state.notes.configuration.edgeColoration
                return option?.color
            },
            edgeFoil: (state) => {
                const option = state.notes.configuration.edgeColoration
                return option?.metallicFoilColor?.id
            },
            defaultPageColor: (state) => {
                return state.notes.configuration.paperType?.title?.pl
            },
            coverSoft: (state) => {
                return ['1', '2', '4'].includes(
                    state.notes.configuration?.coverType?.apiId
                )
            },
            softPaper: (state) =>
                state.notes.configuration?.coverType?.apiId === '1',
        }),
        showEdge() {
            return (
                !this.softPaper || (this.softPaper && this.edgeType !== 'Druk')
            )
        },
    },
}
</script>

<style lang="scss">
.notes-edge {
    &-zoom {
        transform: scale(1.3);
        transform-origin: 150% 60%;
    }

    &-1,
    &-11,
    &-6,
    &-7 {
        fill: none;
    }
    &-1 {
        stroke: rgba(0, 0, 0, 0.12);
    }
    &-2,
    &-3 {
        stroke: rgba(0, 0, 0, 0.27);
    }
    &-2 {
        fill: url(#notes-edge-linear-gradient);
    }
    &-4 {
        fill: rgba(255, 255, 255, 0.16);
    }
    &-5 {
        fill: url(#notes-edge-linear-gradient-2);
    }
    &-6 {
        stroke: rgba(255, 255, 255, 0.2);
    }
    &-7 {
        stroke: rgba(255, 255, 255, 0.45);
    }
    &-8,
    &-9 {
        opacity: 0.5;
    }
    &-8 {
        fill: url(#notes-edge-linear-gradient-4);
    }
    &-9 {
        fill: url(#notes-edge-linear-gradient-5);
    }
    &-10 {
        stroke: none;
    }
    &-12 {
        filter: url(#shadow_vector);
    }

    @media (max-width: $screen-medium) {
        &-zoom {
            transform: scale(1.2);
            transform-origin: 150% 60%;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        margin: -20px auto;
    }
}
</style>
