<template>
    <div :class="['loader', { 'is-active': isActive }]" />
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isAnimation: true,
        }
    },

    watch: {
        isActive(val) {
            if (val) {
                this.isAnimation = true
            } else {
                setTimeout(() => {
                    this.isAnimation = false
                }, 151)
            }
        },
    },
}
</script>

<style lang="scss">
.loader {
    &.is-active {
        transform: none;
        @include show();
    }
}
</style>
