import { render, staticRenderFns } from "./BasePageMixin.vue?vue&type=template&id=08bf0dea&"
import script from "./BasePageMixin.vue?vue&type=script&lang=js&"
export * from "./BasePageMixin.vue?vue&type=script&lang=js&"
import style0 from "./BasePageMixin.vue?vue&type=style&index=0&id=08bf0dea&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports