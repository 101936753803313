import html2canvas from 'html2canvas'
import { mapMutations } from 'vuex'

export default {
    methods: {
        ...mapMutations([
            'setViewClosed',
            'setViewOpen',
            'setViewEdge',
            'setViewFlyleaf',
        ]),
        async notesToCanvas(cb) {
            // INSIDE VIEW
            const notes1 = document.getElementById('notes-open')

            await html2canvas(notes1).then((canvas) => {
                const img = canvas.toDataURL('image/png,1.0')

                this.setViewOpen(img)
            })

            // FLYLEAF
            const notes2 = document.getElementById('notes-open-flyleaf')

            await html2canvas(notes2).then((canvas) => {
                const img = canvas.toDataURL('image/png,1.0')

                this.setViewFlyleaf(img)
            })

            // COVER VIEW
            const notes3 = document.getElementById('notes-accessories')

            await html2canvas(notes3).then((canvas) => {
                const img = canvas.toDataURL('image/png,1.0')

                this.setViewClosed(img)
            })

            // EDGE VIEW
            const notes5 = document.getElementById('notes-edge')

            await html2canvas(notes5).then((canvas) => {
                const img = canvas.toDataURL('image/png,1.0')

                this.setViewEdge(img)
            })
            if (cb) {
                cb()
            }
        },
    },
}
