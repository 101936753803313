<template>
    <!-- eslint-disable -->
    <g id="Group_1148" data-name="Group 1148" :transform="options[format]">
        <g id="Group_1147" data-name="Group 1147" transform="translate(0 0)">
            <path
                id="Path_6235"
                data-name="Path 6235"
                class="paper-holes"
                d="M63.8,308.049a4.017,4.017,0,1,0-4.017-4.017A4.017,4.017,0,0,0,63.8,308.049Z"
                transform="translate(-47.704 -140.284)"
            />
            <line
                id="Line_408"
                data-name="Line 408"
                class="paper-holes"
                x2="2.935"
                y2="2.935"
                transform="translate(14.623 162.28)"
            />
            <line
                id="Line_409"
                data-name="Line 409"
                class="paper-holes"
                x1="2.935"
                y2="2.935"
                transform="translate(14.623 162.28)"
            />
            <path
                id="Path_6236"
                data-name="Path 6236"
                class="paper-holes"
                d="M63.8,534.82a4.017,4.017,0,1,0-4.017-4.017A4.017,4.017,0,0,0,63.8,534.82Z"
                transform="translate(-47.704 -246.32)"
            />
            <line
                id="Line_410"
                data-name="Line 410"
                class="paper-holes"
                x2="2.935"
                y2="2.935"
                transform="translate(14.623 283.015)"
            />
            <line
                id="Line_411"
                data-name="Line 411"
                class="paper-holes"
                x1="2.935"
                y2="2.935"
                transform="translate(14.623 283.015)"
            />
        </g>
        <g id="Group_1147" data-name="Group 1147" transform="translate(90 0)">
            <path
                id="Path_6235"
                data-name="Path 6235"
                class="paper-holes"
                d="M63.8,308.049a4.017,4.017,0,1,0-4.017-4.017A4.017,4.017,0,0,0,63.8,308.049Z"
                transform="translate(-47.704 -140.284)"
            />
            <line
                id="Line_408"
                data-name="Line 408"
                class="paper-holes"
                x2="2.935"
                y2="2.935"
                transform="translate(14.623 162.28)"
            />
            <line
                id="Line_409"
                data-name="Line 409"
                class="paper-holes"
                x1="2.935"
                y2="2.935"
                transform="translate(14.623 162.28)"
            />
            <path
                id="Path_6236"
                data-name="Path 6236"
                class="paper-holes"
                d="M63.8,534.82a4.017,4.017,0,1,0-4.017-4.017A4.017,4.017,0,0,0,63.8,534.82Z"
                transform="translate(-47.704 -246.32)"
            />
            <line
                id="Line_410"
                data-name="Line 410"
                class="paper-holes"
                x2="2.935"
                y2="2.935"
                transform="translate(14.623 283.015)"
            />
            <line
                id="Line_411"
                data-name="Line 411"
                class="paper-holes"
                x1="2.935"
                y2="2.935"
                transform="translate(14.623 283.015)"
            />
        </g>
        <g v-if="holes === '4 otwory' && format === 'vertical'">
            <g transform="translate(90 0)">
                <g
                    id="Group_1146"
                    data-name="Group 1146"
                    transform="translate(12.074 38.994)"
                >
                    <path
                        id="Path_6237"
                        data-name="Path 6237"
                        class="paper-holes"
                        d="M63.8,81.277a4.017,4.017,0,1,0-4.017-4.017A4.017,4.017,0,0,0,63.8,81.277Z"
                        transform="translate(-59.778 -73.242)"
                    />
                    <line
                        id="Line_412"
                        data-name="Line 412"
                        class="paper-holes"
                        x2="2.935"
                        y2="2.935"
                        transform="translate(2.55 2.55)"
                    />
                    <line
                        id="Line_413"
                        data-name="Line 413"
                        class="paper-holes"
                        x1="2.935"
                        y2="2.935"
                        transform="translate(2.55 2.55)"
                    />
                </g>
                <g>
                    <path
                        id="Path_6238"
                        data-name="Path 6238"
                        class="paper-holes"
                        d="M63.8,761.592a4.017,4.017,0,1,0-4.017-4.017A4.017,4.017,0,0,0,63.8,761.592Z"
                        transform="translate(-47.704 -352.357)"
                    />
                    <line
                        id="Line_414"
                        data-name="Line 414"
                        class="paper-holes"
                        x2="2.935"
                        y2="2.935"
                        transform="translate(14.623 403.75)"
                    />
                    <line
                        id="Line_415"
                        data-name="Line 415"
                        class="paper-holes"
                        x1="2.935"
                        y2="2.935"
                        transform="translate(14.623 403.75)"
                    />
                </g>
            </g>
            <g
                id="Group_1146"
                data-name="Group 1146"
                transform="translate(12.074 38.994)"
            >
                <path
                    id="Path_6237"
                    data-name="Path 6237"
                    class="paper-holes"
                    d="M63.8,81.277a4.017,4.017,0,1,0-4.017-4.017A4.017,4.017,0,0,0,63.8,81.277Z"
                    transform="translate(-59.778 -73.242)"
                />
                <line
                    id="Line_412"
                    data-name="Line 412"
                    class="paper-holes"
                    x2="2.935"
                    y2="2.935"
                    transform="translate(2.55 2.55)"
                />
                <line
                    id="Line_413"
                    data-name="Line 413"
                    class="paper-holes"
                    x1="2.935"
                    y2="2.935"
                    transform="translate(2.55 2.55)"
                />
            </g>
            <g>
                <path
                    id="Path_6238"
                    data-name="Path 6238"
                    class="paper-holes"
                    d="M63.8,761.592a4.017,4.017,0,1,0-4.017-4.017A4.017,4.017,0,0,0,63.8,761.592Z"
                    transform="translate(-47.704 -352.357)"
                />
                <line
                    id="Line_414"
                    data-name="Line 414"
                    class="paper-holes"
                    x2="2.935"
                    y2="2.935"
                    transform="translate(14.623 403.75)"
                />
                <line
                    id="Line_415"
                    data-name="Line 415"
                    class="paper-holes"
                    x1="2.935"
                    y2="2.935"
                    transform="translate(14.623 403.75)"
                />
            </g>
        </g>
    </g>
</template>

<script>
export default {
    props: {
        holes: {
            type: String,
            default: '2 otwory',
        },
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            options: {
                vertical: 'translate(260.545 -2.533)',
                horizontal: 'translate(251.545 -130.533)',
                square: 'translate(200.545 -90.533)',
            },
        }
    },
}
</script>

<style>
.paper-holes {
    fill: none;
    stroke: #a2a2a2;
}
</style>
