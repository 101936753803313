export const bandsColors7 = [
    {
        title: {
            pl: 'Biały',
            en: 'White',
            de: 'Weiß',
            fr: 'Blanc',
        },
        value: '#FFFFFF',
        apiId: '7_white',
        id: 1,
    },
    {
        title: {
            pl: 'Czarny',
            en: 'Black',
            de: 'Schwarz',
            fr: 'Noir',
        },
        value: '#000000',
        apiId: '7_black',
        id: 0,
    },
    {
        title: {
            pl: 'Jasny szary',
            en: 'Light grey',
            de: 'Hellgrau',
            fr: 'Gris clair',
        },
        value: '#9DA9B0',
        apiId: '7_light grey',
        id: 3027,
    },
    {
        title: {
            pl: 'Ciemny szary',
            en: 'Dark grey',
            de: 'Dunkelgrau',
            fr: 'Gris foncé',
        },
        value: '#6C757A',
        apiId: '7_dark grey',
        id: 3028,
    },
    {
        title: {
            pl: 'Czerwony',
            en: 'Red',
            de: 'Rot',
            fr: 'Rouge',
        },
        value: '#bd0d33',
        apiId: '7_red',
        id: 450,
    },
    {
        title: {
            pl: 'Żółty',
            en: 'Yellow',
            de: 'Gelb',
            fr: 'Jaune',
        },
        value: '#F6CB15',
        apiId: '7_yellow',
        id: 968,
    },
    {
        title: {
            pl: 'Ciemny zielony',
            en: 'Dark green',
            de: 'Dunkelgrün',
            fr: 'Vert foncé',
        },
        value: '#104716',
        apiId: '7_green',
        id: 460,
    },
    {
        title: {
            pl: 'Pomarańczowy',
            en: 'Orange',
            de: 'Orange',
            fr: 'Orange',
        },
        value: '#F07C2D',
        apiId: '7_orange',
        id: 2560,
    },
    {
        title: {
            pl: 'Granatowy',
            en: 'Navy blue',
            de: 'Navy blau',
            fr: 'Bleu marine',
        },
        value: '#16193E',
        apiId: '7_dark blue',
        id: 348,
    },
    {
        title: {
            pl: 'Niebieski',
            en: 'Blue',
            de: 'Blau',
            fr: 'Bleu',
        },
        value: '#0612A9',
        apiId: '7_blue',
        id: 694,
    },
    {
        title: {
            pl: 'Błękitny',
            en: 'Light blue',
            de: 'Himmelblau',
            fr: 'Bleu pâle',
        },
        value: '#3A8CC9',
        apiId: '7_light blue',
        id: 3026,
    },
    {
        title: {
            pl: 'Jasny zielony',
            en: 'Light green',
            de: 'Hellgrün',
            fr: 'Vert clair',
        },
        value: '#4FEA61',
        apiId: '7_light green',
        id: 601,
    },
    {
        title: {
            pl: 'Fuksja',
            en: 'Fuchsia',
            de: 'Fuchsia',
            fr: 'Fuchsia',
        },
        value: '#EA4FA4',
        apiId: '7_pink',
        id: 610,
    },
    {
        title: {
            pl: 'Złoty',
            en: 'Gold',
            de: 'Gold',
            fr: 'Doré',
        },
        value: '#A99260',
        image: '/img/colors/color (13).svg',
        apiId: '7_gold',
        id: 400,
    },
    {
        title: {
            pl: 'Srebrny',
            en: 'Silver',
            de: 'Silber',
            fr: 'Argent',
        },
        value: '#A3A2A0',
        image: '/img/colors/color (14).svg',
        apiId: '7_silver',
        id: 500,
    },
]
export const bandsColors10 = [
    {
        title: {
            pl: 'Biały',
            en: 'White',
            de: 'Weiß',
            fr: 'Blanc',
        },
        value: '#FFFFFF',
        apiId: '10_white',
        id: 1,
    },
    {
        title: {
            pl: 'Czarny',
            en: 'Black',
            de: 'Schwarz',
            fr: 'Noir',
        },
        value: '#000000',
        apiId: '10_black',
        id: 0,
    },
    {
        title: {
            pl: 'Jasny szary',
            en: 'Light grey',
            de: 'Hellgrau',
            fr: 'Gris clair',
        },
        value: '#9DA9B0',
        apiId: '10_light grey',
        id: 3027,
    },
    {
        title: {
            pl: 'Ciemny szary',
            en: 'Dark grey',
            de: 'Dunkelgrau',
            fr: 'Gris foncé',
        },
        value: '#6C757A',
        apiId: '10_dark grey',
        id: 3028,
    },
    {
        title: {
            pl: 'Czerwony',
            en: 'Red',
            de: 'Rot',
            fr: 'Rouge',
        },
        value: '#bd0d33',
        apiId: '10_red',
        id: 450,
    },
    {
        title: {
            pl: 'Żółty',
            en: 'Yellow',
            de: 'Gelb',
            fr: 'Jaune',
        },
        value: '#F6CB15',
        apiId: '10_yellow',
        id: 968,
    },
    {
        title: {
            pl: 'Ciemny zielony',
            en: 'Dark green',
            de: 'Dunkelgrün',
            fr: 'Vert foncé',
        },
        value: '#104716',
        apiId: '10_green',
        id: 460,
    },
    {
        title: {
            pl: 'Pomarańczowy',
            en: 'Orange',
            de: 'Orange',
            fr: 'Orange',
        },
        value: '#F07C2D',
        apiId: '10_orange',
        id: 2560,
    },
    {
        title: {
            pl: 'Granatowy',
            en: 'Navy blue',
            de: 'Navy blau',
            fr: 'Bleu marine',
        },
        value: '#16193E',
        apiId: '10_dark blue',
        id: 348,
    },
    {
        title: {
            pl: 'Niebieski',
            en: 'Blue',
            de: 'Blau',
            fr: 'Bleu',
        },
        value: '#0612A9',
        apiId: '10_blue',
        id: 694,
    },
    {
        title: {
            pl: 'Błękitny',
            en: 'Light blue',
            de: 'Himmelblau',
            fr: 'Bleu pâle',
        },
        value: '#3A8CC9',
        apiId: '10_light blue',
        id: 3026,
    },
    {
        title: {
            pl: 'Jasny zielony',
            en: 'Light green',
            de: 'Hellgrün',
            fr: 'Vert clair',
        },
        value: '#4FEA61',
        apiId: '10_light green',
        id: 601,
    },
    {
        title: {
            pl: 'Fuksja',
            en: 'Fuchsia',
            de: 'Fuchsia',
            fr: 'Fuchsia',
        },
        value: '#EA4FA4',
        apiId: '10_pink',
        id: 610,
    },
    {
        title: {
            pl: 'Złoty',
            en: 'Gold',
            de: 'Gold',
            fr: 'Doré',
        },
        value: '#A99260',
        image: '/img/colors/color (13).svg',
        apiId: '10_gold',
        id: 400,
    },
    {
        title: {
            pl: 'Srebrny',
            en: 'Silver',
            de: 'Silber',
            fr: 'Argent',
        },
        value: '#A3A2A0',
        image: '/img/colors/color (14).svg',
        apiId: '10_silver',
        id: 500,
    },
]
export const bandsColors15 = [
    {
        title: {
            pl: 'Biały',
            en: 'White',
            de: 'Weiß',
            fr: 'Blanc',
        },
        value: '#FFFFFF',
        apiId: '15_white',
        id: 1,
    },
    {
        title: {
            pl: 'Czarny',
            en: 'Black',
            de: 'Schwarz',
            fr: 'Noir',
        },
        value: '#000000',
        apiId: '15_black',
        id: 0,
    },
    {
        title: {
            pl: 'Jasny szary',
            en: 'Light grey',
            de: 'Hellgrau',
            fr: 'Gris clair',
        },
        value: '#9DA9B0',
        apiId: '15_light grey',
        id: 3027,
    },
    {
        title: {
            pl: 'Ciemny szary',
            en: 'Dark grey',
            de: 'Dunkelgrau',
            fr: 'Gris foncé',
        },
        value: '#6C757A',
        apiId: '15_dark grey',
        id: 3028,
    },
    {
        title: {
            pl: 'Czerwony',
            en: 'Red',
            de: 'Rot',
            fr: 'Rouge',
        },
        value: '#bd0d33',
        apiId: '15_red',
        id: 450,
    },
    {
        title: {
            pl: 'Żółty',
            en: 'Yellow',
            de: 'Gelb',
            fr: 'Jaune',
        },
        value: '#F6CB15',
        apiId: '15_yellow',
        id: 968,
    },
    {
        title: {
            pl: 'Ciemny zielony',
            en: 'Dark green',
            de: 'Dunkelgrün',
            fr: 'Vert foncé',
        },
        value: '#104716',
        apiId: '15_green',
        id: 460,
    },
    {
        title: {
            pl: 'Pomarańczowy',
            en: 'Orange',
            de: 'Orange',
            fr: 'Orange',
        },
        value: '#F07C2D',
        apiId: '15_orange',
        id: 2560,
    },
    {
        title: {
            pl: 'Granatowy',
            en: 'Navy blue',
            de: 'Navy blau',
            fr: 'Bleu marine',
        },
        value: '#16193E',
        apiId: '15_dark blue',
        id: 348,
    },
    {
        title: {
            pl: 'Niebieski',
            en: 'Blue',
            de: 'Blau',
            fr: 'Bleu',
        },
        value: '#0612A9',
        apiId: '15_blue',
        id: 694,
    },
    {
        title: {
            pl: 'Błękitny',
            en: 'Light blue',
            de: 'Himmelblau',
            fr: 'Bleu pâle',
        },
        value: '#3A8CC9',
        apiId: '15_light blue',
        id: 3026,
    },
    {
        title: {
            pl: 'Jasny zielony',
            en: 'Light green',
            de: 'Hellgrün',
            fr: 'Vert clair',
        },
        value: '#4FEA61',
        apiId: '15_light green',
        id: 601,
    },
    {
        title: {
            pl: 'Fuksja',
            en: 'Fuchsia',
            de: 'Fuchsia',
            fr: 'Fuchsia',
        },
        value: '#EA4FA4',
        apiId: '15_pink',
        id: 610,
    },
    {
        title: {
            pl: 'Złoty',
            en: 'Gold',
            de: 'Gold',
            fr: 'Doré',
        },
        value: '#A99260',
        image: '/img/colors/color (13).svg',
        apiId: '15_gold',
        id: 400,
    },
    {
        title: {
            pl: 'Srebrny',
            en: 'Silver',
            de: 'Silber',
            fr: 'Argent',
        },
        value: '#A3A2A0',
        image: '/img/colors/color (14).svg',
        apiId: '15_silver',
        id: 500,
    },
]
