import coverType from './cover/ModalCoverType'
import coverCorners from './cover/ModalCoverCorners'
import coverRidge from './cover/ModalCoverRidge'
import format from './format/ModalFormat'
import markingPrint from './coverMarking/ModalCoverMarkingPrint'
import markingExpression from './coverMarking/ModalCoverMarkingExpression'
import markingCoverFinish from './coverMarking/ModalCoverMarkingCoverFinish'
import paperType from './inside/ModalInsidePaperType'
import numberOfPages from './inside/ModalInsideNumberOfPages'
import overprintType from './inside/ModalInsideOverprintType'
import perforation from './inside/ModalInsidePerforation'
import binderHoles from './inside/ModalInsideBinderHoles'
import flyleafPaper from './flyleaf/ModalFlyleafFlyleaf'
import pocket from './flyleaf/ModalFlyleafPocket'
import additionalPages from './personalized/ModalPersonalizedAdditionalPages'
import calendar from './personalized/ModalPersonalizedCalendar'
import elastic from './accessories/ModalAccessoriesElastic'
import penHolder from './accessories/ModalAccessoriesPenHolder'
import ribbon from './accessories/ModalAccessoriesRibbon'
import streamer from './accessories/ModalAccessoriesStreamer'
import edgeColoration from './edgeColoration/ModalEdgeColoration'
import wrapping from './package/ModalPackage'
import paperTypeSoft from './paper/ModalPaperTypeSoft'
import paperTypeSoftIntro from './paper/ModalPaperTypeSoftIntro'
import paperTypeHardVeneer from './paper/ModalPaperTypeHardVeneer'
import paperTypeHardElastic from './paper/ModalPaperTypeHardElastic'

export default {
    components: {
        coverType,
        coverCorners,
        coverRidge,
        format,
        markingPrint,
        markingExpression,
        markingCoverFinish,
        paperType,
        numberOfPages,
        overprintType,
        perforation,
        binderHoles,
        flyleafPaper,
        pocket,
        additionalPages,
        calendar,
        elastic,
        penHolder,
        ribbon,
        streamer,
        edgeColoration,
        wrapping,
        paperTypeSoft,
        paperTypeSoftIntro,
        paperTypeHardVeneer,
        paperTypeHardElastic,
    },

    data: {
        coverType,
        coverCorners,
        coverRidge,
        format,
        markingPrint,
        markingExpression,
        markingCoverFinish,
        paperType,
        numberOfPages,
        overprintType,
        perforation,
        binderHoles,
        flyleafPaper,
        pocket,
        additionalPages,
        calendar,
        elastic,
        penHolder,
        ribbon,
        streamer,
        edgeColoration,
        wrapping,
        paperTypeSoft,
        paperTypeSoftIntro,
        paperTypeHardVeneer,
        paperTypeHardElastic,
    },
}
