export const ribbonsColors = [
    {
        title: {
            pl: 'Biały',
            en: 'White',
            de: 'Weiß',
            fr: 'Blanc',
        },
        value: '#FFFFFF',
        apiId: 'white',
        id: 901,
    },
    {
        title: {
            pl: 'Czarny',
            en: 'Black',
            de: 'Schwarz',
            fr: 'Noir',
        },
        value: '#000000',
        apiId: 'black',
        id: 931,
    },
    {
        title: {
            pl: 'Jasny szary',
            en: 'Light grey',
            de: 'Hellgrau',
            fr: 'Gris clair',
        },
        value: '#9DA9B0',
        apiId: 'light grey',
        id: 912,
    },
    // {
    //     title: {
    //         pl: 'Ciemny szary',
    //         en: 'Dark grey',
    //         de: 'Dunkelgrau',
    //         fr: 'Gris foncé',
    //     },
    //     value: '#6C757A',
    // },
    {
        title: {
            pl: 'Czerwony',
            en: 'Red',
            de: 'Rot',
            fr: 'Rouge',
        },
        value: '#bd0d33',
        apiId: 'red',
        id: 916,
    },
    {
        title: {
            pl: 'Żółty',
            en: 'Yellow',
            de: 'Yellow',
            fr: 'Jaune',
        },
        value: '#F6CB15',
        apiId: 'yellow',
        id: 907,
    },
    {
        title: {
            pl: 'Ciemny zielony',
            en: 'Dark green',
            de: 'Dunkelgrün',
            fr: 'Vert foncé',
        },
        value: '#104716',
        apiId: 'green',
        id: 930,
    },
    {
        title: {
            pl: 'Pomarańczowy',
            en: 'Orange',
            de: 'Orange',
            fr: 'Orange',
        },
        value: '#F07C2D',
        apiId: 'orange',
        id: 915,
    },
    {
        title: {
            pl: 'Granatowy',
            en: 'Navy blue',
            de: 'Navy blau',
            fr: 'Bleu marine',
        },
        value: '#16193E',
        apiId: 'dark blue',
        id: 924,
    },
    {
        title: {
            pl: 'Niebieski',
            en: 'Blue',
            de: 'Blau',
            fr: 'Bleu',
        },
        value: '#0612A9',
        apiId: 'blue',
        id: 923,
    },
    {
        title: {
            pl: 'Błękitny',
            en: 'Light blue',
            de: 'Himmelblau',
            fr: 'Bleu pâle',
        },
        value: '#3A8CC9',
        apiId: 'light blue',
        id: 921,
    },
    {
        title: {
            pl: 'Jasny zielony',
            en: 'Light green',
            de: 'Hellgrün',
            fr: 'Vert clair',
        },
        value: '#4FEA61',
        apiId: 'light green',
        id: 933,
    },
    {
        title: {
            pl: 'Fuksja',
            en: 'Fuchsia',
            de: 'Fuchsia',
            fr: 'Fuchsia',
        },
        value: '#EA4FA4',
        apiId: 'pink',
        id: 936,
    },
    {
        title: {
            pl: 'Złoty',
            en: 'Gold',
            de: 'Gold',
            fr: 'Doré',
        },
        value: '#A99260',
        image: '/img/colors/color (13).svg',
        apiId: 'gold',
        id: 939,
    },
    {
        title: {
            pl: 'Srebrny',
            en: 'Silver',
            de: 'Silber',
            fr: 'Argent',
        },
        value: '#A3A2A0',
        image: '/img/colors/color (14).svg',
        apiId: 'silver',
        id: 938,
    },
]
