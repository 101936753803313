<template>
    <!-- eslint-disable -->
    <g>
        <defs>
            <clipPath id="calendar-vertical-clip-path">
                <rect
                    id="Rectangle_1603"
                    data-name="Rectangle 1603"
                    class="notes-pages-1"
                    width="300"
                    height="451"
                    transform="translate(720 245)"
                />
            </clipPath>
            <clipPath id="calendar-horizontal-clip-path">
                <path
                    d="M0,0h308V105.984s.071,2.713-.2,6.664c-.42,6.178-1.878,15.614-6.566,17.8-5.965,4.063-28.882,20.379-35.277,25.342-2.951,2.29-20.424,15.83-23.535,17.92-13.773,9.751-25.209,7.567-25.209,7.567l-210-.164Z"
                    transform="translate(690 244)"
                />
            </clipPath>
            <clipPath id="calendar-square-clip-path">
                <rect
                    id="Rectangle_1618"
                    data-name="Rectangle 1618"
                    class="square-pages-one-2"
                    width="254"
                    height="263"
                    transform="translate(650 245)"
                />
            </clipPath>
        </defs>
        <!-- calendar paper color elements -->
        <g
            v-if="format === 'vertical'"
            clip-path="url(#calendar-vertical-clip-path)"
        >
            <path
                id="Path_9598"
                data-name="Path 9598"
                d="M0,0h300l.944,458s1.063, 40.847-13.127, 47.47c-11.573, 7.884-56.037, 39.54-68.445, 49.169-5.725, 4.443-39.627, 30.714-45.664, 34.768-26.723, 18.919-55.131, 16.15-55.131, 16.15H0Z"
                transform="translate(720 90)"
                :fill="paperColor"
            ></path>
        </g>
        <g
            v-if="format === 'horizontal'"
            clip-path="url(#calendar-horizontal-clip-path)"
        >
            <path
                id="Path_9592"
                data-name="Path 9598"
                d="M0,0h300l.944,451s1.063, 40.847-13.127, 47.47c-11.573, 7.884-56.037, 39.54-68.445, 49.169-5.725, 4.443-39.627, 30.714-45.664, 34.768-26.723, 18.919-55.131, 16.15-55.131, 16.15H0Z"
                transform="translate(707.5 95)"
                :fill="paperColor"
            ></path>
        </g>
        <g
            v-if="format === 'square'"
            clip-path="url(#calendar-square-clip-path)"
        >
            <path
                id="Path_9598"
                data-name="Path 9598"
                d="M0,0h370l.944,274s1.063, 40.847-13.127, 47.47c-11.573, 7.884-56.037, 39.54-68.445, 49.169-5.725, 4.443-39.627, 30.714-45.664, 34.768-26.723, 18.919-55.131, 16.15-55.131, 16.15H0Z"
                transform="translate(666 230) scale(0.65)"
                :fill="paperColor"
            />
        </g>
        <!-- calendar -->
        <image
            :href="calendar[format].url"
            :width="calendar[format].width"
            :transform="calendar[format].transform"
            alt="calendar"
        />
    </g>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            calendar: {
                vertical: {
                    url: '/img/notes/personalized/calendar-year-vertical.png',
                    transform: 'translate(696 257)',
                    width: '350',
                },
                horizontal: {
                    url: '/img/notes/personalized/calendar-year-horizontal.png',
                    transform: 'translate(698 240), scale(0.95)',
                    width: '350',
                },
                square: {
                    url: '/img/notes/personalized/calendar-year-square.png',
                    transform: 'translate(656 237)',
                    width: '250',
                },
            },
        }
    },
    computed: {
        ...mapGetters(['paperColor']),
    },
}
</script>
