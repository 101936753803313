import Api from '@/api/ApiLauncher'
import hidePagesConditons from '@/helper/HidePageConditions'
import {delay} from '@/helper/DelayHelper'

const state = {
    list: [],
    apiList: null,
    paramList: null,
}

const mutations = {
    addDependency(state, { option, newValue }) {
        state.list.push({
            id: newValue.id,
            actions: newValue.actions,
            items: option,
        })
    },
    removeDependency(state, { index }) {
        state.list.splice(index, 1)
    },
    setLimitPaper(state, { option, newValue }) {
        state.list[option] = newValue
    },
    setApiList(state, payload) {
        state.apiList = payload
    },
    setParamList(state, payload) {
        state.paramList = payload
    },
}
const actions = {
    setDependency({ commit, dispatch }, { option, newValue }) {
        let storeList = state.list
        const foundElements = state.list.find((dep) => dep.id === newValue.id)
        const found = !!foundElements?.id

        function enablePages(actions) {
            for (let index in hidePagesConditons) {
                let shouldShow = 0
                const page = hidePagesConditons[index]
                for (let pageParamIndex in page.params) {
                    const pageParam = page.params[pageParamIndex]

                    if (actions[pageParam]?.length === 0) {
                        shouldShow++
                    }
                }
                if (shouldShow === page.params.length) {
                    dispatch('clearPageDependency', page.pageId)
                }
            }
        }

        if (found && newValue.dep === false && storeList) {
            // jeśli znajdzie zależność to ją wywali jeśli nie jest już aktywna
            const index = state.list.findIndex((val) => val.id === newValue.id)
            if (index > -1) {
                commit('removeDependency', { index })

                enablePages(newValue.actions)
            }
        } else if (!found && newValue.dep === true) {
            commit('addDependency', { newValue, option })
        } else {
            // jeśli aktualna zależność ma nadpisać poprzednią (działa dla pierwszego kroku)
            if (newValue.id === 'Params') {
                const index = state.list.findIndex(
                    (val) => val.id === newValue.id
                )
                if (index > -1) {
                    commit('removeDependency', { index })

                    enablePages(newValue.actions)
                }
                commit('addDependency', { newValue, option })
            }
        }
    },
    setLimitPaper({ commit }, payload) {
        commit('setLimitPaper', payload)
    },
    setApiList({ commit }, payload) {
        commit('setApiList', payload)
    },
    setParamList({ commit }, payload) {
        commit('setParamList', payload)
    },
    async getDependencies({ commit, dispatch }, payload) {
        dispatch('showLoader', {loading: true, message: 'loader.info.deps'})
        try {
            // artifical slowdown for one second
            await delay(500)

            await Api.getService('dependencies')
                .requestGet(payload.type) // type is "standard" or "fsc"
                .then((response) => {
                    const dependencies = reformatDependencies(response.data)
                    const params = response?.data?.parameters.filter(
                        (param) => param.validation?.range
                    )

                    const actions = paramsToActions(params)
                    dispatch('setParamList', actions)
                    dispatch('setApiList', dependencies)
                    dispatch('hideLoader')
                })
                .then(payload.callback)
                .catch((e) => {
                    console.error('[getDependencies] ', e)
                    dispatch('showLoaderError', 'loader.error.deps')
                })
                .finally(() => {
                    state.loadingConfiguration = false
                    commit('pageLoaded')
                })
        } catch (e) {
            dispatch('showLoaderError', 'loader.error.deps')
            console.error('[getDependencies] ', e)
        }
    },
}

function paramsToActions(params) {
    const actions = {}
    params.forEach((action) => {
        return (actions[
            action.code
        ] = action?.validation?.range
            .flat()
            .map((param) => param.toUpperCase()))
    })
    return actions
}

function reformatDependencies(dependencies) {
    if (!dependencies) {
        throw Error('dependencies not passed')
    }
    const raw = dependencies.parameterDependencies
    // Map dependencies to nicer format
    const computedDependencies = raw.map((rawDependency) => {
        const items = rawDependency.condition.items.map((item) => {
            return {
                [item.parameter]: item?.match?.range.flat(),
            }
        })

        const actions = {}

        // all params are converted to uppercase
        rawDependency.actions.forEach((action) => {
            return (actions[
                action.parameter
            ] = action?.addValidation?.range
                .flat()
                .map((param) => param.toUpperCase()))
        })
        return { name: rawDependency.name, items, actions }
    })

    return computedDependencies
}

export default {
    state,
    mutations,
    actions,
}
