<template>
    <!-- eslint-disable -->
    <g
        v-if="
            personalizedPrint &&
            (pagePosition === 'after' || pagePosition === 'before')
        "
    >
        <defs>
            <pattern
                id="flyleaf-pattern-cmyk-0"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    xlink:href="/img/notes/wyklejka/cmyk-0.jpg"
                />
            </pattern>
        </defs>
        <!-- is page before -->
        <rect
            v-if="pagePosition === 'before'"
            id="Untitled-3"
            :style="background"
            width="200"
            :height="options[format].height"
            :transform="options[format].transformBefore"
        />
        <!-- is page after -->
        <rect
            v-if="pagePosition === 'after'"
            id="Untitled-3"
            :style="background"
            width="200"
            :height="options[format].height"
            :transform="options[format].transformAfter"
        />
    </g>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        format: {
            type: String,
            default: '',
        },
        pagePosition: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            options: {
                vertical: {
                    height: 451,
                    transformBefore: 'translate(0 5)',
                    transformAfter: 'translate(440 5)',
                },
                horizontal: {
                    height: 186,
                    transformBefore: 'translate(0 3)',
                    transformAfter: 'translate(425 3)',
                },
                square: {
                    height: 263,
                    transformBefore: 'translate(0 5)',
                    transformAfter: 'translate(328 5)',
                },
            },
        }
    },
    computed: {
        ...mapState({
            color: (state) => {
                const option = state.notes.configuration.flyleaf
                if (option && option.singleFlyleafPrint) {
                    return option.singleFlyleafPrint.title.pl
                }
                if (option && option.doubleFlyleafPrint) {
                    return option.doubleFlyleafPrint.title.pl
                }
                return null
            },
            flyleafPaperColor: (state) => {
                const notes = state.notes
                if (notes.configuration.flyleafPaper) {
                    return notes.configuration.flyleafPaper.hex
                } else {
                    return notes.configurationDefault.flyleafPaper.hex
                }
            },
            personalizedPrint: (state) => {
                const option = state.notes.configuration.additionalPages
                if (option && option.personalizedPrint) {
                    if (option.personalizedPrint.title.pl === '1 + 1') {
                        return 'one'
                    }
                    if (option.personalizedPrint.title.pl === '1 + 0') {
                        return false
                    }
                    if (option.personalizedPrint.title.pl === 'CMYK + 0') {
                        return false
                    }
                    if (option.personalizedPrint.title.pl === 'CMYK + CMYK') {
                        return 'cmyk'
                    }
                }
                return null
            },
        }),
        background() {
            return `fill: ${
                this.color && this.color.startsWith('CMYK')
                    ? 'url(#flyleaf-pattern-cmyk-0)'
                    : this.color && this.color.startsWith('1')
                    ? '#7eb9bf'
                    : this.flyleafPaperColor
            };`
        },
    },
}
</script>
