const state = {
    currentSubpage: 'Login',
    subpageProps: {},
}

const mutations = {
    changeSubpage(state, { page, props }) {
        state.subpageProps = props
        state.currentSubpage = page
    },
}

export default {
    state,
    mutations,
}
