<script>
import dependencies from '@/store/modules/dependencies'

export default {
    data() {
        return {
            pageTitle: {
                pl: 'Okładka - znakowanie',
                en: 'Cover - customization',
                de: 'Umschlag – Veredelung',
                fr: 'Couverture – marquage',
            },
        }
    },

    computed: {
        list() {
            return dependencies.state.list
        },
        options() {
            const coverFinishRequired =
                this.coverColorPattern === '250PSBS' ||
                this.coverColorPattern === '150PCW'

            let OKLADKA_ZNAKOWANIE_DRUK = null
            let OKLADKA_ZNAKOWANIE_TLOCZENIE = null
            let OKLADKA_ZNAKOWANIE_WYKONCZENIE = null

            if (this.list?.length) {
                this.list.forEach((dependency) => {
                    OKLADKA_ZNAKOWANIE_DRUK = mergeMultipleParams(
                        OKLADKA_ZNAKOWANIE_DRUK,
                        dependency.actions.OKLADKA_ZNAKOWANIE_DRUK
                    )

                    OKLADKA_ZNAKOWANIE_TLOCZENIE = mergeMultipleParams(
                        OKLADKA_ZNAKOWANIE_TLOCZENIE,
                        dependency.actions.OKLADKA_ZNAKOWANIE_TLOCZENIE
                    )

                    OKLADKA_ZNAKOWANIE_WYKONCZENIE = mergeMultipleParams(
                        OKLADKA_ZNAKOWANIE_WYKONCZENIE,
                        dependency.actions.OKLADKA_ZNAKOWANIE_WYKONCZENIE
                    )
                })
            }

            function mergeMultipleParams(param, dependencyAction) {
                param = param || dependencyAction

                if (param && dependencyAction) {
                    param = param.filter((dep) => {
                        return dependencyAction.includes(dep)
                    })
                }
                return param
            }

            function filterOptionsFn({ apiId }, allowed) {
                return allowed ? allowed.includes(apiId.toUpperCase()) : true
            }

            const markingPrint = {
                id: 'markingPrint',
                apiId: 'OKLADKA_ZNAKOWANIE_DRUK',
                title: {
                    pl: 'Rodzaj zadruku',
                    en: 'Printing type',
                    de: 'Druckart',
                    fr: "Type d'impression",
                },
                choices: [
                    {
                        apiId: 'B',
                        title: {
                            pl: 'Bez zadruku',
                            en: 'Unprinted',
                            de: 'Ohne Druck',
                            fr: 'Sans impression',
                        },
                    },
                    {
                        apiId: '',
                        title: {
                            pl: 'Druk jednostronny',
                            en: 'Single-sided printing',
                            de: 'Einseitiger Druck',
                            fr: 'Impression recto',
                        },
                        childrenGroups: [
                            {
                                id: 'singleMarkingPrint',
                                apiId: '',
                                required: true,
                                title: {
                                    pl: 'Druk',
                                    en: 'Printing',
                                    de: 'Druck',
                                    fr: 'Impression',
                                },
                                items: [
                                    {
                                        apiId: '10',
                                        title: {
                                            pl: '1 kolor',
                                            en: '1 colour',
                                            de: '1-farbig',
                                            fr: '1 couleur',
                                        },
                                    },
                                    {
                                        apiId: '20',
                                        title: {
                                            pl: '2 kolory',
                                            en: '2 colours',
                                            de: '2-frbig',
                                            fr: '2 couleurs',
                                        },
                                    },
                                    {
                                        apiId: '40',
                                        title: {
                                            pl: 'CMYK',
                                            en: 'CMYK',
                                            de: 'CMYK',
                                            fr: 'CMYK',
                                        },
                                    },
                                    {
                                        apiId: 'white',
                                        title: {
                                            pl: 'Biały',
                                            en: 'White',
                                            de: 'Weiß',
                                            fr: 'Blanc',
                                        },
                                    },
                                    {
                                        apiId: '4white',
                                        title: {
                                            pl: 'CMYK + biały',
                                            en: 'CMYK + white',
                                            de: 'CMYK + Weiß',
                                            fr: 'CMYK + Blanc',
                                        },
                                    },
                                ].filter((item) =>
                                    filterOptionsFn(
                                        item,
                                        OKLADKA_ZNAKOWANIE_DRUK
                                    )
                                ),
                            },
                        ],
                    },
                    {
                        apiId: '',
                        title: {
                            pl: 'Druk dwustronny',
                            en: 'Two-sided printing',
                            de: 'Beidseitiger Druck',
                            fr: 'Impression recto verso',
                        },
                        childrenGroups: [
                            {
                                id: 'doubleMarkingPrint',
                                apiId: '',
                                required: true,
                                title: {
                                    pl: 'Druk',
                                    en: 'Printing',
                                    de: 'Druck',
                                    fr: 'Impression',
                                },
                                items: [
                                    {
                                        apiId: '11',
                                        title: {
                                            pl: '1 + 1',
                                            en: '1 + 1',
                                            de: '1 + 1',
                                            fr: '1 + 1',
                                        },
                                    },
                                    {
                                        apiId: '22',
                                        title: {
                                            pl: '2 + 2',
                                            en: '2 + 2',
                                            de: '2 + 2',
                                            fr: '2 + 2',
                                        },
                                    },
                                    {
                                        apiId: '44',
                                        title: {
                                            pl: 'CMYK + CMYK',
                                            en: 'CMYK + CMYK',
                                            de: 'CMYK + CMYK',
                                            fr: 'CMYK + CMYK',
                                        },
                                    },
                                    {
                                        apiId: 'whitewhite',
                                        title: {
                                            pl: 'Biały + biały',
                                            en: 'White + white',
                                            de: 'CMYK + Weiß',
                                            fr: 'CMYK + Blanc',
                                        },
                                    },
                                    {
                                        apiId: '4white4white',
                                        title: {
                                            pl: 'CMYK i biały + CMYK i biały',
                                            en:
                                                'CMYK and white + CMYK and white',
                                            de: 'CMYK und Weiß + CMYK und Weiß',
                                            fr: 'CMYK et Blanc + CMYK et Blanc',
                                        },
                                    },
                                ].filter((item) =>
                                    filterOptionsFn(
                                        item,
                                        OKLADKA_ZNAKOWANIE_DRUK
                                    )
                                ),
                            },
                        ],
                    },
                ],
            }

            const foundSmallOrLarge = OKLADKA_ZNAKOWANIE_TLOCZENIE?.some(
                (r) => ['SMALL', 'LARGE'].indexOf(r) >= 0
            )

            let expressionChoices = []
            !OKLADKA_ZNAKOWANIE_TLOCZENIE ||
            (OKLADKA_ZNAKOWANIE_TLOCZENIE?.length && !foundSmallOrLarge)
                ? expressionChoices.push(
                      ...[
                          {
                              apiId: '',
                              title: {
                                  pl: 'Z folią',
                                  en: 'With foil',
                                  de: 'Mit Folie',
                                  fr: 'Avec film',
                              },
                              childrenGroups: [
                                  {
                                      id: 'color',
                                      required: true,
                                      apiId: '',
                                      title: {
                                          pl: 'Kolor',
                                          en: 'Colour',
                                          de: 'Farbe',
                                          fr: 'Couleur',
                                      },
                                      type: 'colors',
                                      items: [
                                          {
                                              apiId: 'white',
                                              name: 'Biały',
                                              title: {
                                                  pl: 'Biały',
                                                  en: 'White',
                                                  de: 'Weiß',
                                                  fr: 'Blanc',
                                              },
                                              value: '#ffffff',
                                          },
                                          {
                                              apiId: 'black',
                                              name: 'Czarny',
                                              title: {
                                                  pl: 'Czarny',
                                                  en: 'Black',
                                                  de: 'Schwarz',
                                                  fr: 'Noir',
                                              },
                                              value: '#000000',
                                          },
                                          {
                                              apiId: 'gold',
                                              name: 'Złoty',
                                              title: {
                                                  pl: 'Złoty',
                                                  en: 'Gold',
                                                  de: 'Gold',
                                                  fr: 'Doré',
                                              },
                                              image: '/img/colors/gold.svg',
                                          },
                                          {
                                              apiId: 'silver',
                                              name: 'Srebrny',
                                              title: {
                                                  pl: 'Srebrny',
                                                  en: 'Silver',
                                                  de: 'Silber',
                                                  fr: 'Argent',
                                              },
                                              image: '/img/colors/silver.svg',
                                          },
                                      ].filter((item) =>
                                          filterOptionsFn(
                                              item,
                                              OKLADKA_ZNAKOWANIE_TLOCZENIE
                                          )
                                      ),
                                  },
                              ],
                          },
                      ]
                  )
                : ''

            !OKLADKA_ZNAKOWANIE_TLOCZENIE ||
            (OKLADKA_ZNAKOWANIE_TLOCZENIE?.length && foundSmallOrLarge)
                ? expressionChoices.push(
                      ...[
                          {
                              apiId: 'small',
                              title: {
                                  pl: 'Bez folii małe',
                                  en: 'Without foil - small',
                                  de: 'Ohne Folie, klein',
                                  fr: 'Petit sans film',
                              },
                          },
                          {
                              apiId: 'large',
                              title: {
                                  pl: 'Bez folii duże',
                                  en: 'Without foil - large',
                                  de: 'Ohne Folie, groß',
                                  fr: 'Grand sans film',
                              },
                          },
                      ].filter((item) =>
                          filterOptionsFn(item, OKLADKA_ZNAKOWANIE_TLOCZENIE)
                      )
                  )
                : ''

            const markingExpression = {
                id: 'markingExpression',
                isModal: true,
                apiId: 'OKLADKA_ZNAKOWANIE_TLOCZENIE',
                title: {
                    pl: 'Tłoczenie',
                    en: 'Debossing',
                    de: 'Prägung',
                    fr: 'Embossage',
                },
                choices: expressionChoices,
            }

            const markingCoverFinish = {
                id: 'markingCoverFinish',
                apiId: 'OKLADKA_ZNAKOWANIE_WYKONCZENIE',
                required: coverFinishRequired,
                title: {
                    pl: 'Wykończenie okładki',
                    en: 'Cover finishing',
                    de: 'Art der Veredelung',
                    fr: 'Finition de la couverture',
                },
                choices: [
                    {
                        apiId: 'fms',
                        title: {
                            pl: 'Folia matowa odporna na zarysowania',
                            en: 'Scratch proof matt foil',
                            de: 'kratzfeste Mattfolie',
                            fr: 'Film mat résistant aux rayures',
                        },
                    },
                    {
                        apiId: 'fmsuv',
                        title: {
                            pl: 'Folia matowa z lakierem UV wybiórczo',
                            en: 'Matt foil with spot UV',
                            de: 'kratzfeste Mattfolie mit selektivem UV-Lack',
                            fr: 'Film mat vernis UV sélectivement ',
                        },
                    },
                    {
                        apiId: 'fa',
                        title: {
                            pl: 'Folia aksamitna',
                            en: 'Soft touch foil',
                            de: 'Soft Touch Folie',
                            fr: 'Film soft-touch',
                        },
                    },
                    {
                        apiId: 'fauv',
                        title: {
                            pl: 'Folia aksamitna z lakierem UV wybiórczo',
                            en: 'Soft touch foil with spot UV',
                            de: 'Soft Touch Folie mit selektivem UV-Lack',
                            fr: 'Film soft-touch vernis UV sélectivement',
                        },
                    },
                    {
                        apiId: 'fb',
                        title: {
                            pl: 'Folia błyszcząca',
                            en: 'Glossy foil',
                            de: 'Glanzfolie',
                            fr: 'Film brillant',
                        },
                    },
                ].filter((item) =>
                    filterOptionsFn(item, OKLADKA_ZNAKOWANIE_WYKONCZENIE)
                ),
            }

            return [
                OKLADKA_ZNAKOWANIE_DRUK?.length === 0 ? null : markingPrint,
                OKLADKA_ZNAKOWANIE_TLOCZENIE?.length === 0
                    ? null
                    : markingExpression,
                OKLADKA_ZNAKOWANIE_WYKONCZENIE?.length === 0
                    ? null
                    : markingCoverFinish,
            ].filter((e) => e)
        },
    },
}
</script>
