<template>
    <div
        class="navigation-list-element"
        :class="{
            active: isActive,
            reduced: isReduced,
            disabled: disabled,
        }"
    >
        <div v-if="icon" class="navigation-list-element-icon">
            <component :is="iconModule" />
        </div>
        <div class="navigation-list-element-title" v-text="title[locale]" />
        <div
            class="navigation-list-element-square"
            :style="`background-color: ${color}`"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        icon: {
            type: String,
            default: '',
        },
        title: {
            type: Object,
            default: () => {},
        },
        color: {
            type: String,
            default: 'transparent',
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            isReduced: (state) => state.menu.isReduced,
            locale: (state) => state.i18n.locale,
        }),
        iconModule() {
            return require(`@/assets/${this.icon}`).default
        },
    },
}
</script>

<style lang="scss">
.navigation-list-element {
    display: flex;
    color: $color-alto;
    height: 65px;
    padding-left: 28px;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    position: relative;

    &::after {
        content: '';
        display: block;
        background-color: $color-tundra;
        position: absolute;
        left: 0;
        bottom: -1px;
        @include size(312px, 1px);
    }

    &-title {
        padding-left: 16px;
        font-size: 1.8rem;
    }

    &-square {
        height: inherit;
        width: 32px;
        content: '';
        position: absolute;
        right: 0;
        pointer-events: none;
    }

    &-icon {
        min-width: 35px;
        display: block;
        display: flex;
        justify-content: center;

        svg {
            fill: $color-dove-gray;
        }
    }

    &:hover,
    &.active {
        background: $color-burnt-sienna;
        color: $color-white;
    }

    &:hover &,
    &.active & {
        &-icon {
            svg {
                fill: $color-white;
            }
        }
    }

    &.disabled {
        color: $color-dove-gray;
        cursor: initial;
        &:hover {
            background: transparent;
            color: $color-dove-gray;
        }
    }

    &.disabled:hover & {
        &-icon {
            svg {
                fill: $color-dove-gray;
            }
        }
    }

    &.reduced {
        padding-left: 36px;

        &::after {
            width: 100%;
        }
    }

    &.reduced & {
        &-square {
            width: 8px;
        }

        &-title {
            position: relative;
            right: -60px;
            color: $color-bg-dark;
            text-align: left;
            pointer-events: none;
            opacity: 0;
            white-space: nowrap;
            border: 1px solid $color-border;
            padding: 7px 16px;
            font-size: 1.6rem;
            z-index: 99;
            background: $color-white;

            &::after {
                @include size(10px);
                opacity: inherit;
                content: '';
                display: block;
                position: absolute;
                top: calc(50% - 5px);
                left: -6px;
                transform: rotate(45deg);
                border: solid $color-border;
                border-width: 0 0 1px 1px;
                background: $color-white;
                transition: 0;
            }
        }
    }

    &.reduced:hover & {
        &-title {
            opacity: 1;
            transition: opacity 0.2s;
        }
    }

    @media (max-width: $screen-page) {
        height: 57px;
        padding-left: 22px;

        &::after {
            width: 260px;
        }

        &-title {
            padding-left: 10px;
            font-size: 1.6rem;
        }

        &-square {
            width: 27px;
        }
    }

    @media (max-width: $screen-medium) {
        height: 48px;
    }
}
</style>
