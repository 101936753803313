<template>
    <div
        class="notes-cover"
        :class="{
            'page-paper-left': isPagePaper,
        }"
    >
        <NotesOpen
            v-if="notesOpenPages.indexOf(currentPage) !== -1"
            class="notes-cover-open"
        />
        <NotesEdge v-else-if="currentPage === 'edgeColoration'" />
        <NotesCoverAccessories v-else-if="currentPage === 'accessories'" />
        <!-- pokazuje zagięty róg jak ktoś wybierze 
            "Okładka - znakowanie"=> "Rodzaj zadruki" => "Druk dwustrony" -->
        <NotesCoverMarkings
            v-else-if="currentPage === 'coverMarking' && isDoubleMarkingPrint"
        />
        <NotesCoverFront v-else />
        <NotesCoverPaperZoom v-if="isPagePaper" />
        <NotesCoverPrintZoom v-if="isPageInside" />
    </div>
</template>

<script>
import NotesCoverFront from '@/components/notes/NotesCoverFront'
import NotesOpen from '@/components/notes/NotesOpen'
import NotesCoverPaperZoom from '@/components/notes/NotesCoverPaperZoom'
import NotesCoverPrintZoom from '@/components/notes/NotesCoverPrintZoom'
import NotesEdge from '@/components/notes/NotesEdge'
import NotesCoverAccessories from '@/components/notes/NotesCoverAccessories'
import NotesCoverMarkings from '@/components/notes/NotesCoverMarkings'

import { mapState } from 'vuex'

export default {
    components: {
        NotesCoverPaperZoom,
        NotesCoverPrintZoom,
        NotesOpen,
        NotesCoverFront,
        NotesEdge,
        NotesCoverAccessories,
        NotesCoverMarkings,
    },
    data() {
        return {
            notesOpenPages: ['inside', 'personalized', 'flyleaf'],
        }
    },
    computed: {
        ...mapState({
            currentPage: (state) => state.page.currentPage.id,
            isPagePaper: (state) => state.page.currentPage.id === 'paper',
            isPageInside: (state) => state.page.currentPage.id === 'inside',
            isDoubleMarkingPrint: (state) => {
                return (
                    state.notes.configuration?.markingPrint?.general?.title
                        ?.pl === 'Druk dwustronny'
                )
            },
        }),
        cornerType() {
            return this.configuration?.coverCorners
        },
    },
}
</script>

<style lang="scss">
.page-paper {
    &-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}

.notes-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow: visible;

    > svg {
        height: inherit;
    }

    @media (max-width: $screen-tablet-medium) {
        margin: 0 auto;
        max-width: 90%;

        &-button {
            display: none;
        }
    }
}
</style>
