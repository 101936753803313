// *  dependency Engine * //
import { paramMapper, paramMapperReverse } from '@/helper/ParamMapper'
import hidePagesConditons from '@/helper/HidePageConditions'

const dependencyEngine = function (payloadParam, { state, dispatch }) {
    return {
        isDependant: false,

        set(id, actions, items) {
            const mappedParam = paramMapper(payloadParam)
            let correctParamDependencies = 0

            items.forEach((item) => {
                const notesParams = Object.keys(item)
                const notesParam = notesParams[0]

                // support and handle "operand": "AND" (for multiple items)
                if (
                    this.compare(
                        paramMapperReverse(notesParam),
                        item[notesParam]
                    )
                ) {
                    correctParamDependencies++
                    this.isDependant = true
                }

                if (notesParam === mappedParam) {
                    if (correctParamDependencies === items.length) {
                        // set dependendecy in store
                        dispatch('setDependency', {
                            option: item,
                            newValue: { id, dep: this.isDependant, actions },
                        })

                        this.disablePages(actions)

                        // clear options that colide with saved dependency
                        const actionKeys = Object.keys(actions)
                        actionKeys.forEach((optionToClear) => {
                            dispatch('setNotesConfigWithoutCheck', {
                                param: paramMapperReverse(optionToClear),
                                value: null,
                            })
                        })
                    } else {
                        this.isDependant = false
                        dispatch('setDependency', {
                            option: item,
                            newValue: { id, dep: this.isDependant, actions },
                        })
                    }
                }
            })

            return this
        },

        compare(testParam, testValues) {
            const apiIdFromConfiguration =
                state.configuration[testParam]?.apiId ||
                state.configuration[testParam]?.penHolderColor?.apiId ||
                state.configuration[testParam]?.general?.apiId

            if (testValues) {
                return testValues.includes(apiIdFromConfiguration)
            } else {
                return false
            }
        },

        disablePages(actions) {
            for (let index in hidePagesConditons) {
                let shouldHide = 0
                const page = hidePagesConditons[index]
                for (let pageParamIndex in page.params) {
                    const pageParam = page.params[pageParamIndex]

                    if (actions[pageParam]?.length === 0) {
                        shouldHide++
                    }
                }
                if (shouldHide === page.params.length) {
                    dispatch('disablePageByDependency', page.pageId)
                }
            }
        },
    }
}

export const checkDependencies = function (payload, store) {
    const payloadParam = payload?.param

    const apiList = this.state.dependencies.apiList
    const paramList = this.state.dependencies.paramList
    const dependants = dependencyEngine(payloadParam, store)

    // handle params that are not provided in dependency object
    const paperDependencyPayload = {
        option: {},
        newValue: { id: 'Params', dep: true, actions: paramList },
    }

    store.dispatch('setDependency', paperDependencyPayload)

    const items = [{ MATERIAL: paramList?.MATERIAL }]
    delete paramList?.MATERIAL

    setDependency({ name: 'Params', actions: paramList, items })

    function setDependency({ name, actions, items }) {
        dependants.set(name, actions, items)
    }

    // loop api dependencies
    apiList.forEach((dependency) => setDependency(dependency))

    /*
    const dependants = dependencyEngine(payloadParam, store)
    // # 1
    dependants
        // ustawia zależności w storze tak żeby w komponencie notesu dało się "oifować" opcje
        // pierwszy parametr to "id" do identyfikowania zależności w storze (naprawia też błąd z nakładającymi się zależnościami)
        // "showDoublePrint" to wymyślona nazwa zależności która ma służyć identyfikacji w sekcji konfiguratora
        // { coverType: '1' } to sytuacja w której zależność ma się włączyć
        .set('1', {
            showDoublePrint: { coverType: '1' },
            coverRidgeHard: { coverType: '1' },
            onlyPapier: { coverType: '1' },
        })
        // po wybraniu "coverType: '1'" w notesie czyli "Rodzaj okładki: Miękka papierowa"
        // - strona z wyklejką zostanie wyłączona z menu i zostanie pominięta
        .disablePage('flyleaf')
        // limit na określone rodzaje okleiny filtrowane po apiId (case-sensitive)
        .allowPaperTypes(['250PSBS', '300PURW', '300PK', '240PF', '350PB'])
        // zabezpieczenie przed cofaniem się w konfiguracji notesu
        // w przypadku wybrania jakiejkolwiek opcji z 'coverType' wszystkie opcje wybrane w tablicy z drugiego parametru zostaną wyczyszczone
        .clearOption('coverType', [
            'coverRidge',
            'markingPrint',
            'coverColorType',
            'coverColorPattern',
        ])

    // # 2
    dependants.set('2', {
        pocketIsRequired: { coverType: ['1', '2'], penHolder: 'Y' },
    })

    // # 3
    dependants.set('3', {
        pocketIsRequired: {
            coverType: ['1', '2'],
            elastic: ['7_', '10_', '15_'],
        },
    })

    dependants
        .set('clearPapers', {
            clearPaper: { coverType: '2' },
        })
        .clearPaperTypes()

    // #4
    dependants
        .set('4', {
            hideFourHoles: {
                format: [
                    'B5+',
                    'A5',
                    'Square',
                    'Slim',
                    'Reporter',
                    'A6',
                    'Mini',
                ],
            },
        })
        .clearOption('format', 'binderHoles')
    // # 5
    const dependency5 = { coverType: '2' }
    dependants
        .set('5', {
            coverRidgeSoft: dependency5,
            onlyOkleina: dependency5,
            noMarkingPrint: dependency5,
            noMarkingScreenPrinting: dependency5,
            noMarkingCoverFinish: dependency5,
        })
        .clearOption('coverType', [
            'coverRidge',
            'markingPrint',
            'markingScreenPrinting',
            'markingCoverFinish',
        ])
    // # 6
    dependants
        .set('6', { filterPaperTypes: { coverType: '3' } })
        .allowPaperTypes([
            '120PUK',
            '150PCW',
            '160PURW',
            'VF0401',
            'VF0402',
            'VF0403',
            'VF0404',
            'VF0405',
            'VF0406',
            'VN0102',
            'VL0303',
            'VL0302',
            'VL0301',
            'VL0203',
            'VL0201',
            'VN0106',
            'VN0114',
            'VN0116',
            'VN0107',
            'VN0108',
            'VN0118',
            'VN0119',
            'VN0110',
            'VN0111',
            'VN0501',
            'VN0502',
            'VN0112',
            'VP1202',
            'VT0103',
            'VT0102',
            'VP1201',
            'VP1102',
            'VT0101',
            'VP1205',
            'VP1101',
            'VP0801',
            'VP1204',
            'VP1203',
            'VP0701',
            'VT0104',
            'VT0110',
            'VT0111',
            'VT0105',
            'VT0106',
            'VT0112',
            'VT0107',
            'VT0113',
            'VT0114',
            'VT0108',
            'VT0109',
            'VT0115',
            'VT0121',
            'VT1206',
            'VT0120',
            'VT1205',
            'VT0119',
            'VT1204',
            'VT0118',
            'VT1203',
            'VT1202',
            'VT0117',
            'VT0116',
            'VT1201',
            'VP0901',
            'VP0902',
            'VP0903',
            'VP0904',
            'VP0905',
            'VP0906',
            'VP0907',
            'VP0908',
            'VP0909',
            'VP0910',
            'VP0911',
            'VP0912',
            'VP0913',
            'VP0914',
            'VP1401',
            'VP1402',
            'VP1403',
            'VP1404',
            'VP1405',
            'VP1406',
            'VP1407',
            'VT1301',
            'VT1302',
            'VT1303',
            '140PB',
        ])

    // # 7

    const dependency7 = { coverType: '4' }
    dependants
        .set('7', {
            coverRidgeSoft: dependency7,
            onlyOkleina: dependency7,
            noMarkingPrint: dependency7,
            noMarkingScreenPrinting: dependency7,
            noMarkingCoverFinish: dependency7,
        })
        .allowPaperTypes([
            'VP0701',
            'VP1101',
            'VP1102',
            'VP0901',
            'VP0902',
            'VP0903',
            'VP0904',
            'VP0905',
            'VP0906',
            'VP0907',
            'VP0908',
            'VP0914',
            'VP0913',
            'VP0912',
            'VP0911',
            'VP0910',
            'VP0909',
            'VP1201',
            'VP1202',
            'VP1203',
            'VP1204',
            'VP1205',
        ])
        .clearOption('coverType', [
            'coverRidge',
            'markingPrint',
            'markingScreenPrinting',
            'markingCoverFinish',
        ])

    // # 8
    const dependency8 = {
        coverColorPattern: [
            'VP0701',
            'VP1101',
            'VP1102',
            'VP0901',
            'VP0902',
            'VP0903',
            'VP0904',
            'VP0914',
            'VP0913',
            'VP0912',
            'VP0911',
            'VP0910',
            'VP0909',
            'VP0908',
            'VP0907',
            'VP0906',
            'VP0905',
        ],
    }

    dependants
        .set('8', {
            printOnlyFoil: dependency8,
        })
        .clearOption(['coverColorPattern'], ['markingExpression'])
    // # 9
    const dependency9 = {
        coverColorPattern: [
            'VF0401',
            'VF0402',
            'VF0403',
            'VF0404',
            'VF0405',
            'VF0406',
            'VL0201',
            'VN0107',
            'VN0106',
            'VN0116',
            'VN0114',
            'VN0102',
            'VN0112',
            'VN0111',
            'VL0302',
            'VL0301',
            'VN0110',
            'VN0108',
            'VL0203',
            'VN0118',
            'VP1102',
            'VP1201',
            'VN0119',
            'VN0501',
            'VP1202',
            'VN0502',
            'VP1203',
            'VP0701',
            'VP1204',
            'VP1205',
            'VP0801',
            'VP1101',
            'VT0101',
            'VT0108',
            'VT0115',
            'VT0114',
            'VT0107',
            'VT0106',
            'VT0113',
            'VT0105',
            'VT0112',
            'VT0111',
            'VT0104',
            'VT0103',
            'VT0110',
            'VT0109',
            'VT0116',
            'VT0117',
            'VT0118',
            'VT0119',
            'VT0120',
            'VT0121',
            'VT1201',
            '120PUK',
            'VT1206',
            'VT1205',
            'VT1204',
            'VT1203',
            'VT1202',
            '300PK',
            'VT0102',
            'VP0901',
            'VP0902',
            'VP0903',
            'VP0904',
            'VP0905',
            'VP0906',
            'VP0907',
            'VP0908',
            'VP0909',
            'VP0910',
            'VP0911',
            'VP0912',
            'VP0913',
            'VP0914',
            'VP1401',
            'VP1402',
            'VP1403',
            'VP1404',
            'VP1405',
            'VP1406',
            'VP1407',
            'VT1301',
            'VT1302',
            'VT1303',
            'VL0303',
        ],
    }
    dependants
        .set('9', {
            noMarkingCoverFinish: dependency9,
        })
        .clearOption(['coverColorPattern'], ['markingCoverFinish'])

    // # 10

    dependants
        .set('10', { onlyMat: { coverColorPattern: ['160PURW', '300PURW'] } })
        .clearOption(['coverColorPattern'], ['markingCoverFinish'])
    //  # 11

    dependants
        .set('11', {
            noRibbon: { coverType: ['1', '2'] },
        })
        .clearOption(['coverType'], ['ribbon'])

    //  # 12
    const dependency12 = {
        coverColorPattern: [
            'VF0401',
            'VF0402',
            'VF0403',
            'VF0406',
            'VF0405',
            'VF0404',
            'VN0501',
            'VN0502',
        ],
    }
    dependants
        .set('12', {
            noMarkingPrint: dependency12,
            noMarkingExpression: dependency12,
            noMarkingCoverFinish: dependency12,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            ['markingPrint', 'markingExpression', 'markingCoverFinish']
        )

    //  # 13
    const dependency13 = {
        coverColorPattern: ['250PSBS', '300PURW', '240PF'],
        coverType: '1',
    }
    dependants
        .set('13', {
            noWhiteMarkingPrint: dependency13,
            noMarkingExpression: dependency13,
            noMarkingScreenPrinting: dependency13,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            ['markingExpression', 'markingScreenPrinting', 'markingPrint']
        )

    //  # 14
    //  # 15  tej okleiny nie ma w konfiguratorze i zależność nigdy nie zadziała, dodam w razie gdyby dodali tą okleinę
    const dependency14 = {
        coverColorPattern: ['300PK', '350PB'],
        coverType: '1',
    }
    dependants
        .set('14', {
            noMarkingScreenPrinting: dependency14,
            noMarkingCoverFinish: dependency14,
            printNoColor: dependency14,
            printOnlyFoil: dependency14,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            ['markingPrint', 'markingScreenPrinting', 'markingCoverFinish']
        )

    //  # 16
    const dependency16 = {
        coverColorPattern: [
            'VP0901',
            'VP0902',
            'VP0906',
            'VP0909',
            'VP0912',
            'VP0911',
            'VP0908',
            'VP0905',
            'VP0904',
            'VP0907',
            'VP0910',
            'VP0913',
            'VP0914',
            'VP1201',
            'VP1202',
            'VP1205',
            'VP1204',
            'VP1203',
        ],
        coverType: '4',
    }
    dependants
        .set('16', {
            noMarkingPrint: dependency16,
            noMarkingScreenPrinting: dependency16,
            noMarkingCoverFinish: dependency16,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            ['markingPrint', 'markingScreenPrinting', 'markingCoverFinish']
        )

    //  # 17, #25
    const dependency17 = {
        coverColorPattern: ['VP0701', 'VP1101', 'VP1102'],
        coverType: ['3', '4'],
    }
    dependants
        .set('17', {
            noMarkingPrint: dependency17,
            printOnlyFoil: dependency17,
            noMarkingScreenPrinting: dependency17,
            noMarkingCoverFinish: dependency17,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            [
                'markingPrint',
                'markingScreenPrinting',
                'markingCoverFinish',
                'markingExpression',
            ]
        )

    //  # 18
    const dependency18 = {
        coverColorPattern: ['150PCW'],
        coverType: '3',
    }
    dependants
        .set('18', {
            noMarkingExpression: dependency18,
            noMarkingScreenPrinting: dependency18,
            noWhiteMarkingPrint: dependency18,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            ['markingExpression', 'markingScreenPrinting', 'markingPrint']
        )
    //  # 19
    const dependency19 = {
        coverColorPattern: ['120PUK'],
        coverType: '3',
    }
    dependants
        .set('19', {
            printNoColor: dependency19,
            coverRidgeHard: dependency19,
            noMarkingScreenPrinting: dependency19,
            noMarkingCoverFinish: dependency19,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            ['markingCoverFinish', 'markingScreenPrinting', 'markingPrint']
        )

    //  # 20
    const dependency20 = {
        coverColorPattern: ['160PURW'],
        coverType: '3',
    }
    dependants
        .set('20', {
            noMarkingExpression: dependency20,
            noMarkingScreenPrinting: dependency20,
            onlyMat2: dependency20,
            noWhiteMarkingPrint: dependency20,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            [
                'markingExpression',
                'markingScreenPrinting',
                'markingPrint',
                'markingCoverFinish',
            ]
        )

    //  # 21
    const dependency21 = {
        coverColorPattern: ['140PB'],
        coverType: '3',
    }
    dependants
        .set('21', {
            printNoColor: dependency21,
            printOnlyFoil: dependency21,
            noMarkingScreenPrinting: dependency21,
            noMarkingCoverFinish: dependency21,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            [
                'markingPrint',
                'markingExpression',
                'markingScreenPrinting',
                'markingCoverFinish',
            ]
        )

    //  # 22
    const dependency22 = {
        coverColorPattern: [
            'VT0101',
            'VT0102',
            'VT0103',
            'VT0104',
            'VT0105',
            'VT0106',
            'VT0107',
            'VT0108',
            'VT0109',
            'VT0110',
            'VT0111',
            'VT0112',
            'VT0113',
            'VT0114',
            'VT0115',
            'VT0116',
            'VT0117',
            'VT0118',
            'VT0121',
            'VT0120',
            'VT0119',
        ],
        coverType: '3',
    }
    dependants
        .set('22', {
            printNoColor: dependency22,
            noMarkingScreenPrinting: dependency22,
            noMarkingCoverFinish: dependency22,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            ['markingPrint', 'markingScreenPrinting', 'markingCoverFinish']
        )

    //  # 23
    const dependency23 = {
        coverColorPattern: [
            'VP0901',
            'VP0902',
            'VP0903',
            'VP0904',
            'VP0905',
            'VP0906',
            'VP0907',
            'VP0908',
            'VP0909',
            'VP0910',
            'VP0913',
            'VP0914',
            'VP0911',
            'VP0912',
            'VP1401',
            'VP1402',
            'VP1403',
            'VP1404',
            'VP1405',
            'VP1406',
            'VP1407',
            'VT1301',
            'VT1302',
            'VT1303',
            'VT1201',
            'VT1202',
            'VT1203',
            'VT1204',
            'VT1205',
            'VT1206',
            'VL0201',
            'VL0203',
            'VL0301',
            'VL0303',
            'VL0302',
            'VP1201',
            'VP1202',
            'VP1203',
            'VP1204',
            'VP1205',
        ],
        coverType: '3',
    }
    dependants
        .set('23', {
            noMarkingPrint: dependency23,
            noMarkingScreenPrinting: dependency23,
            noMarkingCoverFinish: dependency23,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            ['markingPrint', 'markingScreenPrinting', 'markingCoverFinish']
        )

    //  # 24
    const dependency24 = {
        coverColorPattern: [
            'VN0106',
            'VN0107',
            'VN0108',
            'VN0110',
            'VN0111',
            'VN0112',
            'VN0114',
            'VN0116',
            'VN0118',
            'VN0119',
        ],
        coverType: '3',
    }
    dependants
        .set('24', {
            noMarkingPrint: dependency24,
            noFoilMarkingExpression: dependency24,
            noMarkingScreenPrinting: dependency24,
            noMarkingCoverFinish: dependency24,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            [
                'markingPrint',
                'markingScreenPrinting',
                'markingExpression',
                'markingCoverFinish',
            ]
        )

    //  # 26
    const dependency26 = {
        coverColorPattern: [
            'VF0401',
            'VF0402',
            'VF0403',
            'VF0404',
            'VF0405',
            'VF0406',
            'VN0501',
            'VN0502',
        ],
        coverType: '3',
    }
    dependants
        .set('26', {
            noMarkingPrint: dependency26,
            noMarkingExpression: dependency26,
            noMarkingCoverFinish: dependency26,
        })
        .clearOption(
            ['coverType', 'coverColorPattern'],
            ['markingPrint', 'markingExpression', 'markingCoverFinish']
        )
    */
}
