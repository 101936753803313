<template>
    <div class="offer">
        <div class="offer-header">
            <h1>{{ $t('offer.title') }}</h1>
            <p>{{ $t('offer.description') }}</p>
        </div>
        <div class="offer-body">
            <OfferCurrency />
            <OfferPrinting />
            <OfferRegion @calculate="calculatePrices" />
        </div>
        <button
            class="form-submit send-button"
            :disabled="!validOffer"
            @click="goToSummary"
            v-text="$t('summary.title')"
        />
    </div>
</template>

<script>
import OfferCurrency from '@/components/offer/OfferCurrency'
import OfferPrinting from '@/components/offer/OfferPrinting'
import OfferRegion from '@/components/offer/OfferRegion'
import { mapActions } from 'vuex'
import ConfigurationNumber from "@/mixins/ConfigurationNumber"
import {delay} from "@/helper/DelayHelper"

export default {
    components: {
        OfferCurrency,
        OfferPrinting,
        OfferRegion,
    },
    mixins: [ConfigurationNumber],
    data() {
        return {
            calculationError: false
        }
    },
    computed: {
        validOffer() {
            return this.$store.getters.validValuationConfig
        }
    },
    methods: {
        ...mapActions(['changePage', 'showLoader', 'showLoaderError', 'hideLoader']),
        async goToSummary() {
            this.calculationError = false
            try {
                await this.calculatePrices()
                if (!this.calculationError){
                    this.changePage('summaryPage')
                }
            } catch (e) {
                this.showLoaderError()
            }


        },
        async calculatePrices() {
            this.showLoader({
                message: 'offer.info.valuation',
                loading: true,
            })
            try {
                await delay()
                await this.getConfigNumber(true)
                    .then(() => {
                        this.$store.commit('setValidPrices', {
                            calculation: this.$store.state.notes.configurationApiCode?.calculationId,
                            prices: this.$store.state.notes.configurationApiCode?.prices
                        })
                        this.hideLoader()
                    })
                    .catch(() => {
                        this.calculationError = true
                        this.showLoaderError()
                    })
            } catch (e) {
                this.calculationError = true
                this.showLoaderError()
                console.error(e)
            }
        }
    },
}
</script>

<style lang="scss">
.offer {
    height: 100%;
    overflow: auto;
    padding: 30px 0;
    @include hide-scrollbar();

    @media (max-width: $screen-tablet-medium) {
        padding-top: 40px;
        margin: 0 50px;
    }
    @media (max-width: $screen-mobile-large) {
        margin: 0 20px;
        padding-top: 20px;
        padding-bottom: 40px;
    }
}
</style>
