export const currencies = [
    {
        id: 'PLN',
        title: 'PLN',
        symbol: 'zł',
    },
    {
        id: 'EUR',
        title: 'EUR',
        symbol: '€',
        isOnLeftSide: true,
    },
    /*  {
        id: 'USD',
        title: {
            pl: 'USD',
            en: 'USD',
            de: 'USD',
            fr: 'USD',
        },
        symbol: '$',
        isOnLeftSide: true,
    },
    {
        id: 'GBP',
        title: {
            pl: 'GBP',
            en: 'GBP',
            de: 'GBP',
            fr: 'GBP',
        },
        symbol: '£',
        isOnLeftSide: true,
    },
    {
        id: 'SEK',
        title: {
            pl: 'SEK',
            en: 'SEK',
            de: 'SEK',
            fr: 'SEK',
        },
        symbol: 'kr',
    },*/
]
