import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            flyleafPaperImage: (state) => {
                return state.notes.configuration.flyleafPaper?.image
            },
            flyleafPaperColor: (state) => {
                return state.notes.configuration.flyleafPaper?.hex
            },
            paperTypeImage: (state) => {
                return state.notes.configuration.paperType?.image
            },
            paperTypeColor: (state) => {
                return state.notes.configuration.paperType?.hex
            },
            pocket: (state) => state.notes.configuration.pocket,
        }),
        colorInsideStyle() {
            if (this.pocket?.doublePocketPrint) {
                return null
            }
            if (this.flyleafPaperImage || this.flyleafPaperColor) {
                return `fill: ${
                    this.flyleafPaperImage
                        ? 'url(#flyleafPaper)'
                        : this.flyleafPaperColor
                }`
            }
            if (this.paperTypeImage || this.paperTypeColor) {
                return `fill: ${
                    this.paperTypeImage
                        ? 'url(#paperType)'
                        : this.paperTypeColor
                }`
            }
            return null
        },
        colorStyle() {
            return !this.colorClass ? this.colorInsideStyle : null
        },
        colorClass() {
            if (this.pocketPrint === '1 + 0') {
                return 'one'
            }
            if (this.pocketPrint === 'CMYK + 0') {
                return 'cmyk'
            }
            if (this.pocketPrint === '1 + 1') {
                return 'oneone'
            }
            if (this.pocketPrint === 'CMYK + CMYK') {
                return 'cmykcmyk'
            }
            return this.flyleafPaperImage ? 'notes-pocket-color-flyleaf' : ''
        },
    },
}
