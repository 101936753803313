<template>
    <!-- eslint-disable -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="svg[formatGroup].width"
        :height="svg[formatGroup].height"
        :viewBox="svg[formatGroup].viewBox"
        class="notes-cover-image"
        :class="[
            `notes-cover-image--${formatGroup} notes-cover-image--${formatSpecific}`,
            currentPage.id === 'format'
                ? `notes-cover-image--zoom-${formatSpecific}`
                : '',
        ]"
    >
        <defs>
            <NotesCoverFrontDefs />
            <PaperPattern :background="background" />
        </defs>

        <g id="Notes" :transform="svg[formatGroup].notesTransform">
            <image
                v-if="
                    currentPage &&
                    currentPage.id !== 'paper' &&
                    formatGroup === 'vertical'
                "
                id="shadow"
                class="notes-shadow-image"
                :width="svg[formatGroup].width"
                :height="svg[formatGroup].height"
                transform="translate(568 140)"
                xlink:href="/img/notes/Notes-image.png"
            />

            <g id="notes-2" data-name="notes">
                <g
                    class="notes-shadow"
                    :transform="svg[formatGroup].shadowTransform"
                >
                    <path
                        id="shadow_vector-2"
                        data-name="shadow_vector"
                        :d="svg[formatGroup].shadowPathD"
                        :transform="svg[formatGroup].shadowPathTransform"
                    />
                </g>

                <!--  rog-->
                <g transform="translate(691 259)">
                    <defs>
                        <linearGradient
                            id="notes-cmyk-gradient"
                            x1="1.082"
                            y1="0.819"
                            x2="0.269"
                            y2="0.16"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop offset="0" stop-color="#516e8a" />
                            <stop offset="0.171" stop-color="#395168" />
                            <stop offset="0.385" stop-color="#27374a" />
                            <stop offset="0.59" stop-color="#1f2c39" />
                            <stop offset="0.837" />
                            <stop offset="1" stop-color="#2e4358" />
                        </linearGradient>
                        <linearGradient
                            id="marking-horizontal-gradient"
                            x1="0.069"
                            y1="0.046"
                            x2="0.659"
                            y2="0.734"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop offset="0" stop-color="#fff" />
                            <stop offset="0.319" stop-color="#fcfcfc" />
                            <stop offset="1" stop-color="gray" />
                        </linearGradient>
                        <filter
                            id="shadow_vector"
                            x="65.738"
                            y="23.999"
                            width="347"
                            height="244"
                            filterUnits="userSpaceOnUse"
                        >
                            <feOffset dx="20" dy="20" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="9" result="blur" />
                            <feFlood flood-opacity="0.102" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                        <linearGradient
                            id="marking-horizontal-gradient-2"
                            x1="0.756"
                            y1="0.672"
                            x2="0.035"
                            y2="0.087"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop offset="0" stop-color="#868686" />
                            <stop offset="1" stop-color="#434343" />
                        </linearGradient>
                        <filter
                            id="Path_9592"
                            x="311.356"
                            y="172.353"
                            width="66.92"
                            height="68.3"
                            filterUnits="userSpaceOnUse"
                        >
                            <feOffset dx="1" dy="-1" input="SourceAlpha" />
                            <!-- <feGaussianBlur
                                stdDeviation="1.5"
                                result="blur-2"
                            />
                            <feFlood flood-color="#5d5d5d" /> -->
                            <feComposite operator="in" in2="blur-2" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                        <linearGradient
                            id="marking-horizontal-gradient-3"
                            x1="1.05"
                            y1="0.843"
                            x2="0.455"
                            y2="0.332"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop
                                offset="0"
                                stop-color="#b1b1b1"
                                stop-opacity="0"
                            />
                            <stop
                                offset="0.688"
                                stop-color="#6d6d6d"
                                stop-opacity="0"
                            />
                            <stop
                                offset="0.9"
                                stop-color="#040404"
                                stop-opacity="0.588"
                            />
                            <stop offset="1" />
                        </linearGradient>
                        <filter
                            id="Path_10617"
                            x="311.31"
                            y="172.38"
                            width="66.904"
                            height="68.413"
                            filterUnits="userSpaceOnUse"
                        >
                            <feOffset dx="1" dy="-1" input="SourceAlpha" />
                            <feGaussianBlur
                                stdDeviation="1.5"
                                result="blur-3"
                            />
                            <feFlood flood-color="#5d5d5d" />
                            <feComposite operator="in" in2="blur-3" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>

                    <g
                        id="Group_2034"
                        data-name="Group 2034"
                        transform="matrix(1, -0.017, 0.017, 1, -5763.817, -3863.805)"
                    >
                        <!-- ramka -->
                        <g
                            id="Path_10619"
                            data-name="Path 10619"
                            transform="translate(5775.221 3965.856) rotate(1)"
                            :fill="cornerFill"
                        >
                            <path
                                d="M 292.4375 225.70361328125 L -3.218273878097534 225.70361328125 L 0.4918286800384521 0.4999989569187164 L 292.4375 0.4999989569187164 L 292.4375 225.70361328125 Z"
                                stroke="none"
                            />
                            <path
                                d="M 0.983642578125 1 L -2.709991455078125 225.20361328125 L 291.9375 225.20361328125 L 291.9375 1 L 0.983642578125 1 M 0 0 L 292.9375 0 L 292.9375 226.20361328125 L -3.7265625 226.20361328125 L 0 0 Z"
                                stroke="none"
                                fill="#868686"
                            />
                        </g>

                        <!-- biała kartka -->
                        <g
                            transform="matrix(1, 0.02, -0.02, 1, 5695.51, 3963.81)"
                            filter="url(#shadow_vector)"
                        >
                            <rect
                                id="shadow_vector-2"
                                data-name="shadow_vector"
                                width="293"
                                height="190"
                                transform="translate(80 36)"
                                fill="#fff"
                            />

                            <image
                                href="/img/notes/marking/shadow2.png"
                                height="120"
                                width="139"
                                transform="translate(316 178) scale(0.45)"
                                opacity="0.8"
                                style="
                                    mix-blend-mode: multiply;
                                    isolation: isolate;
                                "
                            />
                        </g>

                        <!-- ?? -->
                        <!-- <rect
                            id="Rectangle_1493"
                            data-name="Rectangle 1493"
                            width="374"
                            transform="translate(5696.244 3963.822) rotate(1)"
                            fill="rgba(255,255,255,0.38)"
                        /> -->

                        <!-- okleina -->

                        <g
                            id="Zmiana_koloru_lub_maska"
                            transform="matrix(1, 0.017, -0.017, 1, 5695.495, 3964.428)"
                            fill="url(#cover)"
                        >
                            <path
                                d="M0,0C367.309,0,373,.032,373,.032l-.115,189.28-49.641,37.354L0,226.628Z"
                                stroke="none"
                            />
                            <path
                                d="M 1 1.000015258789063 L 1 225.6285247802734 L 322.9139099121094 225.6654815673828 L 371.8889465332031 188.8127899169922 L 372.0032958984375 1.030929565429688 C 365.7801513671875 1.0244140625 321.0758361816406 1.00006103515625 1 1.000015258789063 M 0 1.52587890625e-05 C 367.3087158203125 1.52587890625e-05 373.00390625 0.032470703125 373.00390625 0.032470703125 L 372.8886413574219 189.31201171875 L 323.248046875 226.6655120849609 L 0 226.62841796875 L 0 1.52587890625e-05 Z"
                                stroke="none"
                                fill="rgba(0,0,0,0.08)"
                            />
                        </g>
                    </g>
                </g>

                <clipPath id="cover-marking-horizontal-clip-path">
                    <path
                        d="M0,0C367.309,0,373,.032,373,.032l-.115,189.28-49.641,37.354L0,226.628Z"
                        transform="translate(690 263)"
                    />
                </clipPath>

                <!-- markings -->
                <g>
                    <Marking
                        :format="formatGroup"
                        clip-path="url(#cover-marking-horizontal-clip-path)"
                    />
                </g>

                <g transform="translate(691 259)">
                    <g
                        id="Group_2034-x"
                        data-name="Group 2034-x"
                        transform="matrix(1, -0.017, 0.017, 1, -5763.817, -3863.805)"
                    >
                        <!-- róg -->
                        <g
                            transform="matrix(1, 0.02, -0.02, 1, 5695.51, 3963.81)"
                            filter="url(#Path_9592)"
                        >
                            <image
                                href="/img/notes/marking/shadow.png"
                                height="120"
                                width="139"
                                transform="translate(310 172) scale(0.45)"
                                style="
                                    mix-blend-mode: multiply;
                                    isolation: isolate;
                                "
                            />
                            <!-- róg -->
                            <path
                                id="Path_9592-2"
                                data-name="Path 9592"
                                d="M38.652,0s1.919,3.493,5.608,17.038,2.4,23.536,2.4,23.536a86.516,86.516,0,0,0-20.852-.141C13.67,41.714,0,48.831,0,48.831L38.652,0Z"
                                transform="translate(360.14 237.15) rotate(-165)"
                                :fill="cornerFill"
                            />
                        </g>

                        <!-- cień ? -->
                        <path
                            id="Path_9594"
                            data-name="Path 9594"
                            d="M4.392,0C-2.708,19.7.934,41.62.934,41.62l5.516-4.429C-.311,34.858,4.392,0,4.392,0Z"
                            transform="translate(6013.447 4155.032)"
                            fill="#fff"
                            opacity="0.04"
                        />

                        <!-- cień -->
                        <g
                            transform="matrix(1, 0.02, -0.02, 1, 5695.51, 3963.81)"
                            filter="url(#Path_10617)"
                        >
                            <path
                                id="Path_10617-2"
                                data-name="Path 10617"
                                d="M38.61,0s1.917,3.5,5.6,17.084,2.4,23.6,2.4,23.6a86.1,86.1,0,0,0-20.829-.142C13.655,41.825,0,48.961,0,48.961L38.61,0Z"
                                transform="translate(360.04 237.29) rotate(-165)"
                                opacity="0.39"
                                fill="url(#marking-horizontal-gradient-3)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import PaperPattern from '@/components/notes/paper/PaperPattern'
import Marking from '@/components/notes/marking'

import NotesCoverFrontDefs from '@/components/notes/NotesCoverFrontDefs'
import NotesFormatMixin from '@/mixins/NotesFormatMixin'

import { mapState } from 'vuex'

export default {
    components: {
        PaperPattern,
        Marking,
        NotesCoverFrontDefs,
    },
    mixins: [NotesFormatMixin],

    data() {
        return {
            defaultBackground: '#7e7e7e',
            svg: {
                vertical: {
                    viewBox: '0 0 758 880',
                    width: '758',
                    height: '880',
                    notesTransform: 'translate(-568 -140)',
                    shadowTransform: 'matrix(1, 0, 0, 1, 568, 140)',
                    shadowPathTransform: 'translate(123 126)',
                    shadowPathD:
                        'M0,0H367a12,12,0,0,1,12,12V520a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
                horizontal: {
                    viewBox: '0 0 423 278',
                    width: '423',
                    height: '278',
                    notesTransform: 'translate(-684 -254.588)',
                    shadowTransform: 'matrix(1, 0, 0, 1, 684, 256.59)',
                    shadowPathTransform: 'translate(7 7)',
                    shadowPathD:
                        'M0,0H357a12,12,0,0,1,12,12V212a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
                square: {
                    viewBox: '0 0 532 428',
                    width: '532',
                    height: '428',
                    notesTransform: 'translate(-583 -198.273)',
                    shadowTransform: 'translate(583 198.273)',
                    shadowPathTransform: 'translate(108 64)',
                    shadowPathD:
                        'M0,0H249a12,12,0,0,1,12,12V246a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
            },
        }
    },
    computed: {
        ...mapState({
            cornerFillColor: (state) => state.notes.cornerFillColor,
            configuration: (state) => state.notes.configuration,
            background: (state) =>
                state.notes.configuration.coverColorPattern?.image || null,
            currentPage: (state) => state.page.currentPage,
            patternTheme: (state) =>
                state.notes.configuration.coverColorPattern?.theme,
            doubleMarkingPrint: (state) =>
                state.notes.configuration.markingPrint?.doubleMarkingPrint,
        }),
        cornerFill() {
            // if (this.doubleMarkingPrint?.title?.pl.startsWith('CMYK')) {
            //     return 'url(#notes-cmyk-gradient)'
            // } else {
            //     return this.background ? 'url(#cover)' : this.defaultBackground
            // }
            return this.cornerFillColor
        },
    },
}
</script>

<style lang="scss">
.notes-cover-image {
    margin: 0 -50px 0 0;
    pointer-events: none;

    &--zoom {
        &-b5 {
            transform: scale(0.88, 0.825);
        }
        &-a5 {
            transform: scale(0.705, 0.71);
        }
        &-125 {
            transform: scale(0.6, 0.69);
        }
        &-a6 {
            transform: scale(0.5, 0.5);
        }
        &-84 {
            transform: scale(0.401, 0.455);
        }
    }

    &--vertical {
        .notes-shadow {
            filter: url(#vertical-shadow_vector);
        }
    }
    &--horizontal {
        .notes-shadow {
            filter: url(#horizontal-shadow_vector);
        }
    }

    &--square {
        zoom: 1.3;

        .notes-shadow {
            filter: url(#square-shadow_vector);
        }
    }

    @media (max-width: $screen-medium) {
        height: 600px;
        margin: 0 -70px 0 -30px;

        &--vertical {
            width: auto;
        }
    }
    @media (max-width: $screen-desktop) {
        &--horizontal {
            zoom: 0.8;
        }
    }
    @media (max-width: $screen-tablet-medium) {
        margin: -80px auto;

        &--horizontal {
            zoom: 1;
            margin: -120px auto -100px;
        }
    }
}
.notes-shadow-image {
    opacity: 0.8;
}
</style>
