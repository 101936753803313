export const WindowHelper = {
    getHeight() {
        return (
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight
        )
    },

    getWidth() {
        return (
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
        )
    },

    getScrollTop() {
        const html = document.documentElement

        return Math.round(
            (window.pageYOffset || html.scrollTop) - (html.clientTop || 0)
        )
    },

    isFullscreen() {
        return (
            document.fullScreen ||
            document.mozFullScreen ||
            document.webkitIsFullScreen
        )
    },
}
