<template>
    <div
        :class="[
            'input-checkbox-children',
            {
                'is-open': open,
                'is-selected': currentSettings[id],
                'is-disabled': disabled,
            },
        ]"
    >
        <div
            :class="[
                'input-checkbox-children-title',
                { colors: type === 'colors' },
            ]"
            @click="toggleSubOption"
        >
            <div class="input-mark" />
            <div class="input-checkbox-children-title-label">
                {{
                    currentSettings[id]
                        ? currentSettings[id].title
                            ? currentSettings[id].title[locale]
                            : title
                        : title
                }}
                <WarningIcon
                    v-if="required"
                    class="input-checkbox-children-title-label-icon"
                />
            </div>
        </div>
        <Transition name="fade">
            <InputCheckboxChildrenItem
                :id="id"
                :open="open"
                :title="title"
                :items="filteredItems"
                :current-settings="currentSettings"
                :type="type"
                @select-item="selectItem"
                @toggle="toggleSubOption"
            />
        </Transition>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import InputCheckboxChildrenItem from '@/components/input/InputCheckboxChildrenItem'
import WarningIcon from '@/assets/images/system-icons/warning-icon.svg'

export default {
    name: 'InputCheckboxChildren',
    components: {
        InputCheckboxChildrenItem,
        WarningIcon,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
        currentSettings: {
            type: Object,
            default: () => {},
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapState({
            currentSubOption: (state) => state.notes.currentSubOption,
            screenWidth: (state) => state.page.screenWidth,
            locale: (state) => state.i18n.locale,
            isCornerRound: (state) =>
                state.notes.configuration?.coverCorners?.title?.pl ===
                'Zaokrąglone',
        }),
        filteredItems() {
            if (this.isCornerRound) {
                return this.items.filter((item) => !item.hideIfCornerRound)
            }
            return this.items
        },
        open() {
            return this.id === this.currentSubOption
        },
    },
    mounted() {
        // auto open next dropdown option
        // check if it is not already selected (when changing pages)
        if (
            this.currentSettings &&
            Object.keys(this.currentSettings).length < 2
        ) {
            this.toggleSubOption()
        }
    },
    methods: {
        ...mapMutations(['setCurrentSubOption', 'setOverlay']),
        toggleSubOption() {
            this.setCurrentSubOption(this.open ? null : this.id)

            this.$emit('select', { id: this.id, value: null })
            if (this.screenWidth <= 900) {
                this.setOverlay(this.open)
            }
        },
        selectItem(item) {
            // add id to value if id exists (also display that id)
            // const value = `${item.title[this.locale]}${item.id !== undefined && item.id !== null? ' - '+ item.id : '' }`
            this.$emit('select', { id: this.id, value: item })
            this.setCurrentSubOption(null)
            if (this.screenWidth <= 900) {
                this.setOverlay(this.open)
            }
        },
    },
}
</script>

<style lang="scss">
.input-checkbox-children {
    position: relative;
    font-size: 16px;

    &-title {
        position: relative;
        padding: 16px 28px 15px;
        border-top: $color-alto solid 1px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 40px;

        &:after {
            content: '';
            display: block;
            border-bottom: $color-tundra solid 2px;
            border-right: $color-tundra solid 2px;
            position: absolute;
            right: 28px;
            top: 16px;
            transform-origin: 70% 70%;
            transform: rotate(45deg);
            transition: transform 0.3s;
            @include size(11px);
        }

        &-label {
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &-icon {
                margin-right: 20px;
            }
        }

        .input-mark {
            margin-right: 15px;
            display: none;

            &:after {
                opacity: 1;
            }
        }
    }

    &.is-open & {
        &-title {
            background: $color-bg-light;
            border: $color-border-input solid 1px;

            &.colors {
                background: $color-white;
                border-bottom: none;
            }

            &:after {
                transform: rotate(-135deg);
            }
        }
    }

    &.is-selected {
        .input-mark {
            display: block;
        }
    }

    &.is-selected & {
        &-title {
            &-label {
                &-icon {
                    path {
                        fill: transparent;
                    }
                }
            }
        }
    }

    &.is-disabled {
        display: none;
    }

    @media (max-width: $screen-tablet-medium) {
        position: static;
    }
}
</style>
