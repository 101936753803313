<template>
    <div :class="['form-message', result]" v-text="message" />
</template>

<script>
export default {
    props: {
        result: {
            type: String,
            default: '',
        },
    },
    computed: {
        message() {
            switch (this.result) {
                case 'error':
                    return 'Błąd. Spróbuj ponownie za chwilę.'
                    break
                case 'success':
                    return 'Twoje zgłoszenie zostało wysłane. (testowy komunikat)'
                    break
                default:
                    return ''
            }
        },
    },
}
</script>

<style lang="scss">
.form-message {
    padding-bottom: 20px;
    font-size: 17px;
    font-weight: 600;

    &.success {
        color: $color-success;
    }

    &.error {
        color: $color-burnt-sienna;
    }
}
</style>
