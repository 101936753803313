<template>
    <div class="list-colors">
        <div
            v-for="color in colors"
            :key="color.title[locale]"
            class="list-colors-color"
            @click="$emit('select', color)"
        >
            <img
                v-if="color.image"
                :src="color.image"
                :alt="color.title"
                :title="capitalize(color.title[locale])"
            />
            <div
                v-else-if="color.hex"
                class="list-colors-color-hex"
                :style="`background-color: ${color.hex}; ${
                    color.hasBorder ? 'border:1px solid #EAEAEA;' : ''
                }`"
                :title="capitalize(color.title[locale])"
            />
            <div
                v-if="selected && selected.name === color.title"
                class="list-colors-check"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        colors: {
            type: Object,
            default: () => {},
        },
        selected: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapState({
            locale: (state) => state.i18n.locale,
        }),
    },
    methods: {
        capitalize(value) {
            if (!value) {
                return ''
            }
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
}
</script>

<style lang="scss">
.list-colors {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    border: solid $color-silver;
    border-width: 0 1px 1px 1px;
    padding: 10px;
    z-index: 99;
    position: relative;
    background: $color-white;

    &-check {
        border: solid $color-white;
        border-width: 3px 3px 0 0;
        position: absolute;
        top: 24px;
        left: 20px;
        transform: rotate(135deg);
        @include size(16px, 8px);
    }

    &-color {
        padding: 10px;
        cursor: pointer;
        position: relative;

        &-hex {
            @include size(35px);
            border-radius: 50%;
        }

        img {
            @include size(35px);
        }
    }

    @media (max-width: $screen-medium) {
        padding: 0;
        max-height: 300px;
        overflow: auto;

        &-color {
            padding: 8px;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        z-index: 5;
        padding: 30px 39px;
        border-width: 1px;
        max-height: 65vh;

        &-color {
            padding: 10px;
            @include size(70px);
        }

        &-check {
            top: 28px;
            left: 28px;
        }
    }

    @media (max-width: $screen-mobile-large) {
        padding: 10px;
    }
}
</style>
