<template>
    <div>
        <MarkingNotesHorizontal v-if="formatGroup === 'horizontal'" />
        <MarkingNotesSquare v-else-if="formatGroup === 'square'" />
        <MarkingNotesVertical v-else />
    </div>
</template>

<script>
import NotesFormatMixin from '@/mixins/NotesFormatMixin'
import MarkingNotesVertical from '@/components/notes/marking-notes/MarkingNotesVertical'
import MarkingNotesHorizontal from '@/components/notes/marking-notes/MarkingNotesHorizontal'
import MarkingNotesSquare from '@/components/notes/marking-notes/MarkingNotesSquare'

export default {
    components: {
        MarkingNotesHorizontal,
        MarkingNotesVertical,
        MarkingNotesSquare,
    },
    mixins: [NotesFormatMixin],
}
</script>
