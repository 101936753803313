<template>
    <!-- eslint-disable -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="svg[formatGroup].width"
        :height="svg[formatGroup].height"
        :viewBox="customViewBox || svg[formatGroup].viewBox"
        class="notes-cover-image"
        :class="[
            `notes-cover-image--${formatGroup} notes-cover-image--${formatSpecific}`,
            currentPage.id === 'format'
                ? `notes-cover-image--zoom-${formatSpecific}`
                : '',
        ]"
    >
        <defs>
            <NotesCoverFrontDefs />
            <PaperPattern :background="background" />
        </defs>

        <g id="Notes" :transform="svg[formatGroup].notesTransform">
            <image
                v-if="
                    currentPage &&
                    currentPage.id !== 'paper' &&
                    formatGroup === 'vertical'
                "
                id="shadow"
                class="notes-shadow-image"
                :width="svg[formatGroup].width"
                :height="svg[formatGroup].height"
                transform="translate(568 140)"
                xlink:href="/img/notes/Notes-image.png"
            />

            <g id="notes-2" data-name="notes" class="notes-cover-zoom">
                <g
                    class="notes-shadow"
                    :transform="svg[formatGroup].shadowTransform"
                >
                    <path
                        id="shadow_vector-2"
                        data-name="shadow_vector"
                        :d="svg[formatGroup].shadowPathD"
                        :transform="svg[formatGroup].shadowPathTransform"
                    />
                </g>
                <!-- narożniki proste/zaokrąglone -->
                <Corners
                    :is-round="
                        configuration.coverCorners &&
                        configuration.coverCorners.title.pl === `Zaokrąglone`
                    "
                    :background="background"
                    :default-background="defaultBackground"
                    :format="formatGroup"
                />
                <!-- okładka - znakowanie  -->
                <Marking :format="formatGroup" />

                <!-- grzbiet miękki/twardy -->
                <Ridge
                    :soft-cover="
                        configuration.coverType &&
                        configuration.coverType.title.pl === 'Miękka papierowa'
                    "
                    :format="formatGroup"
                    :pattern-theme="patternTheme || 'dark'"
                />
                <Accessories
                    :format="formatGroup"
                    :last-selected-accessory="lastSelectedAccessory"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import Ridge from './cover/Ridge'
import Corners from './cover/Corners'
import PaperPattern from './paper/PaperPattern'
import Marking from './marking'
import Accessories from './accessories'

import NotesCoverFrontDefs from './NotesCoverFrontDefs'
import NotesFormatMixin from '@/mixins/NotesFormatMixin'

import { mapState } from 'vuex'

export default {
    components: {
        Ridge,
        Corners,
        PaperPattern,
        Marking,
        Accessories,
        NotesCoverFrontDefs,
    },
    mixins: [NotesFormatMixin],
    props: {
        customViewBox: {
            type: String,
            default: null,
        },
        // pass to accessories
        lastSelectedAccessory: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            defaultBackground: '#7e7e7e',
            svg: {
                vertical: {
                    viewBox: '0 0 758 880',
                    width: '758',
                    height: '880',
                    notesTransform: 'translate(-568 -140)',
                    shadowTransform: 'matrix(1, 0, 0, 1, 568, 140)',
                    shadowPathTransform: 'translate(123 126)',
                    shadowPathD:
                        'M0,0H367a12,12,0,0,1,12,12V520a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
                horizontal: {
                    viewBox: '0 0 423 278',
                    width: '423',
                    height: '278',
                    notesTransform: 'translate(-684 -254.588)',
                    shadowTransform: 'matrix(1, 0, 0, 1, 684, 254.59)',
                    shadowPathTransform: 'translate(7 7)',
                    shadowPathD:
                        'M0,0H357a12,12,0,0,1,12,12V212a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
                square: {
                    viewBox: '0 0 532 428',
                    width: '532',
                    height: '428',
                    notesTransform: 'translate(-583 -198.273)',
                    shadowTransform: 'translate(583 198.273)',
                    shadowPathTransform: 'translate(108 64)',
                    shadowPathD:
                        'M0,0H249a12,12,0,0,1,12,12V246a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
            },
        }
    },
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
            background: (state) =>
                state.notes.configuration.coverColorPattern?.image || null,
            currentPage: (state) => state.page.currentPage,
            patternTheme: (state) =>
                state.notes.configuration.coverColorPattern?.theme,
            doubleMarkingPrint: (state) =>
                state.notes.configuration.markingPrint?.doubleMarkingPrint,
        }),
    },
}
</script>

<style lang="scss" scoped>
.notes-cover-image {
    margin: 0 -50px 0 0;
    pointer-events: none;
    overflow: visible;

    &--zoom {
        &-b5 {
            transform: scale(0.88, 0.825);
        }
        &-a5 {
            transform: scale(0.705, 0.71);
        }
        &-125 {
            transform: scale(0.6, 0.69);
        }
        &-a6 {
            transform: scale(0.5, 0.5);
        }
        &-84 {
            transform: scale(0.401, 0.455);
        }
    }

    .notes-cover-zoom {
        transform: scale(1.3);
        transform-origin: 127% 50%;
    }

    &--vertical {
        .notes-shadow {
            filter: url(#vertical-shadow_vector);
        }
    }
    &--horizontal {
        .notes-shadow {
            filter: url(#horizontal-shadow_vector);
        }
        .notes-cover-zoom {
            transform: scale(1.1);
            transform-origin: 170% 50%;
        }
    }

    &--square {
        zoom: 1.3;

        .notes-shadow {
            filter: url(#square-shadow_vector);
        }
        .notes-cover-zoom {
            transform: scale(1.2);
            transform-origin: 157% 50%;
        }
    }

    @media (max-width: $screen-medium) {
        height: 600px;
        margin: 0 -70px 0 -30px;

        &--vertical {
            width: auto;
        }
    }
    @media (max-width: $screen-desktop) {
        &--horizontal {
            zoom: 0.8;
        }
    }
    @media (max-width: $screen-tablet-medium) {
        margin: -80px auto;

        &--horizontal {
            zoom: 1;
            margin: -120px auto -100px;
        }
    }
}
.notes-shadow-image {
    opacity: 0.8;
}
</style>
