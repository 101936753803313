import { render, staticRenderFns } from "./ModalPaperTypeHardElastic.vue?vue&type=template&id=c6447e48&"
import script from "./ModalPaperTypeHardElastic.vue?vue&type=script&setup=true&lang=js&"
export * from "./ModalPaperTypeHardElastic.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ModalPaperTypeHardElastic.vue?vue&type=style&index=0&id=c6447e48&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports