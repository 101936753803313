import Vue from 'vue'

Vue.use({
    install: function (Vue) {
        /**
         * Bastards method - inserts non breaking space between single chars
         *
         * @param value
         * @returns {*}
         */
        let bastards = function (value) {
            if (value === undefined || value === null) {
                return
            }

            value = value.replace(/(\s)([\S])[\s]+/g, '$1$2&nbsp;')
            value = value.replace(
                /(\s)([\S])(\s)([\S])[\s]+/g,
                '$1$2&nbsp;$4&nbsp;'
            )
            value = value.replace(
                /(\s)([\S])(&nbsp;)([\S])[\s]+/g,
                '$1$2$3$4&nbsp;'
            )
            value = value.replace(/(\s)([^<][\S])[\s]+/g, '$1$2&nbsp;')

            return value
        }

        Vue.filter('bastards', bastards)

        Vue.directive('bastards', function (element) {
            element.innerHTML = bastards(element.innerHTML)
        })
    },
})
