<template>
    <div>
        <transition name="fade">
            <div v-if="showZoom && isPatternSelected" class="page-paper-zoom">
                <div class="page-paper-zoom-close" @click="closeZoom">
                    &times;
                </div>
                <img :src="configuration.coverColorPattern.imageLarge" alt="" />
                <div
                    v-bastards
                    class="page-paper-zoom-description"
                    v-text="configuration.coverColorPattern.title[locale]"
                />
            </div>
        </transition>
        <ButtonZoom
            class="notes-cover-button"
            :text="$t('general.zoom')"
            :active="isZoomButtonActive"
            @action="zoomPattern"
        />
    </div>
</template>

<script>
import ButtonZoom from '@/components/button/ButtonZoom'
import { mapState } from 'vuex'

export default {
    components: {
        ButtonZoom,
    },
    data() {
        return {
            showZoom: false,
        }
    },
    computed: {
        ...mapState({
            locale: (state) => state.i18n.locale,
            configuration: (state) => state.notes.configuration,
        }),
        isZoomButtonActive() {
            if (this.isPatternSelected) {
                return true
            }
            return false
        },
        isPatternSelected() {
            return (
                this.configuration.coverColorPattern &&
                this.configuration.coverColorPattern.title
            )
        },
    },
    methods: {
        zoomPattern() {
            this.showZoom = true
        },
        closeZoom() {
            this.showZoom = false
        },
    },
}
</script>

<style lang="scss">
.page-paper-zoom {
    position: absolute;
    @include size(320px);
    left: 20px;
    top: 130px;
    border: 2px solid $color-silver;

    &-close {
        position: absolute;
        top: -2px;
        right: -2px;
        background: rgba(0, 0, 0, 0.3);
        color: $color-white;
        font-size: 5rem;
        line-height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 100;
        cursor: pointer;
        user-select: none;
        transition: color 0.2s;
        z-index: 2;
        @include size(48px);

        &:hover {
            color: $color-burnt-sienna;
        }
    }

    &-description {
        background: $color-white;
        padding: 20px;
        font-size: 1.6rem;
        border: inherit;
        border-top: 0;
        margin: -4px -2px 0;
    }

    img {
        @include size(100%);
    }

    @media (max-width: $screen-large) {
        @include size(280px);
        left: 60px;
    }

    @media (max-width: $screen-medium) {
        @include size(220px);
        left: 18%;
        top: 200px;
    }

    @media (max-width: $screen-tablet-large) {
        @include size(60%, 220px);
        left: 10%;

        img {
            object-fit: cover;
        }
    }
}
</style>
