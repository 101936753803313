<template>
    <div
        :class="['button-zoom', { 'button-zoom-active': active }]"
        @click="action"
    >
        <!-- eslint-disable -->
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.431"
            height="19.431"
            viewBox="0 0 19.431 19.431"
            class="button-zoom-image"
        >
            <g transform="translate(-798.9 -863.9)">
                <circle
                    class="a"
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(799.5 864.5)"
                />
                <line
                    class="b"
                    x2="4"
                    y2="4"
                    transform="translate(813.483 878.483)"
                />
                <line class="b" y2="8" transform="translate(807.5 868.5)" />
                <line
                    class="b"
                    y2="8"
                    transform="translate(811.5 872.5) rotate(90)"
                />
            </g>
        </svg>
        <!-- eslint-enable -->
        <div v-text="text" />
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: '',
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        action() {
            if (this.active) {
                this.$emit('action')
            }
        },
    },
}
</script>

<style lang="scss">
.button-zoom {
    font-size: 1.6rem;
    border: 1px solid $color-dove-gray;
    max-width: 191px;
    margin: 0 auto;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    opacity: 0.3;
    transition: opacity 0.3s;
    position: absolute;
    left: calc(50% - 170px);
    bottom: 30px;

    &-image {
        width: auto;
        height: auto;
        max-width: unset;
        margin-right: 10px;
        transition: 0.1s;
        @include size(18px);

        .a,
        .b {
            fill: none;
            stroke: $color-default-text;
            stroke-width: 1.2px;
        }
        .b {
            stroke-linecap: round;
        }
    }

    &-active {
        opacity: 1;
        background: $color-burnt-sienna;
        color: $color-white;
        border-color: transparent;
        border-radius: 3px;
    }

    &-active & {
        &-image {
            .a,
            .b {
                stroke: $color-white;
            }
        }
    }

    &-active:hover & {
        &-image {
            transform: scale(1.2);
        }
    }

    @media (max-width: $screen-medium) {
        bottom: 160px;
    }
}
</style>
