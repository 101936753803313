<script>
import dependencies from '@/store/modules/dependencies'

export default {
    data() {
        return {
            pageTitle: {
                pl: 'Rodzaj opakowania notesu',
                en: 'Notebook packaging type',
                de: 'Verpackungsart',
                fr: "Type d'emballage de carnet",
            },
        }
    },
    computed: {
        list() {
            return dependencies.state.list
        },
        options() {
            let OPAKOWANIE = null

            if (this.list?.length) {
                this.list.forEach((dependency) => {
                    OPAKOWANIE = mergeMultipleParams(
                        OPAKOWANIE,
                        dependency.actions.OPAKOWANIE
                    )
                })
            }

            function mergeMultipleParams(param, dependencyAction) {
                param = param || dependencyAction

                if (param && dependencyAction) {
                    param = param.filter((dep) => {
                        return dependencyAction.includes(dep)
                    })
                }
                return param
            }

            function filterOptionsFn({ apiId }, allowed) {
                return allowed ? allowed.includes(apiId.toUpperCase()) : true
            }

            return [
                {
                    id: 'wrapping',
                    apiId: 'OPAKOWANIE',
                    isModal: true,
                    title: {
                        pl: 'Opakowanie',
                        en: 'Packaging',
                        de: 'Verpackung',
                        fr: 'Emballage',
                    },
                    choices: [
                        {
                            id: 'luz',
                            apiId: 'L',
                            title: {
                                pl: 'Luzem',
                                en: 'Bulk',
                                de: 'Lose',
                                fr: 'En vrac',
                            },
                        },
                        {
                            id: 'standard',
                            apiId: 'W',
                            title: {
                                pl: 'Standardowy woreczek PP',
                                en: 'Standard PP bag',
                                de: 'Standard PP Beutel',
                                fr: 'Polybag standard',
                            },
                        },
                        {
                            id: 'eco',
                            apiId: 'WK',
                            title: {
                                pl: 'Ekologiczny woreczek',
                                en: 'Ecological bag',
                                de: 'Eco Beutel',
                                fr: 'Sachet biodégradable',
                            },
                        },
                    ].filter((item) => filterOptionsFn(item, OPAKOWANIE)),
                },
            ]
        },
    },
}
</script>
