<template>
    <div>
        <h6>
            {{ $t('pdf.file1') }} <br />
            {{ $t('pdf.file2') }}
        </h6>
        <p>
            <strong>
                {{ $t('pdf.file3') }}
                {{ $t('pdf.file4') }}
                {{ $t('pdf.file6') }}
            </strong>
        </p>

        <p>
            {{
                locale === 'de'
                    ? $t('pdf.file7') + $t('pdf.file71')
                    : $t('pdf.file7')
            }}
        </p>
        <p v-html="$t('pdf.file72') + $t('pdf.file73Html')" />
        <p>
            {{ $t('pdf.file8') }}
            {{ $t('pdf.file9') }}
            {{ $t('pdf.file10') }}
            {{ locale !== 'pl' ? $t('pdf.file11') : '' }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        locale: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss"></style>
