<template>
    <div v-if="user" class="user-info" @click="logout">
        <div class="user-info-text">
            <strong>{{ user.name }}</strong>
            <span v-if="user.company" class="user-info-company">
                ({{ user.company }})
            </span>
        </div>
        <OnOffIcon class="user-info-icon" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import OnOffIcon from '@/assets/images/system-icons/on-off-icon.svg'

export default {
    components: {
        OnOffIcon,
    },
    computed: {
        ...mapState({
            user: (state) => state.user.data,
        }),
    },
    methods: {
        logout() {
            console.log('logout')
        },
    },
}
</script>

<style lang="scss">
.user-info {
    @include size(auto, 48px);
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    border: 2px solid $color-silver;
    cursor: pointer;
    transition: 0.1s;
    background-color: $color-white;
    position: relative;
    margin-left: 40px;
    right: 20px;

    &-text {
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &-icon {
        @include size(20px);
        margin-left: 20px;
    }
    &-company {
        margin-left: 4px;
    }

    &:hover {
        border-color: $color-burnt-sienna;
        background-color: $color-burnt-sienna;
        color: $color-white;
        svg {
            fill: $color-white;
        }

        &::before {
            border-color: $color-white;
        }
    }
}
</style>
