<template>
    <!-- eslint-disable -->
    <pattern
        v-if="background"
        id="cover"
        patternUnits="userSpaceOnUse"
        width="100"
        height="100"
    >
        <image
            :xlink:href="base64[background]"
            x="0"
            y="0"
            width="100"
            height="100"
        />
    </pattern>
</template>

<script>
import { mapState } from 'vuex'
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        background: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapState({
            marking: (state) =>
                state.notes.configuration.markingPrint?.singleMarkingPrint
                    ?.title?.pl === 'CMYK',
        }),
    },
    watch: {
        background(value) {
            if (value) {
                this.toDataUrl(value)
            } else {
                this.base64[value] = null
            }
        },
    },
    mounted() {
        if (this.background) {
            this.toDataUrl(this.background)
        }
    },
}
</script>
