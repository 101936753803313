import Api from '@/api/Api'
import {
    Config,
    Env,
    Services
} from '@/api/config'

const ApiLauncher = {
    launch () {
        Api.setConfig(Config.config)
        Api.setEnv(Env.env)
        Api.defineServices(Services.services)

        return Api.init()
    }
}

export default ApiLauncher.launch()
