var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: (isVisible) => (_vm.isInViewport = isVisible),
        once: true,
        intersection: {
            threshold: 0.31,
        },
    }),expression:"{\n        callback: (isVisible) => (isInViewport = isVisible),\n        once: true,\n        intersection: {\n            threshold: 0.31,\n        },\n    }"}],class:[
        'image-lazy',
        {
            'is-ready': _vm.isReady && _vm.isInViewport,
            'is-error': _vm.isError,
        },
    ]},[_c('div',{ref:"placeholder",style:({ height: _vm.heightInPixels })},[(_vm.isBackground && _vm.imageObject)?_c('div',{staticClass:"image-lazy-background",style:({ backgroundImage: 'url(' + _vm.imageObject.src + ')' })}):_vm._e(),(_vm.hasLoader)?_c('Loader',{attrs:{"is-active":!_vm.isReady}}):_vm._e()],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }