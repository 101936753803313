export const pages = [
    {
        id: 'material',
        icon: null,
        title: {
            pl: 'Materiał',
            en: 'Material',
            de: 'Material',
            fr: 'Matière',
        },
        color: null,
        isHiddenInMenu: true,
        hideNotes: true,
        disabled: false,
        hideButtonNext: true,
    },
    {
        id: 'cover',
        icon: 'images/menu-icons/okladka_icon.svg',
        title: {
            pl: 'Okładka',
            en: 'Cover',
            de: 'Einbandmaterial',
            fr: 'Couverture',
        },
        color: 'rgba(237, 84, 78, 0)',
        disabled: true,
        toSummary: true,
        possibleOptions: [
            {
                name: 'coverType',
                title: {
                    pl: 'Rodzaj okładki',
                    en: 'Type of cover',
                    de: 'Art des Einbandmaterials',
                    fr: 'Type de couverture',
                },
            },
            {
                name: 'coverCorners',
                title: {
                    pl: 'Narożniki okładki',
                    en: 'Cover corners',
                    de: 'Umschlagecken',
                    fr: 'Les coins de la couverture',
                },
            },
            {
                name: 'coverRidge',
                title: {
                    pl: 'Rodzaj grzbietu',
                    en: 'Type of spine',
                    de: 'Buchrückenart',
                    fr: 'Type de dos',
                },
            },
        ],
    },
    {
        id: 'format',
        icon: 'images/menu-icons/format__icon.svg',
        title: {
            pl: 'Format',
            en: 'Format',
            de: 'Format',
            fr: 'Format',
        },
        summaryTitle: {
            pl: 'Format notesu',
            en: 'Notebook format',
            de: 'Notizbuch-Format',
            fr: 'Format du carnet',
        },
        color: 'rgba(237, 84, 78, 0.02)',
        disabled: true,
        toSummary: true,
        possibleOptions: [
            {
                name: 'format',
                title: {
                    pl: 'Format',
                    en: 'Format',
                    de: 'Format',
                    fr: 'Format',
                },
            },
        ],
    },
    {
        id: 'paper',
        icon: 'images/menu-icons/okleina_icon.svg',
        title: {
            pl: 'Okładka - materiał',
            en: 'Cover - material',
            de: 'Einbandmaterial',
            fr: 'Couverture – matière',
        },
        summaryTitle: {
            pl: 'Okładka - materiał',
            en: 'Cover - material',
            de: 'Einbandmaterial',
            fr: 'Couverture – matière',
        },
        color: 'rgba(237, 84, 78, 0.05)',
        disabled: true,
        toSummary: true,
        possibleOptions: [
            {
                name: 'coverColorPattern',
                title: {
                    pl: 'Okleina papierowa / Okleina introligatorska',
                    en: 'Paper cover / Material cover ',
                    de: 'Papiereinband / Einbandmaterial',
                    fr: 'Revêtement en papier / en matière',
                },
            },
        ],
    },
    {
        id: 'coverMarking',
        icon: 'images/menu-icons/okladka_znakowanie_icon.svg',
        title: {
            pl: 'Okładka - znakowanie',
            en: 'Cover - customization',
            de: 'Umschlag – Veredelung',
            fr: 'Couverture – marquage',
        },
        summaryTitle: {
            pl: 'Znakowanie okładki',
            en: 'Cover customization',
            de: 'Veredelung des Umschlags',
            fr: 'Marquage de couverture',
        },
        color: 'rgba(237, 84, 78, 0.1)',
        disabled: true,
        toSummary: true,
        possibleOptions: [
            {
                name: 'markingPrint',
                title: {
                    pl: 'Druk',
                    en: 'Printing',
                    de: 'Druck',
                    fr: 'Impression',
                },
            },
            {
                name: 'markingExpression',
                title: {
                    pl: 'Tłoczenie',
                    en: 'Debossing',
                    de: 'Prägung',
                    fr: 'Embossage',
                },
            },
            {
                name: 'markingCoverFinish',
                title: {
                    pl: 'Wykończenie okładki',
                    en: 'Cover finishing',
                    de: 'Art der Veredelung',
                    fr: 'Finition de la couverture',
                },
            },
        ],
    },
    {
        id: 'inside',
        icon: 'images/menu-icons/srodek_blok_icon.svg',
        title: {
            pl: 'Środek - blok',
            en: 'Inside - writing pad',
            de: 'Buchblock - Inhalt',
            fr: 'Intérieur – bloc de papier',
        },
        summaryTitle: {
            pl: 'Środek - blok',
            en: 'Inside - writing pad',
            de: 'Buchblock - Inhalt',
            fr: 'Intérieur – bloc de papier',
        },
        color: 'rgba(237, 84, 78, 0.15)',
        disabled: true,
        toSummary: true,
        possibleOptions: [
            {
                name: 'paperType',
                title: {
                    pl: 'Rodzaj papieru',
                    en: 'Paper type',
                    de: 'Papierart',
                    fr: 'Type de papier',
                },
            },
            {
                name: 'numberOfPages',
                title: {
                    pl: 'Ilość kartek',
                    en: 'Number of sheets',
                    de: 'Blattanzahl',
                    fr: 'Nombre de feuilles',
                },
            },
            {
                name: 'overprintType',
                title: {
                    pl: 'Rodzaj zadruku',
                    en: 'Type of printing',
                    de: 'Druckart',
                    fr: "Type d'impression",
                },
            },
            {
                name: 'perforation',
                title: {
                    pl: 'Perforacja',
                    en: 'Perforation',
                    de: 'Perforation',
                    fr: 'Perforation',
                },
            },
            {
                name: 'binderHoles',
                title: {
                    pl: 'Otwory do segregatora',
                    en: 'Binder holes',
                    de: 'Abheftlochung',
                    fr: 'Trous pour classeur',
                },
            },
        ],
    },
    {
        id: 'flyleaf',
        icon: 'images/menu-icons/wyklejka_icon.svg',
        title: {
            pl: 'Wyklejka',
            en: 'Front and back endsheet',
            de: 'Vor- und Nachsatz',
            fr: 'Pages de garde – au debut et a la fin',
        },
        summaryTitle: {
            pl: 'Wyklejka',
            en: 'Front and back endsheet',
            de: 'Vor- und Nachsatz',
            fr: 'Pages de garde – au debut et a la fin',
        },
        color: 'rgba(237, 84, 78, 0.25)',
        disabled: true,
        toSummary: true,
        possibleOptions: [
            {
                name: 'flyleafPaper',
                title: {
                    pl: 'Rodzaj papieru',
                    en: 'Paper type',
                    de: 'Papierart',
                    fr: 'Type de papier',
                },
            },
            {
                name: 'flyleaf',
                title: {
                    pl: 'Rodzaj zadruku',
                    en: 'Printing type',
                    de: 'Druckart',
                    fr: "Type d'impression",
                },
            },
        ],
    },
    {
        id: 'personalized',
        icon: 'images/menu-icons/spersonalizowane_icon.svg',
        title: {
            pl: 'Personalizowane kartki',
            en: 'Personalised sheets',
            de: 'Personalisierte Blätter',
            fr: 'Pages personnalisées',
        },
        summaryTitle: {
            pl: 'Personalizowane kartki',
            en: 'Personalised sheets',
            de: 'Personalisierte Blätter',
            fr: 'Pages personnalisées',
        },
        color: 'rgba(237, 84, 78, 0.35)',
        disabled: true,
        toSummary: true,
        possibleOptions: [
            {
                name: 'additionalPages',
                title: {
                    pl: 'Dodatkowe kartki',
                    en: 'Extra pages',
                    de: 'Werbeblätter',
                    fr: 'Pages supplémentaires',
                },
            },
            {
                name: 'calendar',
                title: {
                    pl: 'Kalendarz',
                    en: 'Calendar',
                    de: 'Kalender',
                    fr: 'Agenda – calendrier',
                },
            },
        ],
    },
    {
        id: 'accessories',
        icon: 'images/menu-icons/akcesoria_icon.svg',
        title: {
            pl: 'Akcesoria',
            en: 'Accessories',
            de: 'Accessoires',
            fr: 'Accessoires',
        },
        color: 'rgba(237, 84, 78, 0.45)',
        disabled: true,
        toSummary: true,
        possibleOptions: [
            {
                name: 'elastic',
                title: {
                    pl: 'Gumka zamykająca',
                    en: 'Elastic closure',
                    de: 'Verschlussgummiband',
                    fr: 'Fermeture élastique',
                },
            },
            {
                name: 'penHolder',
                title: {
                    pl: 'Uchwyt na długopis',
                    en: 'Pen loop',
                    de: 'Stiftschlaufe',
                    fr: 'Passant stylo',
                },
            },
            {
                name: 'ribbon',
                title: {
                    pl: 'Tasiemka',
                    en: 'Ribbon',
                    de: 'Lesezeichen',
                    fr: 'Ruban',
                },
            },
            {
                name: 'streamer',
                title: {
                    pl: 'Banderola i obwoluta',
                    en: 'Paper belly band and wrapper',
                    de: 'Banderole und Hülle',
                    fr: 'Bandeau et jaquette',
                },
            },
            {
                name: 'pocket',
                title: {
                    pl: 'Kieszonka na dokumenty',
                    en: 'Document pocket',
                    de: 'Tasche',
                    fr: 'Pochette documents',
                },
            },
        ],
    },
    {
        id: 'edgeColoration',
        icon: 'images/menu-icons/barwienie_krawedzi_icon.svg',
        title: {
            pl: 'Krawędzie',
            en: 'Edges',
            de: 'Buchrand',
            fr: 'Bords',
        },
        color: 'rgba(237, 84, 78, 0.55)',
        disabled: true,
        toSummary: true,
        possibleOptions: [
            {
                name: 'edgeColoration',
                title: {
                    pl: 'Znakowanie krawędzi',
                    en: 'Edges customization',
                    de: 'Farbschnitt',
                    fr: 'Marquage des bords',
                },
            },
        ],
    },
    {
        id: 'packagePage',
        icon: 'images/menu-icons/package_icon.svg',
        title: {
            pl: 'Opakowanie',
            en: 'Packaging',
            de: 'Verpackung',
            fr: 'Emballage',
        },
        summaryTitle: {
            pl: 'Rodzaj opakowania notesu',
            en: 'Type of notebook packaging',
            de: 'Verpackungsart',
            fr: "Type d'emballage d'un carnet",
        },
        color: 'rgba(237, 84, 78, 0.65)',
        disabled: true,
        toSummary: true,
        possibleOptions: [
            {
                name: 'wrapping',
                title: {
                    pl: 'Opakowanie',
                    en: 'Packaging',
                    de: 'Verpackung',
                    fr: 'Emballage',
                },
            },
        ],
    },
    {
        id: 'yourConfig',
        icon: 'images/menu-icons/your_config_icon.svg',
        title: {
            pl: 'Twoja konfiguracja',
            en: 'Your setup',
            de: 'Ihre Konfiguration',
            fr: 'Votre configuration',
        },
        color: 'rgba(237, 84, 78, 0.75)',
        disabled: true,
        hideNotes: true,
        hideButtonNext: true,
    },
    // {
    //     id: 'formPage',
    //     icon: 'images/menu-icons/formularz_icon.svg',
    //     title: {
    //         pl: 'Logowanie/Rejestracja',
    //         en: 'Login/Registration',
    //         de: 'Einloggen/Anmelden',
    //         fr: 'Connexion / Enregistrement',
    //     },
    //     color: 'rgba(237, 84, 78, 0.85)',
    //     disabled: true,
    //     hideNotes: true,
    //     hideButtonNext: true,
    // },
    // {
    //     id: 'offer',
    //     icon: 'images/menu-icons/offer_icon.svg',
    //     title: {
    //         pl: 'Oferta',
    //         en: 'Offer',
    //         de: 'Angebot',
    //         fr: 'Offre',
    //     },
    //     color: 'rgba(237, 84, 78, 0.95)',
    //     disabled: true,
    //     hideButtonNext: true,
    // },
    // {
    //     id: 'summaryPage',
    //     icon: 'images/menu-icons/podsumowanie_icon.svg',
    //     title: {
    //         pl: 'Podsumowanie',
    //         en: 'Summary',
    //         de: 'Zusammenfassung',
    //         fr: 'Conclusion',
    //     },
    //     color: 'rgba(237, 84, 78, 1)',
    //     disabled: true,
    //     hideButtonNext: true,
    // },
    // {
    //     id: 'formFinal',
    //     icon: null,
    //     color: null,
    //     title: {
    //         pl: 'Formularz',
    //         en: 'Form',
    //         de: 'Formular',
    //         fr: 'Formulaire',
    //     },
    //     disabled: true,
    //     hideButtonNext: true,
    //     isHiddenInMenu: true,
    // },
    {
        id: 'sent',
        icon: null,
        title: {
            pl: 'Formularz wysłany',
            en: 'Form submitted',
            de: 'Formular abgeschickt',
            fr: 'Formulaire envoyé',
        },
        color: null,
        isHiddenInMenu: true,
        disabled: false,
        hideNotes: true,
    },
]
