<template>
    <div class="form-dropdown" :class="theme" @mouseleave="hideList">
        <div
            class="form-dropdown-selected"
            :class="{ disabled: disabled }"
            @click="toggleList"
        >
            <input
                v-if="isOpen"
                ref="$filter"
                v-model="filter"
                :disabled="disabled"
                @keydown.enter.prevent="selectFirstOption"
            />
            <span v-if="selectedLabel && !isOpen">
                <slot name="selected" :selected="selectedOption">
                    {{ selectedLabel }}
                </slot>
            </span>
        </div>
        <div ref="list" class="form-dropdown-list" :class="{ show: isOpen }">
            <template v-if="filteredOptions.length">
                <div
                    v-for="option in filteredOptions"
                    :key="option.value"
                    class="form-dropdown-list-element"
                    @click="selectOption(option)"
                >
                    <slot name="option" :option="option">
                        {{ option.label }}
                    </slot>
                </div>
            </template>
            <div v-else class="form-dropdown-list-element">
                <div style="text-align: center;">
                    <em>
                        <small>{{ $t('general.notfound') }}</small>
                    </em>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            default: () => [], // [{label: string, value: string}]
        },
        theme: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            filter: '',
        }
    },
    computed: {
        selectedLabel() {
            if (!this.value) {
                return null
            }
            return this.selectedOption?.label
        },
        selectedOption() {
            return this.options.find((option) => option.value === this.value)
        },
        filteredOptions() {
            const filter = this.filter.toLowerCase().trim()
            if (!filter) {
                return this.options
            }

            return this.options.filter(
                (o) =>
                    o.value.toLowerCase().includes(filter) ||
                    o.label.toLowerCase().includes(filter)
            )
        },
    },
    methods: {
        toggleList() {
            if (this.disabled) {
                return
            }
            this.isOpen = !this.isOpen
            if (this.isOpen) {
                setTimeout(() => {
                    this.$refs.$filter.focus()
                })
            }
        },
        hideList() {
            this.isOpen = false
            setTimeout(() => {
                this.filter = ''
            }, 300)
        },
        selectOption(option) {
            if (this.disabled) {
                return
            }
            this.$emit('input', option.value)
            this.hideList()
        },
        selectFirstOption() {
            if (!this.filteredOptions.length) {
                return false
            }
            this.selectOption(this.filteredOptions[0])
        },
    },
}
</script>

<style lang="scss">
$list-height: 170px;

.form-dropdown {
    position: relative;
    max-width: 340px;
    margin-left: auto;

    &-list {
        max-height: 0;
        transition: 0.2s ease-in;
        position: absolute;
        left: 0;
        top: 50px;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
        z-index: 99;
        overflow: auto;
        @include hide-scrollbar();

        &.show {
            max-height: $list-height;
        }

        &-element {
            cursor: pointer;
            @include size(111px, 50px);
            border: 1px solid $color-mercury;
            font-size: 1.8rem;
            padding: 14px 23px;
            transition: 0.15s;
            background: $color-white;
            min-width: 340px;

            &:hover {
                background-color: $color-mercury;
            }
        }
    }

    &-selected {
        @include size(111px, 50px);
        border: 1px solid $color-border-input-darker;
        background: $color-white;
        font-size: 1.8rem;
        padding: 14px 23px;
        cursor: pointer;

        input {
            border: 0;
            outline: none;
            box-shadow: none;
        }

        &::after {
            @include size(11px);
            content: '';
            display: inline-block;
            border-right: $color-default-text solid 2px;
            border-bottom: $color-default-text solid 2px;
            transform: rotate(45deg);
            height: 11px;
            position: absolute;
            right: 20px;
            top: calc(50% - 10px);
        }
        &.disabled {
            cursor: default;
            &::after {
                display: none;
            }
        }
    }
    &-list.hide {
        max-height: 0;
    }
    &.wide {
        width: 100%;
    }
    &.wide & {
        &-list {
            width: 100%;
            &-element {
                width: 100%;
            }
        }
        &-selected {
            position: relative;
            width: 100%;
            border-color: $color-border-input-darker;

            &::after {
                position: absolute;
                right: 35px;
            }
        }
    }
    @media (max-width: $screen-mobile-large) {
        &.wide {
            width: 100%;
        }
    }
}
</style>
