<template>
    <div class="page-form-container">
        <h1 class="page-title">
            {{ $t('form.title') }}
        </h1>
        <div class="form-container">
            <!--            <FormNav />-->
            <!--            <transition name="fade" mode="out-in">-->
            <FormIndividual v-if="userType === 'individual'" />
            <!--                <FormAgency v-if="userType === 'agency'" />-->
            <!--            </transition>-->
        </div>
        <!-- tutaj można podejrzeć na razie ukryte strony z resetowania hasła -->
        <!-- <button @click="NewPassword">NewPassword</button>
        <button @click="NewPasswordReset">NewPasswordReset</button> -->
    </div>
</template>

<script>
// import FormNav from '@/components/form/FormNav'
import FormIndividual from '@/components/form/FormIndividual'
// import FormAgency from '@/components/form/FormAgency'

import { mapState, mapMutations } from 'vuex'

export default {
    components: {
        // FormNav,
        FormIndividual,
        // FormAgency,
    },
    computed: {
        ...mapState({
            userType: (state) => state.user.type,
        }),
    },
    methods: {
        // remove if not needed
        ...mapMutations(['changeSubpage']),

        NewPassword() {
            this.changeSubpage({
                page: 'NewPassword',
            })
        },
        NewPasswordReset() {
            this.changeSubpage({
                page: 'NewPasswordReset',
            })
        },
    },
}
</script>
