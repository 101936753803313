<script>
import BasePageMixin from '@/mixins/BasePageMixin'
import NotesImage from '@/components/notes/NotesCover'
import PersonalizedData from '@/mixins/pages/PersonalizedData'
import { mapState } from 'vuex'

export default {
    components: {
        NotesImage,
    },
    extends: BasePageMixin,
    mixins: [PersonalizedData],
    computed: {
        ...mapState({
            additionalPages: (state) =>
                state.notes.configuration.additionalPages,
        }),
    },
    methods: {
        validate() {
            return this.additionalPages
                ? this.additionalPages.general
                    ? !!this.additionalPages.pagesPlace &&
                      !!this.additionalPages.paperType &&
                      !!this.additionalPages.personalizedPrint
                    : true
                : true
        },
    },
}
</script>
