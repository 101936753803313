<template>
    <div class="summary-page">
        <div class="summary-page-container">
            <h1 class="page-title page-title-mobile">
                {{ $t('summary.title') }}
            </h1>
            <SummaryConfig :summary="summaryPages" />
            <SummaryPrints @back="goToOffer" />
            <SummaryLinks theme="summary" />
            <div class="summary-page-pdf">
                <p class="summary-page-pdf-text">
                    {{ $t('summary.info.pdf') }}
                </p>
                <button
                    class="summary-page-pdf-button page-config-buttons-pdf"
                    @click="downloadPDF()"
                >
                    <DownloadIcon />
                    {{ $t('config.button1') }}
                </button>
            </div>
            <hr />
            <div class="summary-page-info">
                {{ $t('summary.info.individual') }}
            </div>
            <template v-if="userType === 'individual'">
                <FormSummaryDistributor ref="form" @submit="goToForm(true)" />

                <div class="summary-page-disclaimer">
                    <p>
                        {{ $t('summary.info.form') }}
                    </p>
                </div>
            </template>

            <div v-else class="summary-page-disclaimer">
                <p>{{ $t('summary.info.agency') }}</p>
                <p>
                    {{ $t('summary.info.form') }}
                </p>
                <p class="summary-page-disclaimer--small">
                    {{ $t('summary.info.erp') }}
                </p>
            </div>
        </div>
        <button
            v-if="userType === 'individual'"
            class="form-submit send-button"
            @click="goToForm(false)"
            v-text="$t('summary.sendForm')"
        />
        <button
            v-if="userType === 'agency'"
            class="form-submit send-button"
            @click="goToOffer"
            v-text="$t('form.goToOffer')"
        />
    </div>
</template>

<script>
// components
import SummaryConfig from '@/components/summary/SummaryConfig'
import SummaryLinks from '@/components/summary/SummaryLinks'
import SummaryPrints from '@/components/summary/SummaryPrints'
// icons
import DownloadIcon from '@/assets/images/system-icons/download_icon.svg'
// mixins
import saveToPdf from '@/mixins/SaveToPdf'
import { mapActions, mapState } from 'vuex'
import { delay } from '@/helper/DelayHelper'
import NotesToCanvas from '@/mixins/NotesToCanvas'
import FormSummaryDistributor from '@/components/form/FormSummaryDistributor.vue'

export default {
    name: 'SummaryPages',
    components: {
        FormSummaryDistributor,
        SummaryConfig,
        SummaryLinks,
        DownloadIcon,
        SummaryPrints,
    },
    mixins: [saveToPdf, NotesToCanvas],
    computed: {
        ...mapState({
            userType: (state) => state.user.type,
        }),
    },
    methods: {
        ...mapActions([
            'changePage',
            'sendLead',
            'showLoader',
            'showLoaderError',
            'hideLoader',
        ]),
        onSubmitForm() {
            this.$refs.form.onSubmitForm()
        },
        async goToForm(validate = false) {
            if (validate && !this.$refs.form.validation()) {
                this.$store.dispatch('showLoaderError', 'offer.error.form')
                return
            }

            this.showLoader({
                message: 'summary.info.send',
                loading: true,
            })

            try {
                await delay()
                await this.sendLead(true)
                this.hideLoader()
                this.changePage('sent')
            } catch (e) {
                console.error(e)
                this.showLoaderError()
            }
        },
        goToOffer() {
            this.changePage('offer')
        },
    },
}
</script>

<style lang="scss">
.summary-page {
    max-width: 685px;
    padding: 50px 65px 50px 0;
    max-height: 100%;
    overflow-y: auto;
    margin: 0 -35px 0 auto;

    hr {
        border-color: transparent;
        border-bottom-color: $color-silver;
    }

    &-pdf {
        margin: 35px 0;
        > p {
            margin-bottom: 20px;
            font-size: 1.3rem;
        }
        &-button {
            border: 1px solid $color-silver;
            padding: 10px 20px;
            cursor: pointer;
            background: $color-dark-gray;
            color: $color-white;
            border-color: $color-dark-gray;
            font-size: 1.8rem;

            svg {
                margin-right: 15px;
                transition: 0.1s;
            }
        }
    }

    &-info {
        font-size: 1.3rem;
        background: $color-alabaster;
        margin: 35px 0 25px;
        padding: 28px 50px;
        border: 1px solid $color-dark-gray;
    }

    &-disclaimer {
        font-size: 1.8rem;
        font-weight: 600;
        &--small {
            font-weight: 400;
            font-size: 1.6rem;
            margin-top: 10px;
        }
    }
    .page-title {
        margin-top: -10px;
    }

    &-actions {
        padding-top: 45px;
    }

    @media (max-width: $screen-medium) {
        .page-title {
            margin-top: 0;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        max-width: 714px;
        max-height: unset;
        height: 100%;
        margin: 0 auto;
        padding: 0 50px 65px;
    }

    @media (max-width: $screen-mobile-large) {
        padding: 0 20px 40px;
    }
}
</style>
