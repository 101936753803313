import { config as Config, env as Env } from '@/api/config/env'
import Services from '@/api/config/services'

let apiUrl = '/api/'

if (process.env.NODE_ENV !== 'production') {
    apiUrl = ''
}

export const API_CONFIG = {
    API_URL: apiUrl,
    LOG_REQUESTS: false,
    WITH_CREDENTIALS: false
}

export {
    Config,
    Env,
    Services
}
