<template>
    <LoaderPage
        :is-active="isShown"
        :is-loading="isLoaderLoading"
        :text="translationText"
        @close="onClose"
    />
</template>

<script>
import {mapGetters} from 'vuex'
import LoaderPage from '@/components/loader/LoaderPage.vue'

export default {
    components: {LoaderPage},
    computed: {
        ...mapGetters([
            'hasErrorMessage',
            'getErrorMessage',
            'isLoaderLoading',
            'hasInfoMessage',
            'getInfoMessage',
            'isShown'
        ]),
        translationText() {
            return this.$t(this.getErrorMessage || this.getInfoMessage)
        }
    },
    methods: {
        onClose() {
            this.$store.commit('setShow', false)
        }
    }
}
</script>

<style scoped>

</style>
