<template>
    <div v-if="true" :key="selectedPrint" class="page-print-zoom">
        <transition name="fade">
            <div v-if="showZoom && selectedPrint" class="page-print-zoom-image">
                <div class="page-print-zoom-close" @click="closeZoom">
                    &times;
                </div>
                <img
                    :src="images[selectedPrint]"
                    alt=""
                    :style="`background-color: ${paperColor};`"
                />
            </div>
        </transition>
        <ButtonZoom
            class="page-print-zoom-button"
            :text="$t('general.zoom2')"
            :active="isZoomButtonActive"
            @action="zoomPattern"
        />
    </div>
</template>

<script>
import ButtonZoom from '@/components/button/ButtonZoom'
import { mapState, mapGetters } from 'vuex'

export default {
    components: {
        ButtonZoom,
    },
    data() {
        return {
            showZoom: false,
            images: {
                chequered: '/img/notes/inside/zoom/kratka.png',
                halfChequered: '/img/notes/inside/zoom/polkratka.png',
                lines: '/img/notes/inside/zoom/linia.png',
                dots: '/img/notes/inside/zoom/kropki.png',
                plannerDaily: '/img/notes/inside/zoom/kalendarz_4.png',
                plannerWeekly: '/img/notes/inside/zoom/kalendarz_3.png',
                individual1: '/img/notes/inside/zoom/yourlogo_1.png',
                individual2: '/img/notes/inside/zoom/yourlogo_2.png',
                individual3: '/img/notes/inside/zoom/yourlogo_3.png',
            },
        }
    },
    computed: {
        ...mapState({
            locale: (state) => state.i18n.locale,
            configuration: (state) => state.notes.configuration,
        }),
        ...mapGetters(['paperColor']),
        isZoomButtonActive() {
            if (this.selectedPrint) {
                return true
            }
            return false
        },
        selectedPrint() {
            if (
                this.configuration.overprintType &&
                this.configuration.overprintType.style
            ) {
                return this.configuration.overprintType.style.id
            } else if (
                this.configuration.overprintType &&
                this.configuration.overprintType.druk
            ) {
                return this.configuration.overprintType.druk.id
            }
            return ''
        },
    },
    methods: {
        zoomPattern() {
            this.showZoom = true
        },
        closeZoom() {
            this.showZoom = false
        },
    },
}
</script>

<style lang="scss">
.page-print-zoom {
    position: absolute;
    width: 50%;
    height: 100%;

    &-image {
        position: absolute;
        @include size(150px);
        top: calc(50% - 105px);
        left: calc(50% + 30px);
    }

    &-close {
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
        color: $color-white;
        font-size: 5rem;
        line-height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 100;
        cursor: pointer;
        user-select: none;
        transition: color 0.2s;
        @include size(48px);

        &:hover {
            color: $color-burnt-sienna;
        }
    }

    &-button {
        left: calc(50% - 95px);
        bottom: 20%;
    }

    img {
        @include size(100%);
        box-shadow: 7px 4px 16px 0px rgba(175, 175, 175, 0.56);
    }

    @media (max-width: $screen-medium) {
        &-image {
            left: calc(50% + 0px);
        }
    }
    @media (max-width: $screen-tablet-medium) {
        &-button {
            display: none;
        }
    }
}
</style>
