import { mapActions } from 'vuex'

export default {
    methods: {
        ...mapActions(['sendNotesConfig', 'updateNotesConfig', 'showLoader', 'hideLoader', 'showLoaderError']),
        async getCodes() {
            try {
                await this.$store.dispatch('showLoader', {
                    message: 'loader.info.summary',
                    loading: true
                })
                // zapis konfiguracji w cmsie bez kodu
                await this.sendNotesConfig()
                // pobranie kodu z api mindnotes
                await this.getConfigNumber()
                // zapis konfiguracji w cmsie z kodem
                await this.updateNotesConfig()

                await this.$store.dispatch('hideLoader')
            } catch (e ) {
                await this.$store.dispatch('showLoaderError', 'loader.error.summary')
            }

        },
    },
}
