<template>
    <image
        :href="base64[image[format].url]"
        :transform="image[format].transform"
    />
</template>

<script>
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            image: {
                a4: {
                    url: '/img/notes/inside/standard/chequered-vertical.png',
                    transform: 'translate(34 18)',
                },
                '205': {
                    url: '/img/notes/inside/standard/chequered-horizontal.png',
                    transform: 'translate(10 5)',
                },
                '175': {
                    url: '/img/notes/inside/standard/chequered-square.png',
                    transform: 'translate(7 7)',
                },
                b5: {
                    url: '/img/notes/inside/standard/b5/chequered-b5.png',
                    transform: 'translate(32 20)',
                },
                a5: {
                    url: '/img/notes/inside/standard/a5/chequered-a5.png',
                    transform: 'translate(15 20)',
                },
                a6: {
                    url: '/img/notes/inside/standard/a6/chequered-a6.png',
                    transform: 'translate(31 22)',
                },
                '125': {
                    url: '/img/notes/inside/standard/125/chequered-125.png',
                    transform: 'translate(43 23)',
                },
                '84': {
                    url: '/img/notes/inside/standard/84/chequered-84.png',
                    transform: 'translate(45 22)',
                },
            },
        }
    },

    created() {
        Object.keys(this.image).forEach((format) => {
            this.toDataUrl(this.image[format].url)
        })
    },
}
</script>
