var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'input-checkbox-children',
        {
            'is-open': _vm.open,
            'is-selected': _vm.currentSettings[_vm.id],
            'is-disabled': _vm.disabled,
        },
    ]},[_c('div',{class:[
            'input-checkbox-children-title',
            { colors: _vm.type === 'colors' },
        ],on:{"click":_vm.toggleSubOption}},[_c('div',{staticClass:"input-mark"}),_c('div',{staticClass:"input-checkbox-children-title-label"},[_vm._v(" "+_vm._s(_vm.currentSettings[_vm.id] ? _vm.currentSettings[_vm.id].title ? _vm.currentSettings[_vm.id].title[_vm.locale] : _vm.title : _vm.title)+" "),(_vm.required)?_c('WarningIcon',{staticClass:"input-checkbox-children-title-label-icon"}):_vm._e()],1)]),_c('Transition',{attrs:{"name":"fade"}},[_c('InputCheckboxChildrenItem',{attrs:{"id":_vm.id,"open":_vm.open,"title":_vm.title,"items":_vm.filteredItems,"current-settings":_vm.currentSettings,"type":_vm.type},on:{"select-item":_vm.selectItem,"toggle":_vm.toggleSubOption}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }