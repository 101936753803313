export default {
    data() {
        return {
            base64: {},
        }
    },
    methods: {
        toDataUrl(link) {
            const toURL = (url) =>
                fetch(url)
                    .then((response) => response.blob())
                    .then(
                        (blob) =>
                            new Promise((resolve, reject) => {
                                const reader = new FileReader()
                                reader.onloadend = () => resolve(reader.result)
                                reader.onerror = reject
                                reader.readAsDataURL(blob)
                            })
                    )

            toURL(link).then((dataUrl) => {
                this.$set(this.base64, link, dataUrl)
            })
        },
    },
}
