<template>
    <!-- eslint-disable -->
    <g>
        <g v-if="format === 'vertical' && patternTheme === 'dark'">
            <linearGradient
                id="ridge-soft-1"
                x1="0.069"
                y1="0.046"
                x2="0.659"
                y2="0.734"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" />
                <stop offset="0.319" stop-color="#fcfcfc" />
                <stop offset="1" stop-color="gray" />
            </linearGradient>
            <path
                id="Path_10605"
                data-name="Path 10605"
                d="M708.207,259V803.687"
                transform="translate(4)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
                opacity="0.19"
            />
            <rect
                id="Rectangle_1718"
                data-name="Rectangle 1718"
                width="387"
                height="544"
                transform="translate(690 259)"
                opacity="0.17"
                fill="url(#ridge-soft-1)"
            />
        </g>
        <g v-if="format === 'vertical' && patternTheme === 'light'">
            <linearGradient
                id="gradient-vertical-light-2"
                x1="0.231"
                y1="0.564"
                x2="0.258"
                y2="0.564"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#646464" />
                <stop offset="1" stop-color="#2e2e2e" stop-opacity="0" />
            </linearGradient>
            <path
                id="Path_10606"
                data-name="Path 10606"
                d="M0,0H86.383V544.11H0Z"
                transform="translate(690.605 259)"
                opacity="0.03"
                fill="url(#gradient-vertical-light-2)"
            />
            <path
                id="Path_10607"
                data-name="Path 10607"
                d="M708.207,259V803.687"
                transform="translate(4)"
                fill="none"
                stroke="#000"
                stroke-width="1"
                opacity="0.12"
            />
        </g>
        <g v-if="format === 'square' && patternTheme === 'dark'">
            <linearGradient
                id="square-dark-ridge"
                x1="0.231"
                y1="0.564"
                x2="0.258"
                y2="0.564"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#646464" />
                <stop offset="1" stop-color="#2e2e2e" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="square-dark-ridge-2"
                x1="0.022"
                y1="0.465"
                x2="1.172"
                y2="0.467"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#9b9b9b" stop-opacity="0.2" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="square-dark-ridge-3"
                x1="0.069"
                y1="0.046"
                x2="0.659"
                y2="0.734"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" />
                <stop offset="0.319" stop-color="#fcfcfc" />
                <stop offset="1" stop-color="gray" />
            </linearGradient>
            <path
                id="Path_10609"
                data-name="Path 10609"
                d="M0,0H86.383V263H0Z"
                transform="translate(691.604 259.273)"
                opacity="0.26"
                fill="url(#square-dark-ridge)"
            />
            <path
                id="Path_10610"
                data-name="Path 10610"
                d="M0,0H264.395V263H0Z"
                transform="translate(691.605 259.273)"
                fill="url(#square-dark-ridge-2)"
            />
            <path
                id="Rectangle_1721"
                data-name="Rectangle 1721"
                d="M0,0H265V260H0V0Z"
                transform="translate(691 259.273)"
                opacity="0.17"
                fill="url(#square-dark-ridge-3)"
            />
            <path
                id="Path_10611"
                data-name="Path 10611"
                d="M708.207,259V522"
                transform="translate(5 0.273)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
                opacity="0.19"
            />
        </g>
        <g v-if="format === 'square' && patternTheme === 'light'">
            <linearGradient
                id="gradient-square-light"
                x1="0.022"
                y1="0.465"
                x2="1.172"
                y2="0.467"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#9b9b9b" stop-opacity="0.2" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="gradient-square-light-2"
                x1="0.231"
                y1="0.564"
                x2="0.258"
                y2="0.564"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#646464" />
                <stop offset="1" stop-color="#2e2e2e" stop-opacity="0" />
            </linearGradient>
            <g
                id="Group_1481"
                data-name="Group 1481"
                transform="translate(-958 -1156.727)"
            >
                <path
                    id="Path_10613"
                    data-name="Path 10613"
                    d="M0,0H86.383V263H0Z"
                    transform="translate(1649.604 1416)"
                    fill="none"
                    opacity="0.26"
                />
                <path
                    id="Path_10612"
                    data-name="Path 10612"
                    d="M0,0H264.395V263H0Z"
                    transform="translate(1649.605 1416)"
                    fill="url(#gradient-square-light)"
                />
                <path
                    id="Path_10614"
                    data-name="Path 10614"
                    d="M708.207,259V522"
                    transform="translate(963 1157)"
                    fill="none"
                    stroke="#000"
                    stroke-width="1"
                    opacity="0.12"
                />
                <path
                    id="Path_10615"
                    data-name="Path 10615"
                    d="M0,0H86.383V260.488H0Z"
                    transform="translate(1649.605 1417)"
                    opacity="0.03"
                    fill="url(#gradient-square-light-2)"
                />
            </g>
        </g>

        <g v-if="format === 'horizontal' && patternTheme === 'dark'">
            <linearGradient
                id="gradient-horizontal-dark-2"
                x1="0.231"
                y1="0.564"
                x2="0.258"
                y2="0.564"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#646464" />
                <stop offset="1" stop-color="#2e2e2e" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="gradient-horizontal-dark-3"
                x1="0.069"
                y1="0.046"
                x2="0.659"
                y2="0.734"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#fff" />
                <stop offset="0.319" stop-color="#fcfcfc" />
                <stop offset="1" stop-color="gray" />
            </linearGradient>
            <g
                id="Group_1482"
                data-name="Group 1482"
                transform="translate(-1797 -162)"
            >
                <path
                    id="Path_10616"
                    data-name="Path 10616"
                    d="M0,0H86.383V227H0Z"
                    transform="translate(2489.604 420)"
                    opacity="0.26"
                    fill="url(#gradient-horizontal-dark-2)"
                />
                <path
                    id="Rectangle_1726"
                    data-name="Rectangle 1726"
                    d="M0,0H372V226H0V0Z"
                    transform="translate(2489 421)"
                    opacity="0.17"
                    fill="url(#gradient-horizontal-dark-3)"
                />
                <path
                    id="Path_10617"
                    data-name="Path 10617"
                    d="M708.207,259V486"
                    transform="translate(1803 161)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="1"
                    opacity="0.19"
                />
            </g>
        </g>
        <g v-if="format === 'horizontal' && patternTheme === 'light'">
            <linearGradient
                id="gradient-horizontal-light"
                x1="0.022"
                y1="0.465"
                x2="1.172"
                y2="0.467"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#9b9b9b" stop-opacity="0.2" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="gradient-horizontal-light-2"
                x1="0.231"
                y1="0.564"
                x2="0.258"
                y2="0.564"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#646464" />
                <stop offset="1" stop-color="#2e2e2e" stop-opacity="0" />
            </linearGradient>
            <g transform="translate(692, 259)">
                <path
                    id="Path_10618"
                    data-name="Path 10618"
                    d="M0,0H385.115V225.794H0Z"
                    transform="translate(1.368 1.258)"
                    fill="url(#gradient-horizontal-light)"
                />
                <path
                    id="Path_10619"
                    data-name="Path 10619"
                    d="M0,0H86.383V225.794H0Z"
                    transform="translate(1.368 1.258)"
                    opacity="0.03"
                    fill="url(#gradient-horizontal-light-2)"
                />
                <path
                    id="Rectangle_1727"
                    data-name="Rectangle 1727"
                    d="M0,0H370V225H0V0Z"
                    transform="translate(1.762 1.258)"
                    fill="#fff"
                    opacity="0.17"
                />
                <path
                    id="Path_10620"
                    data-name="Path 10620"
                    d="M708.207,259V485"
                    transform="translate(-685.238 -257.742)"
                    fill="none"
                    stroke="#000"
                    stroke-width="1"
                    opacity="0.12"
                />
            </g>
        </g>
    </g>
</template>

<script>
export default {
    props: {
        format: {
            type: String,
            default: '',
        },
        patternTheme: {
            type: String,
            required: true,
        },
    },
}
</script>

<style>
.cls-6 {
    opacity: 0.17;
}

.cls-7,
.cls-9 {
    fill: none;
}
</style>
