<template>
    <div class="material-header">
        <a
            class="material-header-back"
            :href="$t('link.mainWebsite')"
            v-text="$t('link.label')"
        />
        <LangChange />
        <div class="material-header-logo">
            <img src="/img/logo/logo_mindnotes.svg" alt="logo" />
        </div>
        <SvgLogoSmall class="material-header-logo-mobile" />
    </div>
</template>

<script>
import SvgLogoSmall from '@/assets/images/logo_mindnotes_small.svg'
import LangChange from '@/components/lang/LangChange'

export default {
    components: {
        SvgLogoSmall,
        LangChange,
    },
}
</script>

<style lang="scss">
.material-header {
    display: none;

    &-logo {
        &-mobile {
            display: none;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        padding: 16px 0;
        background-color: $color-bg-dark;

        &-logo {
            height: 30px;
            margin-right: 33px;

            img {
                height: 30px;
            }
        }

        &-back {
            @include size(auto, 48px);
            cursor: pointer;
            transition: 0.1s;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 33px;
            z-index: 19;
            color: $color-gray;

            &::before {
                @include size(10px);
                content: '';
                display: block;
                border: solid $color-gray;
                border-width: 0 0 2px 2px;
                transform: rotate(45deg);
                margin-right: 10px;
            }
        }
    }

    @media (max-width: $screen-mobile-landscape) {
        padding: 6px 0;
    }

    @media (max-width: $screen-mobile-large) {
        &-logo {
            display: none;

            &-mobile {
                display: block;
                fill: $color-white;
                margin-right: 15px;
                transform: scale(0.65);
            }
        }
    }
}
</style>
