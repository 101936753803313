<script>
import BasePageMixin from '@/mixins/BasePageMixin'
import NotesImage from '@/components/notes/NotesCover'
import FormatData from '@/mixins/pages/FormatData'
import { mapState } from 'vuex'

export default {
    components: {
        NotesImage,
    },
    extends: BasePageMixin,
    mixins: [FormatData],
    computed: {
        ...mapState({
            format: (state) => state.notes.configuration.format,
        }),
    },
    methods: {
        validate() {
            return !!this.format
        },
    },
}
</script>
