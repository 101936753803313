<template>
    <div
        :class="[
            'option',
            { 'is-current': currentOption ? currentOption.id === id : false },
            { 'is-selected': selected },
        ]"
    >
        <div class="option-container">
            <div class="option-title" @click="toggleOption">
                {{ title }}
                <WarningIcon
                    v-if="option.required"
                    class="option-title-error-icon"
                />
                <div class="option-title-info" @click="showPopoup">
                    <InfoIcon v-if="hasModal" />
                </div>
            </div>
            <div class="option-content">
                <slot name="content" />
            </div>
        </div>
        <div v-if="isModal" class="option-more" @click="showMore()" />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import WarningIcon from '@/assets/images/system-icons/warning-icon.svg'
import InfoIcon from '@/assets/images/system-icons/i-icon.svg'

export default {
    components: { WarningIcon, InfoIcon },
    props: {
        title: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        isModal: {
            type: Boolean,
            default: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        option: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapState({
            currentOption: (state) => state.notes.currentOption,
        }),
        hasModal() {
            return this.currentOption?.isModal ? true : false
        },
    },
    created() {
        if (!this.currentOption) {
            this.setCurrentOption(this.option)
        }
    },
    methods: {
        ...mapMutations([
            'setCurrentOption',
            'setCurrentSubOption',
            'openModal',
            'setModalComponentId',
        ]),
        toggleOption() {
            this.setCurrentOption(this.option)
            this.setCurrentSubOption(null)
            this.$emit('edit', { id: this.id })
        },
        showMore() {
            this.setModalComponentId(this.id)
            this.openModal(true)
        },
        showPopoup() {
            if (this.hasModal) {
                this.setModalComponentId(this.currentOption.id)
                this.openModal(true)
            }
        },
    },
}
</script>

<style lang="scss">
.option {
    position: relative;
    max-width: 400px;
    max-height: 57px;
    margin-bottom: 2px;
    overflow: hidden;
    transition: all 0.8s ease-in-out;
    transition-delay: 0s;
    display: flex;

    &-container {
        width: calc(100% - 57px);
    }

    &-title {
        color: $color-default-text;
        background: $color-bg-light;
        padding: 17px 25px;
        font-size: 20px;
        transition: background-color 0.3s, opacity 0.3s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-error-icon {
            z-index: 4;
        }

        &-info {
            display: none;
        }

        &:hover {
            background: $color-bg-lighter;
        }
    }

    &-content {
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0.1s;
    }

    &-more {
        background: url('/img/info.svg');
        background-size: contain;
        opacity: 0;
        cursor: default;
        pointer-events: none;
        transition: opacity 0.3s;
        @include size(57px);
    }

    &.is-current & {
        &-title {
            path {
                fill: $color-white;
            }
        }
    }

    &.is-selected & {
        &-title {
            path {
                fill: transparent;
            }
        }
    }

    &.is-selected {
        max-height: 750px;
        overflow: visible;
    }

    &.is-current {
        max-height: 750px;
        overflow: visible;
    }

    &.is-current & {
        &-title {
            color: $color-white;
            background: $color-burnt-sienna;

            &:hover {
                background: $color-burnt-sienna-light;
            }
        }

        &-content {
            opacity: 1;
        }

        &-more {
            opacity: 1;
            cursor: pointer;
            pointer-events: auto;
        }
    }

    &.is-selected & {
        &-title {
            color: $color-white;
            background: $color-bg-selected;

            &:hover {
                background: $color-bg-selected-lighter;
            }
        }

        &-content {
            opacity: 1;
        }
    }

    &.is-selected {
        &:after {
            content: '';
            position: absolute;
            top: 19px;
            right: 77px;
            background: url('/img/check.svg') top center no-repeat;
            opacity: 1;
            transform: translateX(0px);
            transition: opacity 0.2s, transform 0.2s;
            pointer-events: none;
            @include size(23px, 20px);
        }

        .input-checkbox {
            border-bottom: none;

            &:not(.is-checked) {
                display: none;
            }
        }
    }

    @media (max-width: $screen-tablet-medium) {
        &-title {
            &-error-icon {
                display: none;
            }

            &-info {
                display: block;
                z-index: 6;
            }
        }
        &.is-selected::after {
            right: 30px;
        }

        &-more {
            display: none;
        }

        &-container {
            width: 100%;
        }

        &::after {
            right: 27px;
        }
    }

    @media (max-width: $screen-mobile-large) {
        max-width: unset;
        max-height: 53px;

        &-title {
            font-size: 1.8rem;
            padding: 15px 25px;
        }
    }
}
</style>
