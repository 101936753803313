import Login from './Login'
import RegisterAgency from './RegisterAgency'
import ForgotPassword from './ForgotPassword'
import MessagePage from './MessagePage'
import NewPassword from './NewPassword'
import NewPasswordReset from './NewPasswordReset'

export default {
    Login,
    RegisterAgency,
    ForgotPassword,
    MessagePage,
    NewPassword,
    NewPasswordReset,
}
