<template>
    <!-- eslint-disable -->
    <g>
        <defs>
            <pattern
                id="flyleaf-pattern-1-1-green"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="base64[options[format].image.image11Green]"
                />
            </pattern>
            <pattern
                id="flyleaf-pattern-1-0"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="base64[images['1-1']]"
                />
            </pattern>
            <pattern
                id="flyleaf-pattern-1-1-white"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="base64[options[format].image.image11White]"
                />
            </pattern>
            <pattern
                id="flyleaf-pattern-2-2"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="base64[options[format].image.image22]"
                />
            </pattern>
            <pattern
                id="flyleaf-pattern-cmyk-0"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="base64[images['cmyk-0']]"
                />
            </pattern>
            <pattern
                id="flyleaf-pattern-cmyk-0-white"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="base64[images['cmyk-0-dark']]"
                />
            </pattern>
            <pattern
                id="flyleaf-pattern-cmyk-cmyk"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="base64[options[format].image.imageCmykCmyk]"
                />
            </pattern>
            <pattern
                id="flyleaf-pattern-cmyk-cmyk-dark"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="
                        base64[options[format].image.imageCmykCmykDark]
                    "
                />
            </pattern>
            <clipPath id="flyleaf-vertical-clip-path">
                <rect
                    id="Rectangle_1603"
                    data-name="Rectangle 1603"
                    class="notes-pages-1"
                    :width="options[format].clipWidth"
                    height="451"
                    transform="translate(10 5.096)"
                />
            </clipPath>
            <clipPath id="flyleaf-pock-vertical-clip-path">
                <rect
                    id="Rectangle_1603"
                    data-name="Rectangle 1603"
                    class="notes-pages-1"
                    width="640"
                    height="451"
                    :transform="options[format].flyleafPocketTransform"
                />
            </clipPath>
        </defs>

        <g v-if="isCalendar" clip-path="url(#flyleaf-vertical-clip-path)">
            <rect
                id="Untitled-3"
                :ry="isRound ? options[format].corner : 0"
                :rx="isRound ? options[format].corner : 0"
                :style="background"
                :width="options[format].width"
                :height="options[format].height"
                :transform="options[format].transform"
            />
        </g>

        <!-- show left edge when pocket is active -->
        <g
            v-else-if="currentPage === 'accessories' && isPocket"
            clip-path="url(#flyleaf-pock-vertical-clip-path)"
        >
            <rect
                id="Untitled-3"
                :style="background"
                :width="options[format].width"
                :height="options[format].height"
                :transform="options[format].transform"
                :ry="isRound ? options[format].corner : 0"
                :rx="isRound ? options[format].corner : 0"
            />
        </g>

        <rect
            v-else
            id="Untitled-3"
            :style="background"
            :width="options[format].width"
            :height="options[format].height"
            :transform="options[format].transform"
            :ry="isRound ? options[format].corner : 0"
            :rx="isRound ? options[format].corner : 0"
        />
    </g>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        format: {
            type: String,
            default: '',
        },
        currentPage: {
            type: String,
            default: '',
        },
        isPocket: {
            type: Boolean,
            default: false,
        },
        isCalendar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            images: {
                '1-1': '/img/notes/wyklejka/1-0.png',
                'cmyk-0': '/img/notes/wyklejka/cmyk-0.jpg',
                'cmyk-0-dark': '/img/notes/wyklejka/cmyk-0-dark.jpg',
            },
            options: {
                vertical: {
                    corner: 17,
                    width: '623',
                    height: '451',
                    transform: 'translate(0 5)',
                    clipWidth: '320',
                    flyleafPocketTransform: 'translate(18 5.096)',
                    image: {
                        image11Green: '/img/notes/wyklejka/1-1-green.png',
                        image22: '/img/notes/wyklejka/2-2.png',
                        image11White: '/img/notes/wyklejka/1-1-white.png',
                        imageCmykCmyk: '/img/notes/wyklejka/cmyk-cmyk.png',
                        imageCmykCmykDark:
                            '/img/notes/wyklejka/cmyk-cmyk-dark.png',
                    },
                },
                horizontal: {
                    corner: 8,
                    width: '615',
                    height: '184',
                    transform: 'translate(0 3)',
                    clipWidth: '313',
                    flyleafPocketTransform: 'translate(11 0)',
                    image: {
                        image11Green: '/img/notes/wyklejka/205-1-1-green.png',
                        image11: '/img/notes/wyklejka/205-1-1.png',
                        image22: '/img/notes/wyklejka/205-2-2.png',
                        imageCmykCmyk: '/img/notes/wyklejka/205-cmyk-cmyk.png',
                        imageCmykCmykDark:
                            '/img/notes/wyklejka/205-cmyk-cmyk-dark.png',
                    },
                },
                square: {
                    corner: 10,
                    width: '509',
                    height: '263',
                    transform: 'translate(2 5)',
                    clipWidth: '262',
                    flyleafPocketTransform: 'translate(18 5.096)',
                    image: {
                        image11Green: '/img/notes/wyklejka/144-1-1-green.png',
                        image11: '/img/notes/wyklejka/144-1-1.png',
                        image22: '/img/notes/wyklejka/144-2-2.png',
                        imageCmykCmyk: '/img/notes/wyklejka/144-cmyk-cmyk.png',
                        imageCmykCmykDark:
                            '/img/notes/wyklejka/144-cmyk-cmyk-dark.png',
                    },
                },
            },
        }
    },
    computed: {
        ...mapGetters(['paperColor']),
        ...mapState({
            color: (state) => {
                const option = state.notes.configuration.flyleaf
                return (
                    option?.singleFlyleafPrint?.title.pl ||
                    option?.doubleFlyleafPrint?.title.pl ||
                    null
                )
            },
            flyleafPaper: (state) => {
                return state.notes.configuration.flyleafPaper
            },
            flyleafPaperColor: (state) => {
                const notes = state.notes
                return (
                    notes.configuration.flyleafPaper?.hex ||
                    notes.configurationDefault.flyleafPaper?.hex
                )
            },
            flyleafPaperImage: (state) => {
                return  state.notes.configuration.flyleafPaper?.image
            },
            isRound: (state) => {
                const option = state.notes.configuration.coverCorners
                return option?.title.pl === `Zaokrąglone`
            },
        }),
        background() {
            let background = ''
            switch (this.color) {
                case '1 kolor':
                    background = '#7eb9bf'
                    break
                case '2 kolory':
                    background = 'url(#flyleaf-pattern-1-0)'
                    break
                case 'CMYK':
                    background = 'url(#flyleaf-pattern-cmyk-0-white)'
                    break
                case 'Biały':
                    background = '#fff'
                    break
                case 'CMYK + biały':
                    background = 'url(#flyleaf-pattern-cmyk-0)'
                    break

                // Dwustronne
                case '1 + 1':
                    background = 'url(#flyleaf-pattern-1-1-green)'
                    break
                case '2 + 2':
                    background = 'url(#flyleaf-pattern-2-2)'
                    break
                case 'CMYK + CMYK':
                    background = 'url(#flyleaf-pattern-cmyk-cmyk-dark)'
                    break
                case 'Biały + biały':
                    background = 'url(#flyleaf-pattern-1-1-white)'
                    break
                case 'CMYK i biały + CMYK i biały':
                    background = 'url(#flyleaf-pattern-cmyk-cmyk)'
                    break
                default:
                    background = this.flyleafPaper
                        ? this.flyleafPaperImage ? 'url(#flyleafPaper)' : this.flyleafPaperColor
                        : this.paperColor
            }
            return `fill: ${background};`
        },
    },
    created() {
        Object.keys(this.images).forEach((image) => {
            this.toDataUrl(this.images[image])
        })

        Object.keys(this.options).forEach((format) => {
            Object.keys(this.options[format].image).forEach((img) => {
                this.toDataUrl(this.options[format].image[img])
            })
        })

        // Object.keys(this.options.horizontal.image).forEach((img) => {
        //     this.toDataUrl(this.dark[img])
        // })
        // Object.keys(this.options.square.image).forEach((img) => {
        //     this.toDataUrl(this.dark[img])
        // })
    },
}
</script>
