import Vue from 'vue'
import Bastards from './bastards'
import ImageLazy from '@/components/image/ImageLazy'
import ImageLazySimple from '@/components/image/ImageLazySimple'
import ImageLazyResponsive from '@/components/image/ImageLazyResponsive'
import Loader from '@/components/loader/Loader'
import { ObserveVisibility } from 'vue-observe-visibility'
import i18n from './i18n'

Vue.directive('observe-visibility', ObserveVisibility)
Vue.component('ImageLazy', ImageLazy)
Vue.component('ImageLazySimple', ImageLazySimple)
Vue.component('ImageLazyResponsive', ImageLazyResponsive)
Vue.component('Loader', Loader)
