<script>
import BasePageMixin from '@/mixins/BasePageMixin'
import NotesImage from '@/components/notes/NotesCover'
import AccessoriesData from '@/mixins/pages/AccessoriesData'
import { mapState } from 'vuex'

export default {
    components: {
        NotesImage,
    },
    extends: BasePageMixin,
    mixins: [AccessoriesData],
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
            ribbon: (state) => state.notes.configuration.ribbon,
            elastic: (state) => state.notes.configuration.elastic,
            coverType: (state) => state.notes.configuration.coverType,
            defaultLocale: (state) => state.i18n.defaultLocale,
        }),
    },
    methods: {
        validate() {
            // jeśli występuje tasiemka i twarde okładki wówczas tasiemka i jej kolor muszą być wybrane
            // Kieszonka jest obowiązkowa w sytuacji wyboru gumki zamykajacej dla miękkiej okładki

            const coverTypeId = this.coverType?.apiId

            const isRibbonRequired = ['3', '4'].includes(coverTypeId)

            const isPocketRequired =
                this.elastic?.general && ['1', '2'].includes(coverTypeId)

            const isPocket = !!this.configuration.pocket

            const isRibbon =
                !!this.configuration.ribbon?.general &&
                !!this.configuration.ribbon?.ribbonColor

            return (
                (isPocketRequired ? isPocket : true) &&
                (isRibbonRequired ? isRibbon : true) &&
                (!isPocket ||
                    this.configuration.pocket.apiId === 'B' ||
                    !!this.configuration.pocket?.doublePocketPrint ||
                    !!this.configuration.pocket?.singlePocketPrint)
            )
        },
    },
}
</script>
