import FormFieldDefault from '@/components/form/input/FormFieldDefault'
import FormMessage from '@/components/form/message/FormMessage'
import LoaderForm from '@/components/loader/LoaderForm'
import VueScrollTo from 'vue-scrollto'

const FormMixin = {
    components: {
        FormFieldDefault,
        FormMessage,
        LoaderForm,
    },
    data() {
        return {
            inProcess: false,
            result: '',
            payload: {},
        }
    },
    created() {
        this.setDefaultPayload()
    },
    methods: {
        submitForm() {
            this.result = ''
            this.inProcess = true

            VueScrollTo.scrollTo('#page-form', 400, {
                container: '#page-form',
            })

            if (!this.validation()) {
                console.log('error', this.payload)
                this.inProcess = false
                return
            }

            this.sendForm()
        },
        validation() {
            let isValid = true
            for (let ref in this.$refs) {
                if (
                    this.$refs.hasOwnProperty(ref) &&
                    typeof this.$refs[ref].validate !== 'undefined'
                ) {
                    if (!this.$refs[ref].validate()) {
                        isValid = false
                    }
                }
            }
            return isValid
        },
        sendForm() {
            throw new Error(`Method sendForm is not implemented.`)
        },
        setDefaultPayload() {
            throw new Error(`Method setDefaultPayload is not implemented.`)
        },
    },
}

export default FormMixin
