<template>
    <image
        :href="base64[image[format].url]"
        :transform="image[format].transform"
    />
</template>

<script>
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            image: {
                a4: {
                    url:
                        '/img/notes/inside/standard/half-chequered-vertical.png',
                    transform: 'translate(27 18)',
                },
                '205': {
                    url:
                        '/img/notes/inside/standard/half-chequered-horizontal.png',
                    transform: 'translate(11 5)',
                },
                '175': {
                    url: '/img/notes/inside/standard/half-chequered-square.png',
                    transform: 'translate(18 8)',
                },
                b5: {
                    url: '/img/notes/inside/standard/b5/half-chequered-b5.png',
                    transform: 'translate(33 25)',
                },
                a5: {
                    url: '/img/notes/inside/standard/a5/half-chequered-a5.png',
                    transform: 'translate(33 25)',
                },
                a6: {
                    url: '/img/notes/inside/standard/a6/half-chequered-a6.png',
                    transform: 'translate(40 25)',
                },
                '125': {
                    url:
                        '/img/notes/inside/standard/125/half-chequered-125.png',
                    transform: 'translate(48 23)',
                },
                '84': {
                    url: '/img/notes/inside/standard/84/half-chequered-84.png',
                    transform: 'translate(52 23)',
                },
            },
        }
    },

    created() {
        Object.keys(this.image).forEach((format) => {
            this.toDataUrl(this.image[format].url)
        })
    },
}
</script>
