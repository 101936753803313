<template>
    <FormFieldDefault
        v-model="inputValue"
        :name="name"
        type="tel"
        :label="label"
        :required="required"
        :theme="theme"
        @input="onInput"
    >
        <template #prepend>
            <FormDropdown
                v-model="prefixValue"
                :options="phone_country_code.map(o => ({label: o.name, value: o.code, emoji: o.emoji, dial: o.dial_code}))"
                style="margin-right: -1px"
                @input="onInput"
            >
                <template #selected="{selected}">
                    <div class="form-field-tel-selected">
                        <span class="emoji-lang">{{ selected.emoji }}</span>
                        {{ selected.dial }}
                    </div>
                </template>
                <template #option="{option}">
                    <div class="form-field-tel-option">
                        <span class="emoji-lang">
                            {{ option.emoji }}
                        </span>
                        {{ option.dial }} <small>- {{ option.label }}</small>
                    </div>
                </template>
        </formdropdown>
</template>
    </FormFieldDefault>
</template>

<script>
import ThemeClassMixin from '@/mixins/ThemeClassMixin'
import FormFieldDefault from '@/components/form/input/FormFieldDefault.vue'
import FormDropdown from '@/components/form/FormDropdown.vue'
import phone_country_code from '@/assets/data/phone_country_code.json'

export default {
    components: {
        FormDropdown,
        FormFieldDefault,
    },
    mixins: [ThemeClassMixin],
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        tips: {
            type: Array,
            default: () => [],
        },
        defaultCode: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            inputValue: null,
            prefixValue: null,
            phone_country_code
        }
    },
    created() {
        if (!this.value) {
            this.prefixValue = this.phone_country_code.find(p => p.code.toLowerCase() === this.defaultCode)?.code || ''
        } else {
            const split = this.value.split(' ', 2)
            this.prefixValue = this.phone_country_code.find(p => p.dial_code === split[0])?.code || ''
            this.inputValue = split[1]
        }
    },
    methods: {
        onInput() {
            const dial = this.phone_country_code.find(p => p.code === this.prefixValue)?.dial_code
            this.$emit('input', [dial, this.inputValue].join(' '))
        }
    }
}
</script>

<style lang="scss" >
.form-field-tel-option {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.form-field-tel-selected {

    .emoji-lang {
        margin-left: -10px;
    }
}

</style>
