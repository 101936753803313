<template>
    <div
        :class="['input-simple-checkbox', { 'is-checked': isChecked }]"
        @click="$emit('click')"
    >
        <div class="input-mark" />
        <div class="input-simple-checkbox-label" v-text="label" />
    </div>
</template>

<script>
export default {
    props: {
        isChecked: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss">
.input-simple-checkbox {
    cursor: pointer;
    display: flex;
    align-items: center;

    &.is-checked {
        .input-mark {
            &:after {
                opacity: 1;
            }
        }
    }

    &-label {
        display: inline-block;
        font-size: 16px;
        max-width: 100%;
        padding-left: 13px;
        padding-right: 60px;
    }

    @media (max-width: $screen-mobile-large) {
        &-label {
            padding-left: 10px;
            padding-right: 30px;
        }

        &:last-child & {
            &-label {
                padding-right: 0;
            }
        }
    }
}
</style>
