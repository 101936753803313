const state = {
    locale: null,
    defaultLocale: 'en',
    locales: ['pl', 'en', 'de', 'fr'],
}

const mutations = {
    setLocale(state, locale) {
        state.locale = locale
    },
}

export default {
    state,
    mutations,
}
