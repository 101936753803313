<template>
    <div class="message-page">
        <div class="message-page-box">
            <div class="message-page-box-description">
                <h1 class="message-page-box-description-title">
                    {{ title }}
                </h1>
                <div class="message-page-box-description-text" v-html="text" />
            </div>
            <div class="message-page-box-icon">
                <img :src="icons[icon]" alt="icon" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        callback: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            icons: {
                warning: '/img/login/warning.png',
                mail: '/img/login/mail.png',
                clock: '/img/login/clock.png',
            },
        }
    },
    mounted() {
        if (this.callback) {
            this.callback()
        }
    },
}
</script>

<style lang="scss">
.message-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &-box {
        @include size(857px, auto);
        box-shadow: 0 0 45px rgba(#000, 0.05);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 50px 70px;

        &-description {
            max-width: 380px;

            &-title {
                margin-top: 0;
                font-size: 4.2rem;
            }
            &-text {
                font-size: 2.5rem;
            }
        }
    }
}
</style>
