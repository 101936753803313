<template>
    <div :class="['form form-register-agency', { 'in-process': inProcess }]">
        <h2 class="page-title page-title-mobile form-register-agency-title">
            {{ $t('form.register.secondTitle') }}
        </h2>
        <LoaderForm v-if="inProcess" />
        <div v-if="result !== 'success'" class="form-fields">
            <div class="form-register-agency-radio">
                <div class="form-register-agency-radio-element">
                    <input
                        id="mr"
                        type="radio"
                        name="mrmrs"
                        value="Pan"
                        checked
                    />
                    <label for="mr">Pan</label>
                </div>

                <div class="form-register-agency-radio-element">
                    <input id="mrs" type="radio" name="mrmrs" value="mrs" />
                    <label for="mrs">Pani</label>
                </div>
            </div>

            <div class="form-register-agency-row">
                <FormFieldDefault
                    ref="name"
                    v-model="payload.name"
                    name="name"
                    theme="half"
                    :label="$t('form.name')"
                    required
                />
                <FormFieldDefault
                    ref="surname"
                    v-model="payload.surname"
                    name="surname"
                    theme="half"
                    :label="$t('form.surname')"
                    required
                />
            </div>
            <div class="form-register-agency-row">
                <FormFieldDefault
                    ref="email"
                    v-model="payload.email"
                    name="email"
                    theme="half"
                    type="email"
                    :label="$t('form.email')"
                    required
                />
                <FormFieldDefault
                    ref="companyName"
                    v-model="payload.companyName"
                    name="companyName"
                    theme="half"
                    :label="$t('form.company')"
                    required
                />
            </div>
            <div class="form-register-agency-row">
                <FormFieldDefault
                    ref="country"
                    v-model="payload.country"
                    name="country"
                    type="country"
                    theme="half"
                    :label="$t('form.country')"
                    required
                    :tips="mappedCountries"
                />
                <FormFieldDefault
                    ref="city"
                    v-model="payload.city"
                    name="city"
                    theme="half"
                    :label="$t('form.city')"
                    required
                />
            </div>
            <div class="form-register-agency-row">
                <FormFieldDefault
                    ref="postalCode"
                    v-model="payload.postalCode"
                    name="postalCode"
                    type="zip"
                    theme="half"
                    :label="$t('form.zipcode')"
                    required
                    mask="##-###"
                />
                <FormFieldDefault
                    ref="street"
                    v-model="payload.street"
                    name="street"
                    theme="half"
                    :label="$t('form.street')"
                    required
                />
            </div>
            <div class="form-register-agency-row">
                <!-- nr domu / nip -->
                <FormFieldDefault
                    ref="houseNumber"
                    v-model="payload.houseNumber"
                    name="houseNumber"
                    theme="half"
                    :label="$t('form.houseNumber')"
                    required
                />
                <FormFieldDefault
                    ref="nip"
                    v-model="payload.nip"
                    name="nip"
                    theme="half"
                    :label="$t('form.nip')"
                    required
                />
            </div>
            <div class="form-register-agency-row">
                <FormFieldDefault
                    ref="website"
                    v-model="payload.website"
                    name="website"
                    theme="half"
                    :label="$t('form.website')"
                    required
                />
                <FormFieldDefault
                    ref="phoneNumber"
                    v-model="payload.phoneNumber"
                    name="phoneNumber"
                    type="phone"
                    theme="half"
                    :label="$t('form.phone')"
                    required
                />
            </div>
            <FormCheckbox
                ref="consent1"
                v-model="payload.consent1"
                name="consent1"
                :label="$t('form.consent1')"
                required
            />
            <FormCheckbox
                ref="consent2"
                v-model="payload.consent2"
                name="consent2"
                :label="$t('form.consent2')"
            />
            <FormIsRequiredInfo class="form-register-agency-required" />

            <button
                class="send-button form-register-agency-button"
                @click="submitForm"
                v-text="$t('form.register.send')"
            />
        </div>
    </div>
</template>

<script>
import FormCheckbox from './input/FormCheckbox'
import { countries } from '@/assets/data/countries'
import FormMixin from '@/mixins/FormMixin'
import FormIsRequiredInfo from '@/components/form/FormIsRequiredInfo'

export default {
    components: {
        FormCheckbox,
        FormIsRequiredInfo,
    },
    extends: FormMixin,
    props: {
        formData: {
            type: Object,
            default: null,
        },
    },
    computed: {
        mappedCountries() {
            return countries.map((country) => {
                return country.name_pl
            })
        },
    },
    created() {
        if (this.formData) {
            this.payload = {
                companyName: this.formData.companyName
                    ? this.formData.companyName
                    : null,
                phoneNumber: this.formData.phoneNumber
                    ? this.formData.phoneNumber
                    : null,
                email: this.formData.email ? this.formData.email : null,
                website: this.formData.website ? this.formData.website : null,
                street: this.formData.street ? this.formData.street : null,
                city: this.formData.city ? this.formData.city : null,
                postalCode: this.formData.postalCode
                    ? this.formData.postalCode
                    : null,
                country: this.formData.country ? this.formData.country : null,
                name: this.formData.name ? this.formData.name : null,
                surname: this.formData.surname ? this.formData.surname : null,
                position: this.formData.position
                    ? this.formData.position
                    : null,
                message: null,
                consent: false,
                sentToExtraEmails: false,
                extraEmails: [],
            }
        }
    },
    methods: {
        sendForm() {
            let success = true
            setTimeout(() => {
                if (success) {
                    this.inProcess = false
                    this.result = 'success'
                    this.setDefaultPayload()
                } else {
                    this.result = 'error'
                    this.inProcess = false
                }
            }, 1000)
        },
        setDefaultPayload() {
            this.payload = {
                companyName: null,
                phoneNumber: null,
                email: null,
                website: null,
                street: null,
                city: null,
                postalCode: null,
                country: null,
                name: null,
                surname: null,
                position: null,
                message: null,
                consent: false,
                sentToExtraEmails: false,
                extraEmails: [],
            }
        },
    },
}
</script>

<style lang="scss">
.form-register-agency {
    margin-bottom: 50px;

    &-radio {
        display: flex;
        margin-bottom: 30px;

        &-element {
            margin-right: 30px;

            label {
                margin-left: 10px;
            }
        }
    }

    &-title {
        margin-bottom: 40px;
        margin-top: 35px;
    }
    &-row {
        display: flex;
        justify-content: space-between;
    }
    &-button {
        margin-left: auto;
        margin-top: 70px;
    }

    &-required {
        margin-left: 46px;
    }
    @media (max-width: $screen-tablet-medium) {
        &-required {
            margin-left: 60px;
        }
    }

    @media (max-width: $screen-mobile-large) {
        &-row {
            flex-direction: column;
        }
    }
}
</style>
