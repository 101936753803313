<template>
    <!-- eslint-disable -->
    <g>
        <g v-if="format === 'vertical'">
            <g
                id="Group_4732"
                data-name="Group 4732"
                class="notes-open-6"
                :transform="
                    isCoverSoft
                        ? 'translate(272 5) scale(1 0.98)'
                        : 'translate(272)'
                "
            >
                <rect
                    id="Rectangle_13492"
                    data-name="Rectangle 13492"
                    class="notes-open-7"
                    width="48.22"
                    height="461.324"
                    transform="translate(48.22 461.324) rotate(180)"
                />
                <rect
                    id="Rectangle_13492-2"
                    data-name="Rectangle 13492"
                    class="notes-open-8"
                    width="48.194"
                    height="461.324"
                    transform="translate(48.164)"
                />
            </g>
            <line
                id="Line_732"
                data-name="Line 732"
                class="notes-open-9"
                y2="452.797"
                transform="translate(320.194 4.69)"
            />

            <g v-if="roundCorners">
                <linearGradient
                    id="not-op-edg-3"
                    x1="1"
                    y1="0.637"
                    x2="-1.242"
                    y2="0.638"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-opacity="0" />
                    <stop offset="1" stop-opacity="0.302" />
                </linearGradient>
                <linearGradient
                    id="not-op-edg-4"
                    x1="0"
                    y1="0.637"
                    x2="2.242"
                    y2="0.638"
                    xlink:href="#not-op-edg-3"
                />

                <path
                    id="Path_611"
                    data-name="Path 611"
                    d="M683.21,591.71v-415a18.022,18.022,0,0,0-18-18h18v.021h.914a17,17,0,0,1,17,17V592.71a17,17,0,0,1-17,17H665.21A18.023,18.023,0,0,0,683.21,591.71Z"
                    transform="translate(-60 -153)"
                    fill="url(#not-op-edg-3)"
                    style="isolation: isolate;"
                />
                <path
                    v-if="!isFlyleafVirtual && showLeftEdge"
                    id="Path_6299"
                    data-name="Path 6299"
                    d="M683.124,591.71v-415a18.022,18.022,0,0,1,18-18h-18v.021h-.914a17,17,0,0,0-17,17V592.71a17,17,0,0,0,17,17h18.914A18.023,18.023,0,0,1,683.124,591.71Z"
                    transform="translate(-666 -153)"
                    fill="url(#not-op-edg-4)"
                    style="isolation: isolate;"
                />
            </g>
            <g v-else>
                <rect
                    v-if="pagePosition !== 'after'"
                    id="Rectangle_13922"
                    data-name="Rectangle 13922"
                    class="notes-open-10"
                    width="17.496"
                    height="451.944"
                    transform="translate(621.998 5.116)"
                />
                <rect
                    v-else-if="pagePosition !== 'before'"
                    id="Rectangle_13922"
                    class="notes-open-10"
                    data-name="Rectangle 13922"
                    width="9.496"
                    height="451.944"
                    transform="translate(629.998 5.116)"
                    opacity="0.31"
                />

                <rect
                    v-if="showLeftEdge && pagePosition !== 'before'"
                    id="Rectangle_13932"
                    data-name="Rectangle 13932"
                    class="notes-open-11"
                    width="17.496"
                    height="451.944"
                    transform="translate(17.907 457.061) rotate(180)"
                />
                <rect
                    v-else-if="showLeftEdge"
                    id="Rectangle_13932"
                    class="notes-open-11"
                    data-name="Rectangle 13922"
                    width="9.496"
                    height="451.944"
                    transform="translate(10 457.061) rotate(180)"
                    opacity="0.31"
                />
            </g>
        </g>

        <g v-if="format === 'horizontal'">
            <g
                id="Group_4732"
                data-name="Group 4732"
                class="notes-open-6"
                :transform="
                    isCoverSoft
                        ? 'translate(265.474 5) scale(1 0.95)'
                        : 'translate(265.474)'
                "
            >
                <rect
                    id="Rectangle_13492"
                    data-name="Rectangle 13492"
                    class="notes-open-7"
                    width="47.1"
                    height="192.457"
                    transform="translate(47.1 192.457) rotate(180)"
                />
                <rect
                    id="Rectangle_13492-2"
                    data-name="Rectangle 13492"
                    class="notes-open-8"
                    width="47.075"
                    height="192.457"
                    transform="translate(47.045)"
                />
            </g>
            <line
                id="Line_732"
                data-name="Line 732"
                class="notes-open-9"
                y2="186.664"
                transform="translate(312.531 3.186)"
            />

            <g v-if="roundCorners">
                <linearGradient
                    id="not-op-edge-horizontal"
                    x1="1"
                    y1="0.637"
                    x2="-1.242"
                    y2="0.638"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-opacity="0" />
                    <stop offset="1" stop-opacity="0.302" />
                </linearGradient>
                <linearGradient
                    id="not-op-edge-horizontal-2"
                    x1="0"
                    y1="0.637"
                    x2="2.242"
                    y2="0.638"
                    xlink:href="#not-op-edge-horizontal"
                />

                <path
                    id="Path_611"
                    data-name="Path 611"
                    d="M672.615,336.846V166.115a7.414,7.414,0,0,0-7.405-7.405h7.405v.009h.376a6.994,6.994,0,0,1,6.994,6.994V337.257a6.994,6.994,0,0,1-6.994,6.994H665.21A7.415,7.415,0,0,0,672.615,336.846Z"
                    transform="translate(-56 -155)"
                    fill="url(#not-op-edge-horizontal)"
                    style="isolation: isolate;"
                />
                <path
                    v-if="!isFlyleafVirtual && showLeftEdge"
                    id="Path_6298"
                    data-name="Path 6298"
                    d="M672.58,336.846V166.115a7.414,7.414,0,0,1,7.405-7.405H672.58v.009H672.2a6.994,6.994,0,0,0-6.994,6.994V337.257a6.994,6.994,0,0,0,6.994,6.994h7.781A7.415,7.415,0,0,1,672.58,336.846Z"
                    transform="translate(-664 -155)"
                    fill="url(#not-op-edge-horizontal-2)"
                    style="isolation: isolate;"
                />
            </g>
            <g v-else>
                <rect
                    v-if="pagePosition !== 'after'"
                    id="Rectangle_13922"
                    data-name="Rectangle 13922"
                    class="notes-open-10"
                    width="11"
                    height="186.085"
                    transform="translate(613.326 3.475)"
                />

                <rect
                    v-if="showLeftEdge && pagePosition !== 'before'"
                    id="Rectangle_13932"
                    data-name="Rectangle 13932"
                    class="notes-open-11"
                    width="11"
                    height="186.085"
                    transform="translate(11.265 189.561) rotate(180)"
                />
                <rect
                    v-if="showLeftEdge && pagePosition === 'before'"
                    id="Rectangle_13932"
                    data-name="Rectangle 13932"
                    class="notes-open-11"
                    width="7"
                    height="186.085"
                    transform="translate(7.265 189.561) rotate(180)"
                />

                <rect
                    v-if="pagePosition === 'after'"
                    id="Rectangle_13922"
                    data-name="Rectangle 13922"
                    width="7"
                    height="186.085"
                    transform="translate(618.326 3.475)"
                    opacity="0.31"
                    fill="url(#after-horiz-linear-gradient-2)"
                />
            </g>
        </g>
        <g v-if="format === 'square'">
            <g
                id="Group_4732"
                data-name="Group 4732"
                class="notes-open-6"
                :transform="
                    isCoverSoft
                        ? 'translate(215.232 4) scale(1 0.97)'
                        : 'translate(215.232)'
                "
            >
                <rect
                    id="Rectangle_13492"
                    data-name="Rectangle 13492"
                    class="notes-open-7"
                    width="48.22"
                    height="273.99"
                    transform="translate(48.22 273.99) rotate(180)"
                />
                <rect
                    id="Rectangle_13492-2"
                    data-name="Rectangle 13492"
                    class="notes-open-8"
                    width="48.194"
                    height="273.99"
                    transform="translate(48.164)"
                />
            </g>
            <line
                id="Line_732"
                data-name="Line 732"
                class="notes-open-9"
                y2="265.463"
                transform="translate(263.415 4.69)"
            />
            <g v-if="roundCorners">
                <linearGradient
                    id="not-op-edge-1"
                    x1="1"
                    y1="0.637"
                    x2="-1.242"
                    y2="0.638"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-opacity="0" />
                    <stop offset="1" stop-opacity="0.302" />
                </linearGradient>
                <linearGradient
                    id="not-op-edge-2"
                    x1="0"
                    y1="0.637"
                    x2="2.242"
                    y2="0.638"
                    xlink:href="#not-op-edge-1"
                />

                <path
                    id="Path_611"
                    data-name="Path 611"
                    d="M675.707,411.213V169.207a10.51,10.51,0,0,0-10.5-10.5h10.5v.012h.533a9.913,9.913,0,0,1,9.914,9.914V411.8a9.913,9.913,0,0,1-9.914,9.914H665.21A10.51,10.51,0,0,0,675.707,411.213Z"
                    transform="translate(-162 -153)"
                    fill="url(#not-op-edge-1)"
                    style="isolation: isolate;"
                />
                <path
                    v-if="!isFlyleafVirtual && showLeftEdge"
                    id="Path_6298"
                    data-name="Path 6298"
                    d="M675.657,411.213V169.207a10.51,10.51,0,0,1,10.5-10.5h-10.5v.012h-.533a9.913,9.913,0,0,0-9.914,9.914V411.8a9.913,9.913,0,0,0,9.914,9.914h11.03A10.51,10.51,0,0,1,675.657,411.213Z"
                    transform="translate(-662 -153)"
                    fill="url(#not-op-edge-2)"
                    style="isolation: isolate;"
                />
            </g>
            <g v-else>
                <rect
                    v-if="pagePosition !== 'after'"
                    id="Rectangle_13922"
                    data-name="Rectangle 13922"
                    class="notes-open-10"
                    width="17.496"
                    height="264.61"
                    transform="translate(508.505 5.116)"
                />
                <rect
                    v-if="pagePosition === 'after'"
                    id="Rectangle_13922"
                    data-name="Rectangle 13922"
                    class="notes-open-10"
                    width="7.496"
                    height="264.61"
                    transform="translate(518.505 5.116)"
                />

                <rect
                    v-if="showLeftEdge && pagePosition !== 'before'"
                    id="Rectangle_13932"
                    data-name="Rectangle 13932"
                    class="notes-open-11"
                    width="17.496"
                    height="264.61"
                    transform="translate(17.914 269.727) rotate(180)"
                />
                <rect
                    v-if="showLeftEdge && pagePosition === 'before'"
                    id="Rectangle_13932"
                    data-name="Rectangle 13932"
                    width="7.496"
                    height="264.61"
                    transform="translate(7.914 269.727) rotate(180)"
                    opacity="0.31"
                    fill="url(#notes-open-linear-gradient-2)"
                />
            </g>
        </g>
    </g>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'NotesOpenEdge',
    props: {
        format: {
            type: String,
            default: '',
        },
        pagePosition: {
            type: String,
            default: '',
        },
        roundCorners: {
            type: Boolean,
            default: false,
        },
        isCoverSoft: {
            type: Boolean,
            default: false,
        },
        isFlyleafVirtual: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            showLeftEdge: (state) => {
                const configuration = state.notes.configuration,
                    currentPage = state.page.currentPage

                const isPageFlyleaf = currentPage.id === 'flyleaf',
                    isPagePersonalized = currentPage.id === 'personalized',
                    isPageAccessories = currentPage.id === 'accessories'

                const isCalendar = configuration.calendar,
                    isPocket = configuration.pocket

                const showEdge =
                    (!isPageFlyleaf && !isPagePersonalized) ||
                    (!isCalendar && isPagePersonalized)

                if (isPocket && isPageAccessories) {
                    return true
                }
                return showEdge
            },
        }),
    },
}
</script>
