<script>
import dependencies from '@/store/modules/dependencies'

export default {
    data() {
        return {
            coverType: 'okleina',
            categoryTitle: {
                pl: 'Kategorii',
                en: 'Categories',
                de: 'Kategorie / Kategorien',
                fr: 'Catégorie / Catégories',
            },
            colorTitle: {
                pl: 'Kolorze',
                en: 'Colours',
                de: 'Farbe',
                fr: 'Couleur / en couleur',
            },
        }
    },
    computed: {
        list() {
            return dependencies.state.list
        },

        patternColors() {
            return {
                1: {
                    title: {
                        pl: 'czarny',
                        en: 'black',
                        de: 'Schwarz',
                        fr: 'Noir',
                    },
                    image: '/img/colors/czarny.svg',
                    id: 1,
                },
                2: {
                    title: {
                        pl: 'biały',
                        en: 'white',
                        de: 'Weiß',
                        fr: 'Blanc',
                    },
                    hex: '#fff',
                    hasBorder: true,
                    id: 2,
                },
                3: {
                    title: {
                        pl: 'czerwony',
                        en: 'red',
                        de: 'Rot',
                        fr: 'Rouge',
                    },
                    hex: '#ef3535',
                    id: 3,
                },
                4: {
                    title: {
                        pl: 'niebieski',
                        en: 'blue',
                        de: 'Blau',
                        fr: 'Bleu',
                    },
                    hex: '#40abee',
                    id: 4,
                },
                5: {
                    title: {
                        pl: 'szary',
                        en: 'grey',
                        de: 'Grau',
                        fr: 'Gris',
                    },
                    hex: '#949494',
                    id: 5,
                },
                6: {
                    title: {
                        pl: 'zielony',
                        en: 'green',
                        de: 'Grün',
                        fr: 'vert',
                    },
                    hex: '#4fea61',
                    id: 6,
                },
                7: {
                    title: {
                        pl: 'pomarańczowy',
                        en: 'orange',
                        de: 'Orange',
                        fr: 'Orange',
                    },
                    hex: '#f07c2d',
                    id: 7,
                },
                8: {
                    title: {
                        pl: 'żółty',
                        en: 'yellow',
                        de: 'Gelb',
                        fr: 'Jaune',
                    },
                    hex: '#f6cb15',
                    id: 8,
                },
                9: {
                    title: {
                        pl: 'fioletowy',
                        en: 'purple',
                        de: 'violett',
                        fr: 'violet',
                    },
                    hex: '#9e43f2',
                    id: 9,
                },
                10: {
                    title: {
                        pl: 'różowy',
                        en: 'pink',
                        de: 'rosa',
                        fr: 'rose',
                    },
                    hex: '#e61794',
                    id: 10,
                },
                11: {
                    title: {
                        pl: 'brązowy',
                        en: 'brown',
                        de: 'braun',
                        fr: 'marron',
                    },
                    hex: '#784008',
                    id: 11,
                },
                12: {
                    title: {
                        pl: 'złoty',
                        en: 'gold',
                        de: 'Gold',
                        fr: 'Doré',
                    },
                    image: '/img/colors/color (13).svg',
                    id: 12,
                },
                13: {
                    title: {
                        pl: 'srebrny',
                        en: 'silver',
                        de: 'Silber',
                        fr: 'Argent',
                    },
                    image: '/img/colors/color (14).svg',
                    id: 13,
                },
            }
        },
        patternCategories() {
            return {
                1: {
                    id: 1,
                    title: {
                        pl: 'Gładkie',
                        en: 'Smooth',
                        de: 'Glatt',
                        fr: 'Lisse',
                    },
                    value: 'gładkie',
                },
                2: {
                    id: 2,
                    title: {
                        pl: 'Miękkie w dotyku',
                        en: 'Soft to the touch',
                        de: 'Soft Touch',
                        fr: 'Doux au toucher',
                    },
                    value: 'miękkie w dotyku',
                },
                3: {
                    id: 3,
                    title: {
                        pl: 'Aksamitne',
                        en: 'Velvety',
                        de: 'Samt',
                        fr: 'Velours',
                    },
                    value: 'aksamitne',
                },
                4: {
                    id: 4,
                    title: {
                        pl: 'Strukturalne',
                        en: 'Textured',
                        de: 'Strukturiert',
                        fr: 'Texturé',
                    },
                    value: 'strukturalne',
                },
                5: {
                    id: 5,
                    title: {
                        pl: 'Skóropodobne',
                        en: 'Leather-like',
                        de: 'Lederimitat',
                        fr: 'Simili cuir',
                    },
                    value: 'skóropodobne',
                },
                6: {
                    id: 6,
                    title: {
                        pl: 'Płócienne',
                        en: 'Canvas',
                        de: 'Leinen',
                        fr: 'En toile',
                    },
                    value: 'płócienne',
                },
                7: {
                    id: 7,
                    title: {
                        pl: 'Metaliczne',
                        en: 'Metallic',
                        de: 'Metallic',
                        fr: 'Métallique',
                    },
                    value: 'metaliczne',
                },
            }
        },
        patternGroups() {
            return {
                1: {
                    id: 1,
                    title: {
                        pl: 'ART PAPER',
                        en: 'ART PAPER',
                        de: 'ART PAPER',
                        fr: 'ART PAPER',
                    },
                    symbol: 'VN01',
                },
                2: {
                    id: 2,
                    title: {
                        pl: 'FLORENCE',
                        en: 'FLORENCE',
                        de: 'FLORENCE',
                        fr: 'FLORENCE',
                    },
                    symbol: 'VT13',
                },
                3: {
                    id: 3,
                    title: {
                        pl: 'LINO COLOR',
                        en: 'LINO COLOR',
                        de: 'LINO COLOR',
                        fr: 'LINO COLOR',
                    },
                    symbol: 'VF04',
                },
                4: {
                    id: 4,
                    title: {
                        pl: 'LINO NATURE',
                        en: 'LINO NATURE',
                        de: 'LINO NATURE',
                        fr: 'LINO NATURE',
                    },
                    symbol: 'VN05',
                },
                5: {
                    id: 5,
                    title: {
                        pl: 'MATRYX SCALA',
                        en: 'MATRYX SCALA',
                        de: 'MATRYX SCALA',
                        fr: 'MATRYX SCALA',
                    },
                    symbol: 'VP07',
                },
                6: {
                    id: 6,
                    title: {
                        pl: 'MATRYX SCALA',
                        en: 'MATRYX SCALA',
                        de: 'MATRYX SCALA',
                        fr: 'MATRYX SCALA',
                    },
                    symbol: 'VP08',
                },
                7: {
                    id: 7,
                    title: {
                        pl: 'MATRYX SANTOS',
                        en: 'MATRYX SANTOS',
                        de: 'MATRYX SANTOS',
                        fr: 'MATRYX SANTOS',
                    },
                    symbol: 'VP11',
                },
                8: {
                    id: 8,
                    title: {
                        pl: 'MILANO',
                        en: 'MILANO',
                        de: 'MILANO',
                        fr: 'MILANO',
                    },
                    symbol: 'VP12',
                },
                9: {
                    id: 9,
                    title: {
                        pl: 'SIENA',
                        en: 'SIENA',
                        de: 'SIENA',
                        fr: 'SIENA',
                    },
                    symbol: 'VL02',
                },
                10: {
                    id: 10,
                    title: {
                        pl: 'BOLOGNA',
                        en: 'BOLOGNA',
                        de: 'BOLOGNA',
                        fr: 'BOLOGNA',
                    },
                    symbol: 'VL03',
                },
                11: {
                    id: 11,
                    title: {
                        pl: 'TORINO',
                        en: 'TORINO',
                        de: 'TORINO',
                        fr: 'TORINO',
                    },
                    symbol: 'VT01',
                },
                12: {
                    id: 12,
                    title: {
                        pl: 'VERONA',
                        en: 'VERONA',
                        de: 'VERONA',
                        fr: 'VERONA',
                    },
                    symbol: 'VT12',
                },
                20: {
                    id: 20,
                    title: {
                        pl: 'ROMA',
                        en: 'ROMA',
                        de: 'ROMA',
                        fr: 'ROMA',
                    },
                    symbol: 'VP09',
                },
                // PAPER
                13: {
                    id: 13,
                    title: {
                        pl: '',
                        en: '',
                        de: '',
                        fr: '',
                    },
                    symbol: '',
                },
                14: {
                    id: 14,
                    title: {
                        pl: 'PALERMO',
                        en: 'PALERMO',
                        de: 'PALERMO',
                        fr: 'PALERMO',
                    },
                    symbol: 'VP14',
                },
                21: {
                    id: 21,
                    title: {
                        pl: 'Newapple',
                        en: 'Newapple',
                        de: 'Newapple',
                        fr: 'Newapple',
                    },
                    symbol: 'VT14',
                },
            }
        },
        patterns() {
            let OKLEINA = null

            if (this.list?.length) {
                this.list.forEach((dependency) => {
                    OKLEINA = mergeMultipleParams(
                        OKLEINA,
                        dependency.actions.OKLEINA
                    )
                })
            }

            function mergeMultipleParams(param, dependencyAction) {
                param = param || dependencyAction

                if (param && dependencyAction) {
                    param = param.filter((dep) => {
                        return dependencyAction.includes(dep)
                    })
                }
                return param
            }

            function filterOptionsFn({ apiId }, allowed) {
                return allowed ? allowed.includes(apiId.toUpperCase()) : true
            }

            return {
                id: 'coverColorPattern',
                apiId: 'OKLEINA',
                okleina: [
                    {
                        id: 'VN0102',
                        apiId: 'VN0102',
                        image: '/img/okleiny/112/art paper 112/VN0102.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0102.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 3,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0106',
                        apiId: 'VN0106',
                        image: '/img/okleiny/112/art paper 112/VN0106.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0106.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0107',
                        apiId: 'VN0107',
                        image: '/img/okleiny/112/art paper 112/VN0107.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0107.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0108',
                        apiId: 'VN0108',
                        image: '/img/okleiny/112/art paper 112/VN0108.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0108.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 6,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0110',
                        apiId: 'VN0110',
                        image: '/img/okleiny/112/art paper 112/VN0110.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0110.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 6,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0111',
                        apiId: 'VN0111',
                        image: '/img/okleiny/112/art paper 112/VN0111.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0111.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 6,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0112',
                        apiId: 'VN0112',
                        image: '/img/okleiny/112/art paper 112/VN0112.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0112.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 3,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0114',
                        apiId: 'VN0114',
                        image: '/img/okleiny/112/art paper 112/VN0114.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0114.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 11,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0116',
                        apiId: 'VN0116',
                        image: '/img/okleiny/112/art paper 112/VN0116.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0116.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 7,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0118',
                        apiId: 'VN0118',
                        image: '/img/okleiny/112/art paper 112/VN0118.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0118.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0119',
                        apiId: 'VN0119',
                        image: '/img/okleiny/112/art paper 112/VN0119.jpg',
                        imageLarge: '/img/okleiny/320/art paper 320/VN0119.jpg',
                        group: 1,
                        category: [4, 6],
                        title: {
                            pl:
                                'ART PAPER termoodbarwialna okleina introligatorska o strukturze płótna',
                            en:
                                'ART PAPER canvas design - heat sensitive paper cover material',
                            de:
                                'ART PAPER – farbveränderndes Einbandmaterial aus Papier mit Leinentextur',
                            fr:
                                'ART PAPER matière thermochromique texture toile',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1301',
                        apiId: 'VT1301',
                        image: '/img/okleiny/112/florence 112/VT1301.jpg',
                        imageLarge: '/img/okleiny/320/florence 320/VT1301.jpg',
                        group: 2,
                        category: [2, 3, 5],
                        title: {
                            pl:
                                'FLORENCE termoodbarwialna okleina introligatorska przypominająca nubuk, z delikatnym, zamszowym wykończeniem',
                            en:
                                'FLORENCE heat sensitive cover material that resembling nubuck, with a delicate suede finish',
                            de:
                                'FLORENCE – farbveränderndes nubukähnliches Einbandmaterial mit feiner Wildlederoptik',
                            fr:
                                'FLORENCE matière thermochromique type nubuck, finition délicate en daim',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1302',
                        apiId: 'VT1302',
                        image: '/img/okleiny/112/florence 112/VT1302.jpg',
                        imageLarge: '/img/okleiny/320/florence 320/VT1302.jpg',
                        group: 2,
                        category: [2, 3, 5],
                        title: {
                            pl:
                                'FLORENCE termoodbarwialna okleina introligatorska przypominająca nubuk, z delikatnym, zamszowym wykończeniem',
                            en:
                                'FLORENCE heat sensitive cover material that resembling nubuck, with a delicate suede finish',
                            de:
                                'FLORENCE – farbveränderndes nubukähnliches Einbandmaterial mit feiner Wildlederoptik',
                            fr:
                                'FLORENCE matière thermochromique type nubuck, finition délicate en daim',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1303',
                        apiId: 'VT1303',
                        image: '/img/okleiny/112/florence 112/VT1303.jpg',
                        imageLarge: '/img/okleiny/320/florence 320/VT1303.jpg',
                        group: 2,
                        category: [2, 3, 5],
                        title: {
                            pl:
                                'FLORENCE termoodbarwialna okleina introligatorska przypominająca nubuk, z delikatnym, zamszowym wykończeniem',
                            en:
                                'FLORENCE heat sensitive cover material that resembling nubuck, with a delicate suede finish',
                            de:
                                'FLORENCE – farbveränderndes nubukähnliches Einbandmaterial mit feiner Wildlederoptik',
                            fr:
                                'FLORENCE matière thermochromique type nubuck, finition délicate en daim',
                        },
                        color: 3,
                        theme: 'dark',
                    },
                    {
                        id: 'VF0401',
                        apiId: 'VF0401',
                        image: '/img/okleiny/112/lino 112/VF0401.jpg',
                        imageLarge: '/img/okleiny/320/lino 320/VF0401.jpg',
                        group: 3,
                        category: [4, 6],
                        title: {
                            pl:
                                'LINO COLOR płótno introligatorskie zawierające włókna wiskozy i jedwabiu',
                            en:
                                'LINO COLOUR canvas containing viscose and silk fibers',
                            de:
                                'LINO COLOR – Einbandleinen mit Viskose- und Seidenfasern',
                            fr:
                                'LINO COLOR couverture toile contenant des fibres de viscose et de soie',
                        },
                        color: 3,
                        theme: 'dark',
                    },
                    {
                        id: 'VF0402',
                        apiId: 'VF0402',
                        image: '/img/okleiny/112/lino 112/VF0402.jpg',
                        imageLarge: '/img/okleiny/320/lino 320/VF0402.jpg',
                        group: 3,
                        category: [4, 6],
                        title: {
                            pl:
                                'LINO COLOR płótno introligatorskie zawierające włókna wiskozy i jedwabiu',
                            en:
                                'LINO COLOUR canvas containing viscose and silk fibers',
                            de:
                                'LINO COLOR – Einbandleinen mit Viskose- und Seidenfasern',
                            fr:
                                'LINO COLOR couverture toile contenant des fibres de viscose et de soie',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VF0403',
                        apiId: 'VF0403',
                        image: '/img/okleiny/112/lino 112/VF0403.jpg',
                        imageLarge: '/img/okleiny/320/lino 320/VF0403.jpg',
                        group: 3,
                        category: [4, 6],
                        title: {
                            pl:
                                'LINO COLOR płótno introligatorskie zawierające włókna wiskozy i jedwabiu',
                            en:
                                'LINO COLOUR canvas containing viscose and silk fibers',
                            de:
                                'LINO COLOR – Einbandleinen mit Viskose- und Seidenfasern',
                            fr:
                                'LINO COLOR couverture toile contenant des fibres de viscose et de soie',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    {
                        id: 'VF0404',
                        apiId: 'VF0404',
                        image: '/img/okleiny/112/lino 112/VF0404.jpg',
                        imageLarge: '/img/okleiny/320/lino 320/VF0404.jpg',
                        group: 3,
                        category: [4, 6],
                        title: {
                            pl:
                                'LINO COLOR płótno introligatorskie zawierające włókna wiskozy i jedwabiu',
                            en:
                                'LINO COLOUR canvas containing viscose and silk fibers',
                            de:
                                'LINO COLOR – Einbandleinen mit Viskose- und Seidenfasern',
                            fr:
                                'LINO COLOR couverture toile contenant des fibres de viscose et de soie',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VF0405',
                        apiId: 'VF0405',
                        image: '/img/okleiny/112/lino 112/VF0405.jpg',
                        imageLarge: '/img/okleiny/320/lino 320/VF0405.jpg',
                        group: 3,
                        category: [4, 6],
                        title: {
                            pl:
                                'LINO COLOR płótno introligatorskie zawierające włókna wiskozy i jedwabiu',
                            en:
                                'LINO COLOUR canvas containing viscose and silk fibers',
                            de:
                                'LINO COLOR – Einbandleinen mit Viskose- und Seidenfasern',
                            fr:
                                'LINO COLOR couverture toile contenant des fibres de viscose et de soie',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0501',
                        apiId: 'VN0501',
                        image: '/img/okleiny/112/lino 112/VN0501.jpg',
                        imageLarge: '/img/okleiny/320/lino 320/VN0501.jpg',
                        group: 4,
                        category: [4, 6],
                        title: {
                            pl:
                                'LINO NATURE naturalne płótno zawierające włókna lnu i bawełny',
                            en:
                                'LINO NATURE natural canvas with linen and cotton fibres',
                            de:
                                'LINO NATURE – naturelles Leinen mit Leinen- und Baumwollefasern',
                            fr:
                                'LINO NATURE couverture toile naturel contenant des fibres de lin et de coton',
                        },
                        color: 11,
                        theme: 'dark',
                    },
                    {
                        id: 'VN0502',
                        apiId: 'VN0502',
                        image: '/img/okleiny/112/lino 112/VN0502.jpg',
                        imageLarge: '/img/okleiny/320/lino 320/VN0502.jpg',
                        group: 4,
                        category: [4, 6],
                        title: {
                            pl:
                                'LINO NATURE naturalne płótno zawierające włókna lnu i bawełny',
                            en:
                                'LINO NATURE natural canvas with linen and cotton fibres',
                            de:
                                'LINO NATURE – naturelles Leinen mit Leinen- und Baumwollefasern',
                            fr:
                                'LINO NATURE couverture toile naturel contenant des fibres de lin et de coton',
                        },
                        color: 11,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0701',
                        apiId: 'VP0701',
                        image: '/img/okleiny/112/matryx 112/VP0701.jpg',
                        imageLarge: '/img/okleiny/320/matryx 320/VP0701.jpg',
                        group: 5,
                        category: [5],
                        title: {
                            pl:
                                'MATRYX SCALA okleina introligatorska o strukturze skóry',
                            en:
                                'MATRYX SCALA cover material with leather texture',
                            de:
                                'MATRYX SCALA – Einbandmaterial mit lederähnlichen Oberfläche',
                            fr: 'MATRYX SCALA matière structure en cuir',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    // {
                    //     id: 'VP0801',
                    //     image: '/img/okleiny/112/matryx 112/VP0801.jpg',
                    //     imageLarge: '/img/okleiny/320/matryx 320/VP0801.jpg',
                    //     group: 6,
                    //     category: [5],
                    //     title: {
                    //         pl:
                    //             'MATRYX SCALA okleina introligatorska o strukturze skóry',
                    //         en: 'MATRYX SCALA cover material with leather texture',
                    //         de: 'MATRYX SCALA – Einbandmaterial mit lederähnlichen Oberfläche',
                    //         fr: 'MATRYX SCALA matière structure en cuir',
                    //     },
                    //     color: 1,
                    //     theme: 'dark',
                    // },
                    {
                        id: 'VP1101',
                        apiId: 'VP1101',
                        image: '/img/okleiny/112/matryx 112/VP1101.jpg',
                        imageLarge: '/img/okleiny/320/matryx 320/VP1101.jpg',
                        group: 7,
                        category: [1],
                        title: {
                            pl:
                                'MATRYX SANTOS okleina introligatorska o gładkiej strukturze',
                            en:
                                'MATRYX SANTOS cover material with a smooth texture',
                            de:
                                'MATRYX SANTOS Einbandmaterial mit glatter Oberfläche',
                            fr: 'MATRYX SANTOS matière structure lisse',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1102',
                        apiId: 'VP1102',
                        image: '/img/okleiny/112/matryx 112/VP1102.jpg',
                        imageLarge: '/img/okleiny/320/matryx 320/VP1102.jpg',
                        group: 7,
                        category: [1],
                        title: {
                            pl:
                                'MATRYX SANTOS okleina introligatorska o gładkiej strukturze',
                            en:
                                'MATRYX SANTOS cover material with a smooth texture',
                            de:
                                'MATRYX SANTOS Einbandmaterial mit glatter Oberfläche',
                            fr: 'MATRYX SANTOS matière structure lisse',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1201',
                        apiId: 'VP1201',
                        image: '/img/okleiny/112/milano 112/VP1201.jpg',
                        imageLarge: '/img/okleiny/320/milano 320/VP1201.jpg',
                        group: 8,
                        category: [4, 7],
                        title: {
                            pl:
                                'MILANO, metaliczna okleina introligatorska o strukturze szczotkowanego metalu',
                            en:
                                'MILANO metallic cover material with brushed metal structure',
                            de:
                                'MILANO – Einbandmaterial mit gebürsteter metallic Struktur',
                            fr:
                                'MILANO matière métallique structure en métal brossé',
                        },
                        color: 13,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1202',
                        apiId: 'VP1202',
                        image: '/img/okleiny/112/milano 112/VP1202.jpg',
                        imageLarge: '/img/okleiny/320/milano 320/VP1202.jpg',
                        group: 8,
                        category: [4, 7],
                        title: {
                            pl:
                                'MILANO, metaliczna okleina introligatorska o strukturze szczotkowanego metalu',
                            en:
                                'MILANO metallic cover material with brushed metal structure',
                            de:
                                'MILANO – Einbandmaterial mit gebürsteter metallic Struktur',
                            fr:
                                'MILANO matière métallique structure en métal brossé',
                        },
                        color: 12,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1203',
                        apiId: 'VP1203',
                        image: '/img/okleiny/112/milano 112/VP1203.jpg',
                        imageLarge: '/img/okleiny/320/milano 320/VP1203.jpg',
                        group: 8,
                        category: [4, 7],
                        title: {
                            pl:
                                'MILANO, metaliczna okleina introligatorska o strukturze szczotkowanego metalu',
                            en:
                                'MILANO metallic cover material with brushed metal structure',
                            de:
                                'MILANO – Einbandmaterial mit gebürsteter metallic Struktur',
                            fr:
                                'MILANO matière métallique structure en métal brossé',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1204',
                        apiId: 'VP1204',
                        image: '/img/okleiny/112/milano 112/VP1204.jpg',
                        imageLarge: '/img/okleiny/320/milano 320/VP1204.jpg',
                        group: 8,
                        category: [4, 7],
                        title: {
                            pl:
                                'MILANO, metaliczna okleina introligatorska o strukturze szczotkowanego metalu',
                            en:
                                'MILANO metallic cover material with brushed metal structure',
                            de:
                                'MILANO – Einbandmaterial mit gebürsteter metallic Struktur',
                            fr:
                                'MILANO matière métallique structure en métal brossé',
                        },
                        color: 11,
                        theme: 'light',
                    },
                    {
                        id: 'VP1205',
                        apiId: 'VP1205',
                        image: '/img/okleiny/112/milano 112/VP1205.jpg',
                        imageLarge: '/img/okleiny/320/milano 320/VP1205.jpg',
                        group: 8,
                        category: [4, 7],
                        title: {
                            pl:
                                'MILANO, metaliczna okleina introligatorska o strukturze szczotkowanego metalu',
                            en:
                                'MILANO metallic cover material with brushed metal structure',
                            de:
                                'MILANO – Einbandmaterial mit gebürsteter metallic Struktur',
                            fr:
                                'MILANO matière métallique structure en métal brossé',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VL0201',
                        apiId: 'VL0201',
                        image:
                            '/img/okleiny/112/siena i bologna 112/VL0201.jpg',
                        imageLarge:
                            '/img/okleiny/320/siena i bologna 320/VL0201.jpg',
                        group: 9,
                        category: [4, 5],
                        title: {
                            pl:
                                'SIENA termoodbarwialna okleina introligatorska o strukturze skóry',
                            en:
                                'SIENA heat sensitive cover material with a leather-like texture',
                            de:
                                'SIENA – farbveränderndes Einbandmaterial mit Lederstruktur',
                            fr: 'SIENA matière thermochromique texturée cuir',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    {
                        id: 'VL0203',
                        apiId: 'VL0203',
                        image:
                            '/img/okleiny/112/siena i bologna 112/VL0203.jpg',
                        imageLarge:
                            '/img/okleiny/320/siena i bologna 320/VL0203.jpg',
                        group: 9,
                        category: [4, 5],
                        title: {
                            pl:
                                'SIENA termoodbarwialna okleina introligatorska o strukturze skóry',
                            en:
                                'SIENA heat sensitive cover material with a leather-like texture',
                            de:
                                'SIENA – farbveränderndes Einbandmaterial mit Lederstruktur',
                            fr: 'SIENA matière thermochromique texturée cuir',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: 'VL0301',
                        apiId: 'VL0301',
                        image:
                            '/img/okleiny/112/siena i bologna 112/VL0301.jpg',
                        imageLarge:
                            '/img/okleiny/320/siena i bologna 320/VL0301.jpg',
                        group: 10,
                        category: [4, 5],
                        title: {
                            pl:
                                'BOLOGNA termoodbarwialna okleina introligatorska o gładkiej strukturze',
                            en:
                                'BOLOGNA heat sensitive cover material with a smooth texture',
                            de:
                                'BOLOGNA farbverändernders Einbandmaterial mit glänzender Lederstruktur',
                            fr:
                                'BOLOGNA matière thermochromique structure lisse',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    {
                        id: 'VL0302',
                        apiId: 'VL0302',
                        image:
                            '/img/okleiny/112/siena i bologna 112/VL0302.jpg',
                        imageLarge:
                            '/img/okleiny/320/siena i bologna 320/VL0302.jpg',
                        group: 10,
                        category: [4, 5],
                        title: {
                            pl:
                                'BOLOGNA termoodbarwialna okleina introligatorska o gładkiej strukturze',
                            en:
                                'BOLOGNA heat sensitive cover material with a smooth texture',
                            de:
                                'BOLOGNA farbverändernders Einbandmaterial mit glänzender Lederstruktur',
                            fr:
                                'BOLOGNA matière thermochromique structure lisse',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VL0303',
                        apiId: 'VL0303',
                        image:
                            '/img/okleiny/112/siena i bologna 112/VL0303.jpg',
                        imageLarge:
                            '/img/okleiny/320/siena i bologna 320/VL0303.jpg',
                        group: 10,
                        category: [4, 5],
                        title: {
                            pl:
                                'BOLOGNA termoodbarwialna okleina introligatorska o gładkiej strukturze',
                            en:
                                'BOLOGNA heat sensitive cover material with a smooth texture',
                            de:
                                'BOLOGNA farbverändernders Einbandmaterial mit glänzender Lederstruktur',
                            fr:
                                'BOLOGNA matière thermochromique structure lisse',
                        },
                        color: 3,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0101',
                        apiId: 'VT0101',
                        image: '/img/okleiny/112/torino 112/VT0101.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0101.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0102',
                        apiId: 'VT0102',
                        image: '/img/okleiny/112/torino 112/VT0102.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0102.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: 'VT0103',
                        apiId: 'VT0103',
                        image: '/img/okleiny/112/torino 112/VT0103.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0103.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 3,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0104',
                        apiId: 'VT0104',
                        image: '/img/okleiny/112/torino 112/VT0104.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0104.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0105',
                        apiId: 'VT0105',
                        image: '/img/okleiny/112/torino 112/VT0105.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0105.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0106',
                        apiId: 'VT0106',
                        image: '/img/okleiny/112/torino 112/VT0106.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0106.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0107',
                        apiId: 'VT0107',
                        image: '/img/okleiny/112/torino 112/VT0107.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0107.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 6,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0108',
                        apiId: 'VT0108',
                        image: '/img/okleiny/112/torino 112/VT0108.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0108.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 6,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0109',
                        apiId: 'VT0109',
                        image: '/img/okleiny/112/torino 112/VT0109.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0109.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 6,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0110',
                        apiId: 'VT0110',
                        image: '/img/okleiny/112/torino 112/VT0110.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0110.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 7,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0111',
                        apiId: 'VT0111',
                        image: '/img/okleiny/112/torino 112/VT0111.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0111.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 8,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0112',
                        apiId: 'VT0112',
                        image: '/img/okleiny/112/torino 112/VT0112.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0112.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 8,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0113',
                        apiId: 'VT0113',
                        image: '/img/okleiny/112/torino 112/VT0113.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0113.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0114',
                        apiId: 'VT0114',
                        image: '/img/okleiny/112/torino 112/VT0114.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0114.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0115',
                        apiId: 'VT0115',
                        image: '/img/okleiny/112/torino 112/VT0115.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0115.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0116',
                        apiId: 'VT0116',
                        image: '/img/okleiny/112/torino 112/VT0116.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0116.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 9,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0117',
                        apiId: 'VT0117',
                        image: '/img/okleiny/112/torino 112/VT0117.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0117.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 9,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0118',
                        apiId: 'VT0118',
                        image: '/img/okleiny/112/torino 112/VT0118.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0118.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 10,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0119',
                        apiId: 'VT0119',
                        image: '/img/okleiny/112/torino 112/VT0119.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0119.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 10,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0120',
                        apiId: 'VT0120',
                        image: '/img/okleiny/112/torino 112/VT0120.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0120.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 11,
                        theme: 'dark',
                    },
                    {
                        id: 'VT0121',
                        apiId: 'VT0121',
                        image: '/img/okleiny/112/torino 112/VT0121.jpg',
                        imageLarge: '/img/okleiny/320/torino 320/VT0121.jpg',
                        group: 11,
                        category: [1, 2, 3],
                        title: {
                            pl:
                                'TORINO termoodbarwialna i doskonała w tłoczeniu okleina introligatorska',
                            en:
                                'TORINO perfect debossing and heat sensitive cover material',
                            de:
                                'TORINO farbveränderndes und für Prägungen prädestiniertes Einbandmaterial',
                            fr:
                                'TORINO matière thermochromique, parfait pour l’embossage ',
                        },
                        color: 11,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1201',
                        apiId: 'VT1201',
                        image: '/img/okleiny/112/verona 112/VT1201.jpg',
                        imageLarge: '/img/okleiny/320/verona 320/VT1201.jpg',
                        group: 12,
                        category: [2, 3],
                        title: {
                            pl:
                                'VERONA aksamitna okleina introligatorska z delikatnym brokatem',
                            en:
                                'VERONA extremely mellow, cover material, with delicate glitter',
                            de:
                                'VERONA angenehm samt-weiches Material mit dezentem Metallic Effekt',
                            fr:
                                'VERONA matière agréablement douce et veloutée avec un subtil effet métallique',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1202',
                        apiId: 'VT1202',
                        image: '/img/okleiny/112/verona 112/VT1202.jpg',
                        imageLarge: '/img/okleiny/320/verona 320/VT1202.jpg',
                        group: 12,
                        category: [2, 3],
                        title: {
                            pl:
                                'VERONA aksamitna okleina introligatorska z delikatnym brokatem',
                            en:
                                'VERONA extremely mellow, cover material, with delicate glitter',
                            de:
                                'VERONA angenehm samt-weiches Material mit dezentem Metallic Effekt',
                            fr:
                                'VERONA matière agréablement douce et veloutée avec un subtil effet métallique',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1203',
                        apiId: 'VT1203',
                        image: '/img/okleiny/112/verona 112/VT1203.jpg',
                        imageLarge: '/img/okleiny/320/verona 320/VT1203.jpg',
                        group: 12,
                        category: [2, 3],
                        title: {
                            pl:
                                'VERONA aksamitna okleina introligatorska z delikatnym brokatem',
                            en:
                                'VERONA extremely mellow, cover material, with delicate glitter',
                            de:
                                'VERONA angenehm samt-weiches Material mit dezentem Metallic Effekt',
                            fr:
                                'VERONA matière agréablement douce et veloutée avec un subtil effet métallique',
                        },
                        color: 11,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1204',
                        apiId: 'VT1204',
                        image: '/img/okleiny/112/verona 112/VT1204.jpg',
                        imageLarge: '/img/okleiny/320/verona 320/VT1204.jpg',
                        group: 12,
                        category: [2, 3],
                        title: {
                            pl:
                                'VERONA aksamitna okleina introligatorska z delikatnym brokatem',
                            en:
                                'VERONA extremely mellow, cover material, with delicate glitter',
                            de:
                                'VERONA angenehm samt-weiches Material mit dezentem Metallic Effekt',
                            fr:
                                'VERONA matière agréablement douce et veloutée avec un subtil effet métallique',
                        },
                        color: 3,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1205',
                        apiId: 'VT1205',
                        image: '/img/okleiny/112/verona 112/VT1205.jpg',
                        imageLarge: '/img/okleiny/320/verona 320/VT1205.jpg',
                        group: 12,
                        category: [2, 3],
                        title: {
                            pl:
                                'VERONA aksamitna okleina introligatorska z delikatnym brokatem',
                            en:
                                'VERONA extremely mellow, cover material, with delicate glitter',
                            de:
                                'VERONA angenehm samt-weiches Material mit dezentem Metallic Effekt',
                            fr:
                                'VERONA matière agréablement douce et veloutée avec un subtil effet métallique',
                        },
                        color: 13,
                        theme: 'light',
                    },
                    {
                        id: 'VT1206',
                        apiId: 'VT1206',
                        image: '/img/okleiny/112/verona 112/VT1206.jpg',
                        imageLarge: '/img/okleiny/320/verona 320/VT1206.jpg',
                        group: 12,
                        category: [2, 3],
                        title: {
                            pl:
                                'VERONA aksamitna okleina introligatorska z delikatnym brokatem',
                            en:
                                'VERONA extremely mellow, cover material, with delicate glitter',
                            de:
                                'VERONA angenehm samt-weiches Material mit dezentem Metallic Effekt',
                            fr:
                                'VERONA matière agréablement douce et veloutée avec un subtil effet métallique',
                        },
                        color: 12,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1401',
                        apiId: 'VP1401',
                        image: '/img/okleiny/112/palermo 112/VP1401.jpg',
                        imageLarge: '/img/okleiny/320/palermo 320/VP1401.jpg',
                        group: 14,
                        category: [4],
                        title: {
                            pl:
                                'PALERMO nowoczesna, niezwykle trawała okleina introligatorska z efektem 3D',
                            en:
                                'PALERMO modern, extremely durable cover material with a 3D effect',
                            de:
                                'PALERMO modernes, extrem strapazierfähiges Bezugsmaterial mit 3D-Effekt ',
                            fr:
                                'PALERMO matière moderne, durable avec effet 3D  ',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1402',
                        apiId: 'VP1402',
                        image: '/img/okleiny/112/palermo 112/VP1402.jpg',
                        imageLarge: '/img/okleiny/320/palermo 320/VP1402.jpg',
                        group: 14,
                        category: [4],
                        title: {
                            pl:
                                'PALERMO nowoczesna, niezwykle trawała okleina introligatorska z efektem 3D',
                            en:
                                'PALERMO modern, extremely durable cover material with a 3D effect',
                            de:
                                'PALERMO modernes, extrem strapazierfähiges Bezugsmaterial mit 3D-Effekt ',
                            fr:
                                'PALERMO matière moderne, durable avec effet 3D  ',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1403',
                        apiId: 'VP1403',
                        image: '/img/okleiny/112/palermo 112/VP1403.jpg',
                        imageLarge: '/img/okleiny/320/palermo 320/VP1403.jpg',
                        group: 14,
                        category: [4],
                        title: {
                            pl:
                                'PALERMO nowoczesna, niezwykle trawała okleina introligatorska z efektem 3D',
                            en:
                                'PALERMO modern, extremely durable cover material with a 3D effect',
                            de:
                                'PALERMO modernes, extrem strapazierfähiges Bezugsmaterial mit 3D-Effekt ',
                            fr:
                                'PALERMO matière moderne, durable avec effet 3D  ',
                        },
                        color: 3,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1404',
                        apiId: 'VP1404',
                        image: '/img/okleiny/112/palermo 112/VP1404.jpg',
                        imageLarge: '/img/okleiny/320/palermo 320/VP1404.jpg',
                        group: 14,
                        category: [4],
                        title: {
                            pl:
                                'PALERMO nowoczesna, niezwykle trawała okleina introligatorska z efektem 3D',
                            en:
                                'PALERMO modern, extremely durable cover material with a 3D effect',
                            de:
                                'PALERMO modernes, extrem strapazierfähiges Bezugsmaterial mit 3D-Effekt ',
                            fr:
                                'PALERMO matière moderne, durable avec effet 3D  ',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1405',
                        apiId: 'VP1405',
                        image: '/img/okleiny/112/palermo 112/VP1405.jpg',
                        imageLarge: '/img/okleiny/320/palermo 320/VP1405.jpg',
                        group: 14,
                        category: [4, 7],
                        title: {
                            pl:
                                'PALERMO nowoczesna, niezwykle trawała okleina introligatorska z efektem 3D',
                            en:
                                'PALERMO modern, extremely durable cover material with a 3D effect',
                            de:
                                'PALERMO modernes, extrem strapazierfähiges Bezugsmaterial mit 3D-Effekt ',
                            fr:
                                'PALERMO matière moderne, durable avec effet 3D  ',
                        },
                        color: 13,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1406',
                        apiId: 'VP1406',
                        image: '/img/okleiny/112/palermo 112/VP1406.jpg',
                        imageLarge: '/img/okleiny/320/palermo 320/VP1406.jpg',
                        group: 14,
                        category: [4, 7],
                        title: {
                            pl:
                                'PALERMO nowoczesna, niezwykle trawała okleina introligatorska z efektem 3D',
                            en:
                                'PALERMO modern, extremely durable cover material with a 3D effect',
                            de:
                                'PALERMO modernes, extrem strapazierfähiges Bezugsmaterial mit 3D-Effekt ',
                            fr:
                                'PALERMO matière moderne, durable avec effet 3D  ',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VP1407',
                        apiId: 'VP1407',
                        image: '/img/okleiny/112/palermo 112/VP1407.jpg',
                        imageLarge: '/img/okleiny/320/palermo 320/VP1407.jpg',
                        group: 14,
                        category: [4],
                        title: {
                            pl:
                                'PALERMO nowoczesna, niezwykle trawała okleina introligatorska z efektem 3D',
                            en:
                                'PALERMO modern, extremely durable cover material with a 3D effect',
                            de:
                                'PALERMO modernes, extrem strapazierfähiges Bezugsmaterial mit 3D-Effekt ',
                            fr:
                                'PALERMO matière moderne, durable avec effet 3D  ',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: 'VP0901',
                        apiId: 'VP0901',
                        image: '/img/okleiny/112/roma 112/VP0901.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0901.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: 'VP0902',
                        apiId: 'VP0902',
                        image: '/img/okleiny/112/roma 112/VP0902.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0902.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 10,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0903',
                        apiId: 'VP0903',
                        image: '/img/okleiny/112/roma 112/VP0903.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0903.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 10,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0904',
                        apiId: 'VP0904',
                        image: '/img/okleiny/112/roma 112/VP0904.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0904.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 7,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0905',
                        apiId: 'VP0905',
                        image: '/img/okleiny/112/roma 112/VP0905.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0905.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 3,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0906',
                        apiId: 'VP0906',
                        image: '/img/okleiny/112/roma 112/VP0906.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0906.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 6,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0907',
                        apiId: 'VP0907',
                        image: '/img/okleiny/112/roma 112/VP0907.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0907.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 6,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0908',
                        apiId: 'VP0908',
                        image: '/img/okleiny/112/roma 112/VP0908.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0908.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 6,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0909',
                        apiId: 'VP0909',
                        image: '/img/okleiny/112/roma 112/VP0909.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0909.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0910',
                        apiId: 'VP0910',
                        image: '/img/okleiny/112/roma 112/VP0910.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0910.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0911',
                        apiId: 'VP0911',
                        image: '/img/okleiny/112/roma 112/VP0911.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0911.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0912',
                        apiId: 'VP0912',
                        image: '/img/okleiny/112/roma 112/VP0912.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0912.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0913',
                        apiId: 'VP0913',
                        image: '/img/okleiny/112/roma 112/VP0913.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0913.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VP0914',
                        apiId: 'VP0914',
                        image: '/img/okleiny/112/roma 112/VP0914.jpg',
                        imageLarge: '/img/okleiny/320/roma 320/VP0914.jpg',
                        group: 20,
                        category: [1],
                        title: {
                            pl:
                                'ROMA gładka, matowa, odporna  na zabrudzenia oklieina introligatorska',
                            en:
                                'ROMA smooth, matte and dirt-resistant cover material',
                            de:
                                'ROMA glattes, mattes und schmutzabweisendes Bezugsmaterial',
                            fr:
                                'ROMA matière lisse, matte résistant aux rayures',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                    // MNT-201
                    {
                        id: 'VT1401',
                        apiId: 'VT1401',
                        image: '/img/_updates/MNT-201/VT1401--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/VT1401--large.jpg',
                        group: 21,
                        category: [1, 2, 5],
                        title: {
                            pl:
                                'NEWAPPLE - termoodbarwialna, gładka, miękka w dotyku okleina, wykonana z przemysłowych odpadów przetwórstwa jabłek',
                            en:
                                'NEWAPPLE - heat sensitive, smooth and soft cover material made of organic waste from apple processing',
                            de:
                                'NEWAPPLE - tiefprägend, soft und farbverändernd Einband aus biodynamischen Abfällen der Apfelverarbeitung',
                            fr:
                                'NEWAPPLE - matière de couverture sensible à la chaleur, lisse et doux fait de déchets organique issus de la transformation des pommes',
                        },
                        color: 6,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1402',
                        apiId: 'VT1402',
                        image: '/img/_updates/MNT-201/VT1402--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/VT1402--large.jpg',
                        group: 21,
                        category: [1, 2, 5],
                        title: {
                            pl:
                                'NEWAPPLE - termoodbarwialna, gładka, miękka w dotyku okleina, wykonana z przemysłowych odpadów przetwórstwa jabłek',
                            en:
                                'NEWAPPLE - heat sensitive, smooth and soft cover material made of organic waste from apple processing',
                            de:
                                'NEWAPPLE - tiefprägend, soft und farbverändernd Einband aus biodynamischen Abfällen der Apfelverarbeitung',
                            fr:
                                'NEWAPPLE - matière de couverture sensible à la chaleur, lisse et doux fait de déchets organique issus de la transformation des pommes',
                        },
                        color: 8,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1403',
                        apiId: 'VT1403',
                        image: '/img/_updates/MNT-201/VT1403--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/VT1403--large.jpg',
                        group: 21,
                        category: [1, 2, 5],
                        title: {
                            pl:
                                'NEWAPPLE - termoodbarwialna, gładka, miękka w dotyku okleina, wykonana z przemysłowych odpadów przetwórstwa jabłek',
                            en:
                                'NEWAPPLE - heat sensitive, smooth and soft cover material made of organic waste from apple processing',
                            de:
                                'NEWAPPLE - tiefprägend, soft und farbverändernd Einband aus biodynamischen Abfällen der Apfelverarbeitung',
                            fr:
                                'NEWAPPLE - matière de couverture sensible à la chaleur, lisse et doux fait de déchets organique issus de la transformation des pommes',
                        },
                        color: 3,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1404',
                        apiId: 'VT1404',
                        image: '/img/_updates/MNT-201/VT1404--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/VT1404--large.jpg',
                        group: 21,
                        category: [1, 2, 5],
                        title: {
                            pl:
                                'NEWAPPLE - termoodbarwialna, gładka, miękka w dotyku okleina, wykonana z przemysłowych odpadów przetwórstwa jabłek',
                            en:
                                'NEWAPPLE - heat sensitive, smooth and soft cover material made of organic waste from apple processing',
                            de:
                                'NEWAPPLE - tiefprägend, soft und farbverändernd Einband aus biodynamischen Abfällen der Apfelverarbeitung',
                            fr:
                                'NEWAPPLE - matière de couverture sensible à la chaleur, lisse et doux fait de déchets organique issus de la transformation des pommes',
                        },
                        color: 4,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1405',
                        apiId: 'VT1405',
                        image: '/img/_updates/MNT-201/VT1405--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/VT1405--large.jpg',
                        group: 21,
                        category: [1, 2, 5],
                        title: {
                            pl:
                                'NEWAPPLE - termoodbarwialna, gładka, miękka w dotyku okleina, wykonana z przemysłowych odpadów przetwórstwa jabłek',
                            en:
                                'NEWAPPLE - heat sensitive, smooth and soft cover material made of organic waste from apple processing',
                            de:
                                'NEWAPPLE - tiefprägend, soft und farbverändernd Einband aus biodynamischen Abfällen der Apfelverarbeitung',
                            fr:
                                'NEWAPPLE - matière de couverture sensible à la chaleur, lisse et doux fait de déchets organique issus de la transformation des pommes',
                        },
                        color: 5,
                        theme: 'dark',
                    },
                    {
                        id: 'VT1406',
                        apiId: 'VT1406',
                        image: '/img/_updates/MNT-201/VT1406--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/VT1406--large.jpg',
                        group: 21,
                        category: [1, 2, 5],
                        title: {
                            pl:
                                'NEWAPPLE - termoodbarwialna, gładka, miękka w dotyku okleina, wykonana z przemysłowych odpadów przetwórstwa jabłek',
                            en:
                                'NEWAPPLE - heat sensitive, smooth and soft cover material made of organic waste from apple processing',
                            de:
                                'NEWAPPLE - tiefprägend, soft und farbverändernd Einband aus biodynamischen Abfällen der Apfelverarbeitung',
                            fr:
                                'NEWAPPLE - matière de couverture sensible à la chaleur, lisse et doux fait de déchets organique issus de la transformation des pommes',
                        },
                        color: 1,
                        theme: 'dark',
                    },
                ].filter((option) => filterOptionsFn(option, OKLEINA)),
                papier: [
                    {
                        id: '120PUK',
                        apiId: '120PUK',
                        image: '/img/papiery/112/120PUK.jpg',
                        imageLarge: '/img/papiery/320/120PUK.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier niepowlekany z recyklingu kraft 120g/m2',
                            en: 'uncoated recycled kraft paper 120g/m2',
                            de:
                                'recyceltes unbeschichtetes Kraftpapier 120 g/m2',
                            fr: 'papier kraft non couché recyclé 120 g/m²',
                        },
                        color: 2,
                        theme: 'dark',
                    },
                    {
                        id: '160PURW',
                        apiId: '160PURW',
                        image: '/img/papiery/112/160PURW.jpg',
                        imageLarge: '/img/papiery/320/160PURW.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl: 'papier offsetowy z recyklingu 160g/m2',
                            en: 'recycled offset paper 160g/m2',
                            de: 'recyceltes Offset Papier 160 g/m2',
                            fr: 'papier offset recyclé 160 g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '300PURW',
                        apiId: '300PURW',
                        // image: '/img/papiery/112/300PURW.jpg',
                        // imageLarge: '/img/papiery/320/300PURW.jpg',
                        image: '/img/_updates/MNT-201/135PUA--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/135PUA--large.jpg',

                        group: 13,
                        category: [],
                        title: {
                            pl: 'papier offsetowy z recyklingu 300g/m2',
                            en: 'recycled offset paper 300g/m2',
                            de: 'recyceltes Offset Papier 300g/m2',
                            fr: 'papier offset recyclé 300 g/m2 ',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '150PCW',
                        apiId: '150PCW',
                        image: '/img/papiery/112/150PCW.jpg',
                        imageLarge: '/img/papiery/320/150PCW.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl: 'papier kredowy 150g/m2',
                            en: 'coated paper 150g/m2',
                            de: 'gestrichenes Papier 150 g/m2',
                            fr: 'papier craie 150 g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '250PSBS',
                        apiId: '250PSBS',
                        image: '/img/papiery/112/250PSBS.jpg',
                        imageLarge: '/img/papiery/320/250PSBS.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl: 'karton dwustronnie powlekany 250g/m2',
                            en: 'double-coated cardboard 250g/m2',
                            de: 'beidseitig gestrichener Karton 250 g/m2',
                            fr: 'carton couché recto verso 250 g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '300PK',
                        apiId: '300PK',
                        image: '/img/papiery/112/300PK.jpg',
                        imageLarge: '/img/papiery/320/300PK.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'karton niepowlekany z recyklingu kraft 300g/m2',
                            en: 'uncoated recycled kraft cardboard 300g/m2',
                            de: 'recycelter ungestrichener Kraftkarton 300g/m2',
                            fr: 'carton kraft non couché recyclé 300 g/m2',
                        },
                        color: 2,
                        theme: 'dark',
                    },
                    {
                        id: '140PB',
                        apiId: '140PB',
                        image: '/img/papiery/112/112black.jpg',
                        imageLarge: '/img/papiery/320/black.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'karton niepowlekany barwiony w masie czarny 140g/m2',
                            en: 'cardboard uncoated black 140g/m2',
                            de: 'schwarzer ungestrichener Karton 140 g/m2',
                            fr:
                                'carton non couché, teint masse, noir 140 g/m2 ',
                        },
                        color: 2,
                        theme: 'dark',
                    },
                    {
                        id: '350PB',
                        apiId: '350PB',
                        image: '/img/papiery/112/112black.jpg',
                        imageLarge: '/img/papiery/320/black.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'karton niepowlekany barwiony w masie czarny 350g/m2',
                            en: 'uncoated dyed black cardboard 350g/sqm',
                            de: 'schwarzer ungestrichener Karton 350 g/m2',
                            fr:
                                'carton non couché, teint masse, noir 350 g/m2 ',
                        },
                        color: 2,
                        theme: 'dark',
                    },
                    {
                        id: '240PF',
                        apiId: '240PF',
                        image: '/img/papiery/112/160PURW.jpg',
                        imageLarge: '/img/papiery/320/160PURW.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl: 'karton niepowlekany z recyklingu 240g/m2',
                            en: 'uncoated recycled cardboard 240g/m2',
                            de: 'recycelter ungestrichener Karton 240 g/m2',
                            fr: 'carton recyclé non couché 240g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '300PB',
                        apiId: '300PB',
                        image: '/img/papiery/112/112black.jpg',
                        imageLarge: '/img/papiery/320/black.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'karton niepowlekany barwiony w masie czarny 350g/m2',
                            en: 'cardboard uncoated black 350g/m2',
                            de: 'schwarzer ungestrichener Karton 350 g/m2',
                            fr: 'carton non couché, teint masse, noir 350g/m2',
                        },
                        color: 2,
                        theme: 'dark',
                    },
                    // MNT-201
                    {
                        id: '120pg',
                        apiId: '120pg',
                        image: '/img/_updates/MNT-201/120PG--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/120PG--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier z recyklingu zawierający włókna trawy 120g/m2',
                            en:
                                'recycled paper containing grass fibers 120g/sqm',
                            de: 'Recyclingpapier mit Grasfasern 120g/m2',
                            fr:
                                "papier  recyclé contenant des fibres d'herbe 120g/m2",
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '120pg-fsc',
                        apiId: '120pg-fsc',
                        image: '/img/_updates/MNT-201/120PG--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/120PG--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier z recyklingu zawierający włókna trawy 120g/m2',
                            en:
                                'recycled paper containing grass fibers 120g/sqm',
                            de: 'Recyclingpapier mit Grasfasern 120g/m2',
                            fr:
                                "papier  recyclé contenant des fibres d'herbe 120g/m2",
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '350pg',
                        apiId: '350pg',
                        image: '/img/_updates/MNT-201/120PG--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/120PG--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier z recyklingu zawierający włókna trawy 350g/m2',
                            en:
                                'recycled paper containing grass fibers 350g/sqm',
                            de: 'Recyclingpapier mit Grasfasern 350g/m2',
                            fr:
                                "papier  recyclé contenant des fibres d'herbe 350g/m2",
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '120pc',
                        apiId: '120pc',
                        image: '/img/_updates/MNT-201/120PC--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/120PC--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier z recyklingu zawierający odpady z kawy 120g/m2',
                            en:
                                'recycled paper containing coffee waste 120g/sqm',
                            de: 'Recyclingpapier mit Kaffeeabfällen 120g/m2',
                            fr:
                                'papier  recyclé contenant des déchets de café 120g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '120pc-fsc',
                        apiId: '120pc-fsc',
                        image: '/img/_updates/MNT-201/120PC--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/120PC--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier z recyklingu zawierający odpady z kawy 120g/m2',
                            en:
                                'recycled paper containing coffee waste 120g/sqm',
                            de: 'Recyclingpapier mit Kaffeeabfällen 120g/m2',
                            fr:
                                'papier  recyclé contenant des déchets de café 120g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '250pc',
                        apiId: '250pc',
                        image: '/img/_updates/MNT-201/120PC--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/120PC--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier z recyklingu zawierający odpady z kawy 250g/m2',
                            en:
                                'recycled paper containing coffee waste 250g/sqm',
                            de: 'Recyclingpapier mit Kaffeeabfällen 250g/m2',
                            fr:
                                'papier  recyclé contenant des déchets de café 250g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '250pc-fsc',
                        apiId: '250pc-fsc',
                        image: '/img/_updates/MNT-201/120PC--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/120PC--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier z recyklingu zawierający odpady z kawy 250g/m2',
                            en:
                                'recycled paper containing coffee waste 250g/sqm',
                            de: 'Recyclingpapier mit Kaffeeabfällen 250g/m2',
                            fr:
                                'papier  recyclé contenant des déchets de café 250g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '135pua',
                        apiId: '135pua',
                        image: '/img/_updates/MNT-201/135PUA--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/135PUA--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier offsetowy zawierający naturalne odpady przetwórstwa jabłek 135g/m2',
                            en:
                                'offset paper containing organic waste from apple processing 135g/sqm',
                            de:
                                'Offsetpapier mit Biodynamischen abfällen der Apfelverarbeitung 135g/m2',
                            fr:
                                'papel offset hecho de residuos orgánico del procesamiento de manzanas 135g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '135pua-fsc',
                        apiId: '135pua-fsc',
                        image: '/img/_updates/MNT-201/135PUA--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/135PUA--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier offsetowy zawierający naturalne odpady przetwórstwa jabłek 135g/m2',
                            en:
                                'offset paper containing organic waste from apple processing 135g/sqm',
                            de:
                                'Offsetpapier mit Biodynamischen abfällen der Apfelverarbeitung 135g/m2',
                            fr:
                                'papel offset hecho de residuos orgánico del procesamiento de manzanas 135g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '320pua',
                        apiId: '320pua',
                        image: '/img/_updates/MNT-201/135PUA--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/135PUA--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier offsetowy zawierający naturalne odpady przetwórstwa jabłek 320g/m2',
                            en:
                                'offset paper containing organic waste from apple processing 320g/sqm',
                            de:
                                'Offsetpapier mit Biodynamischen abfällen der Apfelverarbeitung 320g/m2',
                            fr:
                                'papel offset hecho de residuos orgánico del procesamiento de manzanas 320g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                    {
                        id: '320pua-fsc',
                        apiId: '320pua-fsc',
                        image: '/img/_updates/MNT-201/135PUA--mini.jpg',
                        imageLarge: '/img/_updates/MNT-201/135PUA--large.jpg',
                        group: 13,
                        category: [],
                        title: {
                            pl:
                                'papier offsetowy zawierający naturalne odpady przetwórstwa jabłek 320g/m2',
                            en:
                                'offset paper containing organic waste from apple processing 320g/sqm',
                            de:
                                'Offsetpapier mit Biodynamischen abfällen der Apfelverarbeitung 320g/m2',
                            fr:
                                'papel offset hecho de residuos orgánico del procesamiento de manzanas 320g/m2',
                        },
                        color: 2,
                        theme: 'light',
                    },
                ].filter((option) => filterOptionsFn(option, OKLEINA)),
            }
        },
    },
}
</script>
