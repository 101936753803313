<template>
    <div class="page-sent">
        <div class="page-sent-box">
            <div class="page-sent-box-description">
                <h1 v-bastards class="page-sent-box-description-title">
                    {{ $t('sent.title') }}
                </h1>
                <div v-bastards class="page-sent-box-description-text">
                    {{ $t('sent.desc') }}
                </div>
                <button
                    class="page-sent-button"
                    @click="startNewNotes"
                    v-text="$t('sent.button')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
    methods: {
        ...mapMutations(['clearConfiguration']),
        ...mapActions(['changePage']),
        startNewNotes() {
            this.clearConfiguration()
            this.changePage('material')
        },
    },
}
</script>

<style lang="scss">
.page-sent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &-box {
        @include size(857px, auto);
        box-shadow: 0 0 45px rgba(#000, 0.05);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 50px 70px;

        &-description {
            text-align: center;
            max-width: 600px;
            margin: 0 auto;
            &-title {
                margin-top: 0;
                font-size: 4.2rem;
                font-weight: bold;
            }
            &-text {
                font-size: 3rem;
            }
        }
    }

    &-button {
        cursor: pointer;
        background-color: $color-burnt-sienna;
        border: 0;
        padding: 10px 20px 10px 20px;
        color: $color-white;
        font-size: 1.8rem;
        font-weight: 400;
        position: relative;
        margin-top: 30px;
    }

    @media (max-width: $screen-tablet-medium) {
        height: 100vh;
        &-box {
            width: 90%;
            padding: 25px 35px;

            &-description {
                &-title {
                    font-size: 3rem;
                }
                &-text {
                    font-size: 2.2rem;
                }
            }
        }
    }
}
</style>
