<template>
    <!-- eslint-disable -->
    <g v-if="personalizedPrint && currentPage.id === 'personalized'">
        <defs v-if="format === 'square'">
            <clipPath id="logo-clip-path">
                <path
                    id="Path_9658"
                    data-name="Path 9658"
                    d="M49.642,193.791a169.948,169.948,0,0,0,8.812-24.133,293.751,293.751,0,0,0,6.612-30.086s-10.153,3-28.462,1.105a155.165,155.165,0,0,1-17.146-2.811,147.26,147.26,0,0,1-16.339-4.5s-9.252-6.5-3.973-24.907l8.6-29.985L34.361-10.388,277.8,78.722,227.9,252.745,92.065,213.794,49.106,193.173Z"
                    transform="translate(2469.77 4761.2) rotate(-16)"
                    fill="#e00"
                />
            </clipPath>
        </defs>
        <defs v-if="personalizedPrint === 'one'">
            <clipPath id="vertical-clip-path">
                <rect
                    id="Rectangle_1603"
                    data-name="Rectangle 1603"
                    class="notes-pages-1"
                    width="308"
                    height="451"
                    transform="translate(319.994 5.096)"
                />
            </clipPath>
            <clipPath id="horizontal-clip-path-2">
                <rect
                    id="Rectangle_1603"
                    data-name="Rectangle 1603"
                    width="305"
                    height="186"
                    transform="translate(0 265.375)"
                />
            </clipPath>

            <clipPath id="square-clip-path-2">
                <rect
                    id="Rectangle_1618"
                    data-name="Rectangle 1618"
                    class="square-pages-one-2"
                    width="254"
                    height="263"
                    transform="translate(319.994 45.095)"
                />
            </clipPath>
        </defs>
        <defs>
            <clipPath id="logo-horizontal-clip-path">
                <path
                    id="Path_9662"
                    data-name="Path 9662"
                    d="M44.477,138.489a169.59,169.59,0,0,0,8.927-24.38A293.1,293.1,0,0,0,60.061,83.7s-10.371,3-29.1,1.019c-2.079-.22-4.119-.484-6.385-.821C8.972,81.578,2.1,78.967,2.1,78.967a10.44,10.44,0,0,1-7.163-10.2C-5.212,60.47-1.71,50.021-1.71,50.021L13.15-1.8,325.562,86.468,288.241,213,54.972,146.253Z"
                    transform="translate(612.555 246.804) rotate(-16)"
                    fill="#f1f1f1"
                />
            </clipPath>
        </defs>
        <!-- ONE color -->
        <!-- horizontal -->
        <g
            v-if="personalizedPrint === 'one' && format === 'horizontal'"
            transform="translate(0 -263)"
        >
            <rect
                v-if="!isFolded"
                :style="`fill:${paperColor};`"
                width="311"
                height="184"
                transform="translate(0 267)"
            />
            <path
                v-else
                id="Path_8167"
                data-name="Path 8167"
                d="M361.281,75.1H56.382l-.2,110.886s-.071,2.713.2,6.664c.42,6.178,1.878,15.614,6.566,17.8,5.965,4.063,28.882,20.379,35.277,25.342,2.951,2.29,20.424,15.83,23.535,17.92,13.773,9.751,25.209,7.567,25.209,7.567H361.281Z"
                transform="translate(-50 191)"
                :fill="paperColor"
            />
            <g
                id="Group_1226"
                data-name="Group 1226"
                class="horizontal-pages-one-14"
                transform="translate(5 0)"
            >
                <text
                    id="Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at._Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit._Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at"
                    data-name="Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at"
                    class="horizontal-pages-one-15"
                    transform="translate(29 332.375)"
                >
                    <tspan x="0" y="6">
                        Nunc in ipsum tortor. Suspendisse ultricies magna magna,
                        ac vehicula urna ullamcorper at.
                    </tspan>
                    <tspan x="0" y="14">
                        Suspendisse potenti. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Nunc in ipsum tortor.
                    </tspan>
                    <tspan x="0" y="22">
                        Suspendisse ultricies magna magna, ac vehicula urna
                        ullamcorper at. Suspendisse potenti.
                    </tspan>
                </text>
                <text
                    id="YOUR_LOGO"
                    data-name="YOUR LOGO"
                    class="horizontal-pages-one-16"
                    transform="translate(153 297.375)"
                >
                    <tspan x="-56.089" y="0">YOUR LOGO</tspan>
                </text>
                <text
                    id="advertisement"
                    class="horizontal-pages-one-17"
                    transform="translate(149 319.375)"
                >
                    <tspan x="-78.801" y="0">ADVERTISEMENT</tspan>
                </text>
                <g
                    v-if="isFolded"
                    id="Mask_Group_51"
                    data-name="Mask Group 51"
                    transform="translate(-624 82)"
                    clip-path="url(#logo-horizontal-clip-path)"
                >
                    <path
                        id="Path_9661"
                        data-name="Path 9661"
                        d="M62.722,60.21C59.467,84.2,33.5,84.354,27.041,84.391l-4.307.025.415-69.436C23.2,6.434,28,4.389,33.144,4.359l.011-1.78L.011,2.77,0,4.55c5.145-.03,8.477,3.037,8.434,10.277L8.094,71.563C8.046,79.635,5.624,84.515.479,84.545l-.011,1.78,63.775-.368L64.4,60.2Zm76.909-15.873c-.2,32.641-8.857,40.524-22.976,40.606-14.239.082-22.926-7.7-22.731-40.342.195-32.522,8.984-41.712,23.223-41.794C131.265,2.725,139.826,11.815,139.631,44.337Zm17.35-.1c.141-23.5-13.5-43.363-39.824-43.211S76.954,21.2,76.813,44.7c-.141,23.62,13.507,42.176,39.831,42.024S156.839,67.857,156.981,44.237Zm58.582,7.614-.011,1.78c7.418-.043,9.928.417,9.827,17.391-.065,10.8-3.908,13.316-17.908,13.4-13.4.077-22.085-8.3-21.89-40.94s8.741-41.117,22.381-41.2c14.119-.081,23.42,5.206,25.938,24.3l3.35-.019.955-19.708C230.817,2.032,221.374.425,207.973.5c-25.845.149-40.917,19.464-41.058,43.084s14.7,42.762,40.545,42.613c12.8-.074,23.345-2.271,30.781-5.163.155-25.875,1.361-27.544,8.3-27.584l.011-1.78Zm101.752-8.539c-.2,32.641-8.857,40.524-22.976,40.606-14.239.082-22.926-7.7-22.731-40.342.195-32.522,8.984-41.712,23.223-41.794C308.949,1.7,317.51,10.79,317.315,43.312Zm17.35-.1c.141-23.5-13.5-43.363-39.824-43.211s-40.2,20.172-40.344,43.673C254.356,67.294,268,85.85,294.328,85.7S334.524,66.832,334.665,43.212Z"
                        transform="translate(609.989 292)"
                    />
                </g>
                <path
                    v-else
                    id="Path_6260"
                    data-name="Path 6260"
                    d="M62.722,60.21C59.467,84.2,33.5,84.354,27.041,84.391l-4.307.025.415-69.436C23.2,6.434,28,4.389,33.144,4.359l.011-1.78L.011,2.77,0,4.55c5.145-.03,8.477,3.037,8.434,10.277L8.094,71.563C8.046,79.635,5.624,84.515.479,84.545l-.011,1.78,63.775-.368L64.4,60.2Zm76.909-15.873c-.2,32.641-8.857,40.524-22.976,40.606-14.239.082-22.926-7.7-22.731-40.342.195-32.522,8.984-41.712,23.223-41.794C131.265,2.725,139.826,11.815,139.631,44.337Zm17.35-.1c.141-23.5-13.5-43.363-39.824-43.211S76.954,21.2,76.813,44.7c-.141,23.62,13.507,42.176,39.831,42.024S156.839,67.857,156.981,44.237Zm58.582,7.614-.011,1.78c7.418-.043,9.928.417,9.827,17.391-.065,10.8-3.908,13.316-17.908,13.4-13.4.077-22.085-8.3-21.89-40.94s8.741-41.117,22.381-41.2c14.119-.081,23.42,5.206,25.938,24.3l3.35-.019.955-19.708C230.817,2.032,221.374.425,207.973.5c-25.845.149-40.917,19.464-41.058,43.084s14.7,42.762,40.545,42.613c12.8-.074,23.345-2.271,30.781-5.163.155-25.875,1.361-27.544,8.3-27.584l.011-1.78Zm101.752-8.539c-.2,32.641-8.857,40.524-22.976,40.606-14.239.082-22.926-7.7-22.731-40.342.195-32.522,8.984-41.712,23.223-41.794C308.949,1.7,317.51,10.79,317.315,43.312Zm17.35-.1c.141-23.5-13.5-43.363-39.824-43.211s-40.2,20.172-40.344,43.673C254.356,67.294,268,85.85,294.328,85.7S334.524,66.832,334.665,43.212Z"
                    transform="translate(-14 374)"
                />
            </g>
            <image
                v-if="isFolded"
                href="/img/notes/personalized/rog_1_left.png"
                transform="translate(5 200) scale(0.6)"
            />
        </g>
        <!-- vertical -->
        <g
            v-if="personalizedPrint === 'one' && format === 'vertical'"
            id="Group_1226"
            data-name="Group 1226"
            class="notes-pages-14"
            transform="translate(-310 0)"
        >
            <rect
                v-if="!isFolded"
                id="Untitled-4"
                class="notes-pages-2"
                width="310"
                :style="`fill:${paperColor};`"
                height="455"
                transform="translate(320 0)"
            />
            <path
                v-else
                id="Path_9598"
                data-name="Path 9598"
                d="M580.8-32.756H272.909l-.693,331.745s-.881,33.845,10.876,39.332c9.589,6.532,46.431,32.762,56.712,40.74,4.744,3.681,37.124,28.3,42.126,31.661a57.376,57.376,0,0,0,32.049,7.735c4.762-.212,166.82-.183,166.82-.183h0Z"
                transform="translate(47 36)"
                :fill="paperColor"
            />
            <text
                id="Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at._Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit._Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at"
                data-name="Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at"
                class="notes-pages-15"
                transform="translate(347.994 148.096)"
            >
                <tspan x="0" y="6"
                    >Nunc in ipsum tortor. Suspendisse ultricies</tspan
                >
                <tspan x="0" y="14">magna magna, ac vehicula urna</tspan>
                <tspan x="0" y="22"
                    >ullamcorper at. Suspendisse potenti. Lorem</tspan
                >
                <tspan x="0" y="30">ipsum dolor sit amet, consectetur</tspan>
                <tspan x="0" y="38"
                    >adipiscing elit. Nunc in ipsum tortor.</tspan
                >
                <tspan x="0" y="46"
                    >Suspendisse ultricies magna magna, ac</tspan
                >
                <tspan x="0" y="54"
                    >vehicula urna ullamcorper at. Suspendisse</tspan
                >
                <tspan x="0" y="62">potenti.</tspan>
            </text>
            <text
                id="YOUR_LOGO"
                data-name="YOUR LOGO"
                class="notes-pages-16"
                transform="translate(473.994 99.096)"
            >
                <tspan x="-74.785" y="0">YOUR LOGO</tspan>
            </text>
            <text
                id="advertisement"
                class="notes-pages-17"
                transform="translate(469.994 127.096)"
            >
                <tspan x="-105.068" y="0">ADVERTISEMENT</tspan>
            </text>
            <text
                id="_Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit._Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at._Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_per_at._Suspendisse_potent"
                data-name=" Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur per at. Suspendisse potent"
                class="notes-pages-15"
                transform="translate(480.994 148.096)"
            >
                <tspan x="0" y="6" xml:space="preserve"
                    >Suspendisse potenti. Lorem ipsum dolor</tspan
                >
                <tspan x="0" y="14"
                    >sit amet, consectetur adipiscing elit. Nunc</tspan
                >
                <tspan x="0" y="22"
                    >in ipsum tortor. Suspendisse ultricies</tspan
                >
                <tspan x="0" y="30">magna magna, ac vehicula urna</tspan>
                <tspan x="0" y="38"
                    >ullamcorper at. Suspendisse potenti. Lorem</tspan
                >
                <tspan x="0" y="46"
                    >ipsum dolor sit amet, consectetur per at.</tspan
                >
                <tspan x="0" y="54"
                    >Suspendisse potenti. Lorem ipsum dolor sit</tspan
                >
                <tspan x="0" y="62">amet, consectetur adipi</tspan>
            </text>
            <path
                id="Path_6260"
                data-name="Path 6260"
                d="M-7.2,28.457C-10.86,56.888-41.4,56.888-49,56.888h-5.067V-25.45c0-10.134,5.63-12.527,11.682-12.527v-2.111H-81.374v2.111c6.052,0,9.993,3.659,9.993,12.245V41.546c0,9.571-2.815,15.341-8.867,15.341V59H-5.23V28.457Zm90.36-18.3c0,38.706-10.134,47.995-26.742,47.995-16.749,0-27.023-9.289-27.023-47.995,0-38.565,10.275-49.4,27.023-49.4C73.026-39.243,83.16-28.405,83.16,10.16Zm20.408,0c0-27.868-16.186-51.514-47.15-51.514S9.267-17.708,9.267,10.16c0,28.009,16.186,50.106,47.15,50.106S103.568,38.168,103.568,10.16Zm68.966,9.43V21.7c8.726,0,11.682.563,11.682,20.69,0,12.808-4.5,15.764-20.971,15.764-15.764,0-26.038-9.993-26.038-48.7s9.993-48.7,26.038-48.7c16.608,0,27.587,6.334,30.683,28.994h3.941l.985-23.364c-8.726-5.771-19.845-7.741-35.609-7.741-30.4,0-47.995,22.8-47.995,50.81s17.593,50.81,47.995,50.81c15.06,0,27.446-2.533,36.172-5.911,0-30.683,1.407-32.653,9.571-32.653V19.59ZM292.17,10.16c0,38.706-10.134,47.995-26.742,47.995-16.749,0-27.024-9.289-27.024-47.995,0-38.565,10.275-49.4,27.024-49.4C282.036-39.243,292.17-28.405,292.17,10.16Zm20.408,0c0-27.868-16.186-51.514-47.15-51.514s-47.15,23.646-47.15,51.514c0,28.009,16.186,50.106,47.15,50.106S312.578,38.168,312.578,10.16Z"
                transform="matrix(0.966, 0.259, -0.259, 0.966, 377.613, 272.372)"
            />
            <image
                v-if="isFolded"
                href="/img/notes/personalized/rog_1_left.png"
                transform="translate(316 35)"
            />
        </g>
        <!-- square  -->
        <g
            v-if="personalizedPrint === 'one' && format === 'square'"
            id="Group_1341"
            data-name="Group 1341"
            class="square-pages-one-14"
            transform="translate(-313 -40.096)"
        >
            <rect
                v-if="!isFolded"
                :style="`fill:${paperColor};`"
                width="308"
                height="263"
                transform="translate(270 46)"
            />
            <path
                v-else
                id="Path_9598"
                data-name="Path 9598"
                d="M436.376-.684H182.411L182.2,185.985s-.548,21.053,6.766,24.466c5.965,4.063,28.882,20.379,35.277,25.342,2.951,2.29,20.424,15.83,23.535,17.92a45.984,45.984,0,0,0,28.415,8.324l160.3.126Z"
                transform="translate(140 45) scale(1)"
                :fill="paperColor"
            />
            <text
                id="Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at._Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit._Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at"
                data-name="Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at"
                class="square-pages-one-15"
                transform="translate(352.994 132.096)"
            >
                <tspan x="0" y="5">Nunc in ipsum tortor. Suspendisse </tspan
                ><tspan x="0" y="11"
                    >ultricies magna magna, ac vehicula urna </tspan
                ><tspan x="0" y="17"
                    >ullamcorper at. Suspendisse potenti. </tspan
                ><tspan x="0" y="23"
                    >Lorem ipsum dolor sit amet, consectetur </tspan
                ><tspan x="0" y="29"
                    >adipiscing elit. Nunc in ipsum tortor. </tspan
                ><tspan x="0" y="35"
                    >Suspendisse ultricies magna magna, ac </tspan
                ><tspan x="0" y="41"
                    >vehicula urna ullamcorper at. Suspendisse </tspan
                ><tspan x="0" y="47">potenti. </tspan>
            </text>
            <text
                id="YOUR_LOGO"
                data-name="YOUR LOGO"
                class="square-pages-one-16"
                transform="translate(457.994 93.096)"
            >
                <tspan x="-61.127" y="0">YOUR LOGO</tspan>
            </text>
            <text
                id="advertisement"
                class="square-pages-one-17"
                transform="translate(465.994 114.096)"
                ><tspan x="-91.728" y="0">ADVERTISEMENT</tspan></text
            >
            <text
                id="_Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit._Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at._Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_per_at._Suspendisse_potent"
                data-name=" Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur per at. Suspendisse potent"
                class="square-pages-one-15"
                transform="translate(460.994 132.096)"
            >
                <tspan x="0" y="5" xml:space="preserve">
                    Suspendisse potenti. Lorem ipsum dolor </tspan
                ><tspan x="0" y="11"
                    >sit amet, consectetur adipiscing elit. Nunc </tspan
                ><tspan x="0" y="17"
                    >in ipsum tortor. Suspendisse ultricies </tspan
                ><tspan x="0" y="23">magna magna, ac vehicula urna </tspan
                ><tspan x="0" y="29"
                    >ullamcorper at. Suspendisse potenti. </tspan
                ><tspan x="0" y="35"
                    >Lorem ipsum dolor sit amet, consectetur </tspan
                ><tspan x="0" y="41"
                    >per at. Suspendisse potenti. Lorem ipsum </tspan
                ><tspan x="0" y="47">dolor sit amet, consectetur adipi</tspan>
            </text>
            <path
                v-if="!isFolded"
                id="Path_7065"
                data-name="Path 7065"
                d="M61.254,53.948C58.738,75.381,33.972,74.9,27.809,74.776L23.7,74.7l-1.307-62.24c-.161-7.66,4.366-9.38,9.274-9.284l-.034-1.6L.02.958l.034,1.6c4.908.1,8.161,2.925,8.3,9.415L9.419,62.824C9.571,70.059,7.38,74.376,2.472,74.28l.034,1.6,60.831,1.19-.485-23.087Zm72.981-12.4c.614,29.258-7.456,36.119-20.923,35.856-13.581-.266-22.06-7.451-22.675-36.709-.612-29.152,7.548-37.181,21.129-36.915C125.234,4.046,133.623,12.4,134.235,41.551Zm16.549.324c-.442-21.066-13.942-39.2-39.051-39.688S73.875,19.312,74.317,40.378C74.762,61.55,88.237,78.511,113.346,79S151.229,63.047,150.784,41.875ZM206.857,50.1l.033,1.6c7.076.138,9.482.611,9.8,15.825.2,9.682-3.4,11.845-16.755,11.583-12.783-.25-21.273-7.967-21.887-37.225s7.33-36.653,20.341-36.4c13.467.264,22.47,5.225,25.34,22.4l3.2.063.428-17.646c-7.168-4.5-16.215-6.167-29-6.417C173.706,3.4,159.8,20.356,160.246,41.528S175.318,80.215,199.97,80.7c12.212.239,22.215-1.48,29.238-3.895-.487-23.194.623-24.661,7.243-24.531l-.033-1.6Zm96.861-5.23c.614,29.258-7.456,36.119-20.923,35.856-13.581-.266-22.06-7.451-22.675-36.709-.612-29.152,7.548-37.181,21.129-36.915C294.717,7.363,303.106,15.716,303.718,44.867Zm16.549.324c-.442-21.066-13.942-39.2-39.051-39.688S243.358,22.629,243.8,43.695c.444,21.172,13.92,38.133,39.029,38.624S320.711,66.363,320.267,45.191Z"
                transform="matrix(0.966, 0.259, -0.259, 0.966, 320.733, 163.883)"
            />
            <g
                v-else
                id="Mask_Group_49"
                data-name="Mask Group 49"
                transform="translate(-2177.52 -4632.642)"
                clip-path="url(#logo-clip-path)"
            >
                <g
                    id="Group_1518"
                    data-name="Group 1518"
                    transform="translate(2186.006 4632.904)"
                >
                    <path
                        id="Path_9657"
                        data-name="Path 9657"
                        d="M61.254,53.948C58.738,75.381,33.972,74.9,27.809,74.776L23.7,74.7l-1.307-62.24c-.161-7.66,4.366-9.38,9.274-9.284l-.034-1.6L.02.958l.034,1.6c4.908.1,8.161,2.925,8.3,9.415L9.419,62.824C9.571,70.059,7.38,74.376,2.472,74.28l.034,1.6,60.831,1.19-.485-23.087Zm72.981-12.4c.614,29.258-7.456,36.119-20.923,35.856-13.581-.266-22.06-7.451-22.675-36.709-.612-29.152,7.548-37.181,21.129-36.915C125.234,4.046,133.623,12.4,134.235,41.551Zm16.549.324c-.442-21.066-13.942-39.2-39.051-39.688S73.875,19.312,74.317,40.378C74.762,61.55,88.237,78.511,113.346,79S151.229,63.047,150.784,41.875ZM206.857,50.1l.033,1.6c7.076.138,9.482.611,9.8,15.825.2,9.682-3.4,11.845-16.755,11.583-12.783-.25-21.273-7.967-21.887-37.225s7.33-36.653,20.341-36.4c13.467.264,22.47,5.225,25.34,22.4l3.2.063.428-17.646c-7.168-4.5-16.215-6.167-29-6.417C173.706,3.4,159.8,20.356,160.246,41.528S175.318,80.215,199.97,80.7c12.212.239,22.215-1.48,29.238-3.895-.487-23.194.623-24.661,7.243-24.531l-.033-1.6Zm96.861-5.23c.614,29.258-7.456,36.119-20.923,35.856-13.581-.266-22.06-7.451-22.675-36.709-.612-29.152,7.548-37.181,21.129-36.915C294.717,7.363,303.106,15.716,303.718,44.867Zm16.549.324c-.442-21.066-13.942-39.2-39.051-39.688S243.358,22.629,243.8,43.695c.444,21.172,13.92,38.133,39.029,38.624S320.711,66.363,320.267,45.191Z"
                        transform="matrix(0.966, 0.259, -0.259, 0.966, 311.733, 163.883)"
                    />
                </g>
            </g>
            <image
                v-if="isFolded"
                href="/img/notes/personalized/rog_1_left.png"
                transform="translate(320 35) scale(0.65)"
            />
        </g>
        <!-- CMYK -->
        <!-- vertical -->
        <defs v-if="personalizedPrint === 'cmyk' && format === 'vertical'">
            <pattern
                id="vertical-pattern"
                width="1"
                height="1"
                viewBox="466.002 0 162.75 244.668"
            >
                <image
                    preserveAspectRatio="xMidYMid slice"
                    width="688.135"
                    height="451"
                    xlink:href="/img/notes/cmyk.png"
                />
            </pattern>
            <clipPath id="vertical-clip-path">
                <rect
                    id="Rectangle_1601"
                    data-name="Rectangle 1601"
                    class="notes-pages-1"
                    width="308"
                    height="451"
                    transform="translate(319.994 5.096)"
                />
            </clipPath>
        </defs>
        <g
            v-if="personalizedPrint === 'cmyk' && format === 'vertical'"
            id="Group_776"
            data-name="Group 776"
            transform="translate(-881 -307)"
        >
            <rect
                v-if="!isFolded"
                id="Untitled-4"
                class="notes-pages-2"
                width="310"
                height="451"
                transform="translate(891 312)"
            />
            <path
                v-else
                id="Path_9598"
                data-name="Path 9598"
                d="M580.8-32.756H272.909l-.693,331.745s-.881,33.845,10.876,39.332c9.589,6.532,46.431,32.762,56.712,40.74,4.744,3.681,37.124,28.3,42.126,31.661a57.376,57.376,0,0,0,32.049,7.735c4.762-.212,166.82-.183,166.82-.183h0Z"
                transform="translate(619 345)"
                class="notes-pages-2"
            />
            <rect
                id="Rectangle_1602"
                data-name="Rectangle 1602"
                class="notes-pages-3"
                width="267"
                height="101"
                transform="translate(907 400)"
            />
            <g
                id="Group_17"
                data-name="Group 17"
                class="notes-pages-4"
                transform="translate(571.006 306.904)"
            >
                <text
                    id="Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at._Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit._Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at"
                    data-name="Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at"
                    class="notes-pages-5"
                    :fill="`${paperColor}`"
                    transform="translate(347.994 148.096)"
                    ><tspan x="0" y="6"
                        >Nunc in ipsum tortor. Suspendisse ultricies </tspan
                    ><tspan x="0" y="14">magna magna, ac vehicula urna </tspan
                    ><tspan x="0" y="22"
                        >ullamcorper at. Suspendisse potenti. Lorem </tspan
                    ><tspan x="0" y="30"
                        >ipsum dolor sit amet, consectetur </tspan
                    ><tspan x="0" y="38"
                        >adipiscing elit. Nunc in ipsum tortor. </tspan
                    ><tspan x="0" y="46"
                        >Suspendisse ultricies magna magna, ac </tspan
                    ><tspan x="0" y="54"
                        >vehicula urna ullamcorper at. Suspendisse </tspan
                    ><tspan x="0" y="62">potenti. </tspan></text
                >
                <text
                    id="YOUR_LOGO"
                    data-name="YOUR LOGO"
                    class="notes-pages-6"
                    :fill="`${paperColor}`"
                    transform="translate(473.994 99.096)"
                    ><tspan x="-74.785" y="0">YOUR LOGO</tspan></text
                >
                <text
                    id="advertisement"
                    class="notes-pages-7"
                    :fill="`${paperColor}`"
                    transform="translate(469.994 127.096)"
                    ><tspan x="-105.068" y="0">ADVERTISEMENT</tspan></text
                >
                <text
                    id="_Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit._Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at._Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_per_at._Suspendisse_potent"
                    data-name=" Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur per at. Suspendisse potent"
                    class="notes-pages-5"
                    transform="translate(480.994 148.096)"
                    :fill="`${paperColor}`"
                    ><tspan x="0" y="6" xml:space="preserve">
                        Suspendisse potenti. Lorem ipsum dolor </tspan
                    ><tspan x="0" y="14"
                        >sit amet, consectetur adipiscing elit. Nunc </tspan
                    ><tspan x="0" y="22"
                        >in ipsum tortor. Suspendisse ultricies </tspan
                    ><tspan x="0" y="30">magna magna, ac vehicula urna </tspan
                    ><tspan x="0" y="38"
                        >ullamcorper at. Suspendisse potenti. Lorem </tspan
                    ><tspan x="0" y="46"
                        >ipsum dolor sit amet, consectetur per at. </tspan
                    ><tspan x="0" y="54"
                        >Suspendisse potenti. Lorem ipsum dolor sit </tspan
                    ><tspan x="0" y="62">amet, consectetur adipi</tspan></text
                >
                <path
                    id="Path_6259"
                    data-name="Path 6259"
                    class="notes-pages-8"
                    d="M-7.2,28.457C-10.86,56.888-41.4,56.888-49,56.888h-5.067V-25.45c0-10.134,5.63-12.527,11.682-12.527v-2.111H-81.374v2.111c6.052,0,9.993,3.659,9.993,12.245V41.546c0,9.571-2.815,15.341-8.867,15.341V59H-5.23V28.457Zm90.36-18.3c0,38.706-10.134,47.995-26.742,47.995-16.749,0-27.023-9.289-27.023-47.995,0-38.565,10.275-49.4,27.023-49.4C73.026-39.243,83.16-28.405,83.16,10.16Zm20.408,0c0-27.868-16.186-51.514-47.15-51.514S9.267-17.708,9.267,10.16c0,28.009,16.186,50.106,47.15,50.106S103.568,38.168,103.568,10.16Zm68.966,9.43V21.7c8.726,0,11.682.563,11.682,20.69,0,12.808-4.5,15.764-20.971,15.764-15.764,0-26.038-9.993-26.038-48.7s9.993-48.7,26.038-48.7c16.608,0,27.587,6.334,30.683,28.994h3.941l.985-23.364c-8.726-5.771-19.845-7.741-35.609-7.741-30.4,0-47.995,22.8-47.995,50.81s17.593,50.81,47.995,50.81c15.06,0,27.446-2.533,36.172-5.911,0-30.683,1.407-32.653,9.571-32.653V19.59ZM292.17,10.16c0,38.706-10.134,47.995-26.742,47.995-16.749,0-27.024-9.289-27.024-47.995,0-38.565,10.275-49.4,27.024-49.4C282.036-39.243,292.17-28.405,292.17,10.16Zm20.408,0c0-27.868-16.186-51.514-47.15-51.514s-47.15,23.646-47.15,51.514c0,28.009,16.186,50.106,47.15,50.106S312.578,38.168,312.578,10.16Z"
                    transform="matrix(0.966, 0.259, -0.259, 0.966, 377.613, 272.372)"
                    :fill="`${paperColor}`"
                />
            </g>
            <image
                id="folded"
                v-if="isFolded"
                href="/img/notes/personalized/rog_2_left.png"
                transform="translate(895 344) scale(1)"
            />
        </g>
        <!-- horizontal -->
        <g
            v-if="personalizedPrint === 'cmyk' && format === 'horizontal'"
            id="Group_1226"
            data-name="Group 1226"
            class="horizontal-pages-cmyk-16"
            transform="translate(9 -263)"
        >
            <defs>
                <clipPath id="horiz-cmyk-clip-path-2">
                    <rect
                        id="Rectangle_1603"
                        data-name="Rectangle 1603"
                        class="horizontal-pages-cmyk-2"
                        width="301"
                        height="186"
                        transform="translate(0 265.375)"
                    />
                </clipPath>
                <pattern
                    id="horiz-cmyk-pattern-3"
                    width="1"
                    height="1"
                    viewBox="0 0 500 300"
                >
                    <image
                        preserveAspectRatio="xMidYMid slice"
                        width="688.135"
                        height="451"
                        xlink:href="/img/notes/cmyk.png"
                    />
                </pattern>
            </defs>
            <rect
                v-if="!isFolded"
                id="Untitled-4"
                class="horizontal-pages-cmyk-17"
                width="308"
                height="451"
                transform="translate(-3 129.375)"
            />
            <path
                v-else
                id="Path_8167"
                d="M361.281,75.1H56.382l-.2,110.886s-.071,2.713.2,6.664c.42,6.178,1.878,15.614,6.566,17.8,5.965,4.063,28.882,20.379,35.277,25.342,2.951,2.29,20.424,15.83,23.535,17.92,13.773,9.751,25.209,7.567,25.209,7.567H361.281Z"
                data-name="Path 8167"
                transform="translate(-60 190)"
                class="horizontal-pages-cmyk-17"
            />
            <text
                id="Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at._Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit._Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at"
                data-name="Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at"
                class="horizontal-pages-cmyk-18"
                :fill="`${paperColor}`"
                transform="translate(29 332.375)"
                ><tspan x="0" y="6"
                    >Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac
                    vehicula urna ullamcorper at. </tspan
                ><tspan x="0" y="14"
                    >Suspendisse potenti. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Nunc in ipsum tortor. </tspan
                ><tspan x="0" y="22"
                    >Suspendisse ultricies magna magna, ac vehicula urna
                    ullamcorper at. Suspendisse potenti.
                </tspan></text
            >
            <text
                id="YOUR_LOGO"
                data-name="YOUR LOGO"
                class="horizontal-pages-cmyk-19"
                :fill="`${paperColor}`"
                transform="translate(153 297.375)"
                ><tspan x="-56.089" y="0">YOUR LOGO</tspan></text
            >
            <text
                id="advertisement"
                class="horizontal-pages-cmyk-20"
                :fill="`${paperColor}`"
                transform="translate(149 319.375)"
                ><tspan x="-78.801" y="0">ADVERTISEMENT</tspan></text
            >
            <g
                v-if="isFolded"
                :fill="`${paperColor}`"
                id="Mask_Group_51"
                data-name="Mask Group 51"
                transform="translate(-624 82)"
                clip-path="url(#logo-horizontal-clip-path)"
            >
                <path
                    id="Path_9661"
                    data-name="Path 9661"
                    d="M62.722,60.21C59.467,84.2,33.5,84.354,27.041,84.391l-4.307.025.415-69.436C23.2,6.434,28,4.389,33.144,4.359l.011-1.78L.011,2.77,0,4.55c5.145-.03,8.477,3.037,8.434,10.277L8.094,71.563C8.046,79.635,5.624,84.515.479,84.545l-.011,1.78,63.775-.368L64.4,60.2Zm76.909-15.873c-.2,32.641-8.857,40.524-22.976,40.606-14.239.082-22.926-7.7-22.731-40.342.195-32.522,8.984-41.712,23.223-41.794C131.265,2.725,139.826,11.815,139.631,44.337Zm17.35-.1c.141-23.5-13.5-43.363-39.824-43.211S76.954,21.2,76.813,44.7c-.141,23.62,13.507,42.176,39.831,42.024S156.839,67.857,156.981,44.237Zm58.582,7.614-.011,1.78c7.418-.043,9.928.417,9.827,17.391-.065,10.8-3.908,13.316-17.908,13.4-13.4.077-22.085-8.3-21.89-40.94s8.741-41.117,22.381-41.2c14.119-.081,23.42,5.206,25.938,24.3l3.35-.019.955-19.708C230.817,2.032,221.374.425,207.973.5c-25.845.149-40.917,19.464-41.058,43.084s14.7,42.762,40.545,42.613c12.8-.074,23.345-2.271,30.781-5.163.155-25.875,1.361-27.544,8.3-27.584l.011-1.78Zm101.752-8.539c-.2,32.641-8.857,40.524-22.976,40.606-14.239.082-22.926-7.7-22.731-40.342.195-32.522,8.984-41.712,23.223-41.794C308.949,1.7,317.51,10.79,317.315,43.312Zm17.35-.1c.141-23.5-13.5-43.363-39.824-43.211s-40.2,20.172-40.344,43.673C254.356,67.294,268,85.85,294.328,85.7S334.524,66.832,334.665,43.212Z"
                    transform="translate(609.989 292)"
                />
            </g>
            <path
                v-else
                :fill="`${paperColor}`"
                id="Path_6260"
                data-name="Path 6260"
                d="M62.722,60.21C59.467,84.2,33.5,84.354,27.041,84.391l-4.307.025.415-69.436C23.2,6.434,28,4.389,33.144,4.359l.011-1.78L.011,2.77,0,4.55c5.145-.03,8.477,3.037,8.434,10.277L8.094,71.563C8.046,79.635,5.624,84.515.479,84.545l-.011,1.78,63.775-.368L64.4,60.2Zm76.909-15.873c-.2,32.641-8.857,40.524-22.976,40.606-14.239.082-22.926-7.7-22.731-40.342.195-32.522,8.984-41.712,23.223-41.794C131.265,2.725,139.826,11.815,139.631,44.337Zm17.35-.1c.141-23.5-13.5-43.363-39.824-43.211S76.954,21.2,76.813,44.7c-.141,23.62,13.507,42.176,39.831,42.024S156.839,67.857,156.981,44.237Zm58.582,7.614-.011,1.78c7.418-.043,9.928.417,9.827,17.391-.065,10.8-3.908,13.316-17.908,13.4-13.4.077-22.085-8.3-21.89-40.94s8.741-41.117,22.381-41.2c14.119-.081,23.42,5.206,25.938,24.3l3.35-.019.955-19.708C230.817,2.032,221.374.425,207.973.5c-25.845.149-40.917,19.464-41.058,43.084s14.7,42.762,40.545,42.613c12.8-.074,23.345-2.271,30.781-5.163.155-25.875,1.361-27.544,8.3-27.584l.011-1.78Zm101.752-8.539c-.2,32.641-8.857,40.524-22.976,40.606-14.239.082-22.926-7.7-22.731-40.342.195-32.522,8.984-41.712,23.223-41.794C308.949,1.7,317.51,10.79,317.315,43.312Zm17.35-.1c.141-23.5-13.5-43.363-39.824-43.211s-40.2,20.172-40.344,43.673C254.356,67.294,268,85.85,294.328,85.7S334.524,66.832,334.665,43.212Z"
                transform="translate(-14 374)"
            />
            <image
                v-if="isFolded"
                href="/img/notes/personalized/rog_2_left.png"
                transform="translate(0 195) scale(0.6)"
            />
        </g>
        <!-- square -->
        <g
            v-if="personalizedPrint === 'cmyk' && format === 'square'"
            id="Group_1344"
            data-name="Group 1344"
            transform="translate(-873 -307)"
        >
            <defs>
                <pattern
                    id="square-cmyk-pattern"
                    width="1"
                    height="1"
                    viewBox="254.231 0 132.37 143.22"
                >
                    <image
                        preserveAspectRatio="xMidYMid slice"
                        width="402.811"
                        height="264"
                        xlink:href="/img/notes/cmyk.png"
                    />
                </pattern>
                <clipPath id="square-cmyk-clip-path-2">
                    <rect
                        id="Rectangle_1601"
                        data-name="Rectangle 1601"
                        class="square-pages-cmyk-2"
                        :fill="`${paperColor}`"
                        width="254"
                        height="264"
                        transform="translate(-10 -0.001)"
                    />
                </clipPath>
            </defs>
            <rect
                v-if="!isFolded"
                id="Untitled-4"
                class="square-pages-cmyk-14"
                width="255"
                height="264"
                transform="translate(880.667 311.999)"
            />
            <path
                v-else
                id="Path_9598"
                data-name="Path 9598"
                d="M436.376-.684H182.411L182.2,185.985s-.548,21.053,6.766,24.466c5.965,4.063,28.882,20.379,35.277,25.342,2.951,2.29,20.424,15.83,23.535,17.92a45.984,45.984,0,0,0,28.415,8.324l160.3.126Z"
                transform="translate(698 313) scale(1)"
                fill="url(#square-cmyk-pattern)"
            />
            <rect
                id="Rectangle_1602"
                data-name="Rectangle 1602"
                class="square-pages-cmyk-15"
                width="218"
                height="81"
                transform="translate(903.667 357.999)"
            />
            <g
                id="Group_17"
                data-name="Group 17"
                class="square-pages-cmyk-16"
                transform="translate(890.667 312)"
            >
                <path
                    id="Path_7066"
                    data-name="Path 7066"
                    class="square-pages-cmyk-5"
                    :fill="`${paperColor}`"
                    d="M55.494,48.963c-2.28,19.417-24.716,18.978-30.3,18.869l-3.722-.073L20.289,11.374c-.146-6.94,3.956-8.5,8.4-8.41l-.03-1.446L.02.958.05,2.4c4.446.087,7.394,2.65,7.517,8.529L8.535,57c.138,6.554-1.847,10.465-6.293,10.378l.03,1.446L57.38,69.906l-.439-20.915Zm66.115-11.231c.556,26.506-6.754,32.721-18.955,32.482-12.3-.241-19.985-6.75-20.541-33.255C81.558,10.55,88.95,3.276,101.254,3.517,113.454,3.755,121.054,11.323,121.609,37.732Zm14.992.293c-.4-19.084-12.631-35.509-35.377-35.954s-34.3,15.515-33.9,34.6c.4,19.18,12.61,34.545,35.357,34.991S137,57.206,136.6,38.025Zm50.8,7.449.03,1.446c6.41.125,8.59.553,8.879,14.336.184,8.771-3.082,10.73-15.179,10.494-11.58-.227-19.271-7.218-19.828-33.723s6.641-33.205,18.428-32.975c12.2.239,20.356,4.734,22.956,20.3l2.9.057.388-15.986c-6.493-4.077-14.69-5.586-26.27-5.813-22.333-.437-34.929,14.924-34.527,34.1S158.827,72.759,181.16,73.2c11.063.216,20.125-1.34,26.487-3.528-.441-21.012.565-22.341,6.561-22.223L214.178,46Zm87.748-4.738c.556,26.506-6.754,32.721-18.955,32.482-12.3-.241-19.985-6.75-20.541-33.255-.554-26.409,6.837-33.683,19.141-33.442C266.993,6.76,274.593,14.327,275.147,40.737Zm14.992.293c-.4-19.084-12.631-35.509-35.377-35.954s-34.3,15.515-33.9,34.6c.4,19.18,12.611,34.545,35.357,34.991S290.542,60.21,290.139,41.03Z"
                    transform="matrix(0.966, 0.259, -0.259, 0.966, -10.837, 130.082)"
                />
                <g
                    id="Group_1345"
                    data-name="Group 1345"
                    transform="translate(247 -20)"
                >
                    <text
                        id="Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at._Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit._Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at"
                        data-name="Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at"
                        class="square-pages-cmyk-17"
                        :fill="`${paperColor}`"
                        transform="translate(-221 102)"
                        ><tspan x="0" y="5"
                            >Nunc in ipsum tortor. Suspendisse </tspan
                        ><tspan x="0" y="11"
                            >ultricies magna magna, ac vehicula urna </tspan
                        ><tspan x="0" y="17"
                            >ullamcorper at. Suspendisse potenti. </tspan
                        ><tspan x="0" y="23"
                            >Lorem ipsum dolor sit amet, consectetur </tspan
                        ><tspan x="0" y="29"
                            >adipiscing elit. Nunc in ipsum tortor. </tspan
                        ><tspan x="0" y="35"
                            >Suspendisse ultricies magna magna, ac </tspan
                        ><tspan x="0" y="41"
                            >vehicula urna ullamcorper at. Suspendisse </tspan
                        ><tspan x="0" y="47">potenti. </tspan></text
                    >
                    <text
                        id="YOUR_LOGO"
                        data-name="YOUR LOGO"
                        class="square-pages-cmyk-18"
                        :fill="`${paperColor}`"
                        transform="translate(-120 63)"
                        ><tspan x="-61.127" y="0">YOUR LOGO</tspan></text
                    >
                    <text
                        id="advertisement"
                        class="square-pages-cmyk-19"
                        :fill="`${paperColor}`"
                        transform="translate(-112 84)"
                        ><tspan x="-91.728" y="0">ADVERTISEMENT</tspan></text
                    >
                    <text
                        id="_Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit._Nunc_in_ipsum_tortor._Suspendisse_ultricies_magna_magna_ac_vehicula_urna_ullamcorper_at._Suspendisse_potenti._Lorem_ipsum_dolor_sit_amet_consectetur_per_at._Suspendisse_potent"
                        data-name=" Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in ipsum tortor. Suspendisse ultricies magna magna, ac vehicula urna ullamcorper at. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur per at. Suspendisse potent"
                        class="square-pages-cmyk-17"
                        :fill="`${paperColor}`"
                        transform="translate(-113 102)"
                        ><tspan x="0" y="5" xml:space="preserve">
                            Suspendisse potenti. Lorem ipsum dolor </tspan
                        ><tspan x="0" y="11"
                            >sit amet, consectetur adipiscing elit. Nunc </tspan
                        ><tspan x="0" y="17"
                            >in ipsum tortor. Suspendisse ultricies </tspan
                        ><tspan x="0" y="23"
                            >magna magna, ac vehicula urna </tspan
                        ><tspan x="0" y="29"
                            >ullamcorper at. Suspendisse potenti. </tspan
                        ><tspan x="0" y="35"
                            >Lorem ipsum dolor sit amet, consectetur </tspan
                        ><tspan x="0" y="41"
                            >per at. Suspendisse potenti. Lorem ipsum </tspan
                        ><tspan x="0" y="47"
                            >dolor sit amet, consectetur adipi</tspan
                        ></text
                    >
                    <image
                        v-if="isFolded"
                        href="/img/notes/personalized/rog_2_left.png"
                        transform="translate(-251 14) scale(0.65)"
                    />
                </g>
            </g>
        </g>
    </g>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState({
            personalizedPrint: (state) => {
                const option = state.notes.configuration.additionalPages
                if (option && option.personalizedPrint) {
                    if (option.personalizedPrint.title.pl === '1 + 1') {
                        return 'one'
                    }
                    if (option.personalizedPrint.title.pl === '1 + 0') {
                        return 'one'
                    }
                    if (option.personalizedPrint.title.pl === 'CMYK + 0') {
                        return 'cmyk'
                    }
                    if (option.personalizedPrint.title.pl === 'CMYK + CMYK') {
                        return 'cmyk'
                    }
                }
                return null
            },
            // get personalized pages paper color
            paperColor: (state) => {
                const options = state.notes.configuration.additionalPages
                if (options && options.paperType) {
                    return options.paperType.hex
                } else {
                    return state.notes.configurationDefault.additionalPages
                        .paperType.hex
                }
            },
            currentPage: (state) => state.page.currentPage,
            isFolded: (state) => {
                const additionalPages =
                    state.notes.configuration.additionalPages
                if (additionalPages) {
                    const print =
                        additionalPages.personalizedPrint &&
                        additionalPages.personalizedPrint.title.pl
                    if (print) {
                        const printTypesArray = print.split('+')
                        const trimedTypes = printTypesArray.map((el) => {
                            return el.trim()
                        })
                        if (trimedTypes[0] === trimedTypes[1]) {
                            return true
                        }
                    }
                }
                return false
            },
        }),
    },
}
</script>

<style lang="scss">
.square-pages-cmyk-14 {
    fill: url(#square-cmyk-pattern);
}
.square-pages-cmyk-15 {
    fill: #2e4358;
}
.square-pages-cmyk-16 {
    clip-path: url(#square-cmyk-clip-path-2);
}
.square-pages-cmyk-17 {
    font-size: 5px;
    font-family: SourceSansPro-Light, Source Sans Pro;
    letter-spacing: 0.02em;
}
.square-pages-cmyk-17,
.square-pages-cmyk-18 {
    font-weight: 300;
}
.square-pages-cmyk-18 {
    font-size: 22px;
    font-family: Butler-Light, Butler;
}
.square-pages-cmyk-19 {
    font-size: 21px;
    font-family: Butler-Medium, Butler;
    font-weight: 500;
}
.square-pages-one-14 {
    clip-path: url(#square-clip-path-2);
}
.square-pages-one-15 {
    font-size: 5px;
    font-family: SourceSansPro-Light, Source Sans Pro;
    letter-spacing: 0.02em;
}
.square-pages-one-15,
.square-pages-one-16 {
    font-weight: 300;
}
.square-pages-one-16 {
    font-size: 22px;
    font-family: Butler-Light, Butler;
}
.square-pages-one-17 {
    font-size: 21px;
    font-family: Butler-Medium, Butler;
    font-weight: 500;
}

.horizontal-pages-cmyk-2 {
    stroke: #707070;
}
.horizontal-pages-cmyk-16 {
    clip-path: url(#horiz-cmyk-clip-path-2);
}

.horizontal-pages-cmyk-17 {
    fill: url(#horiz-cmyk-pattern-3);
}

.horizontal-pages-cmyk-18 {
    font-size: 6px;
    font-family: SourceSansPro-Light, Source Sans Pro;
    letter-spacing: 0.02em;
}

.horizontal-pages-cmyk-18,
.horizontal-pages-cmyk-19 {
    font-weight: 300;
}

.horizontal-pages-cmyk-19,
.horizontal-pages-cmyk-20 {
    font-size: 21px;
}

.horizontal-pages-cmyk-19 {
    font-family: SegoeUI-Light, Segoe UI;
}

.horizontal-pages-cmyk-20 {
    font-family: SegoeUI, Segoe UI;
}

.horizontal-pages-one-14 {
    clip-path: url(#horizontal-clip-path-2);
}

.horizontal-pages-one-15 {
    font-size: 6px;
    font-family: SourceSansPro-Light, Source Sans Pro;
    letter-spacing: 0.02em;
}

.horizontal-pages-one-15,
.horizontal-pages-one-16 {
    font-weight: 300;
}

.horizontal-pages-one-16,
.horizontal-pages-one-17 {
    font-size: 21px;
}

.horizontal-pages-one-16 {
    font-family: SegoeUI-Light, Segoe UI;
}

.horizontal-pages-one-17 {
    font-family: SegoeUI, Segoe UI;
}

.notes-pages {
    &-1 {
        stroke: #707070;
    }
    &-2 {
        fill: url(#vertical-pattern);
    }
    &-3 {
        fill: #2e4358;
    }
    &-4 {
        clip-path: url(#vertical-clip-path);
    }
    &-5 {
        font-size: 6px;
        font-family: SourceSansPro-Light, Source Sans Pro;
        letter-spacing: 0.02em;
    }
    &-5,
    &-6 {
        font-weight: 300;
    }
    &-6,
    &-7 {
        font-size: 28px;
    }
    &-6 {
        font-family: SegoeUI-Light, Segoe UI;
    }
    &-7 {
        font-family: SegoeUI, Segoe UI;
    }
    &-14 {
        clip-path: url(#vertical-clip-path);
    }
    &-15 {
        font-size: 6px;
        font-family: SourceSansPro-Light, Source Sans Pro;
        letter-spacing: 0.02em;
    }
    &-15,
    &-16 {
        font-weight: 300;
    }
    &-16,
    &-17 {
        font-size: 28px;
    }
    &-16 {
        font-family: SegoeUI-Light, Segoe UI;
    }
    &-17 {
        font-family: SegoeUI, Segoe UI;
    }
}
</style>
