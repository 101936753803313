<template>
    <div
        class="offer-printing-row"
        :class="{ 'offer-printing-row--agency': userType === 'agency' }"
    >
        <div class="offer-printing-row-title">
            {{ $t('offer.prints') }} {{ index + 1 }}
        </div>

        <div class="offer-printing-row-section">
            <div
                class="offer-printing-row-section-title"
                :class="{ 'table-margin': actions }"
            >
                {{ $t('offer.prints') }}<span class="red"> *</span>
            </div>
            <div class="offer-printing-row-box">
                <button
                    v-if="actions"
                    class="offer-printing-row-box-add offer-printing-row-button offer-printing-row-button--plus"
                    :disabled="quantity >= max"
                    @click="incrementInput"
                >
                    +
                </button>
                <button
                    v-if="actions"
                    class="offer-printing-row-box-subtract offer-printing-row-button offer-printing-row-button--minus"
                    :disabled="quantity <= min"
                    @click="decrementInput"
                />
                <div v-if="!isCalculated" class="offer-printing-row-box-input">
                    <input
                        ref="input"
                        v-model.number="roundedQuantity"
                        type="text"
                        :maxlength="max.toString().length"
                        pattern="[0-9]+"
                        @keydown="onInput"
                        @keydown.enter="recalculateInput"
                        @blur="recalculateInput"
                    />
                    {{ $t('offer.szt') }}
                </div>
                <span v-else>{{ quantity }} {{ $t('offer.szt') }}</span>
            </div>
        </div>

        <!--        <div v-if="userType === 'agency'" class="offer-printing-row-section">
            <div class="offer-printing-row-section-title">
                {{ $t('offer.price2') }}
            </div>
            <div class="offer-printing-row-box">
                <span v-if="price.price">
                    <span v-if="currentCurrency.isOnLeftSide">
                        {{ currentCurrency.symbol }}
                    </span>
                    {{ price.price }}
                    <span v-if="!currentCurrency.isOnLeftSide">
                        {{ currentCurrency.symbol }}
                    </span>
                </span>
            </div>
        </div>-->

        <div class="offer-printing-row-section">
            <div class="offer-printing-row-section-title">
                {{ $t('offer.price1') }}
            </div>
            <div class="offer-printing-row-box">
                <span v-if="isNumber(price.price)">
                    <span v-if="currentCurrency.isOnLeftSide">
                        {{ currentCurrency.symbol }}
                    </span>
                    {{ price.price > 0 ? price.price : '-' }}
                    <span v-if="!currentCurrency.isOnLeftSide">
                        {{ currentCurrency.symbol }}
                    </span>
                </span>
            </div>
        </div>

        <div class="offer-printing-row-section">
            <div class="offer-printing-row-section-title">
                {{ $t('offer.transportCost') }}
            </div>
            <div class="offer-printing-row-box">
                <span v-if="isNumber(price.shippingPrice)">
                    <span v-if="currentCurrency.isOnLeftSide">
                        {{ currentCurrency.symbol }}
                    </span>
                    {{ price.shippingPrice > 0 ? price.shippingPrice : '-' }}
                    <span v-if="!currentCurrency.isOnLeftSide">
                        {{ currentCurrency.symbol }}
                    </span>
                </span>
            </div>
        </div>
        <div v-if="actions" class="offer-printing-row-buttons">
            <button
                class="offer-printing-row-button offer-printing-row-button--minus"
                :disabled="isLast && !index"
                @click="$emit('remove')"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    props: {
        index: {
            type: Number,
            required: true,
        },
        quantity: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            default: 100,
        },
        max: {
            type: Number,
            default: 1500,
        },
        step: {
            type: Number,
            default: 50,
        },
        isLast: {
            type: Boolean,
            default: false,
        },
        actions: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isCalculated: false,
            roundedQuantity: 0,
        }
    },
    computed: {
        ...mapState({
            userType: (state) => state.user.type,
            currentCurrency: (state) => state.valuation.currentCurrency,
            validPrices: (state) => state.valuation.validPrices.prices || [],
        }),
        ...mapGetters(['isCalcualtionValid']),

        price() {
            if (!this.isCalcualtionValid) {
                return {}
            }
            return (
                this.validPrices.find((p) => p.quantity === this.quantity) || {}
            )
        },
    },
    watch: {
        quantity(value) {
            this.roundedQuantity = value
        },
    },
    created() {
        this.roundedQuantity = this.quantity
    },
    methods: {
        isNumber(value) {
            return !isNaN(value)
        },
        onInput(event) {
            const key = event.key
            if (
                !['Delete', 'Backspace', 'Tab', 'Escape', 'Enter'].includes(
                    key
                ) &&
                !/\d/i.test(key)
            ) {
                event.preventDefault()
            }
        },
        recalculateInput() {
            const roundedNumber =
                Math.ceil(this.roundedQuantity / this.step) * this.step
            this.roundedQuantity = Math.min(
                Math.max(roundedNumber, this.min),
                this.max
            )

            this.$emit('input', this.roundedQuantity)
        },
        incrementInput() {
            if (this.roundedQuantity < this.max) {
                this.roundedQuantity += this.step
                this.recalculateInput()
            }
        },
        decrementInput() {
            if (this.roundedQuantity > this.min) {
                this.roundedQuantity -= this.step
                this.recalculateInput()
            }
        },
    },
}
</script>

<style lang="scss">
// local mixin changing row with prints and prices to column
@mixin wrap-row {
    flex-direction: column;
    min-width: 230px;
}

@mixin change-to-column {
    &-title {
        text-align: right;
        width: 100%;
        margin-bottom: 15px;
    }

    &-box {
        margin-right: 0;
    }

    &-section {
        display: flex;
        flex-flow: row nowrap;
        max-width: unset;
        margin: 0;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 15px;

        &-title {
            padding-right: 15px;
            width: 130px;
            text-align: right;
            display: block;
            margin: 0;
        }
    }
    &-buttons {
        width: 100%;
        justify-content: flex-end;
        margin-top: 0 !important;
    }
    &-button {
        margin-top: 0;
    }
    &-title {
        margin-top: 0 !important;
    }
}

.offer-printing-row {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 30px;
    align-items: center;
    width: 100%;

    button[disabled] {
        opacity: 0.5;
        cursor: default;
    }

    &-buttons {
        display: flex;
    }

    &-section {
        align-self: flex-end;
        max-width: 111px;
        margin-right: 15px;
        margin-bottom: 0;

        &-title {
            font-size: 1rem;
            margin-bottom: 10px;
            display: none;

            .red {
                color: $color-burnt-sienna;
            }
        }
    }

    &:first-child & {
        &-section {
            &-title {
                display: block;
            }
        }

        &-title {
            margin-top: 32px;
        }

        &-buttons {
            margin-top: 32px;
        }
    }

    &-title {
        width: 100%;
        padding-right: 10px;
    }

    &-box {
        @include size(111px, 50px);
        border: 1px solid $color-dark-gray;
        background: $color-white;
        font-size: 1.8rem;
        padding: 14px 19px;
        margin-right: 15px;
        text-align: center;
        white-space: nowrap;
        position: relative;

        &-input {
            display: flex;

            input {
                width: 50px;
                border: none;
                padding-right: 5px;
            }
        }
        &-add {
            position: absolute;
            right: calc(100% + 31px);
            top: calc(50% - 11px);
        }
        &-subtract {
            position: absolute;
            right: calc(100% + 5px);
            top: calc(50% - 11px);
        }
    }

    &-button {
        color: $color-white;
        @include size(21px);
        min-width: 21px;
        font-size: 3rem;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 21px;

        &--plus {
            background-color: $color-burnt-sienna;
        }

        &--minus {
            background-color: $color-dark-gray;
            // position: relative;
            // margin-left: 5px;

            &::before {
                content: '';
                @include size(12px, 2px);
                background-color: $color-white;
                position: relative;
                top: 0;
                left: 0;
            }
        }

        &--cross {
        }
    }

    // tablet
    // change row to column on larger screen when user is agency
    @media (min-width: $screen-tablet-medium) and (max-width: $screen-large) {
        &--agency {
            @include wrap-row();
            width: 55%;
        }

        &--agency & {
            @include change-to-column();
        }
        .tablet-margin {
            padding-right: 60px;
        }
    }

    // wrap for other users
    @media (min-width: $screen-tablet-medium) and (max-width: $screen-desktop) {
        width: 55%;
        @include wrap-row();
        @include change-to-column();
    }

    // mobile
    // again wrap for agency
    @media (max-width: $screen-tablet-small) {
        &--agency {
            width: 50%;
            @include wrap-row();
        }

        &--agency & {
            @include change-to-column();
        }
    }
    // again wrap for other
    @media (max-width: $screen-mobile-landscape) {
        width: 50%;

        @include wrap-row();
        @include change-to-column();

        .tablet-margin {
            padding-right: 60px;
        }
    }
    @media (max-width: 580px) {
        width: 100%;
    }
}
</style>
