<script>
import dependencies from '@/store/modules/dependencies'

export default {
    data() {
        return {
            pageTitle: {
                pl: 'Wyklejka',
                en: 'Front and back endsheet',
                de: 'Vor- und Nachsatz',
                fr: 'Pages de garde – au debut et a la fin',
            },
        }
    },
    computed: {
        list() {
            return dependencies.state.list
        },
        options() {
            let WYKLEJKA_RODZAJ_PAPIERU = null
            let WYKLEJKA_RODZAJ_ZADRUKU = null

            if (this.list?.length) {
                this.list.forEach((dependency) => {
                    WYKLEJKA_RODZAJ_PAPIERU = mergeMultipleParams(
                        WYKLEJKA_RODZAJ_PAPIERU,
                        dependency.actions.WYKLEJKA_RODZAJ_PAPIERU
                    )

                    WYKLEJKA_RODZAJ_ZADRUKU = mergeMultipleParams(
                        WYKLEJKA_RODZAJ_ZADRUKU,
                        dependency.actions.WYKLEJKA_RODZAJ_ZADRUKU
                    )
                })
            }

            function mergeMultipleParams(param, dependencyAction) {
                param = param || dependencyAction

                if (param && dependencyAction) {
                    param = param.filter((dep) => {
                        return dependencyAction.includes(dep)
                    })
                }
                return param
            }

            function filterOptionsFn({ apiId }, allowed) {
                return allowed ? allowed.includes(apiId.toUpperCase()) : true
            }
            const flyleafPrintChoices = [
                {
                    apiId: 'B',
                    title: {
                        pl: 'Bez zadruku',
                        en: 'Unprinted',
                        de: 'Ohne Druck',
                        fr: 'Sans impression',
                    },
                },
                {
                    apiId: '',
                    title: {
                        pl: 'Druk jednostronny',
                        en: 'Single-sided printing',
                        de: 'Einseitiger Druck',
                        fr: 'Impression recto',
                    },
                    childrenGroups: [
                        {
                            id: 'singleFlyleafPrint',
                            apiId: '',
                            required: true,
                            title: {
                                pl: 'Druk',
                                en: 'Print',
                                de: 'Druck',
                                fr: 'Impression',
                            },
                            items: [
                                {
                                    apiId: '10',
                                    title: {
                                        pl: '1 kolor',
                                        en: '1 colour',
                                        de: '1-farbig',
                                        fr: '1 couleur',
                                    },
                                },
                                {
                                    apiId: '20',
                                    title: {
                                        pl: '2 kolory',
                                        en: '2 colours',
                                        de: '2-frbig',
                                        fr: '2 couleurs',
                                    },
                                },
                                {
                                    apiId: '40',
                                    title: {
                                        pl: 'CMYK',
                                        en: 'CMYK',
                                        de: 'CMYK',
                                        fr: 'CMYK',
                                    },
                                },
                                {
                                    apiId: 'white',
                                    title: {
                                        pl: 'Biały',
                                        en: 'White',
                                        de: 'Weiß',
                                        fr: 'Blanc',
                                    },
                                },
                                {
                                    apiId: '4white',
                                    title: {
                                        pl: 'CMYK + biały',
                                        en: 'CMYK + white',
                                        de: 'CMYK + Weiß',
                                        fr: 'CMYK + Blanc',
                                    },
                                },
                            ].filter((item) =>
                                filterOptionsFn(item, WYKLEJKA_RODZAJ_ZADRUKU)
                            ),
                        },
                    ],
                },
                {
                    apiId: '',
                    title: {
                        pl: 'Druk dwustronny',
                        en: 'Two-sided printing',
                        de: 'Beidseitiger Druck',
                        fr: 'Impression recto verso',
                    },
                    childrenGroups: [
                        {
                            id: 'doubleFlyleafPrint',
                            apiId: '',
                            required: true,
                            title: {
                                pl: 'Druk',
                                en: 'Printing',
                                de: 'Druck',
                                fr: 'Impression',
                            },
                            items: [
                                {
                                    apiId: '11',
                                    title: {
                                        pl: '1 + 1',
                                        en: '1 + 1',
                                        de: '1 + 1',
                                        fr: '1 + 1',
                                    },
                                },
                                {
                                    apiId: '22',
                                    title: {
                                        pl: '2 + 2',
                                        en: '2 + 2',
                                        de: '2 + 2',
                                        fr: '2 + 2',
                                    },
                                },
                                {
                                    apiId: '44',
                                    title: {
                                        pl: 'CMYK + CMYK',
                                        en: 'CMYK + CMYK',
                                        de: 'CMYK + CMYK',
                                        fr: 'CMYK + CMYK',
                                    },
                                },
                                {
                                    apiId: 'whitewhite',
                                    title: {
                                        pl: 'Biały + biały',
                                        en: 'White + white',
                                        de: 'Weiß + Weiß',
                                        fr: 'Blanc + Blanc',
                                    },
                                },
                                {
                                    apiId: '4white4white',
                                    title: {
                                        pl: 'CMYK i biały + CMYK i biały',
                                        en: 'CMYK and white + CMYK and white',
                                        de: 'CMYK und Weiß + CMYK und Weiß',
                                        fr: 'CMYK et Blanc + CMYK et Blanc',
                                    },
                                },
                            ].filter((item) =>
                                filterOptionsFn(item, WYKLEJKA_RODZAJ_ZADRUKU)
                            ),
                        },
                    ],
                },
            ]

            return [
                {
                    id: 'flyleafPaper',
                    apiId: 'WYKLEJKA_RODZAJ_PAPIERU',
                    isModal: true,
                    required: true,
                    title: {
                        pl: 'Rodzaj papieru',
                        en: 'Paper type',
                        de: 'Papierart',
                        fr: 'Type de papier',
                    },
                    choices: [
                        {
                            apiId: 'S',
                            title: {
                                pl: 'Standardowy biały',
                                en: 'White',
                                de: 'Weiß',
                                fr: 'Blanc',
                            },
                            hex: '#f7f7f7',
                        },
                        {
                            apiId: 'I',
                            title: {
                                pl: 'Ivory',
                                en: 'Ivory',
                                de: 'Elfenbein',
                                fr: 'Ivoire',
                            },
                            hex: '#f8f6f1',
                        },
                        {
                            apiId: 'E',
                            title: {
                                pl: 'Biały z recyklingu',
                                en: 'White recycled',
                                de: 'Recycling weiß',
                                fr: 'Recyclé blanc',
                            },
                            hex: '#f4f1e8',
                        },
                        {
                            apiId: 'COF',
                            title: {
                                pl: 'Ekologiczny z kawy',
                                en: 'Eco – coffee',
                                de: 'ECO mit Kaffee',
                                fr: 'Eco de caffé',
                            },
                            hex: '#f0f1ec',
                            image: '/img/_updates/MNT-201/120PC--mini.jpg'
                        },

                        {
                            apiId: 'G',
                            title: {
                                pl: 'Ekologiczny z trawy',
                                en: 'Eco – grass',
                                de: 'ECO mit Grasfasern',
                                fr: "Eco d'herbe",
                            },
                            hex: '#dfd0bd',
                            image: '/img/_updates/MNT-212/80PG-320.jpg'
                        },

                        {
                            apiId: 'A',
                            title: {
                                pl: 'Ekologiczny z jabłek',
                                en: 'Eco - apple',
                                de: 'ECO mit Apfel',
                                fr: 'Eco de pommes',
                            },
                            hex: '#f0f1ec',
                            image: '/img/_updates/MNT-212/80PUA-112.jpg'
                        },
                        {
                            apiId: 'K',
                            title: {
                                pl: 'Kraft',
                                en: 'Kraft',
                                de: 'Kraft',
                                fr: 'Kraft',
                            },
                            hex: '#e7c4ad',
                        },
                        {
                            apiId: 'C',
                            title: {
                                pl: 'Czarny',
                                en: 'Black',
                                de: 'Schwarz',
                                fr: 'Noir',
                            },
                            hex: '#242424',
                        },
                    ].filter((item) =>
                        filterOptionsFn(item, WYKLEJKA_RODZAJ_PAPIERU)
                    ),
                },
                {
                    id: 'flyleaf',
                    apiId: 'WYKLEJKA_RODZAJ_ZADRUKU',
                    required: true,
                    title: {
                        pl: 'Rodzaj zadruku',
                        en: 'Printing type',
                        de: 'Druckart',
                        fr: "Type d'impression",
                    },
                    choices:
                        WYKLEJKA_RODZAJ_ZADRUKU?.length === 0
                            ? null
                            : flyleafPrintChoices,
                },
            ]
        },
    },
}
</script>
