var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-format-patterns"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 325.455 459.165"}},[_c('g',{attrs:{"transform":"translate(-124.787 -110.914)"}},[_c('rect',{class:[
                    'a',
                    { active: (_vm.format ? _vm.format.id : null) === 'a4' },
                ],attrs:{"id":"A4 (210 x 297)","width":"323.76","height":"457.889","transform":"translate(125.787 111.414)"}}),_c('rect',{class:[
                    'a',
                    { active: (_vm.format ? _vm.format.id : null) === 'b5' },
                ],attrs:{"id":"B5 (185 x 245)","width":"285.217","height":"377.72","transform":"translate(125.787 191.583)"}}),_c('rect',{class:[
                    'a',
                    { active: (_vm.format ? _vm.format.id : null) === 'a5' },
                ],attrs:{"id":"A5 (148 x 210)","width":"228.174","height":"323.76","transform":"translate(125.787 245.543)"}}),_c('rect',{class:[
                    'a',
                    { active: (_vm.format ? _vm.format.id : null) === '205' },
                ],attrs:{"id":"205 x 125","width":"316.051","height":"192.714","transform":"translate(125.787 376.588)"}}),_c('rect',{class:[
                    'a',
                    { active: (_vm.format ? _vm.format.id : null) === 'a6' },
                ],attrs:{"id":"A6 (105 x 148)","width":"161.88","height":"228.174","transform":"translate(125.787 341.129)"}}),_c('rect',{class:[
                    'a',
                    { active: (_vm.format ? _vm.format.id : null) === '84' },
                ],attrs:{"id":"84 x 135","width":"129.504","height":"208.131","transform":"translate(125.787 361.171)"}}),_c('rect',{class:[
                    'a',
                    { active: (_vm.format ? _vm.format.id : null) === '125' },
                ],attrs:{"id":"125 x 205","width":"192.714","height":"316.051","transform":"translate(125.787 253.251)"}}),_c('rect',{class:[
                    'a',
                    { active: (_vm.format ? _vm.format.id : null) === '175' },
                ],attrs:{"id":"175 x 175","width":"222.007","height":"222.007","transform":"translate(125.787 347.296)"}}),_c('text',{staticClass:"c",attrs:{"transform":"translate(150.693 470.353)"}},[(_vm.size.text)?_c('tspan',{class:['size', _vm.size.font],attrs:{"x":"0","y":"0"}},[_vm._v(" "+_vm._s(_vm.size.text)+" mm ")]):_vm._e()]),_c('use',{attrs:{"id":"use","xlink:href":`#${_vm.format}`}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }