<template>
    <!-- eslint-disable -->
    <g>
        <defs>
            <linearGradient
                id="edge-streamer-vertical-gradient"
                x1="1.941"
                y1="0.5"
                x2="-0.321"
                y2="0.5"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#bfbfbf" />
                <stop offset="0.243" stop-color="#d2d2d2" />
                <stop offset="0.637" stop-color="#f5f5f5" />
                <stop offset="0.905" stop-color="#d1d1d1" />
                <stop offset="1" stop-color="#bebdbd" />
                <stop offset="1" stop-color="#bdbdbd" />
            </linearGradient>

<!--            <pattern
                id="edge-vertical-pattern"
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                height="100%"
                viewBox="0 0 1242 814"
            >
                <image
                    width="1242"
                    height="814"
                    :xlink:href="base64[image.pattern]"
                />
            </pattern>-->
        </defs>
        <g v-if="type === 'Banderola szeroka'">
            <clipPath id="obwoluta-klejona-clip-path">
                <rect
                    id="Rectangle_1676"
                    data-name="Rectangle 1676"
                    :width="options[format].glued.width"
                    :height="options[format].glued.height"
                    fill="url(#edge-streamer-vertical-gradient)"
                />
            </clipPath>
            <g
                id="Group_1468"
                data-name="Group 1468"
                clip-path="url(#obwoluta-klejona-clip-path)"
                :transform="options[format].glued.transform"
            >
                <template v-if="print === 'CMYK + 0'">
                    <image

                        width="80"
                        height="814"
                        :xlink:href="base64[image.pattern]"
                        transform="translate(0 3)"
                    />
                    <image
                        v-if="print === 'CMYK + 0'"
                        :href="base64[image.shadow]"
                        transform="translate(0 3)"
                        preserveAspectRatio="none"
                        :width="options[format].glued.width"
                        :height="options[format].glued.height"
                    />
                </template>
                <rect
                    v-else
                    id="Rectangle_1672"
                    data-name="Rectangle 1672"
                    :width="options[format].glued.width"
                    :height="options[format].glued.height"
                    transform="translate(0 3)"
                    fill="url(#edge-streamer-vertical-gradient)"
                />


            </g>
        </g>
        <g v-if="type === 'Obwoluta szeroka'">
            <clipPath id="obwoluta-zwijana-clip-path">
                <rect
                    id="Rectangle_1678"
                    data-name="Rectangle 1678"
                    :width="options[format].rolled.width"
                    :height="options[format].rolled.height"
                    fill="url(#linear-gradient)"
                />
            </clipPath>
            <g
                v-if="print === '1 + 0'"
                id="obwoluta_zwijana_bok"
                data-name="obwoluta zwijana bok"
                :transform="options[format].rolled.transformOne"
            >
                <rect
                    id="Rectangle_1672"
                    data-name="Rectangle 1672"
                    :width="options[format].rolled.width"
                    :height="options[format].rolled.height"
                    transform="translate(2.7 3)"
                    fill="url(#edge-streamer-vertical-gradient)"
                />
                <rect
                    id="Rectangle_1672-2"
                    data-name="Rectangle 1672"
                    :width="options[format].rolled.width"
                    :height="options[format].rolled.height"
                    :transform="options[format].rolled.transformOneRight"
                    fill="url(#edge-streamer-vertical-gradient)"
                />
            </g>
            <g
                v-if="print === 'CMYK + 0'"
                :transform="options[format].rolled.transformCmyk"
            >
                <g
                    id="Mask_Group_46"
                    data-name="Mask Group 46"
                    clip-path="url(#obwoluta-zwijana-clip-path)"
                    style="isolation: isolate;"
                >
<!--                    <rect
                        id="Untitled-3"
                        width="2005.61"
                        height="517"
                        transform="translate(186.5 -266.917) rotate(90)"
                        :fill="'url(#edge-vertical-pattern)'"
                    />-->
                    <image
                        width="80"
                        height="814"
                        :xlink:href="base64[image.pattern]"
                        transform="translate(0 3)"
                    />
                    <image

                        :href="base64[image.shadow]"
                        transform="translate(0 3)"
                        preserveAspectRatio="none"
                        :width="options[format].glued.width"
                        :height="options[format].glued.height "
                    />
                </g>
                <g
                    id="Mask_Group_47"
                    data-name="Mask Group 47"
                    :transform="options[format].rolled.transformCmykRight"
                    clip-path="url(#obwoluta-zwijana-clip-path)"
                    style="isolation: isolate;"
                >
<!--                    <rect
                        id="Untitled-3-2"
                        data-name="Untitled-3"
                        width="2005.61"
                        height="517"
                        transform="translate(32.5 -269.917) rotate(90)"
                        :fill="'url(#edge-vertical-pattern)'"
                    />-->

                    <image
                        width="80"
                        height="814"
                        :xlink:href="base64[image.pattern]"
                        transform="translate(70 803) rotate(180)"
                    />
                    <image

                        :href="base64[image.shadow]"
                        transform="translate(0 3)"
                        preserveAspectRatio="none"
                        :width="options[format].glued.width"
                        :height="options[format].glued.height"
                    />
                </g>
            </g>
        </g>
    </g>
</template>

<script>
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        type: {
            type: String,
            default: '',
        },
        print: {
            type: String,
            default: '',
        },
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            options: {
                vertical: {
                    glued: {
                        transform: 'translate(2 32)',
                        width: 61,
                        height: 538,
                    },
                    rolled: {
                        transformOne: 'translate(0 31)',
                        transformOneRight: 'translate(53 3)',
                        width: 10,
                        height: 538,
                        transformCmyk: 'translate(2.5 34.5)',
                        transformCmykRight: 'translate(51 0)',
                    },
                },
                horizontal: {
                    glued: {
                        transform: 'translate(2 36)',
                        width: 51,
                        height: 192,
                    },
                    rolled: {
                        transformOne: 'translate(4 34)',
                        transformOneRight: 'translate(36 3)',
                        width: 6,
                        height: 192,
                        transformCmyk: 'translate(7 37.5)',
                        transformCmykRight: 'translate(33 0)',
                    },
                },
                square: {
                    glued: {
                        transform: 'translate(5 34)',
                        width: 49,
                        height: 256,
                    },
                    rolled: {
                        transformOne: 'translate(3 34)',
                        transformOneRight: 'translate(43 3)',
                        width: 7,
                        height: 254,
                        transformCmyk: 'translate(6 37.5)',
                        transformCmykRight: 'translate(40 0)',
                    },
                },
            },
            image: {
                pattern: '/img/notes/edge/edge-pattern-vertical.png',
                shadow: '/img/notes/edge/shadow-large.png',
            },
        }
    },
    created() {
        Object.keys(this.image).forEach((option) => {
            this.toDataUrl(this.image[option])
        })
    },
}
</script>
