<template>
    <div
        v-if="data"
        v-click-outside="hideList"
        :class="[
            'input-dropdown',
            theme ? `input-dropdown--${theme}` : '',
            isOpen ? `input-dropdown--open` : '',
        ]"
    >
        <InputDropdownSelected
            :theme="theme"
            :selected="selectedOption"
            :name="name"
            @toggle="toggleDropdown"
            @clear="clearCurrent"
        />
        <transition name="fade" mode="out-in">
            <InputDropdownOptions
                :theme="theme"
                :title="title"
                :data="data"
                :is-open="isOpen"
                :selected="selectedOption"
                @select-option="selectOption"
            />
        </transition>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import InputDropdownSelected from './InputDropdownSelected'
import InputDropdownOptions from './InputDropdownOptions'

import { mapState, mapMutations } from 'vuex'

export default {
    directives: {
        ClickOutside,
    },
    components: {
        InputDropdownSelected,
        InputDropdownOptions,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        theme: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isOpen: false,
            selectedOption: null,
        }
    },
    computed: {
        ...mapState({
            isMobileMenuActive: (state) => state.page.isMobileMenuActive,
        }),
    },
    methods: {
        ...mapMutations(['setOverlay']),
        selectOption(option) {
            this.selectedOption = option
            this.$emit('selected', option)
            this.hideList()
        },
        clearCurrent() {
            this.selectedOption = null
            this.$emit('selected', null)
        },
        toggleDropdown() {
            this.isOpen = !this.isOpen
            this.setOverlay(this.isOpen)
        },
        hidePopupAndOverlay() {
            this.isOpen = false
            if (!this.isMobileMenuActive) {
                this.setOverlay(false)
            }
        },
        hideList() {
            if (this.isOpen) {
                this.hidePopupAndOverlay()
            }
        },
    },
}
</script>

<style lang="scss">
.input-dropdown {
    position: relative;

    &--colors {
        opacity: 1;
    }

    &--open & {
        &-selected {
            &-select {
                transform: rotate(-45deg);
                margin-top: 13px;
            }
        }
    }
    @media (max-width: $screen-mobile-large) {
        width: calc(50% - 10px);
        display: flex;
        justify-content: space-between;
    }
}
</style>
