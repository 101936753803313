<template>
    <!-- eslint-disable -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="svg[formatGroup].width"
        :height="svg[formatGroup].height"
        :viewBox="svg[formatGroup].viewBox"
        class="notes-cover-image"
        :class="[
            `notes-cover-image--${formatGroup} notes-cover-image--${formatSpecific}`,
            currentPage.id === 'format'
                ? `notes-cover-image--zoom-${formatSpecific}`
                : '',
        ]"
    >
        <defs>
            <NotesCoverFrontDefs />
            <PaperPattern :background="background" />
        </defs>

        <g id="Notes" :transform="svg[formatGroup].notesTransform">
            <image
                v-if="
                    currentPage &&
                    currentPage.id !== 'paper' &&
                    formatGroup === 'vertical'
                "
                id="shadow"
                class="notes-shadow-image"
                :width="svg[formatGroup].width"
                :height="svg[formatGroup].height"
                transform="translate(568 140)"
                xlink:href="/img/notes/Notes-image.png"
            />

            <g id="notes-2" data-name="notes">
                <g transform="translate(691 259)">
                    <!-- krawędź -->
                    <g
                        id="Rectangle_1720"
                        data-name="Rectangle 1720"
                        transform="translate(4.162 0.716)"
                        stroke="#868686"
                        stroke-width="1"
                        :fill="cornerFill"
                    >
                        <rect width="262.691" height="261.576" stroke="none" />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="261.691"
                            height="260.576"
                            fill="none"
                        />
                    </g>
                    <!-- biała kartka -->
                    <g transform="translate(4 0.9)" filter="url(#b)">
                        <rect
                            width="262.691"
                            height="261.576"
                            stroke="none"
                            fill="#fff"
                            data-name="shadow_vector"
                        />
                        <image
                            href="/img/notes/marking/shadow2.png"
                            height="120"
                            width="139"
                            transform="translate(188 195) scale(0.6)"
                            opacity="0.8"
                            style="
                                mix-blend-mode: multiply;
                                isolation: isolate;
                            "
                        />
                    </g>
                </g>
                <g
                    id="Zmiana_koloru_lub_maska"
                    class="notes-border"
                    transform="translate(691 259)"
                >
                    <path
                        fill="url(#cover)"
                        class="notes-border-1"
                        d="M0,0H266.816l-.088,213.043c-12.035,10.61-51.307,36.105-65.084,48.616L0,261.817Z"
                    />
                </g>

                <clipPath id="cover-marking-square-clip-path">
                    <path
                        transform="translate(691 259)"
                        d="M0,0H266.816l-.088,213.043c-12.035,10.61-51.307,36.105-65.084,48.616L0,261.817Z"
                    />
                </clipPath>

                <Marking
                    :format="formatGroup"
                    clip-path="url(#cover-marking-square-clip-path)"
                />

                <!-- róg? -->
                <g data-name="Group 2033" transform="translate(691 255)">
                    <defs>
                        <linearGradient
                            id="notes-cmyk-gradient"
                            x1="1.082"
                            y1="0.819"
                            x2="0.269"
                            y2="0.16"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop offset="0" stop-color="#516e8a" />
                            <stop offset="0.171" stop-color="#395168" />
                            <stop offset="0.385" stop-color="#27374a" />
                            <stop offset="0.59" stop-color="#1f2c39" />
                            <stop offset="0.837" />
                            <stop offset="1" stop-color="#2e4358" />
                        </linearGradient>
                        <filter
                            id="b"
                            x="2.9"
                            y="18.58"
                            width="307.17"
                            height="286.57"
                            filterUnits="userSpaceOnUse"
                        >
                            <feOffset dx="20" dy="20" />
                            <feGaussianBlur stdDeviation="9" result="blur" />
                            <feFlood flood-opacity=".1" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>

                        <filter
                            id="e"
                            x="187.51"
                            y="192.12"
                            width="85.02"
                            height="86.83"
                            filterUnits="userSpaceOnUse"
                        >
                            <feOffset dx="1" dy="-1" />
                            <!-- <feGaussianBlur
                                stdDeviation="1.5"
                                result="blur-2"
                            /> -->
                            <!-- <feFlood flood-color="#5d5d5d" /> -->
                            <feComposite operator="in" in2="blur-2" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                        <linearGradient
                            id="f"
                            x1=".76"
                            y1=".67"
                            x2=".04"
                            y2=".09"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop offset="0" stop-color="#868686" />
                            <stop offset="1" stop-color="#434343" />
                        </linearGradient>
                        <linearGradient
                            id="h"
                            x1="1.05"
                            y1=".84"
                            x2=".46"
                            y2=".33"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop
                                offset="0"
                                stop-color="#b1b1b1"
                                stop-opacity="0"
                            />
                            <stop
                                offset=".69"
                                stop-color="#6d6d6d"
                                stop-opacity="0"
                            />
                            <stop
                                offset=".9"
                                stop-color="#040404"
                                stop-opacity=".59"
                            />
                            <stop offset="1" />
                        </linearGradient>
                        <filter
                            id="g"
                            x="187.45"
                            y="192.07"
                            width="85.24"
                            height="86.87"
                            filterUnits="userSpaceOnUse"
                        >
                            <feOffset dx="1" dy="-1" />
                            <feGaussianBlur
                                stdDeviation="1.5"
                                result="blur-3"
                            />
                            <feFlood flood-color="#5d5d5d" />
                            <feComposite operator="in" in2="blur-3" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>

                    <g
                        transform="matrix(1 .003 -.003 1 -.9 3.23)"
                        filter="url(#e)"
                    >
                        <image
                            href="/img/notes/marking/shadow.png"
                            height="120"
                            width="139"
                            transform="translate(185 190) scale(0.6)"
                            style="
                                mix-blend-mode: multiply;
                                isolation: isolate;
                            "
                        />
                        <path
                            data-name="Path 9592"
                            d="M50.73 0s2.52 4.58 7.36 22.36 3.15 30.89 3.15 30.89a113.55 113.55 0 00-27.37-.19C17.94 54.75 0 64.1 0 64.1L50.73 0z"
                            transform="rotate(-165 143.35 121.23)"
                            :fill="cornerFill"
                        />
                    </g>
                    <path
                        data-name="Path 9594"
                        d="M204.18 211.1c-8.88 26.01-3.61 54.7-3.61 54.7l7.14-5.94c-8.93-2.9-3.53-48.76-3.53-48.76z"
                        fill="#fff"
                        opacity=".04"
                    />
                    <g
                        transform="matrix(1 .003 -.003 1 -.9 3.23)"
                        filter="url(#g)"
                    >
                        <path
                            data-name="Path 10616"
                            d="M50.92 0s2.53 4.58 7.39 22.35 3.16 30.88 3.16 30.88A114.45 114.45 0 0034 53.05C18 54.73 0 64.07 0 64.07L50.92 0z"
                            transform="rotate(-165 143.44 121.22)"
                            opacity=".39"
                            fill="url(#h)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import PaperPattern from '@/components/notes/paper/PaperPattern'
import Marking from '@/components/notes/marking'
import NotesCoverFrontDefs from '@/components/notes/NotesCoverFrontDefs'
import NotesFormatMixin from '@/mixins/NotesFormatMixin'
import { mapState } from 'vuex'

export default {
    components: {
        PaperPattern,
        Marking,
        NotesCoverFrontDefs,
    },
    mixins: [NotesFormatMixin],
    data() {
        return {
            defaultBackground: '#7e7e7e',
            svg: {
                vertical: {
                    viewBox: '0 0 758 880',
                    width: '758',
                    height: '880',
                    notesTransform: 'translate(-568 -140)',
                    shadowTransform: 'matrix(1, 0, 0, 1, 568, 140)',
                    shadowPathTransform: 'translate(123 126)',
                    shadowPathD:
                        'M0,0H367a12,12,0,0,1,12,12V520a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
                horizontal: {
                    viewBox: '0 0 423 278',
                    width: '423',
                    height: '278',
                    notesTransform: 'translate(-684 -254.588)',
                    shadowTransform: 'matrix(1, 0, 0, 1, 684, 254.59)',
                    shadowPathTransform: 'translate(7 7)',
                    shadowPathD:
                        'M0,0H357a12,12,0,0,1,12,12V212a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
                square: {
                    viewBox: '0 0 532 428',
                    width: '532',
                    height: '428',
                    notesTransform: 'translate(-583 -198.273)',
                    shadowTransform: 'translate(583 198.273)',
                    shadowPathTransform: 'translate(108 64)',
                    shadowPathD:
                        'M0,0H249a12,12,0,0,1,12,12V246a12,12,0,0,1-12,12H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z',
                },
            },
        }
    },
    computed: {
        ...mapState({
            cornerFillColor: (state) => state.notes.cornerFillColor,
            configuration: (state) => state.notes.configuration,
            background: (state) =>
                state.notes.configuration.coverColorPattern?.image || null,
            currentPage: (state) => state.page.currentPage,
            patternTheme: (state) =>
                state.notes.configuration.coverColorPattern?.theme,
            doubleMarkingPrint: (state) =>
                state.notes.configuration.markingPrint?.doubleMarkingPrint,
        }),
        cornerFill() {
            // if (this.doubleMarkingPrint?.title?.pl.startsWith('CMYK')) {
            //     return 'url(#notes-cmyk-gradient)'
            // } else {
            //     return this.background ? 'url(#cover)' : this.defaultBackground
            // }
            return this.cornerFillColor
        },
    },
}
</script>

<style lang="scss" scoped>
.notes-cover-image {
    margin: 0 -50px 0 0;
    pointer-events: none;

    &--zoom {
        &-b5 {
            transform: scale(0.88, 0.825);
        }
        &-a5 {
            transform: scale(0.705, 0.71);
        }
        &-125 {
            transform: scale(0.6, 0.69);
        }
        &-a6 {
            transform: scale(0.5, 0.5);
        }
        &-84 {
            transform: scale(0.401, 0.455);
        }
    }

    &--vertical {
        .notes-shadow {
            filter: url(#vertical-shadow_vector);
        }
    }
    &--horizontal {
        .notes-shadow {
            filter: url(#horizontal-shadow_vector);
        }
    }

    &--square {
        zoom: 1.3;

        .notes-shadow {
            filter: url(#square-shadow_vector);
        }
    }

    @media (max-width: $screen-medium) {
        height: 600px;
        margin: 0 -70px 0 -30px;

        &--vertical {
            width: auto;
        }
    }
    @media (max-width: $screen-desktop) {
        &--horizontal {
            zoom: 0.8;
        }
    }
    @media (max-width: $screen-tablet-medium) {
        margin: -80px auto;

        &--horizontal {
            zoom: 1;
            margin: -120px auto -100px;
        }
    }
}
.notes-shadow-image {
    opacity: 0.8;
}
</style>
