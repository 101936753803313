<template>
    <div
        v-observe-visibility="{
            callback: (isVisible) => (isInViewport = isVisible),
            once: true,
            intersection: {
                threshold: 0.31,
            },
        }"
        :class="[
            'image-lazy',
            {
                'is-ready': isReady && isInViewport,
                'is-error': isError,
            },
        ]"
    >
        <div ref="placeholder" :style="{ height: height }">
            <div
                v-if="isBackground && imageObject"
                :style="{ backgroundImage: 'url(' + imageObject.src + ')' }"
                class="image-lazy-background"
            />

            <Loader v-if="hasLoader" :is-active="!isReady" />
        </div>
        <slot />
    </div>
</template>

<script>
import IE from '@/plugins/ie'

export default {
    props: {
        height: {
            type: [Number, String],
            required: true,
            validator: function (value) {
                return value === 'auto' || (!isNaN(value) && value > 0)
            },
        },
        src: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            required: false,
            default: '',
        },
        isBackground: {
            type: Boolean,
            required: false,
            default: false,
        },
        delay: {
            type: Number,
            required: false,
            default: 0,
        },
        hasLoader: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            imageObject: null,
            isLoaded: false,
            isError: false,
            isReady: false,
            isInViewport: false,
        }
    },

    watch: {
        isInViewport(isVisible) {
            if (isVisible && this.isLoaded) {
                this.setImageReady()
            }
        },
        isLoaded(value) {
            if (value && this.isInViewport) {
                this.setImageReady()
            }
        },
        imageUrl() {
            this.isLoaded = false
            this.isReady = false
            this.loadImage()
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.loadImage()
        })

        window.addEventListener('resize', this.onWindowResize)
    },

    destroyed() {
        window.removeEventListener('resize', this.onWindowResize)
    },

    methods: {
        onWindowResize() {
            if (this.isLoaded) {
                this.onImageLoad()
            }
        },

        loadImage() {
            this.imageObject = new Image()
            this.imageObject.onload = this.onImageLoad
            this.imageObject.onerror = this.onImageError
            this.imageObject.alt = this.alt
            this.imageObject.src = this.src
        },

        onImageLoad() {
            if (!this.isBackground && this.$refs.placeholder) {
                this.$refs.placeholder.appendChild(this.imageObject)
            }

            this.isLoaded = true
        },

        onImageError(e) {
            this.isError = true

            setTimeout(() => {
                this.isReady = true

                this.$emit('ready', false, e)
            }, this.delay)
        },

        setImageReady() {
            setTimeout(() => {
                this.isReady = true

                this.$emit('ready', true)
            }, this.delay)
        },
    },
}
</script>

<style lang="scss">
.image-lazy {
    position: relative;
    overflow: hidden;

    &-background {
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
    }

    img,
    &-background {
        transition: 0.5s;
        transform: scale(1.1);
        opacity: 0;
    }

    &.is-ready {
        img,
        .image-lazy-background {
            transform: scale(1);
            opacity: 1;
        }
    }
}
</style>
