var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"material-boxes-desktop"},[_c('a',{staticClass:"material-boxes-desktop-box material-boxes-desktop-box--standard",on:{"click":function($event){return _vm.$emit('redirect', {
                title: { pl: 'standard' },
                apiId: 'S',
                name: 'standard',
            })}}},[_c('img',{attrs:{"src":"/img/logo/z_materiałów_standardowych.svg","alt":"standard logo"}}),_c('h2',{directives:[{name:"bastards",rawName:"v-bastards"}],staticClass:"material-boxes-desktop-box-title",domProps:{"textContent":_vm._s(_vm.$t('material.standard'))}}),_c('div',{staticClass:"material-boxes-desktop-box-label"},[_vm._v(" "+_vm._s(_vm.$t('material.label'))+" ")])]),_c('a',{staticClass:"material-boxes-desktop-box material-boxes-desktop-box--fsc",on:{"click":function($event){return _vm.$emit('redirect', {
                title: { pl: 'fsc' },
                apiId: 'F',
                name: 'fsc',
            })}}},[_c('img',{attrs:{"src":"/img/logo/fsc.svg","alt":"eko logo"}}),_c('h2',{directives:[{name:"bastards",rawName:"v-bastards"}],staticClass:"material-boxes-desktop-box-title",domProps:{"innerHTML":_vm._s(_vm.$t('material.fsc'))}}),_c('div',{staticClass:"material-boxes-desktop-box-label"},[_vm._v(" "+_vm._s(_vm.$t('material.label'))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }