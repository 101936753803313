<template>
    <div class="new-password-reset">
        <div class="new-password-reset-wrapper">
            <div class="new-password-reset-header">
                <h1 class="new-password-reset-title">
                    {{ $t('form.passwordPage.passResetTitle') }}
                </h1>
                <p>{{ $t('form.passwordPage.passResetDesc') }}</p>
            </div>
            <form class="new-password-reset-form" @submit.prevent="submitForm">
                <FormFieldDefault
                    ref="password"
                    v-model="payload.password"
                    name="password"
                    type="password"
                    :label="$t('form.password')"
                    required
                    @input="$refs.checker.checkPassStrength(payload.password)"
                />
                <FormPasswordStrength ref="checker" />
                <FormFieldDefault
                    ref="password2"
                    v-model="payload.password2"
                    name="password2"
                    type="password"
                    :label="$t('form.password2')"
                    required
                />
                <FormIsRequiredInfo class="new-password-reset-form-required" />

                <button
                    class="new-password-reset-form-button send-button"
                    type="submit"
                    v-text="$t('form.passwordPage.passResetSave')"
                />
            </form>
        </div>
    </div>
</template>

<script>
import FormPasswordStrength from '@/components/form/FormPasswordStrength'
import FormFieldDefault from '@/components/form/input/FormFieldDefault'
import FormMixin from '@/mixins/FormMixin'
import FormIsRequiredInfo from '@/components/form/FormIsRequiredInfo'

export default {
    components: {
        FormFieldDefault,
        FormPasswordStrength,
        FormIsRequiredInfo,
    },
    mixins: [FormMixin],
    data() {
        return {
            payload: {
                password: null,
                password2: null,
            },
            score: 0,
            strength: '',
        }
    },
    methods: {
        submitForm() {
            console.log('submitForm')
        },
        setDefaultPayload() {
            this.payload.password = ''
            this.payload.password2 = ''
        },
    },
}
</script>

<style lang="scss">
.new-password-reset {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &-title {
        font-size: 4.8rem;

        .red {
            color: $color-burnt-sienna;
        }
    }

    &-wrapper {
        max-width: 600px;
    }

    &-form {
        margin-top: 50px;

        &-required {
            margin-left: 28%;
            margin-top: 20px;
        }
        .form-field-default {
            margin-bottom: 10px;
            margin-top: 10px;
        }
        &-strength {
            font-size: 1.3rem;
            text-align: right;
            height: 14px;

            &--strong {
                color: $color-password-strong;
            }
            &--good {
                color: $color-password-good;
            }
            &--weak {
                color: $color-password-weak;
            }
        }

        &-button {
            margin-left: auto;
            margin-top: 38px;
        }
    }

    @media (max-width: $screen-mobile-large) {
        &-wrapper {
            padding: 0 30px;
        }
        &-form {
            &-required {
                margin-left: 0;
            }
        }
    }
}
</style>
