<script>
import BasePageMixin from '@/mixins/BasePageMixin'
import NotesImage from '@/components/notes/NotesCover'
import CoverData from '@/mixins/pages/CoverData'
import { mapState } from 'vuex'

export default {
    components: {
        NotesImage,
    },
    extends: BasePageMixin,
    mixins: [CoverData],
    computed: {
        ...mapState({
            coverCorners: (state) => state.notes.configuration.coverCorners,
            coverRidge: (state) => state.notes.configuration.coverRidge,
            coverType: (state) => state.notes.configuration.coverType,
        }),
    },
    methods: {
        validate() {
            const isRidgeRequired = !['1', '2', '4'].includes(
                this.coverType?.apiId
            )
            const isRidge = isRidgeRequired ? !!this.coverRidge : true
            return this.coverCorners && this.coverType && isRidge
        },
    },
}
</script>
