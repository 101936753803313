<template>
    <div class="text-expandable">
        <div
            v-bastards
            class="text-expandable-text"
            :class="{ 'text-expandable-text--expanded': labelExpanded }"
            @click.stop="expandLabel"
        >
            <slot />
        </div>
        <ArrowIcon
            class="text-expandable-arrow"
            :class="{ 'text-expandable-arrow--rotated': labelExpanded }"
            @click.stop="expandLabel"
        />
    </div>
</template>

<script>
import ArrowIcon from '@/assets/images/system-icons/arrow-down.svg'

export default {
    components: {
        ArrowIcon,
    },
    data() {
        return {
            labelExpanded: false,
        }
    },
    methods: {
        expandLabel() {
            this.labelExpanded = !this.labelExpanded
        },
    },
}
</script>

<style lang="scss">
.text-expandable {
    position: relative;

    &-text {
        cursor: pointer;
        width: 92%;
        overflow: hidden;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        .red {
            color: $color-burnt-sienna;
        }

        &--expanded {
            overflow: visible;
            display: block;
        }
    }
    &-arrow {
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
        transition: 0.2s;

        &--rotated {
            transform: rotate(180deg);
        }
    }
}
</style>
