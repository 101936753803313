<template>
    <div :class="['loader-page', { 'is-active': isActive }]">
        <div class="loader-page-body">
            <SvgSpinner v-if="isLoading" class="loader-page-spinner">
                <img src="/img/logo/sygnet.png" width="35" height="38" alt="Mindnotes ..." />
            </SvgSpinner>

            <div v-if="text" class="loader-page-text" v-html="text" />

            <div v-if="!isLoading && !text" v-html="$t('loader.error.general')" />

            <div v-if="!isLoading" class="loader-page-actions">
                <button class="loader-page-actions-close-modal" @click.prevent="onClose">
                    &times;
                </button>
                <button class="loader-page-actions-close-inline" @click.prevent="onClose">
                    {{ $t('loader.close') }}
                </button>
            </div>
        </div>
        <div class="loader-page-overlay" />
    </div>
</template>

<script>

import SvgSpinner from '@/components/svg/SvgSpinner.vue'

export default {
    components: {SvgSpinner},
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        }
    },
    methods: {
        onClose() {
            this.$emit('close')
        }
    }

}
</script>

<style lang="scss">
.loader-page {
    @include positionFixedFit();
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    @include hide();
    transition: 0.3s;

    &.is-active {
        @include show();
    }

    &-overlay {
        @include positionAbsoluteFit();
        z-index: 1;

        background: rgba(0, 0, 0, 0.5);
    }

    &-body {
        position: relative;
        z-index: 2;
        background: white;
        width: 90%;
        line-height: 1.5;
        font-size: 20px;
        max-width: 517px;
        padding: 50px 40px;
        text-align: center;
        // min-height: 265px;

        strong {
            font-weight: 700;
        }

        @media (max-width: $screen-tablet-large) {
            font-size: 15px;
            padding: 50px 20px 25px;
        }
    }


    &-spinner {

        margin-top: -15px;


    }

    &-actions {
        button {
            outline: none;
        }
        &-close-modal {
            @include positionAbsoluteTopRight();
            border: none;
            background: $color-burnt-sienna;
            color: white;
            width: 1.2em;
            height: 1.2em;
            font-size: 2em;
            font-weight: 300;
            cursor: pointer;
            transition: 0.3s background-color;
            &:hover {
                background: $color-burnt-sienna-light;
            }
        }
        &-close-inline {
            border: 1px solid #CCCCCC;
            padding: 0.75em 1em;
            background: #fff;
            margin: 20px auto -30px;
            cursor: pointer;
            transition: 0.3s background-color;
            &:hover {
                background-color: #fafafa;
            }
        }
    }

}

</style>
