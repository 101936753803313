<template>
    <div
        v-if="
            searchPhrase &&
            searchPhrase.length >= 3 &&
            isModalOpen &&
            searchTipsPatterns &&
            searchTipsPatterns.length
        "
        v-click-outside="hideTips"
        class="input-search-tips"
    >
        <div
            v-for="(tip, index) in searchTipsPatterns"
            :key="index"
            class="input-search-tips-tip"
            @click="searchFor(tip.title[locale])"
            v-html="tip.searchTitle"
        />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ClickOutside from 'vue-click-outside'

export default {
    directives: {
        ClickOutside,
    },
    props: {
        patterns: {
            type: Object,
            default: () => {},
        },
        coverType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isModalOpen: false,
        }
    },
    computed: {
        ...mapState({
            locale: (state) => state.i18n.locale,
            searchPhrase: (state) => state.filters.search,
        }),
        searchTipsPatterns() {
            if (!String.prototype.splice) {
                String.prototype.splice = function (
                    start,
                    delCount,
                    newSubStr
                ) {
                    return (
                        this.slice(0, start) +
                        newSubStr +
                        this.slice(start + Math.abs(delCount))
                    )
                }
            }
            let patterns = this.patterns[this.coverType]

            patterns = patterns.filter((pattern) => {
                return (
                    pattern.title[this.locale]
                        .toLowerCase()
                        .indexOf(this.searchPhrase.toLowerCase()) !== -1
                )
            })

            const searchRegex = new RegExp(this.searchPhrase, 'gi')

            // color search phrase
            patterns.forEach((el) => {
                let i = el.title[this.locale].search(searchRegex)
                if (i > -1) {
                    const length = this.searchPhrase.length
                    el.searchTitle = el.title[this.locale]
                        .splice(i, 0, '<span>')
                        .splice(i + length + 6, 0, '</span>')
                }
            })

            // remove the same
            // some magic from stack >> https://stackoverflow.com/a/36744732
            patterns = patterns.filter(
                (pattern, index, self) =>
                    index ===
                    self.findIndex(
                        (t) =>
                            t.title[this.locale] === pattern.title[this.locale]
                    )
            )

            return patterns
        },
    },
    methods: {
        ...mapMutations(['setSearch']),
        searchFor(title) {
            this.setSearch(title)
            this.$emit('select', title)
            this.hideTips()
        },
        hideTips() {
            this.isModalOpen = false
        },
    },
}
</script>

<style lang="scss">
.input-search-tips {
    position: absolute;
    top: 52px;
    left: 0px;
    background: $color-white;
    z-index: 10;
    padding: 10px 20px;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid $color-silver;

    &-tip {
        padding: 10px 0;
        cursor: pointer;

        span {
            background-color: yellow;
        }
    }
    @media (max-width: $screen-medium) {
        top: 99px;
    }
    @media (max-width: $screen-tablet-medium) {
        top: 60px;
        left: 0;
        z-index: 5;
    }
    @media (max-width: $screen-mobile-large) {
        display: none;
    }
}
</style>
