<template>
    <image
        :href="base64[image[format].url]"
        :transform="image[format].transform"
    />
</template>

<script>
import ConvertToBase64 from '@/mixins/ConvertToBase64'

export default {
    mixins: [ConvertToBase64],
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            image: {
                a4: {
                    url: '/img/notes/inside/standard/dots-vertical-small.png',
                    transform: 'translate(34 13)',
                },
                '205': {
                    url: '/img/notes/inside/standard/dots-horizontal-small.png',
                    transform: 'translate(10 5)',
                },
                '175': {
                    url: '/img/notes/inside/standard/dots-square-small.png',
                    transform: 'translate(18 15)',
                },
                b5: {
                    url: '/img/notes/inside/standard/b5/dots-b5-small.png',
                    transform: 'translate(18 23)',
                },
                a5: {
                    url: '/img/notes/inside/standard/a5/dots-a5-small.png',
                    transform: 'translate(24 28)',
                },
                a6: {
                    url: '/img/notes/inside/standard/a6/dots-a6-small.png',
                    transform: 'translate(5 22)',
                },
                '125': {
                    url: '/img/notes/inside/standard/125/dots-125-small.png',
                    transform: 'translate(22 20)',
                },
                '84': {
                    url: '/img/notes/inside/standard/84/dots-84-small.png',
                    transform: 'translate(11 21)',
                },
            },
        }
    },

    created() {
        Object.keys(this.image).forEach((format) => {
            this.toDataUrl(this.image[format].url)
        })
    },
}
</script>
