<template>
    <div v-show="isOpen" class="input-dropdown-options">
        <div class="input-dropdown-options-title">
            <span>{{ title }}</span>
            <div
                class="input-dropdown-options-title-close"
                @click="selectOption(null)"
            >
                &times;
            </div>
        </div>
        <ul v-if="!theme" class="input-dropdown-options-list">
            <li
                v-for="option in data"
                :key="option.title[locale]"
                class="input-dropdown-options-list-element"
                @click="selectOption(option)"
                v-text="option.title[locale]"
            />
        </ul>
        <ListColors
            v-else-if="theme === 'colors'"
            :colors="data"
            :selected="selected"
            @select="selectOption"
        />
    </div>
</template>

<script>
import ListColors from '@/components/list/ListColors'
import { mapState } from 'vuex'

export default {
    components: {
        ListColors,
    },
    props: {
        theme: {
            type: String,
            default: '',
        },
        data: {
            type: Object,
            default: () => {},
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Object,
            default: () => {},
        },
        title: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState({
            locale: (state) => state.i18n.locale,
        }),
    },
    methods: {
        selectOption(option) {
            this.$emit('select-option', option)
        },
    },
}
</script>

<style lang="scss">
.input-dropdown-options {
    position: absolute;
    top: 94px;
    right: 0;
    width: 315px;

    &-title {
        display: none;
    }

    &-list {
        list-style-type: none;
        max-height: 460px;
        overflow-y: auto;
        user-select: none;
        box-shadow: 0 3px 26px rgba(0, 0, 0, 0.16);
        z-index: 99;
        position: relative;
        background: $color-white;

        &-element {
            @include size(100%, 64px);
            border: solid $color-silver;
            border-width: 1px 1px 0 1px;
            font-size: 1.6rem;
            line-height: 3.5rem;
            padding-left: 28px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:first-child {
                border-top-width: 0;
            }
            &:last-child {
                border-bottom-width: 1px;
            }
            &:hover {
                background-color: $color-bg-light;
            }
        }
    }

    @media (max-width: $screen-large) {
        width: 270px;

        &-list {
            max-height: 330px;
        }
    }

    @media (max-width: $screen-medium) {
        width: 180px;
        top: 60px;
        right: unset;
    }

    @media (max-width: $screen-tablet-medium) {
        width: 360px;
        z-index: 9;
        position: fixed;
        top: 25%;
        left: calc(50% - 180px);

        &-title {
            @include size(100%, 50px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: $color-bg-light;
            padding-left: 24px;
            border: solid $color-silver;
            border-width: 1px 1px 0 1px;

            &-close {
                font-size: 4rem;
                line-height: 0;
                padding: 0 17px;
            }
        }

        &-list {
            z-index: 7;
            border-width: 1px;
            max-height: unset;

            &-element {
                padding-left: 10px;
                height: 55px;

                &:first-child {
                    border-top-width: 1px;
                }
            }
        }
    }

    @media (max-width: $screen-mobile-large) {
        width: 320px;
        left: calc(50% - 160px);
        top: 15%;
        z-index: 7;
    }
}
</style>
