<template>
    <div class="form-password-strength">
        <div
            v-if="strength === 'strong'"
            class="form-password-strength--strong"
        >
            {{ $t('form.passwordPage.passStrong') }}
        </div>
        <div v-if="strength === 'good'" class="form-password-strength--good">
            {{ $t('form.passwordPage.passGood') }}
        </div>
        <div v-if="strength === 'weak'" class="form-password-strength--weak">
            {{ $t('form.passwordPage.passWeak') }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            score: 0,
            strength: '',
        }
    },
    methods: {
        scorePassword(pass) {
            // https://stackoverflow.com/a/11268104
            let score = 0
            if (!pass) {
                return score
            }

            // award every unique letter until 5 repetitions
            let letters = new Object()
            for (var i = 0; i < pass.length; i++) {
                letters[pass[i]] = (letters[pass[i]] || 0) + 1
                score += 5.0 / letters[pass[i]]
            }

            // bonus points for mixing it up
            const variations = {
                digits: /\d/.test(pass),
                lower: /[a-z]/.test(pass),
                upper: /[A-Z]/.test(pass),
                nonWords: /\W/.test(pass),
            }

            let variationCount = 0
            for (var check in variations) {
                variationCount += variations[check] == true ? 1 : 0
            }
            score += (variationCount - 1) * 10
            this.score = score

            return parseInt(score)
        },
        checkPassStrength(pass) {
            const score = this.scorePassword(pass)
            if (!score) {
                this.strength = ''
                return
            }
            if (score > 80) {
                return (this.strength = 'strong')
            }
            if (score > 60) {
                return (this.strength = 'good')
            }
            if (score > 10) {
                return (this.strength = 'weak')
            }
            return ''
        },
    },
}
</script>

<style lang="scss">
.form-password-strength {
    font-size: 1.3rem;
    text-align: right;
    height: 14px;

    &--strong {
        color: $color-password-strong;
    }
    &--good {
        color: $color-password-good;
    }
    &--weak {
        color: $color-password-weak;
    }
}
</style>
