var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.coverType === 'okleina')?_c('div',{staticClass:"filter-paper"},[_c('div',{staticClass:"filter-paper-filters"},[_c('div',{staticClass:"filter-paper-filters-title"},[_vm._v(" "+_vm._s(_vm.$t('paper.filter'))+" ")]),_c('InputDropdown',{attrs:{"name":_vm.colorTitle,"data":_vm.patternColors,"theme":"colors","title":_vm.$t('paper.color')},on:{"selected":_vm.setColor}}),_c('InputDropdown',{attrs:{"name":_vm.categoryTitle,"data":_vm.patternCategories,"title":_vm.$t('paper.category')},on:{"selected":_vm.setCategory}})],1),_c('div',{staticClass:"filter-paper-search filter-paper-search--tablet"},[_c('form',{class:[
                'filter-paper-search-form',
                {
                    'filter-paper-search-form-smaller':
                        _vm.coverType === 'papier',
                },
            ],on:{"submit":function($event){$event.preventDefault();}}},[_c('InputSearch',{ref:"search",attrs:{"placeholder":_vm.$t('paper.search'),"theme":"tablet"},on:{"input":_vm.search}}),_c('InputSearchTips',{ref:"tips",attrs:{"patterns":_vm.patterns,"cover-type":_vm.coverType,"search-input":_vm.$refs.search}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }