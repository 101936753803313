<template>
    <div
        class="offer-printing"
        :class="{ 'offer-printing--agency': userType === 'agency' }"
    >
        <TransitionGroup name="list" tag="div" class="offer-printing-list">
            <OfferPrintingRow
                v-for="(row, index) in list"
                :key="row.id"
                :index="index"
                :quantity="row.quantity"
                :prices="sortedPrices"
                :is-last="list.length < 2"
                @remove="removePrint(row.id)"
                @input="(value) => row.quantity = Number(value)"
>
            </OfferPrintingRow>
        </TransitionGroup>

        <div class="offer-printing-action">
            <button v-if="canAddPrintingRow" @click="addPrint">
                <small>{{ $t('offer.addPrints') }}</small>
                <span class="offer-printing-row-button offer-printing-row-button--plus">
                    +
                </span>
            </button>
        </div>



        <TextExpandable class="offer-printing-text">
            {{ $t('offer.legalInfo') }}
        </TextExpandable>
        <div class="offer-printing-line" />
    </div>
</template>

<script>
import TextExpandable from '@/components/text/TextExpandable'
import OfferPrintingRow from '@/components/offer/OfferPrintingRow'
import { mapState } from 'vuex'

export default {
    components: {
        TextExpandable,
        OfferPrintingRow,
    },
    data() {
        return {
            number: 1,
            limit: 5,
        }
    },
    computed: {
        ...mapState({
            userType: (state) => state.user.userType,
            prices: (state) => state.valuation.valuation?.prices,
            volumes: (state) => state.valuation.volumes
        }),
        canAddPrintingRow() {
            return this.list.length < this.limit
        },
        sortedPrices() {
            if (this.prices) {
                const computedPrices = {}
                this.prices.forEach((priceObject) => {
                    if (!computedPrices.hasOwnProperty(priceObject.quantity)) {
                        computedPrices[priceObject.quantity] = []
                    }
                    computedPrices[priceObject.quantity].push(priceObject)
                })
                return computedPrices
            }
            return {}
        },
        list: {
            get: function() { return this.volumes },
            set: function(value) {
                this.$store.commit('setValuationValues', value)
            }
        }
    },
    mounted() {
        if (!this.list.length) {
            this.addPrint()
        }
    },
    methods: {
        addPrint() {
            if (this.list.length < this.limit) {
                this.$store.commit('addVolume', this.number++)
            }
        },
        removePrint(id) {
            this.$store.commit('removeVolume', id)
        },
    },
}
</script>

<style lang="scss">
.offer-printing {
    margin-bottom: 40px;
    margin-top: 30px;

    &-line {
        @include size(100%, 1px);
        background-color: $color-dark-gray;
        margin-top: 40px;
    }

    &-text {
        font-size: 1.3rem;
    }

    &-action {
        display: flex;
        justify-content: right;
        width: 100%;
        margin-bottom: 20px;

        button {
            cursor: pointer;
            border: 0;
            background: none;
            outline: none;
            box-shadow: none;
            display: flex;
            align-items: center;
            > span {
               margin-left: 8px;
            }
        }
    }

    @media (min-width: $screen-tablet-medium) and (max-width: $screen-large) {
        &--agency & {
            &-list {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
            }
        }
    }

    @media (min-width: $screen-tablet-medium) and (max-width: $screen-desktop) {
        &-list {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;
        }
    }

    @media (min-width: $screen-tablet-medium) and (max-width: $screen-desktop) {
        &-list {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;
        }
    }

    // mobile
    // again wrap for agency
    @media (max-width: $screen-tablet-small) {
        &--agency & {
            &-list {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
            }
        }
    }
    // again wrap for other
    @media (max-width: $screen-mobile-landscape) {
        &-list {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;
        }
    }

    @media (max-width: $screen-mobile-landscape) {
        width: 100%;
    }
}

.list-enter-active,
.list-leave-active {
    transition: all 0.2s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(-30px);
}
</style>
