<script>
import BasePageMixin from '@/mixins/BasePageMixin'
import NotesImage from '@/components/notes/NotesCover'
import FlyleafData from '@/mixins/pages/FlyleafData'
import { mapState } from 'vuex'

export default {
    components: {
        NotesImage,
    },
    extends: BasePageMixin,
    mixins: [FlyleafData],
    computed: {
        ...mapState({
            flyleaf: (state) => state.notes.configuration.flyleaf,
            flyleafPaper: (state) => state.notes.configuration.flyleafPaper,
        }),
    },
    methods: {
        validate() {
            const isFlyleafPaper = this.flyleafPaper
            const isFlyleaf = this.flyleaf
                ? this.flyleaf.general
                    ? this.flyleaf.singleFlyleafPrint ||
                      this.flyleaf.doubleFlyleafPrint
                    : true
                : false

            return isFlyleaf && isFlyleafPaper
        },
    },
}
</script>
