<template>
    <div class="page-register-agency">
        <h1 class="page-title">
            {{ $t('form.register.title') }}
        </h1>
        <p class="page-register-agency-description">
            Podaj swój e-mail, sprawdzimy czy znajduje się w bazie Promonotes.
            Jeśli nie znajdziemy Cię w naszej bazie poprosimy byś wypełnił dane
            potrzebne do realizacji zamówień.
        </p>
        <form class="page-register-agency-form" @submit.prevent="sendForm">
            <FormFieldDefault
                ref="email"
                v-model="email"
                name="email"
                type="email"
                :theme="['wide', result]"
                :label="$t('form.email')"
                required
            />
            <div
                v-if="result"
                class="page-register-agency-form-result"
                :class="{
                    'page-register-agency-form-result--success':
                        result === 'success',
                    'page-register-agency-form-result--fail': result === 'fail',
                }"
            >
                {{ message[result] }}
            </div>
            <button
                v-if="result !== 'fail'"
                class="login-button page-register-agency-form-button"
                type="submit"
            >
                Sprawdź e-mail
            </button>
        </form>
        <FormRegisterAgency v-if="result === 'fail'" />
    </div>
</template>

<script>
import FormFieldDefault from '@/components/form/input/FormFieldDefault'
import FormRegisterAgency from '@/components/form/FormRegisterAgency'
import FormMixin from '@/mixins/FormMixin'

export default {
    components: {
        FormFieldDefault,
        FormRegisterAgency,
    },
    mixins: [FormMixin],
    data() {
        return {
            message: {
                success:
                    'Twój e-mail istnieje już w bazie klientów Promonotes.',
                fail:
                    'Twojego maila nie  ma w bazie Promonotes. Uzupełnij dane poniżej.',
            },
            email: '',
            result: 'fail', //success or fail
        }
    },
    methods: {
        setDefaultPayload() {
            this.email = ''
            this.result = ''
        },
        sendForm() {
            // mock send form
            if (this.validation()) {
                setTimeout(() => {
                    this.result = 'fail'
                    this.inProcess = false
                }, 500)
            }
        },
    },
}
</script>

<style lang="scss">
.page-register-agency {
    max-width: 600px;
    margin: 0 auto;

    &-description {
        font-size: 2rem;
    }

    &-form {
        margin-top: 40px;

        &-result {
            border: 1px solid $color-border-input-darker;
            padding: 14.5px 23.5px;
            font-size: 16px;
            transition: 0.2s;

            &--success {
                background-color: rgba($color-success2, 0.1);
                border-color: $color-success2;
            }

            &--fail {
                background-color: #f5f5f5;
                border-color: rgba(#2d2e2f, 0.45);
            }
        }
        &-button {
            margin-left: auto;
            margin-top: 35px;
            padding: 12px 25px;
        }
    }

    @media (max-width: $screen-tablet-small) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (max-width: $screen-mobile-large) {
        padding-top: 50px;
    }
}
</style>
