<template>
    <div class="option-list">
        <h1 class="page-title" v-text="title" />
        <div class="option-list-items">
            <slot name="list" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss">
.option-list {
    max-width: 468px;
    margin: auto;
    width: 100%;

    &-items {
        margin-top: 25px;
    }

    @media (max-width: $screen-medium) {
        width: 100%;
    }
    @media (max-width: $screen-tablet-medium) {
        max-width: none;
        width: 320px;

        &-items {
            margin: 0 auto;
        }
    }
    @media (max-width: $screen-mobile-large) {
        width: 100%;
        margin: 20px;
    }
}
</style>
