import ApiService from '@/api/ApiService'

const Api = {
    config: {
        env: ''
    },
    envsAvailable: {},
    services: [],
    servicesContracts: [],

    defineServices (arrServicesContracts) {
        this.servicesContracts = this.servicesContracts.concat(arrServicesContracts)
    },

    getService (name) {
        if (!this.isConfigured()) {
            throw new Error(`
                [Api] The Api configuration is not finished.
                You need to specify at least the runMode parameter of configuration object.
                Maybe forgot to use Api.setConfig(objConfig)?
            `)
        }

        const service = this.services.filter(service => service.getName() === name)

        if (!service || service.length !== 1) {
            throw new Error(`Service '${name}' is not defined in 'api/config/services.js'`)
        } else {
            return service[0]
        }
    },

    init () {
        this.servicesContracts.forEach((serviceContract) => {
            const service = new ApiService(serviceContract, this.envsAvailable, this.config.env)

            this.services.push(service)
        })

        return this
    },

    isConfigured () {
        return this.config.env.length > 0 && this.envsAvailable.hasOwnProperty(this.config.env)
    },

    setEnv (objEnv) {
        Object.assign(this.envsAvailable, objEnv)
    },

    setConfig (objConfig) {
        this.config.env = objConfig.env || ''
    }
}

Object.seal(Api.config)

export default Api
