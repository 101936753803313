const state = {
    color: null,
    category: null,
    search: null,
}

const mutations = {
    setColor(state, color) {
        state.color = color
    },
    setCategory(state, category) {
        state.category = category
    },
    setSearch(state, search) {
        state.search = search
    },
}

export default {
    state,
    mutations,
}
