export const countries = [
    {
        code: 'Afghanistan',
        i18n: {
            de: 'Afghanistan',
            fr: 'Afghanistan',
        },
    },
    {
        code: 'Albania',
        i18n: { de: 'Albanien', fr: 'Albanie' },
    },
    {
        code: 'Algeria',
        i18n: { de: 'Algerien', fr: 'Algérie' },
    },
    {
        code: 'American Samoa',
        i18n: {
            de: 'Amerikanischen Samoa-Inseln',
            fr: 'Samoa américaines',
        },
    },
    {
        code: 'Andorra',
        i18n: { de: 'Andorra', fr: 'Andorre' },
    },
    {
        code: 'Angola',
        i18n: { de: 'Angola', fr: 'Angola' },
    },
    {
        code: 'Anguilla',
        i18n: {
            de: 'Anguilla',
            fr: 'Anguilla',
        },
    },
    {
        code: 'Antarctica',
        i18n: {
            de: 'Antarktis',
            fr: 'Antarctique',
        },
    },
    {
        code: 'Arabia Saudyjska',
        i18n: {
            de: 'Arabien Saudyjska',
            fr: 'Arabie Saoudjska',
        },
    },
    {
        code: 'Argentina',
        i18n: {
            de: 'Argentinien',
            fr: 'Argentine',
        },
    },
    {
        code: 'Armenia',
        i18n: { de: 'Armenien', fr: 'Arménie' },
    },
    {
        code: 'Aruba',
        i18n: { de: 'Aruba', fr: 'Aruba' },
    },
    {
        code: 'Australia',
        i18n: {
            de: 'Australien',
            fr: 'Australie',
        },
    },
    {
        code: 'Austria',
        offer: true,
        i18n: {
            de: 'Österreich',
            fr: "L'Autriche",
        },
    },
    {
        code: 'Azerbaijan',
        i18n: {
            de: 'Aserbaidschan',
            fr: 'Azerbaïdjan',
        },
    },
    {
        code: 'Bahamas',
        i18n: { de: 'Bahamas', fr: 'Bahamas' },
    },
    {
        code: 'Bahrain',
        i18n: { de: 'Bahrein', fr: 'Bahreïn' },
    },
    {
        code: 'Bangladesh',
        i18n: {
            de: 'Bangladesch',
            fr: 'Bengladesh',
        },
    },
    {
        code: 'Barbados',
        i18n: { de: 'Barbados', fr: 'Barbade' },
    },
    {
        code: 'Belarus',
        i18n: {
            de: 'Weißrussland',
            fr: 'Biélorussie',
        },
    },
    {
        code: 'Belgium',
        offer: true,
        i18n: { de: 'Belgien', fr: 'Belgique' },
    },
    {
        code: 'Belize',
        i18n: { de: 'Belize', fr: 'Bélize' },
    },
    {
        code: 'Benin',
        i18n: { de: 'Benin', fr: 'Bénin' },
    },
    {
        code: 'Bermuda',
        i18n: { de: 'Bermudas', fr: 'Bermudes' },
    },
    {
        code: 'Bhutan',
        i18n: { de: 'Bhutan', fr: 'Bhoutan' },
    },
    {
        code: 'Bolivia',
        i18n: { de: 'Bolivien', fr: 'Bolivie' },
    },
    {
        code: 'Bonaire',
        i18n: { de: 'Bonaire', fr: 'Bonaire' },
    },
    {
        code: 'Bosnia and Herzegovina',
        i18n: {
            de: 'Bosnien und Herzegowina',
            fr: 'Bosnie Herzégovine',
        },
    },
    {
        code: 'Botswana',
        i18n: {
            de: 'Botswana',
            fr: 'Bostwana',
        },
    },
    {
        code: 'Bouvet Island',
        i18n: {
            de: 'Bouvet-Insel',
            fr: 'Île Bouvet',
        },
    },
    {
        code: 'Brazil',
        i18n: { de: 'Brasilien', fr: 'Brésil' },
    },
    {
        code: 'British Indian Ocean Territory',
        i18n: {
            de: 'Britisches Territorium des Indischen Ozeans',
            fr: "Territoire britannique de l'océan Indien",
        },
    },
    {
        code: 'Brunei Darussalam',
        i18n: {
            de: 'Brunei Darussalam',
            fr: 'Brunei Darussalam',
        },
    },
    {
        code: 'Bulgaria',
        i18n: {
            de: 'Bulgarien',
            fr: 'Bulgarie',
        },
    },
    {
        code: 'Burkina Faso',
        i18n: {
            de: 'Burkina Faso',
            fr: 'Burkina Faso',
        },
    },
    {
        code: 'Burundi',
        i18n: { de: 'Burundi', fr: 'Burundi' },
    },
    {
        code: 'Cambodia',
        i18n: {
            de: 'Kambodscha',
            fr: 'Cambodge',
        },
    },
    {
        code: 'Cameroon',
        i18n: { de: 'Kamerun', fr: 'Cameroun' },
    },
    {
        code: 'Canada',
        i18n: { de: 'Kanada', fr: 'Canada' },
    },
    {
        code: 'Canary Islands',
        i18n: {
            de: 'Kanarische Inseln',
            fr: 'les îles Canaries',
        },
    },
    {
        code: 'Cape Verde',
        i18n: {
            de: 'Kap Verde',
            fr: 'Cap-Vert',
        },
    },
    {
        code: 'Cayman Islands',
        i18n: {
            de: 'Cayman Inseln',
            fr: 'Îles Caïmans',
        },
    },
    {
        code: 'Central African Republic',
        i18n: {
            de: 'Zentralafrikanische Republik',
            fr: 'République centrafricaine',
        },
    },
    {
        code: 'Ceuta',
        i18n: { de: 'Ceuta', fr: 'Ceuta' },
    },
    {
        code: 'Chad',
        i18n: { de: 'Tschad', fr: 'Tchad' },
    },
    {
        code: 'Chile',
        i18n: { de: 'Chile', fr: 'Chili' },
    },
    {
        code: 'China',
        i18n: { de: 'China', fr: 'Chine' },
    },
    {
        code: 'Christmas Islands',
        i18n: {
            de: 'Weihnachtsinseln',
            fr: 'Îles de Noël',
        },
    },
    {
        code: 'Cocos (Keeling) Islands',
        i18n: {
            de: 'Kokosinseln (Keelinginseln).',
            fr: 'Îles Cocos (Keeling)',
        },
    },
    {
        code: 'Colombia',
        i18n: {
            de: 'Kolumbien',
            fr: 'Colombie',
        },
    },
    {
        code: 'Comoros',
        i18n: { de: 'Komoren', fr: 'Comores' },
    },
    {
        code: 'Congo (Rep.)',
        i18n: {
            de: 'Kongo (Rep.)',
            fr: 'Congo (Rép.)',
        },
    },
    {
        code: 'Cook Islands',
        i18n: {
            de: 'Cookinseln',
            fr: 'les Îles Cook',
        },
    },
    {
        code: 'Costa Rica',
        i18n: {
            de: 'Costa Rica',
            fr: 'Costa Rica',
        },
    },
    {
        code: 'Cote D’Ivoire',
        i18n: {
            de: 'Elfenbeinküste',
            fr: "Côte d'Ivoire",
        },
    },
    {
        code: 'Croatia',
        i18n: { de: 'Kroatien', fr: 'Croatie' },
    },
    { code: 'Cuba', i18n: { de: 'Kuba', fr: 'Cuba' } },
    {
        code: 'Curaao',
        i18n: { de: 'Curaao', fr: 'Curaão' },
    },
    {
        code: 'Curaçao',
        i18n: { de: 'Curacao', fr: 'Curacao' },
    },
    {
        code: 'Cyprus',
        i18n: { de: 'Zypern', fr: 'Chypre' },
    },
    {
        code: 'Czech Republic',
        offer: true,
        i18n: {
            de: 'Tschechien',
            fr: 'République tchèque',
        },
    },
    {
        code: 'Denmark',
        offer: true,
        i18n: { de: 'Dänemark', fr: 'Danemark' },
    },
    {
        code: 'Djibouti',
        i18n: {
            de: 'Dschibuti',
            fr: 'Djibouti',
        },
    },
    {
        code: 'Dominica',
        i18n: {
            de: 'Dominika',
            fr: 'Dominique',
        },
    },
    {
        code: 'Dominican Republic',
        i18n: {
            de: 'Dominikanische Republik',
            fr: 'République dominicaine',
        },
    },
    {
        code: 'Ecuador',
        i18n: { de: 'Ecuador', fr: 'Equateur' },
    },
    {
        code: 'Egypt',
        i18n: { de: 'Ägypten', fr: 'Egypte' },
    },
    {
        code: 'EI Salvador',
        i18n: {
            de: 'EI Salvador',
            fr: 'IE Salvador',
        },
    },
    {
        code: 'Equatorial Guinea',
        i18n: {
            de: 'Äquatorialguinea',
            fr: 'Guinée Équatoriale',
        },
    },
    {
        code: 'Eritrea',
        i18n: { de: 'Eritrea', fr: 'Érythrée' },
    },
    {
        code: 'Estonia',
        offer: true,
        i18n: { de: 'Estland', fr: 'Estonie' },
    },
    {
        code: 'Ethiopia',
        i18n: {
            de: 'Äthiopien',
            fr: 'Ethiopie',
        },
    },
    {
        code: 'Falkland Islands (Malvinas)',
        i18n: {
            de: 'Falklandinseln (Malvinas)',
            fr: 'Îles Falkland (Malouines)',
        },
    },
    {
        code: 'Faroe Islands',
        i18n: {
            de: 'Färöer Inseln',
            fr: 'Îles Féroé',
        },
    },
    {
        code: 'Fiji',
        i18n: { de: 'Fidschi', fr: 'Fidji' },
    },
    {
        code: 'Finland',
        offer: true,
        i18n: {
            de: 'Finnland',
            fr: 'Finlande',
        },
    },
    {
        code: 'France',
        offer: true,
        i18n: { de: 'Frankreich', fr: 'France' },
    },
    {
        code: 'French Guiana',
        i18n: {
            de: 'Französisch-Guayana',
            fr: 'Guyane Française',
        },
    },
    {
        code: 'French Polynesia',
        i18n: {
            de: 'Französisch Polynesien',
            fr: 'Polynésie française',
        },
    },
    {
        code: 'French Southern Territories',
        i18n: {
            de: 'Südfranzösische Territorien',
            fr: 'Terres australes françaises',
        },
    },
    {
        code: 'Gabon',
        i18n: { de: 'Gabun', fr: 'Gabon' },
    },
    {
        code: 'Gambia',
        i18n: { de: 'Gambia', fr: 'Gambie' },
    },
    {
        code: 'Georgia',
        i18n: { de: 'Georgia', fr: 'Géorgie' },
    },
    {
        code: 'Germany',
        offer: true,
        i18n: {
            de: 'Deutschland',
            fr: 'Allemagne',
        },
    },
    {
        code: 'Ghana',
        i18n: { de: 'Ghana', fr: 'Ghana' },
    },
    {
        code: 'Gibraltar',
        i18n: {
            de: 'Gibraltar',
            fr: 'Gibraltar',
        },
    },
    {
        code: 'Greece',
        offer: true,
        i18n: { de: 'Griechenland', fr: 'Grèce' },
    },
    {
        code: 'Greenland',
        i18n: {
            de: 'Grönland',
            fr: 'Groenland',
        },
    },
    {
        code: 'Grenada',
        i18n: { de: 'Grenada', fr: 'Grenade' },
    },
    {
        code: 'Guadeloupe Guam',
        i18n: {
            de: 'Guadeloupe Guam',
            fr: 'GuadeloupeGuam',
        },
    },
    {
        code: 'Guatemala',
        i18n: {
            de: 'Guatemala',
            fr: 'Guatemala',
        },
    },
    {
        code: 'Guinea',
        i18n: { de: 'Guinea', fr: 'Guinée' },
    },
    {
        code: 'Guinea-Bissau',
        i18n: {
            de: 'Guinea-Bissau',
            fr: 'Guinée-Bissau',
        },
    },
    {
        code: 'Guyana',
        i18n: { de: 'Guyana', fr: 'Guyane' },
    },
    {
        code: 'Haiti',
        i18n: { de: 'Haiti', fr: 'Haïti' },
    },
    {
        code: 'Heard and McDonald Islands',
        i18n: {
            de: 'Heard- und McDonald-Inseln',
            fr: 'Îles Heard et McDonald',
        },
    },
    {
        code: 'Honduras',
        i18n: {
            de: 'Honduras',
            fr: 'Honduras',
        },
    },
    {
        code: 'Hong Kong',
        i18n: {
            de: 'Hongkong',
            fr: 'Hong Kong',
        },
    },
    {
        code: 'Hungary',
        offer: true,
        i18n: { de: 'Ungarn', fr: 'Hongrie' },
    },
    {
        code: 'Iceland',
        i18n: { de: 'Island', fr: 'Islande' },
    },
    {
        code: 'India',
        i18n: { de: 'Indien', fr: 'Inde' },
    },
    {
        code: 'Indonesia',
        i18n: {
            de: 'Indonesien',
            fr: 'Indonésie',
        },
    },
    {
        code: 'Iran',
        i18n: { de: 'Iran', fr: "L'Iran" },
    },
    { code: 'Iraq', i18n: { de: 'Irak', fr: 'Irak' } },
    {
        code: 'Ireland',
        offer: true,
        i18n: { de: 'Irland', fr: 'Irlande' },
    },
    {
        code: 'Israel',
        i18n: { de: 'Israel', fr: 'Israël' },
    },
    {
        code: 'Italy',
        offer: true,
        i18n: { de: 'Italien', fr: 'Italie' },
    },
    {
        code: 'Jamaica',
        i18n: { de: 'Jamaika', fr: 'Jamaïque' },
    },
    {
        code: 'Japan',
        i18n: { de: 'Japan', fr: 'Japon' },
    },
    {
        code: 'Jordan',
        i18n: { de: 'Jordanien', fr: 'Jordan' },
    },
    {
        code: 'Kazakhstan',
        i18n: {
            de: 'Kasachstan',
            fr: 'Kazakhstan',
        },
    },
    {
        code: 'Kenya',
        i18n: { de: 'Kenia', fr: 'Kenya' },
    },
    {
        code: 'Kiribati',
        i18n: {
            de: 'Kiribati',
            fr: 'Kiribati',
        },
    },
    {
        code: 'Korea',
        i18n: { de: 'Korea', fr: 'Corée' },
    },
    {
        code: 'Kosovo',
        i18n: { de: 'Kosovo', fr: 'Kosovo' },
    },
    {
        code: 'Kuwait',
        i18n: { de: 'Kuwait', fr: 'Koweit' },
    },
    {
        code: 'Kyrgyztan',
        i18n: {
            de: 'Kirgistan',
            fr: 'Kirghize',
        },
    },
    { code: 'Lao', i18n: { de: 'Lao', fr: 'Laotien' } },
    {
        code: 'Latvia',
        offer: true,
        i18n: { de: 'Lettland', fr: 'Lettonie' },
    },
    {
        code: 'Lebanon',
        i18n: { de: 'Libanon', fr: 'Liban' },
    },
    {
        code: 'Lesotho',
        i18n: { de: 'Lesotho', fr: 'Lesotho' },
    },
    {
        code: 'Liberia',
        i18n: { de: 'Liberia', fr: 'Libéria' },
    },
    {
        code: 'Libyan Arab Jamahiriya',
        i18n: {
            de: 'Libysch-arabische Jamahiriya',
            fr: 'Jamahiriya arabe libyenne',
        },
    },
    {
        code: 'Liechtenstein',
        i18n: {
            de: 'Liechtenstein',
            fr: 'Liechtenstein',
        },
    },
    {
        code: 'Lithuania',
        offer: true,
        i18n: { de: 'Litauen', fr: 'Lituanie' },
    },
    {
        code: 'Luxembourg',
        offer: true,
        i18n: {
            de: 'Luxemburg',
            fr: 'Luxembourg',
        },
    },
    {
        code: 'Macao',
        i18n: { de: 'Macau', fr: 'Macao' },
    },
    {
        code: 'Macedonia',
        i18n: {
            de: 'Mazedonien',
            fr: 'Macédoine',
        },
    },
    {
        code: 'Madagascar',
        i18n: {
            de: 'Madagaskar',
            fr: 'Madagascar',
        },
    },
    {
        code: 'Malawi',
        i18n: { de: 'Malawi', fr: 'Malawi' },
    },
    {
        code: 'Malaysia',
        i18n: { de: 'Malaysia', fr: 'Malaisie' },
    },
    {
        code: 'Maldives',
        i18n: {
            de: 'Malediven',
            fr: 'Maldives',
        },
    },
    { code: 'Mali', i18n: { de: 'Mali', fr: 'Mali' } },
    {
        code: 'Malta',
        i18n: { de: 'Malta', fr: 'Malte' },
    },
    {
        code: 'Marshall Islands',
        i18n: {
            de: 'Marshallinseln',
            fr: 'Iles Marshall',
        },
    },
    {
        code: 'Martynique',
        i18n: {
            de: 'Martinique',
            fr: 'Martynique',
        },
    },
    {
        code: 'Mauritania',
        i18n: {
            de: 'Mauretanien',
            fr: 'Mauritanie',
        },
    },
    {
        code: 'Mauritius',
        i18n: {
            de: 'Mauritius',
            fr: 'Maurice',
        },
    },
    {
        code: 'Mayotte',
        i18n: { de: 'Mayotte', fr: 'Mayotte' },
    },
    {
        code: 'Melilla',
        i18n: { de: 'Melilla', fr: 'Melilla' },
    },
    {
        code: 'Mexico',
        i18n: { de: 'Mexiko', fr: 'Mexique' },
    },
    {
        code: 'Micronesia',
        i18n: {
            de: 'Mikronesien',
            fr: 'Micronésie',
        },
    },
    {
        code: 'Moldova',
        i18n: { de: 'Moldawien', fr: 'Moldavie' },
    },
    {
        code: 'Monaco',
        i18n: { de: 'Monaco', fr: 'Monaco' },
    },
    {
        code: 'Mongolia',
        i18n: {
            de: 'Mongolei',
            fr: 'Mongolie',
        },
    },
    {
        code: 'Montenegro',
        i18n: {
            de: 'Montenegro',
            fr: 'Monténégro',
        },
    },
    {
        code: 'Montserrat',
        i18n: {
            de: 'Montserrat',
            fr: 'Montserrat',
        },
    },
    {
        code: 'Morocco',
        i18n: { de: 'Marokko', fr: 'Maroc' },
    },
    {
        code: 'Mozambique',
        i18n: {
            de: 'Mosambik',
            fr: 'Mozambique',
        },
    },
    {
        code: 'Myanmar',
        i18n: { de: 'Burma', fr: 'Birmanie' },
    },
    {
        code: 'Namibia',
        i18n: { de: 'Namibia', fr: 'Namibie' },
    },
    {
        code: 'Nauru',
        i18n: { de: 'Nauru', fr: 'Nauru' },
    },
    {
        code: 'Nepal',
        i18n: { de: 'Nepal', fr: 'Népal' },
    },
    {
        code: 'Netherlands',
        offer: true,
        i18n: {
            de: 'Niederlande',
            fr: 'Pays-Bas',
        },
    },
    {
        code: 'New Caledonia',
        i18n: {
            de: 'Neu-Kaledonien',
            fr: 'Nouvelle Calédonie',
        },
    },
    {
        code: 'New Zealand',
        i18n: {
            de: 'Neuseeland',
            fr: 'Nouvelle-Zélande',
        },
    },
    {
        code: 'Nicaragua',
        i18n: {
            de: 'Nicaragua',
            fr: 'Nicaragua',
        },
    },
    {
        code: 'Niger',
        i18n: { de: 'Niger', fr: 'Niger' },
    },
    {
        code: 'Nigeria',
        i18n: { de: 'Nigeria', fr: 'Nigeria' },
    },
    { code: 'Niue', i18n: { de: 'Niue', fr: 'Niué' } },
    {
        code: 'Norfolk Island',
        i18n: {
            de: 'Norfolkinsel',
            fr: "l'ile de Norfolk",
        },
    },
    {
        code: 'Northern Mariana Islands',
        i18n: {
            de: 'Nördliche Marianneninseln',
            fr: 'Îles Mariannes du Nord',
        },
    },
    {
        code: 'Norway',
        offer: true,
        i18n: { de: 'Norwegen', fr: 'Norvège' },
    },
    {
        code: 'Occupied',
        i18n: { de: 'Besetzt', fr: 'Occupé' },
    },
    { code: 'Oman', i18n: { de: 'Oman', fr: 'Oman' } },
    {
        code: 'Pakistan',
        i18n: {
            de: 'Pakistan',
            fr: 'Pakistan',
        },
    },
    {
        code: 'Palau',
        i18n: { de: 'Palau', fr: 'Palaos' },
    },
    {
        code: 'Palestinian Territory',
        i18n: {
            de: 'Palästinensisches Gebiet',
            fr: 'Territoire Palestinien',
        },
    },
    {
        code: 'Panama',
        i18n: { de: 'Panama', fr: 'Panama' },
    },
    {
        code: 'Papua New Guinea',
        i18n: {
            de: 'Papua Neu-Guinea',
            fr: 'Papouasie Nouvelle Guinée',
        },
    },
    {
        code: 'Paraguay',
        i18n: {
            de: 'Paraguay',
            fr: 'Paraguay',
        },
    },
    { code: 'Peru', i18n: { de: 'Peru', fr: 'Pérou' } },
    {
        code: 'Philippines',
        i18n: {
            de: 'Philippinen',
            fr: 'Philippines',
        },
    },
    {
        code: 'Pitcairn',
        i18n: {
            de: 'Pitcairn',
            fr: 'Pitcairn',
        },
    },
    {
        code: 'Polska',
        offer: true,
        i18n: { pl: 'Polska', de: 'Polen', fr: 'Pologne', en: 'Poland' },
    },
    {
        code: 'Portorico',
        i18n: {
            de: 'Portorico',
            fr: 'Portorico',
        },
    },
    {
        code: 'Portugal',
        offer: true,
        i18n: {
            de: 'Portugal',
            fr: 'le Portugal',
        },
    },
    {
        code: 'Provice of China',
        i18n: {
            de: 'Provinz China',
            fr: 'Province de Chine',
        },
    },
    {
        code: 'Qatar',
        i18n: { de: 'Katar', fr: 'Qatar' },
    },
    {
        code: 'Reunion',
        i18n: {
            de: 'Wiedervereinigung',
            fr: 'Réunion',
        },
    },
    {
        code: 'Romania',
        offer: true,
        i18n: { de: 'Rumänien', fr: 'Roumanie' },
    },
    {
        code: 'Russian Federation',
        i18n: {
            de: 'Russische Föderation',
            fr: 'Fédération Russe',
        },
    },
    {
        code: 'Rwanda',
        i18n: { de: 'Ruanda', fr: 'Rwanda' },
    },
    {
        code: 'Saint Barthelemy',
        i18n: {
            de: 'Heiliger Barthelemäus',
            fr: 'Saint Barthélemy',
        },
    },
    {
        code: 'Saint-Martin (France)',
        i18n: {
            de: 'Saint-Martin (Frankreich)',
            fr: 'Saint-Martin (France)',
        },
    },
    {
        code: 'Samoa',
        i18n: { de: 'Samoa', fr: 'Samoa' },
    },
    {
        code: 'San Marino',
        i18n: {
            de: 'San Marino',
            fr: 'Saint Marin',
        },
    },
    {
        code: 'Sao Tome and Principe',
        i18n: {
            de: 'Sao Tome und Principe',
            fr: 'Sao Tomé et Principe',
        },
    },
    {
        code: 'Saudi Arabia',
        i18n: {
            de: 'Saudi-Arabien',
            fr: 'Arabie Saoudite',
        },
    },
    {
        code: 'Senegal',
        i18n: { de: 'Senegal', fr: 'Sénégal' },
    },
    {
        code: 'Serbia',
        i18n: { de: 'Serbien', fr: 'Serbie' },
    },
    {
        code: 'Seychelles',
        i18n: {
            de: 'Seychellen',
            fr: 'les Seychelles',
        },
    },
    {
        code: 'Sierra Leone',
        i18n: {
            de: 'Sierra Leone',
            fr: 'Sierra Leone',
        },
    },
    {
        code: 'Singapore',
        i18n: {
            de: 'Singapur',
            fr: 'Singapour',
        },
    },
    {
        code: 'Sint Eustatius and Saba',
        i18n: {
            de: 'Sint Eustatius und Saba',
            fr: 'Saint Eustache et Saba',
        },
    },
    {
        code: 'Sint Maarten (Dutch part)',
        i18n: {
            de: 'Sint Maarten (niederländischer Teil)',
            fr: 'Sint Maarten (partie néerlandaise)',
        },
    },
    {
        code: 'Slovakia',
        offer: true,
        i18n: {
            de: 'Slowakei',
            fr: 'Slovaquie',
        },
    },
    {
        code: 'Slovenia',
        offer: true,
        i18n: {
            de: 'Slowenien',
            fr: 'Slovénie',
        },
    },
    {
        code: 'Solomon Islands',
        i18n: {
            de: 'Salomon-Inseln',
            fr: 'Les îles Salomon',
        },
    },
    {
        code: 'Somalia',
        i18n: { de: 'Somalia', fr: 'Somalie' },
    },
    {
        code: 'South Africa',
        i18n: {
            de: 'Südafrika',
            fr: 'Afrique du Sud',
        },
    },
    {
        code: 'South Georgia and South Sandwich Islands',
        i18n: {
            de: 'Südgeorgien und Südliche Sandwichinseln',
            fr: 'Géorgie du Sud et Îles Sandwich du Sud',
        },
    },
    {
        code: 'South Sudan',
        i18n: {
            de: 'Südsudan',
            fr: 'Soudan du sud',
        },
    },
    {
        code: 'Spain',
        offer: true,
        i18n: { de: 'Spanien', fr: 'Espagne' },
    },
    {
        code: 'Sri Lanka',
        i18n: {
            de: 'Sri Lanka',
            fr: 'Sri Lanka',
        },
    },
    {
        code: 'Sudan',
        i18n: { de: 'Sudan', fr: 'Soudan' },
    },
    {
        code: 'Suriname',
        i18n: { de: 'Surinam', fr: 'Suriname' },
    },
    {
        code: 'Swaziland',
        i18n: {
            de: 'Swasiland',
            fr: 'Swaziland',
        },
    },
    {
        code: 'Sweden',
        offer: true,
        i18n: { de: 'Schweden', fr: 'Suède' },
    },
    {
        code: 'Switzerland',
        offer: true,
        i18n: {
            de: 'Schweiz',
            fr: 'Suisse',
        },
    },
    {
        code: 'Syrian Arab Republic',
        i18n: {
            de: 'Syrische Arabische Republik',
            fr: 'République arabe syrienne',
        },
    },
    {
        code: 'Taiwan',
        i18n: { de: 'Taiwan', fr: 'Taïwan' },
    },
    {
        code: 'Tajikistan',
        i18n: {
            de: 'Tadschikistan',
            fr: 'Tadjikistan',
        },
    },
    {
        code: 'Tanzania',
        i18n: {
            de: 'Tansania',
            fr: 'Tanzanie',
        },
    },
    {
        code: 'Thailand',
        i18n: {
            de: 'Thailand',
            fr: 'Thaïlande',
        },
    },
    {
        code: 'Timor Leste',
        i18n: {
            de: 'Timor-Leste',
            fr: 'Timor Oriental',
        },
    },
    { code: 'Togo', i18n: { de: 'Togo', fr: 'Togo' } },
    {
        code: 'Tokelau',
        i18n: { de: 'Tokelau', fr: 'Tokélaou' },
    },
    {
        code: 'Tonga',
        i18n: { de: 'Tonga', fr: 'Tonga' },
    },
    {
        code: 'Trinidad and Tobago',
        i18n: {
            de: 'Trinidad und Tobago',
            fr: 'Trinité-et-Tobago',
        },
    },
    {
        code: 'Tunisia',
        i18n: { de: 'Tunesien', fr: 'Tunisie' },
    },
    {
        code: 'Turkey',
        i18n: { de: 'Truthahn', fr: 'Turquie' },
    },
    {
        code: 'Turkmenistan',
        i18n: {
            de: 'Turkmenistan',
            fr: 'Turkménistan',
        },
    },
    {
        code: 'Turks and Caicos Islands',
        i18n: {
            de: 'Turks- und Caicosinseln',
            fr: 'îles Turques-et-Caïques',
        },
    },
    {
        code: 'Tuvalu',
        i18n: { de: 'Tuvalu', fr: 'Tuvalu' },
    },
    {
        code: 'Uganda',
        i18n: { de: 'Uganda', fr: 'Ouganda' },
    },
    {
        code: 'Ukraine',
        i18n: { de: 'Ukraine', fr: 'Ukraine' },
    },
    {
        code: 'United Arab Emirates',
        i18n: {
            de: 'Vereinigte Arabische Emirate',
            fr: 'Emirats Arabes Unis',
        },
    },
    {
        code: 'United Kingdom',
        offer: true,
        i18n: {
            de: 'Großbritannien',
            fr: 'Royaume-Uni',
        },
    },
    {
        code: 'United States of America',
        i18n: {
            de: 'vereinigte Staaten von Amerika',
            fr: "les états-unis d'Amérique",
        },
    },
    {
        code: 'Uruguay',
        i18n: { de: 'Uruguay', fr: 'Uruguay' },
    },
    {
        code: 'Uzbekistan',
        i18n: {
            de: 'Usbekistan',
            fr: 'Ouzbékistan',
        },
    },
    {
        code: 'Vanuatu',
        i18n: { de: 'Vanuatu', fr: 'Vanuatu' },
    },
    {
        code: 'Vatican City State',
        i18n: {
            de: 'Staat Vatikanstadt',
            fr: 'État de la Cité du Vatican',
        },
    },
    {
        code: 'Venezuela',
        i18n: {
            de: 'Venezuela',
            fr: 'Venezuela',
        },
    },
    {
        code: 'Virgin Islands (British)',
        i18n: {
            de: 'Virgin Inseln, Britisch)',
            fr: 'Îles Vierges (britanniques)',
        },
    },
    {
        code: 'Virgin Islands (USA)',
        i18n: {
            de: 'Jungferninseln (USA)',
            fr: 'Îles Vierges (États-Unis)',
        },
    },
    {
        code: 'WalIis and Futuna Islands',
        i18n: {
            de: 'Walis und Futuna-Inseln',
            fr: 'Îles WalIis et Futuna',
        },
    },
    {
        code: 'Western Sahara',
        i18n: {
            de: 'Westsahara',
            fr: 'Sahara occidental',
        },
    },
    {
        code: 'Yemen',
        i18n: { de: 'Jemen', fr: 'Yémen' },
    },
    {
        code: 'Zambia',
        i18n: { de: 'Sambia', fr: 'Zambie' },
    },
    {
        code: 'Zimbabwe',
        i18n: {
            de: 'Zimbabwe',
            fr: 'Zimbabwe',
        },
    },
]
