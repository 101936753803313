<template>
    <!-- eslint-disable -->
    <g v-if="option" :transform="transform[format]">
        <!-- 7mm -->
        <g
            v-if="width === '7 mm'"
            id="Group_980"
            data-name="Group 980"
            transform="translate(594 -79)"
        >
            <rect
                id="Rectangle_1395"
                data-name="Rectangle 1395"
                class="accessories-elastic-2"
                width="18"
                :height="height[format]"
                transform="translate(434 338)"
            />
            <g
                id="Rectangle_1396"
                data-name="Rectangle 1396"
                class="accessories-elastic-3"
                :style="`fill: ${color}`"
                transform="translate(432 335)"
            >
                <rect
                    class="accessories-elastic-7"
                    width="18"
                    :height="height[format] + 5"
                />
                <rect
                    class="accessories-elastic-8"
                    x="0.5"
                    y="0.5"
                    width="17"
                    :height="height[format] + 4"
                />
            </g>
            <line
                id="Line_133"
                data-name="Line 133"
                class="accessories-elastic-4"
                :y1="height[format] + 4"
                transform="translate(436.5 335.5)"
            />
            <line
                id="Line_134"
                data-name="Line 134"
                class="accessories-elastic-4"
                :y1="height[format] + 4"
                transform="translate(440.833 335.5)"
            />
            <g
                id="Group_502"
                data-name="Group 502"
                transform="translate(445.167 335.5)"
            >
                <line
                    id="Line_135"
                    data-name="Line 135"
                    class="accessories-elastic-4"
                    :y1="height[format] + 4"
                    transform="translate(0)"
                />
            </g>
            <g
                id="Group_504"
                data-name="Group 504"
                class="accessories-elastic-5"
                transform="translate(-2 1.125)"
            >
                <line
                    id="Line_137"
                    data-name="Line 137"
                    class="accessories-elastic-6"
                    :y1="height[format] + 2"
                    transform="translate(435.5 335.5)"
                />
                <line
                    id="Line_138"
                    data-name="Line 138"
                    class="accessories-elastic-6"
                    :y1="height[format] + 2"
                    transform="translate(439.834 335.5)"
                />
                <g
                    id="Group_503"
                    data-name="Group 503"
                    transform="translate(444.166 335.5)"
                >
                    <line
                        id="Line_135-2"
                        data-name="Line 135"
                        class="accessories-elastic-6"
                        :y1="height[format] + 2"
                    />
                    <line
                        id="Line_136"
                        data-name="Line 136"
                        class="accessories-elastic-6"
                        :y1="height[format] + 2"
                        transform="translate(4.333)"
                    />
                </g>
            </g>
        </g>
        <!-- 10mm -->
        <g
            v-if="width === '10 mm'"
            id="Group_981"
            data-name="Group 981"
            transform="translate(594 -79)"
        >
            <rect
                id="Rectangle_1395-2"
                data-name="Rectangle 1395"
                class="accessories-elastic-2"
                width="22"
                :height="height[format]"
                transform="translate(434 338)"
            />
            <g
                id="Rectangle_1396-2"
                data-name="Rectangle 1396"
                class="accessories-elastic-3"
                :style="`fill: ${color}`"
                transform="translate(432 335)"
            >
                <rect
                    class="accessories-elastic-7"
                    width="22"
                    :height="height[format] + 5"
                />
                <rect
                    class="accessories-elastic-8"
                    x="0.5"
                    y="0.5"
                    width="21"
                    :height="height[format] + 4"
                />
            </g>
            <line
                id="Line_133-2"
                data-name="Line 133"
                class="accessories-elastic-4"
                :y1="height[format] + 4"
                transform="translate(436.5 335.5)"
            />
            <line
                id="Line_134-2"
                data-name="Line 134"
                class="accessories-elastic-4"
                :y1="height[format] + 4"
                transform="translate(440.833 335.5)"
            />
            <g
                id="Group_502-2"
                data-name="Group 502"
                transform="translate(445.167 335.5)"
            >
                <line
                    id="Line_135-3"
                    data-name="Line 135"
                    class="accessories-elastic-4"
                    :y1="height[format] + 4"
                    transform="translate(0)"
                />
                <line
                    id="Line_136-2"
                    data-name="Line 136"
                    class="accessories-elastic-4"
                    :y1="height[format] + 4"
                    transform="translate(4.333)"
                />
            </g>
            <g
                id="Group_504-2"
                data-name="Group 504"
                class="accessories-elastic-5"
                transform="translate(-2 1.125)"
            >
                <line
                    id="Line_137-2"
                    data-name="Line 137"
                    class="accessories-elastic-6"
                    :y1="height[format] + 2"
                    transform="translate(435.5 335.5)"
                />
                <line
                    id="Line_138-2"
                    data-name="Line 138"
                    class="accessories-elastic-6"
                    :y1="height[format] + 2"
                    transform="translate(439.834 335.5)"
                />
                <g
                    id="Group_503-2"
                    data-name="Group 503"
                    transform="translate(444.166 335.5)"
                >
                    <line
                        id="Line_135-4"
                        data-name="Line 135"
                        class="accessories-elastic-6"
                        :y1="height[format] + 2"
                    />
                    <line
                        id="Line_136-3"
                        data-name="Line 136"
                        class="accessories-elastic-6"
                        :y1="height[format] + 2"
                        transform="translate(4.333)"
                    />
                    <line
                        id="Line_190"
                        data-name="Line 190"
                        class="accessories-elastic-6"
                        :y1="height[format] + 2"
                        transform="translate(9.333)"
                    />
                </g>
            </g>
        </g>
        <!-- 15mm -->
        <g
            v-if="width === '15 mm'"
            id="Group_982"
            data-name="Group 982"
            transform="translate(594 -79)"
        >
            <rect
                id="Rectangle_1395-3"
                data-name="Rectangle 1395"
                class="accessories-elastic-2"
                width="22"
                :height="height[format]"
                transform="translate(434 338)"
            />
            <g
                id="Rectangle_1396-3"
                data-name="Rectangle 1396"
                class="accessories-elastic-3"
                :style="`fill: ${color}`"
                transform="translate(432 335)"
            >
                <rect
                    class="accessories-elastic-7"
                    width="26"
                    :height="height[format] + 5"
                />
                <rect
                    class="accessories-elastic-8"
                    x="0.5"
                    y="0.5"
                    width="25"
                    :height="height[format] + 4"
                />
            </g>
            <line
                id="Line_133-3"
                data-name="Line 133"
                class="accessories-elastic-4"
                :y1="height[format] + 4"
                transform="translate(436.5 335.5)"
            />
            <line
                id="Line_134-3"
                data-name="Line 134"
                class="accessories-elastic-4"
                :y1="height[format] + 4"
                transform="translate(440.833 335.5)"
            />
            <g
                id="Group_502-3"
                data-name="Group 502"
                transform="translate(445.167 335.5)"
            >
                <line
                    id="Line_135-5"
                    data-name="Line 135"
                    class="accessories-elastic-4"
                    :y1="height[format] + 4"
                    transform="translate(0)"
                />
                <line
                    id="Line_136-4"
                    data-name="Line 136"
                    class="accessories-elastic-4"
                    :y1="height[format] + 4"
                    transform="translate(4.333)"
                />
                <line
                    id="Line_191"
                    data-name="Line 191"
                    class="accessories-elastic-4"
                    :y1="height[format] + 4"
                    transform="translate(8.333)"
                />
            </g>
            <g
                id="Group_504-3"
                data-name="Group 504"
                class="accessories-elastic-5"
                transform="translate(-2 1.125)"
            >
                <line
                    id="Line_137-3"
                    data-name="Line 137"
                    class="accessories-elastic-6"
                    :y1="height[format] + 2"
                    transform="translate(435.5 335.5)"
                />
                <line
                    id="Line_138-3"
                    data-name="Line 138"
                    class="accessories-elastic-6"
                    :y1="height[format] + 2"
                    transform="translate(439.834 335.5)"
                />
                <line
                    id="Line_135-6"
                    data-name="Line 135"
                    class="accessories-elastic-6"
                    :y1="height[format] + 2"
                    transform="translate(444.166 335.5)"
                />
                <line
                    id="Line_136-5"
                    data-name="Line 136"
                    class="accessories-elastic-6"
                    :y1="height[format] + 2"
                    transform="translate(448.499 335.5)"
                />
                <line
                    id="Line_192"
                    data-name="Line 192"
                    class="accessories-elastic-6"
                    :y1="height[format] + 2"
                    transform="translate(452.499 335.5)"
                />
                <line
                    id="Line_193"
                    data-name="Line 193"
                    class="accessories-elastic-6"
                    :y1="height[format] + 2"
                    transform="translate(456.499 335.5)"
                />
            </g>
        </g>
    </g>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            height: {
                vertical: 544,
                horizontal: 228,
                square: 267.166,
            },
            transform: {
                vertical: 'translate(0, 0)',
                horizontal: 'translate(0, 0)',
                square: 'translate(-115, 0)',
            },
        }
    },
    computed: {
        ...mapState({
            width: (state) => {
                const option = state.notes.configuration.elastic
                if (option && option.title) {
                    return option.title.pl
                }
                if (option && option.general) {
                    return option.general.title.pl
                }
                return null
            },
            color: (state) => {
                const option = state.notes.configuration.elastic
                if (option && option.elasticColor) {
                    return option.elasticColor.value
                }
                return null
            },
            option: (state) => state.notes.configuration.elastic,
        }),
    },
}
</script>

<style lang="scss">
.accessories-elastic {
    &-2 {
        opacity: 0.11;
    }

    &-3 {
        fill: #ed544e;
    }

    &-3,
    &-4 {
        stroke: #707070;
    }

    &-4,
    &-6,
    &-8 {
        fill: none;
    }

    &-4 {
        opacity: 0.35;
    }

    &-5 {
        opacity: 0.28;
    }

    &-6 {
        stroke: #fff0ef;
    }

    &-7 {
        stroke: none;
    }
}
</style>
