<template>
    <!-- eslint-disable -->
    <g>
        <defs>
            <!-- złoty -->
            <linearGradient
                v-if="edgeColor === 'Złoty'"
                id="notes-edge-linear-gradient"
                x1="1.431"
                y1="0.411"
                x2="0"
                y2="0.411"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#ffce7a" />
                <stop offset="0.297" stop-color="#9d5e2e" />
                <stop offset="0.435" stop-color="#edb967" />
                <stop offset="0.577" stop-color="#c2892d" />
                <stop offset="0.628" stop-color="#e5b05e" />
                <stop offset="0.757" stop-color="#f6c471" />
                <stop offset="0.921" stop-color="#c28736" />
                <stop offset="1" stop-color="#b57726" />
            </linearGradient>
            <!-- srebrny -->
            <linearGradient
                v-if="edgeColor === 'Srebrny'"
                id="notes-edge-linear-gradient"
                x1="1.431"
                y1="0.411"
                x2="0"
                y2="0.411"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#cecece" />
                <stop offset="0.297" stop-color="#848484" />
                <stop offset="0.435" stop-color="#dfdfdf" />
                <stop offset="0.577" stop-color="#bebebe" />
                <stop offset="0.628" stop-color="#dedede" />
                <stop offset="0.757" stop-color="#e6e6e6" />
                <stop offset="0.921" stop-color="#b7b7b7" />
                <stop offset="1" stop-color="#b1b1b1" />
            </linearGradient>
            <!-- niebieski -->
            <linearGradient
                v-if="edgeColor === 'Niebieski'"
                id="notes-edge-linear-gradient"
                x1="1.431"
                y1="0.411"
                x2="0"
                y2="0.411"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#7a95ff" />
                <stop offset="0.297" stop-color="#2e399d" />
                <stop offset="0.435" stop-color="#6779ed" />
                <stop offset="0.577" stop-color="#2d50c2" />
                <stop offset="0.628" stop-color="#5e70e5" />
                <stop offset="0.757" stop-color="#7187f6" />
                <stop offset="0.921" stop-color="#3657c2" />
                <stop offset="1" stop-color="#263eb5" />
            </linearGradient>
            <!-- miedziany -->
            <linearGradient
                v-if="edgeColor === 'Miedziany'"
                id="notes-edge-linear-gradient"
                x1="1.431"
                y1="0.411"
                x2="0"
                y2="0.411"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#ffaf7a" />
                <stop offset="0.297" stop-color="#9d542e" />
                <stop offset="0.435" stop-color="#ed9d67" />
                <stop offset="0.577" stop-color="#c2602d" />
                <stop offset="0.628" stop-color="#e5835e" />
                <stop offset="0.757" stop-color="#f69e71" />
                <stop offset="0.921" stop-color="#c27336" />
                <stop offset="1" stop-color="#b56426" />
            </linearGradient>
            <!-- czerwony -->
            <linearGradient
                v-if="edgeColor === 'Czerwony'"
                id="notes-edge-linear-gradient"
                x1="1.431"
                y1="0.411"
                x2="0"
                y2="0.411"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="#ff7a7a" />
                <stop offset="0.297" stop-color="#9d2e2e" />
                <stop offset="0.435" stop-color="#ed6767" />
                <stop offset="0.577" stop-color="#c22d2d" />
                <stop offset="0.628" stop-color="#e55e5e" />
                <stop offset="0.757" stop-color="#f67171" />
                <stop offset="0.921" stop-color="#c23636" />
                <stop offset="1" stop-color="#b52626" />
            </linearGradient>
        </defs>
        <g
            id="Zmiana_koloru_lub_maska"
            class="notes-edge-foil"
            :transform="options[format].gTransform"
        >
            <rect
                class="notes-edge-10"
                :width="options[format].width"
                :height="options[format].height"
            />
            <rect
                class="notes-edge-11"
                x="0.5"
                y="0.5"
                :width="options[format].width - 1"
                :height="options[format].height - 1"
            />
        </g>
    </g>
</template>

<script>
export default {
    props: {
        edgeColor: {
            type: String,
            default: '',
        },
        format: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            options: {
                vertical: {
                    gTransform: 'translate(675 276)',
                    height: '532.11',
                    width: '44.72',
                },
                horizontal: {
                    gTransform: 'translate(4.029 4.7)',
                    height: '187.943',
                    width: '30.027',
                },
                square: {
                    gTransform: 'translate(4.86 7)',
                    height: '251',
                    width: '36.222',
                },
            },
        }
    },
}
</script>

<style>
.notes-edge-foil {
    fill: url(#notes-edge-linear-gradient);
}
</style>
