<template>
    <div class="form-final">
        <h1 class="page-title page-title-mobile">
            {{ $t('form.form') }}
        </h1>
        <LoaderForm v-if="inProcess" />
        <form class="form-fields" @submit.prevent="sendForm">
            <FormFieldDefault
                ref="companyName"
                v-model="payload.companyName"
                name="companyName"
                :label="$t('form.company')"
                required
            />
            <FormFieldDefault
                ref="name"
                v-model="payload.name"
                name="name"
                :label="$t('form.name')"
                required
            />
            <FormFieldDefault
                ref="surname"
                v-model="payload.surname"
                name="surname"
                :label="$t('form.surname')"
                required
            />
            <FormFieldDefault
                ref="email"
                v-model="payload.email"
                name="email"
                type="email"
                :label="$t('form.email')"
                required
            />
            <FormFieldDefault
                ref="phone"
                v-model="payload.phone"
                name="phone"
                :label="$t('form.phone')"
            />
            <FormFieldDefault
                ref="country"
                v-model="payload.country"
                name="country"
                :label="$t('form.country')"
                required
            />
            <FormFieldDefault
                ref="city"
                v-model="payload.city"
                name="city"
                :label="$t('form.city')"
                required
            />
            <FormFieldDefault
                ref="agencyName"
                v-model="payload.agencyName"
                name="agencyName"
                :label="$t('form.agencyName')"
                theme="wrap"
            />
            <FormFieldDefault
                ref="agencyEmail"
                v-model="payload.agencyEmail"
                name="agencyEmail"
                type="email"
                :label="$t('form.agencyEmail')"
                theme="wrap"
            />
            <FormFieldDefault
                ref="agencyAddress"
                v-model="payload.agencyAddress"
                name="agencyAddress"
                :label="$t('form.agencyAddress')"
                theme="wrap"
            />
            <FormCheckbox
                ref="consent1"
                v-model="payload.consent1"
                name="consent1"
                :label="$t('form.consent1')"
                required
            />
            <FormCheckbox
                ref="consent2"
                v-model="payload.consent2"
                name="consent2"
                :label="$t('form.consent2')"
            />
            <FormIsRequiredInfo class="form-final-required" />

            <button
                class="form-submit send-button"
                type="submit"
                v-text="$t('form.send')"
            />
        </form>
    </div>
</template>

<script>
import FormCheckbox from '@/components/form/input/FormCheckbox'
import FormMixin from '@/mixins/FormMixin'
import { mapState, mapActions } from 'vuex'
import FormIsRequiredInfo from '@/components/form/FormIsRequiredInfo'

export default {
    components: {
        FormCheckbox,
        FormIsRequiredInfo,
    },
    extends: FormMixin,
    methods: {
        ...mapActions(['changePage']),
        sendForm() {
            this.changePage('sent')

            // let success = true
            // setTimeout(() => {
            //     if (success) {
            //         this.inProcess = false
            //         this.result = 'success'
            //         this.setDefaultPayload()
            //         this.changePage('sent')
            //     } else {
            //         this.result = 'error'
            //         this.inProcess = false
            //     }
            // }, 1000)
        },
        setDefaultPayload() {
            this.payload = {
                name: null,
                surname: null,
                companyName: null,
                email: null,
                consent1: false,
                consent2: false,
                consent3: false,
            }
        },
        goToOffer() {
            this.changePage('offer')
        },
    },
}
</script>

<style lang="scss">
.form-final {
    padding: 10px 0 15px;
    max-height: 100%;
    overflow-y: auto;
    @include hide-scrollbar();

    .page-title {
        font-size: 4.8rem;
    }

    &-required {
        margin-left: 46px;
    }

    @media (max-width: $screen-tablet-medium) {
        max-height: unset;
        height: 100%;
        margin: 0 40px;
        padding: 0 0 60px;
        &-required {
            margin-left: 60px;
        }
    }
    @media (max-width: $screen-mobile-large) {
        padding: 20px 0;
    }
}
</style>
