<template>
    <div :class="['debugger', { 'is-open': open }]" @click="open = !open">
        <div v-if="!open" class="debugger-switch">
            Debugger
        </div>
        <div v-else class="debugger-info">
            <p>Debug:</p>
            <hr style="margin: 10px 0;" />
            Materiał: {{ configuration.material }}<br />
            Typ okładki: {{ configuration.coverType }}<br />
            Narożniki: {{ configuration.coverCorners }}<br />
            Rodzaj grzbietu: {{ configuration.coverRidge }}<br />
            Format: {{ configuration.format }}<br />
            Okleina - color pattern: {{ configuration.coverColorPattern }}<br />
            Okleina - color type: {{ configuration.coverColorType }}<br />
            Druk - znakowanie okładki: {{ configuration.markingPrint }}<br />
            Tłoczenie - znakowanie okładki: {{ configuration.markingExpression
            }}<br />
            Wykończenie - znakowanie okładki:
            {{ configuration.markingCoverFinish }}<br />
            Rodzaj papieru: {{ configuration.paperType }}<br />
            Ilość kartek: {{ configuration.numberOfPages }}<br />
            Rodzaj zadruku: {{ configuration.overprintType }}<br />
            Perforacja: {{ configuration.perforation }}<br />
            Otwory do segregatora: {{ configuration.binderHoles }}<br />
            Wyklejka: {{ configuration.flyleaf }}<br />
            Kieszeń: {{ configuration.pocket }}<br />
            Dodatkowe strony: {{ configuration.additionalPages }}<br />
            Kalendarz: {{ configuration.calendar }}<br />
            Gumka: {{ configuration.elastic }}<br />
            Uchwyt na długopis: {{ configuration.penHolder }}<br />
            Tasiemka: {{ configuration.ribbon }}<br />
            Banderola i obwoluta: {{ configuration.streamer }}<br />
            Barwienie krawędzi: {{ configuration.edgeColoration }}<br />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            open: false,
        }
    },
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
        }),
        debuggerOn() {
            return process.env.NODE_ENV === 'development'
        },
    },
}
</script>

<style lang="scss">
.debugger {
    position: fixed;
    top: 10px;
    right: 25px;
    margin: 0;
    box-shadow: #acacac 0px 0px 12px;
    background: #ffffff;
    font-family: monospace;
    font-size: 12px;
    padding: 5px;
    line-height: 11px;
    border: #acacac solid 1px;
    z-index: 999;
    max-width: 500px;

    &-switch {
        cursor: pointer;
    }

    &:hover(:not(.is-open)) {
        background: $color-burnt-sienna-light;
        color: $color-white;
    }

    &.is-open {
        cursor: pointer;
    }
}
</style>
