<template>
    <div id="summary" class="summary">
        <div v-for="page in summary" :key="page.id" class="summary-category">
            <p class="summary-category-header">
                {{
                    page.summaryTitle
                        ? page.summaryTitle[locale]
                        : page.title[locale]
                }}
                <span
                    class="edit"
                    @click="changePage(page.id)"
                    v-text="$t('summary.edit')"
                />
            </p>
            <SummaryConfigGroup
                :possible-options="page.possibleOptions"
                :configuration="configuration"
            />
        </div>
    </div>
</template>

<script>
import SummaryConfigGroup from '@/components/summary/SummaryConfigGroup'
import { mapState, mapActions } from 'vuex'

export default {
    components: {
        SummaryConfigGroup,
    },
    props: {
        summary: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapState({
            allPages: (state) => state.page.allPages,
            configuration: (state) => state.notes.configuration,
            locale: (state) => state.i18n.locale,
        }),
    },
    methods: {
        ...mapActions(['changePage']),
    },
}
</script>

<style lang="scss">
.summary {
    margin-bottom: -2px;

    &-category {
        &-header {
            color: $color-gray;
            padding: 12px 0;
            border-bottom: $color-silver2 solid 1px;

            span {
                float: right;
                margin-left: 15px;
                color: $color-bg-selected-lighter;
                font-weight: 300;
                cursor: pointer;
                opacity: 1;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
    @media (max-width: $screen-tablet-medium) {
        &-category {
            margin-top: 25px;

            &-header {
                background: $color-bg-light;
                padding-left: 18px;
                padding-right: 18px;
                color: $color-default-text;
                border-bottom: 0;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .edit {
                    color: $color-default-text;
                    font-weight: normal;
                }
            }
        }
    }
}
</style>
