<script>
import dependencies from '@/store/modules/dependencies'

export default {
    data() {
        return {
            pageTitle: {
                pl: 'Środek - blok',
                en: 'Inside - writing pad',
                de: 'Buchblock - Inhalt',
                fr: 'Intérieur – bloc de papier',
            },
        }
    },
    computed: {
        list() {
            return dependencies.state.list
        },
        options() {
            let BLOK_RODZAJ_PAPIERU = null
            let BLOK_OTWORY = null
            let BLOK_PERFORACJA = null
            let BLOK_ILOSC_KARTEK = null
            let BLOK_RODZAJ_ZADRUKU = null

            if (this.list?.length) {
                this.list.forEach((dependency) => {
                    BLOK_RODZAJ_PAPIERU = mergeMultipleParams(
                        BLOK_RODZAJ_PAPIERU,
                        dependency.actions.BLOK_RODZAJ_PAPIERU
                    )
                    BLOK_OTWORY = mergeMultipleParams(
                        BLOK_OTWORY,
                        dependency.actions.BLOK_OTWORY
                    )
                    BLOK_PERFORACJA = mergeMultipleParams(
                        BLOK_PERFORACJA,
                        dependency.actions.BLOK_PERFORACJA
                    )
                    BLOK_ILOSC_KARTEK = mergeMultipleParams(
                        BLOK_ILOSC_KARTEK,
                        dependency.actions.BLOK_ILOSC_KARTEK
                    )
                    BLOK_RODZAJ_ZADRUKU = mergeMultipleParams(
                        BLOK_RODZAJ_ZADRUKU,
                        dependency.actions.BLOK_RODZAJ_ZADRUKU
                    )
                })
            }

            function mergeMultipleParams(param, dependencyAction) {
                param = param || dependencyAction

                if (param && dependencyAction) {
                    param = param.filter((dep) => {
                        return dependencyAction.includes(dep)
                    })
                }
                return param
            }

            function filterOptionsFn({ apiId }, allowed) {
                return allowed ? allowed.includes(apiId.toUpperCase()) : true
            }

            return [
                {
                    id: 'paperType',
                    apiId: 'BLOK_RODZAJ_PAPIERU',
                    required: true,
                    title: {
                        pl: 'Rodzaj papieru',
                        en: 'Paper type',
                        de: 'Papierart',
                        fr: 'Type de papier',
                    },
                    choices: [
                        {
                            apiId: 'S',
                            title: {
                                pl: 'Standardowy biały',
                                en: 'White',
                                de: 'Weiß',
                                fr: 'Blanc',
                            },
                            hex: '#f7f7f7',
                        },
                        {
                            apiId: 'I',
                            title: {
                                pl: 'Ivory',
                                en: 'Ivory',
                                de: 'Elfenbein',
                                fr: 'Ivoire',
                            },
                            hex: '#f8f6f1',
                        },
                        {
                            apiId: 'E',
                            title: {
                                pl: 'Biały z recyklingu',
                                en: 'White recycled',
                                de: 'Recycling weiß',
                                fr: 'Recycle blanc',
                            },
                            hex: '#eeebe3',
                        },{
                            apiId: 'G',
                            title: {
                                pl: 'Ekologiczny z trawy',
                                en: 'Eco – grass',
                                de: 'ECO mit Grasfasern',
                                fr: "Eco d'herbe",
                            },
                            hex: '#dfd0bd',
                            image: '/img/_updates/MNT-212/80PG-320.jpg'
                        },{
                            apiId: 'A',
                            title: {
                                pl: 'Ekologiczny z jabłek',
                                en: 'Eco - apple',
                                de: 'ECO mit Apfel',
                                fr: 'Eco de pommes',
                            },
                            hex: '#f0f1ec',
                            image: '/img/_updates/MNT-212/80PUA-112.jpg'
                        },
                    ].filter((item) =>
                        filterOptionsFn(item, BLOK_RODZAJ_PAPIERU)
                    ),
                },
                {
                    id: 'numberOfPages',
                    apiId: 'BLOK_ILOSC_KARTEK',
                    required: true,
                    title: {
                        pl: 'Ilość kartek',
                        en: 'Number of sheets',
                        de: 'Blattanzahl',
                        fr: 'Nombre de feuilles',
                    },
                    choices: [
                        {
                            apiId: '50',
                            title: {
                                pl: '50',
                                en: '50',
                                de: '50',
                                fr: '50',
                            },
                        },
                        {
                            apiId: '80',
                            title: {
                                pl: '80',
                                en: '80',
                                de: '80',
                                fr: '80',
                            },
                        },
                        {
                            apiId: '100',
                            title: {
                                pl: '100',
                                en: '100',
                                de: '100',
                                fr: '100',
                            },
                        },
                        {
                            apiId: '120',
                            title: {
                                pl: '120',
                                en: '120',
                                de: '120',
                                fr: '120',
                            },
                        },
                    ].filter((item) =>
                        filterOptionsFn(item, BLOK_ILOSC_KARTEK)
                    ),
                },
                {
                    id: 'overprintType',
                    apiId: 'BLOK_RODZAJ_ZADRUKU',
                    required: true,
                    title: {
                        pl: 'Rodzaj zadruku',
                        en: 'Printing type',
                        de: 'Druckart',
                        fr: "Type d'impression",
                    },
                    choices: [
                        {
                            apiId: 'B',
                            title: {
                                pl: 'Bez zadruku',
                                en: 'No printing',
                                de: 'Ohne Druck',
                                fr: 'Sans impression',
                            },
                        },
                        {
                            apiId: 'S',
                            title: {
                                pl: 'Standardowy',
                                en: 'Standard',
                                de: 'Standard',
                                fr: 'Standard',
                            },
                            childrenGroups: [
                                {
                                    id: 'style',
                                    apiId: '',
                                    required: true,
                                    title: {
                                        pl: 'Styl',
                                        en: 'Style',
                                        de: 'Stil',
                                        fr: 'Style',
                                    },
                                    items: [
                                        {
                                            apiId: 'squared',
                                            title: {
                                                pl: 'Kratka',
                                                en: 'Squares',
                                                de: 'Kariert',
                                                fr: 'Quadrillage',
                                            },
                                            id: 'chequered',
                                        },
                                        {
                                            apiId: 'modern squared',
                                            title: {
                                                pl: 'Pół kratka',
                                                en: 'Halfsquares',
                                                de: 'Winkelkaro',
                                                fr: 'Demi-quadrillage',
                                            },
                                            id: 'halfChequered',
                                        },
                                        {
                                            apiId: 'ruled',
                                            title: {
                                                pl: 'Linie',
                                                en: 'Lines',
                                                de: 'Liniert',
                                                fr: 'Lignes',
                                            },
                                            id: 'lines',
                                        },
                                        {
                                            apiId: 'calendar day',
                                            title: {
                                                pl: 'Planer dzienny',
                                                en: 'Daily planner',
                                                de: 'Tagesplaner',
                                                fr: 'Planificateur quotidien',
                                            },
                                            id: 'plannerDaily',
                                        },
                                        {
                                            apiId: 'calendar week',
                                            title: {
                                                pl: 'Planer tygodniowy',
                                                en: 'Weekly planner',
                                                de: 'Wochenplaner',
                                                fr: 'Planning hebdomadaire',
                                            },
                                            id: 'plannerWeekly',
                                        },
                                        {
                                            apiId: 'dotted',
                                            title: {
                                                pl: 'Kropki',
                                                en: 'Dots',
                                                de: 'Gepunktet',
                                                fr: 'Points',
                                            },
                                            id: 'dots',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            apiId: 'I',
                            title: {
                                pl: 'Indywidualny projekt zadruku',
                                en: 'Individually designed print',
                                de: 'Individuelles Druckdesign',
                                fr: "Conception d'impression individuelle",
                            },
                            childrenGroups: [
                                {
                                    id: 'druk',
                                    apiId: '',
                                    required: true,
                                    title: {
                                        pl: 'Druk',
                                        en: 'Print',
                                        de: 'Druck',
                                        fr: 'Impression',
                                    },
                                    items: [
                                        {
                                            apiId: '11',
                                            title: {
                                                pl: '1 + 1',
                                                en: '1 + 1',
                                                de: '1 + 1',
                                                fr: '1 + 1',
                                            },
                                            id: 'individual1',
                                        },
                                        {
                                            apiId: '22',
                                            title: {
                                                pl: '2 + 2',
                                                en: '2 + 2',
                                                de: '2 + 2',
                                                fr: '2 + 2',
                                            },
                                            id: 'individual2',
                                        },
                                        {
                                            apiId: '44',
                                            title: {
                                                pl: 'CMYK + CMYK',
                                                en: 'CMYK + CMYK',
                                                de: 'CMYK + CMYK',
                                                fr: 'CMYK + CMYK',
                                            },
                                            id: 'individual3',
                                        },
                                    ],
                                },
                            ],
                        },
                    ].filter((item) =>
                        filterOptionsFn(item, BLOK_RODZAJ_ZADRUKU)
                    ),
                },
                {
                    id: 'perforation',
                    apiId: 'BLOK_PERFORACJA',
                    title: {
                        pl: 'Perforacja',
                        en: 'Perforation',
                        de: 'Perforation',
                        fr: 'Perforation',
                    },
                    choices: [
                        // {
                        //     apiId: 'S',
                        //     title: {
                        //         pl: 'Standardowa',
                        //         en: 'Standard',
                        //         de: 'Standard',
                        //         fr: 'Standard',
                        //     },
                        // },
                        {
                            apiId: 'M',
                            title: {
                                pl: 'Mikroperforacja',
                                en: 'Microperforation',
                                de: 'Mikroperforiert',
                                fr: 'Microperforation',
                            },
                        },
                    ].filter((item) => filterOptionsFn(item, BLOK_PERFORACJA)),
                },
                {
                    id: 'binderHoles',
                    apiId: 'BLOK_OTWORY',
                    title: {
                        pl: 'Otwory do segregatora',
                        en: 'Binder holes',
                        de: 'Abheftlochung',
                        fr: 'Trous de classeur',
                    },
                    choices: [
                        {
                            apiId: '2',
                            title: {
                                pl: '2 otwory',
                                en: '2 holes',
                                de: '2-er Lochung',
                                fr: '2 trous',
                            },
                        },
                        {
                            apiId: '4',
                            title: {
                                pl: '4 otwory',
                                en: '4 holes',
                                de: '4-er Lochung',
                                fr: '4 trous',
                            },
                        },
                    ].filter((item) => filterOptionsFn(item, BLOK_OTWORY)),
                },
            ]
        },
    },
}
</script>
