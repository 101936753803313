export const individualFormDefaults = {
    // basic
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
    countryName: 'Polska',
    companyName: '',
    vatNr: '',
    PERMIT_ANSWER: false,
    PERMIT_MAILING: false,
    // extended
    city: '',
    phone: '',
    dealerName: '',
    dealerEmail: '',
    dealerContactDetails: '',
}
