const state = {
    isReduced: false,
    resizeMenuBreakpoint: 1600,
}

const mutations = {
    resize(state, value) {
        state.isReduced = value
    },
}

export default {
    state,
    mutations,
}
