import axios from 'axios'
import { API_CONFIG } from '@/api/config'

/**
 * Axios client
 *
 * @type {AxiosInstance}
 */
const client = axios.create({})

// Add a request interceptor
client.interceptors.request.use(
    (requestConfig) => {
        if (API_CONFIG.LOG_REQUESTS) {
            console.log('[Api Client Request] Payload: ', requestConfig)
        }

        return requestConfig
    },
    (requestError) => {
        return Promise.reject(requestError)
    }
)

// Add a response interceptor
client.interceptors.response.use(
    (response) => {
        if (
            response.data &&
            response.data.code &&
            /^[45].{0,2}$/.test(response.data.code.toString())
        ) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({
                response,
                data: response.data,
            })
        }

        return response
    },
    (error) => {
        // @TODO: log errors
        console.log(error)
        return Promise.reject(error)
    }
)

/**
 * Parse query params
 *
 * @param params {object}
 * @returns {string}
 */
const parseParams = function (params) {
    const out = []
    for (const key in params) {
        if (!params.hasOwnProperty(key)) {
            break
        }

        if (Array.isArray(params[key])) {
            for (let i = 0; i < params[key].length; i++) {
                out.push(key + '[]=' + encodeURIComponent(params[key][i]))
            }
        } else {
            out.push(key + '=' + encodeURIComponent(params[key]))
        }
    }

    return out.length ? '?' + out.join('&') : ''
}

export default {
    /**
     *
     * @param url {string}
     * @param params {object}
     * @param conf {object}
     * @returns {Promise<AxiosResponse<any>>}
     */
    get(url, params = {}, conf = {}) {
        if (params) {
            url += parseParams(params)
        }

        return client
            .get(url, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },

    /**
     *
     * @param url {string}
     * @param params {object}
     * @param conf {object}
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete(url, params = {}, conf = {}) {
        if (params) {
            url += parseParams(params)
        }

        return client
            .delete(url, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },

    /**
     *
     * @param url {string}
     * @param params {object}
     * @param conf {object}
     * @returns {Promise<AxiosResponse<any>>}
     */
    head(url, params = {}, conf = {}) {
        if (params) {
            url += parseParams(params)
        }

        return client
            .head(url, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },

    /**
     *
     * @param url {string}
     * @param params {object}
     * @param conf {object}
     * @returns {Promise<AxiosResponse<any>>}
     */
    options(url, params = {}, conf = {}) {
        if (params) {
            url += parseParams(params)
        }

        return client
            .options(url, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },

    /**
     *
     * @param url {string}
     * @param data {object}
     * @param params {object}
     * @param conf {object}
     * @returns {Promise<AxiosResponse<any>>}
     */
    post(url, data = {}, params = {}, conf = {}) {
        if (params) {
            url += parseParams(params)
        }

        return client
            .post(url, data, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },

    /**
     *
     * @param url {string}
     * @param data {object}
     * @param params {object}
     * @param conf {object}
     * @returns {Promise<AxiosResponse<any>>}
     */
    put(url, data = {}, params = {}, conf = {}) {
        if (params) {
            url += parseParams(params)
        }

        return client
            .put(url, data, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },

    /**
     *
     * @param url {string}
     * @param data {object}
     * @param params {object}
     * @param conf {object}
     * @returns {Promise<AxiosResponse<any>>}
     */
    patch(url, data = {}, params = {}, conf = {}) {
        if (params) {
            url += parseParams(params)
        }

        return client
            .patch(url, data, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },
}
