export const colors = [
    {
        id: 'Złoty',
        title: {
            pl: 'Złoty',
            en: 'Gold',
            de: 'Gold',
            fr: 'Doré',
        },
        apiId: '1',
        image: '/img/colors/gold.svg',
    },
    {
        id: 'Srebrny',
        title: {
            pl: 'Srebrny',
            en: 'Silver',
            de: 'Silber',
            fr: 'Argent',
        },
        apiId: '2',
        image: '/img/colors/silver.svg',
    },
    {
        id: 'Miedziany',
        title: {
            pl: 'Miedziany',
            en: 'Copper',
            de: 'Kupferfarben',
            fr: 'En cuivre',
        },
        apiId: '3',
        image: '/img/colors/orange.svg',
    },
    {
        id: 'Niebieski',
        title: {
            pl: 'Niebieski',
            en: 'Blue',
            de: 'Blau',
            fr: 'Bleu',
        },
        apiId: '4',
        image: '/img/colors/blue.svg',
        hideIfCornerRound: true,
    },
    {
        id: 'Czerwony',
        title: {
            pl: 'Czerwony',
            en: 'Red',
            de: 'Rot',
            fr: 'Rouge',
        },
        apiId: '5',
        image: '/img/colors/red.svg',
        hideIfCornerRound: true,
    },
]
