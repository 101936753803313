<template>
    <div class="loader-container">
        <LoadingSvg />
    </div>
</template>

<script>
import LoadingSvg from '@/assets/images/system-icons/loader.svg'

export default {
    components: {
        LoadingSvg,
    },
}
</script>

<style lang="scss">
.loader-container {
    @include size(100%);
    background: rgba(241, 242, 243, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
}
</style>
