<template>
    <div class="material-config">
        <form class="material-config-form" @submit.prevent="getConfiguration">
            <label class="material-config-label">{{
                $t('material.load')
            }}</label>
            <div class="material-config-input">
                <input v-model="code" type="text" name="konfiguracja" />
                <button type="submit" />
            </div>
        </form>
        <p class="material-config-text">
            {{ $t('material.description') }}
        </p>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    data() {
        return {
            code: '',
        }
    },
    methods: {
        ...mapActions(['getConfig']),
        getConfiguration() {
            this.getConfig({ data: this.code.trim(), context: this })
        },
    },
}
</script>

<style lang="scss">
.material-config {
    width: 867px;
    margin-top: 22px;
    background-color: $color-white;
    padding: 40px 20px 44px;
    border: 1px solid $color-silver;
    z-index: 9;
    box-shadow: 0 5px 40px rgba(#000000, 0.05);

    &-mobile {
        display: none;
    }

    &-form {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 21px;
    }

    &-input {
        height: 50px;
        width: 275px;
        display: flex;
        align-items: center;

        > input {
            height: inherit;
            width: 225px;
            border: solid $color-silver;
            border-width: 1px 0 1px 1px;
            padding-left: 10px;
        }

        > button {
            height: inherit;
            width: 50px;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #2f3031;
            transition: background-color 0.3s;

            &::before {
                content: '';
                @include size(10px);
                display: block;
                border: solid $color-white;
                border-width: 0 0 2px 2px;
                margin-right: 5px;
                transform: rotate(-135deg);
            }

            &:hover {
                background-color: $color-burnt-sienna;
            }
        }
    }

    &-label {
        font-size: 1.8rem;
        margin-right: 19px;
    }

    &-text {
        font-size: 1.6rem;
        text-align: center;
        color: #9b9b9b;
    }

    @media (max-width: $screen-large) {
        padding-top: 29px;
        padding-bottom: 24px;
        width: 602px;
        margin-top: 16px;

        &-label {
            font-size: 1.5rem;
            margin-right: 19px;
        }

        &-text {
            font-size: 1.3rem;
        }

        &-input {
            height: 35px;
            font-size: 13px;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        margin-top: 38px;
        padding: 40px 55px;
        margin-bottom: 40px;
        width: 594px;

        &-label {
            font-size: 1.8rem;
        }

        &-text {
            font-size: 1.6rem;
        }

        &-input {
            height: 50px;
            font-size: 16px;
        }
    }

    @media (max-width: $screen-mobile-landscape) {
        width: calc(100% - 40px);
        margin: 10px 20px;
        padding: 20px;

        &-form {
            width: 100%;
            flex-direction: column;
        }

        &-label {
            margin-bottom: 11px;
        }

        &-text {
            font-size: 1.2rem;
        }

        &-input {
            height: 50px;
            width: 236px;
            display: flex;
            align-items: center;

            > input {
                height: inherit;
                width: 198px;
            }
        }
    }
}
</style>
