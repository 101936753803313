<template>
    <div class="input-dropdown-selected" @click="toggle">
        <div class="input-dropdown-selected-name">
            <div
                v-if="theme === 'colors' && selected"
                class="input-dropdown-selected-color"
            >
                <img
                    v-if="selected.image"
                    :src="selected.image"
                    :alt="selected.title[locale]"
                />
                <div
                    v-else-if="selected.hex"
                    class="input-dropdown-selected-color-hex"
                    :style="`background-color: ${selected.hex}; ${
                        selected.hasBorder ? 'border:1px solid #EAEAEA;' : ''
                    }`"
                    :title="selected.title.pl"
                />
            </div>
            <div v-bastards v-text="capitalize(currentTitle)" />
        </div>
        <div class="input-dropdown-selected-icons">
            <div
                v-if="selected"
                class="input-dropdown-selected-clear"
                @click.stop="clearCurrent"
            >
                &times;
            </div>
            <div v-else class="input-dropdown-selected-select" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        theme: {
            type: String,
            default: '',
        },
        selected: {
            type: Object,
            default: () => {},
        },
        name: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState({
            locale: (state) => state.i18n.locale,
        }),
        currentTitle() {
            return this.selected ? this.selected.title[this.locale] : this.name
        },
    },
    methods: {
        clearCurrent() {
            this.$emit('clear')
        },
        toggle() {
            this.$emit('toggle')
        },
        capitalize(value) {
            if (!value) {
                return ''
            }
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
}
</script>

<style lang="scss">
.input-dropdown-selected {
    margin: 30px 0 30px 24px;
    width: 315px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 28px;
    border: 1px solid $color-silver;
    cursor: pointer;
    transition: 0.2s;
    z-index: 2;
    position: relative;

    &-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &-color {
        margin-right: 20px;

        &-hex {
            @include size(35px);
            border-radius: 50%;
        }
    }

    &-icons {
        display: flex;
        align-items: center;
        margin-top: -5px;
    }

    &-select {
        @include size(12px);
        border: solid $color-default-text;
        margin-right: 30px;
        border-width: 2px 2px 0 0;
        transform: rotate(135deg);
        transition: 0.1s;
    }

    &-clear {
        display: block;
        font-size: 3rem;
        margin-right: 28px;
        margin-top: 5px;
        transition: color 0.2s;
        color: $color-burnt-sienna;
        user-select: none;

        &:hover {
            color: $color-burnt-sienna;
        }
    }

    @media (max-width: $screen-large) {
        width: 270px;
    }

    @media (max-width: $screen-medium) {
        @include size(180px, 50px);
        margin-top: 10px;
        margin-left: 0;
        margin-right: 28px;
        padding-left: 10px;

        &-color {
            @include size(20px);
            min-width: 20px;
            margin-right: 10px;

            &-hex {
                @include size(20px);
            }
        }

        &-clear {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        width: 155px;
        margin-right: 18px;

        &-name {
            font-size: 1.6rem;
        }

        &-select {
            margin-right: 15px;
        }
    }
    @media (max-width: $screen-mobile-large) {
        width: 100%;
        margin: 0;
    }
}
</style>
