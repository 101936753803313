import Api from '@/api/ApiLauncher'
import { validator } from '@/helper/ValidateConfiguration'
import { checkDependencies } from '@/helper/Dependencies'
import { delay } from '@/helper/DelayHelper'
const state = {
    cornerFillColor: '#C58983',
    currentOption: null,
    currentSubOption: '',
    configuration: {
        material: null,
        coverType: null,
        coverCorners: null,
        coverRidge: null,
        format: null,
        coverColorPattern: null,
        coverColorType: null,
        markingPrint: null,
        markingExpression: null,
        markingCoverFinish: null,
        paperType: null,
        numberOfPages: null,
        overprintType: null,
        perforation: null,
        binderHoles: null,
        flyleaf: null,
        flyleafPaper: null,
        pocket: null,
        additionalPages: null,
        calendar: null,
        elastic: null,
        penHolder: null,
        ribbon: null,
        streamer: null,
        edgeColoration: null,
        wrapping: null,
    },
    configurationDefault: {
        material: null,
        coverType: null,
        coverCorners: null,
        coverRidge: null,
        format: {
            id: 'a4',
        },
        coverColorPattern: null,
        coverColorType: null,
        markingPrint: null,
        markingExpression: null,
        markingCoverFinish: null,
        paperType: {
            hex: '#f7f7f7',
        },
        numberOfPages: null,
        overprintType: null,
        perforation: null,
        binderHoles: null,
        flyleaf: null,
        flyleafPaper: {
            hex: '#f7f7f7',
        },
        pocket: null,
        additionalPages: {
            paperType: {
                hex: '#f7f7f7',
            },
        },
        calendar: null,
        elastic: null,
        penHolder: null,
        ribbon: null,
        streamer: null,
        edgeColoration: null,
        wrapping: null,
    },
    configurationVersion: '2022.07.26.1',
    configurationCode: null,
    verticalFormats: ['a4', 'b5', 'a5', '125', 'a6', '84'],
    configValidationError: false,
    configVersionError: false,
    loadingConfiguration: false,
    configurationApiCode: null,
}

const getters = {
    paperColor(state) {
        const image = state.configuration.paperType?.image
        if (image) {
            return `url(#paperType)`
        }
        const paper = state.configuration.paperType?.hex
        const defaultPaperColor = state.configurationDefault.paperType.hex

        return paper ? paper : defaultPaperColor
    },

    hasExpression(state) {
        return (
            state.configuration.markingExpression &&
            (['40', '4white', 'white'].includes(
                state.configuration.markingPrint?.singleMarkingPrint?.apiId
            ) ||
                ['whitewhite'].includes(
                    state.configuration.markingPrint?.doubleMarkingPrint?.apiId
                ))
        )
    },
}

const mutations = {
    setCurrentOption(state, value) {
        state.currentOption = value
    },
    setCurrentSubOption(state, value) {
        state.currentSubOption = value
    },
    setNotesConfig(state, payload) {
        state.configuration[payload.param] = payload.value
        // clear configuration api code when any option changes - then new config number will be generated
        state.configurationApiCode = null
    },
    clearConfiguration(state) {
        const setAll = (obj, val) =>
            Object.keys(obj).forEach((k) => (obj[k] = val))
        const setNull = (obj) => setAll(obj, null)
        setNull(state.configuration)
    },
    setCurrentNotesCode(state, payload) {
        state.configurationCode = payload.data.code
    },
    clearConfigurationCode(state) {
        state.configurationCode = null
    },
    setConfigurationApiCode(state, payload) {
        state.configurationApiCode = payload
    },
}
const actions = {
    setNotesConfigWithoutCheck({ commit }, payload) {
        commit('setNotesConfig', payload)
    },
    setConfigurationApiCode({ commit }, payload) {
        commit('setConfigurationApiCode', payload)
    },
    setNotesConfig(store, payload) {
        store.commit('setNotesConfig', payload)

        checkDependencies.call(this, payload, store)
    },
    async sendNotesConfig({ commit, state }) {
        const params = {}
        const payload = {
            version: state.configurationVersion,
            configuration: state.configuration,
        }
        const jsonPayload = JSON.stringify(payload)
        state.loadingConfiguration = true

        return Api.getService('configuration')
            .requestPost('send', params, jsonPayload)
            .then((data) => {
                commit('setCurrentNotesCode', data)
            })
            .catch((e) => {
                console.error('Error Loading Configuration', e)
            })
            .finally(() => {
                commit('pageLoaded')
                state.loadingConfiguration = false
            })
    },
    async updateNotesConfig({ commit, state }) {
        const payload = {
            configurationApiCode: state.configurationApiCode.calculationId,
        }
        const jsonPayload = JSON.stringify(payload)

        return Api.getService('configuration')
            .requestPut(
                'update',
                { token: state.configurationCode },
                jsonPayload
            )
            .catch((e) => {
                console.error('Error Loading Configuration', e)
            })
            .finally(() => {
                commit('pageLoaded')
            })
    },
    async getConfig({ commit, dispatch }, { data, context }) {
        if (!data) {
            return
        }
        state.loadingConfiguration = true
        commit('pageIsLoading')

        dispatch('showLoader', {
            message: 'loader.info.config',
            loading: true,
        })

        const params = { token: data }

        // artifical slowdown for one second
        await delay(1000)

        await Api.getService('configuration')
            .requestGet('index', params)
            .then((response) => {
                setAllConfiguration(dispatch, response)

                if (!response.data?.configurationApiCode) {
                    context.$root.$emit('get-config-api-code')
                }
                commit('setConfigurationApiCode', {
                    calculationId: response.data?.configurationApiCode || '-',
                })
                commit('setCurrentNotesCode', {
                    data: { code: response.data?.token || '-' },
                })
                dispatch('changePage', 'yourConfig')

                commit('pageLoaded')
                dispatch('hideLoader')
            })
            .catch((e) => {
                console.error('[getConfig] ', e)
                dispatch('showLoaderError', 'loader.error.config')
            })
            .finally(() => {
                state.loadingConfiguration = false
            })
    },
}

function setAllConfiguration(dispatch, payload) {
    const config = payload.data.configuration
    const version = payload.data.version
    const configurationApiCode = payload.data.configurationApiCode
    const versionMatch = version === state.configurationVersion
    state.configVersionError = false

    if (configurationApiCode) {
        dispatch('setConfigurationApiCode', configurationApiCode)
    }

    // is versions are equal just update configuration else go through validator
    if (versionMatch) {
        for (let prop in config) {
            dispatch('setNotesConfigWithoutCheck', {
                param: prop,
                value: config[prop],
            })
        }
    } else {
        state.configVersionError = true
        for (let prop in config) {
            // if option from api exists in current configurator state then replace it
            // else just set it to null
            if (validator.optionValidator(state, prop, config[prop])) {
                dispatch('setNotesConfigWithoutCheck', {
                    param: prop,
                    value: config[prop],
                })
            } else {
                dispatch('setNotesConfigWithoutCheck', {
                    param: prop,
                    value: null,
                })
            }
        }
    }

    // załaduj zależności po załadowaniu konfiguracji
    dispatch('getDependencies', {
        type: state.configuration?.material?.name,
    })
}

export default {
    actions,
    state,
    mutations,
    getters,
}
