import Vue from 'vue'
import Vuex from 'vuex'
// modules
import menu from './modules/menu'
import page from './modules/page'
import notes from './modules/notes'
import modal from './modules/modal'
import filters from './modules/filters'
import user from './modules/user'
import i18n from './modules/i18n'
import login from './modules/login'
import valuation from './modules/valuation'
import dependencies from './modules/dependencies'
import canvas from './modules/canvas'
import loader from './modules/loader'
// plugins
import changePage from './plugins/changePage'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        menu,
        page,
        notes,
        modal,
        filters,
        user,
        i18n,
        login,
        valuation,
        dependencies,
        canvas,
        loader
    },
    plugins: [changePage],
})
